import { Drawer, DrawerOverlay } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { FastOrderResults } from 'app/pages/customers/common/fastOrder/FastOrderResults'

import { useMobileScannerContext } from '../../context'

import {
  DrawerButton,
  ScanningResultsHeader,
  StickyScanningResultsInfo,
} from './components'
import {
  DrawerBodyStyled,
  DrawerContentStyled,
  DrawerHeaderStyled,
} from './styles'

const messages = translations.pages.customer.scanner

type ScanningResultsDrawerProps = {
  pauseScanning: () => void
  resumeScanning: () => void
}

export const ScanningResultsDrawer = ({
  pauseScanning,
  resumeScanning,
}: ScanningResultsDrawerProps) => {
  const { t } = useTranslation()

  const { isDrawerOpen, setIsDrawerOpen } = useMobileScannerContext()

  const onOpenHandler = () => {
    setIsDrawerOpen(true)
    pauseScanning()
  }

  const onCloseHandler = () => {
    setIsDrawerOpen(false)
    resumeScanning()
  }

  const emptyCaseWrapperProps = {
    message: t(messages.emptyCase.header),
    shouldHideBorder: true,
    subHeader: t(messages.emptyCase.descriptionMobile),
  }

  return (
    <>
      {/* INFO: StickyScanningResultsInfo is always visible, but hidden below Drawer when it's fully expanded */}
      <StickyScanningResultsInfo onClick={onOpenHandler} />

      <Drawer
        isOpen={isDrawerOpen}
        onClose={onCloseHandler}
        placement="bottom"
        size="full"
      >
        <DrawerOverlay />

        <DrawerContentStyled data-testid="expandedDrawerContent">
          <DrawerHeaderStyled onClick={onCloseHandler}>
            <ScanningResultsHeader textStyle="headerH3" />
            <DrawerButton />
          </DrawerHeaderStyled>
          <DrawerBodyStyled>
            <FastOrderResults emptyCaseWrapperProps={emptyCaseWrapperProps} />
          </DrawerBodyStyled>
        </DrawerContentStyled>
      </Drawer>
    </>
  )
}
