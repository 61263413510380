import { useMutation, useQueryClient } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { EXTERNAL_REFERENCES_LIST_QUERY_KEY } from '../List'
import { ARTICLE_LISTS_QUERY_KEY } from '../../ArticleLists/List'

export const useDeleteArticleExternalReferenceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: generatedApi.customers.deleteArticleExternalReference.request,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [EXTERNAL_REFERENCES_LIST_QUERY_KEY],
      })

      queryClient.invalidateQueries({
        queryKey: [ARTICLE_LISTS_QUERY_KEY],
      })
    },
  })
}
