import type { TypographyWithTooltipOnOverflowProps } from 'app/components/tooltips'
import { TypographyWithTooltipOnOverflow } from 'app/components/tooltips'

export const ColumnHeaderTypography = ({
  ...props
}: TypographyWithTooltipOnOverflowProps) => (
  <TypographyWithTooltipOnOverflow
    textStyle="textSmallSb"
    as="span"
    color="contentB"
    {...props}
  />
)
