import styled from '@emotion/styled'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

import { hiddenScrollbarStyles } from 'theme/reusableStylesCss'

export const TableStyled = styled(Box)(() => ({
  display: 'block', // These styles are suggested for the table fill all available space in its containing element
  overflow: 'auto', // These styles are required for a horizontaly scrollable table overflow
}))

export const TrStyled = styled('div')<{
  isActive?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}>(({ isActive, onClick, theme: { colors, mediaQuery } }) => ({
  cursor: typeof onClick === 'function' ? 'pointer' : 'auto',
  ...(isActive && {
    borderLeft: `4px solid ${colors.blue_500}`,
    background: colors.backgroundC,
  }),
  [mediaQuery.print]: {
    breakInside: 'avoid',
  },
}))

type TbodyStyledProps = {
  withoutLastRowBorder?: boolean
  isVirtualized?: boolean
  withoutRowBorder?: boolean
  withScrollbar?: boolean
}

export const TbodyStyled = styled(
  ({
    withoutLastRowBorder,
    isVirtualized,
    withoutRowBorder,
    withScrollbar,
    ...rest
  }: TbodyStyledProps & BoxProps) => <Box {...rest} />,
)(
  ({
    theme,
    withScrollbar,
    withoutLastRowBorder,
    withoutRowBorder,
    isVirtualized,
  }) => ({
    // These styles are required for a scrollable table body
    overflowY: 'auto',
    overflowX: 'hidden',
    ...(!withScrollbar && { '> *': hiddenScrollbarStyles }),

    [String(TrStyled)]: {
      alignItems: 'center',
      borderBottom: withoutRowBorder ? 'none' : theme.borders.primary,
      ':hover': {
        backgroundColor: theme.colors.backgroundB,
      },
      ...(withoutLastRowBorder &&
        !isVirtualized && {
          ':last-child': {
            borderBottom: 'none',
          },
        }),
    },
    ...(withoutLastRowBorder &&
      isVirtualized && {
        '.lastRow': {
          borderBottom: 'none',
        },
      }),
    // INFO: In the virtualized table the TrStyled element is wrapped in additional wrapper
    // so the last-child selector doesn't work
  }),
)

export const defaultColumn = {
  width: 100, // width is used for both the flex-basis and flex-grow
  minWidth: 30, // minWidth is only used as a limit for resizing
  // maxWidth: 200, // maxWidth is only used as a limit for resizing
}
