import type { IconProps } from '../types'

export const ShippingIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M2 7C2 5.89543 2.89543 5 4 5H11C12.1046 5 13 5.89543 13 7V14H3C2.44771 14 2 13.5523 2 13V7Z"
      fill={color}
    />
    <circle cx="6" cy="14" r="2" fill="white" stroke={color} strokeWidth="2" />
    <rect x="12" y="11" width="5" height="3" fill={color} />
    <path d="M12 8H15.382L17 11.2361V13H12V8Z" stroke={color} strokeWidth="2" />
    <circle cx="14" cy="14" r="2" fill="white" stroke={color} strokeWidth="2" />
  </svg>
)
