import type { ReactNode } from 'react'

import { IS_TEST } from 'consts/envVars'

import { HideOnPrintout } from './HideOnPrintout'
import { ShowOnlyOnPrintout } from './ShowOnlyOnPrintout'

export const PRINTOUT_ID = 'printout'

export const PrintoutIfElse = ({
  printChildren,
  nonPrintChildren,
}: {
  printChildren: ReactNode
  nonPrintChildren: ReactNode
}) => {
  if (IS_TEST || window.Cypress) return <>{nonPrintChildren}</>

  return (
    <>
      <HideOnPrintout>{nonPrintChildren}</HideOnPrintout>
      <ShowOnlyOnPrintout id={PRINTOUT_ID}>{printChildren}</ShowOnlyOnPrintout>
    </>
  )
}
