import { useQueryParam } from 'use-query-params'

import type { ModelVendorPreviewArticlesSearchSearchArticlesByEnum } from 'libs/openapiGeneratedApi/Api'

import { useSearchAndPagination } from 'app/hooks/useSearchAndPagination'

import { SEARCH_ARTICLE_TERM_NAME, SEARCH_PRESET_NAME } from './consts'

const searchQueryParamName = SEARCH_ARTICLE_TERM_NAME
const searchPresetQueryParamName = SEARCH_PRESET_NAME

export const useMarketplaceSearchAndPagination = () => {
  const [searchPreset, setSearchPreset] = useQueryParam<
    ModelVendorPreviewArticlesSearchSearchArticlesByEnum | undefined
  >(SEARCH_PRESET_NAME)

  const searchAndPagination = useSearchAndPagination({
    searchQueryParamName,
  })

  return {
    searchQueryParamName,
    searchPresetQueryParamName,
    searchPreset,
    setSearchPreset,
    ...searchAndPagination,
  }
}
