import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import type { ModelVendorPreviewArticlesSearchSearchArticlesByEnum } from 'libs/openapiGeneratedApi/Api'
import { EXTRA_SEARCH_PROFILES } from 'consts/envVars'

import { useMarketplaceSearchAndPagination } from '../../useMarketplaceSearchAndPagination'

const presetSelectOptionsMessages =
  translations.components.searchBox.presetSelect.options
const presetSelectShortOptionsMessages =
  translations.components.searchBox.presetSelect.shortOptions

export const ARTICLE_SEARCH_PRESETS: Extract<
  ModelVendorPreviewArticlesSearchSearchArticlesByEnum,
  'STANDARD' | 'ALL_ATTRIBUTES' | 'CUSTOMER_ARTICLE_NUMBER'
>[] = ['STANDARD', 'ALL_ATTRIBUTES', 'CUSTOMER_ARTICLE_NUMBER']

export const useSearchPresetSelect = () => {
  const { t } = useTranslation()

  const { searchPreset } = useMarketplaceSearchAndPagination()

  const baseOptions = ARTICLE_SEARCH_PRESETS.map((preset) => ({
    id: preset,
    label: t(presetSelectOptionsMessages[preset]),
    shortLabel: t(presetSelectShortOptionsMessages[preset]),
  }))

  const extraOptions = (EXTRA_SEARCH_PROFILES || []).map(
    ({ value, label }: { value: string; label: string }) => ({
      id: value as ModelVendorPreviewArticlesSearchSearchArticlesByEnum,
      label: label,
      shortLabel: label,
    }),
  )

  const options = [...baseOptions, ...extraOptions]

  const activeSearchPreset =
    options.find((option) => option.id === searchPreset) ?? options[0]

  return {
    options,
    activeSearchPreset,
  }
}
