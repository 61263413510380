import type { IconProps } from '../types'

export const SearchIcon = ({
  width = 20,
  height = 20,
  color = '#102C60',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="9.5" cy="9.5" r="6.5" stroke={color} strokeWidth="2" />
    <path d="M14 14L19 19" stroke={color} strokeWidth="2" />
  </svg>
)
