import { isWeekend, isWithinInterval, isValid, isFriday } from 'date-fns'

const PHONE_SUPPORT_AVAILABILITY_START_TIME = '8:00'
const PHONE_SUPPORT_AVAILABILITY_END_TIME_MON_THU = '16:00'
const PHONE_SUPPORT_AVAILABILITY_END_TIME_FRI = '15:00'

export const useCheckSupportTimeAvailability = () => {
  const current = new Date()

  if (isWeekend(current)) return { isValid: true, isAvailable: false }

  const timeStart = new Date(
    `${current.toDateString()} ${PHONE_SUPPORT_AVAILABILITY_START_TIME}`,
  )
  const timeEndMonThu = new Date(
    `${current.toDateString()} ${PHONE_SUPPORT_AVAILABILITY_END_TIME_MON_THU}`,
  )
  const timeEndFri = new Date(
    `${current.toDateString()} ${PHONE_SUPPORT_AVAILABILITY_END_TIME_FRI}`,
  )

  if (!isValid(timeStart) || !isValid(timeEndMonThu) || !isValid(timeEndFri)) {
    return { isValid: false }
  }

  const isSupportAvailableFri = isWithinInterval(current, {
    start: timeStart,
    end: timeEndFri,
  })

  if (isFriday(current)) {
    return { isValid: true, isAvailable: isSupportAvailableFri }
  }

  const isSupportAvailableMonThu = isWithinInterval(current, {
    start: timeStart,
    end: timeEndMonThu,
  })

  return { isValid: true, isAvailable: isSupportAvailableMonThu }
}
