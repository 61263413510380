import { articlesSearchMockedData } from './data'

export const filterArticlesMockData = (term?: string | null) => {
  if (!articlesSearchMockedData.items)
    throw new Error('articlesSearchMockedData items property should be defined')

  return articlesSearchMockedData.items.filter((data) =>
    term ? data.article.name.toLowerCase().includes(term.toLowerCase()) : true,
  )
}
