import styled from '@emotion/styled'
import { Fragment } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'

import { colors } from 'theme/colors'
import { dimensionsRaw } from 'theme/dimensions'
import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { useCurrentUser } from 'app/containers/common/Auth'
import { Button } from 'app/components/Buttons/Button'

import type { HeaderProps } from '../types'

type BreadcrumbNavWrapperProps = FlexProps & { isCustomer: boolean }

const BreadcrumbNavWrapper = styled(
  ({ isCustomer, as, ...rest }: BreadcrumbNavWrapperProps) => (
    <Flex as={as} {...rest} />
  ),
)(({ isCustomer, theme: { mediaQuery } }) => ({
  padding: '14px 24px',
  justifyContent: 'space-between',
  ...(isCustomer && {
    alignSelf: 'center',
    width: '100%',
    maxWidth: dimensionsRaw.customerContentMaxWidth + 2 * 24,
  }),

  [mediaQuery.small]: {
    padding: '8px 24px',
  },
}))

type BreadcrumbProps = Pick<HeaderProps, 'breadcrumbs' | 'onCancel'>

export const BreadcrumbNav = ({ breadcrumbs, onCancel }: BreadcrumbProps) => {
  const { t } = useTranslation()
  const { isCustomer, isOciCustomer } = useCurrentUser()

  return (
    <BreadcrumbNavWrapper
      isCustomer={isCustomer || isOciCustomer}
      data-testid="breadcrumbsHeader"
    >
      <Flex align="center">
        {breadcrumbs?.map(({ label }, index) => {
          const isLast = index === breadcrumbs.length - 1

          return (
            <Fragment key={label}>
              <Typography
                textStyle="headerH3"
                {...(!isLast && { color: 'contentC' })}
              >
                {label}
              </Typography>

              {!isLast && (
                <ChevronRightIcon
                  width="20px"
                  height="20px"
                  margin="0 5px"
                  color={colors.contentC}
                />
              )}
            </Fragment>
          )
        })}
      </Flex>

      <Button
        onClick={onCancel}
        size="sm"
        variant="secondary"
        type="reset"
        data-testid="breadcrumbsCancelButton"
      >
        {t(translations.formActions.cancel)}
      </Button>
    </BreadcrumbNavWrapper>
  )
}
