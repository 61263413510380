import type { ReactNode } from 'react'

import type { Color } from 'theme/colors'
import { colors } from 'theme/colors'

type SpanPropsWithoutFontWeight = Omit<
  React.HTMLProps<HTMLSpanElement>,
  'fontWeight'
>

export const SemiBoldSpan = (props: SpanPropsWithoutFontWeight) => (
  <span style={{ fontWeight: 600 }} {...props} />
)

export const BoldSpan = (props: SpanPropsWithoutFontWeight) => (
  <span style={{ fontWeight: 700 }} {...props} />
)

export const SpanWithColor = ({
  color,
  children,
}: {
  color: Color
  children: ReactNode
}) => <span style={{ color: colors[color] }}>{children}</span>
