import type { IconProps } from '../types'

export const TrashIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <path d="M5 6H15" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path
      d="M5 8h10l-.82 8.199A2 2 0 0 1 12.19 18H7.81a2 2 0 0 1-1.99-1.801L5 8Z"
      fill={color}
    />
    <path
      d="m7.5 6 .749-1.871a1 1 0 0 1 .928-.629h1.646a1 1 0 0 1 .928.629L12.5 6"
      stroke={color}
    />
  </svg>
)
