import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { SectionHeader } from '../SectionHeader'

const emptyCaseTranslations = translations.emptyList

export type StaticEmptyCaseWrapperProps = StackProps & {
  icon?: ReactNode
  message?: ReactNode
  subHeader?: ReactNode
  explanation?: ReactNode
  action?: ReactNode
  shouldHideBorder?: boolean
}

export const StaticEmptyCaseWrapper = ({
  icon,
  message,
  subHeader,
  explanation,
  action,
  shouldHideBorder,
  ...props
}: StaticEmptyCaseWrapperProps) => {
  const { t } = useTranslation()

  return (
    <VStack
      data-testid="emptyCaseWrapper"
      p="40px 24px"
      spacing="32px"
      width="100%"
      textAlign="center"
      borderRadius={shouldHideBorder ? undefined : '6px'}
      border={shouldHideBorder ? undefined : 'primary'}
      {...props}
    >
      {icon}

      <SectionHeader
        align="center"
        header={message ?? t(emptyCaseTranslations.default)}
        subHeader={subHeader}
        description={explanation}
        maxWidth="1080px"
        whiteSpace="pre-wrap"
      />

      {action}
    </VStack>
  )
}

export type EmptyCaseWrapperProps = StaticEmptyCaseWrapperProps & {
  children?: ReactNode
  data?: unknown[]
  isLoading?: boolean
}

export const EmptyCaseWrapper = ({
  children,
  isLoading,
  data,
  ...rest
}: EmptyCaseWrapperProps) => {
  const isEmpty = !isLoading && data?.length === 0

  return <>{isEmpty ? <StaticEmptyCaseWrapper {...rest} /> : children}</>
}
