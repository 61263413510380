import { Flex } from '@chakra-ui/react'

import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'
import { colors } from 'theme/colors'

import {
  ArticleEanText,
  ArticleNumberText,
} from 'app/pages/common/marketplaceAndSearch/components/ArticleTexts'
import { ScannerIcon } from 'app/components/icons'

import { MatchedField } from './MatchedField'
import { ArticleContentDescriptionLineWrapper } from './ArticleContentDescriptionLineWrapper'

type ArticleContentDescriptionLineProps = {
  articleData: ModelGetFastOrderListData['items'][number]['foundArticles'][number]
}

export const ArticleContentDescriptionLine = ({
  articleData,
}: ArticleContentDescriptionLineProps) => {
  const { matchedField, articleNumber, ean } = articleData

  if (!matchedField) {
    return (
      <ArticleContentDescriptionLineWrapper>
        <ArticleNumberText number={articleNumber} />
        <ArticleEanText ean={ean ?? ''} />
      </ArticleContentDescriptionLineWrapper>
    )
  }

  return (
    <ArticleContentDescriptionLineWrapper>
      {matchedField !== 'VENDOR_ARTICLE_NUMBER' && (
        <ArticleNumberText number={articleNumber} color="contentB" />
      )}

      <Flex gap="4px">
        <MatchedField articleData={articleData} />
        <ScannerIcon color={colors.contentA} width="16px" height="16px" />
      </Flex>
    </ArticleContentDescriptionLineWrapper>
  )
}
