import { includes } from 'types/utils'

import type { DisabledFastOrderItemStatus } from 'app/pages/customers/common/fastOrder/types'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

import {
  AvailableArticleActions,
  DisabledArticleActions,
  ItemWithoutSelectedArticleActions,
} from './components'

const disabledFastOrderItemStatuses: DisabledFastOrderItemStatus[] = [
  'NOT_FOUND',
  'NO_LONGER_AVAILABLE',
  'RESTRICTED',
]

export const ArticleActions = () => {
  const { status } = useFastOrderListItemContext()

  if (includes(disabledFastOrderItemStatuses, status)) {
    return <DisabledArticleActions />
  }

  if (status === 'AWAITING_SELECTION_FROM_MULTIPLE') {
    return <ItemWithoutSelectedArticleActions />
  }

  return <AvailableArticleActions />
}
