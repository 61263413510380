import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'
import { throwIfAbsent } from 'types/utils'

import { useCurrentUser } from 'app/containers/common/Auth'

const VENDOR_MARKETPLACE_PREVIEW_CATALOG_ASSIGNED_CUSTOMERS_LIST_QUERY_KEY =
  'vendorMarketplacePreviewAssignedCustomersList'

type UseMarketplacePreviewCatalogAssignedCustomersListQueryProps = {
  catalogId: string | undefined
  enabled?: boolean
}

export const useMarketplacePreviewCatalogAssignedCustomersListQuery = ({
  catalogId,
  enabled,
}: UseMarketplacePreviewCatalogAssignedCustomersListQueryProps) => {
  const { isVendor } = useCurrentUser()

  const queryFn = () => {
    throwIfAbsent(catalogId)

    return generatedApi.vendors.getVendorMarketplaceCatalogAssignedCustomers.request(
      { catalogId },
    )
  }

  return useQuery({
    queryKey: [
      VENDOR_MARKETPLACE_PREVIEW_CATALOG_ASSIGNED_CUSTOMERS_LIST_QUERY_KEY,
      catalogId,
    ],
    queryFn,
    enabled: enabled && isVendor && !!catalogId,
    staleTime: 5000,
  })
}
