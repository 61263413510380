import { useQuery, keepPreviousData } from '@tanstack/react-query'

import { getCustomersRequest } from './api'
import type { API__GetCustomersRequestArgs } from './types'

export const CUSTOMERS_QUERY_KEY = 'customers'

export const useCustomersQuery = (params: API__GetCustomersRequestArgs) =>
  useQuery({
    queryKey: [CUSTOMERS_QUERY_KEY, params],
    queryFn: () => getCustomersRequest(params),
    placeholderData: keepPreviousData,
  })
