import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import type { MyOption, SelectProps } from './types'

const selectPlaceholderMessage =
  translations.formFields.genericSelectPlaceholder

type UseSelectFieldProps = {
  multiple?: boolean
} & Pick<SelectProps, 'name' | 'placeholder'>

export const useBareSelectField = ({
  name,
  placeholder,
}: UseSelectFieldProps) => {
  const { t } = useTranslation()

  return {
    id: name,
    name,
    placeholder: placeholder ?? t(selectPlaceholderMessage),
    getOptionValue: (option: MyOption) => option.label ?? '',
    'aria-label': name,
  }
}

export type FormikSelectValue = undefined | MyOption['id'] | MyOption['id'][]

export const useSelectField = (props: UseSelectFieldProps) => {
  const { name, multiple } = props
  const bareSelectProps = useBareSelectField(props)

  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField<FormikSelectValue>({
      name,
      multiple,
    })

  const isInvalid = touched && Boolean(error)

  const selectProps = {
    onBlur: () => setTouched(true),
    'aria-invalid': isInvalid,
    ...bareSelectProps,
  }

  return { value, selectProps, setValue, setTouched, isInvalid, touched, error }
}
