import { useState } from 'react'

import { parseInputValue } from '../../parsers'
import { MAX_INPUT_VALUE } from '../useAmountHandlers/consts'
import type { InMemoryAmountInputProps } from '../types'

import { useInMemoryAmountInputHandlers } from './useInMemoryAmountInputHandlers'

export const useInMemoryAmountHandlers = ({
  quantityInterval: passedQuantityInterval,
  minimumOrder: passedMinimumOrder,
  quantity,
  setQuantity,
}: InMemoryAmountInputProps) => {
  const [shownValue, setShownValue] = useState<number | string>(quantity)

  const quantityInterval = passedQuantityInterval ?? 1
  const quantityMinimum = passedMinimumOrder ?? 1

  const parsedValue = parseInputValue({
    value: shownValue,
    quantityMinimum,
    quantityInterval,
  })

  const minusHandler = () => {
    const newValue = (quantity ?? quantityMinimum) - quantityInterval

    if (newValue < quantityMinimum) {
      setQuantity(quantityMinimum)
      setShownValue(quantityMinimum)
    } else {
      setQuantity(newValue)
      setShownValue(newValue)
    }
  }

  const plusHandler = () => {
    const newValue = (quantity ?? quantityMinimum) + quantityInterval

    if (newValue <= MAX_INPUT_VALUE) {
      setQuantity(newValue)
      setShownValue(newValue)
    }
  }

  const updateHandler = (newValue: number) => {
    if (newValue < quantityMinimum) {
      setShownValue(quantityMinimum)
      setQuantity(quantityMinimum)
    } else if (newValue > MAX_INPUT_VALUE) {
      setQuantity(MAX_INPUT_VALUE)
    } else setQuantity(newValue)
  }

  const { changeHandler, blurHandler, keyUpHandler } =
    useInMemoryAmountInputHandlers({
      updateHandler,
      quantity,
      parsedValue,
      setShownValue,
      shownValue,
    })

  return {
    changeHandler,
    blurHandler,
    keyUpHandler,
    minusHandler,
    plusHandler,
    shownValue,
    setShownValue,
  }
}
