import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useCurrentUser } from 'app/containers/common/Auth'

import type { AccountTypeId } from '../models'
import type { AccountTypeValueId } from '../Values/models'

export const ASSIGNED_ACCOUNT_TYPES_AND_VALUES_LIST_QUERY_KEY =
  'assignedAccountTypesAndValuesList'

export const useAssignedAccountTypesAndValuesListQuery = () => {
  const { isCustomer } = useCurrentUser()

  return useQuery({
    enabled: isCustomer,
    queryKey: [ASSIGNED_ACCOUNT_TYPES_AND_VALUES_LIST_QUERY_KEY],
    queryFn: () =>
      generatedApi.customers.getCustomerAssignedAccountTypesAndValues.request(),
    staleTime: 5_000,
  })
}

export const useIsAnyAssignedAccountTypeAvailable = () => {
  const { data } = useAssignedAccountTypesAndValuesListQuery()

  return { isAnyAssignedAccountTypeAvailable: !!data?.accountTypes.length }
}

export const useGetAssignedAccountType = () => {
  const { data } = useAssignedAccountTypesAndValuesListQuery()

  const getAccountType = (accountTypeId: AccountTypeId | null | undefined) =>
    data?.accountTypes.find(({ id }) => id === accountTypeId)

  return { getAccountType }
}

export const useGetAssignedAccountValue = (
  accountTypeId: AccountTypeId | undefined,
) => {
  const { data } = useAssignedAccountTypesAndValuesListQuery()

  const getAccountValue = (
    accountTypeValueId: AccountTypeValueId | null | undefined,
  ) =>
    accountTypeId &&
    data?.accountValuesByType[accountTypeId]?.find(
      ({ id }) => id === accountTypeValueId,
    )

  return { getAccountValue }
}
