import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'theme'
import { translations } from 'locales/translations'

import { MinusIcon, PlusIcon } from 'app/components/icons'
import { BareInput } from 'app/components/inputs/Input/BareInput'
import { Button } from 'app/components/Buttons/Button'
import { Typography } from 'app/components/Typography'
import { useDetectPrintMode } from 'app/utils/print/useDetectPrintMode'

import type { BasicAmountInputProps } from './types'
import { AmountInputWrapper, InputWrapper } from './styles'

const messages = translations.components.amountInput

export const BasicAmountInput = ({
  size,
  isDisabled,
  isImmutable,
  shownValue,
  changeHandler,
  plusHandler,
  minusHandler,
  blurHandler,
  keyUpHandler,
}: BasicAmountInputProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { isPrintMode } = useDetectPrintMode()

  const buttonCommonProps = {
    size: size === 'sm' ? 'squareSm' : 'squareMd',
    variant: 'secondary',
  } as const

  return (
    <Flex align="center" gap="8px">
      {isPrintMode && (
        <Typography width="130px" as="span">
          {t(messages.quantity)}
        </Typography>
      )}
      <AmountInputWrapper>
        {!isImmutable && (
          <Button
            onClick={minusHandler}
            data-testid="decreaseAmount"
            isDisabled={isDisabled}
            leftIcon={<MinusIcon />}
            {...buttonCommonProps}
          />
        )}

        <InputWrapper>
          <BareInput
            name="amountInput"
            type="number"
            isDisabled={isDisabled || isImmutable}
            size={size}
            value={shownValue}
            onChange={changeHandler}
            onBlur={blurHandler}
            onKeyUp={keyUpHandler}
            fontWeight={600}
            textAlign="center"
            padding="8px 0px"
            {...(isImmutable && { style: { opacity: 1, color: colors.black } })}
          />
        </InputWrapper>

        {!isImmutable && (
          <Button
            onClick={plusHandler}
            data-testid="increaseAmount"
            isDisabled={isDisabled}
            leftIcon={<PlusIcon />}
            {...buttonCommonProps}
          />
        )}
      </AmountInputWrapper>
    </Flex>
  )
}
