/**
 * Combine all reducers in this file and export the combined reducers.
 */

import type { Reducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

import { AUTH_REDUCER_NAME } from 'app/containers/common/Auth/consts'
import { authReducer, logout } from 'app/containers/common/Auth/authSlice'
import {
  LANGUAGE_REDUCER_NAME,
  languageReducer,
} from 'app/containers/common/UnauthorizedUserLanguage'
import {
  fileUploadReducer,
  FILE_UPLOAD_REDUCER_NAME,
} from 'app/containers/common/FileUpload'
import { DELIVERY_ADDRESSES_REDUCER_NAME } from 'app/containers/customers/DeliveryAddresses/consts'
import { deliveryAddressesReducer } from 'app/containers/customers/DeliveryAddresses/deliveryAddressesSlice'

import { routerReducer } from './reduxHistoryContext'

/**
 * Merges the main reducer with the router state
 * persistedReducersPlaceholder additionally adds reducers that are persisted
 * in order to avoid warnings about unexpected state
 */

export const reducer: Reducer = (state, action) => {
  const rootReducer = combineReducers({
    router: routerReducer,
    [AUTH_REDUCER_NAME]: authReducer,
    [FILE_UPLOAD_REDUCER_NAME]: fileUploadReducer,
    [LANGUAGE_REDUCER_NAME]: languageReducer,
    [DELIVERY_ADDRESSES_REDUCER_NAME]: deliveryAddressesReducer,
  })

  if (action.type === logout.type) {
    return {
      ...rootReducer(undefined, action),
      router: state.router,
    }
  }

  return rootReducer(state, action)
}
