import type { TabsProps } from '@chakra-ui/react'
import { TabPanel, TabPanels, Box } from '@chakra-ui/react'
import { useQueryParam } from 'use-query-params'

import { useSearchAndPagination } from 'app/hooks/useSearchAndPagination'

import {
  TabStyled,
  Line,
  WrapperStyled,
  TabsStyled,
  TabsWrapper,
} from './styles'
import type { TabData, PageTabsProps } from './types'
import { TabsGroup } from './components'

export const ACTIVE_TAB_QUERY_KEY = 'activeTab'

export const PageTabs = <T extends string>({
  tabsData,
  activeKey,
  setActiveKey,
  children,
  contentPaddingTop = 0,
  queryTabKey,
  urlUpdateType,
  onTabChange,
  disableResetPageOnTabChange,
  underTabsElement,
  ...rest
}: PageTabsProps<T>) => {
  const [activeUncontrolledKey, setActiveUncontrolledTab] = useQueryParam<
    T | undefined
  >(queryTabKey ?? ACTIVE_TAB_QUERY_KEY, undefined, {
    updateType: urlUpdateType,
  })
  const { resetPage } = useSearchAndPagination()

  // INFO:
  // since TabData type may contain subTabs array, we need to transform it into flat array of BasicTabData,
  // so that we display proper content for all tabs
  const flattenedTabsData = tabsData.reduce(
    (acc, currentTab) => [...acc, currentTab, ...(currentTab.subTabs ?? [])],
    [] as TabData<T>[],
  )

  const activeTab =
    activeKey ?? activeUncontrolledKey ?? flattenedTabsData[0].key
  const setActiveTab = setActiveKey ?? setActiveUncontrolledTab

  const tabIndex = flattenedTabsData.findIndex(({ key }) => key === activeTab)

  const handleTabsChange: TabsProps['onChange'] = (index) => {
    setActiveTab(flattenedTabsData[index].key)

    if (!disableResetPageOnTabChange) {
      resetPage()
    }

    if (onTabChange) onTabChange(flattenedTabsData[index].key)
  }

  return (
    <TabsStyled index={tabIndex} onChange={handleTabsChange} {...rest}>
      <WrapperStyled>
        <TabsWrapper>
          {tabsData.map((tabData, index) => {
            const { key, title, subTabs } = tabData

            if (subTabs) {
              return <TabsGroup key={index} tabData={tabData} />
            }

            return (
              <TabStyled key={key} data-testid={key}>
                {title}
              </TabStyled>
            )
          })}

          {/* Empty Box to solve padding issues */}
          <Box width="40px" />
        </TabsWrapper>

        <Line />
      </WrapperStyled>

      {underTabsElement}

      <TabPanels>
        {flattenedTabsData.map(({ key, content }) => (
          <TabPanel p={0} paddingTop={contentPaddingTop} key={key}>
            {content ?? children}
          </TabPanel>
        ))}
      </TabPanels>
    </TabsStyled>
  )
}
