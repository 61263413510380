import { Flex } from '@chakra-ui/react'

import { colors } from 'theme/colors'

import { Typography } from 'app/components/Typography/Typography'
import { ArticleLinkWrapper } from 'app/pages/common/marketplaceAndSearch/components/ArticleLinkWrapper'
import { SoldByText } from 'app/pages/common/marketplaceAndSearch/components/ArticleTexts'
import { WithDotsSeparatorWrapper } from 'app/components/DotSeparator/WithDotsSeparatorWrapper'

import type { GenericArticleContentProps } from '../types'

type GenericArticleContentHeaderProps = Pick<
  GenericArticleContentProps,
  'isWithoutLinks' | 'articleNameNoOffLines'
> & {
  articleData: Pick<
    GenericArticleContentProps['articleData'],
    'articleId' | 'vendorName' | 'manufacturerName' | 'name'
  >
}

export const GenericArticleContentHeader = ({
  isWithoutLinks,
  articleData,
  articleNameNoOffLines,
}: GenericArticleContentHeaderProps) => {
  const { articleId, vendorName, manufacturerName, name } = articleData

  return (
    <>
      <Flex gap="8px">
        <WithDotsSeparatorWrapper
          color={colors.contentOff}
          margin="0"
          childrenToRender={[
            manufacturerName && (
              <Typography textStyle="textSmallSb" color="contentB">
                {manufacturerName}
              </Typography>
            ),

            <Typography textStyle="textSmallSb" color="contentB">
              <SoldByText vendorName={vendorName} />
            </Typography>,
          ]}
        />
      </Flex>

      <ArticleLinkWrapper articleId={isWithoutLinks ? undefined : articleId}>
        <Typography textStyle="textNormalSb" noOfLines={articleNameNoOffLines}>
          {name}
        </Typography>
      </ArticleLinkWrapper>
    </>
  )
}
