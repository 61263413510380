import { useTranslation } from 'react-i18next'
import type { Dispatch, SetStateAction } from 'react'

import { translations } from 'locales/translations'

import { useGetShowSuccessToast } from 'app/components/Notification/useGetShowSuccessToast'

import { DELAY_AFTER_SUCCESSFUL_SCAN } from '../consts'

const messages = translations.pages.customer.scanner.mobile

export const useShowSuccessToastOnSuccessfulScan = () => {
  const { t } = useTranslation()

  const { showSuccessToast } = useGetShowSuccessToast()

  const showSuccessToastConfigured = () => {
    showSuccessToast({
      content: t(messages.scanSuccessNotification),
      snackbarProps: {
        autoHideDuration: DELAY_AFTER_SUCCESSFUL_SCAN,
      },
      notificationProps: {
        onNotificationClose: undefined, // INFO: Hide close button
      },
    })
  }

  return { showSuccessToastConfigured }
}

type ResumeScanningWithDelayProps = {
  resumeScanningWithNotStaleIsOpen: () => void
  setIsProgressButtonShown: Dispatch<SetStateAction<boolean>>
}

export const useResumeScanningWithDelay = () => {
  const resumeScanningWithDelay = ({
    resumeScanningWithNotStaleIsOpen,
    setIsProgressButtonShown,
  }: ResumeScanningWithDelayProps) => {
    setIsProgressButtonShown(true)

    return setTimeout(() => {
      setIsProgressButtonShown(false)
      resumeScanningWithNotStaleIsOpen()
    }, DELAY_AFTER_SUCCESSFUL_SCAN)
  }

  return { resumeScanningWithDelay }
}
