import { useMutation, useQueryClient } from '@tanstack/react-query'
import { generatePath } from 'react-router-dom'

import { api } from 'apiServices'

import { articleListsPath } from '../consts'
import { ARTICLE_LISTS_QUERY_KEY } from '../List'

import type { API__DeleteArticleListRequestParams } from './types'

export const url = articleListsPath

const deleteArticleListRequest = ({
  id,
}: API__DeleteArticleListRequestParams) =>
  api.delete(generatePath(url, { articleListId: id }))

export const useDeleteArticleListMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteArticleListRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ARTICLE_LISTS_QUERY_KEY],
      })
    },
  })
}
