import { Link } from 'react-router-dom'
import { uniqBy } from 'lodash'

import { PATHS } from 'consts/paths'

import { CartIcon } from 'app/components/icons'
import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'
import { useCartItemsLightQuery } from 'app/containers/customers/CurrentCart/Items/List'

export const CartButton = ({ size = 'md', ...props }: ButtonProps) => {
  const { data: cartArticles } = useCartItemsLightQuery()
  const uniqArticles = uniqBy(cartArticles ?? [], 'articleId')

  return (
    <Link to={PATHS.customer.checkout.cart} style={{ display: 'block' }}>
      <Button
        size={size}
        leftIcon={<CartIcon />}
        data-testid="openCart"
        {...props}
      >
        {uniqArticles.length}
      </Button>
    </Link>
  )
}
