import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { useIsAuthorized, useOciSession } from 'app/containers/common/Auth'
import type { MODEL__User } from 'app/models/user'
import { impersonationSelector } from 'app/containers/common/Auth/selectors'

import { userMeRequest, ociCustomerMeRequest } from '../api'
import { USER_ME_QUERY_KEY } from '../consts'

export const useUserMeQuery = (
  { enabled }: Pick<UseQueryOptions<MODEL__User>, 'enabled'> = {
    enabled: false,
  },
) => {
  const { impersonatedUserId } = useSelector(impersonationSelector) ?? {}
  const { isAuthorized } = useIsAuthorized()
  const { isOciSessionFlow } = useOciSession()
  const isQueryEnabled = enabled && (isOciSessionFlow || isAuthorized)

  return useQuery({
    queryKey: [USER_ME_QUERY_KEY, impersonatedUserId], // INFO: we need `impersonatedUserId` here to refetch `me` data after impersonation in multiple tabs
    queryFn: isOciSessionFlow ? ociCustomerMeRequest : userMeRequest,
    enabled: isQueryEnabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}
