import {
  de as deYupLocale,
  fr as frYupLocale,
  it as itYupLocale,
  cs as csYupLocale,
} from 'yup-locales'
import { setLocale, defaultLocale as enYupLocale } from 'yup'
import { useEffect } from 'react'

import { AVAILABLE_LANGUAGES } from 'locales/consts'

import { useIsAuthorized } from 'app/containers/common/Auth'
import { useLocale } from 'app/hooks/useLocale'

const YUP_LOCALES_MAP = {
  [AVAILABLE_LANGUAGES.de]: deYupLocale,
  [AVAILABLE_LANGUAGES.en]: enYupLocale,
  [AVAILABLE_LANGUAGES.fr]: frYupLocale,
  [AVAILABLE_LANGUAGES.it]: itYupLocale,
  [AVAILABLE_LANGUAGES.cs]: csYupLocale,
  [AVAILABLE_LANGUAGES.cimode]: enYupLocale,
} as const

export const useSetYupLocale = () => {
  const { browserFallbackLocale, serverLocale } = useLocale()
  const { isAuthorized } = useIsAuthorized()

  // unfortunately the setLocale can be effectively called only once before the all validators creation process
  // https://github.com/jquense/yup/issues/1008
  // open PR with the fix - https://github.com/jquense/yup/pull/1371
  // TODO: update yup version and write proper integration tests
  // https://simplesystem.atlassian.net/browse/S2NG-4520
  useEffect(() => {
    if (isAuthorized) {
      if (serverLocale) {
        setLocale(YUP_LOCALES_MAP[serverLocale])
      }
    } else {
      setLocale(YUP_LOCALES_MAP[browserFallbackLocale])
    }
  }, [isAuthorized, serverLocale, browserFallbackLocale])
}
