import * as Sentry from '@sentry/react'

import { SENTRY_ENV } from 'consts/envVars'

import { getInitServerAppVersion } from 'app/App/utils/getServerAppVersion'

const USER_GUIDING_WINDOW_ERROR_MESSAGES = [
  "Failed to execute 'getComputedStyle' on 'Window'",
  'Window.getComputedStyle: Argument 1 is not an object.',
]

type InitializeSentryProps = {
  dsn: string
  environment: string
}

export const initializeSentry = ({ dsn, environment }: InitializeSentryProps) =>
  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: true, maskAllInputs: true }),
    ],
    tracesSampleRate: 0.0,
    environment,
    normalizeDepth: 10,
    attachStacktrace: true,
    ignoreErrors: [
      /Network Error/i,
      /Fetch Error/i,
      ...USER_GUIDING_WINDOW_ERROR_MESSAGES,
    ],
    replaysOnErrorSampleRate: SENTRY_ENV === 'prod' ? 1 : 0,

    beforeSend: (event) => {
      // INFO: filter out UnhandledRejection errors that have no information
      // thanks to https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length == 1
      ) {
        const e = event.exception.values[0]

        if (
          e.type === 'UnhandledRejection' &&
          e.value &&
          e.value.startsWith(
            'Non-Error promise rejection captured with value: Object Not Found Matching Id',
          )
        ) {
          return null
        }
      }
      event.release = getInitServerAppVersion() ?? 'unknown'

      return event
    },
  })
