import i18next from 'i18next'
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import { AVAILABLE_LANGUAGES } from 'locales/consts'

import { camelCaseObjectKeys } from './utils/camelCaseObjectKeys'

export const requestInterceptor = (request: InternalAxiosRequestConfig) => {
  if (request.headers) {
    const isCiLanguageMode = i18next.language === AVAILABLE_LANGUAGES.cimode
    request.headers['Accept-Language'] = isCiLanguageMode
      ? AVAILABLE_LANGUAGES.en
      : i18next.language
  }

  if (window.Cypress) {
    request.headers['isCypressContext'] = true
  }

  return request
}

export const createResponseInterceptor =
  (withoutCamelisation?: boolean, withoutUnwrappingResponseData?: boolean) =>
  (response: AxiosResponse) => {
    if (withoutUnwrappingResponseData) {
      return response
    }

    const { data, headers } = response

    if (data instanceof ArrayBuffer || data instanceof Blob) {
      return { data, headers }
    }

    if (!withoutCamelisation && data instanceof Object && Object.keys(data)) {
      return camelCaseObjectKeys(data)
    }

    return data
  }
