import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef } from 'react'

import { usePrintoutVisibilityContext } from 'app/utils/print'
import { PRINTOUT_ID } from 'app/components/printoutUtils/PrintoutIfElse'

type UsePrintProps = {
  documentTitle?: string
}

export const usePrint = ({ documentTitle }: UsePrintProps) => {
  const visibilityPromiseResolveRef = useRef<((value?: any) => void) | null>(
    null,
  )

  const {
    isPrintoutVisible,
    on: turnOnPrintoutVisibility,
    off: turnOffPrintoutVisibility,
  } = usePrintoutVisibilityContext()

  const handleBeforeprint = () => turnOnPrintoutVisibility()
  const handleAfterprint = () => turnOffPrintoutVisibility()

  useEffect(() => {
    window.addEventListener('beforeprint', handleBeforeprint)
    window.addEventListener('afterprint', handleAfterprint)

    return () => {
      window.removeEventListener('beforeprint', handleBeforeprint)
      window.removeEventListener('afterprint', handleAfterprint)
    }
  })

  useEffect(() => {
    if (isPrintoutVisible && visibilityPromiseResolveRef.current) {
      // INFO: Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      // INFO2: Without setTimeout some components (like accordion) are not rendered correctly
      setTimeout(
        () => {
          if (!visibilityPromiseResolveRef.current)
            throw new Error('visibilityPromiseResolveRef should be defined')

          visibilityPromiseResolveRef.current()
        },
        500, // INFO: the timeout value is taken from the react-to-print where they have the same problem of some content not being rendered
      )
    }
  }, [isPrintoutVisible, visibilityPromiseResolveRef])

  const handlePrint = useReactToPrint({
    content: () => document.getElementById(PRINTOUT_ID),
    documentTitle,
    removeAfterPrint: true, // Problem - every time handlePrint is called, additional styles are appended to the existing iframe,  leading to a buildup of duplicate styles.  Solution -  remove the iframe after print
    pageStyle: '@page { margin: 40px }',
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        turnOnPrintoutVisibility()
        visibilityPromiseResolveRef.current = resolve
      }),
    onAfterPrint: () => {
      turnOffPrintoutVisibility()
      visibilityPromiseResolveRef.current = null
    },
    onPrintError: (error) => {
      console.error('print error', { error })
    },
  })

  return { handlePrint }
}
