import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Tag } from 'app/components/tags'

import { CounterWrapper } from '../ScannerButton/styles'
import { useScannerButton } from '../ScannerButton/useScannerButton'

import type { ScannerButtonContentProps } from './types'

const messages = translations.pages.customer.home.quickAccessButtons

export const ScannerButtonContent = ({
  contentType,
}: ScannerButtonContentProps) => {
  const { t } = useTranslation()
  const { hasScanningListItems, numberOfScanningListItems } = useScannerButton()

  if (contentType === 'full') {
    return (
      <Flex gap="8px" alignItems="center">
        {t(messages.scanner)}
        {hasScanningListItems && (
          <Tag size="sm" type="accent" data-testid="scanningListItemsCounter">
            {numberOfScanningListItems}
          </Tag>
        )}
      </Flex>
    )
  }

  return (
    <>
      {hasScanningListItems && (
        <CounterWrapper data-testid="scanningListItemsCounter">
          {numberOfScanningListItems}
        </CounterWrapper>
      )}
    </>
  )
}
