import { Trans } from 'react-i18next'

import { translations } from 'locales/translations'

import { SemiBoldSpan } from 'app/components/spans'

import type { NotificationType } from '../CartItemToast'

const transComponents = [<SemiBoldSpan />]

const messages = translations.toasts.cartItem

type ToastMessageProps = {
  type: NotificationType
  quantity?: number
}

export const ToastMessage = ({ type, quantity }: ToastMessageProps) => {
  switch (type) {
    case 'added':
      return (
        <Trans
          i18nKey={messages.addedWithUnit}
          values={{ count: quantity }}
          components={transComponents}
        />
      )
    case 'replicated':
      return (
        <Trans
          i18nKey={messages.addedPositionWithUnit}
          values={{ count: quantity }}
          components={transComponents}
          count={quantity}
        />
      )
    case 'removed':
      return (
        <Trans
          i18nKey={messages.articleRemoved}
          components={transComponents}
          count={quantity}
        />
      )
    case 'positionRemoved':
      return (
        <Trans
          i18nKey={messages.positionRemoved}
          components={transComponents}
          count={quantity}
        />
      )
    case 'updated':
      return (
        <Trans
          i18nKey={messages.updatedWithUnit}
          values={{ count: quantity }}
          components={transComponents}
        />
      )
    default:
      return ''
  }
}
