import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const FastOrderListItemWrapper = styled(Flex)(
  ({ theme: { mediaQuery } }) => ({
    width: '100%',
    alignItems: 'center',
    padding: '12px 20px',
    gap: '16px',
    justifyContent: 'space-between',

    [mediaQuery.small]: {
      flexDirection: 'column',
      padding: '12px 16px',
      alignItems: 'initial',
    },
  }),
)
