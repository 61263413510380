import isBase64 from 'is-base64'

import type { MemoizedImageProps } from './types'

const buildPath = (location: URL) => location.host + location.pathname

export const getShouldRerender = (
  prevProps: MemoizedImageProps,
  nextProps: MemoizedImageProps,
) => {
  const { src: prevSrc, ...prevPropsRest } = prevProps
  const { src: nextSrc, ...nextPropsRest } = nextProps

  const areOtherPropsEqual = Object.keys(prevPropsRest).every((key) => {
    const typedKey = key as keyof typeof prevPropsRest

    return (
      nextPropsRest.hasOwnProperty(typedKey) &&
      prevPropsRest[typedKey] === nextPropsRest[typedKey]
    )
  })

  if (!areOtherPropsEqual) return true

  if (prevSrc && nextSrc) {
    if (isBase64(prevSrc) || isBase64(nextSrc)) return prevSrc !== nextSrc

    const prevPath = buildPath(new URL(prevSrc))
    const nextPath = buildPath(new URL(nextSrc))

    const hasSrcChanged = prevPath !== nextPath

    return hasSrcChanged
  }

  return true
}
