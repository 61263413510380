import {
  UndefinedParameterError,
  NullParameterError,
  NullishParameterError,
} from 'app/errors'

type NotUndefined<T> = T extends undefined ? never : T
type NotNull<T> = T extends null ? never : T

export function throwIfAbsent<T>(value: T): asserts value is NotUndefined<T> {
  if (typeof value === 'undefined') throw new UndefinedParameterError()
}

export function throwIfNull<T>(value: T): asserts value is NotNull<T> {
  if (value === null) throw new NullParameterError()
}

export function throwIfNullish<T>(value: T): asserts value is NonNullable<T> {
  if (typeof value === 'undefined' || value === null)
    throw new NullishParameterError()
}
