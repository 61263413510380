import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'

import type { CloseModal } from '../types'
import { Modal } from '../Modal'
import { ModalButton } from '../ModalButton'

const { cancel } = translations.modal

export type ConfirmationActionModalResult = {
  isActionConfirmed: boolean
}

export const buildConfirmModalId = (id: string) => `confirmAction-${id}`

export const showConfirmActionModal = (
  id: string,
  params?: Record<string, unknown>,
) =>
  NiceModal.show<ConfirmationActionModalResult>(buildConfirmModalId(id), params)

export type ConfirmActionModalProps = {
  id: string
  headerText: string
  children: React.ReactNode
  renderConfirmButton: (closeModal: () => void) => React.ReactNode
  contentUnderText?: React.ReactNode
  cancelText?: string
  onCloseCallback?: () => void
  hasCancelButton?: boolean
}

export const ConfirmActionModal = ({
  id,
  headerText,
  children,
  renderConfirmButton,
  contentUnderText,
  cancelText,
  onCloseCallback,
  hasCancelButton = true,
}: ConfirmActionModalProps) => {
  const { t } = useTranslation()
  const modalId = buildConfirmModalId(id)

  const onCloseModal = ({
    isActionConfirmed,
    closeModal,
  }: ConfirmationActionModalResult & { closeModal: CloseModal }) => {
    const result: ConfirmationActionModalResult = { isActionConfirmed }

    closeModal(result)
    onCloseCallback?.()
  }

  return (
    <Modal
      id={modalId}
      header={headerText}
      isWithoutHeaderBorder
      footer={(closeModal) => (
        <>
          {hasCancelButton && (
            <ModalButton
              variant="secondary"
              onClick={() =>
                onCloseModal({ isActionConfirmed: false, closeModal })
              }
            >
              {cancelText ?? t(cancel)}
            </ModalButton>
          )}

          {renderConfirmButton(() =>
            onCloseModal({ isActionConfirmed: true, closeModal }),
          )}
        </>
      )}
    >
      <Typography whiteSpace="pre-line" textStyle="textParagraph">
        {children}
      </Typography>
      {contentUnderText ?? null}
    </Modal>
  )
}
