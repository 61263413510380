import { useCallback, useEffect } from 'react'
import { useLocalStorage } from 'react-use'

import { showMobileScannerWelcomeModal } from './WelcomeModal'

export const DISPLAY_MOBILE_SCANNER_WELCOME_MODAL_LOCAL_STORAGE_KEY =
  'display-mobile-scanner-welcome-modal'

type UseWelcomeModalProps = {
  isScannerInitialized: boolean
  resumeScanning: () => void
  pauseScanning: () => void
}

export const useWelcomeModal = ({
  isScannerInitialized,
  resumeScanning,
  pauseScanning,
}: UseWelcomeModalProps) => {
  const [isFirstTimeUsingScanner, setIsFirstTimeUsingScanner] = useLocalStorage(
    DISPLAY_MOBILE_SCANNER_WELCOME_MODAL_LOCAL_STORAGE_KEY,
    true,
  )

  const handleShowWelcomeModal = useCallback(() => {
    pauseScanning()
    showMobileScannerWelcomeModal({
      onClose: () => {
        resumeScanning()
        setIsFirstTimeUsingScanner(false)
      },
    })
  }, [pauseScanning, setIsFirstTimeUsingScanner, resumeScanning])

  useEffect(() => {
    if (isFirstTimeUsingScanner && isScannerInitialized) {
      handleShowWelcomeModal()
    }
  }, [
    handleShowWelcomeModal,
    isFirstTimeUsingScanner,
    isScannerInitialized,
    pauseScanning,
  ])
}
