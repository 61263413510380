import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { api } from 'apiServices'

import { useOciSession } from 'app/containers/common/Auth'
import type { MODEL__CompanyUser } from 'app/models/companyUser'

import { baseIdentitySelector } from '../../Auth/selectors'
import { userMeScope } from '../User'
import type { MODEL__User } from '../../../../models/user'

export const url = userMeScope

// INFO: difference between Me and Me/CompanyUser is that Me is using api without namespace,
// while Me/CompanyUser is using api with namespace in the requests (customers/vendors/backoffice)
export const companyUserMeRequest = () =>
  api.get<unknown, MODEL__CompanyUser>(url)

export const COMPANY_USER_ME_QUERY_KEY = 'companyUserMe'

export const useCompanyUserMeQuery = ({
  enabled = true,
}: Pick<UseQueryOptions<MODEL__User>, 'enabled'> = {}) => {
  const { selectedCompanyId } = useSelector(baseIdentitySelector)
  const { isOciSessionFlow } = useOciSession()

  return useQuery({
    queryKey: [COMPANY_USER_ME_QUERY_KEY, { companyId: selectedCompanyId }],
    queryFn: companyUserMeRequest,
    enabled: enabled && !isOciSessionFlow && !!selectedCompanyId,
    refetchOnWindowFocus: false, // INFO: this data doesn't change much
    staleTime: 3 * 1000,
  })
}
