import type { ReactNode } from 'react'

import type { ArticleDisplayModeContextType } from './context'
import { ArticleDisplayModeContext } from './context'

export type ArticleDisplayModeProviderProps = ArticleDisplayModeContextType & {
  children: ReactNode
}

/**
 * Use this provider if you want to change the display of an article information based on the `isListView` property.
 *
 * You can use the hook `useArticleDisplayMode` to read this value in child components.
 *
 */
export const ArticleDisplayModeProvider = ({
  isListView,
  children,
}: ArticleDisplayModeProviderProps) => (
  <ArticleDisplayModeContext.Provider value={{ isListView }}>
    {children}
  </ArticleDisplayModeContext.Provider>
)
