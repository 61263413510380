import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useCurrentUser } from 'app/containers/common/Auth'

import { cartEssentialDataRelatedQuerySettings } from '../consts'

export const CURRENT_CART_EXCEEEDED_BUDGETS_QUERY_KEY =
  'CURRENT_CART_EXCEEEDED_BUDGETS_QUERY_KEY'

export const useCurrentCartExceededBudgetsQuery = () => {
  const { isOciCustomer } = useCurrentUser()

  return useQuery({
    queryKey: [CURRENT_CART_EXCEEEDED_BUDGETS_QUERY_KEY],
    queryFn: () =>
      generatedApi.customers.getShoppingCartExceededBudgets.request(),
    ...cartEssentialDataRelatedQuerySettings,
    enabled: !isOciCustomer,
  })
}
