import { useParams } from 'react-router'

import { generatedApi } from 'apiServices'

import { useCurrentUser } from 'app/containers/common/Auth'
import { useCustomerFilterQueryParam } from 'app/pages/common/marketplaceAndSearch/useCustomerFilterQueryParam'
import { useCatalogVersionId } from 'app/pages/common/marketplaceAndSearch/useCatalogVersionId'

export const ARTICLE_DETAILS_QUERY_KEY = 'articleDetails'

export const useArticleDetailsRequestFactory = ({
  articleId,
}: {
  articleId?: string
}) => {
  const { articleId: articleIdFromUrl } = useParams<{ articleId: string }>()

  const articleIdWithFallback = articleId ?? articleIdFromUrl

  const { customerId } = useCustomerFilterQueryParam()
  const { catalogVersionId } = useCatalogVersionId()
  const { userRole } = useCurrentUser()

  const articleDetailsRequest = () => {
    if (
      userRole !== 'customer' &&
      userRole !== 'ociCustomer' &&
      userRole !== 'vendor'
    ) {
      return Promise.reject(new Error('incorrect role'))
    }

    if (userRole === 'vendor') {
      if (!catalogVersionId)
        return Promise.reject(new Error('catalogVersionId is not defined'))

      return generatedApi.vendors.previewArticleDetail.request({
        pathAndQueryParams: {
          catalogVersionId,
          customerId,
          articleId: articleIdWithFallback,
        },
      })
    }

    if (userRole === 'ociCustomer') {
      return generatedApi.customerOciSession.customerOciArticleDetail.request({
        articleId: articleIdWithFallback,
      })
    }

    return generatedApi.customers.articleDetail.request({
      articleId: articleIdWithFallback,
    })
  }

  return {
    articleDetailsRequest,
    articleDetailsQueryKey: [
      ARTICLE_DETAILS_QUERY_KEY,
      {
        articleId: articleIdWithFallback,
        customerId,
        catalogVersionId,
        userRole,
      },
    ],
  }
}
