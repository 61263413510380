import type { ReactElement } from 'react'
import { useCallback, useRef, useLayoutEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { usePrevious } from 'react-use'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

export type SetRefs = (node: HTMLParagraphElement | null) => void

export type ScrollToActiveElementWrapperProps = {
  isEnabled?: boolean
  isExpanded: boolean
  children: (props: { setRefs: SetRefs }) => ReactElement
}

export const ScrollToActiveElementWrapper = ({
  isEnabled,
  isExpanded,
  children,
}: ScrollToActiveElementWrapperProps) => {
  const trackedElementRef = useRef<HTMLElement | null>(null)

  const { ref: inViewRefCallback, inView } = useInView({
    skip: !isEnabled,
  })

  const wasPreviouslyExpanded = usePrevious(isExpanded)
  const hasOpened = !wasPreviouslyExpanded && !!isExpanded

  const { isMobile } = useBreakpointScreenSize()

  // INFO: it scrolls to the open accordion only if the accordion is not in the viewport
  useLayoutEffect(() => {
    if (hasOpened && !inView) {
      trackedElementRef.current?.scrollIntoView?.({
        block: isMobile ? 'start' : 'center',
      })
    }
  }, [hasOpened, inView, isMobile])

  const setRefs = useCallback(
    (node: HTMLElement | null) => {
      trackedElementRef.current = node

      // INFO: Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRefCallback(node)
    },
    [inViewRefCallback, trackedElementRef],
  )

  return children({ setRefs })
}
