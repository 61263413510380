import { Box, HStack, Stack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { useFormatArticleData } from 'app/hooks/useFormatArticleData'
import { AddToCartButton } from 'app/pages/common/marketplaceAndSearch'
import { formatMonetaryAmount } from 'app/utils/formatMoney'

import { PriceAndActionsWrapper, SmallText } from './styles'
import type { PriceAndActionsCellProps } from './types'
import { useArticleActions } from './useArticleActions'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details

// INFO: for some reason the buttons of this cell are not rerendered
// when an article is deleted. This could be due to the hooks inside
// `PriceAndActionsCell`. For now, adding key as it helps and linking
// a similar issue.
// https://github.com/TanStack/table/issues/1196
export const PriceAndActionsCell = ({
  articleListId,
  articleListDetailsItem,
  articleListDetailsItem: { articleData },
  accessLevel,
}: PriceAndActionsCellProps) => (
  <PriceAndActionsComponent
    key={articleData.article.id}
    articleListId={articleListId}
    articleListDetailsItem={articleListDetailsItem}
    accessLevel={accessLevel}
  />
)

const PriceAndActionsComponent = ({
  articleListDetailsItem: { quantity, available, priceUsed, articleData },
  articleListDetailsItem,
  articleListId,
  accessLevel,
}: PriceAndActionsCellProps) => {
  const {
    article,
    article: { id: articleId },
  } = articleData
  const { t } = useTranslation()
  const { basicPackagingInfo, minimalOrderInfo } = useFormatArticleData(article)

  const { deleteButtonRendered, amountInputRendered } = useArticleActions({
    articleListDetailsItem,
    articleListId,
    accessLevel,
  })

  if (!available)
    return (
      <PriceAndActionsWrapper>
        <Typography data-testid="articleNotAvailableInfo">
          {t(messages.articleNotAvailable)}
        </Typography>

        {deleteButtonRendered}
      </PriceAndActionsWrapper>
    )

  return (
    <PriceAndActionsWrapper data-testid="priceAndActionsContainer">
      <Stack spacing="4px" justify="center" align="flex-end" minW={0}>
        <Typography textStyle="headerH4">
          {formatMonetaryAmount(priceUsed)}
        </Typography>

        <SmallText>{basicPackagingInfo}</SmallText>

        <SmallText>{minimalOrderInfo}</SmallText>
      </Stack>

      <Stack overflow="hidden" w="172px" minW="172px" justifyContent="stretch">
        <HStack w="100%">
          <Box minW="128px" w="100%">
            {amountInputRendered}
          </Box>

          {deleteButtonRendered}
        </HStack>

        <AddToCartButton
          article={article}
          size="sm"
          displayOnlyButton
          customAddToCartRequestBody={[
            { articleId, changeQuantityBy: quantity },
          ]}
        />
      </Stack>
    </PriceAndActionsWrapper>
  )
}
