import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useOciSession } from 'app/containers/common/Auth'

import {
  updateUserMeSettingsRequest,
  updateOciCustomerMeSettingsRequest,
} from '../api'
import { USER_ME_QUERY_KEY } from '../consts'

export const useUpdateUserMeSettingsMutation = () => {
  const queryClient = useQueryClient()
  const { isOciSessionFlow } = useOciSession()
  const request = isOciSessionFlow
    ? updateOciCustomerMeSettingsRequest
    : updateUserMeSettingsRequest

  return useMutation({
    mutationFn: request,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USER_ME_QUERY_KEY] })
    },
  })
}
