import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useIsMutating } from '@tanstack/react-query'

import { translations } from 'locales/translations'

import { Button } from 'app/components/Buttons/Button'
import { CartIcon, TrashIcon } from 'app/components/icons'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { useFastOrderListBulkActions } from './useFastOrderListBulkActions'

const deleteButtonMessage = translations.common.table.actions.delete.label
const addToCartButtonMessage =
  translations.pages.customer.search.searchArticle.addToBasket

export const FastOrderListBulkActions = () => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  const { onDeleteHandler, isDeleting, onMoveToCartHandler, isMovingToCart } =
    useFastOrderListBulkActions()

  const isMutatingPosts = useIsMutating({
    mutationKey: ['updateFastOrderListItem'],
  })

  return (
    <Flex gap="8px" justifyContent="flex-end">
      <Button
        onClick={onDeleteHandler}
        isLoading={isDeleting}
        isDisabled={isMutatingPosts > 0}
        dataTestId="masterDeleteButton"
        variant="secondary"
        size={isMobile ? 'squareSm' : 'sm'}
      >
        <Flex alignItems="center" gap="8px">
          <TrashIcon />
          {!isMobile && t(deleteButtonMessage)}
        </Flex>
      </Button>
      <Button
        leftIcon={<CartIcon />}
        onClick={onMoveToCartHandler}
        isLoading={isMovingToCart}
        isDisabled={isMutatingPosts > 0}
        dataTestId="masterMoveToCartButton"
        size="sm"
      >
        {t(addToCartButtonMessage)}
      </Button>
    </Flex>
  )
}
