import { Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { useTheme } from 'theme'

import type { TypographyProps } from './types'

export const Typography = styled(
  forwardRef<HTMLParagraphElement, TypographyProps>(
    ({ color, ...rest }: TypographyProps, ref) => {
      const { colors } = useTheme()

      return <Text color={color ?? colors.contentA} {...rest} ref={ref} />
    },
  ),
)(
  ({ theme: { textStyles }, textStyle = 'textNormal' }) =>
    textStyles[textStyle],
)
