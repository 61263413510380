import { generatePath, useHistory } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import type { BarcodesGenerationLocationState } from 'app/pages/customers/ArticleLists/GenerateBarcodes/useSelectedArticlesData'
import { useArticleListDetailsQuery } from 'app/containers/customers/ArticleLists/Show'

import { useSelectActionsContext } from '../../../context'

type UseBarcodeGenerationOptionProps = {
  articleListId: string
}

export const useBarcodeGenerationOption = ({
  articleListId,
}: UseBarcodeGenerationOptionProps) => {
  const history = useHistory()
  const { data } = useArticleListDetailsQuery()
  const { selectedArticlesIds } = useSelectActionsContext()

  const noArticlesOnList = Number(data?.articlesCount) === 0

  const generatedBarcodesCounter =
    selectedArticlesIds.length > 0 ? ` (${selectedArticlesIds.length})` : ''

  const onGenerateBarcodesClick = () => {
    const state: BarcodesGenerationLocationState =
      selectedArticlesIds.length === 0
        ? { wereAllArticlesSelected: true }
        : {
            articleIds: selectedArticlesIds,
          }

    history.push(
      generatePath(PATHS.customer.articleListGenerateBarcodes, {
        articleListId,
      }),
      state,
    )
  }

  return {
    noArticlesOnList,
    generatedBarcodesCounter,
    onGenerateBarcodesClick,
  }
}
