import { store } from 'store'

import { ociSessionIdSelector } from 'app/containers/common/Auth/selectors'
import type { UserRoles } from 'app/containers/common/Auth'
import type {
  CompanyId,
  MODEL__User,
  MODEL__UserCompanyUnion,
} from 'app/models/user'

type MatchingCompanyReturnType = {
  company: MODEL__UserCompanyUnion
  userRole: Extract<UserRoles, 'vendor' | 'customer' | 'ociCustomer'>
}

export const findMatchingCompany = (
  userData: MODEL__User,
  companyId: CompanyId | undefined,
): MatchingCompanyReturnType | undefined => {
  const state = store.getState()
  const ociSessionId = ociSessionIdSelector(state)

  const matchedCustomerCompany = userData.customers.find(
    ({ id }) => id === companyId,
  )

  if (!!matchedCustomerCompany)
    return {
      company: matchedCustomerCompany,
      userRole: ociSessionId ? 'ociCustomer' : 'customer',
    }

  const matchedVendorCompany = userData.vendors.find(
    ({ id }) => id === companyId,
  )

  if (!!matchedVendorCompany) {
    return { company: matchedVendorCompany, userRole: 'vendor' }
  }
}
