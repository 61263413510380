import { useTranslation } from 'react-i18next'
import NiceModal from '@ebay/nice-modal-react'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { Modal } from 'app/components/modals/Modal'
import { ModalButton } from 'app/components/modals/ModalButton'
import type { ModelOrderOptions } from 'app/containers/common/Search/types/response'
import type { ModalProps } from 'app/components/modals/types'

import { BulkPrices } from './BulkPrices'

export const BULK_PRICES_MODAL_ID = 'BulkPricesModal'
const messages = translations.pages.customer.articleDetails
const modalMessages = translations.modal

type BulkPricesModalProps = {
  articleName: string
  orderUnit: string
  pricing: NonNullable<ModelOrderOptions>['pricing'] | undefined
  modalProps?: Partial<ModalProps>
}

const BulkPricesModal = NiceModal.create<BulkPricesModalProps>((props) => {
  const { articleName, orderUnit, pricing, modalProps } = props
  const { t } = useTranslation()

  return (
    <Modal
      id={BULK_PRICES_MODAL_ID}
      header={t(messages.bulkPricesButton)}
      footer={(closeModal) => (
        <Flex justify="flex-end">
          <ModalButton variant="secondary" onClick={closeModal}>
            {t(modalMessages.close)}
          </ModalButton>
        </Flex>
      )}
      {...modalProps}
    >
      <Typography py="8px" textStyle="headerH4">
        {articleName}:
      </Typography>
      <BulkPrices orderUnit={orderUnit} pricing={pricing} />
    </Modal>
  )
})

NiceModal.register(BULK_PRICES_MODAL_ID, BulkPricesModal)

export const showBulkPricesModal = (params: BulkPricesModalProps) => {
  NiceModal.show(BULK_PRICES_MODAL_ID, params)
}
