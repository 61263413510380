import { useMutation } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useInvalidateArticleRelatedQueries } from 'app/containers/customers/articleRelated/hooks'

export const useUpdateCustomerArticleNumberMutation = () => {
  const invalidateArticleRelatedQueries = useInvalidateArticleRelatedQueries()

  return useMutation({
    mutationFn: generatedApi.customers.updateCustomerArticleNumber.request,
    onSuccess: invalidateArticleRelatedQueries,
  })
}
