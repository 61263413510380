import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import { translations } from 'locales/translations'

import { ModalWithFooter } from 'app/components/modals/ModalWithFooter'
import { preventSubmitOnEnter } from 'app/components/Form/utils'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useGetArticleDetailsQuery } from 'app/containers/common/ArticleDetails'

import { ArticleContent } from '../ArticleContent'
import { useShouldShowArticleDetails } from '../useShouldShowArticleDetails'
import {
  type ExternalReferencesFormModalProps,
  showEditArticleModal,
  EXTERNAL_REFERENCES_FORM_MODAL_ID,
} from '../modalFlowActions'

import { useForm } from './useForm'
import { BarcodeField, QuantityField } from './components'

const messages =
  translations.pages.customer.editArticleDetailsModal.externalReferences.form

export const ExternalReferencesFormModal =
  NiceModal.create<ExternalReferencesFormModalProps>(
    ({ articleId, externalReference }) => {
      const { t } = useTranslation()
      const { isMobile } = useBreakpointScreenSize()

      const { data: articleData } = useGetArticleDetailsQuery({ articleId })

      const {
        validationSchema,
        initialValues,
        onSubmitHandler,
        isPending,
        isEditMode,
      } = useForm({ articleId, externalReference })

      const shouldShowArticleDetails = useShouldShowArticleDetails()

      return (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
        >
          {({ resetForm, isValid, dirty, values }) => (
            <ModalWithFooter
              id={EXTERNAL_REFERENCES_FORM_MODAL_ID}
              shouldWrapWithForm
              isFullScreen={isMobile}
              isWithoutHeaderBorder={isMobile}
              onKeyDown={preventSubmitOnEnter}
              isSaveButtonDisabled={!isValid || !dirty}
              isSaveButtonLoading={isPending}
              handleClose={() => resetForm()}
              onCancelHandler={(closeModal) => {
                resetForm()
                closeModal()
                showEditArticleModal({ articleId })
              }}
              onCloseComplete={() => {
                resetForm()
                showEditArticleModal({ articleId })
              }}
              header={isEditMode ? t(messages.editTitle) : t(messages.addTitle)}
              saveTranslation={
                isEditMode
                  ? t(translations.formActions.save)
                  : t(translations.formActions.add)
              }
            >
              {shouldShowArticleDetails && articleData && (
                <ArticleContent {...articleData} />
              )}
              <BarcodeField isEditMode={isEditMode} />
              <QuantityField articleId={articleId} />
            </ModalWithFooter>
          )}
        </Formik>
      )
    },
  )

NiceModal.register(
  EXTERNAL_REFERENCES_FORM_MODAL_ID,
  ExternalReferencesFormModal,
)
