import type { IconProps } from '../types'

export const CompanyIconB = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6 6.23519C6 5.79781 6.28425 5.41117 6.70173 5.28071L13.3509 3.20286C13.6728 3.10224 14 3.34278 14 3.6801V18H6V6.23519Z"
      fill={color}
    />
    <path
      d="M2 10C2 9.44772 2.44772 9 3 9H4C4.55228 9 5 9.44772 5 10V18H3C2.44772 18 2 17.5523 2 17V10Z"
      fill={color}
    />
    <rect
      x="13.5"
      y="8.5"
      width="5"
      height="10"
      rx="1.5"
      fill={color}
      stroke="#FBFBFB"
    />
    <path
      d="M9 14.5C9 14.2239 9.22386 14 9.5 14H10.5C10.7761 14 11 14.2239 11 14.5V18H9V14.5Z"
      fill="#FBFBFB"
    />
    <rect x="8" y="10" width="1" height="2" rx="0.5" fill="#FBFBFB" />
    <rect x="8" y="7" width="1" height="2" rx="0.5" fill="#FBFBFB" />
    <rect x="11" y="10" width="1" height="2" rx="0.5" fill="#FBFBFB" />
    <rect x="11" y="7" width="1" height="2" rx="0.5" fill="#FBFBFB" />
  </svg>
)
