import type { IconProps } from '../types'

export const EmptySearchIcon = ({
  width = '96px',
  height = '96px',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M62 61L82 81"
      stroke="#276EF1"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <path
      d="M61.7233 60.7233C51.0347 71.4119 33.705 71.4119 23.0164 60.7233C12.3279 50.0347 12.3279 32.705 23.0164 22.0164C33.705 11.3279 51.0347 11.3279 61.7233 22.0164C72.4119 32.705 72.4119 50.0347 61.7233 60.7233Z"
      fill="#E7EEFF"
      stroke="#B5CFFF"
      strokeWidth="7"
      strokeLinecap="round"
    />
    <circle cx="34.5" cy="33.5" r="3.5" fill="#276EF1" />
    <circle cx="49.5" cy="33.5" r="3.5" fill="#276EF1" />
    <path
      d="M34 51C35.1066 48.0961 38.2701 46 42 46C45.7299 46 48.8934 48.0961 50 51"
      stroke="#276EF1"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
)
