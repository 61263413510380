import { useTheme } from 'theme'

import { NOTIFICATION_TYPES } from './consts'
import type { NotificationType } from './types'

export const useColorMapping = (type: NotificationType) => {
  const { colors } = useTheme()

  return {
    [NOTIFICATION_TYPES.info]: {
      backgroundColor: colors.blue_100,
      actionBackgroundColor: colors.blue_200,
      actionHoverBackgroundColor: colors.blue_150,
      actionActiveBackgroundColor: colors.blue_300,
    },
    [NOTIFICATION_TYPES.success]: {
      backgroundColor: colors.green_100,
      actionBackgroundColor: colors.green_200,
      actionHoverBackgroundColor: colors.green_150,
      actionActiveBackgroundColor: colors.green_300,
    },
    [NOTIFICATION_TYPES.error]: {
      backgroundColor: colors.red_100,
      actionBackgroundColor: colors.red_200,
      actionHoverBackgroundColor: colors.red_150,
      actionActiveBackgroundColor: colors.red_300,
    },
    [NOTIFICATION_TYPES.warning]: {
      backgroundColor: colors.yellow_100,
      actionBackgroundColor: colors.yellow_300,
      actionHoverBackgroundColor: colors.yellow_200,
      actionActiveBackgroundColor: colors.yellow_400,
    },
  }[type]
}
