import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import type { MODEL__ResourceItemAssignedUsersInfo } from 'app/models/resourceAssignment'

type UseResourceItemAssignedUsersTextArgs = {
  isAssignedToAllRegularUsers?: MODEL__ResourceItemAssignedUsersInfo['allCustomerUsersAssigned']
  isAssignedToAllOciUsers?: MODEL__ResourceItemAssignedUsersInfo['allOciUsersAssigned']
  isAssignedToGroups?: MODEL__ResourceItemAssignedUsersInfo['userGroupsAssigned']
  assignedUsersCount:
    | MODEL__ResourceItemAssignedUsersInfo['assignedUsersCount']
    | null
}

const messages = translations.common.usersAssignment.assignmentButtonLabels

export const useResourceItemAssignedUsersText = ({
  isAssignedToAllRegularUsers,
  isAssignedToAllOciUsers,
  isAssignedToGroups,
  assignedUsersCount,
}: UseResourceItemAssignedUsersTextArgs) => {
  const { t } = useTranslation()

  if (
    (isAssignedToAllRegularUsers && isAssignedToAllOciUsers) ||
    (isAssignedToAllRegularUsers && isAssignedToAllOciUsers === null)
  )
    return t(messages.all)

  if (isAssignedToAllRegularUsers) return t(messages.allRegularUsers)

  if (isAssignedToAllOciUsers) return t(messages.allOciUsers)

  if (isAssignedToGroups) return t(messages.groups)

  return assignedUsersCount
}
