import { Flex } from '@chakra-ui/react'

import { BareCheckbox } from 'app/components/inputs/Checkbox'
import { useSelectActionsContext } from 'app/pages/customers/ArticleLists/ArticleListDetails/context'

import type { ArticleListDetailItemCellType } from './types'

export const SelectArticleCheckbox = ({
  value,
}: ArticleListDetailItemCellType) => {
  const { checkIfSingleCheckboxSelected, makeSingleCheckboxHandler } =
    useSelectActionsContext()

  const {
    available: isAvailable,
    articleData: { article },
  } = value

  const isRestricted = article.orderOptions?.restricted
  const isAvailableToAddToCart = isAvailable && !isRestricted

  return (
    <Flex w="20px" align="center" mr="14px">
      {isAvailableToAddToCart && (
        <BareCheckbox
          customAriaLabel="toggle single item selection"
          isChecked={checkIfSingleCheckboxSelected(article.id)}
          onChange={makeSingleCheckboxHandler(value)}
          dataTestId="articleListItemCheckbox"
        />
      )}
    </Flex>
  )
}
