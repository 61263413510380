import type { UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { isCompanyUserSelector } from '../Auth/selectors'

import {
  getProfileRequest,
  updateProfileRequest,
  getContactsRequest,
  updateContactsRequest,
} from './api'
import type { VendorContactTypes, CustomerContactTypes } from './contacts.types'

export const PROFILE_QUERY_KEY = 'profile'
export const CONTACTS_QUERY_KEY = 'contacts'

export const useBaseProfileQueryOptions = () => {
  const isCompanyUser = useSelector(isCompanyUserSelector)

  return {
    queryKey: [PROFILE_QUERY_KEY],
    queryFn: getProfileRequest,
    enabled: isCompanyUser,
  } satisfies UseQueryOptions
}

// profile
export const useProfileQuery = () => {
  const baseProfileQueryOptions = useBaseProfileQueryOptions()

  return useQuery(baseProfileQueryOptions)
}

export const useUpdateProfileMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateProfileRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROFILE_QUERY_KEY] })
    },
  })
}

// contacts
export const useProfileContactsQuery = () =>
  useQuery({ queryKey: [CONTACTS_QUERY_KEY], queryFn: getContactsRequest })

export const useVendorProfileContactsQuery = () =>
  useQuery({
    queryKey: [CONTACTS_QUERY_KEY, 'vendor'],
    queryFn: getContactsRequest,
    select: (data) => data as VendorContactTypes,
  })

export const useCustomerProfileContactsQuery = () =>
  useQuery({
    queryKey: [CONTACTS_QUERY_KEY, 'customer'],
    queryFn: getContactsRequest,
    select: (data) => data as CustomerContactTypes,
  })

export const useUpdateContactsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateContactsRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEY] })
    },
  })
}
