/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { ErrorBoundary } from 'app/components/ErrorBoundary'
import { ScrollToTop } from 'app/components/ScrollToTop'

import { AppRoutes } from './routes/AppRoutes'
import { useReloadIfAppVersionChanged, useSetYupLocale } from './utils'
import { UserGuidingHtmlTag } from './UserGuidingHtmlTag'
import './globalModals/registerModals'

export const App = () => {
  const { t } = useTranslation()
  useSetYupLocale()
  useReloadIfAppVersionChanged()

  return (
    <>
      <Helmet
        titleTemplate={`%s - ${t(translations.appTitle)}`}
        defaultTitle={t(translations.appTitle)}
      >
        <meta name="description" content={t(translations.appDescription)} />
      </Helmet>

      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>

      <UserGuidingHtmlTag />
      <ScrollToTop />
    </>
  )
}
