import { Api } from 'libs/openapiGeneratedApi/Api'
import { getConfiguredAxiosInstance } from 'libs/request'

export const publicApi = getConfiguredAxiosInstance()

export const generatedPublicApi = new Api()

getConfiguredAxiosInstance({
  axiosInstance: generatedPublicApi.instance,
  withoutUnwrappingResponseData: true, // INFO: Generated api do the unpacking of response data by itself
})
