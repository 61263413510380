import { useParams } from 'react-router-dom'

import type { ArticleListId } from './types'

export const useArticleListId = () => {
  const params = useParams<{ articleListId?: ArticleListId }>()

  if (!params.articleListId) throw new Error('articleListId is undefined')

  return params.articleListId
}
