import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'
import { throwIfAbsent } from 'types/utils'

import { useCurrentUser } from 'app/containers/common/Auth'

const VENDOR_MARKETPLACE_PREVIEW_CUSTOMERS_WITH_CURRENCY_LIST_QUERY_KEY =
  'vendorMarketplacePreviewCustomersWithCurrencyList'

type UseMarketplacePreviewCustomersWithCurrencyListQueryProps = {
  currencyCode: string | undefined
  enabled?: boolean
}

export const useMarketplacePreviewCustomersWithCurrencyListQuery = ({
  currencyCode,
  enabled,
}: UseMarketplacePreviewCustomersWithCurrencyListQueryProps) => {
  const { isVendor } = useCurrentUser()

  const queryFn = () => {
    throwIfAbsent(currencyCode)

    return generatedApi.vendors.getVendorMarketplaceCustomersWithCurrency.request(
      { currencyCode },
    )
  }

  return useQuery({
    queryKey: [
      VENDOR_MARKETPLACE_PREVIEW_CUSTOMERS_WITH_CURRENCY_LIST_QUERY_KEY,
      currencyCode,
    ],
    queryFn,
    enabled: enabled && isVendor && !!currencyCode,
    staleTime: 5000,
  })
}
