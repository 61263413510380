import { Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { ImageWithFallback } from 'app/components/ImageWithFallback'
import { ArticleLinkWrapper } from 'app/pages/common/marketplaceAndSearch/components/ArticleLinkWrapper'
import { DotSeparator } from 'app/components/DotSeparator/DotSeparator'
import { Typography } from 'app/components/Typography'
import { SoldByText } from 'app/pages/common/marketplaceAndSearch/components/ArticleTexts'
import { useCanEditArticle } from 'app/pages/common/editArticleModalFlow/EditArticleModal/useCanEditArticle'

import type { ArticleListDetailItemCellType } from '../types'

import { ArticleListItemWrapper } from './styles'
import { ArticleContent } from './components'
import { EditArticleIcon } from './components/EditArticleIcon'

export const ArticleListItemCell = ({
  value,
}: ArticleListDetailItemCellType) => {
  const { colors } = useTheme()

  const {
    available: isAvailable,
    articleData: { article, vendor, externalReferences },
  } = value

  const canEditArticle = useCanEditArticle()

  const shouldRenderEditIcon = isAvailable && canEditArticle

  return (
    <ArticleListItemWrapper isAvailable={isAvailable}>
      <ArticleLinkWrapper articleId={article.id}>
        <ImageWithFallback
          src={article.thumbnailUrl}
          alt={article.name}
          size={72}
        />
      </ArticleLinkWrapper>
      <Flex gap="12px" direction="column">
        <Flex direction="column" gap="8px">
          <Flex gap="8px" alignItems="center">
            <Typography textStyle="textSmallSb" color="contentB" gap="4px">
              {article.manufacturer}

              <DotSeparator color={colors.contentOff} />

              <SoldByText vendorName={vendor.name} />
            </Typography>
            {shouldRenderEditIcon && <EditArticleIcon article={article} />}
          </Flex>

          <ArticleLinkWrapper articleId={article.id}>
            <Typography textStyle="inputNormalSb">{article.name}</Typography>
          </ArticleLinkWrapper>
        </Flex>

        <ArticleContent
          article={article}
          externalReferences={externalReferences}
        />
      </Flex>
    </ArticleListItemWrapper>
  )
}
