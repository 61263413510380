import { Fragment, type ReactElement } from 'react'
import { Form } from 'formik'

import type { ModalProps } from '../../types'

type FormWrapperProps = Pick<
  ModalProps,
  'shouldWrapWithForm' | 'onKeyDown' | 'isFullScreen'
> & {
  children: ReactElement
}
export const FormWrapper = ({
  shouldWrapWithForm,
  isFullScreen,
  onKeyDown,
  children,
}: FormWrapperProps) =>
  shouldWrapWithForm ? (
    <Form
      onKeyDown={onKeyDown}
      style={{ height: isFullScreen ? '100vh' : 'auto' }}
    >
      {children}
    </Form>
  ) : (
    <Fragment>{children}</Fragment>
  )
