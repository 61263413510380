import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const BulkSelectionAndActionsWrapper = styled(Flex)(
  ({ theme: { mediaQuery } }) => ({
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    minHeight: '36px',

    [mediaQuery.small]: {
      padding: '0',
    },
  }),
)
