import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { ModelGetCustomerAssignedAccountTypesAndValuesData } from 'libs/openapiGeneratedApi/Api'

import { getCustomerMockUrl, getOciCustomerMockUrl } from '../../utils'

export const assignedAccountTypesAndValuesListMockData: ModelGetCustomerAssignedAccountTypesAndValuesData =
  {
    accountTypes: [
      {
        id: 'bb50273a-5200-44d4-80e1-3a150d1b4149',
        prefix: 'KHYFRFRFRREDED',
        description: 'Marketing',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-3a150d1b414c',
        prefix: 'HK',
        description: 'Expenses',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-3a150d1b414d',
        prefix: 'DDY',
        description: 'Finances',
      },
      {
        id: 'db50273a-5200-44d4-80e1-3a150d1b414d',
        prefix: 'EEE',
        description: 'Solo',
      },
    ],
    accountValuesByType: {
      ['bb50273a-5200-44d4-80e1-3a150d1b4149']: [
        {
          id: 'bb50273a-5200-44d4-80e1-3a150d1bfwef',
          number: 'xxx',
          description: 'Value d 1',
        },
        {
          id: 'cb50273a-5200-44d4-80e1-3a15ad1b414c',
          number: 'yyy',
          description: 'Value description description 13',
        },
        {
          id: 'cb50273a-5200-44d4-80e1-3a950d1b414e',
          number: 'qqq',
          description:
            'Value description description description description4 lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
        },
      ],
      ['cb50273a-5200-44d4-80e1-3a150d1b414c']: [
        {
          id: 'cb50273a-5200-44d4-80e1-3a14fd1b4133',
          number: '234',
          description: 'Value description description 2',
        },
      ],
      ['cb50273a-5200-44d4-80e1-3a150d1b414d']: [],
      ['db50273a-5200-44d4-80e1-3a150d1b414d']: [],
    },
  }

const { url } = generatedApi.customers.getCustomerAssignedAccountTypesAndValues

export const assignedAccountTypesAndValuesListGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json(assignedAccountTypesAndValuesListMockData),
)

export const ociAssignedAccountTypesAndValuesListGetMock = http.get(
  getOciCustomerMockUrl(url),
  () => HttpResponse.json(assignedAccountTypesAndValuesListMockData),
)

export const emptyAssignedAccountTypesAndValuesListGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json({ accountTypes: [], accountValuesByType: [] }),
)

export const makeAssignedAccountTypesAndValuesListGetMock = (
  mockData: ModelGetCustomerAssignedAccountTypesAndValuesData,
) => http.get(getCustomerMockUrl(url), () => HttpResponse.json(mockData))
