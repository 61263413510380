import type { CSSProperties } from 'react'
import type { GridProps } from '@chakra-ui/react'
import { Grid, ModalContent } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'
import { Panel } from 'app/components/Panel/Panel'
import type { PanelProps } from 'app/components/Panel/Panel'

import type { ModalContentStyledProps, ModalProps } from '../types'

const DEFAULT_DESKTOP_WIDTH = '576px'
const DEFAULT_DESKTOP_HEIGHT = '96vh'

const ModalContentContainer = styled.div<ModalContentStyledProps>(
  ({ theme: { mediaQuery }, isFullScreen }) => ({
    '.chakra-modal__content-container': {
      [mediaQuery.small]: {
        padding: isFullScreen ? '0px' : '0 8px',
      },
    },
  }),
)

export const ModalContentStyled = styled(
  ({ as, isFullScreen, ...rest }: ModalContentStyledProps) => (
    <ModalContentContainer isFullScreen={isFullScreen}>
      <ModalContent as={as} {...rest} />
    </ModalContentContainer>
  ),
)<
  Pick<CSSProperties, 'width' | 'maxHeight'> & Pick<ModalProps, 'isFullScreen'>
>(({ theme: { shadows, mediaQuery }, width, maxHeight, isFullScreen }) => ({
  width: width ?? DEFAULT_DESKTOP_WIDTH,
  maxWidth: width ?? DEFAULT_DESKTOP_WIDTH,
  maxHeight: maxHeight ?? DEFAULT_DESKTOP_HEIGHT,
  overflow: 'auto',
  boxShadow: shadows.primary,
  margin: '20px 0',

  [mediaQuery.small]: {
    width: '100%',
    maxWidth: width ?? '100%',
    margin: isFullScreen ? '0px' : '8px 0',
    height: isFullScreen ? '100dvh' : 'fit-content',
  },
}))

export const PanelStyled = ({
  contentPadding,
  isFullScreen,
  ...props
}: PanelProps & Pick<ModalProps, 'contentPadding' | 'isFullScreen'>) => (
  <Panel
    isWithoutBorder
    flex={1}
    pb="0px"
    padding={contentPadding}
    overflow="hidden"
    {...(isFullScreen && { height: '100dvh' })}
    {...props}
  />
)

export const FooterSectionWrapper = styled(ColumnWithGap)(
  ({ theme: { colors, mediaQuery } }) => ({
    paddingBottom: '20px',
    position: 'sticky',
    bottom: '0px',
    background: colors.backgroundA,
    flexGrow: 0,

    [mediaQuery.small]: {
      paddingBottom: '16px',
    },
  }),
)

export const ModalFooterWrapper = (props: GridProps) => (
  <Grid
    gridTemplateColumns="repeat(auto-fit, minmax(0, 1fr))"
    columnGap="20px"
    width="100%"
    {...props}
  />
)
