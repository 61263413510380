import { useMutation } from '@tanstack/react-query'

import { api } from 'apiServices'

import { currentCartScope } from '../consts'
import { useCartMutationOptions } from '../useCartMutationOptions'

const clearCartRequest = () => api.delete(currentCartScope)

export const useClearCartMutation = () => {
  const cartMutationOptions = useCartMutationOptions()

  return useMutation({ mutationFn: clearCartRequest, ...cartMutationOptions })
}
