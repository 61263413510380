import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { useDeleteFastOrderListItemsMutation } from 'app/containers/customers/FastOrders/Delete'
import { useGetShowSuccessToast } from 'app/components/Notification/useGetShowSuccessToast'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'

import { getFastOrderItemStatus } from '../../../utils'

const deleteUnavailableToastMessage =
  translations.pages.customer.scanner.toasts.deleteUnavailable.successMsg

export const useFastOrderResultsNotification = () => {
  const { t } = useTranslation()
  const { showSuccessToast } = useGetShowSuccessToast()
  const { fastOrderList, fastOrderListEnum } = useFastOrderContext()

  const { items } = fastOrderList ?? { items: [] }

  const { mutate: deleteFastOrderItems } = useDeleteFastOrderListItemsMutation({
    fastOrderListEnum,
  })

  const needsArticleSelection = Boolean(
    items.find(
      (item) =>
        getFastOrderItemStatus(item) === 'AWAITING_SELECTION_FROM_MULTIPLE',
    ),
  )
  const notFoundItemIds = items
    .filter((item) => getFastOrderItemStatus(item) === 'NOT_FOUND')
    .map(({ id }) => id)

  const notAvailableOrRestrictedItemIds = items
    .filter((item) => {
      const status = getFastOrderItemStatus(item)

      return status === 'NO_LONGER_AVAILABLE' || status === 'RESTRICTED'
    })
    .map(({ id }) => id)

  const showNotFoundItemsDeletedToast = () =>
    showSuccessToast({
      content: t(deleteUnavailableToastMessage, {
        count: notFoundItemIds.length,
      }),
    })

  const showNotAvailableOrRestrictedItemsDeletedToast = () =>
    showSuccessToast({
      content: t(deleteUnavailableToastMessage, {
        count: notAvailableOrRestrictedItemIds.length,
      }),
    })

  const handleNotFoundItemsDelete = () =>
    deleteFastOrderItems(
      { fastOrderListItemIds: notFoundItemIds },
      { onSuccess: showNotFoundItemsDeletedToast },
    )
  const handleNotAvailableOrRestrictedItemsDelete = () =>
    deleteFastOrderItems(
      { fastOrderListItemIds: notAvailableOrRestrictedItemIds },
      { onSuccess: showNotAvailableOrRestrictedItemsDeletedToast },
    )

  const shouldShowNoMatchingReferencesError = notFoundItemIds.length > 0
  const shouldShowNotAvailableOrRestrictedArticlesWarning =
    notAvailableOrRestrictedItemIds.length > 0

  const isAnyNotificationShown =
    needsArticleSelection ||
    shouldShowNotAvailableOrRestrictedArticlesWarning ||
    shouldShowNoMatchingReferencesError

  return {
    needsArticleSelection,
    notFoundItemIds,
    handleNotFoundItemsDelete,
    notAvailableOrRestrictedItemIds,
    handleNotAvailableOrRestrictedItemsDelete,
    shouldShowNoMatchingReferencesError,
    shouldShowNotAvailableOrRestrictedArticlesWarning,
    isAnyNotificationShown,
  }
}
