import { EXTERNAL_PRICES_QUERY_KEY } from '../../common/ExternalPrices/consts'
import {
  ARTICLES_FILTER_VALUES_QUERY_KEY,
  ARTICLES_SEARCH_QUERY_KEY,
} from '../../common/Search/consts'
import { ARTICLE_DETAILS_QUERY_KEY } from '../../common/ArticleDetails/hooks'
import { CUSTOMER_ARTICLE_NUMBER_DETAILS_QUERY_KEY } from '../CustomerArticleNumber/Show/hooks'
import { BARCODE_ARTICLE_LIST_ITEMS_QUERY_KEY } from '../ArticleLists/Articles/barcode/hooks'
import { ARTICLE_LIST_ITEMS_QUERY_KEY } from '../ArticleLists/Articles/search/hooks'

export const articleRelatedQueryKeys = [
  ARTICLES_SEARCH_QUERY_KEY,
  ARTICLES_FILTER_VALUES_QUERY_KEY,
  EXTERNAL_PRICES_QUERY_KEY,
  ARTICLE_DETAILS_QUERY_KEY,
  CUSTOMER_ARTICLE_NUMBER_DETAILS_QUERY_KEY,
  BARCODE_ARTICLE_LIST_ITEMS_QUERY_KEY,
  ARTICLE_LIST_ITEMS_QUERY_KEY,
]
