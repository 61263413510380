import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useCurrentUser } from 'app/containers/common/Auth'

export const EXTERNAL_REFERENCES_LIST_QUERY_KEY =
  'EXTERNAL_REFERENCES_LIST_QUERY_KEY'

export const useBareArticleExternalReferencesListQuery = (
  articleId: string | undefined,
) => {
  const { isCustomer } = useCurrentUser()

  const externalReferencesListRequest = () => {
    if (!isCustomer || !articleId) {
      return Promise.reject(
        new Error('articleId missing or user is not customer'),
      )
    }

    return generatedApi.customers.getArticleExternalReferences.request({
      articleId,
    })
  }

  return useQuery({
    queryKey: [EXTERNAL_REFERENCES_LIST_QUERY_KEY, articleId, isCustomer],
    queryFn: externalReferencesListRequest,
    enabled: !!articleId && isCustomer,
  })
}

export const useArticleExternalReferencesWithParamsListQuery = () => {
  const { articleId } = useParams<{ articleId?: string }>()

  return useBareArticleExternalReferencesListQuery(articleId)
}
