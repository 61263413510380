import { NoPhotoIcon } from 'app/components/icons'
import { Typography } from 'app/components/Typography'
import { GenericArticleContentWrapper } from 'app/pages/customers/common/articles/GenericArticleContent'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

export const ReferenceNotFoundItemContent = () => {
  const {
    fastOrderListItem: { barcode },
  } = useFastOrderListItemContext()

  return (
    <GenericArticleContentWrapper>
      <NoPhotoIcon />
      <Typography textStyle="textNormalSb" color="contentOff">
        {barcode}
      </Typography>
    </GenericArticleContentWrapper>
  )
}
