import { useCurrentUser } from 'app/containers/common/Auth'
import { UserIcon } from 'app/components/icons'
import { Button } from 'app/components/Buttons/Button'
import {
  useCompanyUserMeQuery,
  useCurrentCompanyUserFullName,
} from 'app/containers/common/Me/CompanyUser'

import { CompanyAccountMenu } from '../../commonCompanyNavComponents'

export const VendorAccountButtonTrigger = () => {
  const fullName = useCurrentCompanyUserFullName()
  const { isPending } = useCompanyUserMeQuery()

  return (
    <Button
      data-testid="userMenuButton"
      size="sm"
      variant="secondary"
      leftIcon={<UserIcon />}
      isLoading={isPending}
      isDisabled={isPending}
    >
      {fullName}
    </Button>
  )
}

export const VendorAccountButton = () => {
  const { isUserImpersonated } = useCurrentUser()

  return (
    <CompanyAccountMenu
      triggerButton={<VendorAccountButtonTrigger />}
      isLogoutAvailable={!isUserImpersonated}
    />
  )
}
