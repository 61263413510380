import type { CSSProperties } from 'react'
import type { Column } from 'react-table'

export const columnAlignmentToJustifyContentDictionary: Record<
  NonNullable<Column['align']>,
  CSSProperties['justifyContent']
> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const

export const columnAlignmentToTextAlignmentDictionary: Record<
  NonNullable<Column['align']>,
  CSSProperties['textAlign']
> = {
  left: 'start',
  center: 'center',
  right: 'end',
} as const
