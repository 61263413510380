import { http, HttpResponse } from 'msw'

import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'

import { url } from './hooks'

export const updateCartItemMock = http.patch(
  getCustomerMockUrl(url),
  () => new HttpResponse(null, { status: 204 }),
)

export const makeUpdateCartItemMockWithSpy = (spyFn?: jest.Mock) =>
  http.patch(getCustomerMockUrl(url), async ({ request }) => {
    const body = await request.json()

    if (spyFn) {
      await spyFn(body)
    }

    return new HttpResponse(null, { status: 204 })
  })

export const makeUpdateOciCartItemMockWithSpy = (spyFn?: jest.Mock) =>
  http.patch(getOciCustomerMockUrl(url), async ({ request }) => {
    const body = await request.json()

    if (spyFn) {
      await spyFn(body)
    }

    return new HttpResponse(null, { status: 204 })
  })

export const updateOciUserCartItemMock = makeUpdateOciCartItemMockWithSpy()
