import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import type { NavLinkProps } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'

import { TypographyWithTooltipOnOverflow } from 'app/components/tooltips'

import type { SideNavOption } from './types'
import { StyledNavLink } from './styles'

type NavItemProps = SideNavOption &
  Omit<NavLinkProps, 'to'> & {
    onLinkActive?: () => void
  }

export const NavItem = ({
  titleMsg,
  icon,
  path,
  dataCy,
  onLinkActive,
  ...props
}: NavItemProps) => {
  const { t } = useTranslation()
  const navLinkRef = useRef<HTMLAnchorElement>(null)
  const wasCalledRef = useRef<boolean>(false)
  const isLinkActive = !!useRouteMatch({
    path,
    exact: false,
  })
  const navLinkRect = navLinkRef?.current?.getBoundingClientRect()

  const isNavLinkVisible =
    navLinkRect &&
    navLinkRect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)

  useEffect(() => {
    if (wasCalledRef.current || !isLinkActive) return

    wasCalledRef.current = true

    onLinkActive && onLinkActive()

    // Checking if link is NOT visible to prevent scrolling during normal clicking
    if (!isNavLinkVisible) {
      navLinkRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      })
    }
  }, [onLinkActive, isLinkActive, isNavLinkVisible])

  return (
    <StyledNavLink
      className={'navItem'}
      data-testid={dataCy}
      to={{ pathname: path }}
      ref={navLinkRef}
      {...props}
    >
      {icon}

      <TypographyWithTooltipOnOverflow color="currentColor" cursor="pointer">
        {t(titleMsg)}
      </TypographyWithTooltipOnOverflow>
    </StyledNavLink>
  )
}
