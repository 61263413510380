import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { translations } from 'locales/translations'
import { useScanDetectionWithIgnoreIfFocusOnInputRef } from 'libs/useScanDetection/useScanDetectionWithIgnoreIfFocusOnInputRef'

import { Input } from 'app/components/inputs/Input'
import { ScannerIcon } from 'app/components/icons'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { Divider } from 'app/components/Divider'

import { BARCODE_FIELD_NAME } from './consts'
import { BarcodeFieldScannerButton } from './components'

const messages =
  translations.pages.customer.editArticleDetailsModal.externalReferences.form
    .fields.barcode

const BarcodeFieldRightElement = () => (
  <Flex h="100%" align="center">
    <Divider isFlexChild orientation="vertical" my="6px" />
    <BarcodeFieldScannerButton />
  </Flex>
)

export const BarcodeField = ({ isEditMode }: { isEditMode: boolean }) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()
  const { setFieldValue } = useFormikContext()

  const shouldShowScannerButton = isMobile && !isEditMode

  const { scannerInputRef } = useScanDetectionWithIgnoreIfFocusOnInputRef({
    onComplete: (value) => {
      setFieldValue(BARCODE_FIELD_NAME, value)
    },
  })

  return (
    <Input
      name={BARCODE_FIELD_NAME}
      label={t(messages.label)}
      placeholder={t(messages.placeholder)}
      caption={!isEditMode && t(messages.caption)}
      leftElement={isMobile ? undefined : <ScannerIcon />}
      isDisabled={isEditMode}
      {...(shouldShowScannerButton && {
        rightElementCustomStyles: { marginRight: 0 },
        rightElement: <BarcodeFieldRightElement />,
      })}
      ref={scannerInputRef}
    />
  )
}
