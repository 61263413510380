import { Flex } from '@chakra-ui/react'

import { CartButton, SearchBox, MobileMenu, Logo } from './components'
import { useMobileSearchInputVisibility } from './useMobileSearchInputVisibility'

export const CustomerNavMobile = () => {
  const { isSearchInputVisible } = useMobileSearchInputVisibility()

  return (
    <Flex
      direction="column"
      gap="12px"
      p="8px 16px"
      data-testid="mobileMainHeader"
    >
      <Flex align="center" justify="space-between" gap="16px">
        <Logo />

        <Flex gap="4px">
          <CartButton size="sm" />

          <MobileMenu />
        </Flex>
      </Flex>

      {isSearchInputVisible && <SearchBox />}
    </Flex>
  )
}
