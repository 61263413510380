import type { IconProps } from '../types'

export const SpinnerIcon = ({
  width = '96px',
  height = '96px',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g className="c" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M48 23a25 25 0 1 1 0 50v8a33 33 0 0 0 0-66v8Z"
        fill="url(#s2ngSpinnerA)"
      />
      <path
        d="M48 23a25 25 0 1 0 0 50 4 4 0 0 1 0 8 33 33 0 0 1 0-66v8Z"
        fill="url(#s2ngSpinnerB)"
      />
    </g>
    <defs>
      <linearGradient
        id="s2ngSpinnerA"
        x1={51.568}
        y1={23}
        x2={51.568}
        y2={73.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6D1FF" />
        <stop offset={1} stopColor="#FBFBFB" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="#s2ngSpinnerB"
        x1={48}
        y1={23}
        x2={48}
        y2={73.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6D1FF" />
        <stop offset={1} stopColor="#276EF1" />
      </linearGradient>
    </defs>
  </svg>
)
