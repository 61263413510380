import { useMutation } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useCartMutationOptions } from '../../useCartMutationOptions'

export const useChangeCartItemsMutation = () => {
  const cartMutationOptions = useCartMutationOptions()

  return useMutation({
    mutationFn: generatedApi.customers.addArticlesToCart.request,
    ...cartMutationOptions,
  })
}
