import versionInfo from '../.version.json'

export const APP_VERSION = `${versionInfo.major}.${versionInfo.minor}.${versionInfo.patch}`

export const FONT_FETCH_TIMEOUT = 5000

export const HOST = `${window.location.protocol}//${window.location.host}`

export const DEFAULT_CURRENCY = 'EUR' as const

export const DEFAULT_DEBOUNCE_DELAY = 500

export const DEFAULT_PER_PAGE = 10

export const KILOBYTE = 2 ** 10
export const GIGABYTE = KILOBYTE ** 3

export const EMAIL_SUBJECT_LENGTH_LIMITATION = 988
