import type { Selector } from 'reselect'
import { createSelector } from 'reselect'
import { jwtDecode } from 'jwt-decode'

import type { RootState } from 'types'

import type { AuthState, DecodedJWT } from './slice.types'
import { AUTH_REDUCER_NAME } from './consts'

const selectAuthData: Selector<RootState, AuthState> = (state) =>
  state[AUTH_REDUCER_NAME]

export const accessTokenSelector = createSelector(
  selectAuthData,
  (data) => data.tokens && data.tokens.token, // INFO: `data.tokens &&` prevent crashes for users using old structure (check git history of this selector)
)

export const refreshTokenSelector = createSelector(
  selectAuthData,
  (data) => data.tokens && data.tokens.refreshToken,
)

export const verificationUserIdSelector = createSelector(
  selectAuthData,
  (data) => data.verificationUserId,
)

export const impersonationSelector = createSelector(
  selectAuthData,
  (data) => data.impersonation,
)

export const decodedTokenSelector = createSelector(
  accessTokenSelector,
  (accessToken) =>
    accessToken ? (jwtDecode(accessToken) as DecodedJWT) : null,
)

export const tokenUserRolesSelector = createSelector(
  decodedTokenSelector,
  (decodedToken) => decodedToken?.roles,
)

export const tokenEmailSelector = createSelector(
  decodedTokenSelector,
  (decodedToken) => decodedToken?.email,
)

export const isCompanyUserSelector = createSelector(
  selectAuthData,
  (data) =>
    data.userRole === 'customer' ||
    data.userRole === 'ociCustomer' ||
    data.userRole === 'vendor',
)

export const isBackofficeUserSelector = createSelector(
  tokenUserRolesSelector,
  impersonationSelector,
  (tokenUserRoles, impersonation) =>
    !!tokenUserRoles?.includes('BACKOFFICE_USER') &&
    !impersonation?.impersonatedUserId,
)

export const baseIdentitySelector = createSelector(selectAuthData, (data) => ({
  // @ts-expect-error INFO: make https://github.com/simple-system/next-gen/pull/3248/files changes backward compatible
  selectedCompanyId: data.selectedCompanyId ?? data.company?.companyId,
  userRole: data.userRole,
  isUserInitialized: !!data.isUserInitialized,
  impersonation: data.impersonation,
  isUserImpersonated: !!data.impersonation,
}))

export const ociSessionIdSelector = createSelector(
  selectAuthData,
  (data) => data.ociSessionId,
)
