import { Trans, useTranslation } from 'react-i18next'
import NiceModal from '@ebay/nice-modal-react'

import { translations } from 'locales/translations'
import { theme } from 'theme'

import { Typography } from 'app/components/Typography'
import { BarcodeScannerIcon } from 'app/components/icons'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'
import { ModalButton } from 'app/components/modals/ModalButton'
import { Modal } from 'app/components/modals/Modal'

type WelcomeModalProps = {
  onClose: () => void
}

const messages = translations.pages.customer.scanner.mobile.welcome

export const MOBILE_SCANNER_WELCOME_MODAL_ID = 'mobileScannerWelcomeModal'

export const WelcomeModal = NiceModal.create<WelcomeModalProps>(
  ({ onClose }) => {
    const { t } = useTranslation()

    return (
      <Modal
        id={MOBILE_SCANNER_WELCOME_MODAL_ID}
        width="311px"
        isWithoutFooterBorder
        onCloseComplete={onClose}
        contentPadding="0"
      >
        {(closeModal) => (
          <>
            <BarcodeScannerIcon
              width="100%"
              height="136px"
              style={{
                backgroundColor: theme.colors.backgroundG,
                borderRadius: '6px',
              }}
            />
            <ColumnWithGap padding="0 16px 16px">
              <Typography textStyle="headingXSmall" color="blue_500" as="div">
                <Trans
                  i18nKey={messages.header}
                  components={[<Typography textStyle="headingXSmall" />]}
                />
              </Typography>
              <Typography>{t(messages.description)}</Typography>
              <ModalButton onClick={closeModal}>
                {t(translations.formActions.ok)}
              </ModalButton>
            </ColumnWithGap>
          </>
        )}
      </Modal>
    )
  },
)

NiceModal.register(MOBILE_SCANNER_WELCOME_MODAL_ID, WelcomeModal)

export const showMobileScannerWelcomeModal = (params: WelcomeModalProps) => {
  NiceModal.show(MOBILE_SCANNER_WELCOME_MODAL_ID, params)
}
