import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Modal } from 'app/components/modals/Modal'
import { Typography } from 'app/components/Typography'
import { SupportMailAndPhoneBoxes } from 'app/components/layout/SupportButton/components/SupportMailAndPhoneBoxes'

const CONTACT_SUPPORT_MODAL_ID = 'contactSupportModal'

const messages = translations.header.contactSupport

export const showContactSupportModal = () =>
  NiceModal.show(CONTACT_SUPPORT_MODAL_ID)

export const ContactSupportModal = NiceModal.create(() => {
  const { t } = useTranslation()

  return (
    <Modal
      id={CONTACT_SUPPORT_MODAL_ID}
      header={t(messages.title)}
      isWithoutHeaderBorder
      width="320px"
    >
      <Typography textStyle="textParagraph">
        {t(messages.description)}
      </Typography>

      <SupportMailAndPhoneBoxes />

      <Typography p="8px 12px" mb="20px" textStyle="textSmall" color="contentB">
        {t(messages.phoneSupport.description)}
      </Typography>
    </Modal>
  )
})

NiceModal.register(CONTACT_SUPPORT_MODAL_ID, ContactSupportModal)
