import { PATHS } from 'consts/paths'
import { translations } from 'locales/translations'

const messages = translations.pages.customer.breadcrumbs

export const BREADCRUMBS_MAP = {
  [PATHS.customer.addUser]: [
    { labelTranslation: messages.users },
    { labelTranslation: messages.addNewUser },
  ],
  [PATHS.customer.editUser]: [
    { labelTranslation: messages.users },
    { labelTranslation: messages.editUser },
  ],
  [PATHS.customer.defaultAccountingValue]: [
    { labelTranslation: messages.users },
    { labelTranslation: messages.defaultAccountingValue },
  ],
  [PATHS.customer.requestNewVendor]: [
    { labelTranslation: messages.vendors },
    { labelTranslation: messages.requestNewVendor },
  ],
  [PATHS.customer.switchAccount]: [
    { labelTranslation: messages.simpleSystem },
    { labelTranslation: messages.changeCompany },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToAccountType]: [
    { labelTranslation: messages.accountTypeAndValues },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToAccountValue]: [
    { labelTranslation: messages.accountTypeAndValues },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToGeneralLedger]: [
    { labelTranslation: messages.generalLedgers },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.addApprovalFlow]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.addNewFlow },
  ],
  [PATHS.customer.companyAndSettings.editApprovalFlow]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.editApprovalFlow },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToApprovalFlow]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.assignUsersToUserGroup]: [
    { labelTranslation: messages.userManagement },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.shareArticleList]: [
    { labelTranslation: messages.articleLists },
    { labelTranslation: messages.shareArticleListWithOthers },
  ],
  [PATHS.customer.articleListsCollaborate]: [
    { labelTranslation: messages.articleLists },
    { labelTranslation: messages.articleListCollaborate },
  ],
  [PATHS.customer.articleListGenerateBarcodes]: [
    { labelTranslation: messages.articleLists },
    { labelTranslation: messages.articleListGenerateBarcodes },
  ],
  [PATHS.customer.companyAndSettings.assignDeliveryAddress]: [
    { labelTranslation: messages.deliveryAddresses },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.deliveryAddressManagePermissions]: [
    { labelTranslation: messages.deliveryAddresses },
    { labelTranslation: messages.managePermissions },
  ],
  [PATHS.customer.companyAndSettings.budgets.createAccountingBudget]: [
    { labelTranslation: messages.budgetManagement },
    { labelTranslation: messages.createAccountingBudget },
  ],
  [PATHS.customer.companyAndSettings.budgets.editAccountingBudget]: [
    { labelTranslation: messages.budgetManagement },
    { labelTranslation: messages.editAccountingBudget },
  ],
  [PATHS.customer.companyAndSettings.budgets.createIndividualBudget]: [
    { labelTranslation: messages.budgetManagement },
    { labelTranslation: messages.createIndividualBudget },
  ],
  [PATHS.customer.companyAndSettings.budgets.editIndividualBudget]: [
    { labelTranslation: messages.budgetManagement },
    { labelTranslation: messages.editIndividualBudget },
  ],
  [PATHS.customer.companyAndSettings.priceRestrictions.create]: [
    { labelTranslation: messages.priceRestrictions },
    { labelTranslation: messages.createPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.priceRestrictions.createDefault]: [
    { labelTranslation: messages.priceRestrictions },
    { labelTranslation: messages.createDefaultPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.priceRestrictions.edit]: [
    { labelTranslation: messages.priceRestrictions },
    { labelTranslation: messages.editPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.priceRestrictions.editDefault]: [
    { labelTranslation: messages.priceRestrictions },
    { labelTranslation: messages.editDefaultPriceRestriction },
  ],
  [PATHS.customer.companyAndSettings.priceRestrictions.assignUsers]: [
    { labelTranslation: messages.priceRestrictions },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.catalogViews.create]: [
    { labelTranslation: messages.catalogView },
    { labelTranslation: messages.createCatalogView },
  ],
  [PATHS.customer.companyAndSettings.catalogViews.edit]: [
    { labelTranslation: messages.catalogView },
    { labelTranslation: messages.editCatalogView },
  ],
  [PATHS.customer.companyAndSettings.catalogViews.assignUsers]: [
    { labelTranslation: messages.catalogView },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.catalogViews.createDefault]: [
    { labelTranslation: messages.catalogView },
    { labelTranslation: messages.createDefaultCatalogView },
  ],
  [PATHS.customer.companyAndSettings.catalogViews.editDefault]: [
    { labelTranslation: messages.catalogView },
    { labelTranslation: messages.editDefaultCatalogView },
  ],
  [PATHS.customer.companyAndSettings.accountingSettings]: [
    { labelTranslation: messages.typesAndValues },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.ordersManageUserPrivileges]: [
    { labelTranslation: messages.orderHistory },
    { labelTranslation: messages.userPrivileges },
  ],
  [PATHS.customer.deliveriesAndPaymentsManageUserPrivileges]: [
    { labelTranslation: messages.deliveriesAndPayments },
    { labelTranslation: messages.userPrivileges },
  ],
  [PATHS.customer.companyAndSettings.articleIndividualisationUserPrivileges]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.userPrivileges },
  ],
  [PATHS.customer.companyAndSettings.createCustomerArticleNumbersList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.createCustomerArticleNumber },
  ],
  [PATHS.customer.companyAndSettings.editCustomerArticleNumbersList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.editCustomerArticleNumber },
  ],
  [PATHS.customer.scanner]: [
    { labelTranslation: messages.home },
    { labelTranslation: messages.barcodeScanner },
  ],
  [PATHS.customer.fastOrder]: [
    { labelTranslation: messages.home },
    { labelTranslation: messages.fastOrder },
  ],
  [PATHS.customer.companyAndSettings.createCoreArticleList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.createCoreArticleList },
  ],
  [PATHS.customer.companyAndSettings.editCoreArticleList]: [
    { labelTranslation: messages.articleIndividualisation },
    { labelTranslation: messages.editCoreArticleList },
  ],
  [PATHS.customer.companyAndSettings.erpIntegrations.ociIntegration
    .individualMapping.create]: [
    { labelTranslation: messages.ociIntegration },
    { labelTranslation: messages.createIndividualMapping },
  ],
  [PATHS.customer.companyAndSettings.erpIntegrations.ociIntegration
    .individualMapping.edit]: [
    { labelTranslation: messages.ociIntegration },
    { labelTranslation: messages.editIndividualMapping },
  ],
  [PATHS.customer.companyAndSettings.approvalFlowsSettings]: [
    { labelTranslation: messages.approvalFlows },
    { labelTranslation: messages.settings },
  ],
  [PATHS.customer.companyAndSettings.vendingMachines.create]: [
    { labelTranslation: messages.vendingMachines },
    { labelTranslation: messages.createVendingMachine },
  ],
  [PATHS.customer.companyAndSettings.vendingMachines.edit]: [
    { labelTranslation: messages.vendingMachines },
    { labelTranslation: messages.editVendingMachine },
  ],
  [PATHS.customer.companyAndSettings.vendingMachines.details]: [
    { labelTranslation: messages.vendingMachines },
    { labelTranslation: messages.vendingMachineDetails },
  ],
  [PATHS.customer.companyAndSettings.cartRestrictions.create]: [
    { labelTranslation: messages.cartRestrictions },
    { labelTranslation: messages.createCartRestriction },
  ],
  [PATHS.customer.companyAndSettings.cartRestrictions.edit]: [
    { labelTranslation: messages.cartRestrictions },
    { labelTranslation: messages.editCartRestriction },
  ],
  [PATHS.customer.companyAndSettings.cartRestrictions.assignUsers]: [
    { labelTranslation: messages.cartRestrictions },
    { labelTranslation: messages.assignUsers },
  ],
  [PATHS.customer.companyAndSettings.budgets.assignUsersToIndividualBudget]: [
    { labelTranslation: messages.individualBudget },
    { labelTranslation: messages.assignIndividualBudget },
  ],
  [PATHS.customer.companyAndSettings.budgets.editAssignmentToIndividualBudget]:
    [
      { labelTranslation: messages.individualBudget },
      { labelTranslation: messages.assignIndividualBudget },
    ],
} as const

export const WIDER_MOBILE_SPACING_PAGES_LIST = [
  PATHS.customer.search,
  PATHS.customer.vendors,
  ...Object.values(PATHS.customer.checkout),
  PATHS.customer.platformOrderDetails,
  PATHS.customer.erpGeneratedOrderDetails,
  PATHS.customer.ociTransferredCartDetails,
]
