import { Typography } from '../Typography'

import { Tooltip } from './Tooltip'
import type { MarkerTooltipProps } from './types'

export const MarkerTooltip = ({
  content,
  contentProps,
  ...rest
}: MarkerTooltipProps) => (
  <Tooltip
    isLazy
    contentPadding="8px 16px"
    content={
      <Typography
        textStyle="textSmall"
        color="currentColor"
        textAlign="center"
        wordBreak="break-word"
        {...contentProps}
      >
        {content}
      </Typography>
    }
    {...rest}
  />
)
