import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'

import { useTheme } from 'theme'

import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'
import { BoxesIcon } from 'app/components/icons'

export const LayoutWrapper = (props: FlexProps) => {
  const { colors } = useTheme()

  return (
    <Flex
      direction="column"
      justify="space-between"
      height="100%"
      bg={colors.backgroundB}
      {...props}
    />
  )
}

export const LabelM = (props: TypographyProps) => (
  <Typography textStyle="figmaLabelM" color="blue_500" {...props} />
)

export const HeaderLarge = (props: TypographyProps) => (
  <Typography
    textStyle="headerLarge"
    color="blue_500"
    fontWeight={700}
    {...props}
  />
)

export const AbsoluteBoxesIcon = (props: BoxProps) => (
  <Box
    position="absolute"
    bottom="0"
    left="50%"
    transform="translateX(-50%)"
    {...props}
  >
    <BoxesIcon />
  </Box>
)

export const FooterWrapper = (props: FlexProps) => (
  <Flex gap="20px" {...props} />
)
