import { useQuery } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type { ModelArticleDetailData } from 'libs/openapiGeneratedApi/Api'
import { PATHS } from 'consts/paths'
import { translations } from 'locales/translations'

import { useExternalPricesQuery } from 'app/containers/common/ExternalPrices'
import { getIs404Error } from 'app/utils/error404'
import { showGenericErrorModal } from 'app/App/globalModals'

import { useArticleDetailsRequestFactory } from './useArticleDetailsRequestFactory'

export const ARTICLE_DETAILS_QUERY_KEY = 'articleDetails'

type ArticleQueryHookParams = {
  enabled?: boolean
  articleId?: string
}

const useArticleDetailsBasicQuery = (params?: ArticleQueryHookParams) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { enabled = true, articleId } = params ?? {}

  const { articleDetailsRequest, articleDetailsQueryKey } =
    useArticleDetailsRequestFactory({ articleId })

  const errorMessage = t(
    translations.pages.customer.articleDetails.articleNotFound,
  )

  const options = {
    refetchOnWindowFocus: false,
    enabled,
    staleTime: 3000,
    meta: {
      onError: (error: unknown) => {
        if (getIs404Error(error)) {
          return history.replace({
            pathname: PATHS.error404,
            state: { errorMessage },
          })
        }

        return showGenericErrorModal({ error })
      },
    },
  }

  return useQuery<
    ModelArticleDetailData & {
      needsExternalPrices?: boolean
    }
  >({
    queryKey: articleDetailsQueryKey,
    queryFn: articleDetailsRequest,
    retry: (_, error: unknown) => !getIs404Error(error),
    ...options,
  })
}

export const useGetArticleDetailsQuery = (params?: ArticleQueryHookParams) => {
  const articleDetailsQuery = useArticleDetailsBasicQuery(params)

  const { data } = articleDetailsQuery
  const needsExternalPrices = !data?.orderOptions

  const { data: externalPrices } = useExternalPricesQuery({
    articlesIds: needsExternalPrices && data ? [data.id] : undefined,
  })

  if (needsExternalPrices && data) {
    const dataWithExternalPrices = {
      ...data,
      needsExternalPrices: true,
      ...(externalPrices?.[0] && {
        orderOptions: externalPrices[0].orderOptions,
      }),
    }

    return {
      ...articleDetailsQuery,
      data: dataWithExternalPrices,
    }
  }

  return articleDetailsQuery
}
