import type { IconProps } from '../types'

export const LogoutIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M15 13L18 10L15 7" stroke={color} strokeWidth="2" />
    <path d="M6 10L17 10" stroke={color} strokeWidth="2" />
    <path
      d="M12 6.5L12 5C12 3.89543 11.1046 3 10 3L5 3C3.89543 3 3 3.89543 3 5L3 15C3 16.1046 3.89543 17 5 17L10 17C11.1046 17 12 16.1046 12 15L12 13.5"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)
