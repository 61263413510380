import { generatePath } from 'react-router-dom'

import type { CustomerId } from 'app/models/user'

import { catalogPreviewUrl, marketplaceUrl } from './consts'

type MakeUrlProps = { isVendor: boolean; customerId: CustomerId | undefined }

export const makeUrl = ({ isVendor, customerId }: MakeUrlProps) => {
  if (isVendor) {
    if (!customerId) throw new Error('customerId has to be defined')

    return generatePath(catalogPreviewUrl, { customerId })
  }

  return marketplaceUrl
}
