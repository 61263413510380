import type { SnackbarMessage } from 'notistack'
import { useCallback } from 'react'

import { colors } from 'theme/colors'

import { TickIcon } from 'app/components/icons'

import { useNotificationSnackbar } from './NotificationToast'
import type {
  EnqueueNotificationSnackbarArgs,
  NotificationProps,
} from './types'

const defaultNotificationProps = {
  type: 'success',
  customIcon: <TickIcon color={colors.contentA} />,
} as const

type ShowSuccessToastParams = Pick<
  EnqueueNotificationSnackbarArgs,
  'snackbarProps'
> & {
  content: SnackbarMessage
  notificationProps?: Omit<NotificationProps, 'children' | 'type'>
}

// TODO: Get rid of useGetShowSuccessToast and use useNotificationSnackbar with type 'success' https://simplesystem.atlassian.net/browse/S2NG-8225
export const useGetShowSuccessToast = () => {
  const { enqueueNotificationSnackbar } = useNotificationSnackbar()

  const showSuccessToast = useCallback(
    ({ content, snackbarProps, notificationProps }: ShowSuccessToastParams) =>
      enqueueNotificationSnackbar({
        notificationContent: content,
        notificationProps: {
          ...defaultNotificationProps,
          ...notificationProps,
        },
        snackbarProps,
      }),
    [enqueueNotificationSnackbar],
  )

  return { showSuccessToast }
}
