import { useRouteMatch } from 'react-router-dom'

import { PATHS } from 'consts/paths'

export const useIsSSOFlow = () => {
  const isSSOFlow = !!useRouteMatch({
    path: PATHS.sso.root,
    exact: false,
  })

  return {
    isSSOFlow,
  }
}
