import type { SliceCaseReducers, CreateSliceOptions } from '@reduxjs/toolkit'
import { createSlice as createSliceOriginal } from '@reduxjs/toolkit'

import type { RootState } from 'types'

/* Wrap createSlice with stricter Name options */

/* istanbul ignore next */
export const createSlice = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends keyof RootState,
>(
  options: CreateSliceOptions<State, CaseReducers, Name>,
) => createSliceOriginal(options)
