import type { Query } from '@tanstack/react-query'

import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'

import type { FastOrderItemStatus } from './types'

type FastOrderListItemType = ModelGetFastOrderListData['items'][number]

const refetchIntervalTime = 1000

export const getRefetchInterval = ({
  state: { data },
}: Query<ModelGetFastOrderListData>) =>
  data?.items.find(({ articleSearchDone }) => !articleSearchDone)
    ? refetchIntervalTime
    : undefined

export const findSelectedArticleDataWithThrow = ({
  selectedArticle,
  foundArticles,
}: FastOrderListItemType) => {
  const selectedArticleData = foundArticles.find(
    ({ id }) => id === selectedArticle,
  )

  if (!selectedArticleData) throw new Error('selected article was not found')

  return selectedArticleData
}

export const getCanItemBeSelected = (item: FastOrderListItemType) =>
  getFastOrderItemStatus(item) === 'AVAILABLE'

/*
  INFO: if the search is in progress, then `articleSearchDone` will be false.

  When the search, is finalized, then `articleSearchDone` = true and if only 
  one result was found, then this article will be automatically selected by the
  API.

  Assumptions: 
  
  1. Backend will never return unavailable articles in the `foundArticles` list
     (except if article was previously selected). 
  2. If multiple articles are found for an item, then selection will be empty 
     and must be done by the user.
*/
export const getFastOrderItemStatus = (
  item: FastOrderListItemType,
): FastOrderItemStatus => {
  const { articleSearchDone, selectedArticle, foundArticles } = item

  if (!articleSearchDone) return 'ARTICLE_SEARCH_IN_PROGRESS'

  if (!foundArticles.length) return 'NOT_FOUND'

  if (!selectedArticle) {
    const areAllFoundArticlesRestricted = foundArticles.every(
      ({ restricted }) => restricted,
    )

    return areAllFoundArticlesRestricted
      ? 'RESTRICTED'
      : 'AWAITING_SELECTION_FROM_MULTIPLE'
  }

  // INFO: should be defined by now, as we discarded already all statuses
  // without article selection

  const selectedArticleData = findSelectedArticleDataWithThrow(item)

  if (!selectedArticleData.available) return 'NO_LONGER_AVAILABLE'

  if (selectedArticleData.restricted) return 'RESTRICTED'

  return 'AVAILABLE'
}

export const getAvailableNonRestrictedFoundArticles = (
  foundArticles: FastOrderListItemType['foundArticles'],
) =>
  foundArticles.filter(({ available, restricted }) => available && !restricted)
