import { Flex, LinkBox, LinkOverlay } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { FAQ_LINK, VENDOR_FAQ_LINK } from 'consts/envVars'

import { Typography } from 'app/components/Typography'
import { OpenInNewIcon } from 'app/components/icons'
import { Divider } from 'app/components/Divider'
import { MenuItemWrapper } from 'app/components/ActionsMenu/styles'
import { useCurrentUser } from 'app/containers/common/Auth'

import { TextPaddingWrapper } from './styles'
import { SupportMailAndPhoneBoxes } from './SupportMailAndPhoneBoxes'

const messages = translations.header.contactSupport

type SupportPopoverContentProps = {
  withTitle?: boolean
}

export const SupportPopoverContent = ({
  withTitle,
}: SupportPopoverContentProps) => {
  const { t } = useTranslation()
  const { isVendor } = useCurrentUser()

  return (
    <Flex direction="column" gap="8px">
      <TextPaddingWrapper>
        {withTitle && (
          <Typography textStyle="headerH4">{t(messages.title)}</Typography>
        )}
        <Typography color="contentB">{t(messages.description)}</Typography>
      </TextPaddingWrapper>

      <SupportMailAndPhoneBoxes />

      <Flex direction="column">
        <TextPaddingWrapper>
          <Typography textStyle="textSmall" color="contentB">
            {t(messages.phoneSupport.description)}
          </Typography>
        </TextPaddingWrapper>
        <Divider py="4px" />

        {withTitle && (
          <TextPaddingWrapper>
            <Typography textStyle="headerH4">
              {t(messages.helpCenter.header)}
            </Typography>
          </TextPaddingWrapper>
        )}

        <LinkBox>
          <MenuItemWrapper>
            <Flex gap="16px">
              <OpenInNewIcon />

              <LinkOverlay
                href={isVendor ? VENDOR_FAQ_LINK : FAQ_LINK}
                target="_blank"
              >
                <Typography>{t(messages.helpCenter.faq)}</Typography>
              </LinkOverlay>
            </Flex>
          </MenuItemWrapper>
        </LinkBox>
      </Flex>
    </Flex>
  )
}
