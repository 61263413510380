import { useSelector } from 'react-redux'

import { ociSessionIdSelector } from '../selectors'

export const useOciSession = () => {
  const ociSessionId = useSelector(ociSessionIdSelector)

  const isOciSessionFlow = !!ociSessionId

  return {
    ociSessionId,
    isOciSessionFlow,
  }
}
