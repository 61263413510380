import 'flag-icons/css/flag-icons.min.css'

import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import type { CountryCode } from 'consts/countries'

interface FlagProps extends FlexProps {
  countryCode?: CountryCode | 'CIMODE'
  isSquared?: boolean
}

// https://github.com/lipis/flag-icons#usage
export const Flag = ({ countryCode = 'DE', isSquared, ...rest }: FlagProps) => (
  <Flex width="30px" height="22px" {...rest}>
    <span
      className={`fi fi-${countryCode.toLowerCase()} ${isSquared ? 'fis' : ''}`}
      style={{ width: '100%', height: '100%' }}
    />
  </Flex>
)
