import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useArticleListId } from '../useArticleListId'
import { ARTICLE_LISTS_QUERY_KEY } from '../List'

export const generateArticleListDetailsQueryKey = <
  T extends { articleListId: string },
>({
  articleListId,
  ...rest
}: T) => [ARTICLE_LISTS_QUERY_KEY, articleListId, rest]

export const useArticleListDetailsQuery = () => {
  const articleListId = useArticleListId()

  const params = { articleListId }

  return useQuery({
    queryKey: generateArticleListDetailsQueryKey(params),
    queryFn: () => generatedApi.customers.getArticleList.request(params),
    enabled: !!articleListId,
    staleTime: 1000 * 3,
    placeholderData: keepPreviousData,
  })
}
