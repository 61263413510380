import { generatePath } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { api } from 'apiServices'

import type { UpdateAccountRequestArgs } from '../../types'
import { cartItemPath } from '../../consts'
import { useCartMutationOptions } from '../../useCartMutationOptions'
import type { CartItemId } from '../../models'
import { useAccountingAbsentOnError } from '../../useAccountingAbsentOnError'

export type API__UpdateCartItemRequestArgs = {
  cartItemId: CartItemId
} & Partial<{
  quantity: number
  account: UpdateAccountRequestArgs
  generalLedgerId: string | null
  itemComment: string
  additionalAccountingInfo: string | null
}>

export const url = cartItemPath

const updateCartItemRequest = ({
  cartItemId,
  ...body
}: API__UpdateCartItemRequestArgs) =>
  api.patch<unknown, undefined>(generatePath(url, { cartItemId }), body)

const useUpdateCartMutationOptions = () => {
  const cartMutationOptions = useCartMutationOptions()
  const accountingAbsentOnError = useAccountingAbsentOnError()

  return {
    mutationFn: updateCartItemRequest,
    ...cartMutationOptions,
    onError: (error: unknown) => {
      cartMutationOptions.onError(error)
      accountingAbsentOnError(error)
    },
  }
}

export const useUpdateCartItemMutation = () => {
  const options = useUpdateCartMutationOptions()

  return useMutation(options)
}

// INFO: Duplicated mutations, so we can show individual loading state in selects
export const useUpdateAccountingValueCartItemMutation = () => {
  const options = useUpdateCartMutationOptions()

  return useMutation(options)
}

export const useUpdateGeneralLedgersCartItemMutation = () => {
  const options = useUpdateCartMutationOptions()

  return useMutation(options)
}
