import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const TooltipTriggerIconWrapper = styled(Flex)(
  ({ theme: { colors } }) => ({
    cursor: 'pointer',
    path: {
      fill: colors.contentB,
    },
    '&:hover': {
      path: {
        fill: colors.contentA,
      },
    },
  }),
)
