import type { TabProps, TabsProps } from '@chakra-ui/react'
import { Tab, Tabs, Flex, Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const TabsStyled = (props: TabsProps) => (
  <Tabs width="auto" isLazy {...props} />
)

type TabStyledProps = TabProps &
  Partial<{
    hasGroupedStyle: boolean | undefined
    isFirstGroupedTab: boolean
    isLastGroupedTab: boolean
  }>

export const TabStyled = styled(
  ({
    hasGroupedStyle,
    isFirstGroupedTab,
    isLastGroupedTab,
    ...props
  }: TabStyledProps) => <Tab _focus={{ boxShadow: 'none' }} {...props} />,
)(
  ({
    theme: { colors },
    hasGroupedStyle,
    isFirstGroupedTab,
    isLastGroupedTab,
  }) => ({
    display: 'flex',
    fontWeight: 600,
    fontSize: '14px',
    color: colors.contentA,
    position: 'relative',
    flex: '0 0 auto',
    padding: '14px 0px 18px 0px',
    borderRadius: '6px 6px 2px 2px',
    // INFO: since we're using custom tab underline, we don't need default chakra ones (which are borderBottom `2px` and marginBottom `-2px`)
    borderBottom: 'none',
    marginBottom: '0px',
    ...(hasGroupedStyle && {
      backgroundColor: colors.backgroundC,
      ...(isFirstGroupedTab && { borderRadius: '6px 0 2px 0' }),
      ...(isLastGroupedTab && { borderRadius: '0 6px 0 2px' }),
      ...(!isFirstGroupedTab && !isLastGroupedTab && { borderRadius: '0' }),
    }),

    ':hover': {
      color: colors.contentB,
    },
    '&[aria-selected=true]': {
      color: colors.blue_500,
      ':after': {
        background: colors.blue_500,
      },
    },
    ':after': {
      content: '""',
      display: 'block',
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      height: '4px',
      backgroundColor: colors.backgroundD,
      borderRadius: '2px',
    },

    ':before': {
      content: '""',
      display: 'block',
      bottom: 0,
      left: -2,
      right: 0,
      position: 'absolute',
      height: '4px',
      backgroundColor: colors.backgroundD,
      borderRadius: '2px',
    },
  }),
)

export const Line = styled(Box)(({ theme: { mediaQuery, colors } }) => ({
  ':after': {
    content: '""',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    height: '4px',
    backgroundColor: colors.backgroundD,
    borderRadius: '2px',
  },

  ':before': {
    content: '""',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    height: '4px',
    backgroundColor: colors.backgroundD,
    borderRadius: '2px',
  },

  [mediaQuery.small]: {
    ':after': {
      left: -20,
      right: -20,
    },
  },
}))

export const TabsWrapper = styled(Flex)(({ theme: { mediaQuery } }) => ({
  '::-webkit-scrollbar': {
    display: 'none',
  },
  position: 'relative',
  zIndex: 1,
  overflowX: 'auto',
  overflowY: 'hidden',
  gap: '32px',

  [mediaQuery.small]: {
    padding: '0 0 0 5px',
  },
}))

// Before and after are needed here for the future in case there are many tabs on desktop view (scrolling) to create the fade in / fade out effect
export const WrapperStyled = styled(Box)(({ theme: { mediaQuery } }) => ({
  position: 'relative',
  ':before': {
    content: '""',
    width: '20px',
    bottom: 0,
    top: 0,
    position: 'absolute',
    zIndex: 2,
    left: '-20px',
    background:
      'linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%)',
  },
  ':after': {
    content: '""',
    width: '20px',
    bottom: 0,
    top: 0,
    position: 'absolute',
    zIndex: 2,
    right: '-20px',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%)',
  },

  [mediaQuery.small]: {
    ':after': {
      width: 0,
    },
    ':before': {
      width: 0,
    },
  },
}))
