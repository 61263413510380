import { StringParam, useQueryParam } from 'use-query-params'

import type { CustomerId } from 'app/models/user'

import { useMarketplacePreviewCustomersData } from './useMarketplacePreviewCustomersData'

export const useCustomerFilterQueryParam = () => {
  const customersData = useMarketplacePreviewCustomersData()

  const [queryParamValue, setQueryParamValue] = useQueryParam(
    'customerId',
    StringParam,
  )
  const setCustomerId = (customerId: string) => {
    setQueryParamValue(customerId)
  }

  const isQueryCustomerOnTheList = customersData?.some(
    (customer) => customer.id === queryParamValue,
  )

  const customerId = isQueryCustomerOnTheList
    ? (queryParamValue as CustomerId)
    : undefined

  return { customerId, setCustomerId }
}
