import type { CountryCode } from 'consts/countries'
import { COUNTRIES } from 'consts/countries'

export const getIsCountryCode = (
  countryCode: string,
): countryCode is CountryCode => countryCode in COUNTRIES

export const getCountryLabel = (countryCode?: string) => {
  if (!countryCode) return

  if (!getIsCountryCode(countryCode)) return

  return COUNTRIES[countryCode].label
}
