import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { includes } from 'types/utils'

const packageUnits = translations.packageUnits

type FormatArticleUnitProps = {
  unit: string | undefined
  quantity?: number | string
}

export const useFormatArticleUnit = () => {
  const { t } = useTranslation()

  const formatArticleUnit = ({
    unit = '',
    quantity,
  }: FormatArticleUnitProps) => {
    const packageUnitsKeys = Object.keys(packageUnits) as unknown as Array<
      keyof typeof packageUnits
    >

    return includes(packageUnitsKeys, unit)
      ? t(packageUnits[unit], { count: Number(quantity) || 1 })
      : unit
  }

  return { formatArticleUnit }
}
