import type { IconProps } from '../types'

export const PermissionIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6V7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7V6Z"
      fill={color}
    />
    <path
      d="M5.53524 16H11.5C11.5 16 10.0001 15.3136 10.0001 13.5C10.0001 11.6865 11.5 11.0001 11.5 11.0001L10.0001 11C10.0001 11 4 10.9617 4 13.5001C4 14.1534 4.42691 15.0202 4.72288 15.5426C4.88769 15.8335 5.2009 16 5.53524 16Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5002 10.7998C13.7368 10.7998 12.2366 11.9268 11.6807 13.4998C12.2366 15.0728 13.7368 16.1998 15.5002 16.1998C17.2636 16.1998 18.7638 15.0728 19.3198 13.4998C18.7638 11.9268 17.2636 10.7998 15.5002 10.7998ZM15.5002 15.2998C16.4943 15.2998 17.3002 14.4939 17.3002 13.4998C17.3002 12.5057 16.4943 11.6998 15.5002 11.6998C14.5061 11.6998 13.7002 12.5057 13.7002 13.4998C13.7002 14.4939 14.5061 15.2998 15.5002 15.2998Z"
      fill={color}
    />
    <circle cx="15.5001" cy="13.4996" r="0.9" fill={color} />
  </svg>
)
