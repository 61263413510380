import { AVAILABLE_LANGUAGES } from 'locales/consts'
import type { AvailableLanguages } from 'locales/types'
import {
  SHOW_PO_EDITOR_KEYS_LANGUAGE,
  NEW_LANGUAGES_ENABLED,
} from 'consts/envVars'

export const LOCALES: Partial<
  Record<
    AvailableLanguages,
    { id: AvailableLanguages; label: string; labelLong: string }
  >
> = {
  [AVAILABLE_LANGUAGES.de]: {
    id: AVAILABLE_LANGUAGES.de,
    label: 'DE',
    labelLong: 'Deutsch',
  },
  [AVAILABLE_LANGUAGES.en]: {
    id: AVAILABLE_LANGUAGES.en,
    label: 'EN',
    labelLong: 'English',
  },
  [AVAILABLE_LANGUAGES.fr]: {
    id: AVAILABLE_LANGUAGES.fr,
    label: 'FR',
    labelLong: 'Français',
  },
  ...(NEW_LANGUAGES_ENABLED && {
    [AVAILABLE_LANGUAGES.it]: {
      id: AVAILABLE_LANGUAGES.it,
      label: 'IT',
      labelLong: 'Italiano',
    },
    [AVAILABLE_LANGUAGES.cs]: {
      id: AVAILABLE_LANGUAGES.cs,
      label: 'CS',
      labelLong: 'Čeština',
    },
  }),
  ...(SHOW_PO_EDITOR_KEYS_LANGUAGE && {
    [AVAILABLE_LANGUAGES.cimode]: {
      id: AVAILABLE_LANGUAGES.cimode,
      label: 'PO',
      labelLong: 'PO Editor',
    },
  }),
}
