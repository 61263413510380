import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import type { ModelArticleDetailVersionEnum } from 'libs/openapiGeneratedApi/Api'

import { Typography, type TypographyProps } from 'app/components/Typography'
import { Tooltip } from 'app/components/tooltips/Tooltip'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'
import { Divider } from 'app/components/Divider'

const messages = translations.components.eClassInfo

type EClassInfoProps = {
  eclass?: {
    version: ModelArticleDetailVersionEnum
    code?: string | null
    name?: string | null
  }
  labelTypographyProps?: TypographyProps
  valueTypographyProps?: TypographyProps
}

export const EClassInfo = ({
  eclass,
  labelTypographyProps,
  valueTypographyProps,
}: EClassInfoProps) => {
  const { t } = useTranslation()

  const hasEClassInfo = eclass && eclass.code && eclass.name

  const tooltipContentRendered = hasEClassInfo ? (
    <ColumnWithGap gap={8}>
      <Typography textStyle="textNormalSb" mb="10px" color="white">
        {t(messages.versions[eclass.version])}: {eclass.code}
      </Typography>

      <Divider color="white_50_alpha" />

      <Typography textStyle="textSmallLink" color="white">
        {eclass.name}
      </Typography>
    </ColumnWithGap>
  ) : (
    <Typography color="white">{t(messages.noEClassInfoMessage)}</Typography>
  )

  return (
    <Flex gap="2px" data-testid="eClassInfo">
      <Typography
        textStyle="textSmall"
        color="contentB"
        {...labelTypographyProps}
      >
        {t(messages.eClassLabel)}:{' '}
      </Typography>

      <Tooltip content={tooltipContentRendered} contentWidth="300px">
        <Typography
          textStyle="textSmall"
          color="contentB"
          {...valueTypographyProps}
        >
          {hasEClassInfo ? eclass.code : '—'}
        </Typography>
      </Tooltip>
    </Flex>
  )
}
