import { Flex } from '@chakra-ui/react'

import { ImageWithFallback } from 'app/components/ImageWithFallback'
import { Panel } from 'app/components/Panel/Panel'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import type { CommonModalProps } from '../types'
import { ArticleContent } from '../../ArticleContent'
import { ArticleDisplayModeProvider } from '../../ArticleDisplayModeProvider'

export const PanelWithArticleImageAndContent = ({
  article,
  vendor,
}: CommonModalProps) => {
  const { isMobile } = useBreakpointScreenSize()

  return (
    <Panel bg="backgroundB">
      <Flex gap="20px">
        <ImageWithFallback
          src={article.thumbnailUrl}
          alt={article.name}
          size={96}
        />
        <ArticleDisplayModeProvider isListView={!isMobile}>
          <ArticleContent article={article} vendor={vendor} />
        </ArticleDisplayModeProvider>
      </Flex>
    </Panel>
  )
}
