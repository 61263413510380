import { HStack } from '@chakra-ui/react'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { useSearchAndPagination } from 'app/hooks/useSearchAndPagination/useSearchAndPagination'
import { ScrollToTop } from 'app/components/ScrollToTop'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import type { ButtonProps } from '../Buttons/Button'
import { Typography } from '../Typography'
import { Divider } from '../Divider'

import { TEST_IDS } from './consts'
import { PaginationButton, CompactButtonWrapper } from './styles'
import type { PaginationProps } from './types'
import { PageSizeSelect } from './PageSizeSelect'

// TODO: reuse for PDP carousels https://simplesystem.atlassian.net/browse/S2NG-2331
export const Pagination = ({
  pageCount,
  size = 'sm',
  mode = 'full',
  withoutDividerBottom,
  shouldNotScrollToTopOnPageChange,
  ...rest
}: PaginationProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()
  const {
    page: currentPage,
    setPage,
    resetPage,
    pageSize,
    setPageSize,
    pageSizeOptions,
  } = useSearchAndPagination()

  const goToPreviousPage = () => setPage(currentPage - 1)
  const goToNextPage = () => setPage(currentPage + 1)

  const isCompact = isMobile || mode === 'compact'
  const previousBtnDisabled = currentPage <= 1
  const nextButtonDisabled = !!pageCount && currentPage >= pageCount
  const isLoading = !pageCount
  const navButtonSize: ButtonProps['size'] =
    size === 'md' ? 'squareMd' : 'squareSm'

  const ButtonsWrapper = isCompact ? CompactButtonWrapper : HStack

  return (
    <>
      <HStack
        p={isCompact ? '20px 0' : '16px 20px'}
        w="100%"
        justify="space-between"
        {...rest}
      >
        <PageSizeSelect
          size={size}
          resetPage={resetPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageSizeOptions={pageSizeOptions}
          isLoading={isLoading}
        />

        <ButtonsWrapper size={size}>
          <PaginationButton
            size={navButtonSize}
            onClick={goToPreviousPage}
            isLoading={isLoading}
            isDisabled={previousBtnDisabled}
            data-testid={TEST_IDS.prevPageBtn}
            isCompact={isCompact}
          >
            <ArrowBackIcon boxSize="20px" color="contentC" />
          </PaginationButton>

          {pageCount && (
            <PaginationButton
              size={size}
              pointerEvents="none"
              isLoading={isLoading}
              data-testid={TEST_IDS.counter}
              isCompact={isCompact}
            >
              <Typography
                textStyle="inputMediumButtonSb"
                px={isCompact ? 0 : '16px'}
              >
                {t(translations.pagination.xOfY, { currentPage, pageCount })}
              </Typography>
            </PaginationButton>
          )}

          <PaginationButton
            size={navButtonSize}
            onClick={goToNextPage}
            isLoading={isLoading}
            isDisabled={nextButtonDisabled}
            data-testid={TEST_IDS.nextPageBtn}
            isCompact={isCompact}
          >
            <ArrowForwardIcon boxSize="20px" color="contentC" />
          </PaginationButton>
        </ButtonsWrapper>

        {!shouldNotScrollToTopOnPageChange && (
          <ScrollToTop scrollDependantProp={currentPage} />
        )}
      </HStack>

      {!withoutDividerBottom && <Divider />}
    </>
  )
}
