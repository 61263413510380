import { http, HttpResponse } from 'msw'

import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'
import type { CartItemId } from 'app/containers/customers/CurrentCart/models'

import type { API__CartItemsLightResponse } from './hooks'
import { url } from './hooks'

export const cartItemsLightResponseMockData: API__CartItemsLightResponse = {
  items: [
    {
      articleId: '0906cd7b-2810-4671-963b-81be91d2ad25',
      cartItemId: '12314234534643214' as CartItemId,
      inCartQuantity: 3,
    },
  ],
}

export const cartItemsLightGetMock = http.get(getCustomerMockUrl(url), () =>
  HttpResponse.json(cartItemsLightResponseMockData),
)

export const cartItemsLightOciCustomerGetMock = http.get(
  getOciCustomerMockUrl(url),
  () => HttpResponse.json(cartItemsLightResponseMockData),
)
