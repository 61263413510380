import type { AxiosRequestConfig } from 'axios'
import { generatePath } from 'react-router-dom'

import { store as appStore } from 'store'
import { API_URL } from 'consts/envVars'

import {
  baseIdentitySelector,
  ociSessionIdSelector,
} from 'app/containers/common/Auth/selectors'

export const USER_ROLE_PATHS = {
  // TODO: It should be "companyId, not userId"
  // https://simplesystem.atlassian.net/browse/S2NG-3018
  vendor: 'vendors/:userId',
  customer: 'customers/:userId',
  ociCustomer: 'customer-oci-session/:ociSessionId',
} as const

const getBaseUrlHref = (url: string) => new URL(url, API_URL).href

export const addBaseUrl = (request: AxiosRequestConfig, store = appStore) => {
  const state = store.getState()
  const { selectedCompanyId, userRole } = baseIdentitySelector(state)
  const ociSessionId = ociSessionIdSelector(state)

  if (ociSessionId) {
    request.baseURL = getBaseUrlHref(
      generatePath(USER_ROLE_PATHS.ociCustomer, { ociSessionId }),
    )
  } else if (
    (userRole === 'customer' || userRole === 'vendor') &&
    selectedCompanyId
  ) {
    request.baseURL = getBaseUrlHref(
      generatePath(USER_ROLE_PATHS[userRole], { userId: selectedCompanyId }),
    )
  }

  return request
}
