import type { ModelGetFastOrderListParamsEnum } from 'libs/openapiGeneratedApi/Api'

import { getCustomerMockUrl, getOciCustomerMockUrl } from '../utils'

type GenerateFastOrderListPathArgs = {
  fastOrderListEnum: ModelGetFastOrderListParamsEnum
  url:
    | '/fast-order/:fastOrderListEnum'
    | `/fast-order/:fastOrderListEnum/${string}`
  isOciCustomer?: boolean
}

export const generateFastOrderListPath = ({
  fastOrderListEnum,
  url,
  isOciCustomer,
}: GenerateFastOrderListPathArgs) => {
  const mockUrl = url.replace(':fastOrderListEnum', fastOrderListEnum)

  return isOciCustomer
    ? getOciCustomerMockUrl(mockUrl)
    : getCustomerMockUrl(mockUrl)
}
