import type { CSSProperties } from 'react'
import styled from '@emotion/styled'
import {
  PopoverBody,
  PopoverContent,
  Flex,
  type PopoverContentProps,
} from '@chakra-ui/react'

export const StyledPopoverContent = styled(
  ({
    minWidth,
    ...props
  }: PopoverContentProps & {
    children: React.ReactNode
    minWidth: CSSProperties['minWidth']
  }) => <PopoverContent {...props} />,
)(({ minWidth, theme: { shadows } }) => ({
  boxShadow: shadows.primary,
  marginBottom: '16px',
  minWidth: minWidth ?? 'auto',

  '&:focus': {
    boxShadow: shadows.primary,
  },
}))

export const StyledPopoverBody = styled(PopoverBody)(
  () =>
    ({
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    }) as const,
)

export const PopoverOption = styled(Flex)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: '16px 12px',
  borderRadius: '6px',
  ':hover': {
    backgroundColor: theme.colors.backgroundC,
    cursor: 'pointer',
  },
}))
