import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import type { UseArticlePriceSourceProps } from './types'

const messages = translations.pages.customer.articleDetails.priceSource

export const useArticlePriceSource = ({
  needsExternalPrices,
  orderOptions,
}: UseArticlePriceSourceProps) => {
  const { t } = useTranslation()

  const hasExternalPrices = !!needsExternalPrices
  const firstPriceSource = orderOptions?.pricing[0].source

  if (hasExternalPrices) {
    return t(messages.externalPrices)
  }

  if (!firstPriceSource) {
    return null
  }

  if (firstPriceSource.type === 'CATALOG') {
    return t(messages.catalog)
  }

  if (firstPriceSource.type === 'PRICE_LIST') {
    return t(messages.priceList, { name: firstPriceSource.priceListFilename })
  }

  return null
}
