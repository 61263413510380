import type { SystemStyleObject } from '@chakra-ui/react'
import { css } from '@emotion/react'

export const getMultiLineTruncateStyles = (lines = 2): SystemStyleObject => ({
  display: '-webkit-box',
  WebkitLineClamp: lines,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
})

export const twoLineTruncateStyles = getMultiLineTruncateStyles(2)

export const hiddenScrollbarStyles = css({
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
    display: 'none',
  },
})
