import { api } from 'apiServices'

import { customersScope } from '../consts'

import type {
  API__GetCustomersRequestArgs,
  API__GetCustomersResponse,
} from './types'

export const getCustomersRequest = (params: API__GetCustomersRequestArgs) =>
  api.get<unknown, API__GetCustomersResponse>(customersScope, { params })
