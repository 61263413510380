import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { includes } from 'types/utils'
import { ALL_DEFINED_PATHS } from 'consts/allDefinedPaths'
import type { Paths } from 'consts/paths'

import type { Breadcrumb } from 'app/components/layout/Layout/components/Header'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useCurrentUser } from 'app/containers/common/Auth'
import { useCompanyCountryConfig } from 'app/containers/common/Profile'

import type {
  BreadcrumbMapSingleEntityData,
  BreadcrumbsMap,
  LayoutOnCancelMap,
} from './types'

function isPath(path: string): path is Paths {
  return includes(ALL_DEFINED_PATHS, path)
}

// TODO: with the Partial in the BreadcrumbsMap type, it's possible to pass breadcrumb with non existing path as a key
// e.g. const breadcrumbsMap = { [PATHS.customer.addUser]: [...], asdf: 'asdf' }
// https://simplesystem.atlassian.net/browse/S2NG-4522
export const useActiveBreadcrumbs = (
  breadcrumbsMap: BreadcrumbsMap,
): Breadcrumb[] | undefined => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  const { path } =
    useRouteMatch({ path: Object.keys(breadcrumbsMap), exact: true }) || {}

  if (path && isPath(path)) {
    const breadcrumbs = (
      isMobile ? breadcrumbsMap[path]?.slice(-1) : breadcrumbsMap[path]
    ) as BreadcrumbMapSingleEntityData

    return breadcrumbs.map(({ labelTranslation, ...breadcrumbData }) => ({
      label: t(labelTranslation),
      ...breadcrumbData,
    }))
  }

  return undefined
}

export const useLayoutOnCancelHandler = (
  layoutOnCancelMap: LayoutOnCancelMap,
): (() => void) | undefined => {
  const { path } =
    useRouteMatch({ path: Object.keys(layoutOnCancelMap), exact: true }) || {}

  if (path && isPath(path)) {
    return layoutOnCancelMap[path]
  }

  return undefined
}

export const useGetHelpCenterOnClick = () => {
  const { isCustomer, isVendor, isOciCustomer } = useCurrentUser()
  const { CUSTOMER_OPEN_HELP_CENTER_ID, VENDOR_OPEN_HELP_CENTER_ID } =
    useCompanyCountryConfig()

  if (!window?.userGuiding?.expandResourceCenter) return

  if (isCustomer || isVendor || isOciCustomer) {
    if (isCustomer || isOciCustomer) {
      return () =>
        window.userGuiding.expandResourceCenter(CUSTOMER_OPEN_HELP_CENTER_ID)
    }

    if (isVendor) {
      return () =>
        window.userGuiding.expandResourceCenter(VENDOR_OPEN_HELP_CENTER_ID)
    }
  }
}
