import type { IconProps } from '../types'

export const XIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M14 6L6 14" stroke={color} strokeWidth="2" />
    <path d="M14 14L6 6" stroke={color} strokeWidth="2" />
  </svg>
)
