import type { IconProps } from '../types'

export const ChangeIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      d="M15 5H6a2 2 0 0 0-2 2v3M5 15h9a2 2 0 0 0 2-2v-3"
      stroke={color}
      strokeWidth={2}
    />
    <path d="m12 2 3 3-3 3M8 18l-3-3 3-3" stroke={color} strokeWidth={2} />
  </svg>
)
