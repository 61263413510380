import { useCartItemsLightQuery } from './Items/List'

type UseCartArticleDataLightProps = {
  articleId?: string
  queryEnabled?: boolean
}

export const useCartArticleDataLight = ({
  articleId,
  queryEnabled,
}: UseCartArticleDataLightProps) => {
  const { data: cartArticlesDataLight } = useCartItemsLightQuery({
    enabled: queryEnabled,
  })
  const fallbackData = {
    articleId,
    cartItemId: null,
    inCartQuantity: 0,
  }

  if (!articleId || !cartArticlesDataLight) return fallbackData

  const matchedCartArticle = cartArticlesDataLight.find(
    (item) => item.articleId === articleId,
  )

  return matchedCartArticle ?? fallbackData
}
