import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { SearchIcon } from 'app/components/icons'

import type { BareInputProps } from '../Input'
import { BareInput } from '../Input'

export const BareSearchInput = ({
  shouldNotResizeOnMobile,
  ...rest
}: BareInputProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const size: BareInputProps['size'] =
    isMobile && !shouldNotResizeOnMobile ? 'sm' : 'md'

  return (
    <BareInput
      aria-label="Search input"
      size={size}
      leftElement={<SearchIcon />}
      {...rest}
    />
  )
}
