import { useQuery, useMutation } from '@tanstack/react-query'

import { useInvalidateArticleRelatedQueries } from 'app/containers/customers/articleRelated/hooks'

import { useCatalogId } from '../useCatalogId'

import {
  getCatalogCustomerAssignmentsRequest,
  assignCustomersToCatalogRequest,
} from './api'
import type { API__AssignCustomersToCatalogRequestArgs } from './types'

export const CATALOG_CUSTOMER_ASSIGNMENTS_QUERY_KEY =
  'CATALOG_CUSTOMER_ASSIGNMENTS_QUERY_KEY'

export const useCatalogCustomerAssignmentsQuery = () => {
  const catalogId = useCatalogId()

  return useQuery({
    queryKey: [CATALOG_CUSTOMER_ASSIGNMENTS_QUERY_KEY, catalogId],
    queryFn: () => getCatalogCustomerAssignmentsRequest({ catalogId }),
    select: (data) => data.customers,
  })
}

export const useAssignCustomersToCatalogMutation = () => {
  const catalogId = useCatalogId()
  const invalidateArticleRelatedQueries = useInvalidateArticleRelatedQueries()

  return useMutation({
    mutationFn: (
      customerIds: API__AssignCustomersToCatalogRequestArgs['customerIds'],
    ) => assignCustomersToCatalogRequest({ catalogId, customerIds }),
    onSuccess: invalidateArticleRelatedQueries,
  })
}
