import { Box, Stack } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { colors } from 'theme/colors'

import type { SideNavOption } from './components/types'
import { Nav } from './components/Nav'
import { Heading } from './components/Heading'
import { ExtendableNavItem } from './components/ExtendableNavItem'
import { NavItem } from './components/NavItem'
import { SIDE_NAV_WIDTH } from './consts'

export type SideNavigationStructure = {
  titleMsg?: string
  options: SideNavOption[]
}

type SideNavigationProps = {
  navigationStructure: SideNavigationStructure[]
  navigationBottomSection: ReactNode
}

export const SideNavigation = ({
  navigationStructure,
  navigationBottomSection,
}: SideNavigationProps) => {
  const { t } = useTranslation()

  return (
    <Box
      data-testid="sideNavigationBar"
      position="relative"
      flexShrink={0}
      width={SIDE_NAV_WIDTH}
    >
      <Nav backgroundColor={colors.backgroundA}>
        <Stack spacing="20px" flexGrow={1}>
          {navigationStructure.map((section, index) => (
            <section key={section.titleMsg ?? index}>
              {section.titleMsg && <Heading>{t(section.titleMsg)}</Heading>}

              <Stack spacing="0px">
                {section.options.map((option) => {
                  if (option.subRoutes) {
                    return (
                      <ExtendableNavItem
                        key={option.titleMsg}
                        icon={option.icon}
                        options={option.subRoutes}
                        titleMsg={option.titleMsg}
                        dataTestId={option.dataCy}
                      />
                    )
                  }

                  return <NavItem key={option.titleMsg} {...option} />
                })}
              </Stack>
            </section>
          ))}
        </Stack>
        {navigationBottomSection}
      </Nav>
    </Box>
  )
}
