import { findSelectedArticleDataWithThrow } from 'app/pages/customers/common/fastOrder/utils'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'
import { ArticleContent } from 'app/pages/customers/common/fastOrder/FastOrderResults/components/common/ArticleContent'

import {
  MultipleMatchingArticlesContent,
  ReferenceNotFoundItemContent,
} from './components'

export const FastOrderListItemArticleContent = () => {
  const {
    fastOrderListItem: { selectedArticle, foundArticles },
    fastOrderListItem,
    status,
  } = useFastOrderListItemContext()

  if (status === 'NOT_FOUND') {
    return <ReferenceNotFoundItemContent />
  }

  if (status === 'AWAITING_SELECTION_FROM_MULTIPLE') {
    return <MultipleMatchingArticlesContent />
  }

  // INFO: scenario when backend finds only restricted articles on initial search,
  // therefore pre-selection is not possible
  if (status === 'RESTRICTED' && !selectedArticle) {
    return <ArticleContent articleData={foundArticles[0]} isDisabled />
  }

  // INFO: it should be defined by this point as we have only 2 scenarios:
  // 1. Only one article was found, then it was preselected by API.
  // 2. User already chose the article.
  const selectedArticleData =
    findSelectedArticleDataWithThrow(fastOrderListItem)

  return (
    <ArticleContent
      articleData={selectedArticleData}
      isDisabled={status !== 'AVAILABLE'}
    />
  )
}
