import { useTranslation } from 'react-i18next'

import { colors } from 'theme/colors'
import { translations } from 'locales/translations'

import { MarketplaceIcon } from 'app/components/icons/components/MarketplaceIcon'
import { MarkerTooltip } from 'app/components/tooltips/MarkerTooltip'
import { CoreIcon } from 'app/components/icons'
import { Tag } from 'app/components/tags/Tag'

const messages = translations.pages.customer.articleDetails

export const ClosedMarketplaceTag = () => {
  const { t } = useTranslation()

  return (
    <MarkerTooltip
      content={t(messages.closedMarketplaceArticle)}
      wrapperProps={{ padding: '2px 6px' }}
    >
      <MarketplaceIcon color={colors.contentB} />
    </MarkerTooltip>
  )
}

export const CoreArticleTag = () => {
  const { t } = useTranslation()

  return (
    <Tag
      type="accentPrimary"
      borderRadius="0 0 6px 0"
      padding="2px 6px"
      gap="4px"
      dataTestId="coreArticleTag"
    >
      <CoreIcon />
      {t(messages.coreArticle)}
    </Tag>
  )
}
