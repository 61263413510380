import styled from '@emotion/styled'
import { css } from '@emotion/react'
import type { MenuItemProps } from '@chakra-ui/react'
import { Flex, MenuItem, MenuList } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import type { Theme } from 'theme'
import type { Color } from 'theme/colors'

import type { MenuListStyledProps } from './types'

export const MenuListStyled = styled(MenuList)<MenuListStyledProps>(
  ({ theme }) => ({
    padding: '16px',
    boxShadow: theme.shadows.primary,
    zIndex: theme.zIndexes.basic,
    maxHeight: '80vh',
    overflow: 'auto',
  }),
  ({ variant, theme: { colors } }) => {
    switch (variant) {
      case 'primary':
        return {}
      case 'secondary':
        return {
          backgroundColor: colors.blue_500,
        }
    }
  },
)

const commonMenuItemStyles = ({
  theme: { textStyles, colors },
}: {
  theme: Theme
}) =>
  css({
    ...textStyles.textNormal,
    padding: '14px 12px',
    borderRadius: '6px',
    color: colors.contentA,
    cursor: 'pointer',
    justifyContent: 'space-between',
    width: '100%',

    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        background: colors.backgroundC,
      },
    },
  })

export const MenuLink = styled(NavLink)(
  ({ theme }) => commonMenuItemStyles({ theme }),
  () => ({
    display: 'flex',
  }),
)

export const MenuItemWrapper = styled(Flex)(({ theme }) =>
  commonMenuItemStyles({ theme }),
)

export const StyledMenuItem = styled(MenuItem)<ActionItemStyledProps>(
  ({ theme }) => commonMenuItemStyles({ theme }),
  ({ variant = 'default', theme: { colors } }) => {
    switch (variant) {
      case 'default':
        return {}
      case 'primary':
        return {
          background: colors.blue_500,
          color: colors.white,
          '@media (hover: hover) and (pointer: fine)': {
            ':hover': {
              background: colors.blue_400,
            },
          },
        }
    }
  },
)

interface ActionItemStyledProps extends MenuItemProps {
  bg?: Color
  variant?: 'default' | 'primary'
}

// INFO: overriding _focus as it's buggy when nesting accordion as a Menu Item in LanguageAccordion
export const ActionItem = ({ children, ...rest }: ActionItemStyledProps) => (
  <StyledMenuItem _focus={{ background: 'initial' }} {...rest}>
    {children}
  </StyledMenuItem>
)
