import type { IconProps } from '../types'

export const GlobeIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m2.746 6.622 1.44-.96h1.5l1-1 1-1V2.34A8 8 0 0 1 10 2c.873 0 1.713.14 2.5.398V4.5l-1 .5-1.5.5L9 6 8 7l-1 .5L6 9h1l1-1h1l.5 1 1.5-.5 1-2 .5 1.5v1l-1 1 .5 1.5.5.5 1.5-.5V9h1l1.5 2V9l1-.5.249-.498A8 8 0 0 1 4.565 15.87L5 15v-1l-1.5-2c-.333-.5-1.1-1.6-1.5-2a7.97 7.97 0 0 1 .746-3.378ZM6 10.5l1-.5 1.5.5 2-.5.5 1.5 1 1h1l-.5 1.5-1 1-.5 1.5-1 1-.5-.5-.5-1-.5-1.5-1-.5-1.5-.5-.5-1 .5-2Z"
      fill="#102C60"
    />
    <circle cx={10} cy={10} r={7.5} stroke="#102C60" />
  </svg>
)
