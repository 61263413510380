import { Typography, type TypographyProps } from 'app/components/Typography'

import { getQrBoxDistanceFromTop } from '../../../useCodeScan'

const SCAN_INFO_MARGIN_TOP_RAW = 32

export const ScanInfo = (props: TypographyProps) => {
  const { qrBoxDistanceFromTop } = getQrBoxDistanceFromTop()

  const scanInfoOffsetTop = qrBoxDistanceFromTop + SCAN_INFO_MARGIN_TOP_RAW

  return (
    <Typography
      color="white"
      position="absolute"
      top={`${scanInfoOffsetTop}px`}
      width="100%"
      textAlign="center"
      {...props}
    />
  )
}
