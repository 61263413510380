import type { AxiosRequestConfig } from 'axios'

import { store as appStore } from 'store'

import {
  accessTokenSelector,
  impersonationSelector,
} from 'app/containers/common/Auth/selectors'

export const addAuthData = (request: AxiosRequestConfig, store = appStore) => {
  const state = store.getState()
  const token = accessTokenSelector(state)

  const { impersonatedUserId } = impersonationSelector(state) ?? {}

  if (request.headers) {
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`
    }

    if (impersonatedUserId) {
      request.headers['Impersonate-User'] = impersonatedUserId
    }
  }

  return request
}
