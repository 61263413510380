import { Link } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { LogoWithFallback } from 'app/containers/customers/common/LogoWithFallback'

export const Logo = () => (
  <Link to={PATHS.customer.home} data-testid="homeLinkLogo">
    <LogoWithFallback />
  </Link>
)
