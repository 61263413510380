import { groupBy } from 'lodash'
import { format } from 'date-fns'

import { DATE_FORMATS } from 'consts/dateFormats'

import type { EmptyCaseWrapperProps } from 'app/components/EmptyCaseWrapper'
import { EmptyCaseWrapper } from 'app/components/EmptyCaseWrapper'
import { PartPageLoader } from 'app/components/loaders/PartPageLoader'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { Divider } from 'app/components/Divider'

import { useFastOrderContext } from '../contexts/fastOrderContext'

import {
  FastOrderResultsNotifications,
  GroupedByDateFastOrderList,
  FastOrderListBulkSelectionAndActions,
} from './components'
import { FastOrderListWrapper } from './styles'

type FastOrderResultsProps = {
  emptyCaseWrapperProps?: Omit<EmptyCaseWrapperProps, 'isLoading' | 'data'>
}

export const FastOrderResults = ({
  emptyCaseWrapperProps,
}: FastOrderResultsProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const { fastOrderList, isFastOrderListPending } = useFastOrderContext()

  if (isFastOrderListPending) return <PartPageLoader />

  const fastOrderListGroupedByDate = groupBy(
    fastOrderList?.items,
    ({ lastAddedAt }) =>
      format(new Date(lastAddedAt), DATE_FORMATS.displayShort),
  )

  const fastOrderQueryDates = Object.keys(fastOrderListGroupedByDate)

  return (
    <EmptyCaseWrapper
      isLoading={isFastOrderListPending}
      data={fastOrderList?.items}
      {...emptyCaseWrapperProps}
    >
      <FastOrderResultsNotifications />
      {isMobile && <Divider />}
      <FastOrderListBulkSelectionAndActions />

      <FastOrderListWrapper>
        {fastOrderQueryDates.map((fastOrderQueryDate) => (
          <GroupedByDateFastOrderList
            key={fastOrderQueryDate}
            queryDateShort={fastOrderQueryDate}
            fastOrderItems={fastOrderListGroupedByDate[fastOrderQueryDate]}
          />
        ))}
      </FastOrderListWrapper>
    </EmptyCaseWrapper>
  )
}
