import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography, type TypographyProps } from 'app/components/Typography'
import { MarkerTooltip } from 'app/components/tooltips'
import type { ModelSearchArticleItem } from 'app/containers/common/Search'
import { checkIfHasBulkPrices } from 'app/pages/common/marketplaceAndSearch/utils'
import { useCurrentUser } from 'app/containers/common/Auth'

import { useArticlePriceSource } from './useArticlePriceSource'

type PriceInfoProps = {
  price: string
  orderOptions: ModelSearchArticleItem['orderOptions']
  needsExternalPrices: ModelSearchArticleItem['needsExternalPrices']
  typographyProps?: TypographyProps
}

const messages = translations.pages.customer.articleDetails

export const PriceInfo = ({
  price,
  orderOptions,
  needsExternalPrices,
  typographyProps,
}: PriceInfoProps) => {
  const { t } = useTranslation()
  const { isVendor } = useCurrentUser()

  const hasBulkPrices = checkIfHasBulkPrices(orderOptions)
  const priceSource = useArticlePriceSource({
    orderOptions,
    needsExternalPrices,
  })

  const priceTextRendered = (
    <Typography data-testid="priceInfo" {...typographyProps}>
      {hasBulkPrices ? t(messages.startPrice, { price }) : price}
    </Typography>
  )

  if (isVendor && priceSource) {
    return (
      <MarkerTooltip content={priceSource}>{priceTextRendered}</MarkerTooltip>
    )
  }

  return priceTextRendered
}
