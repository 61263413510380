import { forwardRef } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import { useTheme } from 'theme'

import { Button } from 'app/components/Buttons/Button'

import type { ButtonWithPopoverProps } from '../types'

type PopoverButtonProps = {
  isOpen: boolean
} & ButtonWithPopoverProps['triggerButtonProps']

export const PopoverButton = forwardRef<HTMLButtonElement, PopoverButtonProps>(
  (
    { isOpen, withoutArrow, size = 'sm', arrowBoxSize, arrowColor, ...rest },
    ref,
  ) => {
    const { colors } = useTheme()

    const EndEnhancer = isOpen ? ChevronUpIcon : ChevronDownIcon
    const rightIcon = withoutArrow ? undefined : (
      <EndEnhancer
        boxSize={arrowBoxSize ?? '20px'}
        color={arrowColor ?? colors.contentC}
      />
    )

    return (
      <Button
        isActive={isOpen}
        ref={ref}
        variant="secondary"
        size={size}
        rightIcon={rightIcon}
        {...rest}
      />
    )
  },
)
