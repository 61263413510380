import { useMutation, useQueryClient } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'
import type { ModelDeleteFromFastOrderListPayload } from 'libs/openapiGeneratedApi/Api'

import type { UseFastOrderHookArgs } from '../types'
import { getFastOrderListQueryKey } from '../List'
import { getFastOrderListCountQueryKey } from '../Count'

export const useDeleteFastOrderListItemsMutation = ({
  fastOrderListEnum,
}: UseFastOrderHookArgs) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ModelDeleteFromFastOrderListPayload) =>
      generatedApi.customers.deleteFromFastOrderList.request({
        fastOrderListEnum,
        data: params,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getFastOrderListQueryKey({ fastOrderListEnum }),
      })

      queryClient.invalidateQueries({
        queryKey: getFastOrderListCountQueryKey({ fastOrderListEnum }),
      })
    },
  })
}
