import type { PopoverProps } from '@chakra-ui/react'
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import styled from '@emotion/styled'

import { useTheme } from 'theme'

import { tooltipModifiers } from 'app/components/tooltips'
import { textStyles, Typography } from 'app/components/Typography'
import { MAX_WIDTH_1310PX_HEADER_BREAKPOINT_MEDIA_QUERY } from 'app/components/layout/Layout/components/Header/CustomerNav/useCustom1310Breakpoint'
import { PopoverContentOverriden } from 'app/components/tooltips/PopoverContentOverriden'

export type TooltipProps = PopoverProps & {
  children: ReactNode
  content: ReactNode
}

const OverflowWrapper = styled(Box)({
  ...textStyles.textNormalSb,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '90px',

  [MAX_WIDTH_1310PX_HEADER_BREAKPOINT_MEDIA_QUERY]: {
    width: 'auto',
  },
})

export const SearchPresetTooltip = ({
  children,
  content,
  ...rest
}: TooltipProps) => {
  const { colors } = useTheme()
  const colorStyles = { bg: colors.contentA, color: colors.white }

  return (
    <Box width="fit-content">
      <Popover
        trigger="hover"
        placement="left"
        offset={[0, 30]}
        modifiers={tooltipModifiers}
        {...rest}
      >
        <PopoverTrigger>
          <OverflowWrapper>{children}</OverflowWrapper>
        </PopoverTrigger>
        <Portal>
          <Box zIndex="popover" w="full" h="full" position="relative">
            <PopoverContentOverriden border={0} width="auto" {...colorStyles}>
              <PopoverArrow {...colorStyles} />
              <PopoverBody padding="8px 16px">
                <Typography
                  textStyle="textNormal"
                  color="currentColor"
                  textAlign="center"
                  wordBreak="break-word"
                >
                  {content}
                </Typography>
              </PopoverBody>
            </PopoverContentOverriden>
          </Box>
        </Portal>
      </Popover>
    </Box>
  )
}
