import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import { useTheme } from 'theme'

type DisplayInfoRowContainerProps = {
  withoutTopBorder?: boolean
  withoutBottomBorder?: boolean
} & FlexProps

export const DisplayInfoRowContainer = ({
  alignItems = 'center',
  withoutTopBorder,
  withoutBottomBorder = true,
  ...rest
}: DisplayInfoRowContainerProps) => {
  const { borders } = useTheme()

  return (
    <Flex
      alignItems={alignItems}
      justifyContent="space-between"
      width="100%"
      gap="20px"
      {...(!withoutTopBorder && { borderTop: borders.primary })}
      {...(!withoutBottomBorder && { borderBottom: borders.primary })}
      {...rest}
    />
  )
}
