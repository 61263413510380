import { useCallback, useState } from 'react'

// INFO: Thanks to https://stackoverflow.com/questions/52169520/how-can-i-check-whether-line-clamp-is-enabled
const getIsTextTruncated = (element: HTMLParagraphElement | null) => {
  if (!element) return false

  return element.scrollHeight > element.clientHeight
}

export const useIsTextTruncatedRefCallback = (children: React.ReactNode) => {
  const [isTextTruncated, setIsTextTruncated] = useState(false)

  const isTextTruncatedRefCallback = useCallback(
    (node: HTMLParagraphElement | null) => {
      if (!node) return

      setIsTextTruncated(getIsTextTruncated(node))
    },
    [children], // eslint-disable-line react-hooks/exhaustive-deps
    // INFO: we want to re-fire refCallback each time the children (string) will change, to adopt the ellipsis to the new value displayed
  )

  return { isTextTruncated, isTextTruncatedRefCallback }
}
