import { Trans, useTranslation } from 'react-i18next'
import NiceModal from '@ebay/nice-modal-react'

import { translations } from 'locales/translations'

import {
  buildConfirmModalId,
  ConfirmActionModal,
  showConfirmActionModal,
} from 'app/components/modals/ConfirmActionModal'
import { ModalButton } from 'app/components/modals/ModalButton'
import { Typography } from 'app/components/Typography'
import type { ArticleListId } from 'app/containers/customers/ArticleLists/types'
import { useDeleteArticleListMutation } from 'app/containers/customers/ArticleLists/Delete'

export const CONFIRM_ARTICLE_LIST_DELETE_MODAL_ID =
  'CONFIRM_ARTICLE_LIST_DELETE_MODAL_ID'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.list.deleteModal

type DeleteModalProps = {
  id: ArticleListId
  name: string
  onDeleteSuccess?: () => void
}

export const ConfirmDeleteModal = NiceModal.create<DeleteModalProps>(
  ({ id, name, onDeleteSuccess }) => {
    const { t } = useTranslation()
    const { mutate: deleteArticleList, isPending } =
      useDeleteArticleListMutation()

    const confirmButtonRendered = (closeModal: () => void) => {
      const onSuccess = () => {
        closeModal()

        onDeleteSuccess?.()
      }

      return (
        <ModalButton
          variant="red"
          isLoading={isPending}
          onClick={() => deleteArticleList({ id }, { onSuccess })}
        >
          {t(messages.deleteButton)}
        </ModalButton>
      )
    }

    return (
      <ConfirmActionModal
        id={CONFIRM_ARTICLE_LIST_DELETE_MODAL_ID}
        headerText={t(messages.header)}
        renderConfirmButton={confirmButtonRendered}
      >
        <Trans
          i18nKey={messages.content}
          values={{ name }}
          components={[<Typography as="span" textStyle="textLargeSb" />]}
        />
      </ConfirmActionModal>
    )
  },
)

NiceModal.register(
  buildConfirmModalId(CONFIRM_ARTICLE_LIST_DELETE_MODAL_ID),
  ConfirmDeleteModal,
)

export const showArticleListDeleteConfirmationModal = (
  params: DeleteModalProps,
) => showConfirmActionModal(CONFIRM_ARTICLE_LIST_DELETE_MODAL_ID, params)
