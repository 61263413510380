import { Flex } from '@chakra-ui/react'

import { useArticleActions } from './useArticleActions'
import { ChooseArticleButton } from './ChooseArticleButton'
import { DeleteArticleButton } from './DeleteArticleButton'

export const ItemWithoutSelectedArticleActions = () => {
  const { deleteMutation, deleteHandler } = useArticleActions()

  return (
    <Flex gap="8px" justifyContent="flex-end">
      <ChooseArticleButton />
      <DeleteArticleButton
        onClick={deleteHandler}
        isLoading={deleteMutation.isPending || deleteMutation.isSuccess}
      />
    </Flex>
  )
}
