import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'

const messages = translations.common.table

export const Wrapper = styled(Box)(
  () =>
    ({
      position: 'absolute',
      textAlign: 'center',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #F2F5FD 100%);',
      padding: '8px',
      width: '100%',
      bottom: '0',
    }) as const,
)

export const ScrollForMoreIndicator = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Typography textStyle="textSmall" color="contentB">
        {t(messages.scrollForMore)}
      </Typography>
    </Wrapper>
  )
}
