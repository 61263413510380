import type { ReactNode } from 'react'

import type { TypographyProps } from '../Typography/types'
import { Typography } from '../Typography/Typography'

import type { MarkerTooltipProps } from './types'
import { MarkerTooltip } from './MarkerTooltip'
import { useIsTextTruncatedRefCallback } from './useIsTextTruncatedRefCallback'

export interface TypographyWithTooltipOnOverflowProps extends TypographyProps {
  rawText?: string
  children: ReactNode
  tooltipProps?: Omit<MarkerTooltipProps, 'content'>
}

export const TypographyWithTooltipOnOverflow = ({
  rawText,
  children,
  tooltipProps,
  noOfLines = 1,
  ...rest
}: TypographyWithTooltipOnOverflowProps) => {
  const { isTextTruncated, isTextTruncatedRefCallback } =
    useIsTextTruncatedRefCallback(children)

  return (
    <MarkerTooltip
      isOpen={isTextTruncated ? undefined : false} // INFO: we want to keep MarkerTooltip always rendered to not trigger loops for 'refCallback' below, 'undefined' === uncontrolled, 'false' === controlled
      content={rawText ?? children}
      {...tooltipProps}
    >
      <Typography
        noOfLines={noOfLines}
        wordBreak={noOfLines === 1 ? 'break-all' : 'break-word'}
        {...rest}
        ref={isTextTruncatedRefCallback}
      >
        {children}
      </Typography>
    </MarkerTooltip>
  )
}
