import { forwardRef, useCallback } from 'react'
import { SnackbarContent, useSnackbar } from 'notistack'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import type {
  EnqueueNotificationSnackbarArgs,
  NotificationToastProps,
} from './types'
import { Notification } from './Notification'

const NotificationWrapper = styled(Box)(() => ({
  marginTop: '9px',
  width: '100%',
}))

export const NotificationToast = forwardRef<
  HTMLDivElement,
  NotificationToastProps
>(({ message, id, notificationProps }, ref) => {
  const { closeSnackbar } = useSnackbar()

  const { onNotificationClose } = notificationProps

  const closeNotification = () => {
    closeSnackbar(id)

    if (onNotificationClose) onNotificationClose()
  }

  return (
    <SnackbarContent ref={ref} onClick={closeNotification}>
      <NotificationWrapper>
        <Notification
          onNotificationClose={closeNotification}
          {...notificationProps}
        >
          {message}
        </Notification>
      </NotificationWrapper>
    </SnackbarContent>
  )
})

const defaultSnackbarProps = {
  anchorOrigin: { horizontal: 'center', vertical: 'top' },
}

export const useNotificationSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()

  const enqueueNotificationSnackbar = useCallback(
    ({
      notificationContent,
      notificationProps,
      snackbarProps,
    }: EnqueueNotificationSnackbarArgs) =>
      enqueueSnackbar(notificationContent, {
        width: '528px',
        variant: 'notification',
        notificationProps,
        ...defaultSnackbarProps,
        ...snackbarProps,
      }),
    [enqueueSnackbar],
  )

  return { enqueueNotificationSnackbar }
}
