import { merge } from 'lodash'
import type { FormikState } from 'formik'
import type { InferType } from 'yup'

import { useUpdateVendorCustomerMutation } from 'app/containers/vendors/Customers/UpdateVendorRequest'

import type { CustomerNumberModalProps } from './types'
import { useValidationSchema } from './validationSchema'

type UseFormProps = Pick<
  CustomerNumberModalProps,
  'customerId' | 'vendorSpecificCustomerNumber' | 'additionalOnCloseHandler'
> & {
  hideModal: () => void
}

type FormikConfig = {
  resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void
}

export const useForm = ({
  customerId,
  vendorSpecificCustomerNumber,
  additionalOnCloseHandler,
  hideModal,
}: UseFormProps) => {
  const { mutate: updateCustomer, isPending } =
    useUpdateVendorCustomerMutation()

  const validationSchema = useValidationSchema()
  const initialValues = merge(validationSchema.getDefault(), {
    vendorSpecificCustomerNumber,
  })

  const onSubmitHandler = (
    values: InferType<typeof validationSchema>,
    { resetForm }: FormikConfig,
  ) =>
    updateCustomer(
      { customerId, body: values },
      {
        onSuccess: () => {
          resetForm()
          hideModal()

          if (additionalOnCloseHandler) {
            additionalOnCloseHandler(values.vendorSpecificCustomerNumber)
          }
        },
      },
    )

  return {
    validationSchema,
    initialValues,
    onSubmitHandler,
    isPending,
  }
}
