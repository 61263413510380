import { useMutation } from '@tanstack/react-query'
import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { SuppressGlobalOnErrorProp } from 'queryClient/types'
import type { ModelUpdateArticleListItemPayload } from 'libs/openapiGeneratedApi/Api'

import { getCustomerMockUrl } from 'app/containers/customers/utils'

import { useMutationOptions } from './utils'

type API__UpdateArticleFromArticleListRequestArgs = {
  articleId: string
} & ModelUpdateArticleListItemPayload

export const useUpdateArticleFromArticleListMutation = ({
  articleListId,
  suppressGlobalOnError,
}: {
  articleListId: string
} & SuppressGlobalOnErrorProp) => {
  const mutationOptions = useMutationOptions(articleListId)

  return useMutation({
    mutationFn: ({
      articleId,
      ...data
    }: API__UpdateArticleFromArticleListRequestArgs) =>
      generatedApi.customers.updateArticleListItem.request({
        articleListId,
        articleId,
        data,
      }),
    ...mutationOptions,
    meta: { suppressGlobalOnError },
  })
}

export const makeUpdateArticleFromArticleListMock = (mockFn?: jest.Mock) =>
  http.put(
    getCustomerMockUrl(generatedApi.customers.updateArticleListItem.url),
    async ({ request }) => {
      if (mockFn) {
        mockFn(await request.json())
      }

      return new HttpResponse(null, { status: 200 })
    },
  )

export const updateArticleFromArticleListMock =
  makeUpdateArticleFromArticleListMock()
