import { colors } from 'theme/colors'
import { theme } from 'theme'

import type { TextStyle } from '../Typography'
import { textStyles } from '../Typography'

import type { Size } from './types'
import type { BareInputProps } from './Input'

type MakeSizeDependentStylesParams = { size?: Size; textStyle?: TextStyle }

export const makeSizeDependentStyles = ({
  size = 'md',
  textStyle,
}: MakeSizeDependentStylesParams) => {
  switch (size) {
    case 'sm': {
      return {
        height: '36px',
        ...(textStyle ? textStyles[textStyle] : textStyles.textNormal),
      }
    }
    case 'md': {
      return {
        height: '48px',
        ...(textStyle ? textStyles[textStyle] : textStyles.inputNormal),
      }
    }
  }
}

export const disabledStateStyles = {
  ':disabled, :hover:disabled, div[data-disabled="true"], div[data-disabled="true"]:hover':
    {
      border: 'none',
      cursor: 'not-allowed',
      color: colors.contentOff,
      background: colors.backgroundB,
      opacity: 1,

      '.left-icon': {
        opacity: 0.2,
      },
    },
}

export const readOnlyStateStyles = {
  ':read-only, :hover:read-only': {
    border: `1px solid ${colors.line}`,
    cursor: 'default',
    background: 'unset',
    color: colors.contentA,
  },
}

export const makeCommonInputStyles = ({
  isError,
  variant,
}: Pick<BareInputProps, 'isError' | 'variant'>) => {
  const borderRadius = '6px'

  switch (variant) {
    case 'white': {
      return {
        background: colors.white,
        borderRadius,
        color: isError ? colors.red_500 : colors.contentA,
        border: theme.borders.primary,
        ':hover:not([disabled]), :focus, :active': {
          boxShadow: 'none',
          backgroundColor: isError ? colors.red_100 : colors.backgroundC,
        },
      }
    }

    case 'normal':
    default:
      return {
        borderRadius,
        border: 'none',
        color: isError ? colors.red_500 : colors.contentA,
        background: isError ? colors.red_100 : colors.backgroundC,

        ':hover:not([disabled]), :focus, :active': {
          border: 'none',
          boxShadow: 'none',
          backgroundColor: isError ? colors.red_100 : colors.backgroundC,
        },
      }
  }
}
