import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { MobileScannerComponent } from 'app/pages/customers/common/MobileScannerComponent'

import { ScanningResultsDrawer, useWelcomeModal } from './components'
import { useMobileScanner } from './hooks'
import { DELAY_AFTER_SUCCESSFUL_SCAN } from './consts'

const messages = translations.pages.customer.scanner

export const MobileScanner = () => {
  const { t } = useTranslation()

  const {
    onScanSuccessHandler,
    onScannerCloseHandler,
    isAddingScannedValue,
    wasScannedItemAddedSuccessfully,
  } = useMobileScanner()

  return (
    <MobileScannerComponent
      header={t(messages.barcodeScanner)}
      scannerReadyMessage={t(messages.mobile.scanInfo.scanning)}
      scannedSuccessfullyMessage={t(messages.mobile.scanInfo.scanned)}
      onScanSuccessHandler={onScanSuccessHandler}
      onScannerCloseHandler={onScannerCloseHandler}
      hooksToCallWithCodeScanActions={useWelcomeModal}
      isScanPending={isAddingScannedValue}
      isScannedValueProcessedSuccessfully={wasScannedItemAddedSuccessfully}
      delayAfterSuccessfulScan={DELAY_AFTER_SUCCESSFUL_SCAN}
      shouldShowProgressButton
    >
      {({ pauseScanning, resumeScanning }) => (
        <ScanningResultsDrawer
          pauseScanning={pauseScanning}
          resumeScanning={resumeScanning}
        />
      )}
    </MobileScannerComponent>
  )
}
