import type { LinkProps } from '@chakra-ui/react'
import { Box, Flex, Link } from '@chakra-ui/react'
import styled from '@emotion/styled'
import type { ReactNode } from 'react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

export const FooterText = (props: { children: ReactNode }) => {
  const { isMobile } = useBreakpointScreenSize()

  if (!isMobile) {
    return <Box display="inline-block" paddingRight="20px" {...props} />
  }

  return <Flex width="100%" justifyContent="center" {...props} />
}

export const FooterSeparator = styled('span')(({ theme }) => ({
  display: 'inline-block',
  color: theme.colors.lineDarker,
  margin: '0 10px',
}))

export const FooterLink = styled((props: LinkProps) => (
  <Link isExternal boxShadow="none" {...props} />
))()
