import { useContext } from 'react'

import type { ArticleDisplayModeContextType } from './context'
import { ArticleDisplayModeContext } from './context'

/**
 * Hook that reads the ArticleDisplayModeContext and returns the value of the
 * display mode of the article.
 *
 * If the context `ArticleDisplayModeContext` is not available, then it throws an exception`
 *
 */
export const useArticleDisplayMode = (): ArticleDisplayModeContextType => {
  const articleDisplayMode = useContext(ArticleDisplayModeContext)

  if (articleDisplayMode) return articleDisplayMode

  throw new Error(
    'Article display mode context must be defined when using this hook',
  )
}
