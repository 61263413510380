import { useTranslation } from 'react-i18next'

import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'
import { translations } from 'locales/translations'

import {
  ArticleEanText,
  ArticleNumberText,
  CustomerArticleNumberText,
} from 'app/pages/common/marketplaceAndSearch'
import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

type MatchedFieldProps = {
  articleData: ModelGetFastOrderListData['items'][number]['foundArticles'][number]
}

const messages = translations.pages.customer.fastOrder.item

export const MatchedField = ({ articleData }: MatchedFieldProps) => {
  const { matchedField, articleNumber, customerArticleNumber, ean } =
    articleData

  const { t } = useTranslation()

  const {
    fastOrderListItem: { barcode },
  } = useFastOrderListItemContext()

  const commonTypographyProps: TypographyProps = {
    textStyle: 'textSmall',
    color: 'contentA',
  }

  if (matchedField === 'VENDOR_ARTICLE_NUMBER') {
    return (
      <ArticleNumberText number={articleNumber} {...commonTypographyProps} />
    )
  }

  if (matchedField === 'CUSTOMER_ARTICLE_NUMBER') {
    return (
      <CustomerArticleNumberText
        customerArticleNumber={customerArticleNumber ?? ''}
        {...commonTypographyProps}
      />
    )
  }

  if (matchedField === 'EAN') {
    return <ArticleEanText ean={ean ?? ''} {...commonTypographyProps} />
  }

  if (matchedField === 'EXTERNAL_REFERENCE') {
    return (
      <Typography {...commonTypographyProps}>
        {t(messages.externalReferences, { externalReference: barcode })}
      </Typography>
    )
  }

  return null
}
