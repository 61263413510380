import { http, HttpResponse, delay } from 'msw'

import { getVendorMockUrl } from 'app/containers/vendors/utils'
import { getCustomerMockUrl } from 'app/containers/customers/utils'

import type { GetCompanyUserMeMockDataArgs } from './utils'
import { getCompanyUserMeMockData } from './utils'
import { url } from './query'

export const makeVendorUserMeGetMock = (
  mockArgs?: Omit<
    GetCompanyUserMeMockDataArgs,
    | 'isCustomer'
    | 'permissionGroup'
    | 'assignedPrivileges'
    | 'additionalInformation'
  >,
) =>
  http.get(getVendorMockUrl(url), () =>
    HttpResponse.json(
      getCompanyUserMeMockData({ isCustomer: false, ...mockArgs }),
    ),
  )

export const vendorUserMeGetMock = makeVendorUserMeGetMock()

export const customerUserMeGetMock = http.get(getCustomerMockUrl(url), () =>
  HttpResponse.json(
    getCompanyUserMeMockData({
      isCustomer: true,
      assignedPrivileges: [
        'VIEW_ALL_ORDERS',
        'EDIT_PAYMENTS',
        'EDIT_DELIVERIES',
        'EDIT_EXTERNAL_REFERENCES',
        'EDIT_CUSTOMER_ARTICLE_NUMBERS',
      ],
    }),
    { status: 200 },
  ),
)

export const makeCustomerUserMeGetMock = (
  mockArgs?: Omit<GetCompanyUserMeMockDataArgs, 'isCustomer'>,
) =>
  http.get(getCustomerMockUrl(url), () =>
    HttpResponse.json(
      getCompanyUserMeMockData({ isCustomer: true, ...mockArgs }),
    ),
  )

export const customerShopperUserMeGetMock = http.get(
  getCustomerMockUrl(url),
  () =>
    HttpResponse.json(
      getCompanyUserMeMockData({
        isCustomer: true,
        permissionGroup: 'SHOPPER',
      }),
    ),
)

export const customerUserWithForcedDelayGetMock = http.get(
  getCustomerMockUrl(url),
  async () => {
    await delay(200)

    return HttpResponse.json(getCompanyUserMeMockData({ isCustomer: true }))
  },
)

export const customerUserMeGetMockWithAdditionalInfo =
  makeCustomerUserMeGetMock({
    additionalInformation: 'Additional customer user info',
  })
