import type { PayloadAction } from '@reduxjs/toolkit'
import type { Selector } from 'reselect'
import { createSelector } from 'reselect'

import { createSlice } from 'utils/@reduxjs/toolkit'
import type { AvailableLanguages } from 'locales/types'
import type { RootState } from 'types'

import { LANGUAGE_REDUCER_NAME } from './consts'

export type LanguageState = {
  language: AvailableLanguages | null
}

export const initialState: LanguageState = {
  language: null,
}

export const languageSlice = createSlice({
  name: LANGUAGE_REDUCER_NAME,
  initialState,
  reducers: {
    setUnauthorizedUserLang: (
      state,
      action: PayloadAction<LanguageState['language']>,
    ) => {
      state.language = action.payload
    },
  },
})

const selectLanguageData: Selector<RootState, LanguageState> = (state) =>
  state[LANGUAGE_REDUCER_NAME]

export const languageSelector = createSelector(
  selectLanguageData,
  (data) => data.language,
)

export const { setUnauthorizedUserLang } = languageSlice.actions
export const { reducer: languageReducer } = languageSlice
