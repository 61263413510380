import type { ErrorProps } from './types'

// INFO: I need to wrap the return strings in the fragment to satisfy the TS
// issue to make it right is here https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
export const Error = ({ error, touched }: ErrorProps) => {
  if (!touched || !error) {
    return null
  }

  if (Array.isArray(error)) {
    return <>{error[0]}</>
  }

  return <>{error}</>
}
