import { Box } from '@chakra-ui/react'

import { BareSelect } from 'app/components/inputs/Select'

import type { PaginationProps } from '../types'

import { usePageSizeSelectComponents } from './styles'

type PageSize = number | undefined

type PageSizeSelectProps = Pick<PaginationProps, 'size'> & {
  resetPage: () => void
  pageSize: PageSize
  setPageSize: (value: PageSize) => void
  pageSizeOptions: number[]
  isLoading: boolean
}

export const PageSizeSelect = ({
  size,
  resetPage,
  pageSize,
  setPageSize,
  pageSizeOptions,
  isLoading,
}: PageSizeSelectProps) => {
  const pageSizeSelectComponents = usePageSizeSelectComponents()

  const options = pageSizeOptions.map((pageSizeOption) => {
    const stringValue = pageSizeOption.toString()

    return { id: stringValue, label: stringValue }
  })

  const selectedPageSize =
    options.find((option) => Number(option.id) === pageSize) ?? options[0]

  // INFO: returning Box instead of null to have proper alignment of pagination buttons
  if (isLoading) return <Box />

  return (
    <Box>
      <BareSelect
        size={size}
        menuPosition="fixed"
        name="pageSizeSelect"
        componentsMemoized={pageSizeSelectComponents}
        options={options}
        value={selectedPageSize}
        onChange={(option) => {
          resetPage()
          setPageSize(Number(option?.id ?? options[0].id))
        }}
      />
    </Box>
  )
}
