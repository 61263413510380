import { useState } from 'react'

import { useArticleInputHandlersContext } from 'app/pages/common/marketplaceAndSearch/components/articleInputHandlersContext'
import { parseInputValue } from 'app/pages/common/marketplaceAndSearch/components/AmountInput/parsers'

import type { PersistentAmountInputProps } from '../types'

import { useAmountInputHandlers } from './useAmountInputHandlers'
import { usePlusMinusButtonHandlers } from './usePlusMinusButtonHandlers'

export const useAmountHandlers = (props: PersistentAmountInputProps) => {
  const {
    quantityInterval: passedQuantityInterval,
    minimumOrder: passedMinimumOrder,
    quantity,
  } = props
  const [shownValue, setShownValue] = useState<number | string>(quantity)
  const {
    deleteUseMutationResult,
    updateUseMutationResult,
    deleteHandler,
    updateHandler,
  } = useArticleInputHandlersContext()
  const isDeleting = deleteUseMutationResult.isPending
  const isDeleted = deleteUseMutationResult.isSuccess
  const isPending =
    deleteUseMutationResult.isPending || updateUseMutationResult.isPending

  const quantityInterval = passedQuantityInterval ?? 1
  const quantityMinimum = passedMinimumOrder ?? 1

  const parsedValue = parseInputValue({
    value: shownValue,
    quantityMinimum,
    quantityInterval,
  })

  const onNetworkError = () => {
    setShownValue(quantity)
  }

  const { minusHandlers, plusHandlers } = usePlusMinusButtonHandlers({
    parsedValue,
    quantityMinimum,
    quantityInterval,
    updateHandler,
    deleteHandler,
    setShownValue,
    onNetworkError,
    isDeleting,
  })

  const { changeHandler, blurHandler, keyUpHandler } = useAmountInputHandlers({
    shownValue,
    quantity,
    parsedValue,
    updateHandler,
    deleteHandler,
    setShownValue,
    onNetworkError,
    cancelPlusMinusHandlers: () => {
      minusHandlers.network.cancel()
      plusHandlers.network.cancel()
    },
  })

  const isUiPending =
    plusHandlers.network.isPending() || minusHandlers.network.isPending()

  return {
    shownValue,
    minusHandlers,
    plusHandlers,
    changeHandler,
    blurHandler,
    keyUpHandler,
    isPending,
    isDeleting,
    isDeleted,
    isUiPending,
  }
}
