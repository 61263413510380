import { getAvailableNonRestrictedFoundArticles } from 'app/pages/customers/common/fastOrder/utils'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

import { useArticleActions } from '../useArticleActions'
import { DeleteArticleButton } from '../DeleteArticleButton'
import { ArticleActionsWrapper } from '../styles'
import { ChooseArticleButton } from '../ChooseArticleButton'

import { DisabledArticleInfo } from './components/DisabledArticleInfo'

export const DisabledArticleActions = () => {
  const {
    fastOrderListItem: { foundArticles },
  } = useFastOrderListItemContext()

  const { deleteHandler, deleteMutation } = useArticleActions()

  const filteredFoundArticles =
    getAvailableNonRestrictedFoundArticles(foundArticles)

  const canChooseAnotherArticle = Boolean(filteredFoundArticles.length)

  return (
    <ArticleActionsWrapper gap="16px">
      <DisabledArticleInfo />
      {canChooseAnotherArticle && <ChooseArticleButton displayOnlyIcon />}
      <DeleteArticleButton
        onClick={deleteHandler}
        isLoading={deleteMutation.isPending || deleteMutation.isSuccess}
      />
    </ArticleActionsWrapper>
  )
}
