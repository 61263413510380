import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { useArticleListAssignmentsQuery } from 'app/containers/customers/ArticleLists/Assignments/List'
import { useUpdateArticleListAssignmentsMutation } from 'app/containers/customers/ArticleLists/Assignments/Update'
import type {
  ArticleListId,
  MODEL__ArticleListAssignments,
} from 'app/containers/customers/ArticleLists/types'
import { useCartArticleDataLight } from 'app/containers/customers/CurrentCart/useCartArticleDataLight'
import { useNotificationSnackbar } from 'app/components/Notification/NotificationToast'

export type FormValues = { articleListsAssigned: ArticleListId[] }

const notificationMsg =
  translations.pages.customer.articleDetails.articleAddedToListNotification

const useArticleListAssignmentsFormInitialValues = (articleId: string) => {
  const { data } = useArticleListAssignmentsQuery(articleId)

  const initialArticleListsAssigned = {
    articleListsAssigned:
      data
        ?.filter(({ articleAssigned }) => articleAssigned)
        .map(({ articleListId }) => articleListId) ?? [],
  }

  return { initialArticleListsAssigned }
}

const useArticleListAssignmentsFormOnSubmit = ({
  articleId,
  articleListAssignments,
  initialQuantity,
  initialArticleListsAssigned,
}: {
  articleId: string
  articleListAssignments: MODEL__ArticleListAssignments
  initialQuantity: number
  initialArticleListsAssigned: ArticleListId[]
}) => {
  const { mutate: updateArticleListAssignments } =
    useUpdateArticleListAssignmentsMutation()
  const { t } = useTranslation()
  const { enqueueNotificationSnackbar } = useNotificationSnackbar()

  const onSubmit = (values: FormValues, onSuccess: () => void) => {
    const assignments =
      articleListAssignments?.map(({ articleListId }) => ({
        articleListId,
        articleAssigned: values.articleListsAssigned.includes(articleListId),
      })) ?? []

    const isAdded = values.articleListsAssigned.some(
      (value) => !initialArticleListsAssigned.includes(value),
    )

    return updateArticleListAssignments(
      {
        articleId,
        assignments,
        ...(initialQuantity > 0 ? { initialQuantity } : {}),
      },
      {
        onSuccess: () => {
          onSuccess()

          if (isAdded)
            enqueueNotificationSnackbar({
              notificationContent: t(notificationMsg),
              notificationProps: { type: 'success' },
            })
        },
      },
    )
  }

  return onSubmit
}

export const useArticleListAssignmentsForm = ({
  articleId,
  hideArticleListAssignmentsModalFlow,
}: {
  articleId: string
  hideArticleListAssignmentsModalFlow: () => void
}) => {
  const { data: articleListAssignments = [] } =
    useArticleListAssignmentsQuery(articleId)

  const { inCartQuantity: initialQuantity } = useCartArticleDataLight({
    articleId,
  })

  const { initialArticleListsAssigned } =
    useArticleListAssignmentsFormInitialValues(articleId)
  const { articleListsAssigned } = initialArticleListsAssigned

  const onSubmit = useArticleListAssignmentsFormOnSubmit({
    articleId,
    articleListAssignments,
    initialQuantity,
    initialArticleListsAssigned: articleListsAssigned,
  })

  const articleListAssignmentsFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialArticleListsAssigned,
    onSubmit: (values) => onSubmit(values, hideArticleListAssignmentsModalFlow),
  })

  const {
    values: { articleListsAssigned: currentArticleListsAssigned },
    setFieldValue,
  } = articleListAssignmentsFormik

  const assignArticleList = async (newArticleListId: ArticleListId) => {
    await setFieldValue('articleListsAssigned', [
      ...currentArticleListsAssigned,
      newArticleListId,
    ])
  }

  return {
    articleListAssignmentsFormik,
    assignArticleList,
  }
}
