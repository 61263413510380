const VIEWPORT_META_TAG_NAME = 'viewport'

export const setViewportMetaTagContent = ({ content }: { content: string }) => {
  const viewportMetaTag = document.querySelector(
    `meta[name="${VIEWPORT_META_TAG_NAME}"]`,
  )

  if (viewportMetaTag) {
    viewportMetaTag.setAttribute('content', content)
  }
}
