import { useLocation } from 'react-router-dom'
import type { Location } from 'history'

export type DefaultRedirectLocationState = {
  pushedFromLocation?: Location
}

export const usePushedFromLocation = () => {
  const { state } = useLocation<DefaultRedirectLocationState>()
  const { pushedFromLocation } = state || {}

  return { pushedFromLocation }
}
