import {
  Box,
  type BoxProps,
  forwardRef,
  Flex,
  type FlexProps,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

type ScannerProps = BoxProps & {
  hasBeenScannedAndProcessedSuccessfully?: boolean
}

export const Scanner = styled(
  forwardRef(
    (
      { hasBeenScannedAndProcessedSuccessfully, ...props }: ScannerProps,
      ref,
    ) => <Box {...props} ref={ref} height="100dvh" overflow="hidden" />,
  ),
)(({ hasBeenScannedAndProcessedSuccessfully, theme: { colors } }) => ({
  '> div:last-child': {
    // INFO: To hide pause banner
    display: 'none !important', // INFO: Important because we need to overwrite inline styles
  },
  '> video': {
    objectFit: 'cover',
    width: '100dvw',
    height: '100dvh',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  // INFO: To color the scanning box frame
  '> div#qr-shaded-region > div': {
    display: 'none',
    ...(hasBeenScannedAndProcessedSuccessfully && {
      display: 'block',
      backgroundColor: `${colors.blue_500} !important`, // INFO: Important because we need to overwrite inline styles
    }),
  },
}))

export const NoVideoPermissionWrapper = styled((props: FlexProps) => (
  <Flex {...props} />
))(({ theme: { colors } }) => ({
  flexDirection: 'column',
  flex: 1,
  padding: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: colors.black,
}))
