import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { Button, type ButtonProps } from 'app/components/Buttons/Button'
import { ScannerIcon } from 'app/components/icons'
import { MarkerTooltip } from 'app/components/tooltips'

import { ScannerButtonContent } from '../ScannerButton/ScannerButtonContent'

import type { ScannerButtonContentProps } from './types'

const messages = translations.pages.customer.scanner

type MarketTooltipWrapperProps = {
  children: ReactNode
  contentType: ScannerButtonContentProps['contentType']
}
const MarkerTooltipWrapper = ({
  children,
  contentType,
}: MarketTooltipWrapperProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  if (isMobile || contentType === 'full') {
    return <Fragment>{children}</Fragment>
  }

  return (
    <MarkerTooltip content={t(messages.buttonTooltip)} placement="left">
      {children}
    </MarkerTooltip>
  )
}

type ScannerButtonProps = ScannerButtonContentProps & ButtonProps

export const ScannerButton = ({
  contentType,
  ...props
}: ScannerButtonProps) => {
  const history = useHistory()

  return (
    <MarkerTooltipWrapper contentType={contentType}>
      <Button
        size="squareSm"
        data-testid="scannerButton"
        onClick={() => history.push(PATHS.customer.scanner)}
        labelProps={{
          textStyle: 'textSmallSb',
          color: 'white',
        }}
        leftIcon={<ScannerIcon />}
        {...props}
      >
        <ScannerButtonContent contentType={contentType} />
      </Button>
    </MarkerTooltipWrapper>
  )
}
