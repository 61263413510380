import { Box, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { translations } from 'locales/translations'

import { ArticleInputHandlersProvider } from 'app/pages/common/marketplaceAndSearch/components/articleInputHandlersContext/articleInputHandlersContext'
import { MarkerTooltip } from 'app/components/tooltips/MarkerTooltip'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import {
  findSelectedArticleDataWithThrow,
  getAvailableNonRestrictedFoundArticles,
} from 'app/pages/customers/common/fastOrder/utils'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'
import { PersistentAmountInput } from 'app/pages/common/marketplaceAndSearch/components/AmountInput'

import { useArticleActions } from '../useArticleActions'
import { ChooseArticleButton } from '../ChooseArticleButton'
import { DeleteArticleButton } from '../DeleteArticleButton'
import { ArticleActionsWrapper } from '../styles'

import { MoveArticleToCartButton } from './components/MoveArticleToCartButton'

const messages = translations.pages.customer.search.searchArticle

export const AvailableArticleActions = () => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()
  const [isArticleQuantityUpdating, setIsArticleQuantityUpdating] =
    useState(false)
  const {
    fastOrderListItem: { foundArticles, quantity },
    fastOrderListItem,
  } = useFastOrderListItemContext()

  const {
    deleteMutation,
    deleteHandler,
    updateMutation,
    updateHandler,
    moveToCartMutation,
    moveToCartHandler,
  } = useArticleActions()

  // INFO: only available non-restricted articles can be chosen
  const filteredFoundArticles =
    getAvailableNonRestrictedFoundArticles(foundArticles)

  const hasMultipleFoundArticles = filteredFoundArticles.length > 1

  // INFO: should be defined as the article is available
  const selectedArticleData =
    findSelectedArticleDataWithThrow(fastOrderListItem)

  if (quantity === undefined) throw new Error('quantity should be defined')

  const chooseArticleButtonRendered = hasMultipleFoundArticles && (
    <ChooseArticleButton displayOnlyIcon />
  )

  return (
    <ArticleActionsWrapper>
      {!isMobile && chooseArticleButtonRendered}

      <ArticleInputHandlersProvider
        deleteUseMutationResult={deleteMutation || moveToCartMutation}
        updateUseMutationResult={updateMutation}
        deleteHandler={deleteHandler || moveToCartHandler}
        updateHandler={updateHandler}
      >
        <Box maxWidth="120px">
          <PersistentAmountInput
            quantityInterval={selectedArticleData.quantityInterval}
            minimumOrder={selectedArticleData.minimumOrder}
            quantity={quantity}
            onArticleQuantityUpdate={setIsArticleQuantityUpdating}
            size="sm"
          />
        </Box>
      </ArticleInputHandlersProvider>

      <Flex gap="8px">
        <DeleteArticleButton
          onClick={deleteHandler}
          isLoading={deleteMutation.isPending || deleteMutation.isSuccess}
        />

        {isMobile && chooseArticleButtonRendered}

        <MarkerTooltip content={t(messages.addToBasket)}>
          <MoveArticleToCartButton
            onClick={moveToCartHandler}
            isLoading={moveToCartMutation.isPending}
            isDisabled={isArticleQuantityUpdating}
          />
        </MarkerTooltip>
      </Flex>
    </ArticleActionsWrapper>
  )
}
