import { useMutation, useQueryClient } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'
import type { SuppressGlobalOnErrorProp } from 'queryClient/types'

import { EXTERNAL_REFERENCES_LIST_QUERY_KEY } from '../List'
import { ARTICLE_LISTS_QUERY_KEY } from '../../ArticleLists/List'

export const useCreateArticleExternalReference = ({
  suppressGlobalOnError,
}: SuppressGlobalOnErrorProp = {}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: generatedApi.customers.createArticleExternalReference.request,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [EXTERNAL_REFERENCES_LIST_QUERY_KEY],
      })

      queryClient.invalidateQueries({
        queryKey: [ARTICLE_LISTS_QUERY_KEY],
      })
    },
    meta: { suppressGlobalOnError },
  })
}
