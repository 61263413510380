import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { PencilIcon } from 'app/components/icons'
import { Tooltip, TooltipTriggerIconWrapper } from 'app/components/tooltips'
import { showEditArticleModal } from 'app/pages/common/editArticleModalFlow/modalFlowActions'
import type { ModelSearchArticleListItem } from 'app/containers/customers/ArticleLists/Articles/search/types'
// INFO: We need to register the modals
import 'app/pages/common/editArticleModalFlow/EditArticleModal/EditArticleModal'
import 'app/pages/common/editArticleModalFlow/ExternalReferenceFormModal/ExternalReferenceFormModal'
import 'app/pages/common/editArticleModalFlow/ConfirmDeleteExternalReferenceModal/ConfirmDeleteExternalReferenceModal'

const messages = translations.pages.customer.articleDetails.articleActions.edit

type EditArticleIconProps = Pick<
  ModelSearchArticleListItem['articleData'],
  'article'
>

export const EditArticleIcon = ({ article }: EditArticleIconProps) => {
  const { t } = useTranslation()

  const { id: articleId } = article

  return (
    <>
      <Tooltip
        contentPadding="8px 16px"
        content={
          <Typography textStyle="textSmall" color="white">
            {t(messages.label)}
          </Typography>
        }
      >
        <TooltipTriggerIconWrapper
          data-testid="editArticleTooltipTrigger"
          onClick={() => showEditArticleModal({ articleId })}
        >
          <PencilIcon />
        </TooltipTriggerIconWrapper>
      </Tooltip>
    </>
  )
}
