import { useDisclosure } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Button } from 'app/components/Buttons/Button'
import { ScannerIcon } from 'app/components/icons'
// eslint-disable-next-line boundaries/element-types
import { MobileScannerComponent } from 'app/pages/customers/common/MobileScannerComponent'

import { BARCODE_FIELD_NAME } from '../consts'

const messages =
  translations.pages.customer.editArticleDetailsModal.externalReferences.form
    .mobileScanner

export const BarcodeFieldScannerButton = () => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size="squareMd" variant="secondary" onClick={onOpen}>
        <ScannerIcon />
      </Button>

      {isOpen && (
        <MobileScannerComponent
          modalProps={{ isOpen }}
          onScannerCloseHandler={onClose}
          onScanSuccessHandler={({ decodedMessage }) => {
            setFieldValue(BARCODE_FIELD_NAME, decodedMessage)
            onClose()
          }}
          header={t(messages.header)}
          scannerReadyMessage={t(messages.scannerReadyMessage)}
        />
      )}
    </>
  )
}
