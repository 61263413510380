import { colors } from 'theme/colors'

import type { IconProps } from '../types'

export type TickInsideCircleIconProps = {
  circleStroke?: string
  tickStroke?: string
} & IconProps

export const TickInsideCircleIcon = ({
  width = '20px',
  height = '20px',
  circleStroke = colors.contentB,
  tickStroke = colors.contentB,
  fill = 'none',
  style,
}: TickInsideCircleIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle cx="10" cy="10" r="8" stroke={circleStroke} strokeWidth="2" />
    <path d="M6 10L9 13L13.875 7" stroke={tickStroke} strokeWidth="2" />
  </svg>
)

export const GreenTickInsideCircleIcon = (
  props: Omit<IconProps, 'fill' | 'stroke'>,
) => (
  <TickInsideCircleIcon
    fill={colors.green_600}
    circleStroke={colors.green_600}
    tickStroke={colors.white}
    {...props}
  />
)

export const GrayTickInsideCircleIcon = ({
  color = colors.contentB,
  ...props
}: Omit<IconProps, 'fill' | 'stroke'>) => (
  <TickInsideCircleIcon circleStroke={color} tickStroke={color} {...props} />
)
