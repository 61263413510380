import { generatePath } from 'react-router-dom'

import { api } from 'apiServices'

import { customerPath } from '../consts'

import type { API__UpdateVendorCustomerRequestArgs } from './types'

export const url = customerPath

export const updateVendorCustomerRequest = ({
  customerId,
  body,
}: API__UpdateVendorCustomerRequestArgs) =>
  api.patch<unknown, undefined>(generatePath(url, { customerId }), body)
