import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { ModalButton } from './ModalButton'
import type { CloseModal, ModalWithFooterProps } from './types'

const { save } = translations.modal

export type ModalSaveButtonProps = Pick<
  ModalWithFooterProps,
  | 'onSaveHandler'
  | 'isSaveButtonLoading'
  | 'isSaveButtonDisabled'
  | 'saveTranslation'
> & {
  closeModal: CloseModal
  isSubmitButton?: boolean
}

export const ModalSaveButton = ({
  isSubmitButton = true,
  isSaveButtonLoading,
  isSaveButtonDisabled,
  onSaveHandler,
  saveTranslation,
  closeModal,
}: ModalSaveButtonProps) => {
  const { t } = useTranslation()

  return (
    <ModalButton
      dataTestId="saveButton"
      type={isSubmitButton ? 'submit' : 'button'}
      isLoading={isSaveButtonLoading}
      isDisabled={isSaveButtonDisabled || isSaveButtonLoading}
      onClick={(event) => {
        if (typeof onSaveHandler === 'function')
          onSaveHandler(closeModal, event)
      }}
    >
      {saveTranslation || t(save)}
    </ModalButton>
  )
}
