import type { Column } from 'react-table'
import { useMemo } from 'react'

import type { ModelGetArticleListData } from 'libs/openapiGeneratedApi/Api'

import type { ModelSearchArticleListItem } from 'app/containers/customers/ArticleLists/Articles/search/types'

import type { ArticleListDetailItemCellType } from './components'
import {
  ArticleListItemCell,
  PriceAndActionsCell,
  SelectArticleCheckbox,
} from './components'

type UseColumnsProps = Pick<ModelGetArticleListData, 'accessLevel' | 'id'>

export const useColumns = ({
  id: articleListId,
  accessLevel,
}: UseColumnsProps) =>
  useMemo<Column<ModelSearchArticleListItem>[]>(
    () => [
      {
        id: 'selectArticleColumn',
        accessor: (originalRow) => originalRow,
        Cell: SelectArticleCheckbox,
        maxWidth: 20,
        alignSelf: 'center',
      },

      {
        id: 'articleData',
        accessor: (originalRow) => originalRow,
        Cell: ArticleListItemCell,
      },
      {
        id: 'priceAndActions',
        accessor: (originalRow) => originalRow,
        Cell: ({ value }: ArticleListDetailItemCellType) => (
          <PriceAndActionsCell
            articleListDetailsItem={value}
            articleListId={articleListId}
            accessLevel={accessLevel}
          />
        ),
        maxWidth: 310,
        align: 'right',
        alignSelf: 'center',
      },
    ],
    [accessLevel, articleListId],
  )
