import type { StackProps } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import type { CSSProperties } from 'react'

import type { Color } from 'theme/colors'
import { useTheme } from 'theme'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { Divider } from '../Divider'

export type PanelProps = Omit<StackProps, 'bg' | 'color' | 'flexDirection'> & {
  color?: Color
  bg?: Color
  borderRadius?: CSSProperties['borderRadius']
  withDivider?: boolean
  isWithoutBorder?: boolean
}

const StackTyped = (props: PanelProps) => <Stack {...props} />

export const Panel = ({
  withDivider,
  isWithoutBorder,
  ...rest
}: PanelProps) => {
  const { isMobile } = useBreakpointScreenSize()
  const { borders } = useTheme()

  return (
    <StackTyped
      align="start"
      p={isMobile ? '16px' : '20px'}
      spacing={isMobile ? '16px' : '20px'}
      borderRadius="6px"
      {...(withDivider && { divider: <Divider /> })}
      {...(!isWithoutBorder && { border: borders.primary })}
      {...rest}
    />
  )
}
