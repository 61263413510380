import type { UseCheckboxReturn, FlexProps } from '@chakra-ui/react'
import { chakra, Flex } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import styled from '@emotion/styled'

type CheckboxWrapperProps = UseCheckboxReturn['htmlProps'] & {
  withHoverEffect?: boolean
  padding?: string
  children?: ReactNode
  isVerticallyCentered?: boolean
  withLabel: boolean
}

export const CheckboxWrapper = styled(
  ({
    withHoverEffect,
    isVerticallyCentered,
    padding,
    withLabel,
    ...rest
  }: CheckboxWrapperProps) => <chakra.label {...rest} />,
)(
  ({
    withLabel,
    withHoverEffect,
    isVerticallyCentered,
    padding,
    theme: { colors },
  }) => ({
    width: withLabel ? '100%' : 'auto',
    padding,
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    borderRadius: '6px',
    cursor: 'pointer',
    alignItems: isVerticallyCentered ? 'center' : 'flex-start',

    ...(withHoverEffect && {
      ':hover': {
        backgroundColor: colors.backgroundC,
      },
    }),
  }),
)

type CheckboxStyledProps = FlexProps & {
  hasFilledBackground?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  isChecked: boolean
}

export const CheckboxStyled = styled(
  ({
    hasFilledBackground,
    isInvalid,
    isDisabled,
    isChecked,
    as,
    ...rest
  }: CheckboxStyledProps) => <Flex as={as} {...rest} />,
)(
  ({
    isChecked,
    hasFilledBackground,
    isInvalid,
    isDisabled,
    theme: { colors },
  }) => ({
    width: '20px',
    height: '20px',
    border: `2px solid ${colors.contentC}`,
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    ...(hasFilledBackground && {
      borderColor: colors.blue_500,
      backgroundColor: colors.blue_500,
    }),
    ...(isInvalid && {
      borderColor: colors.red_500,
      backgroundColor: colors.red_100,
    }),
    ...(isDisabled && {
      borderColor: colors.contentOff,
      cursor: 'default',
      ...(isChecked ? { backgroundColor: colors.contentOff } : {}),
    }),
  }),
)
