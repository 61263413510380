import { publicApi } from 'apiServices'

import type { API__LogoutRequestArgs } from './api.types'

export const scope = 'authentication'

export const loginUrl = `${scope}/login`
export const logoutUrl = `${scope}/logout`

export const logoutRequest = (params: API__LogoutRequestArgs) =>
  publicApi.post<unknown, undefined>(logoutUrl, params)
