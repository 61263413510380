import { useTranslation } from 'react-i18next'
import type { SystemStyleObject } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { translations } from 'locales/translations'
import type {
  ModelGetArticleListData,
  ModelSearchArticleListItemsData,
} from 'libs/openapiGeneratedApi/Api'

import { EmptyListIcon, EmptySearchIcon } from 'app/components/icons'
import { Table } from 'app/components/tables'
import { useSearchAndPagination } from 'app/hooks/useSearchAndPagination'
import type { EmptyCaseWrapperProps } from 'app/components/EmptyCaseWrapper'

import { useColumns } from './useColumns'
import { GoToCatalogsButton, SelectActionsBar } from './components'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details

type ArticleListItemsTableProps = {
  detailsData: ModelGetArticleListData
  articlesData: ModelSearchArticleListItemsData
}

export const ArticleListItemsTable = ({
  detailsData,
  articlesData,
}: ArticleListItemsTableProps) => {
  const { t } = useTranslation()
  const { borders } = useTheme()
  const { term } = useSearchAndPagination()

  const { id, accessLevel } = detailsData
  const { items: articles, metadata } = articlesData
  const hasItems = articles && articles.length > 0

  const columns = useColumns({ id, accessLevel })

  const customStyles: SystemStyleObject = {
    '.table-body': {
      border: borders.primary,
      borderRadius: '6px',
    },
  }

  const emptyCaseWrapperProps: Omit<EmptyCaseWrapperProps, 'data'> = term
    ? {
        icon: <EmptySearchIcon />,
        message: t(translations.emptyList.default),
      }
    : {
        icon: <EmptyListIcon />,
        message: t(messages.emptyCase.title),
        subHeader: t(messages.emptyCase.subHeader),
        action: <GoToCatalogsButton />,
      }

  return (
    <Box>
      {(hasItems || !!term) && <SelectActionsBar />}

      <Table
        columns={columns}
        data={articles}
        pageCount={metadata?.pageCount}
        sx={customStyles}
        withoutHeadersRow
        withoutHeadersRowDivider
        withoutLastRowBorder
        emptyCaseWrapperProps={emptyCaseWrapperProps}
      />
    </Box>
  )
}
