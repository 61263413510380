import type { CSSProperties } from 'react'

import type { ModelVendorPreviewArticlesSearchSearchArticlesByEnum } from 'libs/openapiGeneratedApi/Api'

import { BareSelect } from 'app/components/inputs/Select'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { useMarketplaceSearchAndPagination } from '../../useMarketplaceSearchAndPagination'

import { useSearchPresetSelect } from './useSearchPresetSelect'
import { useChakraStyles, useSelectComponents } from './styles'
import { MobileSearchPresets } from './components'

export const SearchPresetSelect = ({
  controlHeight,
}: { controlHeight?: CSSProperties['height'] } = {}) => {
  const { isMobile } = useBreakpointScreenSize()
  const { setSearchPreset } = useMarketplaceSearchAndPagination()
  const { options, activeSearchPreset } = useSearchPresetSelect()

  const chakraStyles = useChakraStyles({ controlHeight })
  const selectComponents = useSelectComponents()

  if (isMobile) return <MobileSearchPresets />

  return (
    <BareSelect
      name="searchPreset"
      options={options}
      value={activeSearchPreset}
      onChange={(option) => {
        setSearchPreset(
          option?.id as ModelVendorPreviewArticlesSearchSearchArticlesByEnum,
        )
      }}
      chakraStyles={chakraStyles}
      componentsMemoized={selectComponents}
    />
  )
}
