import { Route, Switch } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { FullPageLoader } from 'app/components/loaders/FullPageLoader'
import { ApprovalSuccess } from 'app/pages/customers/approveOrderFromLinkFlow/Success/Loadable'
import { OrderAlreadyApproved } from 'app/pages/customers/approveOrderFromLinkFlow/AlreadyApproved/Loadable'
import { LinkExpired } from 'app/pages/customers/approveOrderFromLinkFlow/Expired/Loadable'
import { OrderAlreadyRejected } from 'app/pages/customers/approveOrderFromLinkFlow/AlreadyRejected/Loadable'

import {
  useIsOnInitialPage,
  useProcessApprovalLinkWithRedirects,
} from './hooks'

export const ApproveOrderFromLinkRoutes = () => {
  useProcessApprovalLinkWithRedirects()

  const { isOnInitialPage } = useIsOnInitialPage()

  if (isOnInitialPage) {
    return <FullPageLoader />
  }

  return (
    <Switch>
      <Route path={PATHS.approveOrder.success} exact>
        <ApprovalSuccess />
      </Route>
      <Route path={PATHS.approveOrder.alreadyApproved} exact>
        <OrderAlreadyApproved />
      </Route>
      <Route path={PATHS.approveOrder.alreadyRejected} exact>
        <OrderAlreadyRejected />
      </Route>
      <Route path={PATHS.approveOrder.expired} exact>
        <LinkExpired />
      </Route>
    </Switch>
  )
}
