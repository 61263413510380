import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'

import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'

export const addItemToCartOciCustomerPostMock = http.post(
  getOciCustomerMockUrl(generatedApi.customers.addArticlesToCart.url),
  () => new HttpResponse(null, { status: 204 }),
)

export const makeAddItemToCartPostMockWithSpy = (spy: jest.Mock) =>
  http.post(
    getCustomerMockUrl(generatedApi.customers.addArticlesToCart.url),
    async ({ request }) => {
      const body = await request.json()

      spy(body)

      return new HttpResponse(null, { status: 204 })
    },
  )
