import { useTheme } from '@emotion/react'

import { usePageOffsetTop } from './usePageOffsetTop'

export const useViewportContentMaxHeight = () => {
  const pageOffsetTop = usePageOffsetTop()
  const { dimensionsRaw } = useTheme()

  return {
    maxHeight: `calc(100vh - ${pageOffsetTop} - ${dimensionsRaw.contentPaddingY}px * 2)`,
  }
}
