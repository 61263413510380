import { type FlexProps, type StackProps, Flex, Stack } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { useTheme } from 'theme'

import type { Orientation, RadioOptionProps } from './types'

type RadioGroupStyledProps = StackProps & { orientation?: Orientation }

export const RadioGroupStyled = forwardRef<
  HTMLInputElement,
  RadioGroupStyledProps
>(({ orientation = 'vertical', ...props }, ref) => (
  <Stack
    flex={1}
    flexDirection={orientation === 'vertical' ? 'column' : 'row'}
    {...(orientation === 'horizontal' && { width: '100%' })}
    columnGap="16px"
    overflow="auto"
    ref={ref}
    {...props}
  />
))

type RadioOptionStyledProps = FlexProps &
  Partial<Pick<RadioOptionProps, 'withRadioFirst' | 'orientation'>>

export const RadioOptionStyled = ({
  withRadioFirst,
  orientation,
  alignItems,
  borderRadius,
  justifyContent,
  padding,
  ...flexProps
}: RadioOptionStyledProps) => {
  const { colors } = useTheme()

  return (
    <Flex
      flexDirection={withRadioFirst ? 'row-reverse' : 'row'}
      flex={orientation === 'horizontal' ? '1' : 'initial'}
      alignItems={alignItems || 'center'}
      justifyContent={
        justifyContent ||
        (orientation === 'horizontal' && 'flex-end') ||
        'space-between'
      }
      padding={padding || '14px 12px'}
      gap="16px"
      cursor="pointer"
      borderRadius={borderRadius || '6px'}
      _hover={{
        backgroundColor: colors.backgroundC,
      }}
      {...flexProps}
    />
  )
}

type RadioStyledProps = FlexProps & { checked?: boolean; isDisabled?: boolean }

export const RadioStyled = styled(
  ({ checked, as, isDisabled, ...props }: RadioStyledProps) => (
    <Flex as={as} {...props} />
  ),
)(({ isDisabled, checked, theme: { colors } }) => ({
  minWidth: '20px',
  width: '20px',
  height: '20px',
  border: `2px solid ${colors.contentC}`,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  ...(checked && {
    borderWidth: '7px',
    borderColor: colors.blue_500,
  }),
  ...(isDisabled && {
    borderColor: colors.contentOff,
  }),
}))
