import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { MobileButtonWithFullModal } from 'app/components/MobileButtonWithFullModal'
import { Button } from 'app/components/Buttons/Button'
import { PencilIcon } from 'app/components/icons'
import { useUpdateFastOrderListItemMutation } from 'app/containers/customers/FastOrders/Update'
import { FooterButton } from 'app/components/inputs/DatePicker/DatePickerSelect/styles'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

import type { ChooseArticleButtonProps } from '../types'

import { FoundArticlesList } from './FoundArticlesList'

const messages = translations.pages.customer.scanner.article

export const MobileChooseArticleButton = ({
  displayOnlyIcon,
}: ChooseArticleButtonProps) => {
  const { t } = useTranslation()

  const { fastOrderListEnum } = useFastOrderContext()

  const { mutate, isPending } = useUpdateFastOrderListItemMutation({
    fastOrderListEnum,
  })

  const {
    fastOrderListItem: {
      id: fastOrderListItemId,
      selectedArticle: selectedArticleFromQuery,
    },
  } = useFastOrderListItemContext()

  const [selectedArticle, setSelectedArticle] = useState(
    selectedArticleFromQuery,
  )

  const onSaveHandler = ({ close }: { close: () => void }) =>
    mutate({ fastOrderListItemId, selectedArticle }, { onSuccess: close })

  return (
    <MobileButtonWithFullModal
      mt={0}
      minHeight="100%"
      title={t(messages.chooseArticle)}
      openModalButton={({ open }) => (
        <Button
          variant="secondary"
          size="sm"
          onClick={open}
          dataTestId="chooseArticleButton"
        >
          {displayOnlyIcon ? <PencilIcon /> : t(messages.chooseArticle)}
        </Button>
      )}
      footer={({ close }) => (
        <Flex gap="16px" w="100%">
          <FooterButton variant="secondary" onClick={close}>
            {t(translations.formActions.cancel)}
          </FooterButton>

          <FooterButton
            isLoading={isPending}
            onClick={() => onSaveHandler({ close })}
            isDisabled={selectedArticleFromQuery === selectedArticle}
          >
            {t(translations.formActions.save)}
          </FooterButton>
        </Flex>
      )}
    >
      <FoundArticlesList
        selectedArticle={selectedArticle}
        articleSelectHandler={(articleId: string) =>
          setSelectedArticle(articleId)
        }
      />
    </MobileButtonWithFullModal>
  )
}
