import { useIsFetching, useQuery } from '@tanstack/react-query'

import { api } from 'apiServices'

import { useCurrentUser } from 'app/containers/common/Auth'
import { searchRelatedQueriesCacheAndRetryOptions } from 'app/containers/common/Search'
import { useCustomerFilterQueryParam } from 'app/pages/common/marketplaceAndSearch/useCustomerFilterQueryParam'

import type {
  API__ExternalPricesHookParams,
  API__ExternalPricesResponse,
} from './types'
import { EXTERNAL_PRICES_QUERY_KEY } from './consts'
import { makeUrl } from './utils'

export const useExternalPricesQuery = (
  params?: API__ExternalPricesHookParams,
) => {
  const { articlesIds = [] } = params ?? {}
  const { isVendor } = useCurrentUser()
  const { customerId } = useCustomerFilterQueryParam()

  return useQuery({
    queryKey: [EXTERNAL_PRICES_QUERY_KEY, articlesIds],
    queryFn: () =>
      api.post<unknown, API__ExternalPricesResponse>(
        makeUrl({ isVendor, customerId }),
        articlesIds,
      ),
    enabled: articlesIds.length > 0 && (!isVendor || !!customerId),
    ...searchRelatedQueriesCacheAndRetryOptions,
  })
}

export const useIsFetchingAnyExternalPrices = () =>
  !!useIsFetching({ queryKey: [EXTERNAL_PRICES_QUERY_KEY] })
