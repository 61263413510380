import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useParams,
} from 'react-router-dom'
import queryString from 'query-string'

import { PATHS } from 'consts/paths'

import type { RegistrationAvailableCountry } from 'app/hooks/useAvailableCountries'
import { Login } from 'app/pages/public/Login/Loadable'
import {
  InvitationAccepted,
  InvitationExpired,
  PasswordInvitationForm,
  SignedUpConfirmation,
} from 'app/pages/public/invitationFlow'
import type { MODEL__InvitationState } from 'app/containers/public/Invitations/GetState'
import { useInvitationStateQuery } from 'app/containers/public/Invitations/GetState'
import { FullPageLoader } from 'app/components/loaders/FullPageLoader'

const getInitialRedirectPath = ({
  data,
  isError,
  invitationKey,
  countryOfOperation = 'DE',
}: {
  data?: MODEL__InvitationState
  isError: boolean
  invitationKey?: string
  countryOfOperation?: RegistrationAvailableCountry
}) => {
  if (isError || !data || !invitationKey) {
    return PATHS.root
  }

  let defaultDestination

  if (data.expired) {
    defaultDestination = PATHS.invitation.invitationExpired
  } else if (data.alreadyAccepted) {
    defaultDestination = PATHS.invitation.invitationAccepted
  } else if (data.passwordExists) {
    defaultDestination = PATHS.invitation.login
  } else {
    defaultDestination = PATHS.invitation.invitationSetPassword
  }

  return queryString.stringifyUrl({
    url: generatePath(defaultDestination, { invitationKey }),
    query: { countryOfOperation },
  })
}

export const InvitationRoutes = () => {
  const { invitationKey } = useParams<{
    invitationKey?: string
  }>()

  const countryOfOperation =
    (new URLSearchParams(window.location.search).get(
      'countryOfOperation',
    ) as RegistrationAvailableCountry | null) || 'DE'

  const { data, isError } = useInvitationStateQuery()

  if (!data && !isError) {
    return <FullPageLoader />
  }

  const pending = !isError && !data?.alreadyAccepted && !data?.expired

  return (
    <Switch>
      {data?.expired && (
        <Route path={PATHS.invitation.invitationExpired}>
          <InvitationExpired />
        </Route>
      )}

      {data?.alreadyAccepted && (
        <Route path={PATHS.invitation.invitationAccepted}>
          <InvitationAccepted />
        </Route>
      )}

      {pending && !data?.passwordExists && (
        <Route path={PATHS.invitation.invitationSetPassword}>
          <PasswordInvitationForm />
        </Route>
      )}

      {pending && data?.passwordExists && (
        <Route path={PATHS.invitation.login}>
          <Login />
        </Route>
      )}

      <Route path={PATHS.invitation.signedUpConfirmation}>
        <SignedUpConfirmation />
      </Route>

      <Redirect
        to={getInitialRedirectPath({
          data,
          isError,
          invitationKey,
          countryOfOperation,
        })}
      />
    </Switch>
  )
}
