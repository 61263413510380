import type { MODEL__CustomerBusinessCategory } from 'consts/customerBusinessCategory'
import type { MODEL__VendorBusinessCategory } from 'consts/vendorBusinessCategory'

import type { UserRoles } from 'app/containers/common/Auth'

import type {
  CustomerContactTypes,
  VendorContactTypes,
} from '../contacts.types'

export const commonCompanyRegistrationMockData = {
  name: 'Doe Company',
  vatId: 'DE000010001',
  humanReadableId: '1234141',
  countryCode: 'DE',
  streetName: 'Doe Street',
  streetNumber: '123',
  postalCode: '01234',
  city: 'City',
  publicVendor: true,
  migrated: true,
  currency: 'GBP',
} as const

export const makeBusinessInfoMockData = (
  companyType: Extract<UserRoles, 'vendor' | 'customer'>,
) =>
  ({
    businessCategories:
      companyType === 'vendor'
        ? ([
            'OCCUPATIONAL_SAFETY_PPE',
            'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY',
          ] as MODEL__VendorBusinessCategory[])
        : null,
    businessCategory:
      companyType === 'customer'
        ? ('FINANCE_INSURANCE_REAL_ESTATE' as MODEL__CustomerBusinessCategory)
        : null,
    countryOfOperation: 'DE',
    websiteUrl: null,
    termsOfBusinessUrl: 'https://termsOfBusinessUrl.com',
    publiclyNamed: false,
    currency: 'GBP',
    dac7Completed: true,
  }) as const

export const customerProfileContactsMockData: CustomerContactTypes = {
  main: {
    title: 'MR',
    email: 'joe.doe@example.com',
    firstName: 'joe',
    lastName: 'doe',
    phoneNumber: '+49 123123123',
  },
  billingAccounting: null,
  dataPrivacy: null,
  technicalIntegration: {
    title: 'MR',
    email: 'joe.technik@example.com',
    firstName: 'joe',
    lastName: 'technik',
    phoneNumber: '+49 111111123',
  },
}

export const vendorProfileContactsMockData: VendorContactTypes = {
  main: {
    title: 'MR',
    email: 'joe.doe@example.com',
    firstName: 'joe',
    lastName: 'doe',
    phoneNumber: '+49 123123123',
  },
  customerSupport: null,
  billingAccounting: null,
  dataPrivacy: null,
  technicalIntegration: {
    title: 'MR',
    email: 'joe.technik@example.com',
    firstName: 'joe',
    lastName: 'technik',
    phoneNumber: '+49 111111123',
  },
  platformInvoicesEmail: 'joe.invoice@example.com',
  vendorRequestNotificationEmail: null,
}
