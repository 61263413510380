import { useMutation } from '@tanstack/react-query'
import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { SuppressGlobalOnErrorProp } from 'queryClient/types'

import { getCustomerMockUrl } from 'app/containers/customers/utils'

import { useMutationOptions } from './utils'

export const useDeleteArticleFromArticleListMutation = ({
  articleListId,
  suppressGlobalOnError,
}: {
  articleListId: string
} & SuppressGlobalOnErrorProp) => {
  const mutationOptions = useMutationOptions(articleListId)

  return useMutation({
    mutationFn: (articleId: string) =>
      generatedApi.customers.deleteArticleListItem.request({
        articleListId,
        articleId,
      }),
    ...mutationOptions,
    meta: { suppressGlobalOnError },
  })
}

export const makeDeleteArticleFromArticleListMock = (mockFn?: jest.Mock) =>
  http.delete(
    getCustomerMockUrl(generatedApi.customers.deleteArticleListItem.url),
    async ({ params }) => {
      const { articleId } = params as {
        articleId: string
      }

      if (mockFn) {
        mockFn(articleId)
      }

      return new HttpResponse(null, { status: 200 })
    },
  )

export const deleteArticleFromArticleListMock =
  makeDeleteArticleFromArticleListMock()
