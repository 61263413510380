import type { ReactNode } from 'react'
import { Popover, PopoverTrigger, PopoverBody } from '@chakra-ui/react'

import type { ModelOrderOptions } from 'app/containers/common/Search/types/response'

import { BulkPrices } from './BulkPrices'
import { BulkPricesPopoverContentWrapper } from './styles'

type BulkPricesPopoverProps = {
  pricing: NonNullable<ModelOrderOptions>['pricing'] | undefined
  orderUnit: string
  isOpen: boolean | undefined
  children: ReactNode
}

export const BulkPricesPopover = ({
  pricing,
  orderUnit,
  isOpen,
  children,
}: BulkPricesPopoverProps) => (
  <Popover isOpen={isOpen} placement="auto-start">
    <PopoverTrigger>{children}</PopoverTrigger>
    <BulkPricesPopoverContentWrapper>
      <PopoverBody
        padding="0 16px 12px"
        boxShadow="0px 16px 24px -6px #2655AB29"
      >
        <BulkPrices pricing={pricing} orderUnit={orderUnit} />
      </PopoverBody>
    </BulkPricesPopoverContentWrapper>
  </Popover>
)
