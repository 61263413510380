import { TEST_ARTICLE_EXTERNAL_PRICE } from '../../consts'
import type { API__ExternalPricesResponse } from '../types'

export const makeData = (articleIds?: string[]): API__ExternalPricesResponse =>
  articleIds?.map((id) => ({
    articleId: id,
    orderOptions: {
      restricted: false,
      priceQuantity: '1',
      pricing: [
        {
          quantity: '1',
          price: {
            amount: TEST_ARTICLE_EXTERNAL_PRICE,
            currency: 'GBP',
          },
        },
      ],
    },
  })) ?? []
