import { ArticleLinkWrapper } from 'app/pages/common/marketplaceAndSearch/components/ArticleLinkWrapper'
import { ImageWithFallback } from 'app/components/ImageWithFallback'

import type { GenericArticleContentProps } from '../types'

type ArticleImageWithOptionalRedirectProps = {
  articleData: Pick<
    GenericArticleContentProps['articleData'],
    'articleId' | 'thumbnailUrl' | 'name'
  >
} & Pick<GenericArticleContentProps, 'isWithoutLinks'>

export const ArticleImageWithOptionalRedirect = ({
  isWithoutLinks,
  articleData: { articleId, thumbnailUrl, name },
}: ArticleImageWithOptionalRedirectProps) => (
  <ArticleLinkWrapper articleId={isWithoutLinks ? undefined : articleId}>
    <ImageWithFallback src={thumbnailUrl} alt={name} size={40} />
  </ArticleLinkWrapper>
)
