import type { UseMutationResult } from '@tanstack/react-query'
import { createContext, useContext, useMemo } from 'react'
import { enqueueSnackbar } from 'notistack'

import { useDeleteItemFromCartMutation } from 'app/containers/customers/CurrentCart/Items/Delete'
import { useUpdateCartItemMutation } from 'app/containers/customers/CurrentCart/Items/Update'
import { useCartItemsLightQuery } from 'app/containers/customers/CurrentCart/Items/List'

import type {
  ArticleInputHandlersContextType,
  ArticleInputHandlersProviderProps,
  CartArticleInputHandlersProviderProps,
} from './types'

// INFO: this context exists to mitigate the problem of not being able to get some specific mutation hook instance state (isLoading, isSuccess etc)
// TODO: in react query v5 there is a hook for it https://tanstack.com/query/v5/docs/react/reference/useMutationState https://simplesystem.atlassian.net/browse/S2NG-6018

const defaultCallback = (_arg: unknown) => {
  throw new Error(
    'either Provider missing in the tree or your component is a modal rendered in a portal',
  )
}

const useMutationDefault = (() => ({
  mutate: defaultCallback,
})) as unknown as UseMutationResult

export const ArticleInputHandlersContext =
  createContext<ArticleInputHandlersContextType>({
    deleteUseMutationResult: useMutationDefault,
    deleteHandler: defaultCallback,
    updateUseMutationResult: useMutationDefault,
    updateHandler: defaultCallback,
  })

export const CartArticleInputHandlersProvider = ({
  children,
  cartItemId,
  article,
}: CartArticleInputHandlersProviderProps) => {
  const { data: cartArticles } = useCartItemsLightQuery()
  const deleteItemFromCartMutationResult = useDeleteItemFromCartMutation(
    cartItemId,
  ) as UseMutationResult

  const isArticleMaic =
    (cartArticles?.filter((item) => item.articleId === article.id).length ??
      0) > 1

  const updateItemFromCartMutationResult =
    useUpdateCartItemMutation() as UseMutationResult

  const deleteHandler = (onError?: () => void) => {
    if (!cartItemId) throw new Error('No such item in the cart')
    deleteItemFromCartMutationResult.mutate(undefined, {
      onSuccess: () =>
        enqueueSnackbar({
          variant: 'cartItem',
          article,
          type: isArticleMaic ? 'positionRemoved' : 'removed',
        }),
      onError,
    })
  }

  const updateHandler = (newQuantity: number, onError?: () => void) => {
    if (!cartItemId) throw new Error('No such item in the cart')

    updateItemFromCartMutationResult.mutate(
      { cartItemId, quantity: newQuantity },
      { onError },
    )
  }

  return (
    <ArticleInputHandlersContext.Provider
      value={{
        deleteUseMutationResult: deleteItemFromCartMutationResult,
        updateUseMutationResult: updateItemFromCartMutationResult,
        deleteHandler,
        updateHandler,
      }}
    >
      {children}
    </ArticleInputHandlersContext.Provider>
  )
}

export const ArticleInputHandlersProvider = ({
  children,
  deleteHandler,
  updateHandler,
  deleteUseMutationResult,
  updateUseMutationResult,
}: ArticleInputHandlersProviderProps) => {
  const valueMemoized = useMemo(
    () => ({
      deleteUseMutationResult,
      updateUseMutationResult,
      deleteHandler,
      updateHandler,
    }),
    [
      deleteHandler,
      deleteUseMutationResult,
      updateHandler,
      updateUseMutationResult,
    ],
  )

  return (
    <ArticleInputHandlersContext.Provider value={valueMemoized}>
      {children}
    </ArticleInputHandlersContext.Provider>
  )
}

export const useArticleInputHandlersContext = () => {
  const articleInputHandlersContext = useContext(ArticleInputHandlersContext)

  if (!articleInputHandlersContext) {
    throw new Error(
      'useArticleInputHandlersContext has to be used within <ArticleInputHandlersProvider>',
    )
  }

  return articleInputHandlersContext
}
