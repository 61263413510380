import { useSelector } from 'react-redux'

import { baseIdentitySelector } from 'app/containers/common/Auth/selectors'

export const useCurrentUser = () => {
  const baseIdentity = useSelector(baseIdentitySelector)
  const { userRole } = baseIdentity

  const userRoleFlags = {
    isOciCustomer: userRole === 'ociCustomer',
    isCustomer: userRole === 'customer',
    isVendor: userRole === 'vendor',
    isBackofficeUser: userRole === 'backofficeUser',
    noIdentitySelected: userRole === 'noIdentitySelected',
  }

  const extendedIdentity = {
    ...baseIdentity,
    ...userRoleFlags,
  }

  return extendedIdentity
}
