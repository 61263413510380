/*
 * INFO: 'wasPageRefreshed' case is about "user app" using old frontend app (old main js and chunks),
 * trying to fetch js chunk files that after deployment are not available on the server anymore
 *
 * Before forcing reload, we are using `promiseRetry` to retry with a timeout of 100ms when e.g. there
 * are network errors
 */

import React, { lazy, Suspense } from 'react'
import promiseRetry from 'promise-retry'

interface Opts {
  fallback: React.ReactNode
}

const STORAGE_PAGE_REFRESHED_KEY = 's2ng-page-was-force-refreshed'

export const lazyLoad = <
  T extends Promise<any>,
  U extends React.ComponentType<any>,
>(
  importFunc: () => T,
  selectorFunc: (s: Awaited<T>) => U,
  opts: Opts = { fallback: null },
) => {
  const lazyFactory = async () => {
    const wasPageRefreshed: boolean = JSON.parse(
      window.localStorage.getItem(STORAGE_PAGE_REFRESHED_KEY) || 'false',
    )

    try {
      const module = await promiseRetry((retry) => importFunc().catch(retry), {
        retries: 1,
        minTimeout: 100,
      })

      window.localStorage.setItem(STORAGE_PAGE_REFRESHED_KEY, 'false')

      if (selectorFunc) return { default: selectorFunc(module) }
    } catch (error) {
      if (!wasPageRefreshed) {
        window.localStorage.setItem(STORAGE_PAGE_REFRESHED_KEY, 'true')

        return window.location.reload()
      }

      throw error
    }
  }

  const LazyComponent = lazy(lazyFactory as () => Promise<{ default: U }>)

  return (props: React.ComponentProps<U>): JSX.Element => (
    <Suspense fallback={opts.fallback}>
      <LazyComponent {...props} />
    </Suspense>
  )
}
