import { useCallback } from 'react'
import { useQueryParam } from 'use-query-params'

export const ARTICLE_LISTS_NAME_SORT_KEY = 'articleListsNameSort'

export type ArticleListsNameSort = 'name,desc' | 'name,asc'

export const useArticleListsNameSortQueryParam = () => {
  const [queryValue, setQueryValue] = useQueryParam<ArticleListsNameSort>(
    ARTICLE_LISTS_NAME_SORT_KEY,
  )

  const queryArticleListsNameSort: ArticleListsNameSort =
    queryValue ?? 'name,asc'

  const toggleArticleListsNameSort = useCallback(() => {
    setQueryValue(
      queryArticleListsNameSort === 'name,desc' ? 'name,asc' : 'name,desc',
    )
  }, [queryArticleListsNameSort, setQueryValue])

  return {
    queryArticleListsNameSort,
    toggleArticleListsNameSort,
  }
}
