import { Flex, Tab } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { MAX_WIDTH_1310PX_HEADER_BREAKPOINT_MEDIA_QUERY } from '../../useCustom1310Breakpoint'

export const TabsWrapper = styled(Flex)(({ theme: { mediaQuery } }) => ({
  marginRight: '8px',
  [mediaQuery.small]: {
    marginTop: '0',
  },
}))

export const TabStyled = styled(Tab)(({ theme: { colors } }) => ({
  height: '48px',
  position: 'relative',
  fontSize: '13px',
  lineHeight: '16px',
  fontWeight: 600,
  color: colors.contentA,
  ':hover': {
    backgroundColor: colors.backgroundC,
  },
  ':focus': {
    boxShadow: 'none',
  },
  '&[aria-selected="true"]': {
    color: colors.blue_500,
    ':after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '44px',
      height: '4px',
      width: '100%',
      backgroundColor: colors.blue_500,
      borderRadius: '2px',
    },
  },
  [MAX_WIDTH_1310PX_HEADER_BREAKPOINT_MEDIA_QUERY]: {
    padding: '8px',
  },
}))
