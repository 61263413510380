import { useTranslation } from 'react-i18next'
import { type FormikHelpers } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import { merge } from 'lodash'

import { translations } from 'locales/translations'

import {
  getRequiredNumberFieldValidation,
  getRequiredStringFieldValidation,
} from 'app/components/fields/utils'
import { useCreateArticleExternalReference } from 'app/containers/customers/ExternalReferences/Create'
import { useEditArticleExternalReference } from 'app/containers/customers/ExternalReferences/Edit'
import { showGenericErrorModal } from 'app/App/globalModals'
import { useGetShowSuccessToast } from 'app/components/Notification/useGetShowSuccessToast'

import {
  type ExternalReferencesFormModalProps,
  hideExternalReferencesFormModal,
  showEditArticleModal,
} from '../modalFlowActions'

const messages = translations.pages.customer.editArticleDetailsModal

const useValidationSchema = () => {
  const { t } = useTranslation()

  const validationSchema = yup.object({
    barcode: getRequiredStringFieldValidation(
      t(messages.externalReferences.form.fields.barcode.label),
    ),
    quantity: getRequiredNumberFieldValidation({
      label: t(messages.externalReferences.form.fields.quantity.label),
      min: 1,
    }),
  })

  return { validationSchema }
}

type FormValues = yup.InferType<
  ReturnType<typeof useValidationSchema>['validationSchema']
>

export const useForm = ({
  articleId,
  externalReference,
}: ExternalReferencesFormModalProps) => {
  const { t } = useTranslation()
  const { validationSchema } = useValidationSchema()
  const { showSuccessToast } = useGetShowSuccessToast()

  const { mutate: create, isPending: isCreating } =
    useCreateArticleExternalReference({ suppressGlobalOnError: true })
  const { mutate: edit, isPending: isEditing } =
    useEditArticleExternalReference({ suppressGlobalOnError: true })

  const isEditMode = !!externalReference
  const { id: externalReferenceId, ...externalReferenceValue } =
    externalReference ?? {}

  const initialValues: FormValues = merge(
    { barcode: '', quantity: 1 },
    externalReferenceValue,
  )

  const onSubmitHandler = (
    data: FormValues,
    { resetForm, setFieldError }: FormikHelpers<FormValues>,
  ) => {
    const onSuccess = () => {
      resetForm()
      hideExternalReferencesFormModal()
      showSuccessToast({ content: t(messages.saveSuccessMessage) })
      showEditArticleModal({ articleId })
    }
    const onError = (error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        setFieldError(
          'quantity',
          t(
            messages.externalReferences.form.fields.quantity.errors
              .alreadyExists,
          ),
        )
      } else {
        showGenericErrorModal({ error })
      }
    }
    const params = { onSuccess, onError }

    if (isEditMode && externalReferenceId) {
      return edit(
        { articleId, externalReferenceId, data: { quantity: data.quantity } },
        params,
      )
    }

    return create({ articleId, data }, params)
  }

  return {
    validationSchema,
    initialValues,
    onSubmitHandler,
    isPending: isCreating || isEditing,
    isEditMode,
  }
}
