import { Typography, type TypographyProps } from 'app/components/Typography'

export const Heading = (props: TypographyProps) => (
  <Typography
    textStyle="textSmallLink"
    fontWeight="bolder"
    textTransform="uppercase"
    ml="8px"
    mb="4px"
    color="contentB"
    {...props}
  />
)
