import type { MobileScannerProps } from '../types'

import { setViewportMetaTagContent } from './utils'
import { useCaptureSentryExceptionForIOS } from './useCaptureSentryExceptionForIOS'

export const useResetZoom = ({
  onScannerCloseHandler,
}: Pick<MobileScannerProps, 'onScannerCloseHandler'>) => {
  // INFO: below solution with setting meta tag content does not work for iOS devices, so
  // we just monitor and capture sentry exception in case zoomed in page is opened on iOS device
  useCaptureSentryExceptionForIOS()

  // INFO: below meta tag content resets zoom to 100% and disables zoom (does not work on iOS devices)
  const setViewportMetaTagToResetAndDisableZoom = () => {
    setViewportMetaTagContent({
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no',
    })
  }

  // INFO: we want to reset and disable zoom only on mobile scanner page, so need to enable zoom when leaving the page
  const resetViewportMetaTagContent = () => {
    setViewportMetaTagContent({
      content: 'width=device-width, initial-scale=1.0',
    })
  }

  const handleMobileScannerClose = () => {
    resetViewportMetaTagContent()
    onScannerCloseHandler()
  }

  return { setViewportMetaTagToResetAndDisableZoom, handleMobileScannerClose }
}
