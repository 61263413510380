import { api } from 'apiServices'

import type {
  API__GetProfileResponse,
  API__UpdateProfileArgs,
  API__GetContactsResponse,
  API__UpdateContactsRequestProps,
} from './api.types'

export const profileScope = 'profile' as const
export const contactsScope = 'contacts' as const

export const getProfileRequest = () =>
  api.get<unknown, API__GetProfileResponse>(profileScope)

export const updateProfileRequest = (data: API__UpdateProfileArgs) =>
  api.patch<unknown, undefined>(profileScope, data)

export const getContactsRequest = () =>
  api.get<unknown, API__GetContactsResponse>(contactsScope)

export const updateContactsRequest = (data: API__UpdateContactsRequestProps) =>
  api.patch<unknown, undefined>(contactsScope, data)
