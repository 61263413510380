import { matchPath, useLocation } from 'react-router-dom'

import { PATHS } from 'consts/paths'
import type { Color } from 'theme/colors'

const PATHS_WITH_BACKGROUND = [
  {
    paths: [
      PATHS.customer.home,
      PATHS.customer.checkout.cart,
      PATHS.customer.checkout.address,
      PATHS.customer.checkoutSuccess,
    ],
    color: 'backgroundH',
  },
  {
    paths: [
      PATHS.customer.checkout.cart,
      PATHS.customer.checkout.address,
      PATHS.customer.checkoutSuccess,
      PATHS.customer.companyAndSettings.dashboard,
    ],
    color: 'backgroundB',
  },
] as const

export const usePageBackground = (): Color => {
  const { pathname } = useLocation()

  for (const { paths, color } of PATHS_WITH_BACKGROUND) {
    if (paths.some((path) => matchPath(pathname, { path, exact: true }))) {
      return color
    }
  }

  return 'white'
}
