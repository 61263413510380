import { getConfiguredAxiosInstance } from 'libs/request/axios'
import { withRefreshToken } from 'libs/request/utils/handleUnauthorizedRequest'
import { IS_TEST, EXTENDED_TESTING_OPTIONS } from 'consts/envVars'
import { Api } from 'libs/openapiGeneratedApi/Api'

import { createDefaultRequestInterceptor } from '../requestInterceptors'

export const api = getConfiguredAxiosInstance()

api.interceptors.request.use(
  createDefaultRequestInterceptor(),
  undefined,
  // INFO: We don't want to run this interceptor for the tests cause we inject the same interceptor manually for the tests with the proper redux store
  { runWhen: () => IS_TEST !== true },
)

withRefreshToken(api)

/*
 * axios instance generated from OpenAPI
 */
export const generatedApi = new Api()

getConfiguredAxiosInstance({
  axiosInstance: generatedApi.instance,
  withoutUnwrappingResponseData: true, // INFO: Generated api do the unpacking of response data by itself
  withoutCamelisation: true,
})

generatedApi.instance.interceptors.request.use(
  createDefaultRequestInterceptor(),
  undefined,
  // INFO: We don't want to run this interceptor for the tests cause we inject the same interceptor manually for the tests with the proper redux store
  { runWhen: () => IS_TEST !== true },
)

withRefreshToken(generatedApi.instance)

if (EXTENDED_TESTING_OPTIONS) {
  window.generatedApi = generatedApi
}
