import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { BareCheckbox } from 'app/components/inputs/Checkbox'
import { Typography } from 'app/components/Typography'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'

import { FastOrderListBulkActions } from './FastOrderListBulkActions'
import { BulkSelectionAndActionsWrapper } from './styles'

export const FastOrderListBulkSelectionAndActions = () => {
  const { t } = useTranslation()

  const {
    availableItemsCount,
    selectedItemsCount,
    selectAllItems,
    unselectAllItems,
  } = useFastOrderContext()

  const isMasterCheckboxDisabled = !availableItemsCount

  const isMasterCheckboxSelected =
    !isMasterCheckboxDisabled && availableItemsCount === selectedItemsCount

  const isMasterCheckboxIndeterminate =
    !isMasterCheckboxSelected && Boolean(selectedItemsCount)

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? selectAllItems() : unselectAllItems()
  }

  return (
    <BulkSelectionAndActionsWrapper>
      <BareCheckbox
        customAriaLabel="master checkbox"
        isIndeterminate={isMasterCheckboxIndeterminate}
        isChecked={isMasterCheckboxSelected}
        onChange={onChangeHandler}
        dataTestId="masterCheckbox"
        isDisabled={isMasterCheckboxDisabled}
      >
        <Typography>
          {t(translations.xFromYSelected, {
            x: selectedItemsCount,
            y: availableItemsCount,
          })}
        </Typography>
      </BareCheckbox>
      {!!selectedItemsCount && <FastOrderListBulkActions />}
    </BulkSelectionAndActionsWrapper>
  )
}
