import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const StickyScanningResultsInfoWrapper = styled(Flex)(({ theme }) => ({
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  right: '0px',
  backgroundColor: theme.colors.backgroundA,
  zIndex: theme.zIndexes.aboveModal,
  borderRadius: '8px 8px 0 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
}))
