import { history } from 'libs/history'

import { useCurrentUser } from 'app/containers/common/Auth'

import { BreadcrumbNav } from './BreadcrumbNav'
import { BackofficeNav } from './BackofficeNav'
import { CustomerNav } from './CustomerNav'
import { VendorNav } from './VendorNav'
import type { HeaderProps } from './types'

export const Header = ({
  breadcrumbs,
  onCancel = history.goBack,
}: HeaderProps) => {
  const { isCustomer, isOciCustomer, isVendor } = useCurrentUser()

  if (breadcrumbs) {
    return <BreadcrumbNav breadcrumbs={breadcrumbs} onCancel={onCancel} />
  }

  if (isCustomer || isOciCustomer) {
    return <CustomerNav />
  }

  if (isVendor) return <VendorNav />

  return <BackofficeNav />
}
