import _ from 'lodash'

import { useOciSession } from 'app/containers/common/Auth'
import { useFormatName } from 'app/hooks/useFormatName'
import type {
  CustomerPermissionGroup,
  MODEL__CustomerPrivilege,
  MODEL__AvailableFeatures,
} from 'app/models/companyUser'
import { FullPageLoader } from 'app/components/loaders/FullPageLoader' // eslint-disable-line boundaries/element-types

import { useUserMeQuery } from '../User'

import { useCompanyUserMeQuery } from './query'

export const useHasFeatureEnabled = (feature: MODEL__AvailableFeatures) => {
  const { data: { availableFeatures = [] } = {}, isPending } =
    useCompanyUserMeQuery()

  if (isPending) return undefined

  return availableFeatures.includes(feature)
}

export const useHasPrivilegeAssigned = (
  privilege: MODEL__CustomerPrivilege,
) => {
  const { data: { profile } = {} } = useCompanyUserMeQuery()

  if (!profile?.assignedPrivileges) return false

  return profile.assignedPrivileges.includes(privilege)
}

export const useCurrentCompanyUserFullName = () => {
  const { isOciSessionFlow } = useOciSession()
  const { data: { profile } = {} } = useCompanyUserMeQuery()
  const { data: userMeData } = useUserMeQuery()
  const formatName = useFormatName()

  if (isOciSessionFlow) return userMeData?.username

  return formatName(profile?.person)
}

// INFO: We need to have a hooks per company type to have proper types
export const useCheckCustomerPermission = () => {
  const { data: { profile } = {} } = useCompanyUserMeQuery()
  const serverPermissionGroup = profile?.permissionGroup

  const checkPermission = (
    permissionGroup: CustomerPermissionGroup | CustomerPermissionGroup[],
  ) =>
    _.castArray(permissionGroup).some((permission) =>
      serverPermissionGroup?.includes(permission),
    )

  const isAdmin = checkPermission('ADMIN')
  const isShopper = checkPermission('SHOPPER')

  return {
    isAdmin,
    isShopper,
    currentPermission: serverPermissionGroup,
  }
}

export const useLoaderIfFetchingPermissions = () => {
  const { data: { profile } = {} } = useCompanyUserMeQuery()

  if (!profile?.permissionGroup) return <FullPageLoader />
}
