import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { FooterCopyright, FooterLinks } from './components'

export type FooterProps = FlexProps

export const Footer = ({ justifyContent, ...rest }: FooterProps) => {
  const { isMobile } = useBreakpointScreenSize()

  return (
    <Flex
      flexWrap="wrap"
      justifyContent={(justifyContent ?? isMobile) ? 'center' : 'space-between'}
      width="100vw"
      data-testid="copyrightsAndLinksFooter"
      {...rest}
    >
      <FooterCopyright />
      <FooterLinks />
    </Flex>
  )
}
