import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { GoChevronDown, GoChevronUp } from 'react-icons/go'

import type { GoChevronIconProps } from './types'

export const HeaderWithChevronWrapper = styled(
  ({ isActive, ...props }: FlexProps & { isActive: boolean }) => (
    <Flex {...props} />
  ),
)(({ isActive, theme }) => {
  const activeStyles = {
    '.chevron-icon': {
      color: theme.colors.blue_500,
    },
  } as const

  const inactiveStyles = {
    '.chevron-icon': {
      color: theme.colors.contentA,
      visibility: 'hidden',
    },

    '&:hover span': {
      color: theme.colors.contentA,
    },

    '&:hover .chevron-icon': {
      visibility: 'visible',
    },
  } as const

  return {
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
    justifyContent: 'inherit',
    ...(isActive ? activeStyles : inactiveStyles),
  }
})

export const GoChevronIcon = ({
  chevronDirection,
  ...props
}: GoChevronIconProps) => {
  const ChevronIcon = chevronDirection === 'UP' ? GoChevronUp : GoChevronDown

  return <ChevronIcon size="16px" {...props} />
}
