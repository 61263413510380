import type { NiceModalHandler } from '@ebay/nice-modal-react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

export const useHideOnPathnameChange = (modal: NiceModalHandler) => {
  const { pathname } = useLocation()
  const prevPathname = usePrevious(pathname)

  useEffect(() => {
    if (prevPathname && pathname !== prevPathname && modal.visible) {
      modal.remove()
    }

    // return () => {
    //   // TODO https://simplesystem.atlassian.net/browse/S2NG-5127: component is unmounting when opening approval order modal
    //   if (modal.visible) {
    //     modal.remove()
    //   }
    // }
    // we want to only run it on pathname change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
}
