import { useContext } from 'react'

import { SelectActionsContext } from './selectActionsContext'

export const useSelectActionsContext = () => {
  const selectActionsContext = useContext(SelectActionsContext)

  if (!selectActionsContext) {
    throw new Error('Component beyond SelectActionsContext!')
  }

  return selectActionsContext
}
