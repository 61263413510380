import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { ModelGetShoppingCartExceededBudgetsData } from 'libs/openapiGeneratedApi/Api'

import { getCustomerMockUrl } from '../../utils'
import { budgetsMockData } from '../../Budgets/List'

const filteredBudgets = budgetsMockData.budgets
  .filter(({ percentageUsed }) => percentageUsed >= 100)
  .map(({ enabled, ...budget }) => ({
    ...budget,
    enabled: true,
    // TODO: IMO it should be always required
    // https://simplesystem.atlassian.net/browse/S2NG-7591
    ...(!budget.accountType || !budget.accountValue
      ? {
          accountValue: {
            id: '1234',
            number: '14',
            description: 'Value description',
          },
          accountType: {
            id: '7eced8a1-daaf-4020-879e-99e9ea66a041',
            prefix: 'K',
            description: 'Project Name',
          },
        }
      : {}),
  }))

export const exceededBudgetsMockData: ModelGetShoppingCartExceededBudgetsData =
  {
    exceededIndividualBudgets: [
      {
        id: 'a3ca288c-81a0-4a5c-a879-5e046fd182fe',
        name: 'Generic Individual budget',
        refreshPeriod: 'QUARTERLY',
        budgetLimit: { amount: 200000, currency: 'GBP' },
        budgetLeft: { amount: 0, currency: 'GBP' },
        budgetSpent: { amount: 200000, currency: 'GBP' },
        percentageUsed: 100,
        createdBy: 'Chuck Norris',
      },
    ],
    exceededBudgets: [
      ...filteredBudgets,
      {
        id: 'bb50273a-5200-44d4-80e1-3a150d1b4999',
        name: 'IT budget',
        refreshPeriod: 'MONTHLY',
        createdBy: 'Tiesto',
        budgetLimit: { amount: 200000, currency: 'GBP' },
        budgetLeft: { amount: 0, currency: 'GBP' },
        budgetSpent: { amount: 210000, currency: 'GBP' },
        additionalBudgetAmount: { amount: 0, currency: 'GBP' },
        additionalBudgetSpent: { amount: 0, currency: 'GBP' },
        additionalBudgetLeft: { amount: 0, currency: 'GBP' },
        totalBudgetLimit: { amount: 200000, currency: 'GBP' },
        totalBudgetLeft: { amount: 0, currency: 'GBP' },
        accountType: {
          id: '7eced8a1-daaf-4020-879e-99e9ea66aaaa',
          prefix: 'Z',
          description: 'ZZZZ Name',
        },
        accountValue: {
          id: '7eced8a1-daaf-4020-879e-99e9ea66aaaa',
          number: '12345',
          description: 'Long value',
        },
        enabled: true,
        percentageUsed: 105,
      },
    ],
    cartItemIdsBlockedByBudgets: [
      '12314234534643214',
      '23975548736239045834985',
    ],
  }

export const exceededIndividualBudgetsMockData: ModelGetShoppingCartExceededBudgetsData =
  {
    exceededIndividualBudgets: [
      {
        id: 'cb50273a-5200-44d4-80e1-3a150d1b499c',
        name: 'Individual IT budget',
        refreshPeriod: 'DAILY',
        createdBy: 'Festo',
        budgetLimit: { amount: 230000, currency: 'GBP' },
        budgetLeft: { amount: 0, currency: 'GBP' },
        budgetSpent: { amount: 250000, currency: 'GBP' },
        percentageUsed: 101,
      },
    ],
    exceededBudgets: [],
    cartItemIdsBlockedByBudgets: ['12314234534643214'],
  }

export const emptyExceededBudgetsMockData: ModelGetShoppingCartExceededBudgetsData =
  {
    exceededIndividualBudgets: [],
    exceededBudgets: [],
    cartItemIdsBlockedByBudgets: [],
  }

export const currentCartExceededBudgetsGetMock = http.get(
  getCustomerMockUrl(generatedApi.customers.getShoppingCartExceededBudgets.url),
  () => HttpResponse.json(exceededBudgetsMockData),
)

export const currentCartExceededIndividualBudgetsGetMock = http.get(
  getCustomerMockUrl(generatedApi.customers.getShoppingCartExceededBudgets.url),
  () => HttpResponse.json(exceededIndividualBudgetsMockData),
)

export const emptyCurrentCartExceededBudgetsGetMock = http.get(
  getCustomerMockUrl(generatedApi.customers.getShoppingCartExceededBudgets.url),
  () => HttpResponse.json(emptyExceededBudgetsMockData),
)
