import styled from '@emotion/styled'
import type { NavLinkProps } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { forwardRef } from 'react'

import type { Theme } from 'theme'

export const getCommonNavItemStyle = ({ colors, textStyles }: Theme) => ({
  ...textStyles.textNormalSb,
  color: colors.contentA,
  display: 'flex',
  alignItems: 'flex-end',
  height: '40px',
  padding: '10px 12px',
  borderRadius: '6px',

  ':hover': {
    background: colors.backgroundC,
  },
})

export const StyledNavLink = styled(
  forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
    // Bypass and do not pass style prop to NavLink
    const { style, ...rest } = props

    return <NavLink ref={ref} {...rest} />
  }),
)(({ theme, style }) => ({
  gap: '8px',
  ...getCommonNavItemStyle(theme),
  ...style,

  '&.active': {
    color: theme.colors.blue_500,
    background: theme.colors.backgroundC,
    ':hover': {
      background: theme.colors.backgroundD,
    },
  },
}))
