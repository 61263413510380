import React from 'react'

import { BareCheckbox } from 'app/components/inputs/Checkbox'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

export const FastOrderListItemCheckbox = () => {
  const { selectSingleItem, unselectSingleItem, getIsSelected } =
    useFastOrderContext()

  const {
    fastOrderListItem: { id },
    status,
  } = useFastOrderListItemContext()

  const isSelected = getIsSelected(id)

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? selectSingleItem(id) : unselectSingleItem(id)
  }

  return (
    <BareCheckbox
      isChecked={isSelected}
      customAriaLabel="fast order item checkbox"
      isDisabled={status !== 'AVAILABLE'}
      onChange={onChangeHandler}
    />
  )
}
