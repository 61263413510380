import type { UserRoles } from 'app/containers/common/Auth'
import type { MODEL__UserCompanyUnion } from 'app/models/user'

type CreateBaseCompanyIdentityProps = {
  company?: MODEL__UserCompanyUnion
  userRole?: Extract<UserRoles, 'vendor' | 'customer' | 'ociCustomer'>
}

export const createBaseCompanyIdentity = ({
  company,
  userRole,
}: CreateBaseCompanyIdentityProps) => ({
  selectedCompanyId: company?.id ?? null,
  userRole: userRole ?? null,
})
