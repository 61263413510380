import { isNil, omitBy } from 'lodash'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import type { ModalProps } from '../types'

const mobileModalDefaultProps = {
  isWithXButton: true,
  isFullScreen: true,
  isWithoutFooterBorder: true,
  isWithoutHeaderBorder: true,
}

export const useModalPropsWithSizeDefaults = ({
  isWithDefaultMobileProps,
  isFullScreen,
  isWithXButton,
  isWithoutFooterBorder,
  isWithoutHeaderBorder,
  ...props
}: ModalProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const screenSizeDependentProps = {
    isFullScreen,
    isWithXButton,
    isWithoutFooterBorder,
    isWithoutHeaderBorder,
  }

  const filteredScreenSizeDependentProps = omitBy(
    screenSizeDependentProps,
    isNil,
  )

  return {
    ...(isMobile && isWithDefaultMobileProps
      ? {
          ...mobileModalDefaultProps,
          ...filteredScreenSizeDependentProps,
        }
      : screenSizeDependentProps),
    ...props,
  }
}
