import { Link } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { SimpleSystemLogoIcon } from 'app/components/icons'

import { VendorAccountButton } from './components'
import { VendorNavWrapper } from './styles'

export const VendorNav = () => (
  <VendorNavWrapper data-testid="mainHeader">
    <Link to={PATHS.root} data-testid="homeLinkLogo">
      <SimpleSystemLogoIcon />
    </Link>

    <VendorAccountButton />
  </VendorNavWrapper>
)
