import { useMediaQuery } from '@chakra-ui/react'

import { usePrintingContext } from './printingContext'

export const useDetectPrintMode = () => {
  const { isPrintMode: isContextPrintMode } = usePrintingContext()
  const [isMediaQueryPrintMode] = useMediaQuery('print', { ssr: false })

  return { isPrintMode: isContextPrintMode || isMediaQueryPrintMode }
}
