import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'

const messages = translations.pages.customer.search.searchArticle

type ArticleNumberTextProps = { number: string } & TypographyProps

export const ArticleNumberText = ({
  number,
  textStyle = 'textSmall',
  ...rest
}: ArticleNumberTextProps) => {
  const { t } = useTranslation()

  return (
    <Typography textStyle={textStyle} {...rest} data-testid="articleNumberText">
      {t(messages.articleNumberFootnote, { number })}
    </Typography>
  )
}
