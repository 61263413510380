import type { IconProps } from '../types'

export const LinkBIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.3859 9.08513L13.0756 7.39513C14.3082 6.16273 14.3082 4.157 13.0756 2.9246C11.8431 1.6918 9.83758 1.6918 8.60509 2.9246L6.16665 5.36313C4.93403 6.59553 4.93403 8.60126 6.16665 9.83366C6.34865 10.0158 6.54784 10.1702 6.75863 10.2985L8.06405 8.99286C7.81539 8.9334 7.57953 8.80793 7.38594 8.61446C6.82569 8.0542 6.82569 7.14246 7.38594 6.58233L9.82438 4.1438C10.3846 3.58353 11.2963 3.58353 11.8564 4.1438C12.4167 4.70406 12.4167 5.61566 11.8564 6.17593L11.1401 6.89246C11.4353 7.58726 11.5169 8.3514 11.3859 9.08513Z"
      fill={color}
    />
    <path
      d="M4.61424 6.9145L2.92457 8.60437C1.69181 9.8369 1.69181 11.8425 2.92457 13.075C4.15692 14.3077 6.16258 14.3077 7.39507 13.075L9.83337 10.6365C11.066 9.40397 11.0659 7.39837 9.83337 6.16597C9.65151 5.98384 9.45232 5.82944 9.24153 5.70117L7.93611 7.00677C8.18464 7.06637 8.42049 7.19157 8.61422 7.38517C9.17447 7.94544 9.17447 8.85704 8.61422 9.4173L6.17564 11.8558C5.6154 12.4161 4.70383 12.4161 4.14359 11.8558C3.58334 11.2956 3.58334 10.384 4.14359 9.8237L4.86009 9.1073C4.56477 8.4125 4.48317 7.64824 4.61424 6.9145Z"
      fill={color}
    />
  </svg>
)
