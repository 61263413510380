import type { ReactNode } from 'react'
import { createContext } from 'react'

import { ExcludesFalsy } from 'types/utils'

import { useArticleListItemsQuery } from 'app/containers/customers/ArticleLists/Articles/search'

import type { SelectActionsContextType } from './types'
import { useSelectArticlesHandlers } from './useSelectArticlesHandlers'

export const SelectActionsContext = createContext<
  SelectActionsContextType | undefined
>(undefined)

export const SelectActionsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { data: { items: articles } = {} } = useArticleListItemsQuery()

  const articlesAvailableToAddToCart =
    articles?.filter(
      ({ available, articleData: { article } }) =>
        available && !article.orderOptions?.restricted,
    ) ?? []

  const {
    selectedArticles,
    selectAllCheckboxHandler,
    makeSingleCheckboxHandler,
    resetSelectedArticles,
    deleteSelectedArticle,
  } = useSelectArticlesHandlers({
    articlesAvailableToAddToCart,
  })

  const articlesAvailableToAddToCartCount = articlesAvailableToAddToCart.length

  const selectedArticlesIds = Object.keys(selectedArticles)
  const selectionCountTotal = selectedArticlesIds.length

  const selectionCountInPage = articlesAvailableToAddToCart.filter(
    ({ articleData }) => articleData.article.id in selectedArticles,
  ).length

  const isMasterCheckboxSelected =
    selectionCountInPage > 0 &&
    selectionCountInPage === articlesAvailableToAddToCartCount

  const isMasterCheckboxIndeterminate =
    !isMasterCheckboxSelected && selectionCountInPage > 0

  const isAddToCartActionAvailable = selectionCountTotal > 0

  const resetSelectedState = () => {
    resetSelectedArticles()
  }

  const checkIfSingleCheckboxSelected = (articleId: string) =>
    articleId in selectedArticles

  const getSelectedArticleListDetailItems = () =>
    Object.values(selectedArticles).filter(ExcludesFalsy)

  return (
    <SelectActionsContext.Provider
      value={{
        selectionCountTotal,
        selectionCountInPage,
        isMasterCheckboxSelected,
        isMasterCheckboxIndeterminate,
        selectAllCheckboxHandler,
        makeSingleCheckboxHandler,
        checkIfSingleCheckboxSelected,
        isAddToCartActionAvailable,
        resetSelectedState,
        getSelectedArticleListDetailItems,
        articlesAvailableToAddToCart,
        articlesAvailableToAddToCartCount,
        deleteSelectedArticle,
        selectedArticlesIds,
      }}
    >
      {children}
    </SelectActionsContext.Provider>
  )
}
