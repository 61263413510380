import { CheckIcon } from '@chakra-ui/icons'
import styled from '@emotion/styled'
import { Flex } from '@chakra-ui/react'

import { Panel } from 'app/components/Panel/Panel'
import { PopoverOption } from 'app/components/buttonWithPopovers/styles'
import { useFormatArticleUnit } from 'app/hooks/useFormatArticleUnit'
import { Typography } from 'app/components/Typography'
import { getAvailableNonRestrictedFoundArticles } from 'app/pages/customers/common/fastOrder/utils'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'
import { ArticleContent } from 'app/pages/customers/common/fastOrder/FastOrderResults/components/common/ArticleContent'

export const PopoverOptionStyled = styled(PopoverOption)(() => ({
  padding: '12px 20px',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

type FoundArticlesListProps = {
  articleSelectHandler: (articleId: string) => void
  selectedArticle?: string
}

export const FoundArticlesList = ({
  selectedArticle,
  articleSelectHandler,
}: FoundArticlesListProps) => {
  const { formatArticleUnit } = useFormatArticleUnit()

  const {
    fastOrderListItem: { foundArticles },
  } = useFastOrderListItemContext()

  const options = getAvailableNonRestrictedFoundArticles(foundArticles)

  return (
    <Panel
      isWithoutBorder
      withDivider
      p={0}
      spacing={0}
      data-testid="foundArticleOptions"
    >
      {options.map((articleData) => (
        <PopoverOptionStyled
          key={articleData.id}
          data-testid="foundArticleOption"
          onClick={() => {
            if (articleData.id !== selectedArticle) {
              articleSelectHandler(articleData.id)
            }
          }}
        >
          <ArticleContent articleData={articleData} isWithoutLinks />
          <Flex gap="16px">
            <Typography flexShrink={0}>
              {`${articleData.originalQuantity} ${formatArticleUnit({
                quantity: articleData.originalQuantity,
                unit: articleData.orderUnit,
              })}`}
            </Typography>
            {articleData.id === selectedArticle && <CheckIcon />}
          </Flex>
        </PopoverOptionStyled>
      ))}
    </Panel>
  )
}
