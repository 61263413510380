import { useTranslation } from 'react-i18next'
import { Form, FormikProvider } from 'formik'

import { translations } from 'locales/translations'
import type { ModelUpdateCustomerArticleNumberSourceEnum } from 'libs/openapiGeneratedApi/Api'

import type { RadioListOption } from 'app/components/inputs/RadioList'
import { RadioList } from 'app/components/inputs/RadioList'
import { Input } from 'app/components/inputs/Input'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'
import { Notification } from 'app/components/Notification/Notification'

import { CustomerArticleNumberOption } from './CustomerArticleNumberOption'
import type { EditCustomerArticleNumberProps } from './types'

const messages =
  translations.pages.customer.editArticleDetailsModal.buyerArticleNumber

export const EditCustomerArticleNumber = ({
  formik,
}: EditCustomerArticleNumberProps) => {
  const { t } = useTranslation()

  const customerArticleNumberOptions: RadioListOption<ModelUpdateCustomerArticleNumberSourceEnum>[] =
    [
      {
        value: 'DEFAULT',
        label: (
          <CustomerArticleNumberOption
            title={t(messages.useDefaultTitle)}
            description={t(messages.useDefaultDescription)}
          />
        ),
      },
      {
        value: 'CUSTOM',
        label: (
          <CustomerArticleNumberOption
            title={t(messages.useCustomTitle)}
            description={t(messages.useCustomDescription)}
          />
        ),
      },
      {
        value: 'NONE',
        label: (
          <CustomerArticleNumberOption
            title={t(messages.useNoneTitle)}
            description={t(messages.useNoneDescription)}
          />
        ),
      },
    ]

  return (
    <FormikProvider value={formik}>
      <Form>
        <ColumnWithGap gap={8}>
          <Notification type="info">
            {t(messages.updateTimeNotification)}
          </Notification>
          <ColumnWithGap gap={4}>
            <RadioList
              name="source"
              options={customerArticleNumberOptions}
              radioOptionProps={{
                withRadioFirst: true,
                alignItems: 'flex-start',
              }}
            />
            {formik.values.source === 'CUSTOM' && (
              <Input
                name="customValue"
                label={t(messages.customBuyerArticleNumber.label)}
                placeholder={t(messages.customBuyerArticleNumber.placeholder)}
              />
            )}
            {formik.values.source === 'DEFAULT' && (
              <Input
                name="defaultValue"
                placeholder="—"
                isDisabled
                label={t(messages.defaultBuyerArticleNumber.label)}
              />
            )}
          </ColumnWithGap>
        </ColumnWithGap>
      </Form>
    </FormikProvider>
  )
}
