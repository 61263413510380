import { useField } from 'formik'

import { FormControlWrapper } from 'app/components/inputs/FormControlWrapper'

import type { RadioListProps } from './types'
import { BareRadioList } from './BareRadioList'

export function RadioList<TValue extends string>({
  name,
  label,
  labelAs,
  caption,
  handleChange,
  ...radioListProps
}: RadioListProps<TValue>) {
  const [{ value }, { touched, error }, { setValue }] = useField<TValue>({
    name,
  })

  return (
    <FormControlWrapper
      name={name}
      label={label}
      labelAs={labelAs}
      caption={caption}
      touched={touched}
      error={error}
      data-testid={name}
    >
      <BareRadioList
        name={name}
        value={value}
        onChange={(newValue) => {
          handleChange?.(newValue)
          setValue(newValue)
        }}
        {...radioListProps}
      />
    </FormControlWrapper>
  )
}
