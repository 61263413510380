import { assignedAccountTypesAndValuesListMockData } from 'app/containers/customers/AccountTypes/AssignedTypesAndValuesList/mock'
import type { AccountTypeValueId } from 'app/containers/customers/AccountTypes/Values/models'
import type { AccountTypeId } from 'app/containers/customers/AccountTypes/models'
import { assignedGeneralLedgersMockData } from 'app/containers/customers/GeneralLedgers/AssignedToCustomer/mock/data'

const { accountTypes } = assignedAccountTypesAndValuesListMockData
const accountValues = Object.values(
  assignedAccountTypesAndValuesListMockData.accountValuesByType,
)[0]

export const cartLevelTypeAndValue = {
  typeId: accountTypes[0].id as AccountTypeId,
  valueId: accountValues[0].id as AccountTypeValueId,
  freeTextValue: 'Cart-level free text value',
}
export const itemLevelTypeAndValue = {
  typeId: accountTypes[1].id as AccountTypeId,
  valueId: accountValues[1].id as AccountTypeValueId,
  freeTextValue: 'Item-level free text value',
}

export const defaultGeneralLedger =
  assignedGeneralLedgersMockData.generalLedgers[1]
export const cartLevelGeneralLedger =
  assignedGeneralLedgersMockData.generalLedgers[0]
export const cartLevelGeneralLedgerId = cartLevelGeneralLedger.id

export const defaultCartAdditionalAccountingInfo =
  'Cart general accounting info'
