import type { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'

import { colors } from 'theme/colors'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { WithDotsSeparatorWrapper } from 'app/components/DotSeparator/WithDotsSeparatorWrapper'

type ArticleContentDescriptionLineWrapperProps = { children: ReactNode[] }

export const ArticleContentDescriptionLineWrapper = ({
  children,
}: ArticleContentDescriptionLineWrapperProps) => {
  const { isMobile } = useBreakpointScreenSize()

  if (isMobile) {
    return (
      <Flex gap="8px" direction="column">
        {children}
      </Flex>
    )
  }

  return (
    <Flex gap="4px">
      <WithDotsSeparatorWrapper
        color={colors.contentOff}
        margin="0"
        childrenToRender={children}
      />
    </Flex>
  )
}
