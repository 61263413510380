import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = ({
  scrollDependantProp,
}: {
  scrollDependantProp?: unknown
}) => {
  const { pathname } = useLocation()

  useEffect(() => {
    document.getElementById('root')?.scrollTo(0, 0)
  }, [pathname, scrollDependantProp])

  return null
}
