import { Flex, type FlexProps } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { useViewportContentMaxHeight } from './Layout/useViewportContentMaxHeight'

export type ContentWrapperProps = FlexProps & {
  size?: 'narrow' | 'wide'
  withViewportAsMaxHeight?: boolean
}

export const ContentWrapper = ({
  withViewportAsMaxHeight,
  size = 'narrow',
  ...props
}: ContentWrapperProps) => {
  const { maxHeight: viewportContentMaxHeight } = useViewportContentMaxHeight()

  const { isMobile } = useBreakpointScreenSize()

  const { dimensions } = useTheme()

  return (
    <Flex
      maxHeight={withViewportAsMaxHeight ? viewportContentMaxHeight : 'none'}
      flexDirection="column"
      margin="0 auto"
      maxWidth={
        size === 'narrow'
          ? dimensions.narrowContentMaxWidth
          : dimensions.wideContentMaxWidth
      }
      width={isMobile ? '100%' : 'auto'}
      {...props}
    />
  )
}
