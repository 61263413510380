import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { ActionItem } from 'app/components/ActionsMenu/styles'
import { useArticleListDetailsQuery } from 'app/containers/customers/ArticleLists/Show'
import { PrintButton } from 'app/pages/customers/common/PrintButton'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details.menu

type PagePrintButtonProps = {
  noArticlesOnList: boolean
}

export const PagePrintButton = ({ noArticlesOnList }: PagePrintButtonProps) => {
  const { t } = useTranslation()

  const { data } = useArticleListDetailsQuery()
  const documentTitle = data?.name

  return (
    <PrintButton
      documentTitle={documentTitle}
      renderCustomPrintButton={(handlePrint) => (
        <ActionItem isDisabled={noArticlesOnList} onClick={handlePrint}>
          {t(messages.print)}
        </ActionItem>
      )}
    />
  )
}
