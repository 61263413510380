import { useTranslation } from 'react-i18next'
import { Box, Flex, HStack } from '@chakra-ui/react'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'

import { useSelectActionsContext } from 'app/pages/customers/ArticleLists/ArticleListDetails/context'
import { useAddToCartHandler } from 'app/pages/customers/ArticleLists/ArticleListDetails/useAddToCartHandler'
import { BareCheckbox } from 'app/components/inputs/Checkbox'
import { Typography } from 'app/components/Typography'
import { Button } from 'app/components/Buttons/Button'
import { DotSeparator } from 'app/components/DotSeparator/DotSeparator'
import { CartIcon } from 'app/components/icons'
import { SearchInput } from 'app/components/inputs/SearchInput'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details
    .selectActionsBar

export const SelectActionsBar = () => {
  const { t } = useTranslation()
  const { borders } = useTheme()

  const {
    selectionCountInPage,
    selectionCountTotal,
    articlesAvailableToAddToCartCount,
    isMasterCheckboxSelected,
    isMasterCheckboxIndeterminate,
    selectAllCheckboxHandler,
    isAddToCartActionAvailable,
    resetSelectedState,
    getSelectedArticleListDetailItems,
  } = useSelectActionsContext()

  const { addToCartHandler, isAddingToCart } = useAddToCartHandler()

  return (
    <Flex
      height="52px"
      p="8px 20px"
      borderTop={borders.primary}
      width="100%"
      justifyContent="space-between"
    >
      <Flex gap="20px">
        <HStack /* INFO: to override 100% width of BareCheckbox */>
          <BareCheckbox
            isVerticallyCentered
            customAriaLabel="toggle all selection"
            dataTestId="globalArticleListItemCheckbox"
            isChecked={isMasterCheckboxSelected}
            isIndeterminate={isMasterCheckboxIndeterminate}
            onChange={selectAllCheckboxHandler}
          >
            <Flex>
              <Typography>
                {t(translations.xFromYSelected, {
                  x: selectionCountInPage,
                  y: articlesAvailableToAddToCartCount,
                })}
              </Typography>
              {isAddToCartActionAvailable && (
                <Typography color="contentB">
                  <DotSeparator />
                  {t(messages.articlesSelectedInTotal, {
                    count: selectionCountTotal,
                  })}
                </Typography>
              )}
            </Flex>
          </BareCheckbox>
        </HStack>

        <Box w="206px">
          <SearchInput
            name="articleListItemsSearch"
            placeholder={t(translations.formActions.searchOnTheList)}
            size="sm"
          />
        </Box>
      </Flex>

      {isAddToCartActionAvailable && (
        <Button
          leftIcon={<CartIcon />}
          size="sm"
          isLoading={isAddingToCart}
          onClick={() =>
            addToCartHandler({
              articles: getSelectedArticleListDetailItems(),
              onSuccess: resetSelectedState,
            })
          }
          dataTestId="addSelectedToCart"
        >
          {`${t(messages.addSelected)} (${selectionCountTotal})`}
        </Button>
      )}
    </Flex>
  )
}
