import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import type { ChooseArticleButtonProps } from './types'
import {
  MobileChooseArticleButton,
  DesktopChooseArticleButton,
} from './components'

export const ChooseArticleButton = ({
  displayOnlyIcon,
}: ChooseArticleButtonProps) => {
  const { isMobile } = useBreakpointScreenSize()

  const ChooseArticleButtonComponent = isMobile
    ? MobileChooseArticleButton
    : DesktopChooseArticleButton

  return <ChooseArticleButtonComponent displayOnlyIcon={displayOnlyIcon} />
}
