import { generatePath } from 'react-router'

import { api } from 'apiServices'

import { platformCatalogPath } from '../consts'

import type {
  API__AssignCustomersToCatalogRequestArgs,
  API__CatalogCustomersAssignmentsResponse,
  API__GetCatalogCustomerAssignmentsRequestArgs,
} from './types'

export const url = `${platformCatalogPath}/assignments`

export const getCatalogCustomerAssignmentsRequest = ({
  catalogId,
}: API__GetCatalogCustomerAssignmentsRequestArgs) =>
  api.get<unknown, API__CatalogCustomersAssignmentsResponse>(
    generatePath(url, { catalogId }),
  )

export const assignCustomersToCatalogRequest = ({
  catalogId,
  customerIds,
}: API__AssignCustomersToCatalogRequestArgs) =>
  api.post<unknown, undefined>(generatePath(url, { catalogId }), {
    customerIds,
  })
