import * as yup from 'yup'

import { MAX_NUMBER_VALUE } from '../consts'

import type {
  GetFieldNameForGroupProps,
  GetRequiredNumberFieldValidationProps,
  GetRequiredSelectFieldValidationProps,
} from './types'

export const getRequiredStringFieldValidation = (label: string) =>
  yup.string().required().ensure().default('').label(label)

export const getRequiredNumberFieldValidation = ({
  label,
  min,
  max,
}: GetRequiredNumberFieldValidationProps = {}) => {
  let basicNumberSchema = yup
    .number()
    .max(max ?? MAX_NUMBER_VALUE)
    .label(label ?? ' ')
    .required()

  if (min) {
    basicNumberSchema = basicNumberSchema.min(min)
  }

  return basicNumberSchema
}

export const getRequiredSelectFieldValidation = <T extends string>({
  possibleValues,
  label,
}: GetRequiredSelectFieldValidationProps<T>) =>
  yup
    .mixed<T>()
    .oneOf([...possibleValues])
    .required()
    .label(label ?? ' ')
    .default(null) // INFO: using 'default' to remove 'undefined' from the type

export const getFieldNameForGroup = ({
  sectionName,
  fieldName,
}: GetFieldNameForGroupProps) =>
  sectionName ? `${sectionName}.${fieldName}` : fieldName

export const optionalCheckboxValidation = yup.boolean().default(false)
export const requiredCheckboxValidation = yup
  .boolean()
  .oneOf([true])
  .default(false)
  .required()
