import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { useScanDetectionWithIgnoreIfFocusOnInputRef } from 'libs/useScanDetection/useScanDetectionWithIgnoreIfFocusOnInputRef'

import { ContentWrapper } from 'app/components/layout/ContentWrapper'
import { FastOrderResults } from 'app/pages/customers/common/fastOrder/FastOrderResults'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'
import { EmptyListIcon } from 'app/components/icons'
import { useAddItemToFastOrderListMutation } from 'app/containers/customers/FastOrders/Add'

import { ScannerInput } from './components'

const messages = translations.pages.customer.scanner

export const DesktopScanner = () => {
  const { t } = useTranslation()

  const { setInputValue } = useFastOrderContext()

  const { mutate: addScannedValue, isPending: isAddingScannedValue } =
    useAddItemToFastOrderListMutation({
      fastOrderListEnum: 'SCANNING_LIST',
      onSuccess: () => {
        setInputValue(undefined)
      },
    })

  // INFO: allows to scan articles one after another, without need to manually clear input value
  const { scannerInputRef } = useScanDetectionWithIgnoreIfFocusOnInputRef({
    onComplete: (value) => {
      setInputValue(value.toString())
      addScannedValue({ barcode: value.toString() })
    },
  })

  const emptyCaseWrapperProps = {
    message: t(messages.emptyCase.header),
    icon: <EmptyListIcon />,
    subHeader: t(messages.emptyCase.description),
  }

  return (
    <ContentWrapper gap="20px" size="wide" withViewportAsMaxHeight>
      <ScannerInput
        addScannedValue={addScannedValue}
        isAddingScannedValue={isAddingScannedValue}
        ref={scannerInputRef}
      />
      <FastOrderResults emptyCaseWrapperProps={emptyCaseWrapperProps} />
    </ContentWrapper>
  )
}
