import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { PATHS } from 'consts/paths'
import { translations } from 'locales/translations'

import { Button } from 'app/components/Buttons/Button'

const emptyCaseButtonMessage =
  translations.pages.customer.companyAndSettings.articlesLists.details.emptyCase
    .buttonText

export const GoToCatalogsButton = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Button
      data-testid="goToCatalogsButton"
      onClick={() => history.push(PATHS.customer.search)}
    >
      {t(emptyCaseButtonMessage)}
    </Button>
  )
}
