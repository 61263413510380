import { useSelector } from 'react-redux'

import { useTheme } from 'theme'

import { baseIdentitySelector } from 'app/containers/common/Auth/selectors'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useCurrentUser } from 'app/containers/common/Auth'
import { useActiveBreadcrumbs } from 'app/App/routes/authorized/routes/utils'
import { BREADCRUMBS_MAP } from 'app/App/routes/authorized/routes/CustomerRoutes/consts'

import { useMobileSearchInputVisibility } from './components/Header/CustomerNav/useMobileSearchInputVisibility'

const useHeaderHeight = () => {
  const { dimensionsRaw } = useTheme()
  const { isMobile, isTablet } = useBreakpointScreenSize()
  const { isSearchInputVisible } = useMobileSearchInputVisibility()
  const { isCustomer, isOciCustomer } = useCurrentUser()
  const activeBreadcrumbs = useActiveBreadcrumbs(BREADCRUMBS_MAP)

  if (activeBreadcrumbs) return dimensionsRaw.headerHeight

  if (isMobile)
    return isSearchInputVisible
      ? dimensionsRaw.mobileHeaderHeightWithSearch
      : dimensionsRaw.mobileHeaderHeight

  if (isCustomer || isOciCustomer) {
    if (isTablet)
      return isSearchInputVisible
        ? dimensionsRaw.tabletHeaderHeightWithSearch
        : dimensionsRaw.tabletHeaderHeight

    return dimensionsRaw.customerDesktopHeaderHeight
  }

  return dimensionsRaw.headerHeight
}

export const usePageOffsetTop = () => {
  const { dimensionsRaw } = useTheme()
  const headerHeight = useHeaderHeight()
  const { isUserImpersonated } = useSelector(baseIdentitySelector)

  let pageOffsetTop: number = headerHeight

  if (isUserImpersonated) {
    pageOffsetTop = headerHeight + dimensionsRaw.impersonationBarHeight
  }

  return `${pageOffsetTop}px`
}
