import type { ModelGetBarcodeArticleListItemsData } from 'libs/openapiGeneratedApi/Api'

import type { VendorId } from 'app/models/user'

import type { ModelBarcodeArticleListItem } from '../types'

export const mockBarcodeArticleListItem: ModelBarcodeArticleListItem = {
  articleData: {
    eclassCode: '17-01-00-01',
    article: {
      id: '12q4MqlukNCQl287wdSnog:MTA2',
      name: 'Drilling machine',
      number: 'ABC123456',
      ean: '12345678',
      customerArticleNumber: 'VABC123456',
      manufacturerArticleNumber: 'ABC123',
      manufacturer: 'ACME Inc.',
      deliveryTime: 3,
      minimumOrder: 0,
      quantityInterval: 0,
      contentUnit: 'C62',
      contentAmount: '1',
      tax: 0,
      coreArticle: true,
      closedMarketplace: true,
      thumbnailUrl: 'https://simplesystem.com/images/1234.jpg',
      orderUnit: 'PK',
    },
    vendor: {
      id: '225ca0e5-6f0d-45c5-8db1-f3cb5eb3541a',
      name: 'Hoffmann Group',
      logoUrl: 'https://simplesystem.com/images/logo_url.jpg',
    },
    externalReferences: [
      {
        id: '6fad93de-a434-485a-86b1-ef14ffeaafe1',
        barcode: 'sadfasdf',
        quantity: 2,
      },
      {
        id: '01f0f06e-0260-4d46-9355-e96168106c97',
        barcode: '4716415942052',
        quantity: 155,
      },
    ],
  },
  quantity: 3,
  available: true,
}

type BarcodeArticleListItemData = ModelBarcodeArticleListItem['articleData']
type MakeBarcodeArticleMockDataArgs = {
  topLevelArticleData?: Partial<BarcodeArticleListItemData>
  articleData?: Partial<BarcodeArticleListItemData['article']>
  externalReferences?: BarcodeArticleListItemData['externalReferences']
}

export const makeBarcodeArticleMockData = (
  options?: MakeBarcodeArticleMockDataArgs,
): ModelBarcodeArticleListItem => {
  const { topLevelArticleData, articleData, externalReferences } = options ?? {}

  return {
    ...mockBarcodeArticleListItem,
    articleData: {
      ...mockBarcodeArticleListItem.articleData,
      ...topLevelArticleData,
      article: {
        ...mockBarcodeArticleListItem.articleData.article,
        ...articleData,
      },
      externalReferences:
        externalReferences ??
        mockBarcodeArticleListItem.articleData?.externalReferences,
    },
  }
}

export const barcodeArticleListDetailsItemsMockData: ModelGetBarcodeArticleListItemsData =
  {
    items: [
      makeBarcodeArticleMockData(),
      makeBarcodeArticleMockData({
        topLevelArticleData: {
          vendor: {
            id: 'X12345' as VendorId,
            name: 'Whatever vendor example',
            logoUrl: 'https://simplesystem.com/images/logo_url.jpg',
          },
        },
        articleData: {
          id: '1029415e-c799-47dd-8d26-edbbb82a3800',
          name: 'Restricted name',
        },
      }),
    ],
  }
