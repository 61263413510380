import { Flex } from '@chakra-ui/react'

import { useTheme } from 'theme'

import { XIcon } from 'app/components/icons'

export const CloseToastButton = () => {
  const { colors } = useTheme()

  return (
    <Flex justifyContent="flex-end" flexShrink={0}>
      <XIcon color={colors.contentA} />
    </Flex>
  )
}
