import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'
import { useBoolean } from '@chakra-ui/react'

import { IS_TEST } from 'consts/envVars'

type PrintoutVisibilityContextValue = {
  isPrintoutVisible: boolean
  on: () => void
  off: () => void
  toggle: () => void
}

const PrintoutVisibilityContext =
  createContext<PrintoutVisibilityContextValue | null>(null)

export const PrintoutVisibilityContextProvider = ({
  children,
}: {
  children: ReactNode | ((value: PrintoutVisibilityContextValue) => ReactNode)
}) => {
  const [isPrintoutVisible, callbacks] = useBoolean(false)

  const value = useMemo(
    () => ({ isPrintoutVisible, ...callbacks }),
    [isPrintoutVisible, callbacks],
  )

  return (
    <PrintoutVisibilityContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </PrintoutVisibilityContext.Provider>
  )
}

export const usePrintoutVisibilityContext = () => {
  const context = useContext(PrintoutVisibilityContext)

  if (!context) {
    if (IS_TEST) {
      return {
        isPrintoutVisible: false,
        on: () => {},
        off: () => {},
        toggle: () => {},
      }
    }

    throw new Error('out of scope of the PrintingContext')
  }

  return context
}
