import type { QueryObserverOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'
import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'

import type { UseFastOrderHookArgs } from '../types'

export const getFastOrderListQueryKey = ({
  fastOrderListEnum,
}: UseFastOrderHookArgs) => [`${fastOrderListEnum}_QUERY_KEY`] as const

type UseFastOrderListQueryProps<T> = UseFastOrderHookArgs & {
  select?: (data: ModelGetFastOrderListData) => T
} & Pick<QueryObserverOptions<ModelGetFastOrderListData>, 'refetchInterval'>

export const useFastOrderListQuery = <T = ModelGetFastOrderListData>({
  fastOrderListEnum,
  select,
  refetchInterval,
}: UseFastOrderListQueryProps<T>) =>
  useQuery({
    queryKey: getFastOrderListQueryKey({ fastOrderListEnum }),
    queryFn: () =>
      generatedApi.customers.getFastOrderList.request({
        fastOrderListEnum: fastOrderListEnum,
      }),
    select,
    refetchInterval,
  })
