import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'
import { AiFillWarning } from 'react-icons/ai'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'

import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'

const messages = translations.pages.customer.articleDetails

const textStylesMapper: Record<Size, TypographyProps> = {
  sm: {
    ml: '4px',
    textStyle: 'textSmall' as const,
    color: 'contentB' as const,
  },
  md: {
    ml: '8px',
    textStyle: 'headerH3' as const,
  },
}

type Size = 'sm' | 'md'
type PriceErrorProps = {
  size: Size
}

export const PriceError = ({ size }: PriceErrorProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  return (
    <Flex align="center" data-testid="priceError">
      <AiFillWarning
        size={size === 'sm' ? '20px' : '32px'}
        color={colors.contentA}
      />

      <Typography {...textStylesMapper[size]}>
        {t(messages.priceError)}
      </Typography>
    </Flex>
  )
}
