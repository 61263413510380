import { Flex, AccordionIcon, Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { SupportPopoverContent } from 'app/components/layout/SupportButton'

import { AccordionOption } from './AccordionOption'

export const Support = () => {
  const { t } = useTranslation()

  return (
    <AccordionOption
      title={() => (
        <>
          <Flex flex="1" justify="space-between">
            <Typography>{t(translations.header.support)}</Typography>
          </Flex>
          <AccordionIcon />
        </>
      )}
    >
      <Box px="4px">
        <Typography color="contentB" as="div">
          <SupportPopoverContent />
        </Typography>
      </Box>
    </AccordionOption>
  )
}
