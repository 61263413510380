import type { IconProps } from '../types'

export const ErrorIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.707 13.607A1 1 0 0 0 18 12.9V7.1a1 1 0 0 0-.293-.707l-4.1-4.1A1 1 0 0 0 12.9 2H7.1a1 1 0 0 0-.707.293l-4.1 4.1A1 1 0 0 0 2 7.101v5.798a1 1 0 0 0 .293.708l4.1 4.1a1 1 0 0 0 .708.293h5.798a1 1 0 0 0 .708-.293l4.1-4.1Zm-4-5.9L11.414 10l2.293 2.293-1.414 1.414L10 11.414l-2.293 2.293-1.414-1.414L8.586 10 6.293 7.707l1.414-1.414L10 8.586l2.293-2.293 1.414 1.414Z"
      fill={color}
    />
  </svg>
)
