import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const ArticleListItemWrapper = styled(
  ({ isAvailable, ...props }: FlexProps & { isAvailable: boolean }) => (
    <Flex gap="20px" alignItems="center" {...props} />
  ),
)(({ theme: { mediaQuery }, isAvailable }) => ({
  ...(!isAvailable && {
    opacity: 0.3,
    pointerEvents: 'none',
  }),
  [mediaQuery.print]: { breakInside: 'avoid' },
}))
