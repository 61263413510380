export const NoPhotoIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="8" fill="#FAFCFF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11.5625C17.9289 11.5625 16.25 13.2414 16.25 15.3125V16.5625H11.25V15.3125C11.25 10.48 15.1675 6.5625 20 6.5625C24.8325 6.5625 28.75 10.48 28.75 15.3125C28.75 19.0307 26.4987 21.0414 25.0168 22.3649C24.9416 22.4321 24.8683 22.4976 24.7974 22.5614C23.2912 23.917 22.5 24.7536 22.5 26.5625H17.5C17.5 22.4339 19.8338 20.3018 21.4526 18.8449C23.1108 17.3525 23.75 16.718 23.75 15.3125C23.75 13.2414 22.0711 11.5625 20 11.5625Z"
      fill="#DFE6F5"
    />
    <path
      d="M22.8125 31.25C22.8125 32.8033 21.5533 34.0625 20 34.0625C18.4467 34.0625 17.1875 32.8033 17.1875 31.25C17.1875 29.6967 18.4467 28.4375 20 28.4375C21.5533 28.4375 22.8125 29.6967 22.8125 31.25Z"
      fill="#DFE6F5"
    />
  </svg>
)
