import type { IconProps } from '../types'

export const SearchSettingsIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M17 14H9.732a2 2 0 0 0-3.464 0H3v2h3.268a2 2 0 0 0 3.464 0H17v-2Zm-9 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM17 9h-3.268a2 2 0 0 0-3.464 0H3v2h7.268a2 2 0 0 0 3.464 0H17V9Zm-5 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM17 4H9.732a2 2 0 0 0-3.464 0H3v2h3.268a2 2 0 0 0 3.464 0H17V4ZM8 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      clipRule="evenodd"
    />
  </svg>
)
