import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Input } from 'app/components/inputs/Input'
import { Typography } from 'app/components/Typography'
import { useGetArticleDetailsQuery } from 'app/containers/common/ArticleDetails'
import { useFormatArticleData } from 'app/hooks/useFormatArticleData'

const messages =
  translations.pages.customer.editArticleDetailsModal.externalReferences.form
    .fields.quantity

const QUANTITY_FIELD_NAME = 'quantity'

export const QuantityField = ({ articleId }: { articleId: string }) => {
  const { t } = useTranslation()
  const { data } = useGetArticleDetailsQuery({ articleId })

  const { contentUnitText, minimalOrderInfo } = useFormatArticleData(data)

  return (
    <Input
      name={QUANTITY_FIELD_NAME}
      type="number"
      label={t(messages.label)}
      caption={minimalOrderInfo}
      rightElement={
        <Typography textStyle="textNormalSb" data-testid="contentTextUnit">
          {contentUnitText}
        </Typography>
      }
    />
  )
}
