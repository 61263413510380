import { Flex } from '@chakra-ui/react'
import { forwardRef } from 'react'
import type { CustomContentProps } from 'notistack'
import { SnackbarContent, useSnackbar } from 'notistack'
import styled from '@emotion/styled'
import { CheckIcon } from '@chakra-ui/icons'

import { colors } from 'theme/colors'

import type { ModelSearchResult } from 'app/containers/common/Search/types/response/articleSearch'
import { ImageWithFallback } from 'app/components/ImageWithFallback'
import { Typography } from 'app/components/Typography'
import { ToastMessage } from 'app/pages/common/marketplaceAndSearch/components/CartItemToast/components/ToastMessage'
import { MinusIcon, PlusIcon, XIcon } from 'app/components/icons'

import { CircleBadge, CloseToastButton } from './components'
const ToastContainer = styled(Flex)(({ theme }) => ({
  width: '100%',
  padding: '8px 16px 8px 8px',
  background: theme.colors.backgroundA,
  borderRadius: '6px',
  border: theme.borders.primary,
  alignItems: 'center',
  gap: '16px',
}))

export type NotificationType =
  | 'added'
  | 'removed'
  | 'updated'
  | 'replicated'
  | 'positionRemoved'

export type CartItemToastArticle = Pick<
  ModelSearchResult['article'],
  'name' | 'thumbnailUrl'
>

export type CartItemToastContentProps = CustomContentProps & {
  article: CartItemToastArticle
  totalQuantity?: number
  type: NotificationType
}

const getBadgeIconAndColor = (type: NotificationType) => {
  switch (type) {
    case 'added':
    case 'replicated':
      return {
        icon: <PlusIcon width="16px" height="16px" color={colors.white} />,
        color: colors.green_600,
      }
    case 'updated':
      return {
        icon: <CheckIcon width="8px" height="8px" color={colors.white} />,
        color: colors.green_600,
      }
    case 'positionRemoved':
      return {
        icon: <MinusIcon width="12px" height="12px" color={colors.white} />,
        color: colors.red_500,
      }
    case 'removed':
      return {
        icon: <XIcon width="16px" height="16px" color={colors.white} />,
        color: colors.red_500,
      }
    default:
      return null
  }
}

export const CartItemToast = forwardRef<
  HTMLDivElement,
  CartItemToastContentProps
>(
  (
    { article: { name, thumbnailUrl }, type, style, id, totalQuantity },
    ref,
  ) => {
    const { closeSnackbar } = useSnackbar()
    const badgeIconAndColor = getBadgeIconAndColor(type)

    return (
      <SnackbarContent
        ref={ref}
        style={style}
        onClick={() => closeSnackbar(id)}
      >
        <ToastContainer data-testid="articleAddedToCartToast">
          <Flex position="relative">
            <ImageWithFallback src={thumbnailUrl} alt={name} size={48} />
            {badgeIconAndColor && (
              <CircleBadge color={badgeIconAndColor.color}>
                {badgeIconAndColor.icon}
              </CircleBadge>
            )}
          </Flex>

          <Flex flexDirection="column" flexGrow={1}>
            <Typography
              noOfLines={1}
              textStyle="inputNormal"
              data-testid="toastTitle"
            >
              {name}
            </Typography>

            <Typography textStyle="inputNormal" data-testid="toastContent">
              <ToastMessage type={type} quantity={totalQuantity} />
            </Typography>
          </Flex>
          <CloseToastButton />
        </ToastContainer>
      </SnackbarContent>
    )
  },
)
