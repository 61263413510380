import { useTranslation } from 'react-i18next'

import { history } from 'libs/history'
import { PATHS } from 'consts/paths'
import { useTheme } from 'theme'
import { translations } from 'locales/translations'

import { useNotificationSnackbar } from 'app/components/Notification/NotificationToast'
import { TickIcon } from 'app/components/icons'
import { useGetShowSuccessToast } from 'app/components/Notification/useGetShowSuccessToast'

type UseGetShowMoveArticlesToCartSuccessToastProps = {
  movedToCartArticlesCount: number
}

type UseShowDeleteArticleSuccessToastProps = {
  deletedArticlesCount: number
}

const moveToCartMessages = translations.pages.customer.scanner.toasts.moveToCart
const deleteMessages = translations.pages.customer.scanner.toasts.delete

export const useGetShowMoveArticlesToCartSuccessToast = ({
  movedToCartArticlesCount,
}: UseGetShowMoveArticlesToCartSuccessToastProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  const { enqueueNotificationSnackbar } = useNotificationSnackbar()

  const notificationProps = {
    type: 'success',
    customIcon: <TickIcon color={colors.contentA} />,
    buttonText: t(moveToCartMessages.buttonText),
    buttonClickHandler: () => history.push(PATHS.customer.checkout.cart),
    'data-testid': 'moveToCartSuccessToast',
  } as const

  const showToast = () =>
    enqueueNotificationSnackbar({
      notificationContent: t(moveToCartMessages.successMsg, {
        count: movedToCartArticlesCount,
      }),
      notificationProps,
    })

  return showToast
}

export const useShowDeleteArticleSuccessToast = ({
  deletedArticlesCount,
}: UseShowDeleteArticleSuccessToastProps) => {
  const { t } = useTranslation()
  const { showSuccessToast } = useGetShowSuccessToast()

  return () =>
    showSuccessToast({
      content: t(deleteMessages.successMsg, { count: deletedArticlesCount }),
    })
}
