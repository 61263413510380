import { Box, Center } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { translations } from 'locales/translations'

import { Modal } from 'app/components/modals/Modal'
import { ModalCancelButton } from 'app/components/modals/ModalCancelButton'
import { ModalSaveButton } from 'app/components/modals/ModalSaveButton'
import { EmptyCaseWrapper } from 'app/components/EmptyCaseWrapper'
import { Divider } from 'app/components/Divider'
import { EmptyListIcon } from 'app/components/icons'
import { Typography } from 'app/components/Typography'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useArticleListAssignmentsQuery } from 'app/containers/customers/ArticleLists/Assignments/List'

import type { FormValues } from './useArticleListAssignmentsForm'
import type { CommonModalProps } from './types'
import {
  PanelWithArticleImageAndContent,
  ArticleListCheckbox,
  CreateNewListButton,
} from './components'
import { ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID, messages } from './consts'

const tableMessages =
  translations.pages.customer.companyAndSettings.articlesLists.list

type ArticleListAssignmentsFormModalProps = CommonModalProps & {
  turnOnCreateMode: () => void
}

export const ArticleListAssignmentsFormModal = ({
  article,
  vendor,
  turnOnCreateMode,
}: ArticleListAssignmentsFormModalProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  const { data: articleListAssignments = [], isPending } =
    useArticleListAssignmentsQuery(article.id)

  const {
    dirty,
    values: { articleListsAssigned: currentArticleListsAssigned },
  } = useFormikContext<FormValues>()

  return (
    <Modal
      id={ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID}
      header={t(messages.header)}
      isWithoutHeaderBorder
      shouldWrapWithForm
      returnFocusOnClose={false}
      footer={(closeModal) => (
        <>
          <ModalCancelButton closeModal={closeModal} />
          <Center>
            <Typography textStyle="textNormal">
              {t(translations.xFromYSelected, {
                x: currentArticleListsAssigned.length,
                y: articleListAssignments?.length,
              })}
            </Typography>
          </Center>
          <ModalSaveButton
            closeModal={closeModal}
            isSaveButtonDisabled={!dirty}
          />
        </>
      )}
    >
      <PanelWithArticleImageAndContent article={article} vendor={vendor} />

      <CreateNewListButton turnOnCreateMode={turnOnCreateMode} />

      <Divider />

      <EmptyCaseWrapper
        shouldHideBorder
        data={articleListAssignments}
        isLoading={isPending}
        icon={<EmptyListIcon />}
        message={t(tableMessages.emptyCase.title)}
        subHeader={t(tableMessages.emptyCase.subtitle)}
      >
        <Box maxHeight={isMobile ? '160px' : '320px'} overflowY="auto">
          {articleListAssignments?.map((value) => (
            <ArticleListCheckbox key={value.articleListId} {...value} />
          ))}
        </Box>
      </EmptyCaseWrapper>
    </Modal>
  )
}
