import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { translations } from 'locales/translations'
import type { ModelGetCustomerArticleNumberDetailsData } from 'libs/openapiGeneratedApi/Api'

import { useUpdateCustomerArticleNumberMutation } from 'app/containers/customers/CustomerArticleNumber/Edit/hooks'

import { CUSTOMER_ARTICLE_NUMBER_SOURCES } from './consts'

const fieldsMessages = translations.formFields.customBuyerArticleNumber

const useValidationSchema = () => {
  const { t } = useTranslation()

  const validationSchema = yup.object({
    source: yup.string().oneOf(CUSTOMER_ARTICLE_NUMBER_SOURCES).required(),
    defaultValue: yup.mixed().nullable(), // Readonly field
    customValue: yup.string().when('source', {
      is: (value: string) => value === 'CUSTOM',
      then: (schema) =>
        schema.required().ensure().label(t(fieldsMessages.label)),
    }),
  })

  return { validationSchema }
}

type FormValues = yup.InferType<
  ReturnType<typeof useValidationSchema>['validationSchema']
>

type UseEditCustomerArticleNumberFormProps = {
  articleId: string
  customerArticleNumber?: ModelGetCustomerArticleNumberDetailsData
  onSuccess?: () => void
}

export const useEditCustomerArticleNumberForm = ({
  articleId,
  customerArticleNumber,
  onSuccess,
}: UseEditCustomerArticleNumberFormProps) => {
  const { validationSchema } = useValidationSchema()

  const { mutate: updateCustomerArticleNumber, isPending } =
    useUpdateCustomerArticleNumberMutation()

  const formik = useFormik<FormValues>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      source: customerArticleNumber?.source ?? 'DEFAULT',
      customValue: customerArticleNumber?.customValue ?? '',
      defaultValue: customerArticleNumber?.defaultValue ?? '',
    },

    onSubmit: (values) => {
      updateCustomerArticleNumber(
        {
          articleId,
          data: {
            source: values.source,
            customValue: values.source === 'CUSTOM' ? values.customValue : null,
          },
        },
        {
          onSuccess,
        },
      )
    },
  })

  return {
    formik,
    isPending,
  }
}
