import { PopoverContent, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const BulkPricesPopoverContentWrapper = styled(PopoverContent)(
  ({ theme: { shadows } }) => ({
    width: '384px',
    boxShadow: shadows.primary,
    '&:focus': {
      boxShadow: shadows.primary,
    },
  }),
)

export const BulkPriceContainer = styled(Flex)(({ theme: { borders } }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '44px',
  width: '100%',
  borderTop: borders.primary,
  ':last-child': {
    borderBottom: borders.primary,
  },
}))
