import { Typography } from 'app/components/Typography'

import type { ListItemType } from './types'

type ListItemProps = {
  item: ListItemType
}

export const DataRow = ({ item }: ListItemProps) => (
  <>
    <Typography color="contentB" minW="80px">
      {item.label}
    </Typography>

    <Typography textStyle="textNormalSb" textAlign="right" minW="80px">
      {item.value}
    </Typography>
  </>
)
