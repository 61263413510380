import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import { translations } from 'locales/translations'

import { ModalWithFooter } from 'app/components/modals/ModalWithFooter'
import { Typography } from 'app/components/Typography'

import { GLOBAL_MODALS_IDS } from '../consts'

import { VendorSpecificCustomerNumber } from './components'
import { useForm } from './useForm'
import type { CustomerNumberModalProps } from './types'

const messages = translations.vendorRequestFlow.modals.customerNumber

export const CustomerNumberModal = NiceModal.create<CustomerNumberModalProps>(
  ({
    customerName,
    customerId,
    vendorSpecificCustomerNumber,
    additionalOnCloseHandler,
  }) => {
    const { t } = useTranslation()

    const headerMsg = vendorSpecificCustomerNumber
      ? messages.title.change
      : messages.title.add

    const { validationSchema, initialValues, onSubmitHandler, isPending } =
      useForm({
        customerId,
        vendorSpecificCustomerNumber,
        additionalOnCloseHandler,
        hideModal: hideCustomerNumberModal,
      })

    return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
      >
        {({ resetForm, isValid, dirty }) => (
          <ModalWithFooter
            autoFocus={false}
            shouldWrapWithForm
            isSaveButtonDisabled={!isValid || !dirty}
            isSaveButtonLoading={isPending}
            width="550px"
            handleClose={() => resetForm()}
            onCancelHandler={(closeModal) => {
              resetForm()
              closeModal()

              if (additionalOnCloseHandler) additionalOnCloseHandler()
            }}
            id={GLOBAL_MODALS_IDS.customerNumber}
            header={t(headerMsg, { customerName })}
            saveTranslation={t(messages.saveButtonLabel)}
          >
            <Typography textStyle="textParagraph">
              {t(messages.subtitle)}
            </Typography>

            <VendorSpecificCustomerNumber />
          </ModalWithFooter>
        )}
      </Formik>
    )
  },
)

export const hideCustomerNumberModal = () =>
  NiceModal.remove(GLOBAL_MODALS_IDS.customerNumber)

export const showCustomerNumberModal = (params: CustomerNumberModalProps) =>
  NiceModal.show(GLOBAL_MODALS_IDS.customerNumber, params)
