import type { IconProps } from '../types'

export const WomanOnBoxesIcon = ({
  width = 269,
  height = 253,
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 269 253"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path d="M25.683 146.24h89.952v53.172H25.683V146.24Z" fill="#407BFF" />
    <path
      opacity={0.4}
      d="M25.683 146.24h89.952v53.172H25.683V146.24Z"
      fill="#fff"
    />
    <path
      opacity={0.2}
      d="M77.067 146.24h38.568v53.172H77.067V146.24Z"
      fill="#fff"
    />
    <path
      d="M106.79 149.165h6.399v8.358h-6.399v-8.358ZM98.784 149.165h6.399v8.358h-6.4v-8.358Z"
      fill="#263238"
    />
    <path
      d="m108.252 153.425 1.742-2.428 1.742 2.428h-1.101v2.274h-1.282v-2.274h-1.101ZM99.876 151.078h1.805l.099.433h-.306l.487 1.363-.181-1.092h.361l-.081-.704h2.031a2.298 2.298 0 0 1-1.895 2.392v1.941c.451.072.794.334.794.668h-2.04c0-.334.352-.596.803-.668v-1.986a2.298 2.298 0 0 1-1.877-2.347Z"
      fill="#fff"
    />
    <path d="M29.329 184.51h15.38v11.427H29.33V184.51Z" fill="#263238" />
    <path
      d="M34.375 186.803h8.349v.496h-8.35v-.496ZM38.554 188.518h4.17v.496h-4.17v-.496ZM36.352 190.224h6.372v.496h-6.372v-.496ZM15.267 199.069h116.047v53.172H15.267v-53.172Z"
      fill="#407BFF"
    />
    <path
      opacity={0.2}
      d="M66.652 199.069h64.662v53.172H66.652v-53.172Z"
      fill="#fff"
    />
    <path
      d="M96.356 199.069h11.011v3.529H96.356v-3.529ZM96.356 248.568h11.011v3.529H96.356v-3.529ZM56.362 202.002h6.399v8.358h-6.4v-8.358ZM48.356 202.002h6.4v8.358h-6.4v-8.358Z"
      fill="#263238"
    />
    <path
      d="m57.824 206.254 1.742-2.428 1.742 2.428h-1.1v2.274h-1.282v-2.274h-1.102ZM49.448 203.862h1.805l.1.433h-.307l.487 1.372-.18-1.101h.36l-.08-.704h1.994a2.311 2.311 0 0 1-1.896 2.401v1.931c.452.072.795.334.795.668h-2.013c0-.334.352-.596.803-.668v-1.931a2.322 2.322 0 0 1-1.868-2.401ZM112.151 234.578h15.381v11.426h-15.381v-11.426Z"
      fill="#fff"
    />
    <path
      d="M117.197 236.87h8.35v.497h-8.35v-.497ZM121.377 238.585h4.17v.496h-4.17v-.496ZM119.174 240.291h6.373v.496h-6.373v-.496Z"
      fill="#407BFF"
    />
    <path
      d="m43.857 206.859-7.085 4.143-10.696-15.777 7.085-4.152 10.696 15.786Z"
      fill="#E4897B"
    />
    <path
      d="m36.519 210.768 5.822-7.158a.653.653 0 0 1 .848-.154l6.318 3.791a1.337 1.337 0 0 1 .588.907 1.325 1.325 0 0 1-.28 1.043c-2.077 2.455-3.205 3.538-5.768 6.688-1.57 1.941-4.63 6.111-6.815 8.783-2.184 2.671-4.937.658-4.27-.65 2.997-5.867 3.115-9.026 3.07-11.869a2.111 2.111 0 0 1 .487-1.381Z"
      fill="#263238"
    />
    <path
      d="M33.856 215.253h.172c1.155-.18 2.383-2.202 3.006-3.384a.137.137 0 0 0-.019-.156.133.133 0 0 0-.071-.041.135.135 0 0 0-.082.007c-.379.163-3.719 1.634-3.926 2.708a.579.579 0 0 0 .18.532 1.025 1.025 0 0 0 .74.334Zm2.708-3.177c-.947 1.706-1.922 2.78-2.617 2.889a.745.745 0 0 1-.677-.235.334.334 0 0 1-.1-.289c.172-.722 2.275-1.832 3.394-2.365Z"
      fill="#407BFF"
    />
    <path
      d="M34.254 212.42a7.233 7.233 0 0 0 2.708-.551.119.119 0 0 0 .052-.043.119.119 0 0 0 .02-.065.128.128 0 0 0-.054-.127c-.081-.054-1.904-1.29-3.195-1.038a1.389 1.389 0 0 0-.903.605c-.28.424-.171.695 0 .839.393.297.883.432 1.372.38Zm2.374-.695c-1.11.442-3.15.595-3.565.135 0-.045-.154-.172.072-.505a1.068 1.068 0 0 1 .722-.488 4.814 4.814 0 0 1 2.771.894v-.036Z"
      fill="#407BFF"
    />
    <path
      d="m54.977 82.563-.343 1.371-.388 1.48c-.262.994-.56 1.996-.858 2.997a203.841 203.841 0 0 1-1.904 6.003c-.704 1.985-1.381 3.99-2.148 5.966-.37.984-.74 1.986-1.128 2.969l-.46 1.183a2.68 2.68 0 0 1-.362.568 10.606 10.606 0 0 1-3.998 2.528 37.863 37.863 0 0 1-5.696 1.805 83.345 83.345 0 0 1-6.146 1.182c-2.085.325-4.225.578-6.319.804l-.072 4.251c4.501.468 9.038.468 13.54 0a39.131 39.131 0 0 0 6.958-1.345 22.82 22.82 0 0 0 3.61-1.417 13.821 13.821 0 0 0 3.611-2.527 9.89 9.89 0 0 0 1.58-2.049 6.6 6.6 0 0 0 .334-.605l.207-.424.361-.749a92.4 92.4 0 0 0 1.426-3.024c.903-2.04 1.805-4.089 2.6-6.183a138.726 138.726 0 0 0 2.247-6.318 46.42 46.42 0 0 0 .903-3.276l.433-1.68c.126-.586.244-1.091.37-1.804l-8.358-1.706Z"
      fill="#E4897B"
    />
    <path
      d="M57.658 73.618c-3.385.858-5.759 14.794-5.759 14.794l9.333 6.318s8.945-14.053 6.318-17.781c-2.69-3.845-4.955-4.594-9.892-3.33Z"
      fill="#407BFF"
    />
    <path
      opacity={0.2}
      d="M57.658 73.618c-3.385.858-5.759 14.794-5.759 14.794l9.333 6.318s8.945-14.053 6.318-17.781c-2.69-3.845-4.955-4.594-9.892-3.33Z"
      fill="#000"
    />
    <path
      d="m57.324 78.682 7.221 1.678.695 7.086a82.172 82.172 0 0 1-4.061 7.311l-5.66-3.845c.298-3.259.903-10.028 1.805-12.23Z"
      fill="#407BFF"
    />
    <path
      opacity={0.3}
      d="m57.324 78.682 7.221 1.678.695 7.086a82.172 82.172 0 0 1-4.061 7.311l-5.66-3.845c.298-3.259.903-10.028 1.805-12.23Z"
      fill="#000"
    />
    <path
      d="m26.473 111.329-5.821-5.903-.768 8.313s4.369 2.96 7.393 1.372l-.804-3.782ZM13.801 104.532l-.262 7.004 6.345 2.194.768-8.313-6.851-.885ZM55.546 223.097h-8.214l-2.644-19.026h8.222l2.636 19.026Z"
      fill="#E4897B"
    />
    <path
      d="m46.79 220.642 8.124-4.341a.647.647 0 0 1 .84.189l4.359 5.948a1.334 1.334 0 0 1 .187 1.064 1.323 1.323 0 0 1-.666.85c-2.86 1.462-4.323 2.022-7.906 3.926-2.203 1.173-7.727 4.414-10.777 6.038-3.051 1.625-4.802-1.308-3.683-2.256 5.027-4.242 7.465-7.699 8.52-10.326.191-.473.547-.861 1.003-1.092ZM92.119 41.53c.207 9.027 11.255 5.3 12.492 15.697.695 5.84 7.771 6.445 9.026 11.734 1.227 5.118-3.764 8.836 0 15.209 5.036 8.53 7.852 23.404-20.408 24.947-21.012 1.147-28.405-20.2-24.84-33.314 3.566-13.115 5.552-15.137 1.643-18.747-3.908-3.61-10.172-5.416-8.168-16.446 1.552-8.53 5.325-7.527 5.325-7.527.19-4.712 24.434-11.635 24.93 8.448Z"
      fill="#263238"
    />
    <path
      d="m26.076 195.225 5.515 8.132 7.095-4.143-5.515-8.141-7.095 4.152ZM52.91 204.079h-8.222l1.372 9.802h8.213l-1.363-9.802Z"
      fill="#CE6F64"
    />
    <path
      d="M57.658 73.618s-3.917 1.38 3.927 49.579H94.98c-.56-13.538-.569-21.951 5.921-49.823a98.708 98.708 0 0 0-14.171-1.86 106.02 106.02 0 0 0-15.155 0c-6.534.597-13.918 2.104-13.918 2.104Z"
      fill="#407BFF"
    />
    <path
      opacity={0.2}
      d="M57.658 73.618s-3.917 1.38 3.927 49.579H94.98c-.56-13.538-.569-21.951 5.921-49.823a98.708 98.708 0 0 0-14.171-1.86 106.02 106.02 0 0 0-15.155 0c-6.534.597-13.918 2.104-13.918 2.104Z"
      fill="#000"
    />
    <path
      d="M85.196 54.465c-.957 4.874-1.914 13.8 1.498 17.059 0 0-1.336 4.946-10.389 4.946-8.719 0-4.766-4.946-4.766-4.946 5.416-1.3 5.299-5.334 4.351-9.125l9.306-7.934Z"
      fill="#E4897B"
    />
    <path
      d="M81.405 57.696 75.9 62.39c.233.887.387 1.793.46 2.708 2.086-.298 4.938-2.582 5.154-4.757a7.452 7.452 0 0 0-.108-2.645Z"
      fill="#CE6F64"
    />
    <path
      d="M85.765 41.612c.902 8.123 1.516 11.5-1.805 16.337-4.983 7.275-15.137 6.661-18.477-.993-3.005-6.887-4.043-18.954 3.232-23.567a11.12 11.12 0 0 1 17.05 8.223Z"
      fill="#E4897B"
    />
    <path
      d="M85.81 46.396c-1.147 2.13-18.693-7.573-16.698-12.78 1.994-5.209 7.22-3.322 7.22-3.322s9.27 2.112 10.832 6.923c1.561 4.81-1.354 9.179-1.354 9.179Z"
      fill="#263238"
    />
    <path
      d="M89.186 47.325a6.183 6.183 0 0 1-2.104 4.035c-1.588 1.345-3.168.144-3.384-1.805-.199-1.742.388-4.513 2.292-5.145 1.905-.632 3.376 1.029 3.196 2.915Z"
      fill="#E4897B"
    />
    <path
      d="M61.584 123.198s-51.935 5-51.394 30.047c.56 26.058 19.27 49.526 19.27 49.526l9.812-5.551S28.44 174.285 29 158.661c.451-13.359 50.257-15.922 50.257-15.922l6.869-19.541H61.584Z"
      fill="#407BFF"
    />
    <path
      opacity={0.6}
      d="M61.584 123.198s-51.935 5-51.394 30.047c.56 26.058 19.27 49.526 19.27 49.526l9.812-5.551S28.44 174.285 29 158.661c.451-13.359 50.257-15.922 50.257-15.922l6.869-19.541H61.584Z"
      fill="#FAFAFA"
    />
    <path
      d="m43.486 137.883-1.046 10.651c-7.266 2.274-12.853 5.415-13.404 9.622-1.228-9.424 5.343-14.993 14.45-20.273Z"
      fill="#407BFF"
    />
    <path
      opacity={0.4}
      d="m43.486 137.883-1.046 10.651c-7.266 2.274-12.853 5.415-13.404 9.622-1.228-9.424 5.343-14.993 14.45-20.273Z"
      fill="#FAFAFA"
    />
    <path
      d="M70.394 123.198s-35.635 11.806-36.537 26.419c-1.661 26.906 10.172 62.369 10.172 62.369h11.246s-.631-23.531-1.976-47.91c-1.68-30.625 52.35-.424 41.646-40.878H70.394Z"
      fill="#407BFF"
    />
    <path
      opacity={0.6}
      d="M70.394 123.198s-35.635 11.806-36.537 26.419c-1.661 26.906 10.172 62.369 10.172 62.369h11.246s-.631-23.531-1.976-47.91c-1.68-30.625 52.35-.424 41.646-40.878H70.394Z"
      fill="#FAFAFA"
    />
    <path
      d="M74.013 46.025c.117.65-.135 1.237-.56 1.318-.424.081-.902-.397-.983-1.047-.082-.65.135-1.245.56-1.318.423-.072.866.389.983 1.047ZM66.647 47.343c.109.65-.135 1.237-.568 1.318-.433.081-.903-.388-.984-1.047-.081-.659.135-1.237.569-1.318.433-.081.866.388.983 1.047Z"
      fill="#263238"
    />
    <path d="m65.854 46.342-1.643-.172s1.02 1.074 1.643.172Z" fill="#263238" />
    <path
      d="M68.796 48.147a17.837 17.837 0 0 1-1.543 4.513 2.88 2.88 0 0 0 2.4 0l-.857-4.513Z"
      fill="#DE5753"
    />
    <path
      d="M73.373 54.41h.054a5.687 5.687 0 0 0 2.87-3.059.117.117 0 0 0-.081-.154.119.119 0 0 0-.092.004.118.118 0 0 0-.061.069 5.524 5.524 0 0 1-2.708 2.915.126.126 0 0 0-.064.163.127.127 0 0 0 .082.063ZM74.834 43.101a.226.226 0 0 0 .18-.09.226.226 0 0 0 0-.343 3.719 3.719 0 0 0-3.285-.903.248.248 0 0 0 .127.479 3.205 3.205 0 0 1 2.834.821.262.262 0 0 0 .144.036ZM62.622 44.41a.27.27 0 0 0 .226-.154 3.25 3.25 0 0 1 2.22-1.931.252.252 0 0 0 .208-.29.252.252 0 0 0-.29-.198 3.683 3.683 0 0 0-2.59 2.22.235.235 0 0 0 .127.325.18.18 0 0 0 .1.028Z"
      fill="#263238"
    />
    <path
      d="M43.713 223.567c.966 0 2.274-1.011 3.005-1.661a.127.127 0 0 0 .045-.145.144.144 0 0 0-.117-.099c-.334 0-3.267-.271-3.88.533a.602.602 0 0 0-.082.604 1.068 1.068 0 0 0 .73.732c.099.022.199.034.299.036Zm2.581-1.652c-1.191.993-2.22 1.498-2.807 1.345a.78.78 0 0 1-.541-.551.306.306 0 0 1 0-.343c.433-.523 2.328-.514 3.348-.451Z"
      fill="#407BFF"
    />
    <path
      d="M46.628 221.942a.116.116 0 0 0 .117-.072.145.145 0 0 0 0-.136c-.045-.081-1.155-2.148-2.292-2.418a.891.891 0 0 0-.803.153.605.605 0 0 0-.298.722c.243.83 2.274 1.724 3.267 1.751h.01Zm-2.419-2.383h.19c.812.19 1.66 1.517 1.985 2.049-.947-.144-2.572-.903-2.762-1.525 0-.1-.045-.253.19-.415a.673.673 0 0 1 .397-.109Z"
      fill="#407BFF"
    />
    <path
      d="m73.227 44.978-1.651-.171s1.029 1.083 1.651.171ZM95.45 121.257l.261 2.988c.118.235-.153.469-.541.469H61.422c-.307 0-.551-.144-.57-.334l-.306-2.987c0-.208.244-.388.569-.388h33.793a.63.63 0 0 1 .541.252Z"
      fill="#263238"
    />
    <path
      d="M90.91 125.021h.903c.18 0 .316-.09.306-.208l-.424-3.881c0-.117-.171-.207-.352-.207h-.902c-.18 0-.316.09-.307.207l.424 3.881c.018.118.162.208.352.208Z"
      fill="#407BFF"
    />
    <path
      opacity={0.6}
      d="M90.91 125.021h.903c.18 0 .316-.09.306-.208l-.424-3.881c0-.117-.171-.207-.352-.207h-.902c-.18 0-.316.09-.307.207l.424 3.881c.018.118.162.208.352.208Z"
      fill="#FAFAFA"
    />
    <path
      d="M63.958 125.021h.903c.18 0 .316-.09.297-.208l-.424-3.881c0-.117-.162-.207-.343-.207h-.902c-.18 0-.316.09-.298.207l.424 3.881c.027.118.163.208.343.208Z"
      fill="#407BFF"
    />
    <path
      opacity={0.6}
      d="M63.958 125.021h.903c.18 0 .316-.09.297-.208l-.424-3.881c0-.117-.162-.207-.343-.207h-.902c-.18 0-.316.09-.298.207l.424 3.881c.027.118.163.208.343.208Z"
      fill="#FAFAFA"
    />
    <path
      d="M77.434 125.021h.902c.18 0 .325-.09.307-.208l-.424-3.881c0-.117-.171-.207-.352-.207h-.903c-.18 0-.316.09-.306.207l.424 3.881c.018.118.171.208.352.208Z"
      fill="#407BFF"
    />
    <path
      opacity={0.6}
      d="M77.434 125.021h.902c.18 0 .325-.09.307-.208l-.424-3.881c0-.117-.171-.207-.352-.207h-.903c-.18 0-.316.09-.306.207l.424 3.881c.018.118.171.208.352.208Z"
      fill="#FAFAFA"
    />
    <path
      d="m93.013 86.318 4.306 3.664c-.903 4.874-1.535 8.936-1.932 12.546-.803-3.62-2.861-13.44-2.374-16.21Z"
      fill="#407BFF"
    />
    <path
      opacity={0.3}
      d="m93.013 86.318 4.306 3.664c-.903 4.874-1.535 8.936-1.932 12.546-.803-3.62-2.861-13.44-2.374-16.21Z"
      fill="#000"
    />
    <path
      d="M1.805 100.136h58.552l8.484 34.579H10.29l-8.485-34.579Z"
      fill="#FAFAFA"
    />
    <path
      d="m31.474 128.279 35.806 8.349 22.98-6.444-.235-1.905H31.474Z"
      fill="#FAFAFA"
    />
    <path
      d="M95.883 85.261c.695 2.302 1.453 4.676 2.13 7.023.677 2.346 1.39 4.711 2.004 7.067.613 2.356 1.2 4.703 1.651 7.004.217 1.147.424 2.293.56 3.376.096.76.148 1.526.153 2.293a4.113 4.113 0 0 1-.993 1.633 14.754 14.754 0 0 1-2.265 2.004 43.293 43.293 0 0 1-5.93 3.547c-2.121 1.092-4.342 2.085-6.607 3.006-2.266.92-4.576 1.805-6.842 2.708l1.047 4.079c2.59-.406 5.055-.983 7.564-1.606 2.51-.623 4.955-1.444 7.42-2.347 2.545-.931 4.999-2.097 7.328-3.484a21.617 21.617 0 0 0 3.611-2.708 11.187 11.187 0 0 0 3.06-4.603c.17-.538.288-1.091.352-1.652v-3.529a42.487 42.487 0 0 0-.425-4.062c-.37-2.635-.902-5.18-1.462-7.69a150.269 150.269 0 0 0-4.215-14.775l-8.141 2.716Z"
      fill="#E4897B"
    />
    <path
      d="M100.865 73.42c3.34 1.02 8.457 14.143 8.457 14.143l-12.97 9.27s-5.83-11.22-4.423-15.543c1.499-4.54 5.11-9.071 8.936-7.87Z"
      fill="#407BFF"
    />
    <path
      opacity={0.2}
      d="M100.865 73.42c3.34 1.02 8.457 14.143 8.457 14.143l-12.97 9.27s-5.83-11.22-4.423-15.543c1.499-4.54 5.11-9.071 8.936-7.87Z"
      fill="#000"
    />
    <path
      d="m81.433 125.174-7.799-1.976 3.72 7.482s5.261.226 7.003-2.708l-2.924-2.798ZM67.343 126.041l3.447 6.101 6.562-1.462-3.718-7.482-6.291 2.843Z"
      fill="#E4897B"
    />
    <path d="M0 100.136h58.551l8.485 34.579H8.484L0 100.136Z" fill="#263238" />
    <path
      d="m8.485 134.714.235 1.914h58.56l-.235-1.914H8.485ZM70.511 36.792c-7.888 3.159-8.538 7.699-8.123 4.513.596-4.576 4.396-7.87 7.121-8.981 1.598-.668 4.45 3.087 1.002 4.468Z"
      fill="#263238"
    />
    <path
      d="m85.214 40.926.533.623a.46.46 0 0 1 0 .64l-4.26 3.882a.46.46 0 0 1-.623 0l-.731-.677a.46.46 0 0 1 0-.686l4.468-3.827a.46.46 0 0 1 .613.045Z"
      fill="#407BFF"
    />
    <path
      opacity={0.3}
      d="m85.214 40.926.533.623a.46.46 0 0 1 0 .64l-4.26 3.882a.46.46 0 0 1-.623 0l-.731-.677a.46.46 0 0 1 0-.686l4.468-3.827a.46.46 0 0 1 .613.045Z"
      fill="#000"
    />
    <path
      d="M86.306 70.947c-.09.685-.848 4.07-7.293 5.343 0 0 1.914 2.085 2.997 7.076 0 0 11.3-4.431 7.87-11.616a.406.406 0 0 0-.288-.226l-3.141-.668a.116.116 0 0 0-.092.014.118.118 0 0 0-.053.076Z"
      fill="#407BFF"
    />
    <path
      opacity={0.6}
      d="M86.306 70.947c-.09.685-.848 4.07-7.293 5.343 0 0 1.914 2.085 2.997 7.076 0 0 11.3-4.431 7.87-11.616a.406.406 0 0 0-.288-.226l-3.141-.668a.116.116 0 0 0-.092.014.118.118 0 0 0-.053.076Z"
      fill="#FAFAFA"
    />
    <path
      d="M71.91 71.47c-.776.821-2.897 3.61 2.465 4.892 0 0-3.259 1.805-4.342 6.796 0 0-8.024-5.514-.722-11.508a.064.064 0 0 1 .054 0l2.446-.343a.117.117 0 0 1 .1.163Z"
      fill="#407BFF"
    />
    <path
      opacity={0.6}
      d="M71.91 71.47c-.776.821-2.897 3.61 2.465 4.892 0 0-3.259 1.805-4.342 6.796 0 0-8.024-5.514-.722-11.508a.064.064 0 0 1 .054 0l2.446-.343a.117.117 0 0 1 .1.163Z"
      fill="#FAFAFA"
    />
    <path
      d="M146.38 20.18c.17-1.84-1.44-3-3.79-3a7.2 7.2 0 0 0-6.05 3.32L130 16.81c2.8-4.1 7.5-6.81 14.28-6.81 7.09 0 11.89 3 11.4 8.66-.63 7.18-8.28 8.24-8.65 12.48h-8.2c.53-5.98 7.25-7.5 7.55-10.96Zm-9.29 18.34a5.33 5.33 0 0 1 5.62-4.75c3.08 0 5.07 2 4.83 4.75a5.483 5.483 0 0 1-5.67 4.83c-3.09 0-5.02-2.16-4.78-4.83Z"
      fill="#E0E9FD"
    />
    <path
      d="M142.5 53a26.502 26.502 0 0 1-25.991-31.67A26.505 26.505 0 0 1 137.33.51 26.5 26.5 0 0 1 169 26.5 26.528 26.528 0 0 1 142.5 53Zm0-51.056a24.62 24.62 0 0 0-17.497 42.008 24.616 24.616 0 0 0 26.839 5.366 24.618 24.618 0 0 0 15.214-22.753A24.622 24.622 0 0 0 142.5 1.944Z"
      fill="#E0E9FD"
    />
    <path d="M225.359 168.61h-53.623v53.624h53.623V168.61Z" fill="#F5F5F5" />
    <path
      d="M258.305 168.61h-32.946v53.624h32.946V168.61ZM215.118 183.689h-53.623l10.241-15.079h53.623l-10.241 15.079Z"
      fill="#E6E6E6"
    />
    <path
      d="M235.077 183.689h32.924l-9.696-15.079h-32.946l9.718 15.079Z"
      fill="#F5F5F5"
    />
  </svg>
)
