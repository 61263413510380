import { useEffect, useState } from 'react'

import type { ModelGetFastOrderListParamsEnum } from 'libs/openapiGeneratedApi/Api'

import { useFastOrderListQuery } from 'app/containers/customers/FastOrders/List'

import { getCanItemBeSelected } from './utils'

export const useFastOrderItemsSelection = (
  fastOrderListEnum: ModelGetFastOrderListParamsEnum,
) => {
  const { data: availableItemIds } = useFastOrderListQuery({
    fastOrderListEnum,
    select: ({ items }) =>
      items.filter(getCanItemBeSelected).map(({ id }) => id),
  })

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])

  const availableItemsCount = availableItemIds?.length ?? 0

  const selectedItemsCount = selectedItemIds.length

  const selectAllItems = () => setSelectedItemIds(availableItemIds ?? [])

  const unselectAllItems = () => setSelectedItemIds([])

  const selectSingleItem = (id: string) =>
    setSelectedItemIds((prevSelectedIds) => [...prevSelectedIds, id])

  const unselectSingleItem = (id: string) =>
    setSelectedItemIds((prevSelectedIds) =>
      prevSelectedIds.filter((selectedId) => selectedId !== id),
    )

  const getIsSelected = (id: string) => selectedItemIds.includes(id)

  // INFO: when refreshing the data, we should check if all the previous selected items
  // are still available and can be selected
  useEffect(() => {
    const getIsItemAvailable = (id: string) => availableItemIds?.includes(id)

    setSelectedItemIds((prevSelectedIds) =>
      prevSelectedIds.filter(getIsItemAvailable),
    )
  }, [availableItemIds])

  return {
    selectedItemIds,
    availableItemsCount,
    selectedItemsCount,
    selectAllItems,
    unselectAllItems,
    selectSingleItem,
    unselectSingleItem,
    getIsSelected,
  }
}
