import { Redirect, Route, Switch } from 'react-router-dom'

import { ALL_DEFINED_PATHS } from 'consts/allDefinedPaths'
import { PATHS } from 'consts/paths'
import { lazyLoad } from 'utils/loadable'

import { FullPageLoader } from 'app/components/loaders/FullPageLoader'
import { useCurrentUser } from 'app/containers/common/Auth'
import { SelectAccount } from 'app/pages/common/switchAccount/SelectAccount/Loadable'
import { Error404 } from 'app/pages/static/Error404/Loadable'

import { useInitUser } from './useInitUser'
import { usePushedFromLocation } from './usePushedFromLocation'

const BackofficeRoutes = lazyLoad(
  () => import('./routes/BackofficeRoutes'),
  (module) => module.BackofficeRoutes,
)
const CustomerRoutes = lazyLoad(
  () => import('./routes/CustomerRoutes'),
  (module) => module.CustomerRoutes,
)
const VendorRoutes = lazyLoad(
  () => import('./routes/VendorRoutes'),
  (module) => module.VendorRoutes,
)

const DefaultRedirect = () => {
  const { isVendor, isCustomer, isBackofficeUser } = useCurrentUser()
  const { pushedFromLocation } = usePushedFromLocation()

  if (pushedFromLocation) return <Redirect to={pushedFromLocation} />

  if (isBackofficeUser) return <Redirect to={PATHS.backoffice.vendors} />

  if (isVendor) return <Redirect to={PATHS.vendor.home} />

  if (isCustomer) return <Redirect to={PATHS.customer.home} />

  return null
}

export const AuthorizedRoutes = () => {
  // Initialize user data and profile after successful authentication
  useInitUser()

  const {
    isVendor,
    isCustomer,
    isBackofficeUser,
    noIdentitySelected,
    isUserInitialized,
  } = useCurrentUser()

  if (noIdentitySelected) return <SelectAccount />

  if (!isUserInitialized) return <FullPageLoader />

  return (
    <Switch>
      {isBackofficeUser && (
        <Route path={PATHS.backoffice.root}>
          <BackofficeRoutes />
        </Route>
      )}

      {isVendor && (
        <Route path={PATHS.vendor.root}>
          <VendorRoutes />
        </Route>
      )}

      {isCustomer && (
        <Route path={PATHS.customer.root}>
          <CustomerRoutes />
        </Route>
      )}

      <Route path={PATHS.error404} exact>
        <Error404 />
      </Route>

      <Route path={ALL_DEFINED_PATHS} exact>
        <DefaultRedirect />
      </Route>

      <Route>
        <Error404 />
      </Route>
    </Switch>
  )
}
