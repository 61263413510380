import type {
  AccordionProps as AccordionBaseProps,
  AccordionItemProps as AccordionItemBaseProps,
  AccordionButtonProps as AccordionButtonBaseProps,
  AccordionPanelProps as AccordionPanelBaseProps,
} from '@chakra-ui/react'
import {
  Accordion as AccordionBase,
  AccordionItem as AccordionItemBase,
  AccordionButton as AccordionButtonBase,
  AccordionPanel as AccordionPanelBase,
  AccordionIcon,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useTheme } from 'theme'

import type { TypographyProps } from '../Typography'
import { Typography } from '../Typography'
import { Button } from '../Buttons/Button'

/*
 * Main Accordion Wrapper
 */
export type AccordionProps = AccordionBaseProps

export const Accordion = styled((props: AccordionProps) => (
  <AccordionBase allowToggle {...props} />
))(() => ({}))

/*
 * Accordion item wrapper
 */
export type AccordionItemProps = AccordionItemBaseProps & {
  withTopBorder?: boolean
}

export const AccordionItem = ({
  withTopBorder,
  ...props
}: AccordionItemProps) => {
  const { borders } = useTheme()

  return (
    <AccordionItemBase
      border="none"
      {...(withTopBorder && { borderTop: borders.primary })}
      {...props}
    />
  )
}

/*
 * Expand Button
 */
export type AccordionButtonProps = AccordionButtonBaseProps

export const AccordionButton = styled((props: AccordionButtonProps) => (
  <AccordionButtonBase
    p="16px 0"
    gap="8px"
    justifyContent="flex-start"
    alignItems="flex-start"
    {...props}
  />
))(({ theme: { colors } }) => ({
  ':hover': {
    background: 'transparent',
    '.accordionTitle': {
      color: colors.blue_500,
    },
  },
  ':focus': {
    boxShadow: 'none',
  },
}))

/*
 * Expanded Content
 */
export type AccordionPanelProps = AccordionPanelBaseProps

export const StyledAccordionPanel = styled((props: AccordionPanelProps) => (
  <AccordionPanelBase p="0 0 16px 0" {...props} />
))(() => ({}))

export const AccordionChevronInBoxButton = () => (
  <Button
    variant="tertiary"
    size="squareSm"
    as="div"
    leftIcon={<AccordionIcon color="contentC" />}
  />
)

export const AccordionRightSideTitle = (props: TypographyProps) => (
  <Typography
    textStyle="textSmall"
    color="contentC"
    flexShrink={0}
    px="4px"
    {...props}
  />
)
