import { useCurrentUser } from 'app/containers/common/Auth'
import {
  useCheckCustomerPermission,
  useHasPrivilegeAssigned,
} from 'app/containers/common/Me/CompanyUser'

export const useCanEditArticle = () => {
  const { isCustomer } = useCurrentUser()
  const { isAdmin } = useCheckCustomerPermission()

  const hasAtLeastOnePrivilegeAssigned = [
    useHasPrivilegeAssigned('EDIT_EXTERNAL_REFERENCES'),
    useHasPrivilegeAssigned('EDIT_CUSTOMER_ARTICLE_NUMBERS'),
  ].some(Boolean)

  return isCustomer && (isAdmin || hasAtLeastOnePrivilegeAssigned)
}
