import { Spinner, AccordionIcon, Flex } from '@chakra-ui/react'
import { isEmpty } from 'lodash'

import { useTheme } from 'theme'

import { Typography } from 'app/components/Typography'

import {
  AccordionButton,
  AccordionItem,
  StyledAccordionPanel,
  AccordionRightSideTitle,
} from '../styles'

import type { AccordionRowProps } from './types'
import { ScrollToActiveElementWrapper } from './components'

export const AccordionRow = ({
  children,
  title,
  rightSideTitle,
  isLoading,
  shouldScrollToActiveElement,
  isActiveColorDisabled,
  isRightSideHidden,
  accordionButtonProps,
  iconLeft,
  buttonLabelProps,
  _expanded,
  ...rest
}: AccordionRowProps) => {
  const { colors } = useTheme()

  return (
    <AccordionItem {...rest}>
      {({ isExpanded }) => (
        <>
          <AccordionButton _expanded={_expanded} {...accordionButtonProps}>
            <ScrollToActiveElementWrapper
              isExpanded={isExpanded}
              isEnabled={shouldScrollToActiveElement}
            >
              {({ setRefs }) =>
                typeof title === 'function' ? (
                  <>{title({ isExpanded, setRefs })}</>
                ) : (
                  <Typography
                    className="accordionTitle"
                    id={`accordionTitle${title}`}
                    textStyle="textParagraphSb"
                    textAlign="start"
                    display="inline-flex"
                    alignItems="center"
                    ref={setRefs}
                    gap="8px"
                    color={
                      isExpanded && !isActiveColorDisabled
                        ? 'blue_500'
                        : undefined
                    }
                    {...buttonLabelProps}
                  >
                    {iconLeft}
                    {title}
                  </Typography>
                )
              }
            </ScrollToActiveElementWrapper>

            {!isRightSideHidden && (
              <Flex flexShrink={0}>
                {rightSideTitle && (
                  <AccordionRightSideTitle mr="4px">
                    {rightSideTitle}
                  </AccordionRightSideTitle>
                )}

                {isLoading ? (
                  <Spinner boxSize="20px" color="contentA" />
                ) : (
                  <AccordionIcon boxSize="20px" color={colors.contentC} />
                )}
              </Flex>
            )}
          </AccordionButton>

          {!isEmpty(children) && (
            <StyledAccordionPanel>{children}</StyledAccordionPanel>
          )}
        </>
      )}
    </AccordionItem>
  )
}
