import { css } from '@emotion/react'

import { mediaQuery } from 'theme/mediaQueries'

import type { ButtonProps } from '../types'

export const smallButtonDimensionStyles = css({
  height: '36px',
  padding: '8px 12px',

  [mediaQuery.small]: {
    padding: '8px 10px',
  },
})

export const squareSmallButtonDimensionStyles = css({
  height: '36px',
  width: '36px',
  padding: '7px',
})

export const makeMediumButtonDimensionStyles = ({
  isTallOnMobile,
}: Pick<ButtonProps, 'isTallOnMobile'>) =>
  css({
    height: '48px',
    padding: '14px 16px',

    [mediaQuery.small]: {
      height: isTallOnMobile ? '48px' : '36px',
      padding: '8px 12px',
    },
  })

export const fastActionButtonDimensionStyles = css({
  height: '44px',
  padding: '12px 24px',
})

export const squareMediumButtonDimensionStyles = css({
  width: '48px',
  height: '48px',
  padding: '14px',
  [mediaQuery.small]: {
    height: '36px',
    width: '36px',
    padding: '7px',
  },
})
