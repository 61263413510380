import type { StackProps } from '@chakra-ui/react'
import { HStack } from '@chakra-ui/react'

import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'
import { TrashIcon } from 'app/components/icons'
import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'

export const PriceAndActionsWrapper = (props: StackProps) => (
  <HStack spacing="20px" justify="flex-end" {...props} />
)

export const DeleteButton = (props: ButtonProps) => (
  <Button
    data-testid="deleteButton"
    variant="secondary"
    size="squareSm"
    {...props}
  >
    <TrashIcon />
  </Button>
)

export const SmallText = (props: TypographyProps) => (
  <Typography textStyle="textSmall" color="contentB" {...props} />
)
