import { store } from 'store'

import type { MODEL__JWTTokenResponse } from 'app/models/tokenResponse'

import { logout, setAuthData } from './authSlice'

export const loginFlow = (tokens: MODEL__JWTTokenResponse) => {
  store.dispatch(setAuthData(tokens))
}

export const logoutFlow = (dispatch = store.dispatch) => {
  dispatch(logout())
}
