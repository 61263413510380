import type { CSSProperties } from 'react'

import type { MediaQuery } from 'theme/mediaQueries'
import { mediaQuery } from 'theme/mediaQueries'

type TypographyBuildingBlock = Pick<
  CSSProperties,
  'fontSize' | 'lineHeight' | 'fontWeight' | 'letterSpacing' | 'textTransform'
>

type TypographyProps = TypographyBuildingBlock &
  Partial<Record<MediaQuery, TypographyBuildingBlock>>

const defaultTextSmall: TypographyBuildingBlock = {
  fontSize: '12px',
  lineHeight: '16px',
}
const textSmall: TypographyProps = defaultTextSmall
const textSmallSb: TypographyProps = {
  ...defaultTextSmall,
  fontWeight: 600,
}
const textSmallLink: TypographyProps = {
  ...defaultTextSmall,
  lineHeight: '20px',
}

const defaultTextNormal: TypographyBuildingBlock = {
  fontSize: '14px',
  lineHeight: '20px',
}
const textNormal: TypographyProps = defaultTextNormal
const textNormalSb: TypographyProps = {
  ...defaultTextNormal,
  fontWeight: 600,
}

const tabLabelText: TypographyProps = {
  ...textNormal,
  fontWeight: 400,
  lineHeight: '16px',
}

const tabLabelTextSb: TypographyProps = {
  ...textNormalSb,
  lineHeight: '16px',
}

const defaultTextParagraph: TypographyBuildingBlock = {
  fontSize: '16px',
  lineHeight: '24px',
}
const textParagraph: TypographyProps = defaultTextParagraph

const textParagraphSb: TypographyProps = {
  ...defaultTextParagraph,
  fontWeight: 600,
}

const defaultInputNormal: TypographyBuildingBlock = {
  fontSize: '16px',
  lineHeight: '20px',
}
const inputNormal: TypographyProps = defaultInputNormal
const inputNormalSb: TypographyProps = {
  ...defaultInputNormal,
  fontWeight: 600,
}
const inputMediumButtonSb: TypographyProps = textNormalSb

const textLarge = inputNormal
const textLargeSb = inputNormalSb

const headerH5: TypographyProps = {
  fontSize: '10px',
  lineHeight: '20px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  fontWeight: '600',
}

const headerH4: TypographyProps = {
  fontSize: '18px',
  lineHeight: '24px',
  fontWeight: 600,
  [mediaQuery.small]: {
    lineHeight: '22px',
  },
}

const headerSmall: TypographyProps = {
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 600,
}

const headerH3: TypographyProps = {
  fontSize: '22px',
  lineHeight: '32px',
  fontWeight: 600,
  [mediaQuery.small]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
}

const headerH2: TypographyProps = {
  fontSize: '26px',
  lineHeight: '32px',
  fontWeight: 600,
  [mediaQuery.small]: {
    fontSize: '22px',
    lineHeight: '30px',
  },
}

const headerH1: TypographyProps = {
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: 600,
  [mediaQuery.small]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
}

const headerLarge: TypographyProps = { fontSize: '48px', lineHeight: '56px' }

const listRecordTitle: TypographyProps = inputNormalSb
const listRecordTitleLarge: TypographyProps = {
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight: 700,
}

const headingXSmall: TypographyProps = {
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
}

const figmaLabelM: TypographyProps = {
  fontSize: '180px',
  lineHeight: '216px',
  fontWeight: 700,
}

export const textStyles = {
  textNormal,
  textNormalSb,
  textSmallLink,
  textSmall,
  textSmallSb,
  textParagraph,
  textParagraphSb,
  textLarge,
  textLargeSb,
  inputNormal,
  inputNormalSb,
  inputMediumButtonSb,
  headerH1,
  headerH2,
  headerH3,
  headerSmall,
  headerH4,
  headerH5,
  listRecordTitle,
  listRecordTitleLarge,
  headerLarge,
  headingXSmall,
  tabLabelText,
  tabLabelTextSb,
  figmaLabelM,
} as const

export type TextStyle = keyof typeof textStyles
