import { useMutation, useQueryClient } from '@tanstack/react-query'

import { CATALOG_CUSTOMER_ASSIGNMENTS_QUERY_KEY } from 'app/containers/vendors/PlatformCatalogs/CustomerAssignements'

import { CUSTOMERS_QUERY_KEY } from '../List'
import { CUSTOMERS_COUNTS_QUERY_KEY } from '../Counts'

import { updateVendorCustomerRequest } from './api'

export const useUpdateVendorCustomerMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateVendorCustomerRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CUSTOMERS_QUERY_KEY] })
      queryClient.invalidateQueries({
        queryKey: [CATALOG_CUSTOMER_ASSIGNMENTS_QUERY_KEY],
      })
      queryClient.invalidateQueries({ queryKey: [CUSTOMERS_COUNTS_QUERY_KEY] })
    },
  })
}
