import { ColumnHeaderTypography } from '../ColumnHeaderTypography'

import { GoChevronIcon, HeaderWithChevronWrapper } from './styles'
import type { HeaderWithChevronProps } from './types'

export const HeaderWithChevron = ({
  children,
  onClick,
  chevronDirection,
  dataTestId,
}: HeaderWithChevronProps) => {
  const isActive = !!chevronDirection

  return (
    <HeaderWithChevronWrapper
      isActive={isActive}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <ColumnHeaderTypography
        cursor="pointer"
        color={isActive ? 'blue_500' : 'contentB'}
      >
        {children}
      </ColumnHeaderTypography>

      <GoChevronIcon
        className="chevron-icon"
        data-testid={chevronDirection}
        chevronDirection={chevronDirection ?? 'UP'}
      />
    </HeaderWithChevronWrapper>
  )
}
