import { getOciSessionId } from 'utils/getOciSessionId'

import { useOciSession } from 'app/containers/common/Auth'

import { AuthorizedRoutes } from './authorized'
import { UnauthorizedRoutes, useUnauthorizedFlows } from './unauthorized'
import { EmailRedirectRoute, useIsEmailRedirectRoute } from './emailRedirect'
import { OciCustomerSessionRoutes } from './customerAndOciCommonRoutes'
import {
  ApproveOrderFromLinkRoutes,
  useIsApproveOrderFromLinkFlow,
} from './approveOrderFromLinkFlow'
import { SSORoutes, useIsSSOFlow } from './ssoFlow'

export const AppRoutes = () => {
  const { areAuthorizedRoutesAvailable } = useUnauthorizedFlows()
  const { isEmailRedirectRoute } = useIsEmailRedirectRoute()
  const { isApproveOrderFromLinkFlow } = useIsApproveOrderFromLinkFlow()
  const { isSSOFlow } = useIsSSOFlow()
  const { ociSessionId } = useOciSession()
  const pathOciSessionId = getOciSessionId()

  if (isSSOFlow) {
    return <SSORoutes />
  }

  if (isEmailRedirectRoute) {
    return <EmailRedirectRoute />
  }

  if (isApproveOrderFromLinkFlow) {
    return <ApproveOrderFromLinkRoutes />
  }

  if (pathOciSessionId || ociSessionId) {
    return <OciCustomerSessionRoutes />
  }

  if (areAuthorizedRoutesAvailable) {
    return <AuthorizedRoutes />
  }

  return <UnauthorizedRoutes />
}
