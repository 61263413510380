import { LinkOverlay } from '@chakra-ui/react'

import { useTheme } from 'theme'

import { MailOpenIcon, SupportIcon } from 'app/components/icons'
import { useCompanyCountryConfig } from 'app/containers/common/Profile'

import { ColumnWithGap } from '../../ColumnWithGap'

import { LinkBoxStyled } from './styles'
import { SupportAvailabilityStatusTag } from './SupportAvailabilityStatusTag'

export const SupportMailAndPhoneBoxes = () => {
  const { colors } = useTheme()
  const { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } = useCompanyCountryConfig()

  return (
    <ColumnWithGap gap={20} data-testid="supportMailAndPhoneBoxes">
      <LinkBoxStyled>
        <MailOpenIcon color={colors.contentA} />
        <LinkOverlay href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
          {SUPPORT_EMAIL}
        </LinkOverlay>
      </LinkBoxStyled>

      <LinkBoxStyled>
        <SupportIcon color={colors.contentA} />
        <LinkOverlay href={`tel:${SUPPORT_PHONE_NUMBER}`} target="_blank">
          {SUPPORT_PHONE_NUMBER}
        </LinkOverlay>
        <SupportAvailabilityStatusTag />
      </LinkBoxStyled>
    </ColumnWithGap>
  )
}
