import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { api } from 'apiServices'

import { useOciSession } from 'app/containers/common/Auth'

import type { MODEL__AssignedGeneralLedger } from './models'

export const url = 'assigned-general-ledgers'

export type API__AssignedGeneralLedgersResponse = {
  generalLedgers: MODEL__AssignedGeneralLedger[]
}

const getAssignedGeneralLedgersRequest = () =>
  api.get<unknown, API__AssignedGeneralLedgersResponse>(url)

export const ASSIGNED_GENERAL_LEDGERS_QUERY_KEY = 'assignedGeneralLedgers'

export const useAssignedGeneralLedgersQuery = ({
  enabled = true,
}: Pick<UseQueryOptions, 'enabled'> = {}) => {
  const { isOciSessionFlow } = useOciSession()

  return useQuery({
    queryKey: [ASSIGNED_GENERAL_LEDGERS_QUERY_KEY],
    queryFn: getAssignedGeneralLedgersRequest,
    staleTime: 1000 * 3,
    enabled: enabled && !isOciSessionFlow,
  })
}
