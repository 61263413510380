import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { ReactNode } from 'react'

import { translations } from 'locales/translations'

import { useCompanyCommonPathsRedirect } from 'app/hooks/useCompanyCommonPathsRedirect'
import { clearDeliveryAddressStore } from 'app/containers/customers/DeliveryAddresses/deliveryAddressesSlice'
import { useIsChangeCompanyAvailable } from 'app/containers/common/Me/User'
import { useCurrentUser } from 'app/containers/common/Auth'

export const ChangeCompanyActions = ({
  ButtonComponent,
}: {
  ButtonComponent: React.ComponentType<{
    onClick: () => void
    children: ReactNode
  }>
}) => {
  const { t } = useTranslation()
  const { isChangeCompanyAvailable } = useIsChangeCompanyAvailable()
  const { isUserImpersonated } = useCurrentUser()
  const dispatch = useDispatch()

  const goToSwitchAccount = useCompanyCommonPathsRedirect({
    pathKey: 'switchAccount',
  })

  if (!isChangeCompanyAvailable || isUserImpersonated) {
    return null
  }

  const onChangeCompanyClick = () => {
    goToSwitchAccount()
    dispatch(clearDeliveryAddressStore())
  }

  return (
    <ButtonComponent onClick={onChangeCompanyClick}>
      {t(translations.header.changeCompany)}
    </ButtonComponent>
  )
}
