import { Trans } from 'react-i18next'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'

const messages = translations.pages.customer.articleDetails

type SoldByTextProps = {
  vendorName?: string
}

export const SoldByText = ({ vendorName }: SoldByTextProps) => {
  const { colors } = useTheme()

  if (!vendorName) return null

  return (
    <Trans
      i18nKey={messages.soldBy}
      components={[
        <span
          style={{
            color: colors.green_600,
          }}
        />,
      ]}
      values={{ name: vendorName }}
    />
  )
}
