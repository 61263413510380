import { useFastOrderListCountQuery } from 'app/containers/customers/FastOrders/Count'

export const useScannerButton = () => {
  const { data } = useFastOrderListCountQuery({
    fastOrderListEnum: 'SCANNING_LIST',
  })
  const { count: numberOfScanningListItems = 0 } = data ?? {}

  const hasScanningListItems = numberOfScanningListItems > 0

  return {
    hasScanningListItems,
    numberOfScanningListItems,
  }
}
