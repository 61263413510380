import type { ModelGetVendorMarketplaceCustomersWithCurrencyData } from 'libs/openapiGeneratedApi/Api'

export const customersListMockData: ModelGetVendorMarketplaceCustomersWithCurrencyData =
  [
    {
      id: '6805ce4b-7233-4ff1-8922-a6f40a6654b6',
      name: 'Khazad-dûm',
      assignedCustomerArticleNumberList: {
        id: '123',
        name: '2022_06_12_dask_TRY',
        filename: '2022_06_12_dask_TRY.csv',
      },
    },
    {
      id: '4e4c6ea1-60a3-4024-9fec-93dc81bb3a50',
      name: 'Shire',
      assignedCustomerArticleNumberList: null,
    },
  ]
