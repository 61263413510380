import { Center, Flex, Spacer } from '@chakra-ui/react'
import { Trans } from 'react-i18next'

import { colors } from 'theme/colors'
import { translations } from 'locales/translations'

import type { MODEL__ArticleListAssignment } from 'app/containers/customers/ArticleLists/types'
import { MultiCheckbox } from 'app/components/inputs/Checkbox'
import { Typography } from 'app/components/Typography'
import { AccessLevelTag } from 'app/pages/common/articleList'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.list.table

type LabelWithCountProps = { translationLabel: string; count: number }

const LabelWithCount = ({ translationLabel, count }: LabelWithCountProps) => (
  <Typography textStyle="textSmall" textColor={colors.contentB}>
    <Trans
      i18nKey={translationLabel}
      components={[
        <Typography
          textStyle="textSmallSb"
          textColor={colors.contentA}
          as="span"
        />,
      ]}
      values={{ count }}
    />
  </Typography>
)

const ArticleListRowLabel = ({
  name,
  articlesCount,
  vendorsCount,
  accessLevel,
}: MODEL__ArticleListAssignment) => (
  <Flex direction="row">
    <Flex direction="column" gap="4px">
      <Typography textStyle="textNormal" textColor={colors.contentA}>
        {name}
      </Typography>
      <Flex direction="row" gap="4px">
        <LabelWithCount
          translationLabel={messages.articlesCount}
          count={articlesCount}
        />
        <LabelWithCount
          translationLabel={messages.vendorsCount}
          count={vendorsCount}
        />
      </Flex>
    </Flex>
    <Spacer />
    <Center>
      <AccessLevelTag
        pointerEvents="none"
        accessLevel={accessLevel}
        withoutTooltip={true}
      />
    </Center>
  </Flex>
)

export const ArticleListCheckbox = (props: MODEL__ArticleListAssignment) => (
  <MultiCheckbox
    withLabelFirst
    padding="12px 20px"
    dataTestId="assignmentCheckbox"
    name="articleListsAssigned"
    value={props.articleListId}
    label={<ArticleListRowLabel {...props} />}
  />
)
