import type { MutableRefObject } from 'react'
import { useCallback } from 'react'
import type { Html5Qrcode } from 'html5-qrcode'
import { Html5QrcodeScannerState } from 'html5-qrcode'

type UseOtherScanningCallbackParams = {
  scanner: MutableRefObject<Html5Qrcode | null>
  setScannerStateWithFallback: () => void
}

export const useGetOtherScanningCallbacks = ({
  scanner,
  setScannerStateWithFallback,
}: UseOtherScanningCallbackParams) => {
  const stopScanning = useCallback(() => {
    try {
      if (
        scanner.current &&
        scanner.current.getState() === Html5QrcodeScannerState.SCANNING
      ) {
        scanner.current
          .stop()
          .then(() => {
            setScannerStateWithFallback()
          })
          .catch((error) => {
            setScannerStateWithFallback()
            console.error('fails to stop scanning', error)
          })
      }
      // INFO: Not all errors are handled in the .catch promise handler, thus the separate catch block
    } catch (error: unknown) {
      console.error('failed to stop scanning', error)
    }
    // INFO: scanner is ref and should not be included here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setScannerStateWithFallback])

  const pauseScanning = useCallback(() => {
    try {
      if (
        scanner.current &&
        scanner.current.getState() === Html5QrcodeScannerState.SCANNING
      ) {
        scanner.current.pause()
        setScannerStateWithFallback()
      }
    } catch (error: unknown) {
      setScannerStateWithFallback()
      console.error('failed to pause scanning', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setScannerStateWithFallback])

  const resumeScanning = useCallback(() => {
    try {
      if (
        scanner.current &&
        scanner.current.getState() === Html5QrcodeScannerState.PAUSED
      ) {
        scanner.current.resume()
        setScannerStateWithFallback()
      }
    } catch (error: unknown) {
      setScannerStateWithFallback()
      console.error('failed to resume scanning', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setScannerStateWithFallback])

  return {
    stopScanning,
    pauseScanning,
    resumeScanning,
  }
}
