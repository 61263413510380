import type { PopoverProps } from '@chakra-ui/react'
import { debounce } from 'lodash'

type Modifiers = NonNullable<PopoverProps['modifiers']>

export const observeResizePopperModifier: Modifiers[0] = {
  name: 'observeResizePopperModifier',
  enabled: true,
  phase: 'main',
  fn: () => {},
  effect: ({ state, instance }) => {
    const { popper } = state.elements

    const observer = new ResizeObserver(
      // INFO: Thanks to https://stackoverflow.com/a/77680580
      debounce(([{ target }]) => {
        const bottomSpace =
          window.innerHeight - target.getBoundingClientRect().bottom

        if (bottomSpace < 0) {
          instance.update()
        }
      }),
    )

    observer.observe(popper)

    return () => {
      observer.disconnect()
    }
  },
}

export const modifiers: Modifiers = [
  observeResizePopperModifier,
  {
    name: 'preventOverflow',
    options: {
      altAxis: true,
      tether: false,
    },
  },
  {
    name: 'flip',
    enabled: false,
  },
]
