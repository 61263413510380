import { useSnackbar } from 'notistack'

import type { ModelAddArticlesToCartData } from 'libs/openapiGeneratedApi/Api'

import type { CartItemToastArticle } from '../CartItemToast'

export type HandleBulkedOperationProps = {
  articlesNumber: number
  vendorsNumber: number
  articleImages: (string | undefined)[]
}

export type HandleSingleOperationProps = {
  operation: Pick<
    ModelAddArticlesToCartData[number],
    'performedOperation' | 'inCartQuantity'
  >
  article: CartItemToastArticle
}

export const useAddToCartNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()
  const handleBulkedOperation = ({
    articleImages,
    articlesNumber,
    vendorsNumber,
  }: HandleBulkedOperationProps) => {
    enqueueSnackbar({
      variant: 'cartArticlesList',
      articlesNumber,
      vendorsNumber,
      articleImages,
    })
  }

  const handleSingleOperation = ({
    operation,
    article,
  }: HandleSingleOperationProps) => {
    switch (operation.performedOperation) {
      case 'CREATED': {
        enqueueSnackbar({
          article,
          type: 'added',
          variant: 'cartItem',
          totalQuantity: operation.inCartQuantity,
        })
        break
      }
      case 'REPLICATED':
        enqueueSnackbar({
          article,
          type: 'replicated',
          variant: 'cartItem',
          totalQuantity: operation.inCartQuantity,
        })
        break
      case 'UPDATED': {
        enqueueSnackbar({
          article,
          type: 'updated',
          variant: 'cartItem',
          totalQuantity: operation.inCartQuantity,
        })
        break
      }
      default:
        break
    }
  }

  return { handleSingleOperation, handleBulkedOperation }
}
