import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { Login } from 'app/pages/public/Login/Loadable'
import { CompanyRegistration } from 'app/pages/common/registrationFlow/CompanyRegistration/Loadable'
import { ChooseCompanyRegistrationType } from 'app/pages/common/registrationFlow/ChooseCompanyRegistrationType/Loadable'
import { CompanyRegistrationSuccess } from 'app/pages/common/registrationFlow/CompanyRegistrationSuccess/Loadable'
import { Oci401 } from 'app/pages/static/Oci401/Loadable'

import {
  InvitationRoutes,
  ActivationRoutes,
  ResetPasswordRoutes,
} from './routes'

export const UnauthorizedRoutes = () => {
  const location = useLocation()

  return (
    <Switch>
      <Route path={PATHS.activation.root}>
        <ActivationRoutes />
      </Route>

      <Route path={PATHS.invitation.root}>
        <InvitationRoutes />
      </Route>

      <Route path={PATHS.chooseCompanyType}>
        <ChooseCompanyRegistrationType />
      </Route>

      <Route path={PATHS.customerCompanyRegistration}>
        <CompanyRegistration />
      </Route>

      <Route path={PATHS.vendorCompanyRegistration}>
        <CompanyRegistration />
      </Route>

      <Route path={PATHS.customerRegistrationSuccess}>
        <CompanyRegistrationSuccess />
      </Route>

      <Route path={PATHS.vendorRegistrationSuccess}>
        <CompanyRegistrationSuccess />
      </Route>

      <Route path={PATHS.root} exact>
        <Login />
      </Route>

      <Route path={PATHS.passwordReset.root}>
        <ResetPasswordRoutes />
      </Route>

      <Route path={PATHS.oci401}>
        <Oci401 />
      </Route>

      <Redirect
        to={{ pathname: PATHS.root, state: { pushedFromLocation: location } }}
      />
    </Switch>
  )
}
