import { useQuery, keepPreviousData } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useSearchAndPagination } from 'app/hooks/useSearchAndPagination'

import { useArticleListsNameSortQueryParam } from './useArticleListsNameSortQueryParam'

export const ARTICLE_LISTS_QUERY_KEY = 'ARTICLE_LISTS_QUERY_KEY'

export const useArticleListsQuery = () => {
  const { term, page, pageSize } = useSearchAndPagination()

  const { queryArticleListsNameSort: sort } =
    useArticleListsNameSortQueryParam()

  const params = { term, page, pageSize, sort }

  return useQuery({
    queryKey: [ARTICLE_LISTS_QUERY_KEY, params],
    queryFn: () =>
      generatedApi.customers.customerArticleListsSearch.request({
        data: { term },
        query: { page, pageSize, sort: [sort] },
      }),

    placeholderData: keepPreviousData,
  })
}
