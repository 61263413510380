import type { UseMutationResult } from '@tanstack/react-query'

import { useDeleteArticleFromArticleListMutation } from 'app/containers/customers/ArticleLists/Articles/delete'
import { useUpdateArticleFromArticleListMutation } from 'app/containers/customers/ArticleLists/Articles/update'
import { getArticlesListAccessLevel } from 'app/pages/customers/ArticleLists/components'
import { useSelectActionsContext } from 'app/pages/customers/ArticleLists/ArticleListDetails/context'
import { ArticleInputHandlersProvider } from 'app/pages/common/marketplaceAndSearch/components/articleInputHandlersContext'
import { PersistentAmountInput } from 'app/pages/common/marketplaceAndSearch/components/AmountInput'

import type { PriceAndActionsCellProps } from './types'
import { DeleteButton } from './styles'

export const useArticleActions = ({
  articleListDetailsItem: { quantity, articleData },
  articleListId,
  accessLevel,
}: PriceAndActionsCellProps) => {
  const {
    article: { id: articleId, quantityInterval, minimumOrder },
  } = articleData

  const { deleteSelectedArticle } = useSelectActionsContext()

  const { isOwner, isCollaborator } = getArticlesListAccessLevel(accessLevel)
  const areActionsAvailable = isOwner || isCollaborator

  const updateMutation = useUpdateArticleFromArticleListMutation({
    articleListId,
    suppressGlobalOnError: true,
  })
  const deleteMutation = useDeleteArticleFromArticleListMutation({
    articleListId,
    suppressGlobalOnError: true,
  })

  const onDeleteSuccess = () => deleteSelectedArticle(articleId)

  const deleteButtonRendered = areActionsAvailable && (
    <DeleteButton
      isLoading={deleteMutation.isPending || deleteMutation.isSuccess}
      onClick={() =>
        deleteMutation.mutate(articleId, { onSuccess: onDeleteSuccess })
      }
    />
  )

  const amountInputRendered = (
    <ArticleInputHandlersProvider
      deleteHandler={(onError) =>
        deleteMutation.mutate(articleId, {
          onSuccess: onDeleteSuccess,
          onError,
        })
      }
      updateHandler={(newQuantity, onError) =>
        updateMutation.mutate({ articleId, quantity: newQuantity }, { onError })
      }
      deleteUseMutationResult={deleteMutation as UseMutationResult}
      updateUseMutationResult={updateMutation as UseMutationResult}
    >
      <PersistentAmountInput
        isImmutable={!areActionsAvailable}
        quantityInterval={quantityInterval}
        minimumOrder={minimumOrder}
        quantity={quantity}
        size="sm"
      />
    </ArticleInputHandlersProvider>
  )

  return { deleteButtonRendered, amountInputRendered }
}
