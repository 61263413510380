import type { IconProps } from '../icons/types'

import { CustomSpinner, SpinnerWrapper } from './styles'

export const FULL_PAGE_LOADER_ID = 'fullPageLoader'

export const FullPageLoader = (props: IconProps) => (
  <SpinnerWrapper data-testid={FULL_PAGE_LOADER_ID}>
    <CustomSpinner {...props} />
  </SpinnerWrapper>
)
