import type { IconProps } from '../types'

export const UploadIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="m14 7-4-4-4 4M10 13V3M16 16H4" stroke={color} strokeWidth={2} />
  </svg>
)
