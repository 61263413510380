import * as persistConstants from 'redux-persist/lib/constants'
import storage from 'redux-persist/lib/storage'
import type { PersistConfig } from 'redux-persist'

import { AUTH_REDUCER_NAME } from 'app/containers/common/Auth/consts'
import { LANGUAGE_REDUCER_NAME } from 'app/containers/common/UnauthorizedUserLanguage/consts'
import { DELIVERY_ADDRESSES_REDUCER_NAME } from 'app/containers/customers/DeliveryAddresses/consts'

export const REDUX_PERSIST_ACTIONS = [
  persistConstants.FLUSH,
  persistConstants.PAUSE,
  persistConstants.PURGE,
  persistConstants.PERSIST,
  persistConstants.REGISTER,
  persistConstants.REHYDRATE,
]

export const persistConfig: PersistConfig<unknown> = {
  key: 's2ng-root',
  storage,
  whitelist: [
    AUTH_REDUCER_NAME,
    LANGUAGE_REDUCER_NAME,
    DELIVERY_ADDRESSES_REDUCER_NAME,
  ],
}
