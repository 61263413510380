import { AccordionIcon } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { ActionItem, MenuItemWrapper } from 'app/components/ActionsMenu/styles'
import { ActionDivider } from 'app/components/ActionsMenu/ActionsMenu'
import {
  AccordionButton,
  AccordionItem,
  StyledAccordionPanel,
  Accordion,
} from 'app/components/accordions/styles'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { GlobeIcon } from '../../icons'

import { LanguageOptions } from './LanguageOptions'

const LanguageAccordionMenuItem = (
  props: React.ComponentProps<typeof ActionItem>,
) => (
  <ActionItem
    closeOnSelect={false}
    display="flex"
    justifyContent="space-between"
    sx={{
      // INFO: MenuItem is internally wrapping elements in span when you pass icon which is breaking the layout
      span: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    }}
    {...props}
  />
)

export const LanguageAccordion = () => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(-1)
  const { isTablet } = useBreakpointScreenSize()
  const MenuItemComponent = isTablet
    ? MenuItemWrapper
    : LanguageAccordionMenuItem

  return (
    <Accordion
      index={index}
      onChange={(expandedIndex: number) => setIndex(expandedIndex)}
    >
      <AccordionItem borderTop="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton p="0">
              <MenuItemComponent
                as="div"
                bg={isExpanded && !isTablet ? 'backgroundC' : undefined}
                {...(isTablet
                  ? {}
                  : { icon: <GlobeIcon />, iconSpacing: '16px' })}
              >
                <Typography>{t(translations.header.changeLanguage)}</Typography>

                <AccordionIcon boxSize="20px" color="contentC" />
              </MenuItemComponent>
            </AccordionButton>

            <StyledAccordionPanel p="0">
              <ActionDivider />

              <LanguageOptions
                MenuItemComponent={MenuItemComponent}
                handleSelectOption={() => setIndex(-1)}
              />
            </StyledAccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}
