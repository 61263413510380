import type { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { useCallback } from 'react'
import { Html5Qrcode } from 'html5-qrcode'

import type { MobileScannerProps } from '../types'
import { MOBILE_SCANNER_TARGET_TAG_ID } from '../consts'

import { cameraScanConfig } from './scannerConfig'

type UseGetStartScanningParams = {
  scanner: MutableRefObject<Html5Qrcode | null>
  pauseScanning: () => void
  resumeScanning: () => void
  setScannerStateWithFallback: () => void
  setIsProgressButtonShown: Dispatch<SetStateAction<boolean>>
} & Pick<MobileScannerProps, 'onScanSuccessHandler'>

export const useGetStartScanning = ({
  scanner,
  pauseScanning,
  resumeScanning,
  setScannerStateWithFallback,
  setIsProgressButtonShown,
  onScanSuccessHandler,
}: UseGetStartScanningParams) => {
  const onScanSuccessCallback = (decodedMessage: string) => {
    pauseScanning()

    onScanSuccessHandler({
      decodedMessage,
      resumeScanning,
      setIsProgressButtonShown,
    })
  }

  const startScanning = useCallback(() => {
    try {
      const html5qrCodeScanner = new Html5Qrcode(MOBILE_SCANNER_TARGET_TAG_ID)

      // TODO: On page HMR second scanner is added https://github.com/mebjas/html5-qrcode/issues/641
      scanner.current = html5qrCodeScanner
      setScannerStateWithFallback()

      html5qrCodeScanner
        .start(
          { facingMode: 'environment' },
          cameraScanConfig,
          onScanSuccessCallback,
          undefined,
        )
        .then(() => {
          setScannerStateWithFallback()
        })
    } catch (error) {
      setScannerStateWithFallback()
      console.error('failed to start scanning', error)
    }
    // INFO: It's dangerous to rerun it, so we want to keep the deps minimal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { startScanning }
}
