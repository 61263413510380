import { isAxiosError } from 'axios'

export const currentCartScope = 'carts/current' as const
export const cartItemsScope = `${currentCartScope}/items` as const
export const cartItemPath = `${cartItemsScope}/:cartItemId` as const
export const cartVendorsScope = `${currentCartScope}/vendors` as const
export const cartVendorPath = `${cartVendorsScope}/:vendorId` as const

const throwOnError: (error: Error) => boolean = (error) =>
  isAxiosError(error) && String(error.response?.status).startsWith('5')

export const cartEssentialDataRelatedQuerySettings = {
  refetchOnWindowFocus: false, // refetch on focus redundant for now, also prevents focus instead of +- click
  gcTime: 0, // INFO: whenever user is going back to the cart, cache is disabled and isPending is true, more explanation below
  staleTime: 10 * 1000, // INFO: do not refetch for components that are rendered after initial fetch
  retry: false, // INFO: disable retries as they're locking database for longer time which at the end results in limited availability of the backend (https://simplesystem.atlassian.net/browse/S2NG-8474)
  throwOnError, // INFO: throw error and display error page in case of 5xx error response from backend, so that there're no infinite api calls caused by `gcTime: 0` (https://simplesystem.atlassian.net/browse/S2NG-9355)
}
// INFO: we ALWAYS want to render cart components only with a newest data (not old) - to not show old approval data, to not render inputs with wrong quantities
// to do that we are using PartPageLoaded + isLoading prop (isFetching can't be used as it would hide cart content on each refetch)
// SO to be able to achieve the above, we need gcTime: 0
