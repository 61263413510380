import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'
import { Tabs, TabList } from '@chakra-ui/react'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import { useCheckCustomerPermission } from 'app/containers/common/Me/CompanyUser'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useOciSession } from 'app/containers/common/Auth'

import { HEADER_CUSTOMER_TABS_MAP } from './consts'
import type { HeaderCustomersTabData } from './types'
import { TabStyled, TabsWrapper } from './styles'

const messages = translations.layout.customer.tabs

export const useHeaderTabsData = () => {
  const { t } = useTranslation()
  const { isAdmin } = useCheckCustomerPermission()
  const { isMobile } = useBreakpointScreenSize()
  const { isOciSessionFlow } = useOciSession()

  const tabsData: HeaderCustomersTabData[] = [
    {
      title: t(messages.home),
      path: HEADER_CUSTOMER_TABS_MAP.home,
      dataTestId: 'home',
    },

    {
      title: t(messages.shopping),
      path: HEADER_CUSTOMER_TABS_MAP.shopping,
      dataTestId: 'marketplace',
      matchByPaths: [
        HEADER_CUSTOMER_TABS_MAP.shopping,
        PATHS.customer.articleDetails,
      ],
    },

    {
      title: t(messages.vendors),
      path: HEADER_CUSTOMER_TABS_MAP.vendors,
      dataTestId: 'vendors',
    },
    {
      title: t(translations.header.articlesLists),
      path: HEADER_CUSTOMER_TABS_MAP.articleLists,
      dataTestId: 'articlesLists',
    },

    ...(!isOciSessionFlow
      ? [
          {
            title: t(translations.header.orderHistory),
            path: HEADER_CUSTOMER_TABS_MAP.orderHistory,
            dataTestId: 'orderHistory',
            matchByPaths: [
              HEADER_CUSTOMER_TABS_MAP.orderHistory,
              PATHS.customer.platformOrderDetails,
            ],
          },

          {
            title: t(translations.header.deliveriesAndPayments),
            path: HEADER_CUSTOMER_TABS_MAP.deliveryAndPayments,
            dataTestId: 'deliveriesAndPayments',
          },
        ]
      : []),

    ...(isAdmin && !isMobile
      ? [
          {
            title: t(messages.settings),
            path: HEADER_CUSTOMER_TABS_MAP.settings,
            dataTestId: 'settings',
            matchByPaths: [
              HEADER_CUSTOMER_TABS_MAP.settings,
              PATHS.customer.companyAndSettings.root,
            ],
          },
        ]
      : []),
  ]

  return tabsData
}

export const HeaderCustomerTabs = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const tabsData = useHeaderTabsData()

  const currentActiveKey = tabsData.findIndex((tab) => {
    if (tab.matchByPaths) {
      return tab.matchByPaths.some((match) => matchPath(pathname, match))
    }

    return matchPath(pathname, tab.path)
  })

  return (
    <TabsWrapper>
      <Tabs
        data-testid="customerHeaderTabs"
        variant="unstyled"
        index={currentActiveKey}
      >
        <TabList>
          {tabsData.map(({ title, dataTestId }, index) => (
            <TabStyled
              key={title}
              data-testid={dataTestId}
              onClick={() => history.push(tabsData[index].path)}
            >
              {title}
            </TabStyled>
          ))}
        </TabList>
      </Tabs>
    </TabsWrapper>
  )
}
