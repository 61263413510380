import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { translations } from 'locales/translations'

import { CartIcon, GrayTickInsideCircleIcon } from 'app/components/icons'
import type { ButtonProps } from 'app/components/Buttons/Button'

import type { AddToCartButtonState } from './types'

const messages = translations.pages.customer.search.searchArticle

type UseBareButtonState = (props: {
  state: AddToCartButtonState
  isInline?: boolean
}) => {
  buttonProps: ButtonProps
  icon?: ReactElement
  label?: string
  ariaLabel: string
}

export const useBareButtonStateProps: UseBareButtonState = ({
  state,
  isInline,
}) => {
  const { t } = useTranslation()

  return useMemo(() => {
    switch (state) {
      case 'loading':
        return {
          buttonProps: { isLoading: true },
          ariaLabel: t(translations.words.loading),
        }
      case 'success':
        return {
          buttonProps: {
            variant: 'success',
            isDisabled: true,
            _disabled: {
              background: 'green.500',
              color: 'white',
              cursor: 'not-allowed',
            },
          },
          icon: (
            <GrayTickInsideCircleIcon
              style={{ marginRight: isInline ? 0 : '8px', display: 'inline' }}
              color="white"
            />
          ),
          label: t(messages.addedToBasket),
          ariaLabel: t(messages.addedToBasket),
        }
      case 'idle':
      default:
        return {
          buttonProps: {},
          icon: (
            <CartIcon
              style={{ marginRight: isInline ? 0 : '8px', display: 'inline' }}
            />
          ),
          label: t(messages.addToBasket),
          ariaLabel: t(messages.addToBasket),
        }
    }
  }, [state, t, isInline])
}
