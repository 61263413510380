import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { ModalButton } from './ModalButton'
import type { CloseModal, ModalWithFooterProps } from './types'

const { cancel } = translations.modal

export type ModalCancelButtonProps = Pick<
  ModalWithFooterProps,
  'onCancelHandler' | 'cancelTranslation'
> & { closeModal: CloseModal }

export const ModalCancelButton = ({
  onCancelHandler,
  closeModal,
  cancelTranslation,
}: ModalCancelButtonProps) => {
  const { t } = useTranslation()

  return (
    <ModalButton
      variant="secondary"
      type="button"
      onClick={(event) => {
        typeof onCancelHandler === 'function'
          ? onCancelHandler(closeModal, event)
          : closeModal()
      }}
    >
      {cancelTranslation || t(cancel)}
    </ModalButton>
  )
}
