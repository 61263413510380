import { Flex } from '@chakra-ui/react'

import { Button } from 'app/components/Buttons/Button'
import { PencilIcon, TrashIcon } from 'app/components/icons'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import {
  hideEditArticleModal,
  showConfirmDeleteExternalReferenceModal,
  showExternalReferencesFormModal,
} from '../../../modalFlowActions'
import type { ExternalReferencesCellType } from '../types'

export const ActionsCell = ({
  articleId,
  value: externalReference,
}: ExternalReferencesCellType & { articleId: string }) => {
  const { isMobile } = useBreakpointScreenSize()

  const editHandler = () => {
    hideEditArticleModal()
    showExternalReferencesFormModal({
      articleId,
      externalReference,
    })
  }
  const deleteHandler = () => {
    if (!isMobile) {
      hideEditArticleModal()
    }

    showConfirmDeleteExternalReferenceModal({ articleId, externalReference })
  }

  return (
    <Flex gap="8px">
      <Button
        size="squareSm"
        variant="tertiary"
        onClick={editHandler}
        dataTestId="editExternalReferenceButton"
      >
        <PencilIcon />
      </Button>
      <Button
        size="squareSm"
        variant="tertiary"
        onClick={deleteHandler}
        dataTestId="deleteExternalReferenceButton"
      >
        <TrashIcon />
      </Button>
    </Flex>
  )
}
