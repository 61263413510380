import type { CountryCode } from 'consts/countries'

import type {
  MODEL__User,
  CustomerId,
  VendorId,
  UserId,
  MODEL__UserSettings,
} from 'app/models/user'

export const settings: MODEL__UserSettings = {
  locale: 'en',
  viewSettings: {
    searchPage: 'LIST',
    isShowingAllVendorsShops: false,
  },
}

export const createVendorMockData = (
  customSettings: Partial<MODEL__UserSettings> = {},
): MODEL__User => ({
  id: 'ef1b8af4-f511-4fe6-9b6b-0a548a9819fa' as UserId,
  username: 'johnytabasco@gmail.com',
  vendors: [
    {
      id: '722e31c7-c5ed-43d2-8690-994726e332dc' as VendorId,
      name: 'ACME Ltd.',
      vatId: '123',
      address: {
        streetName: 'Customer Str.',
        streetNumber: '2',
        postalCode: '00002',
        city: 'Test City 2',
        countryCode: 'DE' as CountryCode,
      },
    },
  ],
  customers: [],
  settings: {
    ...settings,
    ...customSettings,
  },
})

export const mockCustomerCompanyTypeData: MODEL__User = {
  id: 'ef1b8af4-f511-4fe6-9b6b-0a548a9819fa' as UserId,
  username: 'Johny Tabasco',
  vendors: [],
  customers: [
    {
      id: '722e31c7-c5ed-43d2-8690-994726e332dc' as CustomerId,
      name: 'ACME Ltd.',
      vatId: '123',
      address: {
        streetName: 'Customer Str.',
        streetNumber: '2',
        postalCode: '00002',
        city: 'Test City 2',
        countryCode: 'DE' as CountryCode,
      },
    },
  ],
  settings,
}

export const mockOciCustomerCompanyTypeData: MODEL__User = {
  id: 'df1b8af4-f511-4fe6-9b6b-0a548a9819fa' as UserId,
  username: 'OCI-Test',
  vendors: [],
  customers: [
    {
      id: 'd22e31c7-c5ed-43d2-8690-994726e332dc' as CustomerId,
      name: 'OCI ACME Ltd.',
      vatId: '123',
      address: {
        streetName: 'Customer Str.',
        streetNumber: '2',
        postalCode: '00002',
        city: 'Test City 2',
        countryCode: 'DE' as CountryCode,
      },
    },
  ],
  settings,
}

export const mockBackofficeData: MODEL__User = {
  id: 'ef1b8af4-f511-4fe6-9b6b-0a548a9819fa' as UserId,
  username: 'johnytabasco@gmail.com',
  vendors: [],
  customers: [],
  settings: {
    locale: 'en',
    viewSettings: null,
  },
}
