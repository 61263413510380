import { http, HttpResponse } from 'msw'

import { refreshTokenUrl } from 'libs/request/utils/handleUnauthorizedRequest'

import type { Token } from 'app/models/tokenResponse'
import { joinApiPaths } from 'app/utils/joinApiPaths'

import { loginUrl, logoutUrl } from './api'
import {
  ACCOUNT_NOT_VERIFIED_STATUS,
  INVALID_CREDENTIALS_STATUS,
} from './consts'

export const vendorToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImwyUFVxX09UTE80blctYjZNZUc3T2F0Z0EtWSJ9.eyJhdWQiOiI4NGMyNDAwNi0xNzlhLTRhZDYtYTk0ZS1kZTQ5YzAxYjkyMTQiLCJleHAiOjE2NDg0NzgxMTksImlhdCI6MTY0ODQ3NDUxOSwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo5MDExLyIsInN1YiI6ImYyZDg0NWNiLTliODMtNDM5Zi05ZTcyLTg0MzdhYjg4ZDEzOSIsImp0aSI6ImE1ODA4ODliLTI5NjgtNGNjZi04YmZkLTViOWJlZjA0NmU5NCIsImF1dGhlbnRpY2F0aW9uVHlwZSI6IlJFRlJFU0hfVE9LRU4iLCJlbWFpbCI6InZlbmRvcjFAbG9jYWwubG9jYWxob3N0IiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImFwcGxpY2F0aW9uSWQiOiI4NGMyNDAwNi0xNzlhLTRhZDYtYTk0ZS1kZTQ5YzAxYjkyMTQiLCJyb2xlcyI6WyJwbGF0Zm9ybV91c2VyIl19.m9zt__SVYgQR-0LegTQxFQ0GjCHwV88scYjL51Q_nYXYn_qH41YfxywJjkGkTuirjkqG6DKKcKdCdmPR7dMONfw7mESONXKuBUBWLg_qU091OmBPUQd_ZOPHFQEzxLtAnzAyuKqCaLUYghLotJoHLCNoipn_EGj52UxpWavpJUqdSEWo87CdpVLiENDjPrYYYtkXOCy8mW9JFStYiTgEcx5HrrxoURhZUm9XQNO6meNZ4pnO3tU4U0oFIMJuUDmL1XCk_7yTPHx6Tgptnsm8TR84q2siT0xPtDyitbXEN6B9RcAoGqHKZPkKPkUAOLeyiZ39qP69udnCt5CYdqL4vLJeAD5IEfetxlVoRlEPh2E-Khy2tNa4on3KQWIkOV-0lQnTMg9vZcZ4oqjkDdY3TmgUrLJItp-ruRnTteNxJb5t9KsHPJYoIosbWAgYWOM6dz7hJlwvorn2OoFjEVvDElAcGaMGRMPjmPgy0nHYLnfNMiSjoIxBe2Qx48FKYz8JKhSECDE1KpctSibkGtgnops-WADuWIcT66BU5KsYrK4aOo7rGgikGyHVONimFAciWt35Si2ZB862YtgObFgRMBya-q_ng8kHIWGtyDYXrAJqGpwAzB__4G6FygAyURZPCIDqXcaYUYvuwjbjt_4VsctbvCbS73u1xVlkLNmJm7g' as Token
export const customerToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkJSN1VvYndRTU9RTHU0ZWM2YXhjbEFKNEZVQSJ9.eyJhdWQiOiJmOGI4YmJhMy0wZTIxLTQzY2EtYjVmNy04MjBmNjZhZGVlYTgiLCJleHAiOjE2ODcyNTkzMDIsImlhdCI6MTY4NzI1OTI4NywiaXNzIjoic2ltcGxlc3lzdGVtLWRldi5mdXNpb25hdXRoLmlvIiwic3ViIjoiZGQwZjA1MDAtZDI1Yy00ZTg2LTgzMTUtNjg3YThkNGUzMjhhIiwianRpIjoiNDRiN2IwNTItNjM4Ny00ZDhhLTg5OGItZjhhZjM4NmZjNTNlIiwiYXV0aGVudGljYXRpb25UeXBlIjoiUEFTU1dPUkQiLCJlbWFpbCI6ImN1c3RvbWVyMUBsb2NhbC5kZSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiZjhiOGJiYTMtMGUyMS00M2NhLWI1ZjctODIwZjY2YWRlZWE4Iiwicm9sZXMiOlsiUExBVEZPUk1fVVNFUiJdLCJzaWQiOiI3MjQ3NWIzYy1iMWQyLTRlMzQtYTI0NC1lNWQzODMyZTQ2NmEiLCJhdXRoX3RpbWUiOjE2ODcyNTkyODcsInRpZCI6ImUyNWIwNjMyLTFkM2QtOWY0OS1lMmJiLWJkMzJjN2RmNjA0YyJ9.VBvoHdnrY8j0e9mR1JYtP0M3BOQmFGzAnIgRBCK6pMSvjGSnQuJwCho8O5fcbyeg_JWg7ZMBhoXj3S6IkgyvCiyK-zU4NIfC3ChTrqH17bpQJOOC2b13Xq9Q1Nhpn_x6iTr35TWE6PFkR8kJNhCzgILJUCspggOe8PvUHoT2z67f2aO_aNV47ms90gwa1HJ-tOx7eICpbhY1d5JOlWhZBNNI09Sl7QPlbe2OB2nVgW9bXoIfBKyLImDcK1YcfUPlbHwUbhIZ5801N8EcHidn8C9tQx9H7GSY3ChI5Ck6ZGTOJgIB8f233MmRJJjVRhCsDrkizjllS-lrRTaeRc489xa6tjsp2iM1FKrRFGZhF9E_5fH_JLxbDzLf0QwVSvFxGYfLjoV5ojBybnkLe_ng6eGlZMeuA-w7Jm63ADQlc8RO-806Hd4TVqI0mytPneIJTdK0rBmhbafzaS6VwY_Dll5uYXAS8jFrqf_eFn28W900pNhxQQwu_iBORBfY6XsXKzNeBzAQMmctHfAi_eyaZZElpP3GcwrJLl_u1TxvVZcBpdbbyxnUsnT8CR8muEbFAw-4WSUxVIW43qPLyraTlGU0HSl-Bz8NPDF-GecFoM-ar2_sakoyPC3xYjuf1E0bGHEsyN3HG8c52sf94mS-B_cxRu_9fVHSA6rezPBwUtk' as Token
export const backofficeToken =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InVZQnRySFE1ZjdOMXA1cUVscG11by1EWnFHNCJ9.eyJhdWQiOiI4NGMyNDAwNi0xNzlhLTRhZDYtYTk0ZS1kZTQ5YzAxYjkyMTQiLCJleHAiOjE2NDk4NTQ4MDAsImlhdCI6MTY0OTg1MTIwMCwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo5MDExLyIsInN1YiI6ImU3MGZjMmE5LTQ0Y2UtNDk5ZC04MDI3LWU3OTM1OWZhYTc5ZSIsImp0aSI6IjQzOGM5OTZkLTIwOGUtNDlhMy04ZTYzLTcxOWQ5NDRlYWUzNSIsImF1dGhlbnRpY2F0aW9uVHlwZSI6IlJFRlJFU0hfVE9LRU4iLCJlbWFpbCI6ImJhY2tvZmZpY2UxQGxvY2FsLmxvY2FsaG9zdCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiODRjMjQwMDYtMTc5YS00YWQ2LWE5NGUtZGU0OWMwMWI5MjE0Iiwicm9sZXMiOlsiQkFDS09GRklDRV9VU0VSIl19.iFWMFxR8QLl3NaQaZnVkzPhmYn83LlzHTl_d4syzcQtBR6WCaMSnjPV5ax7cLXAl4Rqfnl1kIcNlLy0F8v3iBXH8QlIv8xF_nURCkaFaEm2J2myEU3HOCvdd_nkAnbpNvn5Krlrm0HVThHQ_5MTzZZRKrID902n99LhX8JwAF6ciLefUsDgH8NfoBExnwvHNT651DAflJ1eWTIlBGzi8329fq1gwygnPudTc6FcNq0myr3PlYtqCsSe4dn0EgrK4IJROGTZGcYjiee-N1vahNrijMg6JP2OmetrrhS1kjUKUmsf3gXuPNysCqTA4Htd6QGJZNgMrpurIaz2_3Xyg0_M-jxYrndDpEi8ffayauk5GAmRHaJ76GdX4JlUO9DJeJRO0qcZiuKjTxeS27THzMNMnM_OCuAJD5o1Fo1VaMy5_ACahe-qwZiaExm-Xus182uRT4H2lgrGcn8c8YXmSztq0BZIO5QP7PLs0zCv1azucpxs0W7CUgUMqyR9iugiFUrIPRfOUaij_Iar8h7SsJG5eig8bcM81WmOKf-Pp5cUV59jE0Rg1UnveX67v08Z1gJOU7JiVcPEccP9VIQroqpghqNaDhl_3IFnVqy9i__iA6voRB4Kd8oV3dYZU7cyjoTeyDPaYObjebFCll3etu9APAPUGLnNMdAxFzln86tQ' as Token

export const refreshTokensPostMock = http.post(
  joinApiPaths(refreshTokenUrl),
  () => new HttpResponse(null, { status: 400 }),
)

export const makeCustomerLoginPostMock = (spy?: jest.Mock) =>
  http.post(joinApiPaths(loginUrl), async ({ request }) => {
    spy?.(await request.json())

    return HttpResponse.json(
      {
        token: customerToken,
        refreshToken: 'refreshToken',
      },
      { status: 200 },
    )
  })

export const customerLoginPostMockWith401Error = http.post(
  joinApiPaths(loginUrl),
  () => new HttpResponse(null, { status: INVALID_CREDENTIALS_STATUS }),
)

const error409response = {
  userId: '123test',
}

export const customerLoginPostMockWith409Error = http.post(
  joinApiPaths(loginUrl),
  () =>
    HttpResponse.json(error409response, {
      status: ACCOUNT_NOT_VERIFIED_STATUS,
    }),
)

export const logoutPostMock = http.post(
  joinApiPaths(logoutUrl),
  () => new HttpResponse(null, { status: 200 }),
)

export const makeLogoutPostMockWithSpy = (spy: jest.Mock) =>
  http.post(joinApiPaths(logoutUrl), () => {
    spy()

    return new HttpResponse(null, { status: 200 })
  })
