import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { useDebounce } from 'react-use'
import type { KeyboardEvent } from 'react'
import { useEffect, useState } from 'react'

import { DEFAULT_DEBOUNCE_DELAY } from 'consts/misc'

import { useGetPageSizeOptions } from './useGetPageSizeOptions'

export const SEARCH_INPUT_QUERY_PARAM = 'term'
export const PAGE_QUERY_PARAM = 'page'
export const PAGE_SIZE_QUERY_PARAM = 'pageSize'

export type SearchAndPaginationHookProps = {
  searchQueryParamName?: string
  triggerThreshold?: number
  delay?: number
  disableReplaceIn?: boolean
  shouldPreservePage?: boolean
}

export const useSearchAndPagination = ({
  searchQueryParamName = SEARCH_INPUT_QUERY_PARAM,
  triggerThreshold = 1,
  delay = DEFAULT_DEBOUNCE_DELAY,
  disableReplaceIn,
  shouldPreservePage,
}: SearchAndPaginationHookProps = {}) => {
  const [queryTerm, setQueryTerm] = useQueryParam<string | undefined>(
    searchQueryParamName,
  )

  const [inputValue, setInputValue] = useState(queryTerm)

  const [page, setPage] = useQueryParam(
    PAGE_QUERY_PARAM,
    withDefault(NumberParam, 1),
  )

  const pageSizeOptions = useGetPageSizeOptions()

  const [pageSize, setPageSize] = useQueryParam(
    PAGE_SIZE_QUERY_PARAM,
    withDefault(NumberParam, pageSizeOptions[0]),
  )

  const resetTerm = () => {
    setQueryTerm(undefined, 'replaceIn')
  }

  const resetPage = () => {
    setPage(undefined, 'replaceIn')
  }

  const isThresholdExceeded = Number(inputValue?.length) >= triggerThreshold
  const searchUrlUpdateType = disableReplaceIn ? undefined : 'replaceIn'

  useDebounce(
    () => {
      if (isThresholdExceeded) {
        if (inputValue !== queryTerm) {
          setQueryTerm(inputValue, searchUrlUpdateType)

          if (!shouldPreservePage && page !== 1) {
            setPage(1)
          }
        }
      } else if (!inputValue && !!queryTerm) {
        setQueryTerm(undefined, searchUrlUpdateType)
      }
    },
    delay,
    [inputValue],
  )

  // TODO: I don't get why we need it, but without it, it works in a weird way - state of the input is not always correct and in sync
  // Maybe to synchronize input state between hook calls? But the useState(queryTerm) should be enough for that
  // https://simplesystem.atlassian.net/browse/S2NG-4526
  useEffect(() => {
    setInputValue(queryTerm)
  }, [queryTerm])

  const onKeyDownToSupportSearchOnEnter = (
    e: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      if (!isThresholdExceeded) {
        setQueryTerm(inputValue, searchUrlUpdateType)
      }
    }
  }

  return {
    inputValue,
    setInputValue,
    onKeyDownToSupportSearchOnEnter,
    resetTerm,
    page,
    setPage,
    resetPage,
    pageSize: pageSize || undefined,
    setPageSize,
    pageSizeOptions,
    term: queryTerm,
  }
}
