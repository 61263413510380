import type { ImageWithFallbackProps } from 'app/components/ImageWithFallback/types'

export const ArticlePlaceholderIcon = ({
  size = 128,
  ...rest
}: Pick<ImageWithFallbackProps, 'alt' | 'title' | 'size' | 'onClick'>) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m68.667 76 58 9.333-34.667 24-22.667 1.334L34.667 100l34-24Z"
      fill="#D9DEED"
      fillOpacity={0.25}
    />
    <path d="m12 41.333 57.333 9.334v60L12 101.333v-60Z" fill="#ECF0F9" />
    <path d="m69.333 50.667 34.667-24v60l-34.667 24v-60Z" fill="#D9DEED" />
    <path d="m46 17.333 58 9.334-34.667 24L12 41.333l34-24Z" fill="#ECEFF9" />
    <path
      d="m86.667 38.667-17.334 12L12 41.333l17-12 57.667 9.334Z"
      fill="#F4F7FC"
    />
    <path
      d="m62.703 29.1 30.63 4.952-13.327 9.219-30.397-4.952 13.094-9.22Z"
      fill="#4D8BFF"
    />
    <path
      d="M80.005 63.75V43.276l13.334-9.222v20.832l-1.667-.914-1.667 3.13-1.666-.913-1.667 3.129-1.667-.913-1.666 3.129-1.667-.913-1.667 3.129Z"
      fill="#276EF1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.652 84.177c0-1.539 1.226-2.559 2.74-2.278 1.512.28 2.738 1.755 2.738 3.293 0 1.044-.467 1.43-1.678 2.313-1.182.863-2.887 2.131-2.887 5.198l3.652.677c0-1.344.578-1.858 1.678-2.661l.16-.116c1.083-.783 2.727-1.972 2.727-4.734 0-3.59-2.861-7.03-6.39-7.684C20.861 77.53 18 79.91 18 83.5v.929l3.652.676v-.928Zm2.74 14.436c1.134.21 2.053-.555 2.053-1.708 0-1.154-.92-2.26-2.054-2.47-1.134-.21-2.054.554-2.054 1.708s.92 2.26 2.054 2.47Z"
      fill="#4D8BFF"
    />
  </svg>
)
