import type { IconProps } from '../types'

export const ScannerIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6 3L5 3C3.89543 3 3 3.89543 3 5L3 6"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M3 14L3 15C3 16.1046 3.89543 17 5 17L6 17"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M14 17L15 17C16.1046 17 17 16.1046 17 15L17 14"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M17 6V5C17 3.89543 16.1046 3 15 3H14"
      stroke={color}
      strokeWidth="2"
    />
    <rect x="5" y="5" width="1" height="7" fill={color} />
    <rect x="5" y="13" width="1" height="2" fill={color} />
    <rect x="10" y="5" width="1" height="7" fill={color} />
    <rect x="10" y="13" width="1" height="2" fill={color} />
    <rect x="12" y="5" width="3" height="7" fill={color} />
    <rect x="12" y="13" width="3" height="2" fill={color} />
    <rect x="7" y="5" width="2" height="7" fill={color} />
    <rect x="7" y="13" width="2" height="2" fill={color} />
  </svg>
)
