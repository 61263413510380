import styled from '@emotion/styled'
import { Flex } from '@chakra-ui/react'

import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'

export const FooterButtonsWrapper = styled(Flex)(
  () =>
    ({
      padding: '20px',
      flexDirection: 'row',
      width: '100%',
      gap: '16px',
    }) as const,
)

export const FooterButton = styled((props: ButtonProps) => (
  <Button size="sm" isFullWidth {...props} />
))(() => ({}))
