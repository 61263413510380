import type { HeaderGroup } from 'react-table'

import { ColumnHeaderTypography } from '../../externalComponents/ColumnHeaderTypography'
import { HeaderWithChevron } from '../../externalComponents/HeaderWithChevron'

export const Header = <T extends object>({
  render,
  canSort,
  isSorted,
  isSortedDesc,
}: HeaderGroup<T>) => {
  const getChevronDirection = () => {
    if (isSortedDesc) return 'DOWN'

    if (isSorted) return 'UP'

    return undefined
  }

  if (!canSort) {
    return (
      <ColumnHeaderTypography
        tooltipProps={{ wrapperProps: { width: '100%' } }}
      >
        {render('Header')}
      </ColumnHeaderTypography>
    )
  }

  return (
    <HeaderWithChevron chevronDirection={getChevronDirection()}>
      {render('Header')}
    </HeaderWithChevron>
  )
}
