import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { useLanguageDependantDocuments } from 'app/hooks/useLanguageDependantDocuments'
import { Typography } from 'app/components/Typography'

import { FooterLink, FooterSeparator } from '../styles'

const messages = translations.footer

export const FooterLinks = () => {
  const { t } = useTranslation()

  const { PRIVACY_LINK, TERMS_AND_CONDITIONS_LINK, IMPRINT_LINK } =
    useLanguageDependantDocuments()

  return (
    <Flex lineHeight="20px">
      <FooterLink href={PRIVACY_LINK}>
        <Typography textStyle="textSmallLink">{t(messages.privacy)}</Typography>
      </FooterLink>

      <FooterSeparator>•</FooterSeparator>

      <FooterLink href={TERMS_AND_CONDITIONS_LINK}>
        <Typography textStyle="textSmallLink">{t(messages.terms)}</Typography>
      </FooterLink>

      <FooterSeparator>•</FooterSeparator>

      <FooterLink href={IMPRINT_LINK}>
        <Typography textStyle="textSmallLink">{t(messages.imprint)}</Typography>
      </FooterLink>
    </Flex>
  )
}
