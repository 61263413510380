/*
 * INFO: this is needed to handle the case when user is using old version of the frontend app
 * and we want to force reload, so that index.html is refetched and new FE app files are used (new app version)
 */

import { minutesToMilliseconds } from 'date-fns'
import { useEffect } from 'react'

import {
  getServerAppVersion,
  getInitServerAppVersion,
} from './getServerAppVersion'

const MIN_REFRESH_INTERVAL = minutesToMilliseconds(5)

let lastCheckTimestamp = Date.now()

const onVisibilityChange = async () => {
  const now = Date.now()
  const shouldSkip =
    document.hidden || now - lastCheckTimestamp < MIN_REFRESH_INTERVAL

  if (shouldSkip) return

  const serverAppVersion = await getServerAppVersion()
  const initServerAppVersion = getInitServerAppVersion()
  const shouldReload =
    initServerAppVersion &&
    serverAppVersion &&
    initServerAppVersion !== serverAppVersion

  if (shouldReload) {
    window.location.reload()
  }
  lastCheckTimestamp = now
}

export const useReloadIfAppVersionChanged = () => {
  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])
}
