import { Route, Switch } from 'react-router-dom'

import { PATHS } from 'consts/paths'
import { lazyLoad } from 'utils/loadable'

const EmailRedirect = lazyLoad(
  () => import('./EmailRedirect'),
  (module) => module.EmailRedirect,
)

export const EmailRedirectRoute = () => (
  <Switch>
    <Route path={PATHS.emailRedirect}>
      <EmailRedirect />
    </Route>
  </Switch>
)
