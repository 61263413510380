import { http, HttpResponse } from 'msw'

import { getVendorMockUrl } from 'app/containers/vendors/utils'

import { url } from './api'
import type { API__UpdateVendorCustomerRequestArgs } from './types'

export const updateVendorCustomerPatchMock = http.patch(
  getVendorMockUrl(url),
  () => new HttpResponse(null, { status: 204 }),
)

export const makeUpdateVendorCustomerPatchMockWithSpyOnVendorCustomerNumber = (
  spy: jest.Mock,
) =>
  http.patch(getVendorMockUrl(url), async ({ request }) => {
    const { vendorSpecificCustomerNumber } =
      (await request.json()) as API__UpdateVendorCustomerRequestArgs['body']

    spy(vendorSpecificCustomerNumber)

    return new HttpResponse(null, { status: 204 })
  })

export const makeUpdateVendorCustomerPatchMockWithSpy = (spy: jest.Mock) =>
  http.patch(getVendorMockUrl(url), async ({ request }) => {
    spy(await request.json())

    return new HttpResponse(null, { status: 204 })
  })
