import { useTranslation } from 'react-i18next'

import { assertNever } from 'types/utils'
import { translations } from 'locales/translations'
import { useTheme } from 'theme'

import { Tag } from 'app/components/tags'
import type { ArticlesListAccessLevel } from 'app/containers/customers/ArticleLists/types'
import { PermissionIcon } from 'app/components/icons/components/PermissionIcon'
import { MarkerTooltip } from 'app/components/tooltips'
import { Typography } from 'app/components/Typography'

import type { AccessLevelTagProps, AccessLevelTagWrapperProps } from './types'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.list.table
    .accessLevel

export const ACCESS_LEVEL_TAG_TEST_ID = 'accessLevelTag'

export const getAccessLevelTranslations = (
  accessLevel: ArticlesListAccessLevel,
) => {
  switch (accessLevel) {
    case 'ADMIN':
      return {
        label: messages.admin.label,
        tooltip: messages.admin.tooltip,
      }
    case 'WRITE':
      return {
        label: messages.write.label,
        tooltip: messages.write.tooltip,
      }
    case 'READ':
      return {
        label: messages.read.label,
        tooltip: messages.read.tooltip,
      }
    default:
      assertNever(accessLevel)
  }
}

const AccessLevelTagWrapper = ({
  accessLevel,
  withoutTooltip = false,
  children,
}: AccessLevelTagWrapperProps) => {
  const { t } = useTranslation()

  return withoutTooltip ? (
    <>{children}</>
  ) : (
    <MarkerTooltip content={t(getAccessLevelTranslations(accessLevel).tooltip)}>
      {children}
    </MarkerTooltip>
  )
}
export const AccessLevelTag = ({
  accessLevel,
  withoutTooltip = false,
  ...rest
}: AccessLevelTagProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  return (
    <AccessLevelTagWrapper
      accessLevel={accessLevel}
      withoutTooltip={withoutTooltip}
    >
      <Tag
        data-testid={ACCESS_LEVEL_TAG_TEST_ID}
        type="normal"
        gap="8px"
        style={{
          paddingLeft: '6px',
          transition: 'background .2s, color .2s',
        }}
        _hover={{
          background: colors.backgroundD,
        }}
        {...rest}
      >
        <PermissionIcon />
        <Typography textStyle="textSmallLink" transition="color .2s">
          {t(getAccessLevelTranslations(accessLevel).label)}
        </Typography>
      </Tag>
    </AccessLevelTagWrapper>
  )
}
