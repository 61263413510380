import type { IconProps } from '../types'

export const CartIcon = ({
  width = '16px',
  height = '16px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M14.8608 6.52751V2.36084H5.13917V6.52751H2.36084V13.4725C2.36084 15.7642 4.23584 17.6392 6.52751 17.6392H13.4725C15.7642 17.6392 17.6392 15.7642 17.6392 13.4725V6.52751H14.8608ZM7.22251 4.44417H12.7775V6.52751H7.22251V4.44417Z"
      fill={color}
    />
  </svg>
)
