import { http, HttpResponse, delay } from 'msw'

import { getDuration } from 'utils/testing/getDuration'

import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'
import { getVendorMockUrl } from 'app/containers/vendors/utils'

import { marketplaceUrl, catalogPreviewUrl } from '../consts'

import { makeData } from './data'
import { EXTERNAL_PRICES_DELAY } from './consts'

// customer marketplace mocks
export const marketplaceExternalPricesPostMock = http.post(
  getCustomerMockUrl(marketplaceUrl),
  async ({ request }) => {
    await delay(getDuration(EXTERNAL_PRICES_DELAY))

    return HttpResponse.json(makeData((await request.json()) as string[]))
  },
)

export const marketplaceExternalPricesPostMockWithForcedDelay = http.post(
  getCustomerMockUrl(marketplaceUrl),
  async ({ request }) => {
    await delay(EXTERNAL_PRICES_DELAY)

    return HttpResponse.json(makeData((await request.json()) as string[]))
  },
)

export const marketplaceExternalPricesPostEmptyMockWithForcedDelay = http.post(
  getCustomerMockUrl(marketplaceUrl),
  async () => {
    await delay(EXTERNAL_PRICES_DELAY)

    return HttpResponse.json([])
  },
)

// oci customer marketplace mocks
export const ociMarketplaceExternalPricesPostMock = http.post(
  getOciCustomerMockUrl(marketplaceUrl),
  async ({ request }) => {
    await delay(getDuration(EXTERNAL_PRICES_DELAY))

    return HttpResponse.json(makeData((await request.json()) as string[]))
  },
)

// vendor catalog preview mocks
export const catalogPreviewExternalPricesPostMock = http.post(
  getVendorMockUrl(catalogPreviewUrl),
  async ({ request }) => {
    await delay(getDuration(EXTERNAL_PRICES_DELAY))

    return HttpResponse.json(makeData((await request.json()) as string[]))
  },
)

export const catalogPreviewExternalPricesPostMockWithForcedDelay = http.post(
  getVendorMockUrl(catalogPreviewUrl),
  async ({ request }) => {
    await delay(EXTERNAL_PRICES_DELAY)

    return HttpResponse.json(makeData((await request.json()) as string[]))
  },
)

export const catalogPreviewExternalPricesPostEmptyMockWithForcedDelay =
  http.post(getVendorMockUrl(catalogPreviewUrl), async () => {
    await delay(EXTERNAL_PRICES_DELAY)

    return HttpResponse.json([])
  })
