import { useRef } from 'react'

import {
  useScanDetection,
  type UseScanDetectionProps,
} from './useScanDetection'

type UseScanDetectionWithIgnoreIfFocusOnProps = Omit<
  UseScanDetectionProps,
  'ignoreIfFocusOn'
>

export const useScanDetectionWithIgnoreIfFocusOnInputRef = (
  props: UseScanDetectionWithIgnoreIfFocusOnProps,
) => {
  const scannerInputRef = useRef<HTMLInputElement>(null)

  useScanDetection({
    ignoreIfFocusOn: scannerInputRef.current ?? undefined,
    ...props,
  })

  return {
    scannerInputRef,
  }
}
