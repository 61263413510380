import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

const PrintingContext = createContext<{ isPrintMode: boolean }>({
  isPrintMode: false,
})

export const PrintingContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const value = useMemo(() => ({ isPrintMode: true }), [])

  return (
    <PrintingContext.Provider value={value}>
      {children}
    </PrintingContext.Provider>
  )
}

export const usePrintingContext = () => useContext(PrintingContext)
