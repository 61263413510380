import { Flex, Skeleton } from '@chakra-ui/react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { FastOrderListItemWrapper } from '../styles'

export const FastOrderItemLoader = () => {
  const { isMobile } = useBreakpointScreenSize()

  if (isMobile) {
    return (
      <FastOrderListItemWrapper data-testid="fastOrderItemLoader">
        <Flex gap="16px" alignItems="center">
          <Skeleton width="20px" height="20px" borderRadius="6px" />
          <Skeleton width="40px" height="40px" borderRadius="6px" />
          <Flex direction="column" gap="8px">
            <Skeleton width="120px" height="12px" borderRadius="4px" />
            <Skeleton width="234px" height="16px" borderRadius="4px" />
            <Skeleton width="120px" height="12px" borderRadius="4px" />
          </Flex>
        </Flex>
        <Flex gap="8px" justifyContent="space-between">
          <Skeleton width="104px" height="36px" borderRadius="6px" />
          <Flex gap="8px">
            <Skeleton width="36px" height="36px" borderRadius="6px" />
            <Skeleton width="36px" height="36px" borderRadius="6px" />
          </Flex>
        </Flex>
      </FastOrderListItemWrapper>
    )
  }

  return (
    <FastOrderListItemWrapper data-testid="fastOrderItemLoader">
      <Skeleton width="20px" height="20px" borderRadius="6px" />
      <Skeleton width="40px" height="40px" borderRadius="6px" />
      <Flex direction="column" gap="8px" flex={1}>
        <Skeleton width="120px" height="12px" borderRadius="4px" />
        <Skeleton width="443px" height="16px" borderRadius="4px" />
        <Skeleton width="320px" height="12px" borderRadius="4px" />
      </Flex>
      <Flex gap="8px" justifyContent="flex-end">
        <Skeleton width="95px" height="36px" borderRadius="6px" />
        <Skeleton width="36px" height="36px" borderRadius="6px" />
        <Skeleton width="36px" height="36px" borderRadius="6px" />
      </Flex>
    </FastOrderListItemWrapper>
  )
}
