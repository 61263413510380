import {
  de as deDateLocale,
  enUS as enDateLocale,
  fr as frDateLocale,
  it as itDateLocale,
  cs as csDateLocale,
} from 'date-fns/locale'

import type { AvailableLanguages } from './types'

export const AVAILABLE_LANGUAGES = {
  de: 'de',
  en: 'en',
  fr: 'fr',
  it: 'it',
  cs: 'cs',
  cimode: 'cimode',
} as const

export const AVAILABLE_LANGUAGES_LIST: AvailableLanguages[] =
  Object.values(AVAILABLE_LANGUAGES)

export const LANGUAGE_DETECTOR_KEYS = {
  navigator: 'CUSTOM_NAVIGATOR_DETECTOR_KEY',
  fallback: 'FALLBACK_DETECTOR_KEY',
}

export const DATE_LOCALES_MAP = {
  [AVAILABLE_LANGUAGES.de]: deDateLocale,
  [AVAILABLE_LANGUAGES.en]: enDateLocale,
  [AVAILABLE_LANGUAGES.fr]: frDateLocale,
  [AVAILABLE_LANGUAGES.it]: itDateLocale,
  [AVAILABLE_LANGUAGES.cs]: csDateLocale,
  [AVAILABLE_LANGUAGES.cimode]: enDateLocale,
} as const
