import { useLocation } from 'react-router-dom'

import type { MODEL__CatalogCategory } from 'app/containers/vendors/common/Catalogs/List'

export type CatalogPreviewLocationState = {
  catalogId: string | undefined
  name: string | undefined
  currency: string | undefined
  activeTab: MODEL__CatalogCategory | undefined
}

export const useCatalog = () => {
  const location = useLocation<CatalogPreviewLocationState>()

  return {
    catalogId: location.state?.catalogId,
    catalogName: location.state?.name,
    catalogCurrency: location.state?.currency,
    activeTab: location.state?.activeTab,
  }
}
