import { Flex } from '@chakra-ui/react'

import { useArticleDisplayMode } from '../ArticleDisplayModeProvider'
import { ArticleEanText, ArticleNumberText } from '../ArticleTexts'

import {
  ArticleContentHeader,
  GridViewArticleDetails,
  ListViewArticleDetails,
} from './components'
import type { ArticleContentProps } from './types'

export const ArticleContent = ({ article, vendor }: ArticleContentProps) => {
  const { isListView } = useArticleDisplayMode()

  return (
    <Flex flex="3" flexDirection="column" justifyContent="space-between">
      <Flex gap={isListView ? '20px' : '16px'} direction="column">
        <ArticleContentHeader
          isListView={isListView}
          article={article}
          vendor={vendor}
        />

        <Flex gap="16px" direction="row">
          <Flex flex={1} flexDirection="column" gap="4px">
            <ArticleNumberText number={article.number} />
            <ArticleEanText ean={article.ean} />
          </Flex>
          {isListView && <ListViewArticleDetails article={article} />}
        </Flex>
      </Flex>

      {!isListView && <GridViewArticleDetails article={article} />}
    </Flex>
  )
}
