import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { showGenericErrorModal } from 'app/App/globalModals/GenericErrorModal'
import { ARTICLES_SEARCH_QUERY_KEY } from 'app/containers/common/Search'

import { CART_ITEMS_LIGHT_QUERY_KEY } from './Items/List'
import { CART_QUERY_KEY } from './Show'
import { CURRENT_CART_EXCEEEDED_BUDGETS_QUERY_KEY } from './ExceededBudgets'

export const useCartMutationOptions = () => {
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [CART_ITEMS_LIGHT_QUERY_KEY] }) // INFO: used for articles input's quantities and CartButton
    queryClient.invalidateQueries({ queryKey: [CART_QUERY_KEY] }) // INFO: used only in the Checkout/Cart for showing cart articles and prices etc
    queryClient.invalidateQueries({
      queryKey: [CURRENT_CART_EXCEEEDED_BUDGETS_QUERY_KEY],
    })
  }

  const onError = (error: unknown) => {
    if (!axios.isAxiosError(error)) return showGenericErrorModal()

    showGenericErrorModal({ error })
    queryClient.invalidateQueries({ queryKey: [CART_QUERY_KEY] })

    if (error.response?.status === 400) {
      queryClient.invalidateQueries({ queryKey: [CART_ITEMS_LIGHT_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [ARTICLES_SEARCH_QUERY_KEY] })
    }
  }

  return { onSuccess, onError }
}
