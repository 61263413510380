import { Flex, type FlexProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const GenericArticleContentWrapper = styled(
  ({ isDisabled, ...props }: FlexProps & { isDisabled?: boolean }) => (
    <Flex {...props} />
  ),
)(({ isDisabled }) => ({
  gap: '16px',
  alignItems: 'center',
  width: '100%',
  ...(isDisabled && {
    opacity: 0.3,
    pointerEvents: 'none',
  }),
}))
