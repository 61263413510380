import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const ArticleActionsWrapper = styled(Flex)(
  ({ theme: { mediaQuery } }) => ({
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,

    [mediaQuery.small]: {
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'initial',
    },
  }),
)
