import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'
import type { ModelAddItemToFastOrderListPayload } from 'libs/openapiGeneratedApi/Api'
import type { SuppressGlobalOnErrorProp } from 'queryClient/types'

import { getFastOrderListQueryKey } from '../List'
import type { UseFastOrderHookArgs } from '../types'
import { getFastOrderListCountQueryKey } from '../Count'

type UseAddItemToFastOrderListMutationProps = UseFastOrderHookArgs &
  SuppressGlobalOnErrorProp &
  Pick<
    UseMutationOptions<any, Error, ModelAddItemToFastOrderListPayload>,
    'onSuccess'
  >

export const useAddItemToFastOrderListMutation = ({
  fastOrderListEnum,
  suppressGlobalOnError,
  onSuccess,
}: UseAddItemToFastOrderListMutationProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ModelAddItemToFastOrderListPayload) =>
      generatedApi.customers.addItemToFastOrderList.request({
        fastOrderListEnum,
        data: params,
      }),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getFastOrderListQueryKey({ fastOrderListEnum }),
      })

      queryClient.invalidateQueries({
        queryKey: getFastOrderListCountQueryKey({ fastOrderListEnum }),
      })

      if (onSuccess) onSuccess(...args)
    },
    meta: { suppressGlobalOnError },
  })
}
