import type { IconProps } from '../types'

export const PencilIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M4 17H16" stroke={color} strokeWidth="2" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 15L4.93282 11.2687C4.97678 11.0929 5.0677 10.9323 5.19586 10.8041L11 4.99998L14 7.99998L8.19586 13.8041C8.0677 13.9323 7.90712 14.0232 7.73129 14.0672L4 15ZM15 6.99998L15.5 6.49998C16.3284 5.67156 16.3284 4.32841 15.5 3.49998C14.6716 2.67156 13.3284 2.67155 12.5 3.49998L12 3.99998L15 6.99998Z"
      fill={color}
    />
  </svg>
)
