import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from 'apiServices'

import { articleListsScope } from '../consts'
import { ARTICLE_LISTS_QUERY_KEY } from '../List'

import type {
  API__CreateArticleListRequestParams,
  API__CreateArticleListRequestResponse,
} from './types'

export const url = articleListsScope

const createArticleListRequest = (data: API__CreateArticleListRequestParams) =>
  api.post<unknown, API__CreateArticleListRequestResponse>(url, data)

export const useCreateArticleListMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createArticleListRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ARTICLE_LISTS_QUERY_KEY] })
    },
  })
}
