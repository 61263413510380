import { useField } from 'formik'

import { BareCheckbox } from './BareCheckbox'
import type { MultiCheckboxProps } from './types'

export const MultiCheckbox = ({
  name,
  label,
  value: checkboxValue,
  dataTestId,
  dataCy,
  customAriaLabel,
  ...rest
}: MultiCheckboxProps) => {
  const [{ value: allValues = [] }, , { setValue, setTouched }] = useField<
    string[] | undefined
  >({ name })

  const checked = allValues.includes(checkboxValue)

  return (
    <BareCheckbox
      isVerticallyCentered
      customAriaLabel={customAriaLabel || name}
      isChecked={checked}
      onChange={() => {
        setTouched(true)

        if (checked) {
          const nextValue = allValues.filter((value) => value !== checkboxValue)
          setValue(nextValue)
        } else {
          const nextValue = [...allValues, checkboxValue]
          setValue(nextValue)
        }
      }}
      dataTestId={dataTestId}
      {...rest}
    >
      {label}
    </BareCheckbox>
  )
}
