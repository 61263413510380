import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { FastOrderProvider } from '../common/fastOrder/contexts/fastOrderContext'

import { MobileScanner, DesktopScanner } from './components'
import { MobileScannerProvider } from './components/MobileScanner/context'

const ScannerComponent = () => {
  const { isMobile, isTablet } = useBreakpointScreenSize()

  if (isMobile || isTablet) {
    return (
      <MobileScannerProvider>
        <MobileScanner />
      </MobileScannerProvider>
    )
  }

  return <DesktopScanner />
}

export const Scanner = () => (
  <FastOrderProvider fastOrderListEnum="SCANNING_LIST">
    <ScannerComponent />
  </FastOrderProvider>
)
