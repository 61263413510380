import { SmallCloseIcon } from '@chakra-ui/icons'
import { Flex, ModalHeader } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useTheme } from 'theme'

import { Button } from 'app/components/Buttons/Button'
import { Typography } from 'app/components/Typography'

export const StyledModalHeader = styled(ModalHeader)(
  ({ theme: { zIndexes } }) => ({
    padding: '16px',
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: zIndexes.aboveModal,
    width: '100%',
  }),
)

type HeaderProps = {
  header: string
  isScannerInitialized: boolean
  videoPermission: boolean
  handleMobileScannerClose: () => void
}

export const Header = ({
  header,
  isScannerInitialized,
  videoPermission,
  handleMobileScannerClose,
}: HeaderProps) => {
  const { colors } = useTheme()

  const isWhiteColorHeader = isScannerInitialized || !videoPermission

  return (
    <StyledModalHeader>
      <Flex justify="space-between" align="center" gap="8px">
        <Typography
          textStyle="headerH3"
          {...(isWhiteColorHeader && { color: 'white' })}
        >
          {header}
        </Typography>

        <Button
          onClick={handleMobileScannerClose}
          variant="tertiary"
          padding="8px"
          leftIcon={
            <SmallCloseIcon
              {...(isWhiteColorHeader && { color: colors.white })}
              boxSize="20px"
            />
          }
          mobileIconOnly
        />
      </Flex>
    </StyledModalHeader>
  )
}
