import { useSelector } from 'react-redux'

import { baseIdentitySelector } from 'app/containers/common/Auth/selectors'
import type { MODEL__User } from 'app/models/user'
import type { AuthState } from 'app/containers/common/Auth'
import { useOciSession } from 'app/containers/common/Auth'

import { createBaseCompanyIdentity } from './createBaseCompanyIdentity'
import { findMatchingCompany } from './findMatchingCompany'

type Identity = Pick<AuthState, 'selectedCompanyId' | 'userRole'>

export const useGetIdentity = () => {
  const { isOciSessionFlow } = useOciSession()
  const { isUserImpersonated, impersonation, userRole } =
    useSelector(baseIdentitySelector)

  return (userData: MODEL__User): Identity | undefined => {
    const impersonationCompanyData =
      isUserImpersonated &&
      findMatchingCompany(userData, impersonation?.companyId)

    if (impersonationCompanyData) {
      return createBaseCompanyIdentity(impersonationCompanyData)
    }

    const isMultiAccount =
      userData.customers.length + userData.vendors.length > 1
    const isAccountSelectionNeeded =
      isMultiAccount && (userRole === null || userRole === 'noIdentitySelected')

    if (isAccountSelectionNeeded) {
      return { selectedCompanyId: null, userRole: 'noIdentitySelected' }
    }

    // INFO: It's here to keep the identity of the picked user, if user has more than one account attached
    if (userRole === 'customer' || userRole === 'vendor') {
      return
    }

    const firstVendorEntity = userData?.vendors[0]

    if (firstVendorEntity) {
      return createBaseCompanyIdentity({
        company: firstVendorEntity,
        userRole: 'vendor',
      })
    }

    const firstCustomerEntity = userData?.customers[0]

    if (firstCustomerEntity) {
      return createBaseCompanyIdentity({
        company: firstCustomerEntity,
        userRole: isOciSessionFlow ? 'ociCustomer' : 'customer',
      })
    }
  }
}
