import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useSearchAndPagination } from 'app/hooks/useSearchAndPagination'

import { useArticleListId } from '../../useArticleListId'

export const ARTICLE_LIST_ITEMS_QUERY_KEY = 'ARTICLE_LIST_ITEMS_QUERY_KEY'

export const generateArticleListItemsQueryKey = <
  T extends { articleListId: string },
>({
  articleListId,
  ...rest
}: T) => [ARTICLE_LIST_ITEMS_QUERY_KEY, articleListId, rest]

export const useArticleListItemsQuery = ({
  paginated = true,
}: {
  paginated?: boolean
} = {}) => {
  const articleListId = useArticleListId()
  const { page, pageSize, term } = useSearchAndPagination()

  const pathAndQueryParams = { articleListId, page, pageSize, paginated }

  return useQuery({
    queryKey: generateArticleListItemsQueryKey({ ...pathAndQueryParams, term }),
    queryFn: () =>
      generatedApi.customers.searchArticleListItems.request({
        pathAndQueryParams,
        data: { term },
      }),
    enabled: !!articleListId,
    staleTime: 1000 * 3,
    placeholderData: keepPreviousData,
  })
}
