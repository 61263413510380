import type { ReactNode } from 'react'
import React, { createContext, useContext } from 'react'

import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'

import type { ProcessedFastOrderItemStatus } from '../types'

export type FastOrderListItemContextType = {
  fastOrderListItem: ModelGetFastOrderListData['items'][number]
  status: ProcessedFastOrderItemStatus
}

export const FastOrderListItemContext = createContext<
  FastOrderListItemContextType | undefined
>(undefined)

interface FastOrderListItemProviderProps {
  children: ReactNode
  value: FastOrderListItemContextType
}

export const FastOrderListItemContextProvider: React.FC<
  FastOrderListItemProviderProps
> = ({ children, value }) => (
  <FastOrderListItemContext.Provider value={value}>
    {children}
  </FastOrderListItemContext.Provider>
)

// Create a custom hook to consume the context
export const useFastOrderListItemContext = () => {
  const context = useContext(FastOrderListItemContext)

  if (!context) {
    throw new Error(
      'useFastOrderListItemContext must be used within a FastOrderListItemContextProvider',
    )
  }

  return context
}
