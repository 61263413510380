import { http, HttpResponse } from 'msw'

import type { ModelGetBarcodeArticleListItemsData } from 'libs/openapiGeneratedApi/Api'
import { generatedApi } from 'apiServices'

import { getCustomerMockUrl } from '../../../../utils'

import { barcodeArticleListDetailsItemsMockData } from './data'

export const makeBarcodeArticleListItemsMock = ({
  data,
  spyFn,
}: {
  data?: Partial<ModelGetBarcodeArticleListItemsData>
  spyFn?: jest.Mock
} = {}) =>
  http.get(
    getCustomerMockUrl(generatedApi.customers.getBarcodeArticleListItems.url),
    async ({ request }) => {
      if (spyFn) spyFn(await request.json())

      return HttpResponse.json({
        ...barcodeArticleListDetailsItemsMockData,
        ...data,
      })
    },
  )

export const barcodeArticleListItemsMock = makeBarcodeArticleListItemsMock()
