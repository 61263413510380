import { useRouteMatch, useHistory, generatePath } from 'react-router-dom'
import axios from 'axios'
import { useRef, useEffect } from 'react'

import { PATHS } from 'consts/paths'

import { useProcessApprovalLink } from 'app/containers/customers/ApprovalFlows/GeneratedLink/Process'
import type { MODEL__ApprovalLink } from 'app/containers/customers/ApprovalFlows/GeneratedLink/types'
import { useLinkId } from 'app/containers/customers/ApprovalFlows/GeneratedLink/useLinkId'
import type { ApprovalSuccessLocationState } from 'app/pages/customers/approveOrderFromLinkFlow/Success/types'

export const useIsOnInitialPage = () => {
  const isOnInitialPage = !!useRouteMatch({
    path: PATHS.approveOrder.root,
    exact: true,
  })

  return { isOnInitialPage }
}

export const useProcessApprovalLinkWithRedirects = () => {
  const isInitialized = useRef(false)
  const history = useHistory()
  const { isOnInitialPage } = useIsOnInitialPage()
  const { linkId } = useLinkId()

  const onApprovalSuccess = ({ shopper }: MODEL__ApprovalLink) => {
    const locationState: ApprovalSuccessLocationState = { shopper }
    history.replace(
      generatePath(PATHS.approveOrder.success, { linkId }),
      locationState,
    )
  }

  const onApprovalError = (error: unknown) => {
    if (!axios.isAxiosError(error) || !error.response) {
      return history.replace(PATHS.root)
    }

    switch (error.response.status) {
      case 404:
        return history.replace(
          generatePath(PATHS.approveOrder.expired, { linkId }),
        )
      case 409:
        return history.replace(
          generatePath(PATHS.approveOrder.alreadyApproved, { linkId }),
        )
      case 439:
        return history.replace(
          generatePath(PATHS.approveOrder.alreadyRejected, { linkId }),
        )

      default:
        throw new Error('unexpected error code')
    }
  }

  const { mutate: processApproval } = useProcessApprovalLink({
    onError: onApprovalError,
    onSuccess: onApprovalSuccess,
  })

  useEffect(() => {
    if (isInitialized.current || !isOnInitialPage || !linkId) return

    // INFO: We need it to not make 2 requests in the strict mode in local development
    isInitialized.current = true

    processApproval({ status: 'APPROVED' })
  }, [isOnInitialPage, linkId, history, processApproval])
}
