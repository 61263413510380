import { useRouteMatch } from 'react-router-dom'

import { PATHS } from 'consts/paths'

export const useIsApproveOrderFromLinkFlow = () => {
  const isApproveOrderFromLinkFlow = !!useRouteMatch({
    path: PATHS.approveOrder.root,
    exact: false,
  })

  return {
    isApproveOrderFromLinkFlow,
  }
}
