import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'

import { translations } from 'locales/translations'

import { ErrorPageLayout } from 'app/components/layout/ErrorPageLayout'

interface Props {
  clearBoundaryError: () => void
}

const messages = translations.pages.feCrashError

export const ErrorPage = ({ clearBoundaryError }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  // INFO: we want to try to show proper UI when user e.g. uses browser back button or "go to homepage" button
  useUpdateEffect(() => {
    clearBoundaryError()
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorPageLayout
      isWithHomepageButton
      isWithRefreshPageButton
      isWithMainSectionIcon={false}
      headerLarge={t(messages.labelMText)}
      header={t(messages.header)}
      description={t(messages.description)}
    />
  )
}
