import type { ReactNode } from 'react'

import type { IconProps } from '../icons/types'

import { FullPageLoader } from './FullPageLoader'
import { useDelayedLoader } from './useDelayedLoading'

type WithLoaderWrapperProps = IconProps & {
  children: ReactNode
  isLoading?: boolean
  shouldUseDelayedLoader?: boolean
}

export const WithLoaderWrapper = ({
  children,
  isLoading,
  shouldUseDelayedLoader,
  ...props
}: WithLoaderWrapperProps) => {
  const { isLoaderDisplayed } = useDelayedLoader({
    isLoading,
    shouldUseDelayedLoader,
  })

  return (
    <>
      {isLoaderDisplayed && <FullPageLoader {...props} />}
      {children}
    </>
  )
}
