import { Box, Flex, Divider } from '@chakra-ui/react'

import { SimpleSystemLogoIcon, WomanOnBoxesIcon } from 'app/components/icons'
import { ContentWrapper } from 'app/components/layout/ContentWrapper'
import { SectionHeader } from 'app/components/SectionHeader'
import { FooterCopyright } from 'app/components/layout/Footer'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'

import type { ErrorPageLayoutProps } from './types'
import {
  AbsoluteBoxesIcon,
  FooterWrapper,
  HeaderLarge,
  LabelM,
  LayoutWrapper,
} from './styles'
import { HomePageButton, RefreshPageButton } from './components'

export const ErrorPageLayout = ({
  isWithHomepageButton,
  isWithRefreshPageButton,
  isWithMainSectionIcon = true,
  labelM,
  headerLarge,
  headerTestId,
  header,
  description,
  footerRendered,
}: ErrorPageLayoutProps) => (
  <LayoutWrapper>
    <ContentWrapper paddingY="64px">
      <ColumnWithGap gap={20}>
        <ColumnWithGap gap={64}>
          <SimpleSystemLogoIcon />

          <Flex align="flex-end" justify="space-between">
            {labelM && <LabelM>{labelM}</LabelM>}

            {headerLarge && <HeaderLarge>{headerLarge}</HeaderLarge>}

            {isWithMainSectionIcon && <WomanOnBoxesIcon />}
          </Flex>
        </ColumnWithGap>

        <SectionHeader
          headerTestId={headerTestId}
          header={header}
          description={description}
        />

        <Divider />

        {footerRendered ?? (
          <FooterWrapper>
            {isWithHomepageButton && <HomePageButton />}

            {isWithRefreshPageButton && <RefreshPageButton />}
          </FooterWrapper>
        )}
      </ColumnWithGap>
    </ContentWrapper>

    <Box p="24px 48px">
      <FooterCopyright />

      {!isWithMainSectionIcon && <AbsoluteBoxesIcon />}
    </Box>
  </LayoutWrapper>
)
