import { Button, type ButtonProps } from 'app/components/Buttons/Button'
import { TrashIcon } from 'app/components/icons/components/TrashIcon'

export const DeleteArticleButton = (props: ButtonProps) => (
  <Button
    size="squareSm"
    variant="secondary"
    dataTestId="deleteItemButton"
    {...props}
  >
    <TrashIcon />
  </Button>
)
