import NiceModal from '@ebay/nice-modal-react'

import { buildConfirmModalId } from 'app/components/modals/ConfirmActionModal'

import type { EditArticleModalProps } from './EditArticleModal/types'

export const EDIT_ARTICLE_MODAL_ID = 'EDIT_ARTICLE_MODAL_ID'
export const showEditArticleModal = (props: EditArticleModalProps) => {
  NiceModal.show(EDIT_ARTICLE_MODAL_ID, props)
}
export const hideEditArticleModal = () => {
  NiceModal.hide(EDIT_ARTICLE_MODAL_ID)
}

// ExternalReferencesFormModal
export type ExternalReferencesFormModalProps = {
  articleId: string
  externalReference?: {
    id: string
    barcode: string
    quantity: number
  }
}
export const EXTERNAL_REFERENCES_FORM_MODAL_ID =
  'EXTERNAL_REFERENCES_FORM_MODAL_ID'
export const showExternalReferencesFormModal = (
  props: ExternalReferencesFormModalProps,
) => NiceModal.show(EXTERNAL_REFERENCES_FORM_MODAL_ID, props)
export const hideExternalReferencesFormModal = () =>
  NiceModal.remove(EXTERNAL_REFERENCES_FORM_MODAL_ID)

// ConfirmDeleteExternalReferenceModal
export type ConfirmDeleteExternalReferenceModalProps = {
  articleId: string
  externalReference: {
    id: string
    barcode: string
    quantity: number
  }
}
export const CONFIRM_DELETE_EXTERNAL_REFERENCE_MODAL_ID =
  'CONFIRM_DELETE_EXTERNAL_REFERENCE_MODAL_ID'
export const showConfirmDeleteExternalReferenceModal = (
  props: ConfirmDeleteExternalReferenceModalProps,
) =>
  NiceModal.show(
    buildConfirmModalId(CONFIRM_DELETE_EXTERNAL_REFERENCE_MODAL_ID),
    props,
  )
