import type { IconProps } from '../types'

export const MailOpenIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M1 17V8L10 15L19 8V17C19 17.5523 18.5523 18 18 18H2C1.44772 18 1 17.5523 1 17Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00004 8.83333L1.34619 6.76923L4.00004 5V8.83333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8.83333L18.6538 6.76923L16 5V8.83333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H14C14.5523 2 15 2.44772 15 3V9.61111L10 13.5L5 9.61111V3C5 2.44772 5.44772 2 6 2ZM13 4H7V5H13V4ZM7 6H11V7H7V6ZM12 8H7V9H12V8Z"
      fill={color}
    />
  </svg>
)
