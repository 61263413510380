import { format } from 'date-fns'

import { DATE_FORMATS } from 'consts/dateFormats'
import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'
import { colors } from 'theme/colors'

import { useGetTimeSince } from 'app/hooks/date'
import { Typography } from 'app/components/Typography'

import { FastOrderListDateWrapper, FastOrderListWrapper } from './styles'
import { FastOrderListItem } from './components'

type GroupedByDateFastOrderListProps = {
  queryDateShort: string
  fastOrderItems: ModelGetFastOrderListData['items']
}

export const GroupedByDateFastOrderList = ({
  queryDateShort,
  fastOrderItems,
}: GroupedByDateFastOrderListProps) => {
  const getTimeSince = useGetTimeSince()

  const lastAddedAt = fastOrderItems[0].lastAddedAt

  const currentDateShort = format(Date.now(), DATE_FORMATS.displayShort)

  const shouldRenderDate = currentDateShort !== queryDateShort

  return (
    <FastOrderListWrapper data-testid="groupedFastOrderList">
      {shouldRenderDate && (
        <FastOrderListDateWrapper data-testid="queryDate">
          <Typography>
            {getTimeSince(lastAddedAt)}{' '}
            <span style={{ color: colors.contentB }}>({queryDateShort})</span>
          </Typography>
        </FastOrderListDateWrapper>
      )}

      {fastOrderItems.map((item) => (
        <FastOrderListItem key={item.id} item={item} />
      ))}
    </FastOrderListWrapper>
  )
}
