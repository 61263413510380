import { http, HttpResponse } from 'msw'

import type { ModelSearchArticleListItemsData } from 'libs/openapiGeneratedApi/Api'
import { generatedApi } from 'apiServices'

import type { VendorId } from 'app/models/user'
import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'

import {
  articleDataMockData,
  cartResponseMockData,
} from '../../../CurrentCart/Show'

import { makeArticleMockData } from './makeArticleMockData'
import type { ModelSearchArticleListItems } from './types'

export const articleListDetailsItemsMockData: ModelSearchArticleListItems = [
  makeArticleMockData({
    baseArticleData: {
      ...articleDataMockData,
      vendor: {
        id: 'X12345' as VendorId,
        name: 'Great Group',
      },
    },
    available: false,
    articleData: { name: 'Fake Name' },
  }),
  makeArticleMockData({
    baseArticleData: articleDataMockData,
    articleData: {
      id: 'restricted',
      name: 'Restricted name',
      orderOptions: {
        restricted: true,
        priceQuantity: '3',
        pricing: [
          { price: { amount: 1234, currency: 'GBP' }, quantity: '0.01' },
        ],
      },
    },
    available: false,
  }),
  makeArticleMockData({ baseArticleData: articleDataMockData }),
  makeArticleMockData({
    baseArticleData: cartResponseMockData.items[1].items[0].articleData,
  }),
]

export const articleListItemsMockData: ModelSearchArticleListItemsData = {
  items: articleListDetailsItemsMockData,
  metadata: {
    page: 1,
    pageCount: 10,
    pageSize: 24,
  },
}

export const makeArticleListItemsMock = ({
  data,
  spyFn,
}: {
  data?: Partial<ModelSearchArticleListItemsData>
  spyFn?: jest.Mock
} = {}) =>
  http.post(
    getCustomerMockUrl(generatedApi.customers.searchArticleListItems.url),
    async ({ request }) => {
      if (spyFn) spyFn(await request.json())

      return HttpResponse.json({ ...articleListItemsMockData, ...data })
    },
  )

export const articleListItemsMock = makeArticleListItemsMock()

export const ociCustomerArticleSearchMock = http.post(
  getOciCustomerMockUrl(
    generatedApi.customerOciSession.customerArticleListsSearchOci.url,
  ),
  async () => HttpResponse.json(articleListItemsMockData),
)
