import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'

import {
  ArticleImageWithOptionalRedirect,
  GenericArticleContentHeader,
  GenericArticleContentWrapper,
} from 'app/pages/customers/common/articles/GenericArticleContent'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'

import { ArticleContentDescriptionLine } from './components'

type ArticleContentProps = {
  articleData: ModelGetFastOrderListData['items'][number]['foundArticles'][number]
  isDisabled?: boolean
  isWithoutLinks?: boolean
}

export const ArticleContent = ({
  articleData,
  isDisabled,
  isWithoutLinks,
}: ArticleContentProps) => (
  <GenericArticleContentWrapper width="100%" isDisabled={isDisabled}>
    <ArticleImageWithOptionalRedirect
      articleData={articleData}
      isWithoutLinks={isWithoutLinks}
    />

    <ColumnWithGap gap={4}>
      <GenericArticleContentHeader
        articleData={articleData}
        isWithoutLinks={isWithoutLinks}
      />

      <ArticleContentDescriptionLine articleData={articleData} />
    </ColumnWithGap>
  </GenericArticleContentWrapper>
)
