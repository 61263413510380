import { ChevronUpIcon } from '@chakra-ui/icons'

import { Button } from 'app/components/Buttons/Button'

import { useMobileScannerContext } from '../../../context'

export const DrawerButton = () => {
  const { isDrawerOpen } = useMobileScannerContext()

  return (
    <Button variant="tertiary" size="squareSm" dataTestId="drawerButton">
      <ChevronUpIcon
        boxSize="20px"
        transform={isDrawerOpen ? 'rotate(-180deg)' : undefined}
        transition="transform 0.2s"
        color="contentC"
      />
    </Button>
  )
}
