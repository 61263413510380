/**
 * Asynchronously loads the component for Error404 page
 */

import { lazyLoad } from 'utils/loadable'

export const Error404 = lazyLoad(
  () => import('./Error404'),
  (module) => module.Error404,
)
