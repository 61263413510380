import type { PayloadAction } from '@reduxjs/toolkit'
import type { Selector } from 'reselect'
import { createSelector } from 'reselect'

import { createSlice } from 'utils/@reduxjs/toolkit'
import type { RootState } from 'types'

export const FILE_UPLOAD_REDUCER_NAME = 'fileUpload'

// INFO: Would be nice to implement it as a state machine at some point
// to prevent from having incorrect states, especially
// when we also have to cover the errors
export type FileUploadState =
  | {
      id: null
      isInProgress: false
      hasFinished: false
    }
  | {
      id: string
      isInProgress: true
      hasFinished: false
    }
  | {
      id: string
      isInProgress: false
      hasFinished: true
    }

export const initialState: FileUploadState = {
  id: null,
  isInProgress: false,
  hasFinished: false,
}

export const fileUploadSlice = createSlice({
  name: FILE_UPLOAD_REDUCER_NAME,
  initialState: initialState as FileUploadState,
  reducers: {
    startUpload: (state, action: PayloadAction<{ id: string }>) => {
      state.id = action.payload.id
      state.isInProgress = true
      state.hasFinished = false
    },

    finishUpload: (state) => {
      state.isInProgress = false
      state.hasFinished = true
    },
    clearUpload: () => initialState,
  },
})

const selectFileUploadData: Selector<RootState, FileUploadState> = (state) =>
  state[FILE_UPLOAD_REDUCER_NAME]

export const isFileUploadInProgressSelector = createSelector(
  selectFileUploadData,
  (data) => data.isInProgress,
)

export const hasFileUploadFinishedSelector = createSelector(
  selectFileUploadData,
  (data) => data.hasFinished,
)

export const { startUpload, finishUpload, clearUpload } =
  fileUploadSlice.actions
export const { reducer: fileUploadReducer } = fileUploadSlice
