import type { OverrideProperties } from 'type-fest'

import { getCountryLabel } from 'app/utils/getCountryLabel'
import type { MODEL__Address } from 'app/models/address'

type Address = Partial<
  OverrideProperties<MODEL__Address, { countryCode: string }>
> | null

export const formatStreetNameNumber = ({
  countryCode,
  streetName,
  streetNumber,
}: NonNullable<Address>) =>
  countryCode === 'FR'
    ? `${streetNumber} ${streetName}`
    : `${streetName} ${streetNumber}`

export const formatAddress = (address?: Address) => {
  if (!address || !address.city) return ''

  const { postalCode, city, countryCode } = address

  const streetNameNumber = formatStreetNameNumber(address)

  return [
    `${streetNameNumber}`,
    `${postalCode} ${city}`,
    getCountryLabel(countryCode),
  ].join(', ')
}

type DisplayCompanyAddressTwoLinesProps = {
  address: Address
}

export const DisplayCompanyAddressTwoLines = ({
  address,
}: DisplayCompanyAddressTwoLinesProps) => {
  if (!address || !address.city) return null

  const { postalCode, city, countryCode } = address

  const streetNameNumber = formatStreetNameNumber(address)

  return (
    <>
      {streetNameNumber}, {postalCode}
      <br />
      {city}, {getCountryLabel(countryCode)}
    </>
  )
}
