import { useCallback, useEffect, useRef, useState } from 'react'
import type { Html5Qrcode } from 'html5-qrcode'
import { Html5QrcodeScannerState } from 'html5-qrcode'

import type { MobileScannerProps } from '../types'

import { useGetVideoPermission } from './useGetVideoPermission'
import { useGetStartScanning } from './useGetStartScanning'
import { useGetOtherScanningCallbacks } from './useGetOtherScanningCallbacks'

export const useCodeScan = ({
  onScanSuccessHandler,
}: Pick<MobileScannerProps, 'onScanSuccessHandler'>) => {
  const html5QrCodeScannerRef = useRef<Html5Qrcode | null>(null)
  const [scannerState, setScannerState] = useState<Html5QrcodeScannerState>(
    html5QrCodeScannerRef.current?.getState() ??
      Html5QrcodeScannerState.UNKNOWN,
  )
  const [isProgressButtonShown, setIsProgressButtonShown] = useState(false)

  const isScannerInitialized =
    scannerState === Html5QrcodeScannerState.SCANNING ||
    scannerState === Html5QrcodeScannerState.PAUSED

  // INFO: Unfortunately there is no better way than manually take care of watching the state changes
  // because library doesn't expose an API to do it in a robust way
  // https://github.com/mebjas/html5-qrcode/pull/760
  const setScannerStateWithFallback = useCallback(() => {
    if (html5QrCodeScannerRef.current) {
      setScannerState(html5QrCodeScannerRef.current.getState())
    } else {
      setScannerState(Html5QrcodeScannerState.UNKNOWN)
    }
  }, [setScannerState])

  const { videoPermission } = useGetVideoPermission()

  const { stopScanning, pauseScanning, resumeScanning } =
    useGetOtherScanningCallbacks({
      scanner: html5QrCodeScannerRef,
      setScannerStateWithFallback,
    })

  const { startScanning } = useGetStartScanning({
    scanner: html5QrCodeScannerRef,
    pauseScanning,
    resumeScanning,
    setScannerStateWithFallback,
    setIsProgressButtonShown,
    onScanSuccessHandler,
  })

  // INFO: We want to run it only on onmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => stopScanning(), [])

  return {
    startScanning,
    pauseScanning,
    resumeScanning,
    scannerState,
    isScannerInitialized,
    videoPermission,
    isProgressButtonShown,
    setIsProgressButtonShown,
  }
}
