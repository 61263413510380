import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { isBackofficeUserSelector } from 'app/containers/common/Auth/selectors'
import { useUserMeQuery } from 'app/containers/common/Me/User/hooks/useUserMeQuery'
import {
  setIsUserInitialized,
  setCompanyIdAndRole,
  setUserRole,
} from 'app/containers/common/Auth/authSlice'
import { useGetIdentity } from 'app/containers/common/Me/User/hooks/utils'

// INFO: detailed informations about establishing user's identity https://simplesystem.atlassian.net/wiki/spaces/S2NEX/pages/3200876545/Frontend+User+identity+establishment

// INFO: this hook is used to fetch "User me" data in order to establish current user identity -> 'noIdentitySelected' | 'vendor' | 'customer' | 'ociCustomer' | 'backofficeUser'
export const useInitUser = () => {
  const dispatch = useDispatch()
  const isBackofficeUser = useSelector(isBackofficeUserSelector)
  const getIdentity = useGetIdentity()

  const { data: userData } = useUserMeQuery({ enabled: true })

  useEffect(() => {
    if (!userData) return

    const identity = getIdentity(userData)

    if (identity) {
      dispatch(setCompanyIdAndRole(identity))
      dispatch(setIsUserInitialized(true))
    }
  }, [dispatch, getIdentity, userData])

  // INFO: backoffice user is a special kind of user because we establish him based on the token content we get after the login and not "User/CompanyUser me" responses
  useEffect(() => {
    if (isBackofficeUser) {
      dispatch(setUserRole('backofficeUser'))
      dispatch(setIsUserInitialized(true))
    }
  }, [dispatch, isBackofficeUser])
}
