import type { IconProps } from '../types'

export const UserIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.39373 11.0906L10 13.5H4C4 12.3511 5.85998 11.3832 8.39373 11.0906ZM11.6063 11.0906C14.14 11.3832 16 12.3511 16 13.5H10L11.6063 11.0906Z"
      fill={color}
    />
    <path
      d="M4 13.5H16L15.2514 15.3714C15.0996 15.751 14.7319 16 14.323 16H5.67703C5.26813 16 4.90042 15.751 4.74856 15.3714L4 13.5Z"
      fill={color}
    />
    <path
      d="M7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6V7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7V6Z"
      fill={color}
    />
  </svg>
)
