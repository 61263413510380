import type { DividerProps as DividerBaseProps } from '@chakra-ui/react'
import { Divider as DividerBase } from '@chakra-ui/react'
import styled from '@emotion/styled'

import type { Color } from 'theme/colors'

type DividerProps = DividerBaseProps & {
  isFlexChild?: boolean
  color?: Color
}

const DividerComponent = ({ isFlexChild, as, ...rest }: DividerProps) => (
  <DividerBase as={as} {...rest} />
)

export const Divider = styled(({ color, ...props }: DividerProps) => (
  <DividerComponent borderColor={color} {...props} />
))(({ isFlexChild, color, theme: { colors } }) => ({
  borderColor: color ?? colors.line,
  opacity: 1,
  ...(isFlexChild && { alignSelf: 'stretch', height: 'auto' }),
}))
