import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useQueryClient } from '@tanstack/react-query'

import { translations } from 'locales/translations'

import { useCreateArticleListMutation } from 'app/containers/customers/ArticleLists/Create'
import { ARTICLE_LIST_ASSIGNMENTS_QUERY_KEY } from 'app/containers/customers/ArticleLists/Assignments/List'
import { Divider } from 'app/components/Divider'
import { SectionHeader } from 'app/components/SectionHeader'
import { Notification } from 'app/components/Notification/Notification'
import { Input } from 'app/components/inputs/Input'
import { ModalWithFooter } from 'app/components/modals/ModalWithFooter'
import type { ArticleListId } from 'app/containers/customers/ArticleLists/types'
import type { API__CreateArticleListRequestResponse } from 'app/containers/customers/ArticleLists/Create/types'

import { PanelWithArticleImageAndContent } from './components'
import {
  ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID,
  createNewListBtnMsg,
  messages,
} from './consts'
import type { CommonModalProps } from './types'

const createListMsgs =
  translations.pages.customer.companyAndSettings.articlesLists.list.createModal

type CreateArticleListModalProps = CommonModalProps & {
  turnOffCreateMode: () => void
  assignArticleList: (newArticleListId: ArticleListId) => Promise<void>
}

export const CreateArticleListModal = ({
  article,
  vendor,
  turnOffCreateMode,
  assignArticleList,
}: CreateArticleListModalProps) => {
  const { t } = useTranslation()
  const { mutate: createArticleList } = useCreateArticleListMutation()
  const queryClient = useQueryClient()

  const onSuccess = async ({
    id: newArticleListId,
  }: API__CreateArticleListRequestResponse) => {
    turnOffCreateMode()
    await assignArticleList(newArticleListId)
    queryClient.invalidateQueries({
      queryKey: [ARTICLE_LIST_ASSIGNMENTS_QUERY_KEY],
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ name: '' }}
      onSubmit={(values) => createArticleList(values, { onSuccess })}
    >
      {({ isValid, dirty }) => (
        <ModalWithFooter
          shouldWrapWithForm
          isWithoutHeaderBorder
          id={ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID}
          header={t(messages.header)}
          onCancelHandler={turnOffCreateMode}
          saveTranslation={t(createListMsgs.saveLabel)}
          isSaveButtonDisabled={!isValid || !dirty}
        >
          <PanelWithArticleImageAndContent article={article} vendor={vendor} />

          <Divider />

          <SectionHeader
            headerTestId="createNewListHeader"
            header={t(createNewListBtnMsg)}
            description={t(messages.createNewList.description)}
          />

          <Input
            name="name"
            label={t(createListMsgs.nameField.label)}
            placeholder={t(createListMsgs.nameField.placeholder)}
          />

          <Notification type="info">
            {t(messages.createNewList.shareInfo)}
          </Notification>
        </ModalWithFooter>
      )}
    </Formik>
  )
}
