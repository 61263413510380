import type { InternalAxiosRequestConfig } from 'axios'

import { store as appStore } from 'store'

import { addAuthData } from './addAuthData'
import { addBaseUrl } from './addBaseUrl'

export const createDefaultRequestInterceptor =
  (store = appStore) =>
  (request: InternalAxiosRequestConfig) => {
    addAuthData(request, store)
    addBaseUrl(request, store)

    return request
  }
