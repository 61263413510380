import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import de from './de/translation.json'
import en from './en/translation.json'
import fr from './fr/translation.json'
import it from './it/translation.json'
import cs from './cs/translation.json'
import { convertLanguageJsonToObject } from './translations'
import { AVAILABLE_LANGUAGES, LANGUAGE_DETECTOR_KEYS } from './consts'
import { fallback, navigator } from './detectors'

export const translationsJson = {
  [AVAILABLE_LANGUAGES.de]: { translation: de },
  [AVAILABLE_LANGUAGES.en]: { translation: en },
  [AVAILABLE_LANGUAGES.fr]: { translation: fr },
  [AVAILABLE_LANGUAGES.it]: { translation: it },
  [AVAILABLE_LANGUAGES.cs]: { translation: cs },
}

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en)

const languageDetector = new LanguageDetector()
languageDetector.addDetector(navigator)
languageDetector.addDetector(fallback)

export const i18n = i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    // will show translation key instead of fallback in the default fallback language
    // fail fast approach
    detection: {
      // order and from where user language should be detected
      order: [
        LANGUAGE_DETECTOR_KEYS.navigator,
        LANGUAGE_DETECTOR_KEYS.fallback,
      ],
      // cache user language on
      caches: [],
    },
    // missing values to fallback to the key in cases where the keys exist
    // in your JSON translation file with empty string as value
    returnEmptyString: false,
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      defaultTransParent: '',
      transWrapTextNodes: 'span', // INFO: needed to prevent https://simplesystem.atlassian.net/browse/S2NG-6041 bug
    },
  })

export { i18next }
