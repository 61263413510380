import { forwardRef } from '@chakra-ui/react'

import { BareInput } from '../Input'

import { getTextAreaProps } from './utils'
import type { BareTextAreaInputProps } from './types'

export const BareTextArea = forwardRef<BareTextAreaInputProps, 'input'>(
  ({ size = 'md', isResizable, rows, ...props }, ref) => (
    <BareInput
      ref={ref}
      rows={rows}
      size={size}
      // INFO: Sets cursor at the end of the input value when using autoFocus
      onFocus={(e) =>
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length,
        )
      }
      {...getTextAreaProps({ rows, size, isResizable })}
      {...props}
    />
  ),
)
