import { useSnackbar } from 'notistack'
import { uniq } from 'lodash'

import { useChangeCartItemsMutation } from 'app/containers/customers/CurrentCart/Items/Create'
import type { ModelSearchArticleListItems } from 'app/containers/customers/ArticleLists/Articles/search/types'
import { useAddToCartNotifications } from 'app/pages/common/marketplaceAndSearch/components/AddToCartButton/useAddToCartNotifications'

import { IMAGES_COUNT } from './components'

type AllToCartHandlerProps = {
  articles: ModelSearchArticleListItems | undefined
  onSuccess?: () => void
}

export const useAddToCartHandler = () => {
  const { enqueueSnackbar } = useSnackbar()
  const addToCartNotifications = useAddToCartNotifications()

  const { mutate: addItemsToCart, isPending: isAddingToCart } =
    useChangeCartItemsMutation()

  const addToCartHandler = ({ articles, onSuccess }: AllToCartHandlerProps) => {
    if (!articles) throw new Error('No articles to add')

    const availableArticles = articles.filter(({ available }) => available)
    const uniqueVendorIds = uniq(
      availableArticles.map(({ articleData }) => articleData.vendor.id),
    )

    const articlesToBeAddedToCart = availableArticles.map((article) => ({
      changeQuantityBy: article.quantity,
      articleId: article.articleData.article.id,
    }))

    const availableArticlesNumber = articlesToBeAddedToCart.length
    const unavailableArticlesNumber = articles.length - availableArticlesNumber

    const addedArticleImages = availableArticles
      .slice(0, IMAGES_COUNT)
      .map(({ articleData }) => articleData.article.thumbnailUrl)

    addItemsToCart(
      { data: articlesToBeAddedToCart },
      {
        onSuccess: () => {
          if (unavailableArticlesNumber > 0) {
            enqueueSnackbar({
              variant: 'unavailableArticles',
            })
          }

          addToCartNotifications.handleBulkedOperation({
            articlesNumber: availableArticlesNumber,
            vendorsNumber: uniqueVendorIds.length,
            articleImages: addedArticleImages,
          })
          onSuccess?.()
        },
      },
    )
  }

  return { addToCartHandler, isAddingToCart }
}
