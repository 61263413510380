import type { IconProps } from '../types'

export const OpenInNewIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M9 3H6C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H14C15.6569 17 17 15.6569 17 14V11"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M12 2H11V4H12V2ZM17 3H18V2H17V3ZM16 8V9H18V8H16ZM12 4H17V2H12V4ZM16 3V8H18V3H16Z"
      fill={color}
    />
    <path
      d="M16.7071 4.70711L17.4142 4L16 2.58579L15.2929 3.29289L16.7071 4.70711ZM9.29289 9.29289L8.58579 10L10 11.4142L10.7071 10.7071L9.29289 9.29289ZM15.2929 3.29289L9.29289 9.29289L10.7071 10.7071L16.7071 4.70711L15.2929 3.29289Z"
      fill={color}
    />
  </svg>
)
