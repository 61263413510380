import { Flex } from '@chakra-ui/react'

import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'

import { getFastOrderItemStatus } from 'app/pages/customers/common/fastOrder/utils'
import { FastOrderListItemContextProvider } from 'app/pages/customers/common/fastOrder/contexts'

import { FastOrderListItemWrapper } from './styles'
import {
  ArticleActions,
  FastOrderListItemCheckbox,
  FastOrderItemLoader,
  FastOrderListItemArticleContent,
} from './components'

/*
   INFO: key is used here to prevent bugs such as
   https://simplesystem.atlassian.net/browse/S2NG-7682
   or
   https://simplesystem.atlassian.net/browse/S2NG-7868

   Every time that we get a new scan or a new selected article we force re-rendering of
   whole row, so components that rely on local state such as `AmountInput` can display
   the updated value.

   `scanCount` changes +1 after each search
   `scanCount` changes to 0 whenever a manual update is done
   `lastAddedAt` changes whenever a scan happens or article merging is done (a found article
    is merged with a previously scanned article)
*/

type FastOrderListItemProps = {
  item: ModelGetFastOrderListData['items'][number]
}

export const FastOrderListItem = ({
  item: { lastAddedAt, selectedArticle, scanCount },
  item,
}: FastOrderListItemProps) => {
  const status = getFastOrderItemStatus(item)

  const key = `${selectedArticle}-${scanCount}-${lastAddedAt}`

  if (status === 'ARTICLE_SEARCH_IN_PROGRESS') {
    return <FastOrderItemLoader />
  }

  const contextValue = {
    fastOrderListItem: item,
    status,
  }

  return (
    <FastOrderListItemWrapper key={key} data-testid="fastOrderListItemArticle">
      <FastOrderListItemContextProvider value={contextValue}>
        <Flex align="center" gap="16px">
          <FastOrderListItemCheckbox />
          <FastOrderListItemArticleContent />
        </Flex>
        <ArticleActions />
      </FastOrderListItemContextProvider>
    </FastOrderListItemWrapper>
  )
}
