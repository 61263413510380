import { generatePath } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from 'apiServices'

import { ARTICLES_SEARCH_QUERY_KEY } from 'app/containers/common/Search'
import { ARTICLE_DETAILS_QUERY_KEY } from 'app/containers/common/ArticleDetails'

import type { MODEL__ArticleListAssignment } from '../../types'
import { articleListAssignmentsPath } from '../consts'
import { ARTICLE_LIST_ASSIGNMENTS_QUERY_KEY } from '../List'
import { ARTICLE_LISTS_QUERY_KEY } from '../../List'

export const url = articleListAssignmentsPath

type API__ArticleToListAssignmentUpdate = Pick<
  MODEL__ArticleListAssignment,
  'articleListId' | 'articleAssigned'
>

type API__ArticleListAssignmentUpdate = {
  assignments: API__ArticleToListAssignmentUpdate[]
  initialQuantity?: number
}

type API__ArticleListAssignmentUpdateRequestParams = {
  articleId: string
} & API__ArticleListAssignmentUpdate

const updateArticleListAssignments = ({
  articleId,
  ...body
}: API__ArticleListAssignmentUpdateRequestParams) =>
  api.put<unknown, undefined>(generatePath(url, { articleId }), body)

export const useUpdateArticleListAssignmentsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateArticleListAssignments,
    onSuccess: (_, { articleId }) => {
      queryClient.invalidateQueries({ queryKey: [ARTICLES_SEARCH_QUERY_KEY] })
      queryClient.invalidateQueries({
        queryKey: [ARTICLE_LIST_ASSIGNMENTS_QUERY_KEY, articleId],
      })
      queryClient.invalidateQueries({ queryKey: [ARTICLE_LISTS_QUERY_KEY] })
      queryClient.invalidateQueries({
        queryKey: [ARTICLE_DETAILS_QUERY_KEY, { articleId }],
      })
    },
  })
}
