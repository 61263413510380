import { http, HttpResponse } from 'msw'

import { getCustomerMockUrl } from '../../../utils'
import { url } from '../hooks'

import {
  assignedGeneralLedgersMockData,
  emptyAssignedGeneralLedgerMockData,
} from './data'

export const assignedGeneralLedgersGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json(assignedGeneralLedgersMockData),
)

export const emptyAssignedGeneralLedgersGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json(emptyAssignedGeneralLedgerMockData),
)
