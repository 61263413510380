import { Box } from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'theme'
import { translations } from 'locales/translations'

import { Button } from 'app/components/Buttons/Button'
import { Typography } from 'app/components/Typography'
import {
  useLogoutWithRedirectToRoot,
  useOciSession,
} from 'app/containers/common/Auth'
import { MobileButtonWithFullModal } from 'app/components/MobileButtonWithFullModal/MobileButtonWithFullModal'
import { MenuItemWrapper, MenuLink } from 'app/components/ActionsMenu/styles'

import { useHeaderTabsData } from '../HeaderCustomerTabs'

import { MyAccount, Support } from './components'

export const MobileMenu = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { isOciSessionFlow } = useOciSession()
  const { mutate: logout } = useLogoutWithRedirectToRoot()
  const tabsData = useHeaderTabsData()

  return (
    <MobileButtonWithFullModal
      bodyPadding="8px"
      withCloseButton={false}
      openModalButton={({ toggle, isOpen }) => (
        <Button
          data-testid={isOpen ? 'closeMobileMenuButton' : 'mobileCustomerNav'}
          onClick={toggle}
          size="squareSm"
          variant={isOpen ? 'primary' : 'tertiary'}
          leftIcon={<HamburgerIcon boxSize="20px" />}
        />
      )}
    >
      <Box bg={colors.backgroundA}>
        {tabsData.map(({ title, path }) => (
          <MenuLink
            key={path}
            to={path}
            style={{ color: colors.contentA }}
            activeStyle={{ color: colors.blue_500 }}
            data-testid={title}
          >
            <Typography textStyle="headerH4" style={{ color: 'inherit' }}>
              {title}
            </Typography>
          </MenuLink>
        ))}

        <MyAccount />

        <Support />

        {!isOciSessionFlow && (
          <MenuItemWrapper onClick={() => logout()}>
            <Typography as="button" textAlign="left">
              {t(translations.header.logout)}
            </Typography>
          </MenuItemWrapper>
        )}
      </Box>
    </MobileButtonWithFullModal>
  )
}
