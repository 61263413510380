import type { StackProps } from '@chakra-ui/react'

import type { IconProps } from '../icons/types'

import { BlockSpinnerWrapper, CustomSpinner } from './styles'

export const PART_PAGE_LOADER_ID = 'partPageLoader'

type PartPageLoaderProps = {
  wrapperProps?: StackProps
} & IconProps

export const PartPageLoader = ({
  wrapperProps,
  ...props
}: PartPageLoaderProps) => (
  <BlockSpinnerWrapper data-testid={PART_PAGE_LOADER_ID} {...wrapperProps}>
    <CustomSpinner {...props} />
  </BlockSpinnerWrapper>
)
