import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const PopoverTriggerWrapper = styled.div`
  > * {
    cursor: default;
  }
`

export const TriggerWrapper = styled(Flex)({
  borderRadius: '6px',
  width: '24px',
  height: '24px',
  alignItems: 'center',
  justifyContent: 'center',
})
