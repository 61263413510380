import { useTranslation } from 'react-i18next'

import { ExcludesFalsy } from 'types/utils'
import { translations } from 'locales/translations'

import { Button } from 'app/components/Buttons/Button'
import { SearchIcon } from 'app/components/icons'
import { BareTextArea } from 'app/components/inputs/TextArea/BareTextArea'
import { useBulkAddToFastOrderListMutation } from 'app/containers/customers/FastOrders/BulkAdd'

import { BARCODES_SEPARATOR } from '../consts'

type FastOrderInputProps = {
  inputValue: string | undefined
  setInputValue: (newValue: string) => void
}

const messages = translations.pages.customer.fastOrder.search

export const FastOrderInput = ({
  inputValue,
  setInputValue,
}: FastOrderInputProps) => {
  const { t } = useTranslation()

  const { mutate: addItemsMutation, isPending } =
    useBulkAddToFastOrderListMutation({
      fastOrderListEnum: 'FAST_ORDER_LIST',
    })

  const handleButtonClick = () => {
    if (inputValue) {
      const barcodes = inputValue
        .split(BARCODES_SEPARATOR)
        .filter(ExcludesFalsy)
        .map((str) => str.trim())

      addItemsMutation({ barcodes }, { onSuccess: () => setInputValue('') })
    }
  }

  return (
    <BareTextArea
      leftElement={<SearchIcon />}
      name="fastOrderSearch"
      data-testid="fastOrderSearch"
      placeholder={t(messages.placeholder)}
      onChange={(e) => setInputValue(e.target.value)}
      value={inputValue}
      paddingRight="65px"
      maxHeight="210px"
      isResizable
      maxRows={9}
      rightElement={
        <Button
          onClick={handleButtonClick}
          isLoading={isPending}
          size="sm"
          data-testid="fastOrderSearchButton"
        >
          {t(translations.formActions.add)}
        </Button>
      }
    />
  )
}
