import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { MenuItemWrapper } from 'app/components/ActionsMenu/styles'
import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'
import { SearchSettingsIcon, TickIcon } from 'app/components/icons'
import { MobileButtonWithFullModal } from 'app/components/MobileButtonWithFullModal'
import { Typography } from 'app/components/Typography'

import { useMarketplaceSearchAndPagination } from '../../../useMarketplaceSearchAndPagination'
import { useSearchPresetSelect } from '../useSearchPresetSelect'

const messages = translations.components.searchBox.presetSelect

const FooterButton = (props: ButtonProps) => (
  <Button isFullWidth flex="1" size="sm" {...props} />
)

export const MobileSearchPresets = () => {
  const { t } = useTranslation()
  const { setSearchPreset } = useMarketplaceSearchAndPagination()
  const { options, activeSearchPreset } = useSearchPresetSelect()
  const presetFromQuery = activeSearchPreset.id
  const [selectedPreset, setSelectedPreset] = useState(presetFromQuery)

  return (
    <MobileButtonWithFullModal
      title={t(messages.menu.header)}
      onMount={() => setSelectedPreset(presetFromQuery)}
      openModalButton={({ open }) => (
        <Flex p="8px" onClick={open} data-testid="searchPresetsIcon">
          <SearchSettingsIcon />
        </Flex>
      )}
      footer={({ close }) => (
        <Flex gap="16px" w="100%">
          <FooterButton variant="secondary" onClick={close}>
            {t(translations.common.cancel)}
          </FooterButton>

          <FooterButton onClick={() => setSearchPreset(selectedPreset)}>
            {t(translations.common.apply)}
          </FooterButton>
        </Flex>
      )}
    >
      <Flex direction="column" gap="8px" mt="20px">
        <Typography textStyle="textNormalSb">
          {t(messages.menu.label)}
        </Typography>

        <Flex direction="column">
          {options.map(({ id, label }) => (
            <MenuItemWrapper key={id} onClick={() => setSelectedPreset(id)}>
              {label}

              {id === selectedPreset && <TickIcon />}
            </MenuItemWrapper>
          ))}
        </Flex>
      </Flex>
    </MobileButtonWithFullModal>
  )
}
