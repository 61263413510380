import type { CustomDetector } from 'i18next-browser-languagedetector'

import { includes } from 'types/utils'

import { LANGUAGE_DETECTOR_KEYS, AVAILABLE_LANGUAGES } from '../consts'

import { getBrowserLocale } from './utils'

export const navigator: CustomDetector = {
  name: LANGUAGE_DETECTOR_KEYS.navigator,
  lookup() {
    const lang = getBrowserLocale({ languageCodeOnly: true })

    if (!lang) {
      return
    }

    if (includes(Object.values(AVAILABLE_LANGUAGES), lang)) {
      return lang
    }
  },
}
