import { Typography } from 'app/components/Typography'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'

import type { CustomerArticleNumberOptionProps } from './types'

export const CustomerArticleNumberOption = ({
  title,
  description,
}: CustomerArticleNumberOptionProps) => (
  <ColumnWithGap as="span" gap={4}>
    <Typography as="span" textStyle="inputNormal">
      {title}
    </Typography>
    <Typography as="span" color="contentB">
      {description}
    </Typography>
  </ColumnWithGap>
)
