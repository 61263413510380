import type { API__AssignedGeneralLedgersResponse } from '../hooks'

export const assignedGeneralLedgersMockData: API__AssignedGeneralLedgersResponse =
  {
    generalLedgers: [
      {
        id: 'bb50273a-5200-44d4-80e1-3a150d1b4149',
        number: '11081',
        description: 'Ledger name 01',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-3a150d1b414c',
        number: '11082',
        description: 'Ledger name 02',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-3a150d1b414d',
        number: '11083',
        description: 'Ledger name 03',
      },
      {
        id: 'cb50273a-5200-44d4-80e1-3a150d1b414e',
        number: '11084',
        description: 'Ledger name 04',
      },
    ],
  }

export const emptyAssignedGeneralLedgerMockData: API__AssignedGeneralLedgersResponse =
  {
    generalLedgers: [],
  }
