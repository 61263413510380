import { useTranslation } from 'react-i18next'

import { COUNTRIES } from 'consts/countries'
import { translations } from 'locales/translations'

const taxIdTranslations = translations.words

type GetVatIdLabelValueProps = {
  countryCode?: string
  vatId?: string
}

const useGetVatIdLabel = () => {
  const { t } = useTranslation()

  const getVatIdLabelTranslation = (countryCode: string = 'DE') => {
    switch (countryCode) {
      case COUNTRIES.CH.id:
        return t(taxIdTranslations.uId)
      case COUNTRIES.CN.id:
        return t(taxIdTranslations.TIN)
      default:
        return t(taxIdTranslations.vatId)
    }
  }

  return getVatIdLabelTranslation
}

export const useGetCountrySpecificVatIdTranslation = () => {
  const getVatIdLabel = useGetVatIdLabel()

  const getVatIdLabelValue = ({
    countryCode,
    vatId,
  }: GetVatIdLabelValueProps = {}) => {
    const vatIdLabel = getVatIdLabel(countryCode)
    const vatIdText = vatId ? `${vatIdLabel}: ${vatId}` : null

    return vatIdText
  }

  return { getVatIdLabel, getVatIdLabelValue }
}
