import type { IconProps } from '../types'

export const EmptyListIcon = ({
  width = '96px',
  height = '96px',
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    fill="none"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M76 29v52a7 7 0 0 1-7 7H27a7 7 0 0 1-7-7V15a7 7 0 0 1 7-7h28l21 21Z"
      fill="#B5CFFF"
    />
    <path
      d="M34.36 27.086c-.196.75-.79 1.386-1.566 1.386-.788 0-1.441-.646-1.344-1.428a7.89 7.89 0 0 1 .068-.447 4.682 4.682 0 0 1 .522-1.44c.252-.427.584-.85.999-1.27.348-.339.651-.661.91-.968.259-.306.462-.62.61-.943.148-.33.222-.698.222-1.101 0-.468-.066-.855-.2-1.161a1.441 1.441 0 0 0-.577-.714c-.251-.161-.57-.242-.954-.242-.318 0-.614.077-.888.23-.274.145-.5.37-.677.677-.337.607-.823 1.21-1.517 1.21h-.33c-.9 0-1.66-.752-1.418-1.618a4.3 4.3 0 0 1 .48-1.116 4.12 4.12 0 0 1 1.808-1.609c.755-.355 1.602-.532 2.542-.532 1.036 0 1.924.186 2.664.556.74.363 1.306.9 1.698 1.61.392.7.588 1.556.588 2.564 0 .701-.126 1.326-.377 1.875a6.433 6.433 0 0 1-.988 1.512c-.407.468-.855.951-1.343 1.451-.422.412-.71.843-.866 1.295a3.88 3.88 0 0 0-.065.223Zm-3.33 5.124c0-.517.163-.944.488-1.283.326-.346.762-.52 1.31-.52.54 0 .973.174 1.298.52.334.34.5.767.5 1.283 0 .5-.166.923-.5 1.27-.325.347-.758.52-1.298.52-.548 0-.984-.173-1.31-.52a1.787 1.787 0 0 1-.488-1.27Z"
      fill="#E7EEFF"
    />
    <path d="M55 22V8l21 21H62a7 7 0 0 1-7-7Z" fill="#276EF1" />
    <rect
      width={23}
      height={4}
      rx={2}
      transform="matrix(1 0 0 -1 46 70)"
      fill="#fff"
    />
    <rect
      width={13.269}
      height={4}
      rx={2}
      transform="matrix(1 0 0 -1 46 78)"
      fill="#fff"
    />
    <rect
      width={23}
      height={4}
      rx={2}
      transform="matrix(1 0 0 -1 46 49)"
      fill="#fff"
    />
    <rect
      width={13.269}
      height={4}
      rx={2}
      transform="matrix(1 0 0 -1 46 57)"
      fill="#fff"
    />
    <rect x={27} y={44} width={14} height={14} rx={3} fill="#E7EEFF" />
    <path
      d="m30.5 51.219 2.692 3.281 4.308-6.125"
      stroke="#276EF1"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={27} y={65} width={14} height={14} rx={3} fill="#E7EEFF" />
  </svg>
)
