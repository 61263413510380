export const useArticlesSearchInputFocus = () => {
  const name = 'articlesSearchInput'

  const focusArticlesSearchInput = () => {
    const input = document.getElementById(name)

    input?.focus()
  }

  return {
    name,
    focusArticlesSearchInput,
  }
}
