import type { ReactNode } from 'react'
import { Component } from 'react'
import * as Sentry from '@sentry/react'

import { ErrorPage } from './ErrorPage'
import { FILE_NOT_AVAILABLE_MSG, FONT_FETCH_TIMEOUT_MSG } from './consts'

interface State {
  error: Error | null
}

interface Props {
  children: ReactNode
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = { error: null }

  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  componentDidCatch(error: Error) {
    // FILE_NOT_AVAILABLE_MSG INFO: we are handling this specific error message in utils/loadable.tsx by force reloading the page
    //
    // FONT_FETCH_TIMEOUT_MSG INFO: we are not handling this error at all, as it does no harm for the user apart from the font not being updated (we have no control over it)
    if (
      error.message.includes(FILE_NOT_AVAILABLE_MSG) ||
      error.message.includes(FONT_FETCH_TIMEOUT_MSG)
    ) {
      return
    }

    Sentry.captureException(error)
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage clearBoundaryError={() => this.setState({ error: null })} />
      )
    }

    return this.props.children
  }
}
