import { useState } from 'react'
import { omit } from 'lodash'

import type {
  ModelSearchArticleListItem,
  ModelSearchArticleListItems,
} from 'app/containers/customers/ArticleLists/Articles/search/types'

type UseSelectArticlesHandlersProps = {
  articlesAvailableToAddToCart: ModelSearchArticleListItems
}

export const useSelectArticlesHandlers = ({
  articlesAvailableToAddToCart,
}: UseSelectArticlesHandlersProps) => {
  const [selectedArticles, setSelectedArticles] = useState<
    Record<string, ModelSearchArticleListItem>
  >({})

  const deleteSelectedArticle = (articleId: string) =>
    setSelectedArticles((internalSelectedArticles) =>
      omit(internalSelectedArticles, [articleId]),
    )

  const selectAllCheckboxHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    setSelectedArticles((internalSelectedArticles) =>
      event.target.checked
        ? articlesAvailableToAddToCart.reduce(
            (acc, article) => ({
              ...acc,
              [article.articleData.article.id]: article,
            }),
            internalSelectedArticles,
          )
        : omit(
            internalSelectedArticles,
            articlesAvailableToAddToCart.map(
              ({ articleData }) => articleData.article.id,
            ),
          ),
    )

  const makeSingleCheckboxHandler = (article: ModelSearchArticleListItem) => {
    const articleId = article.articleData.article.id

    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedArticles((internalSelectedArticles) =>
        event.target.checked
          ? { ...internalSelectedArticles, [articleId]: article }
          : omit(internalSelectedArticles, [articleId]),
      )
    }
  }

  const resetSelectedArticles = () => setSelectedArticles({})

  return {
    selectedArticles,
    selectAllCheckboxHandler,
    makeSingleCheckboxHandler,
    resetSelectedArticles,
    deleteSelectedArticle,
  }
}
