import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { Table } from 'app/components/tables'
import { Panel } from 'app/components/Panel/Panel'
import { useBareArticleExternalReferencesListQuery } from 'app/containers/customers/ExternalReferences/List'
import { Button } from 'app/components/Buttons/Button'
import { PlusIcon } from 'app/components/icons'

import { useExternalReferencesColumns } from '../useExternalReferencesColumns'
import {
  hideEditArticleModal,
  showExternalReferencesFormModal,
} from '../../modalFlowActions'

import type { EditExternalReferencesProps } from './types'

const messages = translations.pages.customer.editArticleDetailsModal

export const EditExternalReferences = ({
  articleId,
}: EditExternalReferencesProps) => {
  const { t } = useTranslation()
  const { data: externalReferencesListData, isLoading } =
    useBareArticleExternalReferencesListQuery(articleId)

  const columns = useExternalReferencesColumns({ articleId })

  const isAnyExternalReferenceDefined =
    Number(externalReferencesListData?.items.length) > 0

  const addExternalReferenceButtonRendered = (
    <Button
      size="sm"
      variant="secondary"
      leftIcon={<PlusIcon />}
      onClick={() => {
        hideEditArticleModal()
        showExternalReferencesFormModal({ articleId })
      }}
      data-testid="addExternalReferenceButton"
    >
      {t(messages.externalReferences.addButtonLabel)}
    </Button>
  )

  const externalReferencesTableEmptyCaseProps = {
    shouldHideBorder: true,
    message: t(messages.externalReferences.table.emptyCase.header),
    subHeader: t(messages.externalReferences.table.emptyCase.subheader),
    action: addExternalReferenceButtonRendered,
  }

  return (
    <Panel p="0" spacing="0">
      {isAnyExternalReferenceDefined && (
        <Flex w="100%" justify="space-between" align="center" p="16px">
          <Typography textStyle="headerH4">
            {t(messages.externalReferences.title)}
          </Typography>
          {addExternalReferenceButtonRendered}
        </Flex>
      )}
      <Table
        data={externalReferencesListData?.items}
        isLoading={isLoading}
        columns={columns}
        withoutLastRowBorder
        emptyCaseWrapperProps={externalReferencesTableEmptyCaseProps}
        dataTestId="externalReferencesTable"
        rowItemsAlignment="center"
      />
    </Panel>
  )
}
