export const getBrowserLocale = ({ languageCodeOnly = false } = {}) => {
  try {
    const browserLocales =
      navigator.languages === undefined && navigator.language
        ? [navigator.language]
        : navigator.languages

    if (!browserLocales) {
      return undefined
    }

    return browserLocales.map((locale) => {
      const trimmedLocale = locale.trim()

      return languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale
    })[0]
  } catch (error) {
    if (error instanceof TypeError) {
      console.error('window not defined')

      return
    }

    if (error instanceof Error) {
      console.error(error.message)

      return
    }

    console.error('Cannot detect the browser language')
  }
}
