import type { Html5QrcodeCameraScanConfig } from 'html5-qrcode'

const SCANNING_AREA_WIDTH_TO_VIDEO_STREAM_RATIO = 0.95
const SCANNING_AREA_ASPECT_RATIO = 1.46
const SCANNING_AREA_MINIMAL_HEIGHT = 200

const getQrBoxDimensions = function () {
  const width = Math.floor(
    window.innerWidth * SCANNING_AREA_WIDTH_TO_VIDEO_STREAM_RATIO,
  )
  const height = Math.max(
    Math.floor(width / SCANNING_AREA_ASPECT_RATIO),
    SCANNING_AREA_MINIMAL_HEIGHT,
  )

  return { width, height }
}

export const getQrBoxDistanceFromTop = () => {
  const windowHeight = window.innerHeight
  const { height: scanningAreaHeight } = getQrBoxDimensions()

  const qrBoxDistanceFromTop = (windowHeight + scanningAreaHeight) / 2

  return { qrBoxDistanceFromTop }
}

export const cameraScanConfig: Html5QrcodeCameraScanConfig = {
  fps: 10,
  qrbox: getQrBoxDimensions,
}
