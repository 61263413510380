import { chunk } from 'lodash/fp'

import type { ModelCustomerArticleSearchData } from 'libs/openapiGeneratedApi/Api'

import { articlesSearchMockedData } from './data'
import { filterArticlesMockData } from './filterArticlesMockData'

const pageSize = 8

export const makeArticlesMockData = (
  term: string | null | undefined,
  page: number,
) => {
  const filteredData = filterArticlesMockData(term)
  const paginatedData = chunk(pageSize)(filteredData)

  if (!articlesSearchMockedData.metadata)
    throw new Error('articlesSearchMockedData metadata field should be defined')

  const data: ModelCustomerArticleSearchData = {
    ...articlesSearchMockedData,
    metadata: {
      ...articlesSearchMockedData.metadata,
      pageCount: paginatedData.length,
      page,
    },
    items: paginatedData[Number(page) - 1],
  }

  return data
}
