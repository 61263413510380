import type { Size } from 'chakra-react-select'
import ResizeTextArea from 'react-textarea-autosize'

type GetTextAreaPropsArgs = {
  rows?: number
  size?: Size
  isResizable?: boolean
}

const makeSizeDependentStyles = (size: Size = 'md') => ({
  paddingTop: size === 'md' ? '14px' : '8px',
  minHeight: size === 'md' ? '48px' : '36px',
})

export const getTextAreaProps = ({
  rows,
  size = 'md',
  isResizable,
}: GetTextAreaPropsArgs) =>
  ({
    ...makeSizeDependentStyles(size),
    ...(rows && { style: { height: 'auto' } }),
    whiteSpace: 'pre-wrap',
    overflowY: 'auto',
    as: isResizable ? ResizeTextArea : 'textarea',
  }) as const

export const replaceLineBreak = (value?: string) =>
  value?.replace(/(\r\n|\n|\r)/gm, ' ') ?? ''
