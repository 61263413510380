import { http, HttpResponse } from 'msw'

import { getVendorMockUrl } from 'app/containers/vendors/utils'

import { url } from './api'
import type { MODEL__CustomersCounts } from './model'

export const customersCounts: MODEL__CustomersCounts = {
  accepted: 19,
  pending: 3,
  onHold: 1,
  rejected: 2,
  pendingOnHold: 2,
  pendingRejectedOnHold: 5,
  term: 'GmbH',
}

export const customersCountsTwoAccepted: MODEL__CustomersCounts = {
  accepted: 2,
  pending: 0,
  onHold: 0,
  rejected: 0,
  pendingOnHold: 0,
  pendingRejectedOnHold: 0,
  term: '',
}

export const customersCountsGetMock = http.get(getVendorMockUrl(url), () =>
  HttpResponse.json(customersCounts),
)

export const customersCountsTwoAcceptedGetMock = http.get(
  getVendorMockUrl(url),
  () => HttpResponse.json(customersCountsTwoAccepted),
)
