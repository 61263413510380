import { http, HttpResponse } from 'msw'

import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'

import type { API__CartResponse } from '../hooks'
import { url } from '../hooks'

import type { MakeCartMockDataProps } from './makeCartMockDataWithCustomAccounting'
import { makeCartMockDataWithCustomAccounting } from './makeCartMockDataWithCustomAccounting'
import { cartResponseMockData, unavailableArticlesMockData } from './data'

export const makeCartGetMockWithData = (
  data: API__CartResponse = cartResponseMockData,
) => http.get(getCustomerMockUrl(url), () => HttpResponse.json(data))

// TODO: Use makeCartGetMockWithData instead of makeCartGetMockWithCustomAccounting
// https://simplesystem.atlassian.net/browse/S2NG-4653

export const makeCartGetMockWithCustomAccounting = (
  props: MakeCartMockDataProps,
) =>
  http.get(getCustomerMockUrl(url), () =>
    HttpResponse.json(makeCartMockDataWithCustomAccounting(props)),
  )

export const cartGetMock = http.get(getCustomerMockUrl(url), () =>
  HttpResponse.json(cartResponseMockData),
)

export const cartWithUnavailableArticlesGetMock = http.get(
  getCustomerMockUrl(url),
  () =>
    HttpResponse.json({
      ...cartResponseMockData,
      unavailableArticles: unavailableArticlesMockData,
    }),
)

export const cartWithGeneralLedgerMappingGetMock = http.get(
  getCustomerMockUrl(url),
  () =>
    HttpResponse.json({
      ...cartResponseMockData,
      generalLedgerCartLevelEnabled: false,
    }),
)

export const cartWithNoAccountDataGetMock = http.get(
  getCustomerMockUrl(url),
  () =>
    HttpResponse.json({
      ...cartResponseMockData,
      additionalAccountingInfo: null,
      account: null,
    }),
)

export const cartWithFreeTextAccountDataGetMock = http.get(
  getCustomerMockUrl(url),
  () =>
    HttpResponse.json({
      ...cartResponseMockData,
      account: {
        freeTextValue: 'testFreeTextValue',
        typeId: 'cb50273a-5200-44d4-80e1-3a150d1b414d',
        valueId: null,
      },
    }),
)

export const cartWithNoGeneralLedgersDataGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json({ ...cartResponseMockData, generalLedgerId: null }),
)

export const makeCartOciCustomerGetMock = (
  data: API__CartResponse = cartResponseMockData,
) => http.get(getOciCustomerMockUrl(url), () => HttpResponse.json(data))

export const cartOciCustomerGetMock = http.get(getOciCustomerMockUrl(url), () =>
  HttpResponse.json(cartResponseMockData),
)
