import { useSpinDelay } from 'spin-delay'

import { IS_TEST } from 'consts/envVars'

type UseDelayedLoaderProps = {
  isLoading?: boolean
  shouldUseDelayedLoader?: boolean
}

const LOADER_DELAY = 200
const DELAYED_LOADER_MIN_DURATION = 500

export const useDelayedLoader = ({
  isLoading,
  shouldUseDelayedLoader,
}: UseDelayedLoaderProps) => {
  // INFO:
  // 1. If isLoading is true for LESS than LOADER_DELAY than we DON'T SHOW the spinner at all
  // 2. If isLoading is true for EQUAL OR MORE than LOADER_DELAY than we SHOW the spinner
  // no custom tests because it's tested here https://github.com/smeijer/spin-delay/blob/master/src/index.test.js
  // INFO 2: We don't want to delay the loader in tests, because we often rely on the loader to determine if the page is fully loaded and ready to be tested
  const isDelayedLoaderVisible = useSpinDelay(!!isLoading, {
    delay: LOADER_DELAY,
    minDuration: DELAYED_LOADER_MIN_DURATION,
  })

  const isLoaderDisplayed =
    shouldUseDelayedLoader && !IS_TEST ? isDelayedLoaderVisible : isLoading

  return { isLoaderDisplayed }
}
