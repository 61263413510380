import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import { usePageOffsetTop } from '../../../usePageOffsetTop'
import { SIDE_NAV_WIDTH } from '../consts'

export const Nav = ({ as, ...props }: FlexProps) => {
  const pageOffsetTop = usePageOffsetTop()

  return (
    <Flex
      as={as}
      flexDirection="column"
      justifyContent="space-between"
      position="fixed"
      height={`calc(100% - ${pageOffsetTop})`}
      width={SIDE_NAV_WIDTH}
      padding="20px 20px 24px"
      overflow="auto"
      borderRight="primary"
      {...props}
    />
  )
}
