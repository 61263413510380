import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { PATHS } from 'consts/paths'
import { translations } from 'locales/translations'

import { Button, type ButtonProps } from 'app/components/Buttons/Button'

const messages = translations.pages.errorPageLayout

export const HomePageButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Button isFullWidth onClick={() => history.push(PATHS.root)} {...props}>
      {t(messages.homepageButton)}
    </Button>
  )
}

export const RefreshPageButton = (props: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      isFullWidth
      variant="secondary"
      onClick={() => window.location.reload()}
      {...props}
    >
      {t(messages.refreshPageButton)}
    </Button>
  )
}
