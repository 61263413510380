import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const IconOnlyCopyButtonWrapper = styled(Box)(
  ({ theme: { colors } }) => ({
    margin: '0 4px 0 4px',

    ':hover': {
      cursor: 'pointer',
      color: colors.contentA,
    },
  }),
)
