import { Modal } from '../Modal'
import { ModalCancelButton } from '../ModalCancelButton'
import { ModalSaveButton } from '../ModalSaveButton'
import type { ModalWithFooterProps } from '../types'

export const ModalWithFooter = ({
  children,
  onCancelHandler,
  onSaveHandler,
  cancelTranslation,
  saveTranslation,
  isSaveButtonDisabled,
  isSaveButtonLoading,
  shouldHideCancelButton,
  shouldWrapWithForm,
  ...props
}: ModalWithFooterProps) => (
  <Modal
    {...props}
    shouldWrapWithForm={shouldWrapWithForm}
    footer={(closeModal) => (
      <>
        {!shouldHideCancelButton && (
          <ModalCancelButton
            onCancelHandler={onCancelHandler}
            cancelTranslation={cancelTranslation}
            closeModal={closeModal}
          />
        )}

        <ModalSaveButton
          isSubmitButton={shouldWrapWithForm}
          isSaveButtonDisabled={isSaveButtonDisabled}
          isSaveButtonLoading={isSaveButtonLoading}
          closeModal={closeModal}
          saveTranslation={saveTranslation}
          onSaveHandler={onSaveHandler}
        />
      </>
    )}
  >
    {children}
  </Modal>
)

//TODO: Add unit tests
// https://simplesystem.atlassian.net/browse/S2NG-5023
