import { Link, generatePath, useHistory } from 'react-router-dom'
import type { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'

import { PATHS } from 'consts/paths'

import { useCurrentUser } from 'app/containers/common/Auth'

import { useCatalogVersionId } from '../useCatalogVersionId'
import { type CatalogPreviewLocationState, useCatalog } from '../useCatalog'

const useArticleLinkTo = (articleId?: string | null) => {
  const history = useHistory()
  const { catalogVersionId } = useCatalogVersionId()
  const { catalogName, catalogCurrency, catalogId, activeTab } = useCatalog()
  const { isVendor } = useCurrentUser()

  if (!articleId) return

  if (isVendor) {
    if (!catalogVersionId) throw new Error('catalogVersionId has to be defined')

    if (!catalogCurrency) throw new Error('catalogCurrency has to be defined')

    const state: CatalogPreviewLocationState = {
      currency: catalogCurrency,
      catalogId,
      name: catalogName,
      activeTab,
    }

    return {
      pathname: generatePath(PATHS.vendor.catalogPreview.articleDetails, {
        catalogVersionId,
        articleId,
      }),
      search: history.location.search, // INFO: preserve user context
      state,
    }
  }

  return {
    pathname: generatePath(PATHS.customer.articleDetails, { articleId }),
    search: history.location.search,
  }
}

type ArticleLinkWrapperProps = {
  articleId?: string | null
  children: ReactNode
}

export const ArticleLinkWrapper = ({
  articleId,
  children,
}: ArticleLinkWrapperProps) => {
  const articleLinkTo = useArticleLinkTo(articleId)

  if (!articleLinkTo) return children

  return (
    <Box sx={{ _hover: { opacity: 0.7 } }}>
      <Link to={articleLinkTo}>{children}</Link>
    </Box>
  )
}
