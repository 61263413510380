import { useMemo } from 'react'
import type { Column } from 'react-table'

import { ExcludesFalsy } from 'types/utils'

type UseGetSortByPropsArgs<T extends object> = {
  columns: Column<T>[]
}
export const useGetSortByProps = <T extends object>({
  columns,
}: UseGetSortByPropsArgs<T>) => {
  const withSortBy = useMemo(
    () => Boolean(columns.find(({ sortType }) => sortType !== undefined)),
    [columns],
  )

  const sortByInitialState = useMemo(
    () =>
      columns
        .map(({ id, initialSortingState }) =>
          id && initialSortingState
            ? { id, desc: initialSortingState === 'desc' }
            : undefined,
        )
        .filter(ExcludesFalsy),
    [columns],
  )

  return { withSortBy, sortByInitialState }
}
