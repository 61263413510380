import type { PopoverContentProps } from '@chakra-ui/react'
import { PopoverContent } from '@chakra-ui/react'
import type { ReactNode } from 'react'

// TODO: custom children prop because of the bug https://github.com/chakra-ui/chakra-ui/issues/7795
// INFO: following override fixes problem with unwanted scrollbars appearing https://github.com/chakra-ui/chakra-ui/issues/4109#issuecomment-850313486
export const PopoverContentOverriden = (
  props: PopoverContentProps & { children: ReactNode },
) => (
  <PopoverContent rootProps={{ style: { transform: 'scale(0)' } }} {...props} />
)
