import { Flex } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

import { colors } from 'theme/colors'
import { translations } from 'locales/translations'

import { DotSeparator } from 'app/components/DotSeparator/DotSeparator'
import { Typography } from 'app/components/Typography'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { GenericArticleContentWrapper } from './styles'
import type { GenericArticleContentProps } from './types'
import {
  ArticleImageWithOptionalRedirect,
  GenericArticleContentHeader,
} from './components'

const messages = translations.pages.customer.search.searchArticle
const detailsMessages = translations.pages.customer.articleDetails

export const GenericArticleContent = ({
  articleData,
  isWithoutLinks,
  isWithoutCustomerArticleNumber,
  isDisabled,
  rightSideRendered,
  articleNameNoOffLines,
}: GenericArticleContentProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  const { articleNumber, ean, customerArticleNumber } = articleData

  return (
    <GenericArticleContentWrapper isDisabled={isDisabled}>
      <ArticleImageWithOptionalRedirect
        articleData={articleData}
        isWithoutLinks={isWithoutLinks}
      />

      <Flex gap="4px" direction="column">
        <GenericArticleContentHeader
          articleNameNoOffLines={articleNameNoOffLines}
          isWithoutLinks={isWithoutLinks}
          articleData={articleData}
        />

        <Flex gap="4px" direction={isMobile ? 'column' : 'row'}>
          <Typography textStyle="textSmall">
            {t(messages.articleNumberFootnote, {
              number: articleNumber,
            })}
          </Typography>

          {!isMobile && <DotSeparator color={colors.contentOff} />}

          <Typography textStyle="textSmall" color="contentB">
            {t(messages.eanFootnote, { number: ean })}
          </Typography>

          {!isWithoutCustomerArticleNumber && customerArticleNumber && (
            <>
              {!isMobile && <DotSeparator color={colors.contentOff} />}

              <Typography textStyle="textSmall" color="contentB">
                <Trans
                  i18nKey={detailsMessages.buyerArticleNumber}
                  components={[<span />]}
                  values={{ number: customerArticleNumber }}
                />
              </Typography>
            </>
          )}
        </Flex>
      </Flex>
      {rightSideRendered}
    </GenericArticleContentWrapper>
  )
}
