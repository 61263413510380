import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'

import {
  CustomerNavTabsWrapper,
  CustomerNavWrapper,
  HeaderActionsWrapper,
  LogoWrapper,
} from './styles'
import {
  CartButton,
  HeaderCustomerTabs,
  SearchBox,
  CustomerAccountButton,
  Logo,
} from './components'
import { CustomerNavMobile } from './CustomerNavMobile'

export const CustomerNav = () => {
  const { isTablet } = useBreakpointScreenSize()

  if (isTablet) return <CustomerNavMobile />

  return (
    <ColumnWithGap gap={0} data-testid="mainHeader">
      <CustomerNavWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <SearchBox />

        <HeaderActionsWrapper>
          <CustomerAccountButton />

          <CartButton />
        </HeaderActionsWrapper>
      </CustomerNavWrapper>

      <CustomerNavTabsWrapper>
        <HeaderCustomerTabs />
      </CustomerNavTabsWrapper>
    </ColumnWithGap>
  )
}
