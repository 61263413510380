import { useTranslation } from 'react-i18next'
import { TbPoint, TbPointFilled } from 'react-icons/tb'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'
import { assertNever } from 'types/utils'

import { Typography, type TypographyProps } from 'app/components/Typography'
import type { ModelSearchArticleItem } from 'app/containers/common/Search'
import { MarkerTooltip } from 'app/components/tooltips'
import { useGetTimeSince } from 'app/hooks/date'

const messages = translations.pages.customer.search.searchArticle.stockInfo

const iconSize = '20px'

const useGetInfo = (stockInfo?: ModelSearchArticleItem['stockInfo']) => {
  const { t } = useTranslation()
  const getTimeSince = useGetTimeSince()
  const { colors } = useTheme()

  if (!stockInfo) return null

  const getTooltipForProvidedInfo = () => {
    if (!stockInfo.updatedAt) return null

    return t(messages.tooltip.provided, {
      timeSince: getTimeSince(stockInfo.updatedAt),
    })
  }

  switch (stockInfo.value) {
    case 'IN_STOCK':
      return {
        label: t(messages.labels.IN_STOCK),
        tooltip: getTooltipForProvidedInfo(),
        icon: <TbPointFilled size={iconSize} color={colors.green_600} />,
      } as const

    case 'OUT_OF_STOCK':
      return {
        label: t(messages.labels.OUT_OF_STOCK),
        tooltip: getTooltipForProvidedInfo(),
        icon: <TbPointFilled size={iconSize} color={colors.orange_600} />,
      } as const

    case 'NOT_PROVIDED':
      return {
        label: t(messages.labels.NOT_PROVIDED),
        tooltip: t(messages.tooltip.notProvided),
        icon: <TbPoint size={iconSize} />,
      } as const

    default:
      assertNever(stockInfo.value)
  }
}

type StockInfoProps = TypographyProps & {
  stockInfo?: ModelSearchArticleItem['stockInfo']
}

export const StockInfo = ({ stockInfo, ...rest }: StockInfoProps) => {
  const { label, tooltip, icon } = useGetInfo(stockInfo) ?? {}

  if (!stockInfo) return null

  return (
    <MarkerTooltip content={tooltip}>
      <Typography
        textStyle="textSmall"
        color="contentB"
        display="flex"
        gap="8px"
        alignItems="center"
        alignSelf="flex-start"
        {...rest}
      >
        {icon}
        {label}
      </Typography>
    </MarkerTooltip>
  )
}
