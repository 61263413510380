import { Flex } from '@chakra-ui/react'

import { useTheme } from 'theme'

import { Typography } from 'app/components/Typography'
import { DotSeparator } from 'app/components/DotSeparator/DotSeparator'
import { SoldByText } from 'app/pages/common/marketplaceAndSearch/components/ArticleTexts/SoldByText'

import { ArticleLinkWrapper } from '../../ArticleLinkWrapper'

type ArticleContentHeaderProps = {
  isListView: boolean
  article: { id: string; name: string; manufacturer: string }
  vendor: { name: string }
}

export const ArticleContentHeader = ({
  isListView,
  article,
  vendor,
}: ArticleContentHeaderProps) => {
  const { colors } = useTheme()

  return (
    <ArticleLinkWrapper articleId={article.id}>
      <Flex gap={isListView ? '8px' : '4px'} direction="column">
        <Typography textStyle="textSmallSb" color="contentB">
          {article.manufacturer}

          {isListView && (
            <>
              <DotSeparator color={colors.contentOff} />

              <SoldByText vendorName={vendor.name} />
            </>
          )}
        </Typography>

        <Typography textStyle={isListView ? 'inputNormalSb' : 'textNormalSb'}>
          {article.name}
        </Typography>

        {!isListView && (
          <Typography color="contentB" textStyle="textSmallSb">
            <SoldByText vendorName={vendor.name} />
          </Typography>
        )}
      </Flex>
    </ArticleLinkWrapper>
  )
}
