import axios from 'axios'

import { delay } from 'app/utils/delay'

type VersionJson = {
  version: string
}

const RETRY_COUNT = 4

export const getServerAppVersion = async () => {
  const versionJsonUrl = `${window.location.origin}/version.json`
  // Retry fetching version.json, e.g. due to the network issues
  for (let i = 0; i < RETRY_COUNT; i++) {
    try {
      const response = await axios.get<VersionJson>(versionJsonUrl)

      return response.data.version
    } catch (error) {}
    await delay(1000 * 2 ** i)
  }
  throw new Error(`Failed to fetch version.json from ${versionJsonUrl}`)
}

let initAppVersion: string | null = null

export const getInitServerAppVersion = () => initAppVersion

export const fetchInitServerAppVersion = async () => {
  initAppVersion = await getServerAppVersion()

  return initAppVersion
}
