import NiceModal from '@ebay/nice-modal-react'
import { Trans, useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { translations } from 'locales/translations'

import { useDeleteArticleExternalReferenceMutation } from 'app/containers/customers/ExternalReferences/Delete'
import {
  ConfirmActionModal,
  buildConfirmModalId,
} from 'app/components/modals/ConfirmActionModal'
import { ModalButton } from 'app/components/modals/ModalButton'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useGetShowSuccessToast } from 'app/components/Notification/useGetShowSuccessToast'

import {
  type ConfirmDeleteExternalReferenceModalProps,
  showEditArticleModal,
  CONFIRM_DELETE_EXTERNAL_REFERENCE_MODAL_ID,
} from '../modalFlowActions'

const SemiBold = styled('span')({ fontWeight: 600 })

const messages =
  translations.pages.customer.editArticleDetailsModal.externalReferences
    .confirmDeleteModal

export const ConfirmDeleteExternalReferenceModal =
  NiceModal.create<ConfirmDeleteExternalReferenceModalProps>(
    ({ articleId, externalReference: { id, barcode } }) => {
      const { t } = useTranslation()
      const { isMobile } = useBreakpointScreenSize()
      const { showSuccessToast } = useGetShowSuccessToast()

      const { mutate: deleteExternalReference, isPending } =
        useDeleteArticleExternalReferenceMutation()

      const deleteHandler = (closeModal: () => void) =>
        deleteExternalReference(
          { articleId, externalReferenceId: id },
          {
            onSuccess: () => {
              closeModal()
              showSuccessToast({ content: t(messages.saveSuccessMessage) })

              if (!isMobile) {
                showEditArticleModal({ articleId })
              }
            },
          },
        )

      return (
        <ConfirmActionModal
          id={CONFIRM_DELETE_EXTERNAL_REFERENCE_MODAL_ID}
          headerText={t(messages.header)}
          contentUnderText={t(translations.common.actionCannotBeUndone)}
          onCloseCallback={() => {
            showEditArticleModal({ articleId })
          }}
          renderConfirmButton={(closeModal) => (
            <ModalButton
              variant="red"
              isLoading={isPending}
              onClick={() => deleteHandler(closeModal)}
            >
              {isMobile
                ? t(translations.formActions.delete)
                : t(messages.submitButtonText)}
            </ModalButton>
          )}
        >
          <Trans
            i18nKey={messages.content}
            components={[<SemiBold />]}
            values={{ externalReference: barcode }}
          />
        </ConfirmActionModal>
      )
    },
  )

NiceModal.register(
  buildConfirmModalId(CONFIRM_DELETE_EXTERNAL_REFERENCE_MODAL_ID),
  ConfirmDeleteExternalReferenceModal,
)
