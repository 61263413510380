import { PATHS } from 'consts/paths'

export const HEADER_CUSTOMER_TABS_MAP = {
  home: PATHS.customer.home,
  vendors: PATHS.customer.vendors,
  shopping: PATHS.customer.search,
  articleLists: PATHS.customer.articleLists,
  orderHistory: PATHS.customer.orders,
  deliveryAndPayments: PATHS.customer.deliveriesAndPayments,
  settings: PATHS.customer.companyAndSettings.dashboard,
} as const
