import { faker } from '@faker-js/faker'

import type { ModelSearchResult } from 'app/containers/common/Search/types'

import type { ModelSearchArticleListItem } from './types'

type MakeBarcodeArticleMockDataArgs = {
  baseArticleData: ModelSearchResult
  articleData?: Partial<ModelSearchArticleListItem['articleData']['article']>
  available?: boolean
  externalReferences?: { id: string; barcode: string; quantity: number }[]
}

export const makeArticleMockData = ({
  baseArticleData,
  articleData,
  available = true,
  externalReferences: passedExternalReferences,
}: MakeBarcodeArticleMockDataArgs): ModelSearchArticleListItem => ({
  articleData: {
    ...baseArticleData,
    article: {
      ...baseArticleData.article,
      ...articleData,
      id: faker.string.uuid(),
      isIncludedInArticleList: true,
      explanation: undefined,
    },

    externalReferences: passedExternalReferences ?? [
      { id: faker.string.uuid(), barcode: '98343432', quantity: 5 },
      { id: faker.string.uuid(), barcode: '0983475', quantity: 4 },
      { id: faker.string.uuid(), barcode: '42342', quantity: 3 },
    ],
  },
  priceUsed: { amount: 1234, currency: 'GBP' },
  quantity: 3,
  available,
})
