import { Flex } from '@chakra-ui/react'

import { colors } from 'theme/colors'
import type { ModelArticleDetailData } from 'libs/openapiGeneratedApi/Api'

import { Panel } from 'app/components/Panel/Panel'
import { ImageWithFallback } from 'app/components/ImageWithFallback'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'
import { Typography } from 'app/components/Typography'
import { DotSeparator } from 'app/components/DotSeparator/DotSeparator'
import {
  ArticleEanText,
  ArticleNumberText,
  SoldByText,
} from 'app/pages/common/marketplaceAndSearch/components/ArticleTexts'

export const ArticleContent = ({
  manufacturer,
  vendor: { name: vendorName },
  name,
  number: articleNumber,
  ean,
  thumbnailUrl,
}: ModelArticleDetailData) => (
  <Panel p="12px 20px" data-testid="articleInformation">
    <Flex gap="16px">
      <ImageWithFallback src={thumbnailUrl} alt={name} size={40} />

      <ColumnWithGap gap={4}>
        <Typography textStyle="textSmallSb" color="contentB" gap="4px">
          {manufacturer}
          <DotSeparator color={colors.contentOff} />
          <SoldByText vendorName={vendorName} />
        </Typography>

        <Typography textStyle="textNormalSb">{name}</Typography>

        <Flex gap="4px">
          <ArticleNumberText color="contentB" number={articleNumber} />
          <DotSeparator color={colors.contentOff} />
          <ArticleEanText ean={ean} />
        </Flex>
      </ColumnWithGap>
    </Flex>
  </Panel>
)
