import { MAX_INPUT_VALUE } from 'app/pages/common/marketplaceAndSearch/components/AmountInput/InMemoryAmountInput/useAmountHandlers/consts'

export const parseToPositiveInteger = (value?: string | number) => {
  const parsed = parseInt(String(+(value ?? 0)), 10)

  return parsed > -1 ? parsed : 1
}

type ParseInputValueProps = {
  value?: string | number
  quantityMinimum: number
  quantityInterval: number
}

export const parseInputValue = ({
  value,
  quantityMinimum,
  quantityInterval,
}: ParseInputValueProps) => {
  const parsed = parseToPositiveInteger(value)

  // fallback to minimum value
  if (parsed !== 0 && parsed < quantityMinimum) {
    return quantityMinimum
  }

  // if value is not a multiplied interval
  // round UP to the nearest interval value
  if (parsed % quantityInterval !== 0) {
    const roundedValue = quantityInterval * Math.ceil(parsed / quantityInterval)

    return roundedValue > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : roundedValue
  }

  return parsed
}
