import type { CSSProperties } from 'react'
import type { GroupBase, SelectComponentsConfig } from 'chakra-react-select'
import { chakraComponents } from 'chakra-react-select'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'

import { useTheme } from 'theme'
import { translations } from 'locales/translations'
import type { ModelVendorPreviewArticlesSearchSearchArticlesByEnum } from 'libs/openapiGeneratedApi/Api'

import type { ChakraSelectStyles, MyOption } from 'app/components/inputs/Select'
import {
  makeChakraStyles,
  makeDropdownIndicatorStyles,
  makeControlStyles,
  useCustomComponentsFactory,
  makeOptionStyles,
  makeMenuStyles,
} from 'app/components/inputs/Select'
import { Typography } from 'app/components/Typography'
import { useCustom1310Breakpoint } from 'app/components/layout/Layout/components/Header/CustomerNav/useCustom1310Breakpoint'
import { SearchSettingsIcon } from 'app/components/icons'

import { useSearchPresetSelect } from './useSearchPresetSelect'
import { SearchPresetTooltip } from './components'

const messages = translations.components.searchBox.presetSelect

export const useChakraStyles = ({
  controlHeight,
}: {
  controlHeight?: CSSProperties['height']
} = {}): ChakraSelectStyles => {
  const { colors } = useTheme()
  const [isBelow1310px] = useCustom1310Breakpoint()

  return {
    ...makeChakraStyles(),
    dropdownIndicator: (_, { selectProps }) =>
      isBelow1310px
        ? { display: 'none' }
        : {
            ...makeDropdownIndicatorStyles({
              isOpen: selectProps.menuIsOpen,
            }),
            padding: '0 8px 0 0',
          },
    menu: (provided) => ({
      ...makeMenuStyles(provided),
      minWidth: '320px',
    }),
    control: (_, { selectProps }) => {
      const isOpen = selectProps.menuIsOpen

      return {
        ...makeControlStyles(),
        height: controlHeight ?? '36px',
        ...(isOpen && { backgroundColor: colors.backgroundE }),
        ':hover:not([disabled]), :focus, :active': {
          backgroundColor: colors.backgroundE,
        },
      }
    },
    option: () => ({
      ...makeOptionStyles(),
      padding: '14px 16px',
    }),
    valueContainer: (provided) => ({ ...provided, p: '0 6px' }),
  }
}

export const useSelectComponents = (): Partial<
  SelectComponentsConfig<MyOption, false, GroupBase<MyOption>>
> => {
  const { t } = useTranslation()
  const [isBelow1310px] = useCustom1310Breakpoint()

  const makeCustomComponents = useCustomComponentsFactory()
  const { options } = useSearchPresetSelect()

  return {
    ...makeCustomComponents(),
    SingleValue: ({ children, ...props }) => {
      const typedValue = props.selectProps.value as unknown as {
        id: ModelVendorPreviewArticlesSearchSearchArticlesByEnum
      }
      const shortLabel = options.find(
        ({ id }) => id === typedValue.id,
      )?.shortLabel

      return (
        <chakraComponents.SingleValue {...props}>
          <SearchPresetTooltip content={children}>
            {isBelow1310px ? <SearchSettingsIcon /> : shortLabel}
          </SearchPresetTooltip>
        </chakraComponents.SingleValue>
      )
    },
    MenuList: ({ children, ...props }) => (
      <>
        <chakraComponents.MenuList {...props}>
          <Typography p="8px 16px" textStyle="tabLabelTextSb">
            {t(messages.menu.label)}
          </Typography>
          <Box data-testid="searchPresetOptions">{children}</Box>
        </chakraComponents.MenuList>
      </>
    ),
  }
}
