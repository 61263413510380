export const dimensionsRaw = {
  contentPaddingX: 32,
  contentPaddingY: 40,
  backofficeContentMaxWidth: 1560,
  customerContentMaxWidth: 1640,
  narrowContentMaxWidth: 576,
  wideContentMaxWidth: 960,
  mobileHeaderHeight: 52,
  mobileHeaderHeightWithSearch: 100,
  tabletHeaderHeight: 52,
  tabletHeaderHeightWithSearch: 112,
  headerHeight: 64,
  customerDesktopHeaderHeight: 120,
  impersonationBarHeight: 52,
  footerPadding: 32,
  mobileFooterPaddingBottom: 94,
  sideNavigationMaxWidth: 284,
} as const

export const dimensions = {
  contentPadding: `${dimensionsRaw.contentPaddingY}px ${dimensionsRaw.contentPaddingX}px`,
  contentPaddingMobile: `28px 8px`,
  contentPaddingMobileWide: `28px 16px`,
  backofficeContentMaxWidth: `${
    dimensionsRaw.backofficeContentMaxWidth + 2 * dimensionsRaw.contentPaddingX
  }px`,
  customerContentMaxWidth: `${
    dimensionsRaw.customerContentMaxWidth + 2 * dimensionsRaw.contentPaddingX
  }px`,
  narrowContentMaxWidth: `${dimensionsRaw.narrowContentMaxWidth}px`,
  wideContentMaxWidth: `${dimensionsRaw.wideContentMaxWidth}px`,
  mobileHeaderHeight: `${dimensionsRaw.mobileHeaderHeight}px`,
  mobileHeaderHeightWithSearch: `${dimensionsRaw.mobileHeaderHeightWithSearch}px`,
  headerHeight: `${dimensionsRaw.headerHeight}px`,
  customerDesktopHeaderHeight: `${dimensionsRaw.customerDesktopHeaderHeight}px`,
  impersonationBarHeight: `${dimensionsRaw.impersonationBarHeight}px`,
  footerPadding: `${dimensionsRaw.footerPadding / 2}px ${dimensionsRaw.footerPadding}px`,
  mobileFooterPaddingBottom: `${dimensionsRaw.mobileFooterPaddingBottom}px`,
} as const
