import type { IconProps } from '../types'

export const UsersIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7a3 3 0 0 1 6 0v1a3 3 0 0 1-6 0V7Zm10.5-2A2.5 2.5 0 0 0 12 7.5v1a2.5 2.5 0 0 0 5 0v-1A2.5 2.5 0 0 0 14.5 5Zm-2.428 6.898a2.75 2.75 0 0 1 .659-.377c.519.304 1.124.479 1.769.479.645 0 1.25-.175 1.77-.48A2.759 2.759 0 0 1 18 14.08a.92.92 0 0 1-.92.92H13v-.54c0-.966-.345-1.863-.928-2.562ZM12 14.459c0-.555-.152-1.081-.421-1.533L12 14.46Zm-.421-1.533a2.998 2.998 0 0 0-1.99-1.408l-.507-.102C8.475 11.786 7.762 12 7 12a3.981 3.981 0 0 1-2.082-.584l-.506.102A3 3 0 0 0 2 14.459V15a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.54"
      fill={color}
    />
  </svg>
)
