import { faker } from '@faker-js/faker'

import type { ModelGetFastOrderListData } from 'libs/openapiGeneratedApi/Api'

type ScanningItem = ModelGetFastOrderListData['items'][number]

const baseArticleMockData = {
  manufacturerName: 'ACME Inc.',
  manufacturerArticleNumber: 'kal-3',
  vendorName: 'Hoffmann Group',
  matchedField: 'CUSTOMER_ARTICLE_NUMBER',
  originalQuantity: 7,
  minimumOrder: 1,
  quantityInterval: 1,
  available: true,
  restricted: false,
  orderUnit: 'PCE',
} as const

export const firstSelectedArticleMockData = {
  ...baseArticleMockData,
  id: 'a53c3e16-be3b-4de4-875a-f70754609505',
  articleId: '025aed44-5bf6-4d98-a2dd-4443579199bb',
  articleNumber: '5080824',
  name: '3-Monats-Tisch-Kalender JK2 Rahmen weiß 110 mm x 180 mm',
  ean: '4010730011647',
  customerArticleNumber: '1573824',
  matchedField: 'EXTERNAL_REFERENCE',
} as const

export const firstItemWithSelectedArticle = {
  id: faker.string.uuid(),
  barcode: '12345678;8',
  lastAddedAt: '2024-01-04T17:32:28.682Z',
  selectedArticle: 'a53c3e16-be3b-4de4-875a-f70754609505',
  quantity: 7,
  scanCount: 10,
  articleSearchDone: true,
  foundArticles: [firstSelectedArticleMockData],
}

export const firstItemWithoutFoundArticles: ScanningItem = {
  id: faker.string.uuid(),
  barcode: '11111',
  scanCount: 1,
  lastAddedAt: '2024-01-04T21:30:29.134335Z',
  selectedArticle: undefined,
  quantity: undefined,
  articleSearchDone: true,

  foundArticles: [],
}

const secondItemWithSelectedArticle: ScanningItem = {
  id: faker.string.uuid(),
  barcode: '798564;8',
  lastAddedAt: '2024-01-01T17:32:28.682Z',
  selectedArticle: '22445b98-f632-4054-b30f-aa73bb1bf034',
  quantity: 1,
  scanCount: 2,
  articleSearchDone: true,
  foundArticles: [
    {
      ...baseArticleMockData,
      id: '22445b98-f632-4054-b30f-aa73bb1bf034',
      articleId: faker.string.uuid(),
      articleNumber: '145896',
      name: 'Desk Organiser S 15x23x38',
      ean: '4010730011648',
      customerArticleNumber: '79542',
    },
  ],
}

const itemWithoutSelectedArticleAndMultipleMatchingReferences: ScanningItem = {
  id: faker.string.uuid(),
  barcode: '56789;8',
  lastAddedAt: '2023-12-29T17:32:28.682Z',
  selectedArticle: undefined,
  quantity: 7,
  scanCount: 5,
  articleSearchDone: true,
  foundArticles: [
    {
      ...baseArticleMockData,
      id: faker.string.uuid(),
      articleId: faker.string.uuid(),
      articleNumber: '489642',
      name: 'Blue pen 8 items',
      ean: '4010730011649',
      customerArticleNumber: '78964',
      restricted: true,
    },
    {
      ...baseArticleMockData,
      id: faker.string.uuid(),
      articleId: faker.string.uuid(),
      articleNumber: '7896541',
      name: 'Blue ballpoint pen 100 items',
      ean: '4010730011649',
      customerArticleNumber: '743589615',
      originalQuantity: 10,
      matchedField: 'EAN',
    },
    {
      ...baseArticleMockData,
      id: faker.string.uuid(),
      articleId: faker.string.uuid(),
      articleNumber: '8934850',
      name: 'Electric desk 120x80',
      ean: '48204652405345',
      customerArticleNumber: '104856208',
      originalQuantity: 9,
      matchedField: 'EXTERNAL_REFERENCE',
    },
  ],
}

const secondItemWithoutFoundArticles: ScanningItem = {
  id: 'c2d69012-6eb6-4054-91db-67ce2b60a465',
  barcode: '123123',
  scanCount: 1,
  lastAddedAt: '2023-12-29T17:32:28.682Z',
  selectedArticle: undefined,
  quantity: undefined,
  articleSearchDone: true,
  foundArticles: [],
}

const itemWithSelectedArticleAndMultipleMatchingReferences: ScanningItem = {
  id: faker.string.uuid(),
  barcode: '48956;8',
  lastAddedAt: '2023-12-29T17:32:28.682Z',
  selectedArticle: 'a092f226-7189-4123-bed9-a0f41dbb5928',
  quantity: 5,
  scanCount: 6,
  articleSearchDone: true,
  foundArticles: [
    {
      ...baseArticleMockData,
      id: faker.string.uuid(),
      articleId: faker.string.uuid(),
      articleNumber: '7546321',
      name: 'Desk Lamp Silver',
      ean: '4010730011650',
      customerArticleNumber: '4678325',
    },
    {
      ...baseArticleMockData,
      id: 'a092f226-7189-4123-bed9-a0f41dbb5928',
      articleId: faker.string.uuid(),
      articleNumber: '5080428',
      name: 'Desk Lamp Silver M',
      ean: '4874627862467',
      manufacturerName: 'IKAE Inc.',
      customerArticleNumber: '7413357',
      matchedField: 'VENDOR_ARTICLE_NUMBER',
    },
  ],
}

const itemWithUnavailableSelectedArticleAndMultipleMatchingReferences: ScanningItem =
  {
    id: faker.string.uuid(),
    barcode: '47204753',
    lastAddedAt: '2023-12-29T17:32:28.682Z',
    selectedArticle: '22445b98-f632-4054-b30f-aa73bb1b4jf3',
    quantity: 1,
    scanCount: 1,
    articleSearchDone: true,
    foundArticles: [
      {
        ...baseArticleMockData,
        available: false,
        id: '22445b98-f632-4054-b30f-aa73bb1b4jf3',
        articleId: faker.string.uuid(),
        articleNumber: '439443',
        name: 'Mouse Pad S 35x50 Black',
        ean: '48305503857',
        customerArticleNumber: '59354',
      },
      {
        ...baseArticleMockData,
        available: true,
        id: faker.string.uuid(),
        articleId: faker.string.uuid(),
        articleNumber: '47896',
        name: 'Mouse Pad S 35x50 Blue',
        ean: '4789615',
        customerArticleNumber: '15631',
      },
    ],
  }

const itemWithRestrictedSelectedArticleAndMultipleMatchingReferences: ScanningItem =
  {
    id: faker.string.uuid(),
    barcode: '7894563',
    lastAddedAt: '2023-12-29T17:32:28.682Z',
    selectedArticle: '4e52db07-1d6e-4073-9c11-4925e1eca145',
    quantity: 1,
    scanCount: 1,
    articleSearchDone: true,
    foundArticles: [
      {
        ...baseArticleMockData,
        restricted: true,
        id: '4e52db07-1d6e-4073-9c11-4925e1eca145',
        articleId: faker.string.uuid(),
        articleNumber: '78945',
        name: 'Desktop chair blue',
        ean: '7896452789',
        customerArticleNumber: '78541',
      },
      {
        ...baseArticleMockData,
        restricted: false,
        id: faker.string.uuid(),
        articleId: faker.string.uuid(),
        articleNumber: '47896',
        name: 'Desktop chair blue M',
        ean: '741625',
        customerArticleNumber: '74126',
      },
    ],
  }

const itemWithOnlyRestrictedFoundArticles: ScanningItem = {
  id: faker.string.uuid(),
  barcode: '78594',
  lastAddedAt: '2023-12-29T17:32:28.682Z',
  selectedArticle: undefined,
  scanCount: 1,
  articleSearchDone: true,
  foundArticles: [
    {
      ...baseArticleMockData,
      restricted: true,
      id: 'ed77e82a-2bc2-42bd-a7c0-d9d8cc1c04da',
      articleId: faker.string.uuid(),
      articleNumber: '47896',
      name: 'Coffee mug black',
      ean: '74563214',
      customerArticleNumber: '745631',
    },
    {
      ...baseArticleMockData,
      restricted: true,
      id: faker.string.uuid(),
      articleId: faker.string.uuid(),
      articleNumber: '47896',
      name: 'Coffee mug blue',
      ean: '46321',
      customerArticleNumber: '47896',
    },
  ],
}

export const fastOrderListResultsMockData: ModelGetFastOrderListData = {
  items: [
    firstItemWithSelectedArticle,
    firstItemWithoutFoundArticles,
    secondItemWithSelectedArticle,
    itemWithoutSelectedArticleAndMultipleMatchingReferences,
    itemWithSelectedArticleAndMultipleMatchingReferences,
    secondItemWithoutFoundArticles,
    itemWithUnavailableSelectedArticleAndMultipleMatchingReferences,
    itemWithRestrictedSelectedArticleAndMultipleMatchingReferences,
    itemWithOnlyRestrictedFoundArticles,
  ],
}
