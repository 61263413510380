import { useEffect } from 'react'
import { isIOS } from 'react-device-detect'
import * as Sentry from '@sentry/react'

export const useCaptureSentryExceptionForIOS = () => {
  // INFO: visualViewport.scale returns the value of a pinch zoom
  const zoom = window.visualViewport?.scale ?? 1

  useEffect(() => {
    if (isIOS && zoom > 1) {
      Sentry.captureException(
        `Zoomed in scanner page was opened on iOS device; page pinch zoom: ${zoom}`,
      )
    }
  }, [zoom])
}
