import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

import { translations } from 'locales/translations'
import { includes } from 'types/utils'

const messages = translations.formFields.currency.options

export const SUPPORTED_CURRENCIES = [
  'EUR',
  'CNY',
  'CZK',
  'HUF',
  'PLN',
  'CHF',
  'GBP',
  'BGN',
  'DKK',
] as const

export type SupportedCurrency = (typeof SUPPORTED_CURRENCIES)[number]

export function throwIfUnsupportedCurrency(
  currency: string,
): asserts currency is SupportedCurrency {
  if (!includes(SUPPORTED_CURRENCIES, currency)) {
    throw new Error(`Unsupported currency: ${currency}`)
  }
}

type MessagesKey = keyof typeof messages

const isThereTranslationForCurrency = (
  currency: string,
): currency is MessagesKey => currency in messages

export const useCurrency = () => {
  const { t } = useTranslation()

  const getCurrencyLabel = useCallback(
    (currency?: string | null) => {
      if (!currency) return ''

      const label = isThereTranslationForCurrency(currency)
        ? t(messages[currency].label)
        : currency

      return label
    },
    [t],
  )

  const currencyOptions = SUPPORTED_CURRENCIES.map((currency) => ({
    id: currency,
    label: getCurrencyLabel(currency),
  }))

  return { getCurrencyLabel, currencyOptions }
}
