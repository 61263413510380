import type { IconProps } from '../types'

export const SupportIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M4 14.5H16L15.2514 16.3714C15.0996 16.751 14.7319 17 14.323 17H5.67703C5.26813 17 4.90042 16.751 4.74856 16.3714L4 14.5Z"
      fill={color}
    />
    <path
      d="M10 11.5H12.5C13.6046 11.5 14.5 10.6046 14.5 9.5V7"
      stroke={color}
    />
    <path
      d="M13.5 7C13.5 6.44772 13.9477 6 14.5 6H15C15.5523 6 16 6.44772 16 7V8C16 8.55228 15.5523 9 15 9H14.5C13.9477 9 13.5 8.55228 13.5 8V7Z"
      fill={color}
    />
    <path
      d="M4 7C4 6.44772 4.44772 6 5 6H5.5C6.05228 6 6.5 6.44772 6.5 7V8C6.5 8.55228 6.05228 9 5.5 9H5C4.44772 9 4 8.55228 4 8V7Z"
      fill={color}
    />
    <path
      d="M5.5 7V7C5.5 4.51472 7.51472 2.5 10 2.5V2.5C12.4853 2.5 14.5 4.51472 14.5 7V7"
      stroke={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C8.34315 4 7 5.34315 7 7V8C7 9.31133 7.84136 10.4262 9.0137 10.8341C9.09273 10.3608 9.50423 10 10 10H12.2361C12.7111 9.46924 13 8.76835 13 8V7C13 5.34315 11.6569 4 10 4Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 13L10 14.5L8.39373 12.0906C5.85998 12.3832 4 13.3511 4 14.5H10H16C16 13.8094 15.328 13.1842 14.2416 12.7318C13.8633 12.904 13.4428 13 13 13H11Z"
      fill={color}
    />
  </svg>
)
