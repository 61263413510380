import { Image } from '@chakra-ui/react'

import { useCompanyLogoFromProfileQuery } from 'app/containers/common/Profile'

export const LogoWithFallback = () => {
  const fallbackUrl = '/simplesystem-logo.svg'
  const { data: logoUrl, isPending } = useCompanyLogoFromProfileQuery()

  if (isPending) return null

  return (
    <Image src={logoUrl ?? fallbackUrl} objectFit="contain" height="24px" />
  )
}
