import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { forwardRef } from 'react'
import type { CustomContentProps } from 'notistack'
import { SnackbarContent, useSnackbar } from 'notistack'
import styled from '@emotion/styled'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'

import { Typography } from 'app/components/Typography'
import { CloseToastButton } from 'app/pages/common/marketplaceAndSearch/components/CartItemToast/components'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details
    .unavailableArticlesToast

const Container = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  gap: '20px',
  width: '100%',
  padding: '16px 20px',
  backgroundColor: theme.colors.yellow_100,
  border: 0,
  borderRadius: '6px',
}))

export const UnavailableArticlesToast = forwardRef<
  HTMLDivElement,
  CustomContentProps
>(({ id, style }, ref) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { closeSnackbar } = useSnackbar()

  return (
    <SnackbarContent ref={ref} style={style} onClick={() => closeSnackbar(id)}>
      <Container alignItems="center" gap="16px" width="100%">
        <WarningTwoIcon boxSize="20px" color={colors.contentA} />

        <Typography flexGrow={1}>{t(messages.content)}</Typography>

        <CloseToastButton />
      </Container>
    </SnackbarContent>
  )
})
