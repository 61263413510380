import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'

const messages = translations.pages.customer.search.searchArticle

type ArticleEanTextProps = { ean: string } & TypographyProps

export const ArticleEanText = ({ ean, ...rest }: ArticleEanTextProps) => {
  const { t } = useTranslation()

  return (
    <Typography textStyle="textSmall" color="contentB" {...rest}>
      {t(messages.eanFootnote, { number: ean })}
    </Typography>
  )
}
