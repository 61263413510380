export class UndefinedParameterError extends Error {
  constructor() {
    super('Parameter is not defined')
  }
}

export class NullParameterError extends Error {
  constructor() {
    super('Parameter is null')
  }
}

export class NullishParameterError extends Error {
  constructor() {
    super('Parameter is not defined or null')
  }
}
