import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

export const ImageWrapper = ({ onClick, ...props }: FlexProps) => (
  <Flex
    height="100%"
    alignItems="center"
    justifyContent="center"
    alignSelf="center"
    cursor={onClick ? 'pointer' : 'inherit'}
    onClick={onClick}
    {...props}
  />
)
