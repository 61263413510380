import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import type { Column } from 'react-table'

import { translations } from 'locales/translations'
import type { ModelGetArticleExternalReferencesData } from 'libs/openapiGeneratedApi/Api'

import { Typography } from 'app/components/Typography'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { ActionsCell } from './components'
import type {
  ExternalReferencesCellType,
  UseExternalReferencesColumnsProps,
} from './types'

const messages =
  translations.pages.customer.editArticleDetailsModal.externalReferences.table
    .headers

export const useExternalReferencesColumns = ({
  articleId,
}: UseExternalReferencesColumnsProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()

  return useMemo<
    Column<ModelGetArticleExternalReferencesData['items'][number]>[]
  >(
    () => [
      {
        Header: isMobile
          ? t(messages.externalReferenceMobile)
          : t(messages.externalReference),
        accessor: 'barcode',
        Cell: ({ value }) => (
          <Typography textStyle="textNormal">{value}</Typography>
        ),
        width: isMobile ? 150 : 200,
      },
      {
        Header: t(messages.quantity),
        accessor: 'quantity',
        Cell: ({ value }) => (
          <Typography textStyle="textNormal">{value}</Typography>
        ),
      },
      {
        id: 'actions',
        accessor: (originalRow) => originalRow,
        align: 'right',
        Cell: (value: ExternalReferencesCellType) => (
          <ActionsCell {...value} articleId={articleId} />
        ),
      },
    ],
    [articleId, isMobile, t],
  )
}
