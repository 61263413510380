import type { Styles } from '@chakra-ui/theme-tools'

import { mediaQuery } from './mediaQueries'

export const globalStyles: Styles = {
  global: {
    'html, body': {
      height: '100%',
      width: '100%',
      padding: 0,
      margin: 0,
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      textRendering: 'optimizeLegibility',
      '@supports (-webkit-overflow-scrolling: touch)': {
        cursor: 'pointer',
      },
    },

    html: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    body: {
      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",

      '&.fontLoaded': {
        fontFamily:
          "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      },
    },

    [mediaQuery.print]: {
      body: {
        zoom: '80%',
      },
    },

    '#root': {
      minHeight: '100%',
      minWidth: '100%',
      width: '100%',
      height: '100%',
      scrollBehavior: 'smooth',

      [mediaQuery.print]: {
        overflow: 'visible !important',
      },

      '> div:first-child': {
        minHeight: '100vh',
        height: 'fit-content',
      },
    },

    svg: { display: 'initial' },

    'p, label': {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      lineHeight: '1.5em',
    },

    'p, h1, h2, h3, h4, h5, h6': {
      margin: 0,
    },

    'input, select': {
      fontFamily: 'inherit',
      fontSize: 'inherit',
    },

    a: {
      textDecoration: 'none',
    },

    // hide the arrows in the input type number field
    'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },
    'input[type="number"]': {
      MozAppearance: 'textfield',
    },
  },
}
