import { api } from 'apiServices'

import type { MODEL__User, MODEL__UserSettings } from 'app/models/user'

import { apiWithoutNamespace } from './apiInstanceWithoutNamespace'
import { userMeScope } from './consts'

export const url = userMeScope

export const userMeRequest = () =>
  apiWithoutNamespace.get<unknown, MODEL__User>(url)

export const updateUserMeSettingsRequest = (
  data: Partial<MODEL__UserSettings>,
) => apiWithoutNamespace.patch<unknown, undefined>(url, { settings: data })

// OCI customer "special" endpoints
export const customerOciUrl = ''

export const ociCustomerMeRequest = () =>
  api.get<unknown, MODEL__User>(customerOciUrl)

export const updateOciCustomerMeSettingsRequest = (
  data: Partial<MODEL__UserSettings>,
) => api.patch<unknown, undefined>(customerOciUrl, { settings: data })
