import type { StackProps } from '@chakra-ui/react'
import { LinkBox, Stack } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { PopoverContentOverriden } from 'app/components/tooltips/PopoverContentOverriden'

export const PopoverContentStyled = styled(PopoverContentOverriden)(
  ({ theme }) => ({
    boxShadow: theme.shadows.primary,
    '&:focus': {
      boxShadow: theme.shadows.primary,
    },
  }),
)

export const LinkBoxStyled = styled(LinkBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '12px',
  ...theme.textStyles.textNormal,
  color: theme.colors.contentB,
  backgroundColor: theme.colors.white,
  border: theme.borders.primary,
  borderRadius: '6px',
}))

export const TextPaddingWrapper = (props: StackProps) => (
  <Stack p="8px 0px" {...props} />
)
