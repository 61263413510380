import type { SystemStyleObject } from '@chakra-ui/react'

import { theme } from 'theme'

export const selectTagStyles = {
  ...theme.textStyles.textSmallSb,
  boxShadow: '0px 1px 2px rgba(127, 158, 214, 0.35)',
  display: 'flex',
  alignItems: 'center',
  padding: '2px 6px',
  borderRadius: '6px',
  margin: '4px',
  background: theme.colors.white,
  color: theme.colors.contentA,
} satisfies SystemStyleObject
