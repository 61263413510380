import type { IconProps } from '../types'

export const QuestionIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9.05 12.556v-.872c0-.233.01-.428.03-.587.032-.169.08-.317.143-.443.074-.138.164-.264.27-.38.116-.128.253-.275.412-.445l1.22-1.236c.264-.264.397-.607.397-1.03 0-.412-.138-.745-.412-.999-.265-.264-.603-.396-1.015-.396-.444 0-.808.153-1.093.46-.286.296-.45.66-.492 1.093l-2.029-.158c.064-.507.196-.956.397-1.347.2-.402.46-.74.776-1.015.328-.274.703-.48 1.126-.618a4.197 4.197 0 0 1 1.394-.222c.476 0 .914.069 1.316.206.412.138.766.338 1.062.603.306.253.544.576.713.966.17.38.254.82.254 1.316 0 .349-.048.655-.143.92a2.99 2.99 0 0 1-.38.728 4.418 4.418 0 0 1-.555.634c-.2.201-.418.412-.65.634-.148.138-.275.26-.38.365a1.958 1.958 0 0 0-.27.333c-.063.105-.11.232-.142.38a2.297 2.297 0 0 0-.048.507v.603H9.049Zm-.318 2.203c0-.338.122-.629.365-.872.253-.243.554-.365.903-.365.338 0 .634.117.888.35.253.232.38.517.38.855 0 .338-.127.629-.38.872a1.208 1.208 0 0 1-.888.364c-.169 0-.333-.031-.491-.095a1.411 1.411 0 0 1-.397-.253 1.285 1.285 0 0 1-.285-.38 1.195 1.195 0 0 1-.095-.476Z"
      fill={color}
    />
  </svg>
)
