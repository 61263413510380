import { assertNever } from 'types/utils'

import type { UseSaveEditArticleModalProps } from './types'

export const useSaveEditArticleModal = ({
  editCustomerArticleNumberFormik,
  isUpdatingCustomerArticleNumber,
  setActiveTab,
  activeTab,
}: UseSaveEditArticleModalProps) => {
  const resetActiveTab = () => setActiveTab(undefined)

  const isSaveButtonDisabled = (() => {
    switch (activeTab) {
      case 'buyerArticleNumber':
        return (
          !editCustomerArticleNumberFormik.dirty ||
          isUpdatingCustomerArticleNumber
        )
      case 'externalReferences':
        return false
      default:
        return true
    }
  })()

  const onSaveHandler = async (closeModal: () => void) => {
    switch (activeTab) {
      case 'buyerArticleNumber':
        await editCustomerArticleNumberFormik.submitForm()
        break
      case 'externalReferences':
        closeModal()
        break
      default:
        assertNever(activeTab)
    }
    resetActiveTab()
  }

  return {
    onSaveHandler,
    isSaveButtonDisabled,
  }
}
