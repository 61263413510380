import type { GroupBase, SelectComponentsConfig } from 'chakra-react-select'
import { chakraComponents } from 'chakra-react-select'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'theme'
import { translations } from 'locales/translations'

import type { MyOption } from 'app/components/inputs/Select'
import { useCustomComponentsFactory } from 'app/components/inputs/Select'
import { Typography } from 'app/components/Typography'

const showPerPageMessage = translations.pagination.showPerPage

export const usePageSizeSelectComponents = (): Partial<
  SelectComponentsConfig<MyOption, false, GroupBase<MyOption>>
> => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const makeCustomComponents = useCustomComponentsFactory()

  return {
    ...makeCustomComponents(),
    SingleValue: ({ children, ...props }) => (
      <chakraComponents.SingleValue {...props}>
        <Typography textStyle="inputMediumButtonSb">
          <span>{t(showPerPageMessage)} </span>
          <span style={{ color: colors.blue_500 }}>{children}</span>
        </Typography>
      </chakraComponents.SingleValue>
    ),
  }
}
