import type { StackProps } from '@chakra-ui/react'
import { Stack, HStack, Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import type { TypographyProps } from '../Typography'
import { Typography } from '../Typography'

export type SectionHeaderProps = StackProps & {
  header?: ReactNode
  headerTestId?: string
  subHeader?: ReactNode
  description?: ReactNode
  rightSideRendered?: ReactNode
}

export const SectionHeader = ({
  header,
  headerTestId,
  subHeader,
  description,
  rightSideRendered,
  align,
  ...rest
}: SectionHeaderProps) => {
  const { isMobile } = useBreakpointScreenSize()

  return (
    <Stack w="100%" spacing="0" align={align} {...rest}>
      <HStack w="100%" justify={align ?? 'space-between'}>
        {header && (
          <Typography
            maxW="100%"
            textStyle="headerH3"
            //TODO: Remove margins and set min height on SectionHeader https://simplesystem.atlassian.net/browse/S2NG-8137
            my={isMobile ? '0px' : '8px'}
            data-testid={headerTestId}
          >
            {header}
          </Typography>
        )}

        {rightSideRendered && <Box flexShrink={0}>{rightSideRendered}</Box>}
      </HStack>

      {(subHeader || description) && (
        <Stack w="100%" spacing="16px" align={align}>
          {subHeader && (
            <Typography maxW="100%" textStyle="inputNormal" color="contentB">
              {subHeader}
            </Typography>
          )}

          {description && (
            <Typography maxW="100%" textStyle="textParagraph">
              {description}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  )
}
