import type { IconProps } from '../types'

export const TickIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M5 10L9 14L15.5 6" stroke={color} strokeWidth={2} />
  </svg>
)
