import type { ReactNode } from 'react'
import { Box, type TextProps } from '@chakra-ui/react'

import { Typography } from 'app/components/Typography'

type ValueRendererProps = {
  rowValues: ReactNode[]
  textAlign?: TextProps['textAlign']
  allowContentWrapping?: boolean
}

export const DisplayInfoRowValue = ({
  rowValues,
  textAlign,
  allowContentWrapping,
}: ValueRendererProps) => (
  <Box>
    {rowValues.map((val) => (
      <Typography
        key={String(val)}
        textAlign={textAlign}
        data-testid="infoRowValue"
        style={
          !allowContentWrapping
            ? {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            : {}
        }
      >
        {val || '–'}
      </Typography>
    ))}
  </Box>
)
