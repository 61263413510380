import { useParams } from 'react-router-dom'

import type { PlatformCatalogId } from '../common/Catalogs'

export const useCatalogId = () => {
  const params = useParams<{ catalogId?: PlatformCatalogId }>()

  if (!params.catalogId) throw new Error('catalogId is undefined')

  return params.catalogId
}
