import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import type { ButtonProps } from 'app/components/Buttons/Button'
import { Button } from 'app/components/Buttons/Button'
import { UploadIcon } from 'app/components/icons'

type FileInputProps = Omit<ButtonProps, 'onClick' | 'children'> & {
  onFileUpload: (file: File) => void
  accept?: string
  buttonMessage?: string
}

export const FileInput = ({
  onFileUpload,
  accept,
  buttonMessage,
  ...buttonProps
}: FileInputProps) => {
  const { t } = useTranslation()

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    hiddenFileInput.current?.click()
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0]

    if (uploadedFile) {
      onFileUpload(uploadedFile)
    }
  }

  return (
    <>
      <Button onClick={handleClick} leftIcon={<UploadIcon />} {...buttonProps}>
        {buttonMessage ?? t(translations.formActions.uploadFile)}
      </Button>

      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        value=""
        accept={accept}
        data-testid="hiddenFileInput"
      />
    </>
  )
}
