import type { IconProps } from '../types'

export const PrintIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 18C4.44772 18 4 17.5523 4 17V14H1V10C1 8.89543 1.89543 8 3 8H4V3C4 2.44771 4.44772 2 5 2H12V6L16 6L16 8H17C18.1046 8 19 8.89543 19 10V14H16V17C16 17.5523 15.5523 18 15 18L5 18ZM16 5L13 5V2L16 5ZM6 11H14V16H6V11ZM13 12H7V13H13V12ZM7 14H10V15H7V14ZM14.5 9C14.2239 9 14 9.22386 14 9.5C14 9.77614 14.2239 10 14.5 10H15.5C15.7761 10 16 9.77614 16 9.5C16 9.22386 15.7761 9 15.5 9H14.5Z"
      fill={color}
    />
  </svg>
)
