import { useTranslation } from 'react-i18next'
import { forwardRef, useState } from 'react'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import type { ModelSearchArticleItem } from 'app/containers/common/Search/types/response'
import {
  BulkPricesPopover,
  showBulkPricesModal,
} from 'app/pages/common/marketplaceAndSearch/components/BulkPrices'
import { useIsFetchingAnyExternalPrices } from 'app/containers/common/ExternalPrices'

const messages = translations.pages.customer.search.searchArticle

type ViewBulkPricesButtonProps = { article: ModelSearchArticleItem }

const StyledButton = forwardRef<
  HTMLParagraphElement,
  {
    onClick: () => void
    children: React.ReactNode
  }
>(({ onClick, children }, ref) => (
  <Typography
    as="button"
    onClick={onClick}
    textStyle="textSmallSb"
    color="contentA"
    _hover={{
      color: 'contentB',
    }}
    ref={ref}
  >
    {children}
  </Typography>
))

export const ViewBulkPricesButton = ({
  article: { orderUnit, orderOptions, name, needsExternalPrices },
}: ViewBulkPricesButtonProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined)
  const isFetchingExternalPrices = useIsFetchingAnyExternalPrices()

  const pricing = orderOptions?.pricing || []
  const hasBulkPrices = Number(pricing.length) > 1

  if (!hasBulkPrices || (isFetchingExternalPrices && needsExternalPrices)) {
    return null
  }

  const handleOnClick = () => {
    if (isMobile) {
      setIsOpen(false)

      showBulkPricesModal({
        articleName: name,
        pricing,
        orderUnit,
      })

      return
    }

    // INFO: For desktop, set state to undefined to show popover, use uncontrolled mode and allow closing on click outside
    setIsOpen(undefined)
  }

  return (
    <Flex justifyContent="end">
      <BulkPricesPopover
        pricing={pricing}
        orderUnit={orderUnit}
        isOpen={isOpen}
      >
        <StyledButton onClick={handleOnClick}>
          {t(messages.viewBulkPrices)}
        </StyledButton>
      </BulkPricesPopover>
    </Flex>
  )
}
