import type { IconProps } from '../types'

export const UsersBIcon = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="2" y="6" width="4" height="5" rx="2" fill={color} />
    <rect x="14" y="6" width="4" height="5" rx="2" fill={color} />
    <rect x="8" y="5" width="4" height="5" rx="2" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.39328 11.6574C5.14058 12.2197 5 12.8434 5 13.4999V13.9998H1.67318C1.30139 13.9998 1 13.6984 1 13.3266C1 12.492 1.51163 11.7538 2.27014 11.4512C2.75899 11.7968 3.35578 11.9998 4 11.9998C4.50293 11.9998 4.97695 11.8761 5.39328 11.6574Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6069 11.6574C14.8596 12.2197 15.0002 12.8434 15.0002 13.4999V13.9998H18.327C18.6988 13.9998 19.0002 13.6984 19.0002 13.3266C19.0002 12.492 18.4886 11.7538 17.7301 11.4512C17.2412 11.7968 16.6444 11.9998 16.0002 11.9998C15.4973 11.9998 15.0233 11.8761 14.6069 11.6574Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36004 10.5127L8.10517 10.5923C6.85275 10.9837 6 12.1436 6 13.4558V14.0002C6 14.5525 6.44772 15.0002 7 15.0002H13C13.5523 15.0002 14 14.5525 14 14.0002V13.4558C14 12.1436 13.1473 10.9837 11.8948 10.5923L11.64 10.5127C11.1686 10.821 10.6052 11.0002 10 11.0002C9.39476 11.0002 8.83138 10.821 8.36004 10.5127Z"
      fill={color}
    />
  </svg>
)
