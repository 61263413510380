import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { FileInput } from 'app/components/inputs/FileInput'
import { ContentWrapper } from 'app/components/layout/ContentWrapper'
import { EmptyListIcon } from 'app/components/icons'

import {
  FastOrderProvider,
  useFastOrderContext,
} from '../common/fastOrder/contexts/fastOrderContext'
import { FastOrderResults } from '../common/fastOrder/FastOrderResults'

import { useFastOrderFileUpload } from './useFastOrderFileUpload'
import { FastOrderInput } from './components'

const messages = translations.pages.customer.fastOrder

const FastOrderComponent = () => {
  const { t } = useTranslation()

  const { inputValue, setInputValue } = useFastOrderContext()

  const { isReadingFile, handleFileUpload } = useFastOrderFileUpload({
    setInputValue,
  })

  const fileInputCommonProps = {
    onFileUpload: handleFileUpload,
    isLoading: isReadingFile,
    accept: '.txt',
  }

  const emptyCaseWrapperProps = {
    message: t(messages.emptyCase.header),
    icon: <EmptyListIcon />,
    subHeader: t(messages.emptyCase.description),
    action: <FileInput variant="secondary" {...fileInputCommonProps} />,
  }

  return (
    <ContentWrapper size="wide" gap="20px" withViewportAsMaxHeight>
      <Flex gap="8px" alignContent="center" data-testid="searchSection">
        <FastOrderInput inputValue={inputValue} setInputValue={setInputValue} />
        <FileInput
          flexShrink={0}
          variant="tertiary"
          {...fileInputCommonProps}
        />
      </Flex>
      <FastOrderResults emptyCaseWrapperProps={emptyCaseWrapperProps} />
    </ContentWrapper>
  )
}

export const FastOrder = () => (
  <FastOrderProvider fastOrderListEnum="FAST_ORDER_LIST">
    <FastOrderComponent />
  </FastOrderProvider>
)
