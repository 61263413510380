import { useDeleteFastOrderListItemsMutation } from 'app/containers/customers/FastOrders/Delete'
import { useMoveFastOrderListItemsToCartMutation } from 'app/containers/customers/FastOrders/MoveToCart'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'

import {
  useGetShowMoveArticlesToCartSuccessToast,
  useShowDeleteArticleSuccessToast,
} from '../common'

export const useFastOrderListBulkActions = () => {
  const {
    fastOrderListEnum,
    selectedItemIds: fastOrderListItemIds,
    selectedItemsCount,
  } = useFastOrderContext()

  const { mutate: deleteFastOrderListItems, isPending: isDeleting } =
    useDeleteFastOrderListItemsMutation({ fastOrderListEnum })

  const { mutate: moveFastOrderItemsToCart, isPending: isMovingToCart } =
    useMoveFastOrderListItemsToCartMutation({
      fastOrderListEnum,
    })

  const showDeleteSuccessToast = useShowDeleteArticleSuccessToast({
    deletedArticlesCount: selectedItemsCount,
  })
  const showMoveToCartSuccessToast = useGetShowMoveArticlesToCartSuccessToast({
    movedToCartArticlesCount: selectedItemsCount,
  })

  const onDeleteHandler = () =>
    deleteFastOrderListItems(
      { fastOrderListItemIds },
      {
        onSuccess: showDeleteSuccessToast,
      },
    )

  const onMoveToCartHandler = () =>
    moveFastOrderItemsToCart(
      { fastOrderListItemIds },
      {
        onSuccess: showMoveToCartSuccessToast,
      },
    )

  return { onDeleteHandler, isDeleting, onMoveToCartHandler, isMovingToCart }
}
