import { useTranslation } from 'react-i18next'
import NiceModal from '@ebay/nice-modal-react'
import { useQueryParam } from 'use-query-params'

import { translations } from 'locales/translations'

import { ModalWithFooter } from 'app/components/modals/ModalWithFooter'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useGetArticleDetailsQuery } from 'app/containers/common/ArticleDetails'
import { useSaveEditArticleModal } from 'app/pages/common/editArticleModalFlow/EditArticleModal/useSaveEditArticleModal'
import { useCustomerArticleNumberDetailsQuery } from 'app/containers/customers/CustomerArticleNumber/Show/hooks'
import { useGetShowSuccessToast } from 'app/components/Notification/useGetShowSuccessToast'
import { EditArticleModalContent } from 'app/pages/common/editArticleModalFlow/EditArticleModal/EditArticleModalContent'
import { useHasPrivilegeAssigned } from 'app/containers/common/Me/CompanyUser'

import { ArticleContent } from '../ArticleContent'
import {
  EDIT_ARTICLE_MODAL_ID,
  hideEditArticleModal,
} from '../modalFlowActions'
import { useShouldShowArticleDetails } from '../useShouldShowArticleDetails'

import { useEditCustomerArticleNumberForm } from './EditCustomerArticleNumber/useEditCustomerArticleNumberForm'
import type { EditArticleModalProps, EditArticleTabKey } from './types'

const messages = translations.pages.customer.editArticleDetailsModal

const TAB_QUERY_PARAM = 'editArticleTab'

export const EditArticleModal = NiceModal.create<EditArticleModalProps>(
  ({ articleId }) => {
    const [activeTab = 'buyerArticleNumber', setActiveTab] = useQueryParam<
      EditArticleTabKey | undefined
    >(TAB_QUERY_PARAM, undefined, {
      updateType: 'replaceIn',
    })
    const { t } = useTranslation()
    const { showSuccessToast } = useGetShowSuccessToast()
    const { isMobile } = useBreakpointScreenSize()
    const shouldShowArticleDetails = useShouldShowArticleDetails()
    const { data: articleDetailsData, isFetching: areArticleDetailsFetching } =
      useGetArticleDetailsQuery({
        articleId,
      })
    const canEditCustomerArticleNumbers = useHasPrivilegeAssigned(
      'EDIT_CUSTOMER_ARTICLE_NUMBERS',
    )
    const { data: customerArticleNumberData } =
      useCustomerArticleNumberDetailsQuery({
        articleId,
        enabled: canEditCustomerArticleNumbers,
      })

    const onSaveSuccess = () => {
      showSuccessToast({ content: t(messages.saveSuccessMessage) })
      hideEditArticleModal()
      setActiveTab(undefined)
    }

    const {
      formik: editCustomerArticleNumberFormik,
      isPending: isUpdatingCustomerArticleNumber,
    } = useEditCustomerArticleNumberForm({
      articleId,
      customerArticleNumber: customerArticleNumberData,
      onSuccess: onSaveSuccess,
    })

    const { onSaveHandler, isSaveButtonDisabled } = useSaveEditArticleModal({
      editCustomerArticleNumberFormik,
      setActiveTab,
      activeTab,
      isUpdatingCustomerArticleNumber,
    })

    return (
      <ModalWithFooter
        isWithoutHeaderBorder
        isFullScreen={isMobile}
        isWithXButton={isMobile}
        id={EDIT_ARTICLE_MODAL_ID}
        header={t(messages.header)}
        isSaveButtonLoading={isUpdatingCustomerArticleNumber}
        subHeader={t(messages.subheader)}
        onSaveHandler={onSaveHandler}
        shouldHideCancelButton={activeTab === 'externalReferences'}
        saveTranslation={
          activeTab === 'externalReferences'
            ? t(translations.formActions.ok)
            : t(translations.formActions.save)
        }
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {shouldShowArticleDetails && articleDetailsData && (
          <ArticleContent {...articleDetailsData} />
        )}
        <EditArticleModalContent
          articleId={articleId}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          shouldShowLoader={areArticleDetailsFetching}
          editCustomerArticleNumberFormik={editCustomerArticleNumberFormik}
        />
      </ModalWithFooter>
    )
  },
)

NiceModal.register(EDIT_ARTICLE_MODAL_ID, EditArticleModal)
