import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'

import { StickyScanningResultsInfoWrapper } from './styles'
import { DrawerButton } from './DrawerButton'
import { ScanningResultsHeader } from './ScanningResultsHeader'

const messages = translations.pages.customer.scanner.mobile.drawerMainBar

type StickyScanningResultsInfoProps = { onClick: () => void }

const StickyScanningResultsInfoContent = () => {
  const { t } = useTranslation()

  const { fastOrderList, isFastOrderListPending } = useFastOrderContext()

  const scannedItemsLength = fastOrderList?.items.length ?? 0

  if (isFastOrderListPending) return null

  if (scannedItemsLength === 0)
    return <Typography color="contentB">{t(messages.emptyInfo)}</Typography>

  return (
    <Flex flexDirection="column">
      <ScanningResultsHeader textStyle="textNormalSb" />

      <Typography textStyle="textSmall" color="contentB">
        {t(translations.words.article, { count: scannedItemsLength })}
      </Typography>
    </Flex>
  )
}

export const StickyScanningResultsInfo = ({
  onClick,
}: StickyScanningResultsInfoProps) => (
  <StickyScanningResultsInfoWrapper
    onClick={onClick}
    data-testid="scanningResultsInfo"
  >
    <StickyScanningResultsInfoContent />

    <DrawerButton />
  </StickyScanningResultsInfoWrapper>
)
