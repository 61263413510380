import { useRouteMatch } from 'react-router-dom'

import { WIDER_MOBILE_SPACING_PAGES_LIST } from './consts'

export const useShouldApplyWiderMobileSpacing = () => {
  const { path } =
    useRouteMatch({ path: WIDER_MOBILE_SPACING_PAGES_LIST, exact: true }) || {}

  if (path) {
    return true
  }

  return false
}
