import { usePrevious } from 'react-use'
import { useEffect } from 'react'

import { BasicAmountInput } from '../BasicAmountInput'

import { useInMemoryAmountHandlers } from './useAmountHandlers/useInMemoryAmountHandlers'
import type { InMemoryAmountInputProps } from './types'

export const InMemoryAmountInput = (props: InMemoryAmountInputProps) => {
  const { quantityInterval, minimumOrder, quantity, setQuantity } = props
  const {
    shownValue,
    changeHandler,
    plusHandler,
    minusHandler,
    blurHandler,
    keyUpHandler,
    setShownValue,
  } = useInMemoryAmountHandlers({
    quantityInterval,
    minimumOrder,
    quantity,
    setQuantity,
  })

  /**
   * Reset shownValue when quantity changes (after successful API call counter should reset)
   */
  const previousQuantity = usePrevious(quantity)
  useEffect(() => {
    if (previousQuantity !== quantity && quantity !== shownValue) {
      setShownValue(quantity)
    }
  }, [previousQuantity, quantity, setShownValue, shownValue])

  return (
    <BasicAmountInput
      {...props}
      shownValue={shownValue}
      changeHandler={changeHandler}
      plusHandler={plusHandler}
      minusHandler={minusHandler}
      blurHandler={blurHandler}
      keyUpHandler={keyUpHandler}
    />
  )
}
