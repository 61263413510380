import { isFirefox } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { IS_TEST } from 'consts/envVars'

import { PrintoutIfElse } from 'app/components/printoutUtils/PrintoutIfElse'
import { Typography } from 'app/components/Typography'

import { PrintingContextProvider } from './printingContext'
import { PrintoutVisibilityContextProvider } from './printoutVisibilityContext'

const messages = translations.components.printPageLogicWrapper

export const PrintPageLogicWrapper = ({
  Page,
}: {
  Page: () => JSX.Element
}) => {
  const { t } = useTranslation()

  if (IS_TEST) return <Page />

  return (
    <PrintoutVisibilityContextProvider>
      {({ isPrintoutVisible }) => (
        <PrintoutIfElse
          printChildren={
            <>
              {isFirefox && !isPrintoutVisible && (
                <Typography textAlign="center" textStyle="headerH2">
                  {t(messages.firefoxFallback.title)}
                </Typography>
              )}

              <PrintingContextProvider>
                {isPrintoutVisible && <Page />}
              </PrintingContextProvider>
            </>
          }
          nonPrintChildren={<Page />}
        />
      )}
    </PrintoutVisibilityContextProvider>
  )
}
