import { Trans, useTranslation } from 'react-i18next'
import { Flex, Grid } from '@chakra-ui/react'
import styled from '@emotion/styled'
import type { CustomContentProps } from 'notistack'
import { SnackbarContent, useSnackbar } from 'notistack'
import { forwardRef } from 'react'

import { translations } from 'locales/translations'
import { colors as themeColors } from 'theme/colors'

import { ImageWithFallback } from 'app/components/ImageWithFallback'
import { Typography } from 'app/components/Typography'
import {
  CircleBadge,
  CloseToastButton,
} from 'app/pages/common/marketplaceAndSearch/components/CartItemToast/components'
import { SemiBoldSpan } from 'app/components/spans'
import { PlusIcon } from 'app/components/icons'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details
    .addedToCartToast

const transComponents = [<SemiBoldSpan />]

export const IMAGES_COUNT = 4

export const ToastContainer = styled(Flex)(({ theme: { colors } }) => ({
  gap: '20px',
  alignItems: 'center',
  width: '100%',
  padding: '8px 20px 8px 8px',
  backgroundColor: colors.backgroundA,
}))

export const ImagesGrid = styled(Grid)(() => ({
  padding: '4px',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gridGap: '4px',
}))

export const PlaceholderImage = styled('div')(({ theme: { colors } }) => ({
  width: '24px',
  height: '24px',
  background: colors.backgroundD,
  borderRadius: '6px',
}))

type AddedToCartToastProps = CustomContentProps & {
  articleImages: Array<string | undefined>
  articlesNumber: number
  vendorsNumber: number
}

export const AddedToCartToast = forwardRef<
  HTMLDivElement,
  AddedToCartToastProps
>(({ articleImages, articlesNumber, vendorsNumber, id, style }, ref) => {
  const { t } = useTranslation()
  const { closeSnackbar } = useSnackbar()

  return (
    <SnackbarContent ref={ref} style={style} onClick={() => closeSnackbar(id)}>
      <ToastContainer data-testid="articlesAddedToCartToast">
        <Flex position="relative">
          <ImagesGrid>
            {Array.from('x'.repeat(IMAGES_COUNT)).map((_val, index) => (
              <ImageWithFallback
                key={index}
                src={articleImages[index]}
                size={24}
                // INFO: Styled components types are not compatible with the functional component definition
                // but it works just fine
                /* @ts-ignore */
                PlaceholderIcon={
                  index > articleImages.length - 1
                    ? PlaceholderImage
                    : undefined
                }
              />
            ))}
          </ImagesGrid>
          <CircleBadge>
            <PlusIcon width="16px" height="16px" color={themeColors.white} />
          </CircleBadge>
        </Flex>

        <Flex flexDirection="column" gap="4px" justify="center" flexGrow={1}>
          <Typography
            noOfLines={1}
            textStyle="inputNormal"
            data-testid="toastTitle"
          >
            <Trans i18nKey={messages.title} components={transComponents} />
          </Typography>

          <Typography color="contentB" data-testid="toastContent">
            {t(messages.subtitle, {
              articlesCounts: t(translations.words.article, {
                count: articlesNumber,
              }),
              vendorsCount: t(translations.words.vendors, {
                count: vendorsNumber,
              }),
            })}
          </Typography>
        </Flex>

        <CloseToastButton />
      </ToastContainer>
    </SnackbarContent>
  )
})
