import {
  Box,
  Modal,
  ModalBody,
  useDisclosure,
  ModalHeader,
  Flex,
  ModalFooter,
  Stack,
} from '@chakra-ui/react'
import { SmallCloseIcon } from '@chakra-ui/icons'

import { useTheme } from 'theme'

import { Button } from 'app/components/Buttons/Button'
import { Typography } from 'app/components/Typography'

import { ModalContentStyled } from './styles'
import type { MobileButtonWithFullModalProps } from './types'
import { useOnMountCallback, useCloseOnPathnameChange } from './hooks'

export const MobileButtonWithFullModal = ({
  footer,
  title,
  withCloseButton = true,
  children,
  openModalButton,
  openModalButtonText,
  onMount,
  onCloseComplete,
  bodyPadding = '16px',
  additionalHeaderContent,
  ...props
}: MobileButtonWithFullModalProps) => {
  const {
    onOpen: open,
    onToggle: toggle,
    onClose: close,
    isOpen,
  } = useDisclosure()

  const { borders } = useTheme()

  useOnMountCallback({ isOpen, onMount })
  useCloseOnPathnameChange({ close })

  return (
    <>
      {typeof openModalButton === 'function' ? (
        openModalButton({ open, toggle, close, isOpen })
      ) : (
        <Box>
          <Button onClick={open} variant="secondary">
            {openModalButtonText}
          </Button>
        </Box>
      )}

      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={close}
        size="full"
        onCloseComplete={onCloseComplete}
      >
        <ModalContentStyled p={bodyPadding} {...props}>
          {title && (
            <ModalHeader
              p="0"
              style={{ paddingBottom: '16px' }}
              as={Stack}
              spacing="20px"
              borderBottom={borders.primary}
            >
              <Flex justify="space-between" align="center" gap="8px">
                <Typography textStyle="headerH3">{title}</Typography>

                {withCloseButton && (
                  <Button
                    onClick={close}
                    variant="tertiary"
                    padding="8px"
                    leftIcon={<SmallCloseIcon boxSize="20px" />}
                    mobileIconOnly
                  />
                )}
              </Flex>
              {additionalHeaderContent}
            </ModalHeader>
          )}

          <ModalBody p="0">{children}</ModalBody>

          {footer && (
            <>
              <ModalFooter
                p="0"
                style={{ paddingTop: '16px' }}
                borderTop={borders.primary}
              >
                {footer({ close })}
              </ModalFooter>
            </>
          )}
        </ModalContentStyled>
      </Modal>
    </>
  )
}
