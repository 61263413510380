import { Flex } from '@chakra-ui/react'

import { colors } from 'theme/colors'

type CircleBadgeProps = {
  children?: React.ReactNode
  color?: string
}

export const CircleBadge = ({ children, color }: CircleBadgeProps) => (
  <Flex
    width="16px"
    height="16px"
    borderRadius="50%"
    border="1px solid"
    borderColor={colors.white}
    backgroundColor={color ?? colors.green_600}
    justifyContent="center"
    alignItems="center"
    position="absolute"
    left="calc(100% - 8px)"
  >
    <Flex alignItems="center" justifyContent="center">
      {children}
    </Flex>
  </Flex>
)
