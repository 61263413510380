import type { InternalAxiosRequestConfig } from 'axios'

import { getConfiguredAxiosInstance } from 'libs/request'
import { withRefreshToken } from 'libs/request/utils/handleUnauthorizedRequest'
import { store as appStore } from 'store'
import { IS_TEST } from 'consts/envVars'
import { addAuthData } from 'apiServices/requestInterceptors/addAuthData'

export const createRequestInterceptorWithTokenOnly =
  (store = appStore) =>
  (request: InternalAxiosRequestConfig) => {
    addAuthData(request, store)

    return request
  }

export const apiWithoutNamespace = getConfiguredAxiosInstance()

export const apiWithoutNamespaceDefaultRequestInterceptor =
  apiWithoutNamespace.interceptors.request.use(
    createRequestInterceptorWithTokenOnly(),
    undefined,
    // INFO: We don't want to run this interceptor for the tests
    // cause we inject the same interceptor manually for the tests
    // with the proper redux store
    { runWhen: () => IS_TEST !== true },
  )
withRefreshToken(apiWithoutNamespace)
