import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction } from '@reduxjs/toolkit'

import { createSlice } from 'utils/@reduxjs/toolkit'

import type { MODEL__JWTTokenResponse } from 'app/models/tokenResponse'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'app/models/tokenResponse'
import type { VerificationUserId } from 'app/containers/models/companyRegistration'

import type { AuthState, ImpersonationState } from './slice.types'
import { AUTH_REDUCER_NAME } from './consts'

export const initialState: AuthState = {
  tokens: {
    [ACCESS_TOKEN_KEY]: null,
    [REFRESH_TOKEN_KEY]: null,
  },
  selectedCompanyId: null,
  userRole: null,
  isUserInitialized: false,
  verificationUserId: null,
  ociSessionId: null,
  impersonation: null,
}

export const authSlice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<MODEL__JWTTokenResponse>) => {
      state.tokens = {
        [ACCESS_TOKEN_KEY]: action.payload.token,
        [REFRESH_TOKEN_KEY]: action.payload.refreshToken,
      }
    },
    setVerificationUserId: (
      state,
      action: PayloadAction<VerificationUserId>,
    ) => {
      state.verificationUserId = action.payload
    },
    setUserRole: (state, action: PayloadAction<AuthState['userRole']>) => {
      state.userRole = action.payload
    },
    setCompanyIdAndRole: (
      state,
      action: PayloadAction<Pick<AuthState, 'selectedCompanyId' | 'userRole'>>,
    ) => {
      state.selectedCompanyId = action.payload.selectedCompanyId ?? null
      state.userRole = action.payload.userRole ?? null
    },
    setIsUserInitialized: (state, action: PayloadAction<boolean>) => {
      state.isUserInitialized = action.payload
    },
    setOciSessionId: (
      state,
      action: PayloadAction<AuthState['ociSessionId']>,
    ) => {
      state.ociSessionId = action.payload
    },
    setImpersonationData: (
      state,
      action: PayloadAction<ImpersonationState | null>,
    ) => {
      state.impersonation = action.payload
    },
    resetWithTokens: (state) => ({ ...initialState, tokens: state.tokens }),
  },
})

export const {
  setAuthData,
  setVerificationUserId,
  setUserRole,
  setCompanyIdAndRole,
  setIsUserInitialized,
  setImpersonationData,
  resetWithTokens,
  setOciSessionId,
} = authSlice.actions
export const { reducer: authReducer } = authSlice

export const logout = createAction('AUTH/logout')
