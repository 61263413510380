import { PATHS } from 'consts/paths'
import { translations } from 'locales/translations'

import {
  CompanyIconB,
  ShippingIcon,
  UsersBIcon,
  UsersIcon,
} from 'app/components/icons'

const messages = translations.pages.customer.companyAndSettings

export const deliveryAddressesLinkConfig = {
  titleMsg: messages.deliveryAddresses.header,
  icon: <ShippingIcon />,
  path: PATHS.customer.companyAndSettings.deliveryAddresses,
  dataCy: 'deliveryAddresses',
}

export const companySettingsSection = {
  titleMsg: messages.companySettings.header,
  options: [
    {
      titleMsg: messages.companyProfile.header,
      icon: <CompanyIconB />,
      path: PATHS.customer.companyAndSettings.myCompany,
      dataCy: 'companyProfile',
    },
    {
      titleMsg: messages.userManagement.header,
      icon: <UsersIcon />,
      path: PATHS.customer.companyAndSettings.usersManagement,
      dataCy: 'usersManagement',
    },
    {
      titleMsg: messages.groupManagement.header,
      icon: <UsersBIcon />,
      path: PATHS.customer.companyAndSettings.groupsManagement,
      dataCy: 'groupsManagement',
    },
    deliveryAddressesLinkConfig,
  ],
}
