import { generatePath } from 'react-router'

import { publicApi } from 'apiServices'

import { invitationsPath } from '../consts'

import type { API__GetInvitationStatusRequestArgs } from './types'
import type { MODEL__InvitationState } from './models'

export const url = invitationsPath

export const getInvitationStateRequest = ({
  invitationKey,
}: API__GetInvitationStatusRequestArgs) =>
  typeof invitationKey === 'undefined'
    ? Promise.reject(new Error('Missing invitationKey'))
    : publicApi.get<unknown, MODEL__InvitationState>(
        generatePath(url, { invitationKey }),
      )
