import type { ReactNode } from 'react'
import React, { createContext, useContext, useState } from 'react'

import type { ModelGetFastOrderListParamsEnum } from 'libs/openapiGeneratedApi/Api'

import { useFastOrderListQuery } from 'app/containers/customers/FastOrders/List'

import { useFastOrderItemsSelection } from '../useFastOrderItemsSelection'
import { getRefetchInterval } from '../utils'

const useFastOrderContextValue = (
  fastOrderListEnum: ModelGetFastOrderListParamsEnum,
) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)

  const { data: fastOrderList, isPending: isFastOrderListPending } =
    useFastOrderListQuery({
      fastOrderListEnum,
      refetchInterval: getRefetchInterval,
    })

  const fastOrderListSelectionStateAndActions =
    useFastOrderItemsSelection(fastOrderListEnum)

  return {
    fastOrderListEnum,
    inputValue,
    setInputValue,
    fastOrderList,
    isFastOrderListPending,
    ...fastOrderListSelectionStateAndActions,
  }
}

export const FastOrderContext = createContext<
  ReturnType<typeof useFastOrderContextValue> | undefined
>(undefined)

interface FastOrderProviderProps {
  fastOrderListEnum: ModelGetFastOrderListParamsEnum
  children: ReactNode
}

export const FastOrderProvider: React.FC<FastOrderProviderProps> = ({
  children,
  fastOrderListEnum,
}) => {
  const fastOrderContextValue = useFastOrderContextValue(fastOrderListEnum)

  return (
    <FastOrderContext.Provider value={fastOrderContextValue}>
      {children}
    </FastOrderContext.Provider>
  )
}

// Create a custom hook to consume the context
export const useFastOrderContext = () => {
  const context = useContext(FastOrderContext)

  if (!context) {
    throw new Error(
      'useFastOrderContext must be used within a FastOrderProvider',
    )
  }

  return context
}
