import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

export const CUSTOMER_ARTICLE_NUMBER_DETAILS_QUERY_KEY =
  'customerArticleNumberDetails'

type CustomerArticleNumberQueryHookParams = {
  articleId: string
  enabled?: boolean
}

export const useCustomerArticleNumberDetailsQuery = ({
  articleId,
  enabled = true,
}: CustomerArticleNumberQueryHookParams) =>
  useQuery({
    queryKey: [CUSTOMER_ARTICLE_NUMBER_DETAILS_QUERY_KEY, articleId],
    queryFn: () =>
      generatedApi.customers.getCustomerArticleNumberDetails.request({
        articleId,
      }),
    enabled,
  })
