import { parseToPositiveInteger } from '../../parsers'

import { MAX_INPUT_VALUE } from './consts'
import type { AmountInputHandlers } from './types'

export type UseInMemoryAmountInputHandlersProps = {
  quantity?: number
  updateHandler: (value: number) => void
  parsedValue: number
  shownValue: number | string
  setShownValue: (value: number | string) => void
}

export type UseInMemoryAmountInputHandlers = (
  props: UseInMemoryAmountInputHandlersProps,
) => AmountInputHandlers

export const useInMemoryAmountInputHandlers: UseInMemoryAmountInputHandlers = ({
  quantity,
  updateHandler,
  parsedValue,
  shownValue,
  setShownValue,
}) => {
  const leaveHandler = () => {
    // prevent keeping empty input on leave or stale value
    if (shownValue !== parsedValue) setShownValue(parsedValue)

    updateHandler(parsedValue)
  }

  const changeHandler = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      setShownValue(value)

      return
    }

    const parsed = parseToPositiveInteger(value)

    const newValue = parsed > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : parsed // allow only values up to max value

    setShownValue(newValue)
  }

  const blurHandler = () => {
    if (quantity !== shownValue) {
      leaveHandler()
    }
  }

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      leaveHandler()
    }
  }

  return {
    changeHandler,
    blurHandler,
    keyUpHandler,
  }
}
