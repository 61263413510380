import { useRouteMatch } from 'react-router-dom'
import { useEffect } from 'react'

import { PATHS } from 'consts/paths'

import { useLogout, useIsAuthorized } from 'app/containers/common/Auth'

export const useUnauthorizedFlows = () => {
  const { isAuthorized } = useIsAuthorized()
  const { mutate: logout } = useLogout()

  const isInvitationFlow = !!useRouteMatch({
    path: PATHS.invitation.root,
    exact: true,
  })

  const isActivationFlow = !!useRouteMatch({
    path: PATHS.activation.root,
    exact: true,
  })

  const isUnauthorizedFlow = isInvitationFlow || isActivationFlow

  useEffect(() => {
    if (isAuthorized && isUnauthorizedFlow) {
      logout()
    }
  }, [isAuthorized, isUnauthorizedFlow, logout])

  const areAuthorizedRoutesAvailable = isAuthorized && !isUnauthorizedFlow

  return {
    areAuthorizedRoutesAvailable,
  }
}
