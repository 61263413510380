import { createListenerMiddleware } from '@reduxjs/toolkit'

import { queryClient } from 'queryClient/queryClient'

import {
  logout,
  resetWithTokens,
  setImpersonationData,
} from 'app/containers/common/Auth/authSlice'

export const reactQueryListener = createListenerMiddleware()

export const clearQueryClientActions = [
  setImpersonationData,
  logout,
  resetWithTokens,
]

reactQueryListener.startListening({
  predicate: (action) =>
    clearQueryClientActions.map((o) => o.type).includes(action.type),
  effect: () => {
    queryClient.clear() // INFO: this side effect is needed to ensure that clearing queryClient is done also in synced tabs (if user is using multiple tabs) e.g. bug https://simplesystem.atlassian.net/browse/S2NG-7608
  },
})
