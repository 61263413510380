import { useMarketplacePreviewCustomersWithCurrencyListQuery } from 'app/containers/vendors/MarketplacePreview/CustomersWithCurrencyList'
import { useMarketplacePreviewCatalogAssignedCustomersListQuery } from 'app/containers/vendors/MarketplacePreview/PlatformCatalogAssignedCustomersList'

import { useCatalog } from './useCatalog'

export const useMarketplacePreviewCustomersData = () => {
  const { catalogId, catalogCurrency, activeTab } = useCatalog()
  const { data: customersWithCurrencyData } =
    useMarketplacePreviewCustomersWithCurrencyListQuery({
      currencyCode: catalogCurrency,
      enabled: activeTab === 'OPEN',
    })
  const { data: catalogAssignedCustomersData } =
    useMarketplacePreviewCatalogAssignedCustomersListQuery({
      catalogId,
      enabled: activeTab === 'CLOSED',
    })

  if (activeTab === 'OPEN') {
    return customersWithCurrencyData ?? []
  }

  if (activeTab === 'CLOSED') {
    return catalogAssignedCustomersData ?? []
  }

  return []
}
