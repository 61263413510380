import {
  DrawerContent,
  DrawerHeader,
  type ModalHeaderProps,
  Flex,
  DrawerBody,
  type ModalBodyProps,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'

const DRAWER_TOP_GAP = '16px'

export const DrawerContentStyled = styled(DrawerContent)(() => ({
  height: `calc(100dvh - ${DRAWER_TOP_GAP})`,
  borderRadius: '8px 8px 0 0',
}))

export const DrawerHeaderStyled = (props: ModalHeaderProps) => (
  <DrawerHeader as={Flex} px="16px" justifyContent="space-between" {...props} />
)

export const DrawerBodyStyled = (props: ModalBodyProps) => (
  <DrawerBody as={ColumnWithGap} gap={16} px="16px" {...props} />
)
