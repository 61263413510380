import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { translations } from 'locales/translations'

import { ModalWithFooter } from 'app/components/modals/ModalWithFooter'
import { Typography } from 'app/components/Typography'

import { GLOBAL_MODALS_IDS } from './consts'

const commonMessages = translations.common
const messages = translations.globalModals.cancelUploadConfirmation

export const CancelUploadConfirmationModal = NiceModal.create(() => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <ModalWithFooter
      cancelTranslation={t(commonMessages.no)}
      saveTranslation={t(commonMessages.yes)}
      id={GLOBAL_MODALS_IDS.cancelUploadConfirmation}
      header={t(messages.header)}
      onSaveHandler={(closeModal) => {
        closeModal()
        history.goBack()
      }}
    >
      <Typography textStyle="textParagraph">{t(messages.body)}</Typography>
    </ModalWithFooter>
  )
})

export const showCancelUploadConfirmationModal = () =>
  NiceModal.show(GLOBAL_MODALS_IDS.cancelUploadConfirmation)
