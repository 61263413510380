import { generatePath } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { api } from 'apiServices'
import { throwIfNull } from 'types/utils'

import { cartItemPath } from '../../consts'
import { useCartMutationOptions } from '../../useCartMutationOptions'
import type { CartItemId } from '../../models'
import { useClearCartMutation } from '../../Delete'
import { useCartItemsLightQuery } from '../List'

export const url = cartItemPath

export const DELETE_ITEM_FROM_CART_MUTATION_KEY = 'deleteItemFromCartMutation'
export const getDeleteItemFromCartKeys = (cartItemId: CartItemId | null) => [
  DELETE_ITEM_FROM_CART_MUTATION_KEY,
  cartItemId,
]

const deleteItemFromCartRequest = (cartItemId: CartItemId | null) => {
  throwIfNull(cartItemId)

  return api.delete<unknown, undefined>(generatePath(url, { cartItemId }))
}

export const useDeleteItemFromCartMutation = (
  cartItemId: CartItemId | null,
) => {
  const cartMutationOptions = useCartMutationOptions()
  const { data } = useCartItemsLightQuery()
  const cartItemsCount = data?.length ?? 0

  const clearCartMutation = useClearCartMutation()
  const deleteCartItemMutation = useMutation({
    mutationKey: getDeleteItemFromCartKeys(cartItemId),
    mutationFn: () => deleteItemFromCartRequest(cartItemId),
    ...cartMutationOptions,
  })

  if (cartItemsCount === 1) return clearCartMutation // INFO: clear whole cart data when deleting last item (comments, accounting data etc)

  return deleteCartItemMutation
}

export const useDeleteItemsFromCartMutation = () => {
  const cartMutationOptions = useCartMutationOptions()
  const mutationRequest = (cartItemIds: CartItemId[]) =>
    Promise.all(
      cartItemIds.map((cartItemId) => deleteItemFromCartRequest(cartItemId)),
    )

  return useMutation({ mutationFn: mutationRequest, ...cartMutationOptions })
}
