import { useTranslation } from 'react-i18next'

import { Button } from 'app/components/Buttons/Button'
import { PlusIcon } from 'app/components/icons'

import { createNewListBtnMsg } from '../consts'

type CreateNewListButtonProps = {
  turnOnCreateMode: () => void
}

export const CreateNewListButton = ({
  turnOnCreateMode,
}: CreateNewListButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      data-testid="createNewListButton"
      isFullWidth
      variant="secondary"
      size="sm"
      leftIcon={<PlusIcon />}
      onClick={turnOnCreateMode}
    >
      {t(createNewListBtnMsg)}
    </Button>
  )
}
