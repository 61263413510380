import { CheckIcon, MinusIcon } from '@chakra-ui/icons'
import { useCheckbox, Box } from '@chakra-ui/react'

import { Typography } from 'app/components/Typography'

import type { BareCheckboxProps } from './types'
import { CheckboxWrapper, CheckboxStyled } from './styles'

type IconProps = { isChecked: boolean; isIndeterminate?: boolean }

const Icon = ({ isChecked, isIndeterminate }: IconProps) => {
  const iconProps = { color: 'white', boxSize: '12px' } as const

  // INFO: Order matters => checkbox can be at the same time checked and indeterminate, then we want to show indeterminate state
  if (isIndeterminate) return <MinusIcon {...iconProps} />

  if (isChecked) return <CheckIcon {...iconProps} />

  return null
}

export const BareCheckbox = ({
  customAriaLabel,
  dataTestId,
  children: label,
  withHoverEffect,
  withLabelFirst,
  isInvalid,
  padding,
  isVerticallyCentered,
  ...props
}: BareCheckboxProps) => {
  const {
    state: { isChecked, isIndeterminate },
    getCheckboxProps,
    getInputProps,
    getLabelProps,
    htmlProps,
  } = useCheckbox(props)

  const labelRendered = label ? (
    <Typography
      {...getLabelProps()}
      lineHeight="20px"
      width="100%"
      color={isInvalid ? 'red_500' : 'contentA'}
      as="span"
    >
      {label}
    </Typography>
  ) : null

  const checkboxRendered = (
    <Box>
      <input
        aria-label={customAriaLabel}
        data-testid={`${dataTestId}_input`}
        hidden
        {...getInputProps()}
      />

      <CheckboxStyled
        hasFilledBackground={isChecked || isIndeterminate}
        isChecked={isChecked}
        isInvalid={isInvalid}
        isDisabled={props.isDisabled}
        aria-label={customAriaLabel}
        {...getCheckboxProps()}
      >
        <Icon isChecked={isChecked} isIndeterminate={isIndeterminate} />
      </CheckboxStyled>
    </Box>
  )

  return (
    <CheckboxWrapper
      withLabel={!!label}
      withHoverEffect={withHoverEffect}
      isVerticallyCentered={isVerticallyCentered}
      {...htmlProps}
      padding={padding}
      data-testid={dataTestId}
    >
      {withLabelFirst ? (
        <>
          {labelRendered} {checkboxRendered}
        </>
      ) : (
        <>
          {checkboxRendered} {labelRendered}
        </>
      )}
    </CheckboxWrapper>
  )
}
