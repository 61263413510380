import { Flex } from '@chakra-ui/react'

import { Typography } from 'app/components/Typography'
import {
  useCompanyUserMeQuery,
  useCurrentCompanyUserFullName,
} from 'app/containers/common/Me/CompanyUser'

export const AccountMenuUsername = () => {
  const fullName = useCurrentCompanyUserFullName()
  const { data: { profile } = {} } = useCompanyUserMeQuery()

  return (
    <Flex direction="column" gap="4px">
      <Typography textStyle="headerH4">{fullName}</Typography>

      {profile?.email && (
        <Typography color="contentB">{profile.email}</Typography>
      )}
    </Flex>
  )
}
