import { useEffect, useState } from 'react'
import { Html5Qrcode } from 'html5-qrcode'

export const useGetVideoPermission = () => {
  const [videoPermission, setVideoPermission] = useState(false)

  useEffect(() => {
    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length) {
          setVideoPermission(true)
        }
      })
      .catch((error) => {
        setVideoPermission(false)
        console.error('failed to getCameras', error)
      })
  }, [])

  return { videoPermission }
}
