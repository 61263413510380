import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import React from 'react'

import { useTheme } from 'theme'

import { QuestionIcon } from 'app/components/icons'

import { PopoverTriggerWrapper, TriggerWrapper } from './styles'
import type { TooltipProps } from './types'
import { PopoverContentOverriden } from './PopoverContentOverriden'

export const tooltipModifiers = [
  {
    name: 'flip',
    options: { fallbackPlacements: ['bottom', 'right', 'left'] },
  },
]

export const Tooltip = ({
  children,
  content,
  tooltipContentDataTestId,
  contentPadding = '16px',
  contentWidth = 'auto',
  contentMaxWidth = '316px',
  shouldWrapWithPortal,
  wrapperProps: { dataTestId: wrapperDataTestId, ...wrapperProps } = {},
  triggerContainerProps,
  ...rest
}: TooltipProps) => {
  const { colors } = useTheme()
  const colorStyles = { bg: colors.contentA, color: colors.white }

  const PopoverContentWrapper = shouldWrapWithPortal ? Portal : React.Fragment

  // Box wrapper needed to remove warnings like https://github.com/chakra-ui/chakra-ui/issues/3173
  return (
    <Box width="fit-content" {...wrapperProps} data-testid={wrapperDataTestId}>
      <Popover
        trigger="hover"
        placement="top"
        modifiers={tooltipModifiers}
        {...rest}
      >
        <PopoverTriggerWrapper>
          <PopoverTrigger>
            <Box as="span" {...triggerContainerProps}>
              {children ? (
                <>{children}</>
              ) : (
                <TriggerWrapper {...colorStyles}>
                  <QuestionIcon />
                </TriggerWrapper>
              )}
            </Box>
          </PopoverTrigger>
        </PopoverTriggerWrapper>

        <PopoverContentWrapper>
          <PopoverContentOverriden
            data-testid={tooltipContentDataTestId}
            width={contentWidth}
            maxWidth={contentMaxWidth}
            border={0}
            {...colorStyles}
          >
            <PopoverArrow {...colorStyles} />

            <PopoverBody padding={contentPadding}>{content}</PopoverBody>
          </PopoverContentOverriden>
        </PopoverContentWrapper>
      </Popover>
    </Box>
  )
}
