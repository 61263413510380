import { useMutation, useQueryClient } from '@tanstack/react-query'
import { generatePath } from 'react-router-dom'

import { api } from 'apiServices'

import { articleListsPath } from '../consts'
import { ARTICLE_LISTS_QUERY_KEY } from '../List'

import type { API__UpdateArticleListRequestParams } from './types'

export const url = articleListsPath

const updateArticleListRequest = ({
  id,
  ...body
}: API__UpdateArticleListRequestParams) =>
  api.put(generatePath(url, { articleListId: id }), body)

export const useUpdateArticleListMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateArticleListRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ARTICLE_LISTS_QUERY_KEY] })
    },
  })
}
