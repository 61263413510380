import { Flex } from '@chakra-ui/react'

import type { TabData } from '../types'
import { TabStyled } from '../styles'

type TabsGroupProps<TabKeyT> = {
  tabData: TabData<TabKeyT>
}

export const TabsGroup = <T extends string>({
  tabData,
  tabData: { subTabs },
}: TabsGroupProps<T>) => {
  const tabsGroupData = [tabData].concat(subTabs ?? [])

  return (
    <Flex>
      {tabsGroupData.map(({ key, title }, index) => {
        const groupedTabProps = {
          hasGroupedStyle: true,
          isFirstGroupedTab: index === 0,
          isLastGroupedTab: index === tabsGroupData.length - 1,
        }

        return (
          <TabStyled key={key} data-testid={key} {...groupedTabProps}>
            {title}
          </TabStyled>
        )
      })}
    </Flex>
  )
}
