import type { UseDisclosureReturn } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

import type { MobileButtonWithFullModalProps } from './types'

export const useOnMountCallback = ({
  isOpen,
  onMount,
}: Pick<UseDisclosureReturn, 'isOpen'> &
  Pick<MobileButtonWithFullModalProps, 'onMount'>) => {
  const wasPreviouslyOpen = usePrevious(isOpen)

  useEffect(() => {
    if (!wasPreviouslyOpen && isOpen) {
      if (onMount) {
        onMount()
      }
    }
  }, [wasPreviouslyOpen, isOpen, onMount])
}

export const useCloseOnPathnameChange = ({
  close,
}: {
  close: UseDisclosureReturn['onClose']
}) => {
  const { pathname, key } = useLocation()

  useEffect(() => {
    close()
  }, [pathname, close])

  // INFO: close modal when clicking on link that redirects to the same path user is on currently
  useEffect(() => {
    close()
  }, [key, close])
}
