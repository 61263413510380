import { http, HttpResponse } from 'msw'

import { joinApiPaths } from 'app/utils/joinApiPaths'

import { url } from './api'
import type { MODEL__InvitationState } from './models'

export const invitationState: MODEL__InvitationState = {
  email: 'kat@example.com',
  passwordExists: false,
  companyName: 'Pokemon',
  expired: false,
  alreadyAccepted: false,
  companyType: 'CUSTOMER',
}

export const makeInvitationStateGetMock = (
  mockData: MODEL__InvitationState = invitationState,
) =>
  http.get(joinApiPaths(url), () =>
    HttpResponse.json(mockData, { status: 200 }),
  )

export const invitationStateGetMock = makeInvitationStateGetMock()

export const invitationWithPasswordSetState: MODEL__InvitationState = {
  ...invitationState,
  passwordExists: true,
}

export const invitationWithPasswordSetStateGetMock = makeInvitationStateGetMock(
  invitationWithPasswordSetState,
)

export const alreadyAcceptedInvitationState: MODEL__InvitationState = {
  ...invitationState,
  passwordExists: true,
  alreadyAccepted: true,
}

export const alreadyAcceptedInvitationStateGetMock = makeInvitationStateGetMock(
  alreadyAcceptedInvitationState,
)

export const expiredInvitationState = {
  ...invitationState,
  expired: true,
}

export const expiredInvitationStateGetMock = makeInvitationStateGetMock(
  expiredInvitationState,
)
