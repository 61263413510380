import { ModalContent, type ModalContentProps } from '@chakra-ui/react'

const MOBILE_MENU_HEIGHT = '54px'

export const ModalContentStyled = (props: ModalContentProps) => (
  <ModalContent
    marginTop={MOBILE_MENU_HEIGHT}
    minHeight={`calc(100% - ${MOBILE_MENU_HEIGHT})`}
    borderRadius={0}
    position="fixed"
    {...props}
  />
)
