import { flatten } from 'flat'
import type { ValueOf } from 'type-fest'

import type { Flatten } from 'types'

const palette = {
  blue: {
    100: '#E3EEFF',
    150: '#D0E2FF',
    200: '#B6D1FF',
    300: '#87B2FF',
    400: '#4D8BFF',
    500: '#276EF1',
    600: '#2059C2',
    700: '#184290',
    800: '#102C60',
  },
  green: {
    100: '#D4F2C1',
    150: '#BEEAB5',
    200: '#9DDEA3',
    300: '#7AD08F',
    400: '#4FC078',
    500: '#25B060',
    600: '#05944F',
  },
  red: {
    100: '#FFE9E9',
    150: '#FED6D6',
    200: '#FDC5C5',
    300: '#EE9191',
    400: '#E86161',
    500: '#DD2222',
    600: '#c61e1e',
  },
  orange: {
    100: '#FFF1C1',
    200: '#FFDFA5',
    300: '#FFC57D',
    400: '#FFAD59',
    500: '#FF9A3C',
    600: '#E3813A',
  },
  yellow: {
    100: '#FFF1C1',
    200: '#FFE690',
    300: '#FFDB61',
    400: '#FFCC00',
  },
} as const

const paletteFlattened = flatten<typeof palette, Flatten<typeof palette, '_'>>(
  palette,
  { delimiter: '_' },
)

export const colors = {
  ...paletteFlattened,
  currentColor: 'currentColor',
  backgroundA: '#fff',
  backgroundB: '#FAFCFF',
  backgroundC: '#F2F5FD',
  backgroundD: '#DFE6F5',
  backgroundE: '#D2DAEB',
  backgroundF: '#EFF3FE',
  backgroundG: '#EDF1FC',
  backgroundH: '#F5F6FA',
  backgroundNotice: '#FFF7E9',
  contentA: '#102C60',
  contentB: '#6C778E',
  contentC: '#96A4BD',
  contentD: '#C4C4C4',
  contentOff: '#CCD4E3',
  accentPrimary: '#FB4C01',
  white: '#fff',
  line: '#DFE6F5',
  lineDarker: '#C9D3EA',
  cardShadow: 'rgba(0, 73, 208, 0.25)',
  boxShadow: 'rgba(108, 119, 142, 0.35)',
  divShadow: 'rgba(0, 73, 208, 0.25)',
  black: '#000000',
  white_50_alpha: 'rgba(255, 255, 255, 0.5);',
  white_15_alpha: 'rgba(255, 255, 255, 0.15);',
} as const

export type Color = keyof typeof colors | 'currentColor'
export type ColorsValues = ValueOf<typeof colors>
