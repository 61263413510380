import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { useFormatArticleUnit } from './useFormatArticleUnit'

const messages = translations.pages.customer.articleDetails

export type FormatArticleDataProp = {
  orderOptions?: {
    priceQuantity: string
  }
  contentUnit?: string
  contentAmount?: string
  minimumOrder?: number
  orderUnit?: string
}

export const useFormatArticleData = (data?: FormatArticleDataProp) => {
  const { t } = useTranslation()
  const { formatArticleUnit } = useFormatArticleUnit()

  const { orderOptions, contentUnit, contentAmount, minimumOrder, orderUnit } =
    data ?? {}
  const { priceQuantity } = orderOptions ?? {}

  const orderAmount = Number(priceQuantity) > 1 ? priceQuantity : ''
  const orderUnitText = formatArticleUnit({ unit: orderUnit })
  const minimalOrderUnitText = formatArticleUnit({
    unit: orderUnit,
    quantity: minimumOrder,
  })
  const multipleOrderUnitsText = formatArticleUnit({
    unit: orderUnit,
    quantity: priceQuantity,
  })
  const contentUnitText = formatArticleUnit({
    unit: contentUnit,
    quantity: contentAmount,
  })

  const basicPackagingInfo = orderAmount
    ? t(messages.packagingInfo, {
        orderAmount,
        multipleOrderUnit: multipleOrderUnitsText,
      })
    : t(messages.packagingSmall, { orderUnit: orderUnitText })

  const packagingContentInfo =
    Number(contentAmount) > 1
      ? t(messages.packagingContentInfo, {
          orderUnit: orderUnitText,
          contentAmount: contentAmount,
          contentUnit: contentUnitText,
        })
      : ''

  const packagingInfo = packagingContentInfo
    ? `${basicPackagingInfo} ${packagingContentInfo}`
    : basicPackagingInfo

  const minimalOrderInfo =
    Number(minimumOrder) > 1
      ? t(messages.minimumOrderQuantity, {
          minNumber: minimumOrder,
          orderUnit: minimalOrderUnitText,
        })
      : ''

  return {
    basicPackagingInfo,
    packagingInfo,
    minimalOrderInfo,
    contentUnitText,
  }
}
