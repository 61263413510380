import { useHistory } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import type { SuppressGlobalOnErrorProp } from 'queryClient/types'
import { generatedApi } from 'apiServices'
import { PATHS } from 'consts/paths'

import type { VerificationUserId } from 'app/containers/models/companyRegistration'
import type { MODEL__JWTTokenResponse } from 'app/models/tokenResponse'
import { showGenericErrorModal } from 'app/App/globalModals'

import { logoutRequest } from '../api'
import { refreshTokenSelector } from '../selectors'
import { loginFlow, logoutFlow } from '../authUtils'
import {
  ACCOUNT_NOT_VERIFIED_STATUS,
  INVALID_CREDENTIALS_STATUS,
} from '../consts'
import { setVerificationUserId } from '../authSlice'

const isUserIdPresent = (
  userId?: VerificationUserId,
): userId is VerificationUserId => userId !== undefined

type LoginMutationFn = (
  ...args: Parameters<typeof generatedApi.authentication.login.request>
) => Promise<MODEL__JWTTokenResponse>

// TODO: Add tests to the useLogin and useLogout hooks
// https://simplesystem.atlassian.net/browse/S2NG-4528
export const useLogin = ({
  suppressGlobalOnError,
}: SuppressGlobalOnErrorProp = {}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: generatedApi.authentication.login.request as LoginMutationFn,
    onSuccess: loginFlow,
    onError: (error: unknown) => {
      if (!axios.isAxiosError(error)) return showGenericErrorModal()

      if (error.response?.status === INVALID_CREDENTIALS_STATUS) return // handled in the view/mutation

      if (error.response?.status === ACCOUNT_NOT_VERIFIED_STATUS) {
        const { userId } = error.response?.data as {
          userId?: VerificationUserId
        }

        if (!isUserIdPresent(userId)) {
          throw new Error('userId missing')
        }

        dispatch(setVerificationUserId(userId))
        history.push(PATHS.activation.notYetActivated)
      } else {
        return showGenericErrorModal({ error })
      }
    },
    meta: { suppressGlobalOnError },
  })
}

export const useLogout = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const refreshToken = useSelector(refreshTokenSelector)
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: () => {
      logoutFlow(dispatch)

      if (refreshToken) {
        return logoutRequest({ refreshToken })
      }

      return Promise.resolve()
    },
    onSuccess,
  })
}

// This hook should be used for cases, when user manually clicks "Logout" button, so that Login page has empty state,
// and, after logging in again, user is redirected to Home (NOT to the page, which was open before logout)
export const useLogoutWithRedirectToRoot = () => {
  const history = useHistory()

  return useLogout({ onSuccess: () => history.push(PATHS.root) })
}
