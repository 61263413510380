export const deliveryAddressesScope = 'delivery-addresses' as const

export const deliveryAddressesPath = `${deliveryAddressesScope}/search`

export const deliveryAddressPath =
  `${deliveryAddressesScope}/:deliveryAddressId` as const

export const userScopeDeliveryAddressPath = `users/:companyUserId/${deliveryAddressesPath}`

export const getScopeSpecificDeliveryAddressesPath = (
  isCompanyWide: boolean,
) => (isCompanyWide ? deliveryAddressesPath : userScopeDeliveryAddressPath)

export const DELIVERY_ADDRESSES_REDUCER_NAME = 'deliveryAddresses'
