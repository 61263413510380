import type { ReactNode } from 'react'
import { ClassNames } from '@emotion/react'
import { SnackbarProvider } from 'notistack'

import { theme } from 'theme'

import { CartItemToast } from 'app/pages/common/marketplaceAndSearch'
import {
  AddedToCartToast,
  UnavailableArticlesToast,
} from 'app/pages/customers/ArticleLists/ArticleListDetails/components'
import { usePageOffsetTop } from 'app/components/layout/Layout/usePageOffsetTop'
import { NotificationToast } from 'app/components/Notification/NotificationToast'

type ToastProviderProps = {
  children: ReactNode
  maxSnack?: number
}

export const ToastProvider = ({
  children,
  maxSnack = 3,
}: ToastProviderProps) => {
  const pageOffsetTop = usePageOffsetTop()

  return (
    <ClassNames>
      {({ css, theme: { zIndexes } }) => (
        <SnackbarProvider
          classes={{
            containerAnchorOriginTopRight: css({
              top: pageOffsetTop,
            }),
            containerAnchorOriginTopCenter: css({
              top: pageOffsetTop,
            }),
            containerRoot: css({
              zIndex: zIndexes.aboveModal,
            }),
          }}
          style={{
            width: '384px',
            cursor: 'pointer',
            alignItems: 'center',
            boxShadow: theme.shadows.primary,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={maxSnack}
          Components={{
            cartItem: CartItemToast,
            unavailableArticles: UnavailableArticlesToast,
            cartArticlesList: AddedToCartToast,
            notification: NotificationToast,
          }}
          disableWindowBlurListener
        >
          {children}
        </SnackbarProvider>
      )}
    </ClassNames>
  )
}
