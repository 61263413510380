import { HttpResponse, http } from 'msw'

import { generatedApi } from 'apiServices'
import type {
  ModelGetArticleExternalReferencesData,
  ModelUpdateArticleExternalReferenceError,
} from 'libs/openapiGeneratedApi/Api'

import { getCustomerMockUrl } from 'app/containers/customers/utils'

export const externalReferencesListMockData: ModelGetArticleExternalReferencesData =
  {
    items: [
      {
        id: '3458712392390512931234',
        barcode: '12324342',
        quantity: 10,
      },
      {
        id: '345871239239051293123000',
        barcode: 'y45u3584302-223',
        quantity: 4,
      },
    ],
  }

const emptyData: ModelGetArticleExternalReferencesData = {
  items: [],
}

const errorResponse: ModelUpdateArticleExternalReferenceError = {
  message: "Article '73eD86HcsIpDWgTBSsEoyB:MTY5NTM1Mi01Swhjg' not found",
  details: [],
  timestamp: '2024-03-13T11:35:48.427092Z',
}

export const externalReferencesListGetMock = http.get(
  getCustomerMockUrl(generatedApi.customers.getArticleExternalReferences.url),
  () => HttpResponse.json(externalReferencesListMockData),
)

export const externalReferencesListGetEmptyMock = http.get(
  getCustomerMockUrl(generatedApi.customers.getArticleExternalReferences.url),
  () => HttpResponse.json(emptyData),
)
