import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { ModelArticleDetailData } from 'libs/openapiGeneratedApi/Api'

import {
  getCustomerMockUrl,
  getOciCustomerMockUrl,
} from 'app/containers/customers/utils'
import { getVendorMockUrl } from 'app/containers/vendors/utils'

import { TEST_ARTICLE_EXTERNAL_PRICE } from '../../consts'

import {
  articleDetailsMockedResponse,
  orderOptionsWithBulkPrices,
  articleDetails404ErrorMockedResponse,
} from './data'

const articleDetailsUrlMapper = {
  customer: generatedApi.customers.articleDetail.url,
  vendor: generatedApi.vendors.previewArticleDetail.url,
}

// customer mocks
export const createArticleDetailsCustomerGetMock = ({
  response = articleDetailsMockedResponse,
} = {}) =>
  http.get(getCustomerMockUrl(articleDetailsUrlMapper.customer), () =>
    HttpResponse.json(response),
  )

export const articleDetailsCustomerGetMock =
  createArticleDetailsCustomerGetMock()

export const articleDetailsRestrictedGetMock =
  createArticleDetailsCustomerGetMock({
    response: {
      ...articleDetailsMockedResponse,
      orderOptions: {
        restricted: true,
        priceQuantity: '1',
        pricing: [
          {
            quantity: '1',
            price: { currency: 'GBP', amount: TEST_ARTICLE_EXTERNAL_PRICE },
          },
        ],
      },
    },
  })

export const articleDetailsWithBulkPricesCustomerGetMock =
  createArticleDetailsCustomerGetMock({
    response: {
      ...articleDetailsMockedResponse,
      orderOptions: orderOptionsWithBulkPrices,
    },
  })

export const articleDetailsWithIncorrectIdCustomerGetMock = http.get(
  getCustomerMockUrl(articleDetailsUrlMapper.customer),
  () =>
    HttpResponse.json(articleDetails404ErrorMockedResponse, { status: 404 }),
)

export const articleDetailsWithoutPricesCustomerGetMock =
  createArticleDetailsCustomerGetMock({
    response: {
      ...articleDetailsMockedResponse,
      orderOptions: undefined,
    },
  })

export const articleDetailsWithArticleAddedToAListGetMock = (
  isIncludedToList: boolean,
) =>
  createArticleDetailsCustomerGetMock({
    response: {
      ...articleDetailsMockedResponse,
      isIncludedInArticleList: isIncludedToList,
    },
  })

// oci customer mocks
export const articleDetailsOciCustomerGetMock = http.get(
  getOciCustomerMockUrl(articleDetailsUrlMapper.customer),
  () => HttpResponse.json(articleDetailsMockedResponse),
)

// vendor mocks
export const createArticleDetailsVendorGetMock = ({
  response = articleDetailsMockedResponse,
  spyFn,
}: {
  response?: ModelArticleDetailData
  spyFn?: jest.Mock
} = {}) =>
  http.get(getVendorMockUrl(articleDetailsUrlMapper.vendor), ({ request }) => {
    const reqUrl = new URL(request.url)

    if (spyFn) spyFn(Object.fromEntries(reqUrl.searchParams))

    return HttpResponse.json(response)
  })

export const articleDetailsVendorGetMock = createArticleDetailsVendorGetMock()

export const articleDetailsWithBulkPricesVendorGetMock =
  createArticleDetailsVendorGetMock({
    response: {
      ...articleDetailsMockedResponse,
      orderOptions: orderOptionsWithBulkPrices,
    },
  })

export const articleDetailsWithoutPricesVendorGetMock =
  createArticleDetailsVendorGetMock({
    response: {
      ...articleDetailsMockedResponse,
      orderOptions: undefined,
    },
  })
