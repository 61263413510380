import { useTable, useFlexLayout, useSortBy } from 'react-table'
import { Box } from '@chakra-ui/react'

import { WithLoaderWrapper } from 'app/components/loaders/WithLoaderWrapper'
import { Pagination } from 'app/components/Pagination'
import { EmptyCaseWrapper } from 'app/components/EmptyCaseWrapper'

import { TableStyled, TbodyStyled, defaultColumn } from '../styles'
import { TableRow } from '../internalComponents/TableRow'
import { Headers } from '../internalComponents/Headers'
import type { TableProps } from '../types'
import { useGetSortByProps } from '../useGetSortByProps'

export const Table = <T extends object>({
  columns,
  data,
  isLoading,
  pageCount,
  shouldNotScrollToTopOnPageChange,
  emptyCaseWrapperProps,
  additionalRowPropsGetter,
  activeRowIndex,
  rowItemsAlignment,
  bodyAdditionalElement,
  TableRowElement = TableRow<T>,
  withoutHeadersRow,
  withoutHeadersRowDivider,
  withoutHeaderTopBorder,
  withoutLastRowBorder,
  withoutDividerBottom,
  withoutRowBorder,
  dataTestId,
  maxHeight,
  shouldUseDelayedLoader,
  rowTestId,
  withScrollbar,
  disableSortRemove,
  ...rest
}: TableProps<T>) => {
  const { withSortBy, sortByInitialState } = useGetSortByProps({ columns })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>(
      {
        columns,
        data: data ?? [],
        defaultColumn,
        disableSortBy: !withSortBy,
        autoResetSortBy: false,
        disableSortRemove,
        initialState: {
          sortBy: sortByInitialState,
        },
      },
      useFlexLayout,
      useSortBy,
    )

  return (
    <EmptyCaseWrapper
      data={data}
      isLoading={isLoading}
      {...emptyCaseWrapperProps}
    >
      <Box position="relative" w="100%" {...rest}>
        <WithLoaderWrapper
          isLoading={isLoading}
          shouldUseDelayedLoader={shouldUseDelayedLoader}
        >
          <TableStyled {...getTableProps()} data-testid={dataTestId ?? 'table'}>
            <Headers
              headerGroups={headerGroups}
              withoutHeadersRow={withoutHeadersRow}
              withoutHeadersRowDivider={withoutHeadersRowDivider}
              withoutHeaderTopBorder={withoutHeaderTopBorder}
            />

            <TbodyStyled
              className="table-body"
              withoutLastRowBorder={withoutLastRowBorder}
              withoutRowBorder={withoutRowBorder}
              maxHeight={maxHeight}
              withScrollbar={withScrollbar}
              {...getTableBodyProps()}
            >
              {rows.map((row, index) => (
                <TableRowElement
                  rowTestId={rowTestId}
                  key={row.id}
                  row={row}
                  additionalRowPropsGetter={additionalRowPropsGetter}
                  prepareRow={prepareRow}
                  rowItemsAlignment={rowItemsAlignment}
                  isActive={activeRowIndex === index}
                  index={index}
                />
              ))}
              {bodyAdditionalElement}
            </TbodyStyled>
          </TableStyled>

          {pageCount && (
            <Pagination
              pageCount={pageCount}
              withoutDividerBottom={withoutDividerBottom}
              shouldNotScrollToTopOnPageChange={
                shouldNotScrollToTopOnPageChange
              }
            />
          )}
        </WithLoaderWrapper>
      </Box>
    </EmptyCaseWrapper>
  )
}
