import styled from '@emotion/styled'

import type { ButtonProps } from '../Buttons/Button'
import { Button } from '../Buttons/Button'

export const CompactButtonWrapper = styled((props: ButtonProps) => (
  <Button variant="secondary" as="div" overflow="hidden" {...props} />
))(({ theme: { colors } }) => ({
  '&&': {
    padding: 0,
  },

  ':hover:not([disabled]):not(:active)': {
    background: colors.backgroundC,
  },
}))

export const PaginationButton = ({
  isCompact,
  ...rest
}: ButtonProps & { isCompact: boolean }) => (
  <Button
    variant="secondary"
    {...(isCompact && { style: { borderRadius: 0 } })}
    {...rest}
  />
)
