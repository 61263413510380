import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const FastOrderListWrapper = styled(Flex)(
  ({ theme: { borders, mediaQuery } }) => ({
    flexDirection: 'column',
    borderTop: borders.primary,
    borderBottom: borders.primary,
    overflow: 'auto',

    [mediaQuery.small]: {
      border: 'none',
      overflow: 'unset',
      gap: '20px',
    },
  }),
)
