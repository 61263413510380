import { http, HttpResponse } from 'msw'

import { joinApiPaths } from 'app/utils/joinApiPaths'
import type { MODEL__JWTTokenResponse, Token } from 'app/models/tokenResponse'
import { customerToken } from 'app/containers/common/Auth/mock'

import { url } from './hooks'

export const verifyAccountSuccessData: MODEL__JWTTokenResponse = {
  token: customerToken as Token,
  refreshToken: 'refreshToken' as Token,
}

export const verifyAccountSuccessGetMock = http.get(joinApiPaths(url), () =>
  HttpResponse.json(verifyAccountSuccessData, { status: 200 }),
)

export const verifyAccountInvalidKeyGetMock = http.get(
  joinApiPaths(url),
  () => new HttpResponse(null, { status: 401 }),
)

export const verifyAccountAlreadyActivatedGetMock = http.get(
  joinApiPaths(url),
  () => new HttpResponse(null, { status: 409 }),
)

export const verifyAccountKeyExpiredGetMock = http.get(joinApiPaths(url), () =>
  HttpResponse.json(
    {
      userId: '123',
    },
    { status: 410 },
  ),
)
