import { useLocation } from 'react-router-dom'

import { includes } from 'types/utils'
import { PATHS } from 'consts/paths'

const NO_SEARCH_INPUT_MOBILE_PAGES = [
  PATHS.customer.checkout.address,
  PATHS.customer.orders,
  PATHS.customer.platformOrderDetails,
  PATHS.customer.erpGeneratedOrderDetails,
  PATHS.customer.ociTransferredCartDetails,
  PATHS.customer.deliveriesAndPayments,
  PATHS.customer.platformOrderDeliveriesAndPaymentsDetails,
  PATHS.customer.erpGeneratedOrderDeliveriesAndPaymentsDetails,
  PATHS.customer.vendors,
  PATHS.customer.companyAndSettings.usersManagement,
  PATHS.customer.companyAndSettings.userDetails,
]

export const useMobileSearchInputVisibility = () => {
  const { pathname } = useLocation()

  const isSearchInputVisible = !includes(NO_SEARCH_INPUT_MOBILE_PAGES, pathname)

  return { isSearchInputVisible }
}
