import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { showGenericErrorModal } from 'app/App/globalModals/GenericErrorModal'

import { generateArticleListDetailsQueryKey } from '../Show'

import { generateArticleListItemsQueryKey } from './search'
import { generateBarcodeArticleListItemsQueryKey } from './barcode'

export const useMutationOptions = (articleListId: string) => {
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: generateArticleListDetailsQueryKey({ articleListId }),
    })

    queryClient.invalidateQueries({
      queryKey: generateBarcodeArticleListItemsQueryKey({ articleListId }),
    })

    queryClient.invalidateQueries({
      queryKey: generateArticleListItemsQueryKey({ articleListId }),
    })
  }

  const onError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      showGenericErrorModal({ error })

      queryClient.invalidateQueries({
        queryKey: generateArticleListDetailsQueryKey({ articleListId }),
      })

      queryClient.invalidateQueries({
        queryKey: generateBarcodeArticleListItemsQueryKey({ articleListId }),
      })

      queryClient.invalidateQueries({
        queryKey: generateArticleListItemsQueryKey({ articleListId }),
      })
    }
  }

  return { onSuccess, onError }
}
