import type { QueryObserverOptions } from '@tanstack/react-query'
import type { SetOptional } from 'type-fest'

export const ARTICLES_SEARCH_QUERY_KEY = 'search'
export const ARTICLES_FILTER_VALUES_QUERY_KEY = 'filter-values'

export const searchRelatedQueriesCacheAndRetryOptions = {
  refetchOnWindowFocus: false,
  gcTime: 15 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
  retryDelay: 5000,
  retry: 1,
} satisfies SetOptional<QueryObserverOptions, 'queryKey'>
