import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { ExcludesFalsy } from 'types/utils'

import { PartPageLoader } from 'app/components/loaders/PartPageLoader'
import { PageTabs } from 'app/components/PageTabs/PageTabs'
import type { PageTabsData } from 'app/components/PageTabs/types'
import { EditCustomerArticleNumber } from 'app/pages/common/editArticleModalFlow/EditArticleModal/EditCustomerArticleNumber/EditCustomerArticleNumber'
import {
  useCheckCustomerPermission,
  useHasPrivilegeAssigned,
} from 'app/containers/common/Me/CompanyUser'

import { EditExternalReferences } from './EditExternalReferences/EditExternalReferences'
import type { EditArticleModalContentProps, EditArticleTabKey } from './types'

const messages = translations.pages.customer.editArticleDetailsModal

export const EditArticleModalContent = ({
  articleId,
  activeTab,
  setActiveTab,
  shouldShowLoader,
  editCustomerArticleNumberFormik,
}: EditArticleModalContentProps) => {
  const { t } = useTranslation()

  const canEditCustomerArticleNumbers = useHasPrivilegeAssigned(
    'EDIT_CUSTOMER_ARTICLE_NUMBERS',
  )
  const canEditExternalReferences = useHasPrivilegeAssigned(
    'EDIT_EXTERNAL_REFERENCES',
  )
  const { isAdmin } = useCheckCustomerPermission()

  const tabsData: PageTabsData<EditArticleTabKey> = [
    (canEditCustomerArticleNumbers || isAdmin) &&
      ({
        key: 'buyerArticleNumber',
        title: t(messages.buyerArticleNumber.tabTitle),
        content: (
          <EditCustomerArticleNumber
            articleId={articleId}
            formik={editCustomerArticleNumberFormik}
          />
        ),
      } as const),
    (canEditExternalReferences || isAdmin) &&
      ({
        key: 'externalReferences',
        title: t(messages.externalReferences.tabTitle),
        content: <EditExternalReferences articleId={articleId} />,
      } as const),
  ].filter(ExcludesFalsy)

  if (shouldShowLoader) {
    return <PartPageLoader />
  }

  const activeKey =
    tabsData.find(({ key }) => key === activeTab)?.key ?? tabsData[0].key

  return (
    <PageTabs
      onTabChange={() => editCustomerArticleNumberFormik.resetForm()}
      tabsData={tabsData}
      activeKey={activeKey}
      setActiveKey={setActiveTab}
      contentPaddingTop="20px"
      gap={10}
      overflowX="hidden"
    />
  )
}
