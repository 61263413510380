import styled from '@emotion/styled'
import type { BoxProps } from '@chakra-ui/react'
import { Box, forwardRef } from '@chakra-ui/react'

import type { ImageWithFallbackProps } from '../types'

type StyledImageProps = Pick<ImageWithFallbackProps, 'size' | 'enableZoom'>

export const StyledImage = styled('img')<StyledImageProps>(
  ({ size, theme: { mediaQuery }, enableZoom }) => ({
    ...(enableZoom // INFO: if zoom is enabled, we are calculating the size ourselves to preserve correct aspectRatio (check getMeta, aspectRatio in useZoomOnHover)
      ? {
          width: '100%',
          height: '100%',
        }
      : {
          objectFit: 'contain',
          width: size ? `${size}px` : '100%',
          height: size ? `${size}px` : '100%',
          maxHeight: size ? `${size}px` : 'initial',
        }),
    margin: '0 auto',
    position: 'relative',
    [mediaQuery.print]: {
      '&[alt]:after': {
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        content: '""',
      },
    },
  }),
)

export const ImageContainer = styled(
  forwardRef((props: ImageWithFallbackProps, ref) => (
    <Box {...props} ref={ref} />
  )),
)(({ size }) => ({
  position: 'relative',
  width: size ? `${size}px` : '100%',
  height: size ? `${size}px` : '100%',
}))

export const ZoomTarget = styled(
  forwardRef(
    ({ isImageHover, ...rest }: BoxProps & { isImageHover?: boolean }, ref) => (
      <Box {...rest} ref={ref} />
    ),
  ),
)(({ isImageHover, theme: { borders, shadows, zIndexes } }) => ({
  zIndex: zIndexes.aboveGround,
  position: 'absolute',
  left: 'calc(100% + 24px)',
  top: '0',
  ...(isImageHover && {
    border: borders.primary,
    boxShadow: shadows.primary,
    borderRadius: '6px',
    overflow: 'hidden',
    backgroundColor: 'white',
  }),
}))
