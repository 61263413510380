import { useParams } from 'react-router-dom'

import type { ActiveCatalogVersionId } from 'app/containers/vendors/PlatformCatalogs/models'

export const useCatalogVersionId = () => {
  const { catalogVersionId } = useParams<{
    catalogVersionId: ActiveCatalogVersionId | undefined
  }>()

  return { catalogVersionId }
}
