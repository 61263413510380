import type { CustomDetector } from 'i18next-browser-languagedetector'

import { AVAILABLE_LANGUAGES, LANGUAGE_DETECTOR_KEYS } from '../consts'

export const fallback: CustomDetector = {
  name: LANGUAGE_DETECTOR_KEYS.fallback,
  lookup() {
    return AVAILABLE_LANGUAGES.en
  },
}
