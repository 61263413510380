import { Route, Switch } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { SSOError } from 'app/pages/public/ssoFlow/Error/Loadable'
import { SSOSuccess } from 'app/pages/public/ssoFlow/Success/Loadable'

export const SSORoutes = () => (
  <Switch>
    <Route path={PATHS.sso.success} exact>
      <SSOSuccess />
    </Route>
    <Route path={PATHS.sso.error} exact>
      <SSOError />
    </Route>
  </Switch>
)
