import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { ExcludesFalsy } from 'types/utils'

import { formatMonetaryAmount } from 'app/utils/formatMoney'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useFormatArticleUnit } from 'app/hooks/useFormatArticleUnit'
import { Typography } from 'app/components/Typography'
import type { ModelOrderOptions } from 'app/containers/common/Search/types/response'

import { DataRow } from '../DataRow'

import { BulkPriceContainer } from './styles'

const messages = translations.pages.customer.articleDetails

type BulkPricesProps = BoxProps & {
  pricing: NonNullable<ModelOrderOptions>['pricing'] | undefined
  orderUnit: string | undefined
}

export const BulkPrices = ({
  orderUnit,
  pricing,
  ...rest
}: BulkPricesProps) => {
  const { t } = useTranslation()

  const { isMobile } = useBreakpointScreenSize()
  const { formatArticleUnit } = useFormatArticleUnit()

  const bulkPriceListData =
    pricing
      ?.map(({ quantity, price }) => {
        if (!quantity || !price) return false

        return {
          label: t(messages.pricingLabelFrom, {
            count: +quantity,
            orderUnit: formatArticleUnit({
              unit: orderUnit,
              quantity: quantity,
            }),
          }),
          value: formatMonetaryAmount(price),
        }
      })
      .filter(ExcludesFalsy) || []

  const areMultipleBulkPricesDefined = bulkPriceListData.length > 1

  if (!areMultipleBulkPricesDefined) return null

  return (
    <Box {...rest} data-testid="bulkPricesContent">
      {!isMobile && (
        <Typography textStyle="headerH4" margin="24px 0">
          {t(messages.bulkPricesTitle)}
        </Typography>
      )}

      <div>
        {bulkPriceListData.map((item) => (
          <BulkPriceContainer key={item.label} data-testid="bulkPriceItem">
            <DataRow item={item} />
          </BulkPriceContainer>
        ))}
      </div>
    </Box>
  )
}
