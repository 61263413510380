import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'

import { API_URL } from 'consts/envVars'

import {
  createResponseInterceptor,
  requestInterceptor,
} from './axiosInterceptors'
import { validateStatus } from './utils/validateStatus'
import { serializeArrayQueryParamsToNoneFormat } from './utils/serializeArrayQueryParamsToNoneFormat'
import { REQUEST_TIMEOUT } from './consts'

type CreateAxiosInstanceParams = Partial<InternalAxiosRequestConfig> & {
  axiosInstance?: AxiosInstance
  withoutCamelisation?: boolean
  withoutUnwrappingResponseData?: boolean
}

export const getConfiguredAxiosInstance = ({
  axiosInstance = axios.create(),
  baseURL,
  timeout,
  withoutCamelisation,
  withoutUnwrappingResponseData,
}: CreateAxiosInstanceParams = {}): AxiosInstance => {
  axiosInstance.defaults.baseURL = baseURL ?? API_URL
  axiosInstance.defaults.timeout = timeout ?? REQUEST_TIMEOUT
  axiosInstance.defaults.validateStatus = validateStatus
  axiosInstance.defaults.paramsSerializer =
    serializeArrayQueryParamsToNoneFormat

  // Request handling
  axiosInstance.interceptors.request.use(requestInterceptor)

  // Response handling
  axiosInstance.interceptors.response.use(
    createResponseInterceptor(
      withoutCamelisation,
      withoutUnwrappingResponseData,
    ),
  )

  return axiosInstance
}
