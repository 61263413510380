import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import { useArticleListId } from '../../useArticleListId'

export const BARCODE_ARTICLE_LIST_ITEMS_QUERY_KEY =
  'BARCODE_ARTICLE_LIST_ITEMS_QUERY_KEY'

export const generateBarcodeArticleListItemsQueryKey = <
  T extends { articleListId: string },
>({
  articleListId,
  ...rest
}: T) => [BARCODE_ARTICLE_LIST_ITEMS_QUERY_KEY, articleListId, rest]

export const useBarcodeArticleListItemsQuery = () => {
  const articleListId = useArticleListId()
  const params = { articleListId }

  return useQuery({
    queryKey: generateBarcodeArticleListItemsQueryKey(params),
    queryFn: () =>
      generatedApi.customers.getBarcodeArticleListItems.request(params),
    enabled: !!articleListId,
    staleTime: 1000 * 3,
  })
}
