import { useParams } from 'react-router-dom'

import type { LinkId } from './types'

export const useLinkId = () => {
  const params = useParams<{ linkId?: LinkId }>()
  const [, , fallbackLinkId] = window.location.pathname.split('/')

  if (!params.linkId && !fallbackLinkId)
    throw new Error('linkId is not defined')

  return { linkId: params.linkId ?? (fallbackLinkId as LinkId) }
}
