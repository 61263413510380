import type { AxiosInstance, AxiosError } from 'axios'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { isNull } from 'lodash'

import { store } from 'store'

import { refreshTokenSelector } from 'app/containers/common/Auth/selectors'
import { loginFlow, logoutFlow } from 'app/containers/common/Auth/authUtils'
import type { MODEL__JWTTokenResponse } from 'app/models/tokenResponse'
import { joinApiPaths } from 'app/utils/joinApiPaths'

export const refreshTokenUrl = '/authentication/refresh'

const refreshAuthCall = (failedRequest: AxiosError) => {
  const refreshToken = refreshTokenSelector(store.getState())

  if (isNull(refreshToken)) {
    return new Promise((_, reject) => {
      logoutFlow()

      return reject(failedRequest)
    })
  }

  return axios
    .post<MODEL__JWTTokenResponse>(joinApiPaths(refreshTokenUrl), {
      refreshToken,
    })
    .then(({ data }) => {
      loginFlow(data)

      if (failedRequest?.response?.config.headers) {
        // We need to set the new token on the failed request,
        // cause it will be retried
        failedRequest.response.config.headers['Authorization'] =
          `Bearer ${data.token}`
      }

      return Promise.resolve()
    })
    .catch(() => {
      logoutFlow()
    })
}

export const withRefreshToken = (axiosInstance: AxiosInstance) =>
  createAuthRefreshInterceptor(axiosInstance, refreshAuthCall)
