import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'
import type { History } from 'history'

import { PATHS } from 'consts/paths'

import { useCurrentUser } from 'app/containers/common/Auth'

type CompanyCommonPathsRedirect = {
  pathKey: keyof typeof PATHS.vendor & keyof typeof PATHS.customer
  pathParams?: object
  state?: object
  method?: keyof Pick<History, 'push' | 'replace'>
}

export const useCompanyCommonPathsRedirect = ({
  pathKey,
  pathParams,
  state,
  method = 'push',
}: CompanyCommonPathsRedirect) => {
  const { userRole } = useCurrentUser()
  const history = useHistory()

  return () => {
    let path: string | undefined

    switch (userRole) {
      case 'customer': {
        path = generatePath(PATHS.customer[pathKey], pathParams)
        break
      }
      case 'vendor': {
        path = generatePath(PATHS.vendor[pathKey], pathParams)
        break
      }
    }

    if (path) {
      return history[method](path, state)
    }

    return history[method](PATHS.root)
  }
}
