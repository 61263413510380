import type { IconProps } from '../types'

export const PlusIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M10 5v10M15 10H5" stroke={color} strokeWidth={2} />
  </svg>
)
