import NiceModal from '@ebay/nice-modal-react'
import { FormikProvider } from 'formik'
import { useBoolean } from '@chakra-ui/react'

import { useArticleListAssignmentsForm } from './useArticleListAssignmentsForm'
import type { CommonModalProps } from './types'
import { ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID } from './consts'
import { CreateArticleListModal } from './CreateArticleListModal'
import { ArticleListAssignmentsFormModal } from './ArticleListAssignmentsFormModal'

export const showArticleListAssignmentsModalFlow = (props: CommonModalProps) =>
  NiceModal.show(ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID, props)

export const hideArticleListAssignmentsModalFlow = () =>
  NiceModal.remove(ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID)

export const ArticleListAssignmentsModalFlow =
  NiceModal.create<CommonModalProps>(({ article, vendor }) => {
    const [
      isCreateNewListMode,
      { off: turnOffCreateMode, on: turnOnCreateMode },
    ] = useBoolean(false)

    const { articleListAssignmentsFormik, assignArticleList } =
      useArticleListAssignmentsForm({
        articleId: article.id,
        hideArticleListAssignmentsModalFlow,
      })

    if (isCreateNewListMode) {
      return (
        <CreateArticleListModal
          article={article}
          vendor={vendor}
          turnOffCreateMode={turnOffCreateMode}
          assignArticleList={assignArticleList}
        />
      )
    }

    return (
      <FormikProvider value={articleListAssignmentsFormik}>
        <ArticleListAssignmentsFormModal
          article={article}
          vendor={vendor}
          turnOnCreateMode={turnOnCreateMode}
        />
      </FormikProvider>
    )
  })

NiceModal.register(
  ARTICLE_LISTS_ASSIGNMENTS_FORM_MODAL_ID,
  ArticleListAssignmentsModalFlow,
)
