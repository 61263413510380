import { useHistory } from 'react-router-dom'

import { showGenericErrorModal } from 'app/App/globalModals'
import type { OnScanSuccessHandlerProps } from 'app/pages/customers/common/MobileScannerComponent/types'

import { useMobileScannerContext } from '../context'

import {
  useResumeScanningWithDelay,
  useShowSuccessToastOnSuccessfulScan,
} from './utils'

export const useMobileScanner = () => {
  const history = useHistory()

  const {
    setIsDrawerOpen,
    addScannedValue,
    isAddingScannedValue,
    wasScannedItemAddedSuccessfully,
  } = useMobileScannerContext()
  const { showSuccessToastConfigured } = useShowSuccessToastOnSuccessfulScan()
  const { resumeScanningWithDelay } = useResumeScanningWithDelay()

  const onScanSuccessHandler = ({
    decodedMessage,
    resumeScanning,
    setIsProgressButtonShown,
  }: OnScanSuccessHandlerProps) => {
    /*
    INFO: 
    General logic is to have scanner paused when drawer is open. So, to properly decide if scanning 
    should be resumed, we need to have latest state of `isDrawerOpen` in `resumeScanningWithDelay` function
  
    Since `resumeScanningWithDelay` has setTimeout in it, by default it will use `isDrawerOpen` variable
    from the initial render (which may change during DELAY_AFTER_SUCCESSFUL_SCAN). In order to have
    the correct state of variable, we use `setIsDrawerOpen`, which is getting the latest value of `isDrawerOpen`
    */
    const resumeScanningWithNotStaleIsOpen = () => {
      setIsDrawerOpen((isOpen) => {
        if (!isOpen) resumeScanning()

        return isOpen
      })
    }

    addScannedValue(
      { barcode: decodedMessage.trim() },
      {
        onSuccess: () => {
          showSuccessToastConfigured()
          resumeScanningWithDelay({
            resumeScanningWithNotStaleIsOpen,
            setIsProgressButtonShown,
          })
        },
        onError: async () => {
          await showGenericErrorModal()
          resumeScanningWithNotStaleIsOpen()
        },
      },
    )
  }

  const onScannerCloseHandler = () => history.goBack()

  return {
    onScanSuccessHandler,
    onScannerCloseHandler,
    isAddingScannedValue,
    wasScannedItemAddedSuccessfully,
  }
}
