import { useSelector } from 'react-redux'

import { baseIdentitySelector } from 'app/containers/common/Auth/selectors'

import { findMatchingCompany } from './utils/findMatchingCompany'
import { useUserMeQuery } from './useUserMeQuery'

export const useCurrentCompany = () => {
  const { data } = useUserMeQuery()
  const { selectedCompanyId } = useSelector(baseIdentitySelector)

  if (!data || !selectedCompanyId) return undefined

  return findMatchingCompany(data, selectedCompanyId)?.company
}
