import { http, HttpResponse } from 'msw'

import { getCustomerMockUrl } from 'app/containers/customers/utils'

import type { ArticleListId, MODEL__ArticleListAssignments } from '../../types'

import { url } from './hooks'

export const articleListAssignmentsMockData: MODEL__ArticleListAssignments = [
  {
    articleListId: '7eced8a1-daaf-4020-879e-99e9ea66a041' as ArticleListId,
    name: 'Office supplies',
    articlesCount: 11,
    vendorsCount: 3,
    accessLevel: 'ADMIN',
    articleAssigned: true,
  },
  {
    articleListId: 'abbb3917-71e8-46e4-bba6-684fd3ec41aa' as ArticleListId,
    name: 'Factory supplies',
    articlesCount: 5,
    vendorsCount: 2,
    accessLevel: 'WRITE',
    articleAssigned: false,
  },
  {
    articleListId: '9074a868-3664-4d7c-b934-b30ce88c7a2e' as ArticleListId,
    name: 'Building supplies',
    articlesCount: 20,
    vendorsCount: 10,
    accessLevel: 'ADMIN',
    articleAssigned: true,
  },
  {
    articleListId: '9074a868-3664-4d7c-b934-b41df99d9b3f' as ArticleListId,
    name: 'Cleaning supplies',
    articlesCount: 19,
    vendorsCount: 3,
    accessLevel: 'WRITE',
    articleAssigned: false,
  },
  {
    articleListId: '1185b979-3664-4d7c-b934-b41df99d9b3f' as ArticleListId,
    name: 'Software devs supplies',
    articlesCount: 12,
    vendorsCount: 4,
    accessLevel: 'ADMIN',
    articleAssigned: true,
  },
]

export const articleListAssignmentsGetMock = http.get(
  getCustomerMockUrl(url),
  () => HttpResponse.json(articleListAssignmentsMockData),
)

export const makeArticleListAssignmentsGetMock = (
  articleListAssignments: MODEL__ArticleListAssignments,
) =>
  http.get(getCustomerMockUrl(url), () =>
    HttpResponse.json(articleListAssignments),
  )
