import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import type { PanelProps } from 'app/components/Panel/Panel'
import { Panel } from 'app/components/Panel/Panel'

export const FastOrderListDateWrapper = styled(Flex)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  padding: '8px 20px',
  backgroundColor: theme.colors.backgroundB,
}))

export const FastOrderListWrapper = styled((props: PanelProps) => (
  <Panel isWithoutBorder withDivider p={0} spacing={0} {...props} />
))(({ theme: { borders, mediaQuery } }) => ({
  borderTop: borders.primary,
  ':first-child': { borderTop: 'none' },

  [mediaQuery.small]: {
    ':first-child': { borderTop: borders.primary },
    border: borders.primary,
    borderRadius: '6px',
  },
}))
