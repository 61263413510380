import type { SetRequired } from 'type-fest'

import type { MemoizedImageProps } from '../types'

import { getMeta } from './getMeta'

type GetFittedAspectRatioSizeProps = {
  imageContainer: HTMLDivElement
} & SetRequired<Pick<MemoizedImageProps, 'src' | 'size'>, 'src'>

export const getFittedAspectRatioSize = async ({
  src,
  imageContainer,
  size,
}: GetFittedAspectRatioSizeProps) => {
  const { naturalWidth: srcWidth, naturalHeight: srcHeight } =
    await getMeta(src)
  const { width: containerMaxWidth, height: containerMaxHeight } =
    imageContainer.getBoundingClientRect()
  const maxWidth = size ?? containerMaxWidth
  const maxHeight = size ?? containerMaxHeight
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

  return {
    width: srcWidth * ratio,
    height: srcHeight * ratio,
    maxWidth,
    maxHeight,
  }
}
