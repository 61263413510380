import type { ExpandedIndex } from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { useState } from 'react'

import { useTheme } from 'theme'

import { Divider } from 'app/components/Divider'
import { MenuItemWrapper } from 'app/components/ActionsMenu/styles'

type AccordionOptionProps = {
  withDividerAtBottom?: boolean
  children: ReactNode
  title: (isExpanded: boolean) => ReactNode
}

export const AccordionOption = ({
  withDividerAtBottom,
  title,
  children,
}: AccordionOptionProps) => {
  const { colors, borders } = useTheme()

  const [accordionIndex, setAccordionIndex] = useState<ExpandedIndex>([])
  const isExpanded = Array.isArray(accordionIndex) && accordionIndex?.length > 0

  const accordionStyles = {
    ...(isExpanded && {
      marginTop: '0',
    }),
  }

  const accordionItemStyles = {
    borderRadius: '6px',
    border: '1px solid transparent',
    transition: 'backgroundColor .2s, border .2s',

    ...(isExpanded && {
      border: borders.primary,
      backgroundColor: colors.backgroundB,
    }),
  }

  return (
    <>
      <Accordion
        allowMultiple
        style={accordionStyles}
        index={accordionIndex}
        onChange={(index) => setAccordionIndex(index)}
      >
        <AccordionItem border="0" style={accordionItemStyles}>
          <MenuItemWrapper direction="column">
            <AccordionButton
              p="0"
              _hover={{ background: 'transparent' }}
              _focus={{ boxShadow: 'none' }}
            >
              {title(isExpanded)}
            </AccordionButton>

            <AccordionPanel p="0">
              <Divider mt="14px" mb="8px" />
              {children}
            </AccordionPanel>
          </MenuItemWrapper>
        </AccordionItem>
      </Accordion>

      {withDividerAtBottom && <Divider my="8px" mx="12px" width="auto" />}
    </>
  )
}
