import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import * as Sentry from '@sentry/react'

import { BARCODES_SEPARATOR } from './consts'

type UseFastOrderFileUploadArgs = {
  setInputValue: Dispatch<SetStateAction<string | undefined>>
}

export const useFastOrderFileUpload = ({
  setInputValue,
}: UseFastOrderFileUploadArgs) => {
  const [isReadingFile, setIsReadingFile] = useState(false)

  const handleFileUpload = async (file: File) => {
    try {
      setIsReadingFile(true)
      const fileContent = await file.text()

      setInputValue((prev) => {
        if (!prev) return fileContent

        return [prev, fileContent].join(BARCODES_SEPARATOR)
      })
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setIsReadingFile(false)
    }
  }

  return {
    isReadingFile,
    handleFileUpload,
  }
}
