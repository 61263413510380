import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { Modal } from 'app/components/modals/Modal'
import { Typography } from 'app/components/Typography'
import { Button } from 'app/components/Buttons/Button'
import { CopyButton } from 'app/components/Buttons/CopyButton'

import { GLOBAL_MODALS_IDS } from './consts'

const messages = translations.globalModals.genericError

export type GenericErrorModalProps = {
  error?: unknown
  description?: string
  header?: string
}

export const GenericErrorModal = NiceModal.create<GenericErrorModalProps>(
  ({ error, description, header }) => {
    const { t } = useTranslation()

    const traceId = axios.isAxiosError(error)
      ? error.response?.headers?.['x-trace-id']
      : undefined

    return (
      <Modal
        isWithoutHeaderBorder
        id={GLOBAL_MODALS_IDS.genericError}
        header={header ?? t(messages.header)}
        footer={(closeModal) => (
          <Button
            isFullWidth
            onClick={closeModal}
            data-testid="closeGenericErrorModalButton"
          >
            {t(translations.formActions.ok)}
          </Button>
        )}
      >
        <Typography textStyle="textParagraph">
          {description ?? t(messages.body)}
        </Typography>

        {traceId && (
          <Flex align="center" gap="24px">
            <Typography textStyle="textParagraph">
              {t(messages.traceId, { traceId })}
            </Typography>

            <CopyButton flexShrink={0} copyValue={String(traceId)} />
          </Flex>
        )}
      </Modal>
    )
  },
)

export const showGenericErrorModal = (params?: GenericErrorModalProps) =>
  NiceModal.show(GLOBAL_MODALS_IDS.genericError, params)
