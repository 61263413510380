/**
 * Create the store
 */

import type { CombinedState, PreloadedState } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import type { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers'
import type { Config as StateSyncMiddlewareConfig } from 'redux-state-sync'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'
import { LOCATION_CHANGE } from 'redux-first-history'
import type { Persistor } from 'redux-persist'
import { persistStore, persistReducer } from 'redux-persist'

import { IS_TEST } from 'consts/envVars'

import { FILE_UPLOAD_REDUCER_NAME } from 'app/containers/common/FileUpload'

import { reducer as rootReducer } from './reducers'
import { REDUX_PERSIST_ACTIONS, persistConfig } from './consts'
import { routerMiddleware } from './reduxHistoryContext'
import { reactQueryListener } from './reactQueryListener'

export const configureAppStore = (
  preloadedState?: PreloadedState<CombinedState<NoInfer<unknown>>>,
) => {
  const stateSyncMiddlewareConfig: StateSyncMiddlewareConfig = {
    predicate: (action) => {
      if (IS_TEST) return false

      if (action.type === LOCATION_CHANGE) return false

      if (action.type.includes(FILE_UPLOAD_REDUCER_NAME)) return false

      if (
        REDUX_PERSIST_ACTIONS.some((persistAction) =>
          action.type.includes(persistAction),
        )
      )
        return false

      if (action.type.includes(FILE_UPLOAD_REDUCER_NAME)) return false

      return true
    },
  }

  const middlewares = [
    routerMiddleware,
    createStateSyncMiddleware(stateSyncMiddlewareConfig),
    reactQueryListener.middleware,
  ]

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    reducer: IS_TEST ? rootReducer : persistedReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: preloadedState,
  })

  let persistor: Persistor | undefined

  if (!IS_TEST) {
    initMessageListener(store)

    persistor = persistStore(store)
  }

  return { store, persistor }
}
