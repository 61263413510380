import { Link } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { SimpleSystemLogoIcon } from 'app/components/icons'

import { BackofficeAccountButton } from './components'
import { BackofficeNavWrapper } from './styles'

export const BackofficeNav = () => (
  <BackofficeNavWrapper>
    <Link to={PATHS.root}>
      <SimpleSystemLogoIcon />
    </Link>

    <BackofficeAccountButton />
  </BackofficeNavWrapper>
)
