import { useCurrentUser, useOciSession } from 'app/containers/common/Auth'

import { CompanyAccountMenu } from '../../../commonCompanyNavComponents'

import { CustomerAccountButtonTrigger } from './components'

export const CustomerAccountButton = () => {
  const { isUserImpersonated } = useCurrentUser()
  const { isOciSessionFlow } = useOciSession()

  const isLogoutAvailable = !isUserImpersonated && !isOciSessionFlow

  return (
    <CompanyAccountMenu
      triggerButton={<CustomerAccountButtonTrigger />}
      isLogoutAvailable={isLogoutAvailable}
      areCompanyDetailsHidden={isOciSessionFlow}
    />
  )
}
