/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type ModelSortSchema = string[]

/** Error */
export interface ModelError {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** ErrorDetail */
export interface ModelErrorDetail {
  /**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   */
  field?: string
  /**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   */
  message?: string
}

/** AdditionalData */
export interface ModelAdditionalData {
  /**
   * Name of the property
   * @example "contactPerson"
   */
  label?: string
  /**
   * Value of the property
   * @example "example@gmail.com"
   */
  value?: string
}

/** Amount of money */
export interface ModelMonetaryAmount {
  /**
   * Amount in minor currency (smallest indivisible unit) of the specified currency
   * @format int64
   * @min -1000000000000000
   * @max 1000000000000000
   * @example 1234
   */
  amount: number
  /**
   * ISO 4217 currency code
   * @example "EUR"
   */
  currency: string
}

/** Range of money */
export interface ModelMonetaryRange {
  /**
   * Min amount in minor currency (smallest indivisible unit) of the specified currency
   * @format int64
   * @example 1234
   */
  minAmount?: number
  /**
   * Max amount in minor currency (smallest indivisible unit) of the specified currency
   * @format int64
   * @example 1234
   */
  maxAmount?: number
  /**
   * ISO 4217 currency code
   * @example "EUR"
   */
  currency: string
}

/** Search metadata */
export interface ModelSearchMetadata {
  /**
   * Page number
   * @format int32
   * @example 1
   */
  page: number
  /**
   * Number of items on the page
   * @format int32
   * @example 10
   */
  pageSize: number
  /**
   * Number of pages available
   * @format int32
   * @example 5
   */
  pageCount: number
  /**
   * Search term
   * @example "drill"
   */
  term?: string
  /**
   * Number of search hits
   * @format int64
   * @example 19
   */
  hitCount?: number
}

/** Article with basic properties */
export interface ModelBasicArticle {
  /**
   * Article ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  id: string
  /**
   * Article name
   * @example "Drilling machine"
   */
  name: string
  /**
   * Article number (Vendor’s unique article number)
   * @example "ABC123456"
   */
  number: string
  /**
   * EAN number
   * @example 12345678
   */
  ean: string
  eclass?: {
    /** Version of eClass specification */
    version: ModelBasicArticleVersionEnum
    /** eClass value formatted with dashes between groups ("12-34-56-78") */
    code?: string | null
    /**
     * eClass name
     * @example "Catering machinery"
     */
    name?: string | null
  }
  /**
   * Article number used in customer systems
   * @example "VABC123456"
   */
  customerArticleNumber: string
  /**
   * Article number used by manufacturer
   * @example "ABC123"
   */
  manufacturerArticleNumber?: string
  /**
   * Manufacturer name
   * @example "ACME Inc."
   */
  manufacturer: string
  /**
   * Maximum delivery time in days declared by vendor
   * @deprecated
   * @min 0
   * @max 999999
   * @example 3
   */
  deliveryTime: number
  /** Delivery time details */
  deliveryInfo: {
    /**
     * Maximum delivery time in days declared by vendor
     * @min 0
     * @example 3
     */
    days: number
    source: ModelBasicArticleSourceEnum
    /**
     * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  /** Stock level info */
  stockInfo: {
    value: ModelBasicArticleValueEnum
    /**
     * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  minimumOrder?: number
  quantityInterval?: number
  contentUnit?: string
  /** @example 0.001 */
  contentAmount?: string
  /** @format double */
  tax: number
  /** Pricing, order unit and constraints on quantity */
  orderOptions?: {
    /** Flag describing if this article is restricted from adding to the cart */
    restricted?: boolean
    /**
     * Article quantity for which price (pricePerOrderUnit) is defined
     * @example 0.001
     */
    priceQuantity: string
    /** Prices of the article */
    pricing: {
      /** @example 0.001 */
      quantity?: string
      /** Amount of money */
      price?: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Pricing source information, returned only for vendor preview */
      source?: {
        type: ModelBasicArticleTypeEnum
        priceListFilename?: string | null
      }
    }[]
  }
  coreArticle: boolean
  closedMarketplace: boolean
}

/** Pricing, order unit and constraints on quantity */
export interface ModelOrderOptions {
  /** Flag describing if this article is restricted from adding to the cart */
  restricted?: boolean
  /**
   * Article quantity for which price (pricePerOrderUnit) is defined
   * @example 0.001
   */
  priceQuantity: string
  /** Prices of the article */
  pricing: {
    /** @example 0.001 */
    quantity?: string
    /** Amount of money */
    price?: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Pricing source information, returned only for vendor preview */
    source?: {
      type: ModelOrderOptionsTypeEnum
      priceListFilename?: string | null
    }
  }[]
}

export interface ModelOrderOptionsPricing {
  /** @example 0.001 */
  quantity?: string
  /** Amount of money */
  price?: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** Pricing source information, returned only for vendor preview */
  source?: {
    type: ModelOrderOptionsPricingTypeEnum
    priceListFilename?: string | null
  }
}

export interface ModelPricingSource {
  type: ModelPricingSourceTypeEnum
  priceListFilename?: string | null
}

/** Basic information about an Address */
export interface ModelAddress {
  /**
   * Name of the street
   * @example "Bodenseestraße"
   */
  streetName: string
  /**
   * Number of the street, building or house
   * @example "21A"
   */
  streetNumber: string
  /**
   * Post code
   * @example 81241
   */
  postalCode: string
  /**
   * Name of the city
   * @example "München"
   */
  city: string
  /**
   * Country code in ISO 3166-1 alfa-2
   * @example "DE"
   */
  countryCode: string
}

/** Delivery time details */
export interface ModelDeliveryInfo {
  /**
   * Maximum delivery time in days declared by vendor
   * @min 0
   * @example 3
   */
  days: number
  source: ModelDeliveryInfoSourceEnum
  /**
   * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2024-08-22T17:32:28.682Z"
   */
  updatedAt?: string | null
}

/** Stock level info */
export interface ModelStockInfo {
  value: ModelStockInfoValueEnum
  /**
   * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2024-08-22T17:32:28.682Z"
   */
  updatedAt?: string | null
}

/** Basic information of the person registering the Customer or Vendor */
export interface ModelPerson {
  title: ModelPersonTitleEnum
  firstName: string
  lastName: string
  phoneNumber: string
}

/** Common information of the company being registered as either Customer or Vendor */
export interface ModelCompanyRegistration {
  name: string
  /**
   * Country code in ISO 3166-1 alfa-2
   * @example "DE"
   */
  countryCode: string
  companyRegistrationNumber?: string
  /** @example "München" */
  cityCourt?: string
  vatId: string
  /**
   * Name of the street
   * @example "Bodenseestraße"
   */
  streetName: string
  /**
   * Number of the street, building or house
   * @example "21A"
   */
  streetNumber: string
  /**
   * Post code
   * @example 81241
   */
  postalCode: string
  /**
   * Name of the city
   * @example "München"
   */
  city: string
}

/** Common information of the company being registered as either Customer or Vendor */
export type ModelCompany = {
  name: string
  /**
   * Country code in ISO 3166-1 alfa-2
   * @example "DE"
   */
  countryCode: string
  companyRegistrationNumber?: string
  /** @example "München" */
  cityCourt?: string
  vatId: string
  /**
   * Name of the street
   * @example "Bodenseestraße"
   */
  streetName: string
  /**
   * Number of the street, building or house
   * @example "21A"
   */
  streetNumber: string
  /**
   * Post code
   * @example 81241
   */
  postalCode: string
  /**
   * Name of the city
   * @example "München"
   */
  city: string
} & {
  humanReadableId?: string
}

/** Common information of the company being registered as either Customer or Vendor */
export type ModelCompanyWithCurrency = ({
  name: string
  /**
   * Country code in ISO 3166-1 alfa-2
   * @example "DE"
   */
  countryCode: string
  companyRegistrationNumber?: string
  /** @example "München" */
  cityCourt?: string
  vatId: string
  /**
   * Name of the street
   * @example "Bodenseestraße"
   */
  streetName: string
  /**
   * Number of the street, building or house
   * @example "21A"
   */
  streetNumber: string
  /**
   * Post code
   * @example 81241
   */
  postalCode: string
  /**
   * Name of the city
   * @example "München"
   */
  city: string
} & {
  humanReadableId?: string
}) & {
  /**
   * ISO 4217 currency code
   * @pattern ^[A-Z]{3}$
   * @example "EUR"
   */
  currency: string
}

/** Response with upload URL and other data required to perform file upload. */
export interface ModelUploadUrl {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelUploadUrlMethodEnum
}

/** Response with download URL and other data required to perform file download. */
export interface ModelDownloadUrl {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Basic user data and information about assigned customers and vendors */
export interface ModelUserInfo {
  /**
   * User ID (the same as in identity provider)
   * @example "ef1b8af4-f511-4fe6-9b6b-0a548a9819fa"
   */
  id: string
  /**
   * Username (used to log-in to the system)
   * @example "user@example.com"
   */
  username: string
  /** List of vendors assigned to user, first is the default (if present). */
  vendors: ({
    /**
     * ID of vendor/customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Vendor/customer company name
     * @example "ACME Ltd."
     */
    name: string
  } & {
    /**
     * company VAT-ID
     * @example "FR12345678901"
     */
    vatId?: string
    /** Basic information about an Address */
    address?: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
  })[]
  /** List of customers assigned to user, first is the default (if present). */
  customers: ({
    /**
     * ID of vendor/customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Vendor/customer company name
     * @example "ACME Ltd."
     */
    name: string
  } & {
    /**
     * company VAT-ID
     * @example "FR12345678901"
     */
    vatId?: string
    /** Basic information about an Address */
    address?: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
  })[]
  /** User's settings */
  settings: {
    /** @example "en" */
    locale: string
    viewSettings?: Record<string, any>
  }
}

/** User's settings */
export interface ModelUserSettings {
  /** @example "en" */
  locale: string
  viewSettings?: Record<string, any>
}

export interface ModelModifyUserRequest {
  /**
   * UserSettingsUpdate
   * User's settings
   */
  settings: {
    /** @example "en" */
    locale?: string | null
    viewSettings?: Record<string, any>
  }
}

/** Basic data of customer or vendor */
export interface ModelBasicCustomerOrVendorData {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}

/** Basic data of customer or vendor */
export type ModelCompanyListItemData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
} & {
  /**
   * company VAT-ID
   * @example "FR12345678901"
   */
  vatId?: string
  /** Basic information about an Address */
  address?: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  }
}

/** Person with additional contact info */
export type ModelContactPerson = {
  title: ModelContactPersonTitleEnum
  firstName: string
  lastName: string
  phoneNumber: string
} & {
  email: string
}

/** Company data for tile (with details) */
export interface ModelBusinessCard {
  /** Common information of the company being registered as either Customer or Vendor */
  company: ({
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    humanReadableId?: string
  }) & {
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency: string
  }
  /** Person with additional contact info */
  contactPerson: {
    title: ModelBusinessCardTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  } & {
    email: string
  }
  /** Status of Vendor-Customer relationship */
  relationshipStatus?: ModelBusinessCardRelationshipStatusEnum
}

/** Status of Vendor-Customer relationship */
export type ModelMarketRelationshipStatus =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

export type ModelBusinessCategory =
  | 'AGRICULTURE'
  | 'CONSTRUCTION'
  | 'CHEMISTRY_RAW_MATERIALS'
  | 'SERVICES_MANUFACTURING'
  | 'E_COMMERCE'
  | 'ENERGY_ENVIRONMENT'
  | 'FINANCE_INSURANCE_REAL_ESTATE'
  | 'LEISURE'
  | 'SOCIETY'
  | 'TRADE'
  | 'IT_TELECOMMUNICATIONS'
  | 'RETAIL'
  | 'PHARMACY'
  | 'SPORTS'
  | 'ELECTRONICS'
  | 'TOURISM_TRAVEL'
  | 'MARKETING'
  | 'POLITICS'

export interface ModelUserConsents {
  /** @default false */
  newsletter: boolean
}

export interface ModelEclass {
  /** Version of eClass specification */
  version: ModelEclassVersionEnum
  /** eClass value formatted with dashes between groups ("12-34-56-78") */
  code?: string | null
  /**
   * eClass name
   * @example "Catering machinery"
   */
  name?: string | null
}

/** Version of eClass specification */
export type ModelEclassVersion = 'ECLASS_5_1' | 'ECLASS_7_1'

/**
 * eClass value formatted with dashes between groups ("12-34-56-78")
 * @pattern ^\d{2}-\d{2}-\d{2}-\d{2}$
 * @example "12-34-56-78"
 */
export type ModelEclassCode = string

/** Basic data of customer with assignment state */
export type ModelCustomerWithAssignment = {
  /**
   * Name of the street
   * @example "Bodenseestraße"
   */
  streetName: string
  /**
   * Number of the street, building or house
   * @example "21A"
   */
  streetNumber: string
  /**
   * Post code
   * @example 81241
   */
  postalCode: string
  /**
   * Name of the city
   * @example "München"
   */
  city: string
  /**
   * Country code in ISO 3166-1 alfa-2
   * @example "DE"
   */
  countryCode: string
} & {
  /**
   * ID of customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Customer company name
   * @example "ACME Ltd."
   */
  name: string
  /** True if customer is assigned to given price list */
  assigned: boolean
  /** @example "ACME" */
  vendorSpecificCustomerNumber: string
  /**
   * ISO 4217 currency code
   * @pattern ^[A-Z]{3}$
   * @example "EUR"
   */
  currency: string
}

/**
 * ISO 4217 currency code
 * @pattern ^[A-Z]{3}$
 * @example "EUR"
 */
export type ModelCurrency = string

/** Version of eClass specification */
export type ModelBasicArticleVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelBasicArticleSourceEnum = 'CATALOG' | 'STOCK_AND_DELIVERY_INFO'

export type ModelBasicArticleValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelBasicArticleTypeEnum = 'CATALOG' | 'PRICE_LIST'

export type ModelOrderOptionsTypeEnum = 'CATALOG' | 'PRICE_LIST'

export type ModelOrderOptionsPricingTypeEnum = 'CATALOG' | 'PRICE_LIST'

export type ModelPricingSourceTypeEnum = 'CATALOG' | 'PRICE_LIST'

export type ModelDeliveryInfoSourceEnum = 'CATALOG' | 'STOCK_AND_DELIVERY_INFO'

export type ModelStockInfoValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelPersonTitleEnum = 'MS' | 'MR' | 'OTHER'

/** HTTP method to use for upload */
export type ModelUploadUrlMethodEnum = 'PUT' | 'POST'

export type ModelContactPersonTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelBusinessCardTitleEnum = 'MS' | 'MR' | 'OTHER'

/** Status of Vendor-Customer relationship */
export type ModelBusinessCardRelationshipStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

/** Version of eClass specification */
export type ModelEclassVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

/** Type of the catalog */
export type ModelGetAssignableCatalogsTypeEnum = 'PLATFORM' | 'OCI'

export interface ModelGetAssignableCatalogsParams {
  /** Search term */
  term?: string
}

/** Search assignable catalogs (platform & OCI) of vendor response */
export interface ModelGetAssignableCatalogsData {
  /** List of assignable catalogs (platform & OCI) of vendor */
  items: {
    /**
     * Catalog name
     * @example "Winter collection"
     */
    name: string
    /**
     * Name of the source file of the catalog
     * @example "Winter_collection.zip"
     */
    fileName?: string
    /** ID and type of the catalog */
    genericCatalogId: {
      /**
       * Id of the catalog
       * @example "e02f4c97-98e0-49e8-bd0d-5ed0a2d8ab68"
       */
      id: string
      /** Type of the catalog */
      type: ModelGetAssignableCatalogsTypeEnum
    }
  }[]
}

/** Type of the catalog file. This field is irrelevant for OCI catalog */
export type ModelSearchCatalogsTypeEnum =
  | 'UNKNOWN'
  | 'BMECAT_1_2'
  | 'BMECAT_2005'
  | 'CSV'

/** Current status of catalog. This field is irrelevant for OCI catalog */
export type ModelSearchCatalogsStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/** Cause of failure, only for catalogs with status FAILED */
export type ModelSearchCatalogsErrorCodeEnum =
  | 'BROKEN_FILE'
  | 'CURRENCY_CHANGED'
  | 'INCONSISTENT_CURRENCY'
  | 'NO_ECLASS_GROUPS'

/** Category of vendor catalog */
export type ModelSearchCatalogsCatalogCategoryEnum =
  | 'UNASSIGNED'
  | 'CLOSED'
  | 'OPEN'
  | 'OCI'

/** Catalogs search request */
export interface ModelSearchCatalogsPayload {
  /** Phrase to find */
  term?: string
  /** Category of vendor catalog */
  catalogCategory: ModelSearchCatalogsCatalogCategoryEnum
}

export interface ModelSearchCatalogsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,asc`, `name,desc`, `lastUpdatedAt,asc`, `lastUpdatedAt,desc`]).
   * Default sorting: `lastUpdatedAt,desc`.
   */
  sort?: string[]
}

/** Catalogs search response */
export interface ModelSearchCatalogsData {
  /** List of found catalogs from search */
  items?: {
    /**
     * Catalog ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Active catalog version ID. This field is irrelevant for OCI catalog
     * @example "747e87ed-1350-4926-a8b0-3d6686963d30"
     */
    activeCatalogVersionId?: string
    /**
     * Name of the catalog
     * @example "Winter 2022 collection"
     */
    name: string
    /**
     * Name of the catalog file. This field is irrelevant for OCI catalog
     * @example "tools.xml"
     */
    fileName?: string
    /** Type of the catalog file. This field is irrelevant for OCI catalog */
    type?: ModelSearchCatalogsTypeEnum
    /** Current status of catalog. This field is irrelevant for OCI catalog */
    status?: ModelSearchCatalogsStatusEnum
    /** Cause of failure, only for catalogs with status FAILED */
    errorCode?: ModelSearchCatalogsErrorCodeEnum
    /** ISO 4217 currency code */
    currency?: string | null
    /**
     * Number of customers assigned to the catalog
     * @example 17
     */
    assignedCustomersCount: number
    /**
     * Number of items successfully imported to the catalog. This field is irrelevant for OCI catalog
     * @example 321
     */
    itemsCount?: number
    /**
     * Number of categories in catalog. This field is irrelevant for OCI catalog
     * @example 3
     */
    categoriesCount?: number
    /**
     * Number of errors encountered during catalog import. This field is irrelevant for OCI catalog
     * @example 2
     */
    errorsCount?: number
    /**
     * Number of warnings encountered during catalog import. This field is irrelevant for OCI catalog
     * @example 27
     */
    warningsCount?: number
    /**
     * Full date and time of catalog upload formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    lastUpdatedAt: string
    /** True if catalog was uploaded via FTP, false if it was uploaded manually.  This field is irrelevant for OCI catalog. */
    updatedAutomatically?: boolean
    /**
     * Name and surname of user who has uploaded the catalog
     * @example "Erika Mustermann"
     */
    lastUpdatedBy: string
  }[]
  /** Found catalogs count per category */
  counts?: {
    /** Count of unassigned catalogs */
    unassigned?: number
    /** Count of open catalogs */
    open?: number
    /** Count of open catalogs */
    closed?: number
    /** Count of open catalogs */
    oci?: number
  }
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Definition of uploaded catalog file to be processed */
export interface ModelPostCatalogPayload {
  /**
   * Name of the catalog to be created for this file. This name has to be unique per vendor.
   * @example "Winter 2022 sale"
   */
  catalogName: string
  /**
   * Original file name of the uploaded file
   * @example "catalog2022winter.xml"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/file123456.xml"
   */
  path: string
}

export type ModelPostCatalogData = any

/**
 * Error
 * Error
 */
export type ModelPostCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of vendor catalogs brief info */
export type ModelGetCatalogNamesData = {
  /**
   * Catalog ID
   * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
   */
  id: string
  /**
   * Active catalog version ID
   * @example "747e87ed-1350-4926-a8b0-3d6686963d30"
   */
  activeCatalogVersionId: string
  /**
   * Name of the catalog
   * @example "Winter 2022 collection"
   */
  name: string
}[]

/** HTTP method to use for upload */
export type ModelGetCatalogUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetCatalogUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetCatalogUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetCatalogUploadUrlMethodEnum
}

/**
 * Error
 * Error
 */
export type ModelGetCatalogUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetCatalogUploadUrlParams1MethodEnum = 'PUT' | 'POST'

/** Definition of uploaded catalog file to be processed to update existing catalog */
export interface ModelUpdateCatalogFilePayload {
  /**
   * Original file name of the uploaded file
   * @example "catalog2022winter_v2.xml"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/file123456_2.xml"
   */
  path: string
}

export type ModelUpdateCatalogFileData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCatalogFileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request for changing catalog or catalog file name */
export interface ModelRenameCatalogPayload {
  /**
   * New name of the catalog. This name has to be unique per vendor.
   * @example "Spring 2022 sale"
   */
  catalogName: string
  /**
   * The new file name to be set for this catalog
   * @example "electric_tools.xml"
   */
  catalogFileName: string
}

export type ModelRenameCatalogData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelRenameCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCatalogData = any

/**
 * Error
 * Error
 */
export type ModelDeleteCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with customers assigned to catalog */
export interface ModelListCatalogAsignmentToCustomersData {
  /** List of customers assigned to catalog */
  customers: ({
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /**
     * ID of customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Customer company name
     * @example "ACME Ltd."
     */
    name: string
    /** True if customer is assigned to given price list */
    assigned: boolean
    /** @example "ACME" */
    vendorSpecificCustomerNumber: string
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency: string
  })[]
}

/**
 * Error
 * Error
 */
export type ModelListCatalogAsignmentToCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to update list of customers assigned to catalog */
export interface ModelAssignCustomersToCatalogPayload {
  /** List of IDs of customers assigned to the catalog */
  customerIds: string[]
}

export type ModelAssignCustomersToCatalogData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelAssignCustomersToCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelOpenCatalogData = any

/** Error */
export type ModelOpenCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUnpublishCatalogData = any

/** Error */
export type ModelUnpublishCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCatalogImportReportData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCatalogFileDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetCatalogFileDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCatalogFtpConnectionConfigurationData = {
  /** Host or IP of the FTP server */
  hostname: string
  /**
   * Path where the files should be uploaded
   * @example "/directory"
   */
  directory: string
  /** @example 1740051 */
  username: string
  /** @example "super$3cret!" */
  password: string
} & {
  /**
   * @min 1
   * @max 65535
   * @example 21
   */
  port: number
}

/** Error */
export type ModelGetCatalogFtpConnectionConfigurationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Method which the OCI Catalog data is obtained */
export type ModelPostOciCatalogMethodEnum = 'GET' | 'POST'

/** Body of the OCI Catalog create or update request */
export interface ModelPostOciCatalogPayload {
  /**
   * Name of the OCI catalog
   * @example "Winter 2022 collection"
   */
  name: string
  /**
   * URL where the OCI Catalog is available
   * @format url
   * @example "https://docs/path/to/file"
   */
  url: string
  /** Method which the OCI Catalog data is obtained */
  method: ModelPostOciCatalogMethodEnum
}

export type ModelPostOciCatalogData = any

/**
 * Error
 * Error
 */
export type ModelPostOciCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Method which the OCI Catalog data is obtained */
export type ModelGetOciCatalogMethodEnum = 'GET' | 'POST'

/** Description of a single OCI catalog */
export type ModelGetOciCatalogData = {
  /**
   * Name of the OCI catalog
   * @example "Winter 2022 collection"
   */
  name: string
  /**
   * URL where the OCI Catalog is available
   * @format url
   * @example "https://docs/path/to/file"
   */
  url: string
  /** Method which the OCI Catalog data is obtained */
  method: ModelGetOciCatalogMethodEnum
} & {
  /**
   * OCI Catalog ID
   * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
   */
  id: string
  /**
   * Number of customers assigned to the catalog
   * @example 17
   */
  assignedCustomersCount: number
  /**
   * Full date and time of OCI catalog creation formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  createdAt: string
  /**
   * Full date and time of OCI catalog update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  lastUpdatedAt: string
  /**
   * Name and surname of user who has updated the catalog
   * @example "Erika Mustermann"
   */
  lastUpdatedBy: string
}

/** Method which the OCI Catalog data is obtained */
export type ModelUpdateOciCatalogMethodEnum = 'GET' | 'POST'

/** Body of the OCI Catalog create or update request */
export interface ModelUpdateOciCatalogPayload {
  /**
   * Name of the OCI catalog
   * @example "Winter 2022 collection"
   */
  name: string
  /**
   * URL where the OCI Catalog is available
   * @format url
   * @example "https://docs/path/to/file"
   */
  url: string
  /** Method which the OCI Catalog data is obtained */
  method: ModelUpdateOciCatalogMethodEnum
}

export type ModelUpdateOciCatalogData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateOciCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteOciCatalogData = any

/**
 * Error
 * Error
 */
export type ModelDeleteOciCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with customers assigned to catalog */
export interface ModelListOciCatalogAssignmentToCustomersData {
  /** List of customers assigned to catalog */
  customers: ({
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /**
     * ID of customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Customer company name
     * @example "ACME Ltd."
     */
    name: string
    /** True if customer is assigned to given price list */
    assigned: boolean
    /** @example "ACME" */
    vendorSpecificCustomerNumber: string
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency: string
  })[]
}

/** Error */
export type ModelListOciCatalogAssignmentToCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to update list of customers assigned to catalog */
export interface ModelAssignCustomersToOciCatalogPayload {
  /** List of IDs of customers assigned to the catalog */
  customerIds: string[]
}

export type ModelAssignCustomersToOciCatalogData = any

/**
 * Error
 * Error
 */
export type ModelAssignCustomersToOciCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUnpublishOciCatalogData = any

/** Error */
export type ModelUnpublishOciCatalogError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetPriceListsStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/** Cause of failure, only for files with status FAILED */
export type ModelGetPriceListsErrorCodeEnum = 'BROKEN_FILE' | 'CURRENCY_CHANGED'

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetPriceListsStatusEnum1 =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/** Cause of failure, only for files with status FAILED */
export type ModelGetPriceListsErrorCodeEnum1 =
  | 'BROKEN_FILE'
  | 'CURRENCY_CHANGED'

/** Vendor's price lists grouped by assigned and unassigned */
export interface ModelGetPriceListsData {
  /** List of assigned price lists */
  assigned: {
    /**
     * Price List ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the price list
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the price list file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetPriceListsStatusEnum
    /**
     * Number of customers assigned to this price list
     * @example 3
     */
    assignedCustomersCount: number
    /**
     * Number of prices which encountered errors during import
     * @example 2
     */
    errorPrices: number
    /**
     * Number of prices which encountered warnings during import
     * @example 4
     */
    warningPrices: number
    /**
     * Number of prices which were successfully imported
     * @example 21
     */
    successPrices: number
    /**
     * Full date and time of price list upload formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /** True if pricelist was uploaded via FTP, false if it was uploaded manually */
    uploadedAutomatically: boolean
    /**
     * Name and surname of user who has uploaded the pricelist
     * @example "Erika Mustermann"
     */
    uploadedBy: string
    /**
     * Date when prices from pricelist start being visible to assigned customers; defaults to upload date
     * @format date
     * @example "2022-12-02"
     */
    validFrom?: string
    /**
     * Date when prices from pricelist stop being visible to assigned customers
     * @format date
     * @example "2022-12-10"
     */
    validTo?: string
    /** Cause of failure, only for files with status FAILED */
    errorCode?: ModelGetPriceListsErrorCodeEnum
    /** ISO 4217 currency code */
    currency?: string | null
  }[]
  /** List of unassigned price lists */
  unassigned: {
    /**
     * Price List ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the price list
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the price list file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetPriceListsStatusEnum1
    /**
     * Number of customers assigned to this price list
     * @example 3
     */
    assignedCustomersCount: number
    /**
     * Number of prices which encountered errors during import
     * @example 2
     */
    errorPrices: number
    /**
     * Number of prices which encountered warnings during import
     * @example 4
     */
    warningPrices: number
    /**
     * Number of prices which were successfully imported
     * @example 21
     */
    successPrices: number
    /**
     * Full date and time of price list upload formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /** True if pricelist was uploaded via FTP, false if it was uploaded manually */
    uploadedAutomatically: boolean
    /**
     * Name and surname of user who has uploaded the pricelist
     * @example "Erika Mustermann"
     */
    uploadedBy: string
    /**
     * Date when prices from pricelist start being visible to assigned customers; defaults to upload date
     * @format date
     * @example "2022-12-02"
     */
    validFrom?: string
    /**
     * Date when prices from pricelist stop being visible to assigned customers
     * @format date
     * @example "2022-12-10"
     */
    validTo?: string
    /** Cause of failure, only for files with status FAILED */
    errorCode?: ModelGetPriceListsErrorCodeEnum1
    /** ISO 4217 currency code */
    currency?: string | null
  }[]
}

/** Definition of uploaded price list file to be processed */
export interface ModelPostPriceListPayload {
  /**
   * Name of the price list to be created for this file
   * @example "Small customers"
   */
  priceListName: string
  /**
   * Original file name of the uploaded file
   * @example "priceListForSmallCustomers.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/priceListForSmallCustomers.csv"
   */
  path: string
}

export type ModelPostPriceListData = any

/**
 * Error
 * Error
 */
export type ModelPostPriceListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetAssignablePriceListsParams {
  /** Search term */
  term?: string
}

/** Search assignable price lists of vendor response */
export interface ModelGetAssignablePriceListsData {
  /** List of assignable assignable price lists of vendor */
  items: {
    /**
     * Price list name
     * @example "Black friday 2023"
     */
    name: string
    /**
     * Name of the source file of the price list
     * @example "BlackFriday2023.zip"
     */
    fileName: string
    /**
     * Id of the price list
     * @example "6d14b341-25f4-45ba-ac5b-b912f28a6a95"
     */
    id: string
  }[]
}

/** HTTP method to use for upload */
export type ModelGetPriceListUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetPriceListUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum1
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum1 = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetPriceListUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetPriceListUploadUrlMethodEnum
}

/** Error */
export type ModelGetPriceListUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetPriceListUploadUrlParams1MethodEnum = 'PUT' | 'POST'

/** Definition of uploaded price list file to be processed to update existing price list */
export interface ModelUpdatePriceListFilePayload {
  /**
   * Original file name of the uploaded file
   * @example "priceListForSmallCustomersUpdated.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/priceListForSmallCustomersUpdated.csv"
   */
  path: string
}

export type ModelUpdatePriceListFileData = any

/**
 * Error
 * Error
 */
export type ModelUpdatePriceListFileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request for changing price list name and file name */
export interface ModelUpdatePriceListPayload {
  /**
   * The new name to be set for this price list
   * @example "Medium customers"
   */
  priceListName: string
  /**
   * The new file name to be set for this price list
   * @example "medium_customers.csv"
   */
  priceListFileName: string
}

export type ModelUpdatePriceListData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdatePriceListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeletePriceListData = any

/**
 * Error
 * Error
 */
export type ModelDeletePriceListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelListPriceListAssignmentToCustomersData = ({
  /**
   * Name of the street
   * @example "Bodenseestraße"
   */
  streetName: string
  /**
   * Number of the street, building or house
   * @example "21A"
   */
  streetNumber: string
  /**
   * Post code
   * @example 81241
   */
  postalCode: string
  /**
   * Name of the city
   * @example "München"
   */
  city: string
  /**
   * Country code in ISO 3166-1 alfa-2
   * @example "DE"
   */
  countryCode: string
} & {
  /**
   * ID of customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Customer company name
   * @example "ACME Ltd."
   */
  name: string
  /** True if customer is assigned to given price list */
  assigned: boolean
  /** @example "ACME" */
  vendorSpecificCustomerNumber: string
  /**
   * ISO 4217 currency code
   * @pattern ^[A-Z]{3}$
   * @example "EUR"
   */
  currency: string
})[]

/** Error */
export type ModelListPriceListAssignmentToCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to update list of customers assigned to price list */
export interface ModelAssignCustomersToPriceListPayload {
  /** List of IDs of customers to be assigned to the price list */
  customerIds: string[]
}

export type ModelAssignCustomersToPriceListData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelAssignCustomersToPriceListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUnassignPriceListData = any

/** Error */
export type ModelUnassignPriceListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetPriceListImportReportData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetPriceListDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetPriceListDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetPriceListFtpConnectionConfigurationData = {
  /** Host or IP of the FTP server */
  hostname: string
  /**
   * Path where the files should be uploaded
   * @example "/directory"
   */
  directory: string
  /** @example 1740051 */
  username: string
  /** @example "super$3cret!" */
  password: string
} & {
  /**
   * @min 1
   * @max 65535
   * @example 21
   */
  port: number
}

/** Error */
export type ModelGetPriceListFtpConnectionConfigurationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetStockAndDeliveryListsStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetStockAndDeliveryListsStatusEnum1 =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/** Vendor's stock and delivery lists grouped by assigned and unassigned */
export interface ModelGetStockAndDeliveryListsData {
  /** List of assigned stock and delivery lists */
  assigned: {
    /**
     * Stock and delivery List ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the stock and delivery list
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the stock and delivery list file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetStockAndDeliveryListsStatusEnum
    /**
     * Number of customers assigned to this stock and delivery list
     * @example 3
     */
    assignedCustomersCount: number
    /**
     * Number of items which encountered errors during import
     * @example 2
     */
    errorItems: number
    /**
     * Number of items which encountered warnings during import
     * @example 4
     */
    warningItems: number
    /**
     * Number of items which were successfully imported
     * @example 21
     */
    successItems: number
    /**
     * Full date and time of stock and delivery list upload
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /** True if stockAndDeliveryList was uploaded via FTP, false if it was uploaded manually */
    uploadedAutomatically: boolean
    /**
     * Name and surname of user who has uploaded the stockAndDeliveryList
     * @example "Erika Counterman"
     */
    uploadedBy: string
  }[]
  /** List of unassigned stock and delivery lists */
  unassigned: {
    /**
     * Stock and delivery List ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the stock and delivery list
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the stock and delivery list file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetStockAndDeliveryListsStatusEnum1
    /**
     * Number of customers assigned to this stock and delivery list
     * @example 3
     */
    assignedCustomersCount: number
    /**
     * Number of items which encountered errors during import
     * @example 2
     */
    errorItems: number
    /**
     * Number of items which encountered warnings during import
     * @example 4
     */
    warningItems: number
    /**
     * Number of items which were successfully imported
     * @example 21
     */
    successItems: number
    /**
     * Full date and time of stock and delivery list upload
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /** True if stockAndDeliveryList was uploaded via FTP, false if it was uploaded manually */
    uploadedAutomatically: boolean
    /**
     * Name and surname of user who has uploaded the stockAndDeliveryList
     * @example "Erika Counterman"
     */
    uploadedBy: string
  }[]
}

/** Definition of uploaded stock and delivery list file to be processed */
export interface ModelCreateStockAndDeliveryListPayload {
  /**
   * Name of the stock and delivery list to be created for this file
   * @example "Stock and delivery info"
   */
  name: string
  /**
   * Original file name of the uploaded file
   * @example "stockAndDeliveryListForSmallCustomers.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/stockAndDeliveryListForSmallCustomers.csv"
   */
  path: string
}

export type ModelCreateStockAndDeliveryListData = any

/**
 * Error
 * Error
 */
export type ModelCreateStockAndDeliveryListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetStockAndDeliveryListFtpConnectionConfigurationData = {
  /** Host or IP of the FTP server */
  hostname: string
  /**
   * Path where the files should be uploaded
   * @example "/directory"
   */
  directory: string
  /** @example 1740051 */
  username: string
  /** @example "super$3cret!" */
  password: string
} & {
  /**
   * @min 1
   * @max 65535
   * @example 21
   */
  ftpPort: number
  /**
   * @min 1
   * @max 65535
   * @example 22
   */
  sftpPort: number
}

/** Error */
export type ModelGetStockAndDeliveryListFtpConnectionConfigurationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method to use for upload */
export type ModelGetStockAndDeliveryListUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetStockAndDeliveryListUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum2
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum2 = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetStockAndDeliveryListUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetStockAndDeliveryListUploadUrlMethodEnum
}

/** Error */
export type ModelGetStockAndDeliveryListUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetStockAndDeliveryListUploadUrlParams1MethodEnum =
  | 'PUT'
  | 'POST'

/** Definition of uploaded stock and delivery list file to be processed to update existing stock and delivery list */
export interface ModelUpdateStockAndDeliveryListFilePayload {
  /**
   * Original file name of the uploaded file
   * @example "stockAndDeliveryListForSmallCustomersUpdated.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/stockAndDeliveryListForSmallCustomersUpdated.csv"
   */
  path: string
}

export type ModelUpdateStockAndDeliveryListFileData = any

/**
 * Error
 * Error
 */
export type ModelUpdateStockAndDeliveryListFileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request for changing stock and delivery list name and file name */
export interface ModelUpdateStockAndDeliveryListPayload {
  /**
   * The new name to be set for this stock and delivery list
   * @example "Medium customers"
   */
  name: string
  /**
   * The new file name to be set for this stock and delivery list
   * @example "medium_customers.csv"
   */
  fileName: string
}

export type ModelUpdateStockAndDeliveryListData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateStockAndDeliveryListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteStockAndDeliveryListData = any

/** Error */
export type ModelDeleteStockAndDeliveryListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetStockAndDeliveryListImportReportData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetStockAndDeliveryListDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetStockAndDeliveryListDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetCustomerArticleNumbersStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetCustomerArticleNumbersStatusEnum1 =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/** Vendor's customer article numbers grouped by assigned and unassigned */
export interface ModelGetCustomerArticleNumbersData {
  /** List of assigned customer article numbers */
  assigned: {
    /**
     * Customer article numbers list ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the customer article numbers
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the customer article numbers file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetCustomerArticleNumbersStatusEnum
    /**
     * Number of customers assigned to this customer article numbers
     * @example 3
     */
    assignedCustomersCount: number
    /**
     * Number of prices which encoutered errors during import
     * @example 2
     */
    errorItems: number
    /**
     * Number of prices which encoutered warnings during import
     * @example 4
     */
    warningItems: number
    /**
     * Number of prices which were successfully imported
     * @example 21
     */
    successItems: number
    /**
     * Full date and time of customer article numbers upload formatted according to \[RFC 3339, section 5.6\](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /** True if customer article numbers was uploaded via FTP, false if it was uploaded manually */
    uploadedAutomatically: boolean
    /**
     * Name and surname of user who has uploaded the customer article numbers
     * @example "Erika Mustermann"
     */
    uploadedBy?: string | null
  }[]
  /** List of unassigned customer article numbers */
  unassigned: {
    /**
     * Customer article numbers list ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the customer article numbers
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the customer article numbers file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetCustomerArticleNumbersStatusEnum1
    /**
     * Number of customers assigned to this customer article numbers
     * @example 3
     */
    assignedCustomersCount: number
    /**
     * Number of prices which encoutered errors during import
     * @example 2
     */
    errorItems: number
    /**
     * Number of prices which encoutered warnings during import
     * @example 4
     */
    warningItems: number
    /**
     * Number of prices which were successfully imported
     * @example 21
     */
    successItems: number
    /**
     * Full date and time of customer article numbers upload formatted according to \[RFC 3339, section 5.6\](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /** True if customer article numbers was uploaded via FTP, false if it was uploaded manually */
    uploadedAutomatically: boolean
    /**
     * Name and surname of user who has uploaded the customer article numbers
     * @example "Erika Mustermann"
     */
    uploadedBy?: string | null
  }[]
}

/** Definition of uploaded customer article numbers file to be processed */
export interface ModelPostCustomerArticleNumbersPayload {
  /**
   * Name of the customer article numbers to be created for this file
   * @example "Small customers"
   */
  name: string
  /**
   * Original file name of the uploaded file
   * @example "customerArticleNumbersForSmallCustomers.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/customerArticleNumbersForSmallCustomers.csv"
   */
  path: string
}

export type ModelPostCustomerArticleNumbersData = any

/**
 * Error
 * Error
 */
export type ModelPostCustomerArticleNumbersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorCustomerArticleNumbersUploadSettingsData = {
  /** Host or IP of the FTP server */
  hostname: string
  /**
   * Path where the files should be uploaded
   * @example "/directory"
   */
  directory: string
  /** @example 1740051 */
  username: string
  /** @example "super$3cret!" */
  password: string
} & {
  /**
   * @min 1
   * @max 65535
   * @example 21
   */
  ftpPort: number
  /**
   * @min 1
   * @max 65535
   * @example 22
   */
  sftpPort: number
}

/** HTTP method to use for upload */
export type ModelGetCustomerArticleNumbersUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetCustomerArticleNumbersUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum3
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum3 = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetCustomerArticleNumbersUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetCustomerArticleNumbersUploadUrlMethodEnum
}

/** Error */
export type ModelGetCustomerArticleNumbersUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetCustomerArticleNumbersUploadUrlParams1MethodEnum =
  | 'PUT'
  | 'POST'

/** Definition of uploaded customer article numbers file to be processed to update existing customer article numbers */
export interface ModelUpdateCustomerArticleNumbersFilePayload {
  /**
   * Original file name of the uploaded file
   * @example "customerArticleNumbersForSmallCustomersUpdated.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/customerArticleNumbersForSmallCustomersUpdated.csv"
   */
  path: string
}

export type ModelUpdateCustomerArticleNumbersFileData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerArticleNumbersFileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request for changing customer article numbers name and file name */
export interface ModelUpdateCustomerArticleNumbersPayload {
  /**
   * The new name to be set for this customer article numbers
   * @example "Medium customers"
   */
  name: string
  /**
   * The new file name to be set for this customer article numbers
   * @example "medium_customers.csv"
   */
  fileName: string
}

export type ModelUpdateCustomerArticleNumbersData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateCustomerArticleNumbersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteVendorCustomerArticleNumbersData = any

/**
 * Error
 * Error
 */
export type ModelDeleteVendorCustomerArticleNumbersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelListCustomerArticleNumbersAssignmentToCustomersData {
  assigned: (({
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /**
     * ID of customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Customer company name
     * @example "ACME Ltd."
     */
    name: string
    /** @example "ACME" */
    vendorSpecificCustomerNumber: string
  }) & {
    /** True if customer is assigned to given customer article numbers */
    assigned: boolean
  })[]
  unassigned: (({
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /**
     * ID of customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Customer company name
     * @example "ACME Ltd."
     */
    name: string
    /** @example "ACME" */
    vendorSpecificCustomerNumber: string
  }) & {
    /** True if customer is assigned to given customer article numbers */
    assigned: boolean
  })[]
  assignedToOtherLists: (({
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /**
     * ID of customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Customer company name
     * @example "ACME Ltd."
     */
    name: string
    /** @example "ACME" */
    vendorSpecificCustomerNumber: string
  }) & {
    /**
     * ID of the customer article numbers list assigned to this customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    customerArticleNumbersListId: string
    /**
     * Name of the customer article numbers list assigned to this customer
     * @example "Customer Article Numbers 2"
     */
    customerArticleNumbersName: string
  })[]
}

/** Error */
export type ModelListCustomerArticleNumbersAssignmentToCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to update list of customers assigned to customer article numbers */
export interface ModelAssignCustomersToCustomerArticleNumbersPayload {
  /** List of IDs of customers to be assigned to the customer article numbers */
  customerIds: string[]
}

export type ModelAssignCustomersToCustomerArticleNumbersData = any

/**
 * Error
 * Error
 */
export type ModelAssignCustomersToCustomerArticleNumbersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to unassign a customer assigned to customer article numbers */
export interface ModelUnassignCustomerArticleNumbersPayload {
  /**
   * ID of customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  customerId: string
}

export type ModelUnassignCustomerArticleNumbersData = any

/** Error */
export type ModelUnassignCustomerArticleNumbersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCustomerArticleNumbersImportReportData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCustomerArticleNumbersDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetCustomerArticleNumbersDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetMediaArchivesStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

export interface ModelGetMediaArchivesParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** Response with list of vendor media archives */
export interface ModelGetMediaArchivesData {
  /** List of search results */
  items: {
    /**
     * Media Archive Id
     * @example "3f1ab7b9-84ea-4b70-ac44-512d8d471356"
     */
    id: string
    /**
     * Name of the media archive
     * @example "Image_files.zip"
     */
    name: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetMediaArchivesStatusEnum
    /**
     * Number of items sucessfully processed
     * @example 321
     */
    itemsCount: number
    /**
     * Number of errors encountered during processing
     * @example 2
     */
    errorsCount: number
    /**
     * Full date and time of media archive upload formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Definition of uploaded media archive to be processed */
export interface ModelPostMediaArchivePayload {
  /**
   * Original file name of the uploaded file
   * @example "media.zip"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/file123456.zip"
   */
  path: string
}

export type ModelPostMediaArchiveData = any

/** Error */
export type ModelPostMediaArchiveError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method to use for upload */
export type ModelGetMediaArchiveUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetMediaArchiveUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum4
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum4 = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetMediaArchiveUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetMediaArchiveUploadUrlMethodEnum
}

/**
 * Error
 * Error
 */
export type ModelGetMediaArchiveUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetMediaArchiveUploadUrlParams1MethodEnum = 'PUT' | 'POST'

/** Response with download URL and other data required to perform file download. */
export interface ModelGetMediaArchiveFileData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetMediaArchiveReportData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

export type ModelGetVendorEvaluationStatusDetailsTierEnum =
  | 'IN_EVALUATION'
  | 'BASIC'
  | 'BRONZE'
  | 'CLASSIC'
  | 'SILVER'
  | 'GOLD'
  | 'VIP'

/** Full details of VendorEvaluationStatus */
export type ModelGetVendorEvaluationStatusDetailsData = ({
  tier: ModelGetVendorEvaluationStatusDetailsTierEnum
  /**
   * percentage number (0-100) as a string; should NOT contain % sign
   * @format number
   * @min 0
   * @max 100
   * @example 0.5
   */
  openMarketCommissionRate: string
  /**
   * percentage number (0-100) as a string; should NOT contain % sign
   * @format number
   * @min 0
   * @max 100
   * @example 6.25
   */
  closedMarketCommissionRate: string
} & object) & {
  /**
   * @format date-time
   * @example "2021-06-25T11:10:57.849+00:00"
   */
  activeAt: string
  /**
   * @format date-time
   * @example "2021-09-25T11:10:57.849+00:00"
   */
  reevaluateAt?: string
}

/** Error */
export type ModelGetVendorEvaluationStatusDetailsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The format in which vendor will receive the orders */
export type ModelGetOrderDataExchangeFormatEnum =
  | 'OPENTRANS_1'
  | 'OPENTRANS_21'
  | 'S2XML'
  | 'PLAIN_TEXT'

/** Order exchange method */
export type ModelGetOrderDataExchangeMethodEnum =
  | 'HTTPS_POST'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'
  | 'FTP_PULL'
  | 'SFTP_PULL'
  | 'EMAIL'

export interface ModelGetOrderDataExchangeData {
  defaultCustomerNumber?: string
  /** Settings that enables vendor to receive orders */
  transportSettings?: {
    /** The format in which vendor will receive the orders */
    format: ModelGetOrderDataExchangeFormatEnum
    /** Order exchange method */
    method: ModelGetOrderDataExchangeMethodEnum
    /** The settings needed to successfully send orders via the chosen method */
    connectionSettings: {
      /**
       * URL where to upload files
       * @example "https://www.abc.com"
       */
      url?: string
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /** Host or IP of the FTP server */
      hostname?: string
      /**
       * Optional; path where the files for orders will be located
       * @example "/orders"
       */
      directory?: string
      /**
       * @min 1
       * @max 65535
       * @example 21
       */
      port?: number
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /**
       * The email address to which the orders will be sent
       * @example "orders@vendor1.com"
       */
      email?: string
      /**
       * The email subject used for sent emails
       * @maxLength 988
       * @example "SimpleSystem ERP Integration"
       */
      subject?: string
    }
  }
}

/** The format in which vendor will receive the orders */
export type ModelPatchOrderDataExchangeFormatEnum =
  | 'OPENTRANS_1'
  | 'OPENTRANS_21'
  | 'S2XML'
  | 'PLAIN_TEXT'

/** Order exchange method */
export type ModelPatchOrderDataExchangeMethodEnum =
  | 'HTTPS_POST'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'
  | 'FTP_PULL'
  | 'SFTP_PULL'
  | 'EMAIL'

export interface ModelPatchOrderDataExchangePayload {
  defaultCustomerNumber?: string | null
  /** Settings that enables vendor to receive orders */
  transportSettings?: {
    /** The format in which vendor will receive the orders */
    format: ModelPatchOrderDataExchangeFormatEnum
    /** Order exchange method */
    method: ModelPatchOrderDataExchangeMethodEnum
    /** The settings needed to successfully send orders via the chosen method */
    connectionSettings: {
      /**
       * URL where to upload files
       * @example "https://www.abc.com"
       */
      url?: string
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /** Host or IP of the FTP server */
      hostname?: string
      /**
       * Optional; path where the files for orders will be located
       * @example "/orders"
       */
      directory?: string
      /**
       * @min 1
       * @max 65535
       * @example 21
       */
      port?: number
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /**
       * The email address to which the orders will be sent
       * @example "orders@vendor1.com"
       */
      email?: string
      /**
       * The email subject used for sent emails
       * @maxLength 988
       * @example "SimpleSystem ERP Integration"
       */
      subject?: string
    }
  } | null
}

export type ModelPatchOrderDataExchangeData = any

/** Error */
export type ModelPatchOrderDataExchangeError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Order exchange method */
export type ModelGetOrderDataExchangePullConfigurationMethodEnum =
  | 'HTTPS_POST'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'
  | 'FTP_PULL'
  | 'SFTP_PULL'
  | 'EMAIL'

/** Configuration data for FTP_PULL and SFTP_PULL */
export type ModelGetOrderDataExchangePullConfigurationData = {
  /** Order exchange method */
  method: ModelGetOrderDataExchangePullConfigurationMethodEnum
  /**
   * Hostname of the FTP server
   * @example "ftp.server.com"
   */
  hostname: string
  /**
   * Port number
   * @min 1
   * @max 65535
   * @example 21
   */
  port: number
  /**
   * Name of the directory where the files for orders is located
   * @example "/orders"
   */
  directory?: string
  /**
   * Username of the FTP account
   * @example "vendor_1444"
   */
  username: string
  /**
   * Password of the FTP account
   * @example "super$3cret!"
   */
  password: string
}[]

/** Error */
export type ModelGetOrderDataExchangePullConfigurationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Sales overview */
export interface ModelGetVendorSalesOverviewData {
  hasOrders: boolean
}

/** Sales statistics */
export type ModelGetVendorMonthlySalesData = {
  /**
   * The number of orders
   * @example 19
   */
  ordersCount: number
  /** Total amount of orders */
  ordersTotalAmount: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
} & {
  /** Daily sales statistics */
  dailySales: {
    /**
     * @format date
     * @example "2022-06-09"
     */
    date: string
    /** Sales statistics */
    sales: {
      /**
       * The number of orders
       * @example 19
       */
      ordersCount: number
      /** Total amount of orders */
      ordersTotalAmount: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
    }
  }[]
}

/** Type of the cart info */
export type ModelGetVendorCartInfosTypeEnum =
  | 'DEFAULT_OPEN_MP'
  | 'DEFAULT_PARTNER_MP'
  | 'PERSONALISED_PARTNER'

export interface ModelGetVendorCartInfosParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,asc`, `name,desc`]).
   * DEFAULT_OPEN_MP and DEFAULT_PARTNER_MP cart infos are always on the top.
   * Other cart infos are sorted by sort param.
   * Default sorting: `name,asc`.
   */
  sort?: string[]
}

export interface ModelGetVendorCartInfosData {
  hasDefaultOpenMP: boolean
  hasDefaultPartnerMP: boolean
  items: ({
    /** @example "General tax message" */
    name: string
    /** Type of the cart info */
    type: ModelGetVendorCartInfosTypeEnum
    /** @example "A W9 from vendors is not required when payments..." */
    message: string
  } & {
    /**
     * Id of cart info
     * @example "e02f4c97-98e0-49e8-bd0d-5ed0a2d8ab68"
     */
    id: string
    /**
     * @format date-time
     * @example "2024-02-22T11:10:57.849+00:00"
     */
    createdAt: string
    /** @example 5 */
    assignedCustomersCount: number
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Type of the cart info */
export type ModelPostVendorCartInfosTypeEnum =
  | 'DEFAULT_OPEN_MP'
  | 'DEFAULT_PARTNER_MP'
  | 'PERSONALISED_PARTNER'

export interface ModelPostVendorCartInfosPayload {
  /** @example "General tax message" */
  name: string
  /** Type of the cart info */
  type: ModelPostVendorCartInfosTypeEnum
  /** @example "A W9 from vendors is not required when payments..." */
  message: string
}

export type ModelPostVendorCartInfosData = any

/** Error */
export type ModelPostVendorCartInfosError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Type of the cart info */
export type ModelUpdateVendorCartInfoTypeEnum =
  | 'DEFAULT_OPEN_MP'
  | 'DEFAULT_PARTNER_MP'
  | 'PERSONALISED_PARTNER'

export interface ModelUpdateVendorCartInfoPayload {
  /** @example "General tax message" */
  name: string
  /** Type of the cart info */
  type: ModelUpdateVendorCartInfoTypeEnum
  /** @example "A W9 from vendors is not required when payments..." */
  message: string
}

export type ModelUpdateVendorCartInfoData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateVendorCartInfoError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteVendorCartInfoData = any

export type ModelListCartInfoAssignableCustomersData = (({
  /**
   * Name of the street
   * @example "Bodenseestraße"
   */
  streetName: string
  /**
   * Number of the street, building or house
   * @example "21A"
   */
  streetNumber: string
  /**
   * Post code
   * @example 81241
   */
  postalCode: string
  /**
   * Name of the city
   * @example "München"
   */
  city: string
  /**
   * Country code in ISO 3166-1 alfa-2
   * @example "DE"
   */
  countryCode: string
} & {
  /**
   * ID of customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Customer company name
   * @example "ACME Ltd."
   */
  name: string
  /** True if customer is assigned to given price list */
  assigned: boolean
  /** @example "ACME" */
  vendorSpecificCustomerNumber: string
  /**
   * ISO 4217 currency code
   * @pattern ^[A-Z]{3}$
   * @example "EUR"
   */
  currency: string
}) & {
  /**
   * True if customer user can be assigned to given resource
   * @example true
   */
  isAssignable?: boolean
  /**
   * Name of the resource the user is assigned to
   * @example "Cart info name"
   */
  assignedTo?: string
})[]

/** Error */
export type ModelListCartInfoAssignableCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelUpdateVendorCartInfoAssignmentsPayload {
  customerIds: string[]
}

export type ModelUpdateVendorCartInfoAssignmentsData = any

/**
 * Error
 * Error
 */
export type ModelUpdateVendorCartInfoAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelVendorPreviewArticlesSearchVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelVendorPreviewArticlesSearchSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelVendorPreviewArticlesSearchValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelVendorPreviewArticlesSearchTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** Type of classification */
export type ModelVendorPreviewArticlesSearchTypeEnum1 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelVendorPreviewArticlesSearchTypeEnum2 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelVendorPreviewArticlesSearchTypeEnum3 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelVendorPreviewArticlesSearchTypeEnum4 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** @default "ALL_ATTRIBUTES" */
export type ModelVendorPreviewArticlesSearchSearchArticlesByEnum =
  | 'ALL_ATTRIBUTES'
  | 'ARTICLE_NUMBER'
  | 'CUSTOMER_ARTICLE_NUMBER'
  | 'VENDOR_ARTICLE_NUMBER'
  | 'EAN'
  | 'STANDARD'
  | 'TEST_PROFILE_01'
  | 'TEST_PROFILE_02'
  | 'TEST_PROFILE_03'
  | 'TEST_PROFILE_04'
  | 'TEST_PROFILE_05'
  | 'TEST_PROFILE_06'
  | 'TEST_PROFILE_07'
  | 'TEST_PROFILE_08'
  | 'TEST_PROFILE_09'
  | 'TEST_PROFILE_10'
  | 'TEST_PROFILE_11'
  | 'TEST_PROFILE_12'
  | 'TEST_PROFILE_13'
  | 'TEST_PROFILE_14'
  | 'TEST_PROFILE_15'
  | 'TEST_PROFILE_16'
  | 'TEST_PROFILE_17'
  | 'TEST_PROFILE_18'
  | 'TEST_PROFILE_19'
  | 'TEST_PROFILE_20'

/** Type of classification */
export type ModelVendorPreviewArticlesSearchTypeEnum5 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** reference type to be searched */
export type ModelVendorPreviewArticlesSearchReferenceTypeEnum =
  | 'ACCESSORIES'
  | 'SIMILAR_ARTICLES'
  | 'SPARE_PARTS'

export type ModelVendorPreviewArticlesSearchCountsEnum =
  | 'CATEGORIES'
  | 'ECLASS5'
  | 'ECLASS7'
  | 'VENDORS'
  | 'MANUFACTURERS'
  | 'FEATURES'
  | 'PRICES'
  | 'PLATFORM_CATEGORIES'

export type ModelVendorPreviewArticlesSearchPayload = {
  /** @default "ALL_ATTRIBUTES" */
  searchArticlesBy?: ModelVendorPreviewArticlesSearchSearchArticlesByEnum
  term?: string
  /** chosen category path for the current search */
  categoryPath?: {
    /** Type of classification */
    type: ModelVendorPreviewArticlesSearchTypeEnum5
    /**
     * Path items beginning from top level to given group
     * @example ["12345678","23456789","34567890"]
     */
    pathGroupIds: string[]
  }
  /** chosen manufacturers scope for the current search */
  manufacturerNames?: string[]
  /**
   * delivery time scope (in days) for the current search
   * @format int32
   * @min 1
   */
  maxDeliveryTime?: number
  /** Range of money */
  priceRange?: {
    /**
     * Min amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    minAmount?: number
    /**
     * Max amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    maxAmount?: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** chosen features scope for the current search */
  features?: {
    name: string
    value: string
  }[]
  /**
   * RelatedArticleSearchParam
   * reference information to be searched
   */
  referenceBy?: {
    /** id of the article whose article references will be searched */
    articleId?: string
    /** reference type to be searched */
    referenceType?: ModelVendorPreviewArticlesSearchReferenceTypeEnum
  }
  /** returns only items marked as Core Article if set to true */
  coreArticle?: boolean
  /** returns only items from Partner Catalogs if set to true */
  closedMarketplace?: boolean
  /**
   * optional list of properties/attributes to count; absent to count all, empty to not count anything at all
   * @uniqueItems true
   */
  counts?: ModelVendorPreviewArticlesSearchCountsEnum[]
  /**
   * return only counts, i.e. without search hits
   * @default false
   */
  countsOnly?: boolean
} & {
  /**
   * ID of catalog version to be previewed
   * @example "2952333f-6c2c-4bb2-9fbb-26b469372b38"
   */
  catalogVersionId: string
  /** @example "100060d5-713f-4b03-95bc-26044feab14c" */
  customerId?: string
}

export interface ModelVendorPreviewArticlesSearchParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`relevance`, `name,asc`, `name,desc`, `price,asc`, `price,desc`]).
   * Default sorting: `relevance`.
   */
  sort?: string[]
}

/** Main search results response */
export interface ModelVendorPreviewArticlesSearchData {
  /** List of search results */
  items: {
    /** Article information */
    article: {
      /**
       * Article ID
       * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
       */
      id: string
      /**
       * Article name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number (Vendor’s unique article number)
       * @example "ABC123456"
       */
      number: string
      /**
       * EAN number
       * @example 12345678
       */
      ean: string
      eclass?: {
        /** Version of eClass specification */
        version: ModelVendorPreviewArticlesSearchVersionEnum
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
      /**
       * Article number used in customer systems
       * @example "VABC123456"
       */
      customerArticleNumber: string
      /**
       * Article number used by manufacturer
       * @example "ABC123"
       */
      manufacturerArticleNumber?: string
      /**
       * Manufacturer name
       * @example "ACME Inc."
       */
      manufacturer: string
      /**
       * Maximum delivery time in days declared by vendor
       * @deprecated
       * @min 0
       * @max 999999
       * @example 3
       */
      deliveryTime: number
      /** Delivery time details */
      deliveryInfo: {
        /**
         * Maximum delivery time in days declared by vendor
         * @min 0
         * @example 3
         */
        days: number
        source: ModelVendorPreviewArticlesSearchSourceEnum
        /**
         * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
         * @format date-time
         * @example "2024-08-22T17:32:28.682Z"
         */
        updatedAt?: string | null
      }
      /** Stock level info */
      stockInfo: {
        value: ModelVendorPreviewArticlesSearchValueEnum
        /**
         * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
         * @format date-time
         * @example "2024-08-22T17:32:28.682Z"
         */
        updatedAt?: string | null
      }
      minimumOrder?: number
      quantityInterval?: number
      contentUnit?: string
      /** @example 0.001 */
      contentAmount?: string
      /** @format double */
      tax: number
      /** Pricing, order unit and constraints on quantity */
      orderOptions?: {
        /** Flag describing if this article is restricted from adding to the cart */
        restricted?: boolean
        /**
         * Article quantity for which price (pricePerOrderUnit) is defined
         * @example 0.001
         */
        priceQuantity: string
        /** Prices of the article */
        pricing: {
          /** @example 0.001 */
          quantity?: string
          /** Amount of money */
          price?: {
            /**
             * Amount in minor currency (smallest indivisible unit) of the specified currency
             * @format int64
             * @min -1000000000000000
             * @max 1000000000000000
             * @example 1234
             */
            amount: number
            /**
             * ISO 4217 currency code
             * @example "EUR"
             */
            currency: string
          }
          /** Pricing source information, returned only for vendor preview */
          source?: {
            type: ModelVendorPreviewArticlesSearchTypeEnum
            priceListFilename?: string | null
          }
        }[]
      }
      coreArticle: boolean
      closedMarketplace: boolean
    } & {
      /**
       * URL to the thumbnail of the article
       * @format url
       * @example "https://simplesystem.com/images/1234.jpg"
       */
      thumbnailUrl?: string
      /** Unit in which the article is sold */
      orderUnit: string
      /** @default false */
      isIncludedInArticleList: boolean
      /** Explanation of search score calculation */
      explanation?: string
    }
    /** Vendor information */
    vendor: {
      /**
       * Vendor ID
       * @example "V12345"
       */
      id: string
      /**
       * Name of the vendor
       * @example "Hoffmann Group"
       */
      name: string
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  /** Definition of subtree to display for custom classification (if present for given search context) */
  customCategories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelVendorPreviewArticlesSearchTypeEnum1
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for platform categories classification (if present for given search context) */
  platformCategories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelVendorPreviewArticlesSearchTypeEnum2
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for eClass 5 classification (if present for given search context) */
  eClass5Categories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelVendorPreviewArticlesSearchTypeEnum3
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for eClass 7 classification (if present for given search context) */
  eClass7Categories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelVendorPreviewArticlesSearchTypeEnum4
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Vendor hits for the current search */
  vendors?: {
    /** @example "3fa742bc-7867-4397-a8b5-d97fe58869dd" */
    vendorId: string
    /**
     * Number of article hits sold by this vendor
     * @example 19
     */
    count: number
  }[]
  /** Available manufacturers for the current search */
  manufacturers?: {
    /**
     * Manufacturer name
     * @example "Hoffmann"
     */
    name: string
    /**
     * Number of article hits with this manufacturer name
     * @example 319
     */
    count: number
  }[]
  /** List of most popular features with most popular values */
  features?: {
    /**
     * Article feature name
     * @example "Inhalt"
     */
    name: string
    /**
     * Number articles with feature defined
     * @example 8753
     */
    count: number
    /** List of most popular feature values */
    values: {
      /**
       * Article feature value
       * @example "10 Stk."
       */
      value: string
      /**
       * Number articles with the feature value
       * @example 977
       */
      count: number
    }[]
  }[]
  /** List of price ranges in various currencies (one entry for every currency used) */
  prices?: {
    /**
     * Min amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    minAmount?: number
    /**
     * Max amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    maxAmount?: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }[]
}

/** Error */
export type ModelVendorPreviewArticlesSearchError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelPreviewArticleDetailVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelPreviewArticleDetailSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelPreviewArticleDetailValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelPreviewArticleDetailTypeEnum = 'CATALOG' | 'PRICE_LIST'

export type ModelPreviewArticleDetailTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelPreviewArticleDetailFileTypeEnum =
  | 'PDF'
  | 'TXT'
  | 'IMG'
  | 'URL'
  | 'OTHER'

export interface ModelPreviewArticleDetailParams {
  /**
   * Catalog version ID to be previewed.
   * @example "e2c0bd2e-1ca1-4316-8729-1bd5c9cec865"
   */
  catalogVersionId: string
  /**
   * Customer ID of the customer which preview of article should be returned.
   * @example "62d7e7a9-9fc9-456a-886e-ca220ae7b5ff"
   */
  customerId?: string
  /**
   * Article ID
   * @example "3vjY9Y6sV0JHkmd8XUk57s:MDAwMDEwMQ"
   */
  articleId: string
}

/** Article with basic properties */
export type ModelPreviewArticleDetailData = {
  /**
   * Article ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  id: string
  /**
   * Article name
   * @example "Drilling machine"
   */
  name: string
  /**
   * Article number (Vendor’s unique article number)
   * @example "ABC123456"
   */
  number: string
  /**
   * EAN number
   * @example 12345678
   */
  ean: string
  eclass?: {
    /** Version of eClass specification */
    version: ModelPreviewArticleDetailVersionEnum
    /** eClass value formatted with dashes between groups ("12-34-56-78") */
    code?: string | null
    /**
     * eClass name
     * @example "Catering machinery"
     */
    name?: string | null
  }
  /**
   * Article number used by manufacturer
   * @example "ABC123"
   */
  manufacturerArticleNumber?: string
  /**
   * Manufacturer name
   * @example "ACME Inc."
   */
  manufacturer: string
  /**
   * Maximum delivery time in days declared by vendor
   * @deprecated
   * @min 0
   * @max 999999
   * @example 3
   */
  deliveryTime: number
  /** Delivery time details */
  deliveryInfo: {
    /**
     * Maximum delivery time in days declared by vendor
     * @min 0
     * @example 3
     */
    days: number
    source: ModelPreviewArticleDetailSourceEnum
    /**
     * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  /** Stock level info */
  stockInfo: {
    value: ModelPreviewArticleDetailValueEnum
    /**
     * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  /** @example 10 */
  minimumOrder?: number
  /** @example 5 */
  quantityInterval?: number
  /** @example "C62" */
  contentUnit?: string
  /** @example 25 */
  contentAmount?: string
  /**
   * @format double
   * @example 0.19
   */
  tax: number
  /** Pricing, order unit and constraints on quantity */
  orderOptions?: {
    /** Flag describing if this article is restricted from adding to the cart */
    restricted?: boolean
    /**
     * Article quantity for which price (pricePerOrderUnit) is defined
     * @example 0.001
     */
    priceQuantity: string
    /** Prices of the article */
    pricing: {
      /** @example 0.001 */
      quantity?: string
      /** Amount of money */
      price?: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Pricing source information, returned only for vendor preview */
      source?: {
        type: ModelPreviewArticleDetailTypeEnum
        priceListFilename?: string | null
      }
    }[]
  }
  coreArticle: boolean
  closedMarketplace: boolean
} & {
  /** Vendor information */
  vendor: {
    /**
     * Vendor ID
     * @example "V12345"
     */
    id: string
    /**
     * Name of the vendor
     * @example "Hoffmann Group"
     */
    name: string
  }
  /** Person with additional contact info */
  vendorContact: {
    title: ModelPreviewArticleDetailTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  } & {
    email: string
  }
  /** @example "PCE" */
  orderUnit: string
  /** @example "Long description to the article that may contain HTML" */
  description?: string
  /** @example "0101 – 0106" */
  hsCode?: string
  manufacturerArticleType?: string
  vendorRemarks?: string
  dependent?: boolean
  expired?: boolean
  /** Features of the article */
  features: {
    /**
     * Name of the feature
     * @example "Length"
     */
    name?: string
    /**
     * Values of the feature
     * @example ["5","8"]
     */
    values?: string[]
    unit?: string
    description?: string
    valueDetails?: string
  }[]
  /**
   * @format url
   * @example "https://cdn.shopify.com/s/files/1/0078/2555/5571/products/YousikOddieOfficeTableb.png?v=1639373568"
   */
  thumbnailUrl?: string
  images: {
    /**
     * URL pointing to a thumbnail of an image (72px x 72px)
     * @format url
     * @example "https://cataas.com/cat/says/thumbnail"
     */
    thumbnail: string
    /**
     * URL pointing to the file for display on page (360px x 360px)
     * @format url
     * @example "https://cataas.com/cat/says/display"
     */
    pageDisplay: string
  }[]
  additionalMedia: {
    /**
     * @format url
     * @example "https://cataas.com/cat/says/download"
     */
    url: string
    description: string
    fileType: ModelPreviewArticleDetailFileTypeEnum
  }[]
  /** @default false */
  isIncludedInArticleList: boolean
  customerArticleNumberInfo: {
    /**
     * Calculated customer article number value.
     * @example 12345
     */
    value: string
    /** @example true */
    isEdited: boolean
    /**
     * Customer article number value from file or catalog.
     * @example 67890
     */
    defaultValue: string
  }
}

/** Error */
export type ModelPreviewArticleDetailError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelFetchPreviewExternalPricesTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** List of articleIds of articles that need external prices to be provided */
export type ModelFetchPreviewExternalPricesPayload = any[]

/** List of fetched external prices */
export type ModelFetchPreviewExternalPricesData = {
  /** @example "b4334fbf-6033-4cf9-985f-5aec2d0bedea" */
  articleId?: string
  /** Pricing, order unit and constraints on quantity */
  orderOptions?: {
    /** Flag describing if this article is restricted from adding to the cart */
    restricted?: boolean
    /**
     * Article quantity for which price (pricePerOrderUnit) is defined
     * @example 0.001
     */
    priceQuantity: string
    /** Prices of the article */
    pricing: {
      /** @example 0.001 */
      quantity?: string
      /** Amount of money */
      price?: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Pricing source information, returned only for vendor preview */
      source?: {
        type: ModelFetchPreviewExternalPricesTypeEnum
        priceListFilename?: string | null
      }
    }[]
  }
}[]

/** Error */
export type ModelFetchPreviewExternalPricesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorMarketplaceCustomersPriceListsData = {
  id: string
  name: string
}[]

/**
 * Error
 * Error
 */
export type ModelGetVendorMarketplaceCustomersPriceListsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorMarketplaceCustomersData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

/** Error */
export type ModelGetVendorMarketplaceCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorMarketplaceCustomersWithCurrencyData = {
  /**
   * ID of customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
  /** Basic data assigned Customer Article Number list */
  assignedCustomerArticleNumberList: {
    /**
     * ID of Customer Article Number list
     * @example "1ef08419-0b2e-4f0e-9bd1-8fb50834645f"
     */
    id: string
    /**
     * Customer Article Number list name
     * @example "ACME specific CAN"
     */
    name: string
    /**
     * Customer Article Number list filename
     * @example "ACME_CAN.csv"
     */
    filename: string
  } | null
}[]

/** Error */
export type ModelGetVendorMarketplaceCustomersWithCurrencyError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorMarketplaceCatalogAssignedCustomersData = {
  /**
   * ID of customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
  /** Basic data assigned Customer Article Number list */
  assignedCustomerArticleNumberList: {
    /**
     * ID of Customer Article Number list
     * @example "1ef08419-0b2e-4f0e-9bd1-8fb50834645f"
     */
    id: string
    /**
     * Customer Article Number list name
     * @example "ACME specific CAN"
     */
    name: string
    /**
     * Customer Article Number list filename
     * @example "ACME_CAN.csv"
     */
    filename: string
  } | null
}[]

/**
 * Error
 * Error
 */
export type ModelGetVendorMarketplaceCatalogAssignedCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPostCustomerCompanyRegistrationTitleEnum =
  | 'MS'
  | 'MR'
  | 'OTHER'

export type ModelPostCustomerCompanyRegistrationBusinessCategoryEnum =
  | 'AGRICULTURE'
  | 'CONSTRUCTION'
  | 'CHEMISTRY_RAW_MATERIALS'
  | 'SERVICES_MANUFACTURING'
  | 'E_COMMERCE'
  | 'ENERGY_ENVIRONMENT'
  | 'FINANCE_INSURANCE_REAL_ESTATE'
  | 'LEISURE'
  | 'SOCIETY'
  | 'TRADE'
  | 'IT_TELECOMMUNICATIONS'
  | 'RETAIL'
  | 'PHARMACY'
  | 'SPORTS'
  | 'ELECTRONICS'
  | 'TOURISM_TRAVEL'
  | 'MARKETING'
  | 'POLITICS'

/** Referral source */
export type ModelPostCustomerCompanyRegistrationReferralSourceEnum =
  | 'SIMPLE_SYSTEM_SALES_TEAM'
  | 'TECHNICAL_ARTICLE'
  | 'ONLINE_SEARCH'
  | 'TRADE_FAIR'
  | 'SOCIAL_MEDIA'
  | 'VENDOR_RECOMMENDATION'
  | 'OTHER_RECOMMENDATION'

/** Data for Customer's company registration */
export interface ModelPostCustomerCompanyRegistrationPayload {
  /** Login request body */
  user: {
    /**
     * The login identifier of the user. The login identifier can be either the email or the username.
     * @example "Customer2@local.localhost"
     */
    loginId: string
    /**
     * User password.
     * @format password
     * @minLength 1
     * @maxLength 256
     * @example "secret!23"
     */
    password: string
  }
  /** Basic information of the person registering the Customer or Vendor */
  person: {
    title: ModelPostCustomerCompanyRegistrationTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  }
  /** Common information of the company being registered as either Customer or Vendor */
  company: {
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    businessCategory: ModelPostCustomerCompanyRegistrationBusinessCategoryEnum
  }
  /** Referral source */
  referralSource?: ModelPostCustomerCompanyRegistrationReferralSourceEnum
  consents: {
    /** @default false */
    newsletter: boolean
  }
  acceptedDataPrivacyPolicy: {
    /** @format uuid */
    versionId: string
  }
}

export interface ModelPostCustomerCompanyRegistrationData {
  /** @example "4906cd7b-2810-4671-963b-81be91d2ad25" */
  userId: string
}

/** Error */
export type ModelPostCustomerCompanyRegistrationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelCustomerArticleSearchVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelCustomerArticleSearchSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelCustomerArticleSearchValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelCustomerArticleSearchTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** Type of classification */
export type ModelCustomerArticleSearchTypeEnum1 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelCustomerArticleSearchTypeEnum2 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelCustomerArticleSearchTypeEnum3 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelCustomerArticleSearchTypeEnum4 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** @default "ALL_ATTRIBUTES" */
export type ModelCustomerArticleSearchSearchArticlesByEnum =
  | 'ALL_ATTRIBUTES'
  | 'ARTICLE_NUMBER'
  | 'CUSTOMER_ARTICLE_NUMBER'
  | 'VENDOR_ARTICLE_NUMBER'
  | 'EAN'
  | 'STANDARD'
  | 'TEST_PROFILE_01'
  | 'TEST_PROFILE_02'
  | 'TEST_PROFILE_03'
  | 'TEST_PROFILE_04'
  | 'TEST_PROFILE_05'
  | 'TEST_PROFILE_06'
  | 'TEST_PROFILE_07'
  | 'TEST_PROFILE_08'
  | 'TEST_PROFILE_09'
  | 'TEST_PROFILE_10'
  | 'TEST_PROFILE_11'
  | 'TEST_PROFILE_12'
  | 'TEST_PROFILE_13'
  | 'TEST_PROFILE_14'
  | 'TEST_PROFILE_15'
  | 'TEST_PROFILE_16'
  | 'TEST_PROFILE_17'
  | 'TEST_PROFILE_18'
  | 'TEST_PROFILE_19'
  | 'TEST_PROFILE_20'

/** Type of classification */
export type ModelCustomerArticleSearchTypeEnum5 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** reference type to be searched */
export type ModelCustomerArticleSearchReferenceTypeEnum =
  | 'ACCESSORIES'
  | 'SIMILAR_ARTICLES'
  | 'SPARE_PARTS'

export type ModelCustomerArticleSearchCountsEnum =
  | 'CATEGORIES'
  | 'ECLASS5'
  | 'ECLASS7'
  | 'VENDORS'
  | 'MANUFACTURERS'
  | 'FEATURES'
  | 'PRICES'
  | 'PLATFORM_CATEGORIES'

export type ModelCustomerArticleSearchPayload = {
  /** @default "ALL_ATTRIBUTES" */
  searchArticlesBy?: ModelCustomerArticleSearchSearchArticlesByEnum
  term?: string
  /** chosen category path for the current search */
  categoryPath?: {
    /** Type of classification */
    type: ModelCustomerArticleSearchTypeEnum5
    /**
     * Path items beginning from top level to given group
     * @example ["12345678","23456789","34567890"]
     */
    pathGroupIds: string[]
  }
  /** chosen manufacturers scope for the current search */
  manufacturerNames?: string[]
  /**
   * delivery time scope (in days) for the current search
   * @format int32
   * @min 1
   */
  maxDeliveryTime?: number
  /** Range of money */
  priceRange?: {
    /**
     * Min amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    minAmount?: number
    /**
     * Max amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    maxAmount?: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** chosen features scope for the current search */
  features?: {
    name: string
    value: string
  }[]
  /**
   * RelatedArticleSearchParam
   * reference information to be searched
   */
  referenceBy?: {
    /** id of the article whose article references will be searched */
    articleId?: string
    /** reference type to be searched */
    referenceType?: ModelCustomerArticleSearchReferenceTypeEnum
  }
  /** returns only items marked as Core Article if set to true */
  coreArticle?: boolean
  /** returns only items from Partner Catalogs if set to true */
  closedMarketplace?: boolean
  /**
   * optional list of properties/attributes to count; absent to count all, empty to not count anything at all
   * @uniqueItems true
   */
  counts?: ModelCustomerArticleSearchCountsEnum[]
  /**
   * return only counts, i.e. without search hits
   * @default false
   */
  countsOnly?: boolean
} & {
  /** chosen vendors scope for the current search */
  vendorIds?: string[]
}

export interface ModelCustomerArticleSearchParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`relevance`, `name,asc`, `name,desc`, `price,asc`, `price,desc`]).
   * Default sorting: `relevance`.
   */
  sort?: string[]
}

/** Main search results response */
export interface ModelCustomerArticleSearchData {
  /** List of search results */
  items: {
    /** Article information */
    article: {
      /**
       * Article ID
       * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
       */
      id: string
      /**
       * Article name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number (Vendor’s unique article number)
       * @example "ABC123456"
       */
      number: string
      /**
       * EAN number
       * @example 12345678
       */
      ean: string
      eclass?: {
        /** Version of eClass specification */
        version: ModelCustomerArticleSearchVersionEnum
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
      /**
       * Article number used in customer systems
       * @example "VABC123456"
       */
      customerArticleNumber: string
      /**
       * Article number used by manufacturer
       * @example "ABC123"
       */
      manufacturerArticleNumber?: string
      /**
       * Manufacturer name
       * @example "ACME Inc."
       */
      manufacturer: string
      /**
       * Maximum delivery time in days declared by vendor
       * @deprecated
       * @min 0
       * @max 999999
       * @example 3
       */
      deliveryTime: number
      /** Delivery time details */
      deliveryInfo: {
        /**
         * Maximum delivery time in days declared by vendor
         * @min 0
         * @example 3
         */
        days: number
        source: ModelCustomerArticleSearchSourceEnum
        /**
         * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
         * @format date-time
         * @example "2024-08-22T17:32:28.682Z"
         */
        updatedAt?: string | null
      }
      /** Stock level info */
      stockInfo: {
        value: ModelCustomerArticleSearchValueEnum
        /**
         * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
         * @format date-time
         * @example "2024-08-22T17:32:28.682Z"
         */
        updatedAt?: string | null
      }
      minimumOrder?: number
      quantityInterval?: number
      contentUnit?: string
      /** @example 0.001 */
      contentAmount?: string
      /** @format double */
      tax: number
      /** Pricing, order unit and constraints on quantity */
      orderOptions?: {
        /** Flag describing if this article is restricted from adding to the cart */
        restricted?: boolean
        /**
         * Article quantity for which price (pricePerOrderUnit) is defined
         * @example 0.001
         */
        priceQuantity: string
        /** Prices of the article */
        pricing: {
          /** @example 0.001 */
          quantity?: string
          /** Amount of money */
          price?: {
            /**
             * Amount in minor currency (smallest indivisible unit) of the specified currency
             * @format int64
             * @min -1000000000000000
             * @max 1000000000000000
             * @example 1234
             */
            amount: number
            /**
             * ISO 4217 currency code
             * @example "EUR"
             */
            currency: string
          }
          /** Pricing source information, returned only for vendor preview */
          source?: {
            type: ModelCustomerArticleSearchTypeEnum
            priceListFilename?: string | null
          }
        }[]
      }
      coreArticle: boolean
      closedMarketplace: boolean
    } & {
      /**
       * URL to the thumbnail of the article
       * @format url
       * @example "https://simplesystem.com/images/1234.jpg"
       */
      thumbnailUrl?: string
      /** Unit in which the article is sold */
      orderUnit: string
      /** @default false */
      isIncludedInArticleList: boolean
      /** Explanation of search score calculation */
      explanation?: string
    }
    /** Vendor information */
    vendor: {
      /**
       * Vendor ID
       * @example "V12345"
       */
      id: string
      /**
       * Name of the vendor
       * @example "Hoffmann Group"
       */
      name: string
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  /** Definition of subtree to display for custom classification (if present for given search context) */
  customCategories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelCustomerArticleSearchTypeEnum1
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for platform categories classification (if present for given search context) */
  platformCategories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelCustomerArticleSearchTypeEnum2
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for eClass 5 classification (if present for given search context) */
  eClass5Categories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelCustomerArticleSearchTypeEnum3
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for eClass 7 classification (if present for given search context) */
  eClass7Categories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelCustomerArticleSearchTypeEnum4
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Vendor hits for the current search */
  vendors?: {
    /** @example "3fa742bc-7867-4397-a8b5-d97fe58869dd" */
    vendorId: string
    /**
     * Number of article hits sold by this vendor
     * @example 19
     */
    count: number
  }[]
  /** Available manufacturers for the current search */
  manufacturers?: {
    /**
     * Manufacturer name
     * @example "Hoffmann"
     */
    name: string
    /**
     * Number of article hits with this manufacturer name
     * @example 319
     */
    count: number
  }[]
  /** List of most popular features with most popular values */
  features?: {
    /**
     * Article feature name
     * @example "Inhalt"
     */
    name: string
    /**
     * Number articles with feature defined
     * @example 8753
     */
    count: number
    /** List of most popular feature values */
    values: {
      /**
       * Article feature value
       * @example "10 Stk."
       */
      value: string
      /**
       * Number articles with the feature value
       * @example 977
       */
      count: number
    }[]
  }[]
  /** List of price ranges in various currencies (one entry for every currency used) */
  prices?: {
    /**
     * Min amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    minAmount?: number
    /**
     * Max amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    maxAmount?: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }[]
}

/** Error */
export type ModelCustomerArticleSearchError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetShoppingCartVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelGetShoppingCartSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelGetShoppingCartValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelGetShoppingCartTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** Version of eClass specification */
export type ModelGetShoppingCartVersionEnum1 = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelGetShoppingCartSourceEnum1 =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelGetShoppingCartValueEnum1 =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelGetShoppingCartTypeEnum1 = 'CATALOG' | 'PRICE_LIST'

/** Version of eClass specification */
export type ModelGetShoppingCartVersionEnum2 = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelGetShoppingCartSourceEnum2 =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelGetShoppingCartValueEnum2 =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelGetShoppingCartTypeEnum2 = 'CATALOG' | 'PRICE_LIST'

/** Version of eClass specification */
export type ModelGetShoppingCartVersionEnum3 = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelGetShoppingCartSourceEnum3 =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelGetShoppingCartValueEnum3 =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelGetShoppingCartTypeEnum3 = 'CATALOG' | 'PRICE_LIST'

export type ModelGetShoppingCartTemplateEnum =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetShoppingCartTemplateEnum1 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetShoppingCartTemplateEnum2 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetShoppingCartSelectionTypeEnum =
  | 'SELECTED_VENDORS'
  | 'ALL_VENDORS'
  | 'VENDORS_FROM_OPEN_MARKETPLACE'
  | 'VENDORS_FROM_PARTNER_MARKETPLACE'

export type ModelGetShoppingCartTemplateEnum3 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetShoppingCartTemplateEnum4 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

/** Content of the shopping cart with accounts assignments */
export type ModelGetShoppingCartData = {
  /** a batch of cart items per vendor */
  items: {
    /** Basic data of customer or vendor */
    vendor: {
      /**
       * ID of vendor/customer
       * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
       */
      id: string
      /**
       * Vendor/customer company name
       * @example "ACME Ltd."
       */
      name: string
    }
    /** List of items that belong to the same vendor */
    items: {
      /**
       * ID of the cart item
       * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
       */
      id: string
      /** A single search result */
      articleData: {
        /** Article information */
        article: {
          /**
           * Article ID
           * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
           */
          id: string
          /**
           * Article name
           * @example "Drilling machine"
           */
          name: string
          /**
           * Article number (Vendor’s unique article number)
           * @example "ABC123456"
           */
          number: string
          /**
           * EAN number
           * @example 12345678
           */
          ean: string
          eclass?: {
            /** Version of eClass specification */
            version: ModelGetShoppingCartVersionEnum
            /** eClass value formatted with dashes between groups ("12-34-56-78") */
            code?: string | null
            /**
             * eClass name
             * @example "Catering machinery"
             */
            name?: string | null
          }
          /**
           * Article number used in customer systems
           * @example "VABC123456"
           */
          customerArticleNumber: string
          /**
           * Article number used by manufacturer
           * @example "ABC123"
           */
          manufacturerArticleNumber?: string
          /**
           * Manufacturer name
           * @example "ACME Inc."
           */
          manufacturer: string
          /**
           * Maximum delivery time in days declared by vendor
           * @deprecated
           * @min 0
           * @max 999999
           * @example 3
           */
          deliveryTime: number
          /** Delivery time details */
          deliveryInfo: {
            /**
             * Maximum delivery time in days declared by vendor
             * @min 0
             * @example 3
             */
            days: number
            source: ModelGetShoppingCartSourceEnum
            /**
             * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          /** Stock level info */
          stockInfo: {
            value: ModelGetShoppingCartValueEnum
            /**
             * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          minimumOrder?: number
          quantityInterval?: number
          contentUnit?: string
          /** @example 0.001 */
          contentAmount?: string
          /** @format double */
          tax: number
          /** Pricing, order unit and constraints on quantity */
          orderOptions?: {
            /** Flag describing if this article is restricted from adding to the cart */
            restricted?: boolean
            /**
             * Article quantity for which price (pricePerOrderUnit) is defined
             * @example 0.001
             */
            priceQuantity: string
            /** Prices of the article */
            pricing: {
              /** @example 0.001 */
              quantity?: string
              /** Amount of money */
              price?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
              /** Pricing source information, returned only for vendor preview */
              source?: {
                type: ModelGetShoppingCartTypeEnum
                priceListFilename?: string | null
              }
            }[]
          }
          coreArticle: boolean
          closedMarketplace: boolean
        } & {
          /**
           * URL to the thumbnail of the article
           * @format url
           * @example "https://simplesystem.com/images/1234.jpg"
           */
          thumbnailUrl?: string
          /** Unit in which the article is sold */
          orderUnit: string
          /** @default false */
          isIncludedInArticleList: boolean
          /** Explanation of search score calculation */
          explanation?: string
        }
        /** Vendor information */
        vendor: {
          /**
           * Vendor ID
           * @example "V12345"
           */
          id: string
          /**
           * Name of the vendor
           * @example "Hoffmann Group"
           */
          name: string
        }
      }
      /**
       * If true, it is impossible to change item quantity
       * @example false
       */
      immutable: boolean
      /**
       * @format int32
       * @min 1
       */
      quantity: number
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Amount of money */
      priceUsed: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Shopper's additional texts on some order positions
       * @example "for Machine 4711"
       */
      itemComment?: string
    }[]
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Message from vendor
     * @example "Deliveries will be delayed..."
     */
    vendorCartInfo?: string
  }[]
  /** Amount of money */
  total: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * List of IDs of articles that are no longer available
   * @deprecated
   */
  unavailableArticleIds?: string[]
  /** List of articles that are no longer available */
  unavailableArticles?: {
    /**
     * ID of the cart item
     * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
     */
    id: string
    /** A single search result */
    articleData: {
      /** Article information */
      article: {
        /**
         * Article ID
         * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
         */
        id: string
        /**
         * Article name
         * @example "Drilling machine"
         */
        name: string
        /**
         * Article number (Vendor’s unique article number)
         * @example "ABC123456"
         */
        number: string
        /**
         * EAN number
         * @example 12345678
         */
        ean: string
        eclass?: {
          /** Version of eClass specification */
          version: ModelGetShoppingCartVersionEnum1
          /** eClass value formatted with dashes between groups ("12-34-56-78") */
          code?: string | null
          /**
           * eClass name
           * @example "Catering machinery"
           */
          name?: string | null
        }
        /**
         * Article number used in customer systems
         * @example "VABC123456"
         */
        customerArticleNumber: string
        /**
         * Article number used by manufacturer
         * @example "ABC123"
         */
        manufacturerArticleNumber?: string
        /**
         * Manufacturer name
         * @example "ACME Inc."
         */
        manufacturer: string
        /**
         * Maximum delivery time in days declared by vendor
         * @deprecated
         * @min 0
         * @max 999999
         * @example 3
         */
        deliveryTime: number
        /** Delivery time details */
        deliveryInfo: {
          /**
           * Maximum delivery time in days declared by vendor
           * @min 0
           * @example 3
           */
          days: number
          source: ModelGetShoppingCartSourceEnum1
          /**
           * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        /** Stock level info */
        stockInfo: {
          value: ModelGetShoppingCartValueEnum1
          /**
           * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        minimumOrder?: number
        quantityInterval?: number
        contentUnit?: string
        /** @example 0.001 */
        contentAmount?: string
        /** @format double */
        tax: number
        /** Pricing, order unit and constraints on quantity */
        orderOptions?: {
          /** Flag describing if this article is restricted from adding to the cart */
          restricted?: boolean
          /**
           * Article quantity for which price (pricePerOrderUnit) is defined
           * @example 0.001
           */
          priceQuantity: string
          /** Prices of the article */
          pricing: {
            /** @example 0.001 */
            quantity?: string
            /** Amount of money */
            price?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
            /** Pricing source information, returned only for vendor preview */
            source?: {
              type: ModelGetShoppingCartTypeEnum1
              priceListFilename?: string | null
            }
          }[]
        }
        coreArticle: boolean
        closedMarketplace: boolean
      } & {
        /**
         * URL to the thumbnail of the article
         * @format url
         * @example "https://simplesystem.com/images/1234.jpg"
         */
        thumbnailUrl?: string
        /** Unit in which the article is sold */
        orderUnit: string
        /** @default false */
        isIncludedInArticleList: boolean
        /** Explanation of search score calculation */
        explanation?: string
      }
      /** Vendor information */
      vendor: {
        /**
         * Vendor ID
         * @example "V12345"
         */
        id: string
        /**
         * Name of the vendor
         * @example "Hoffmann Group"
         */
        name: string
      }
    }
    /**
     * If true, it is impossible to change item quantity
     * @example false
     */
    immutable: boolean
    /**
     * @format int32
     * @min 1
     */
    quantity: number
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of money */
    priceUsed: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Shopper's additional texts on some order positions
     * @example "for Machine 4711"
     */
    itemComment?: string
  }[]
  cartRestriction?: {
    /** Amount of money */
    limit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of money */
    exceededBy?: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    } | null
  }
} & {
  /** a batch of cart items per vendor */
  items: {
    /** Basic data of customer or vendor */
    vendor: {
      /**
       * ID of vendor/customer
       * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
       */
      id: string
      /**
       * Vendor/customer company name
       * @example "ACME Ltd."
       */
      name: string
    }
    /** List of items that belong to the same vendor */
    items: ({
      /**
       * ID of the cart item
       * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
       */
      id: string
      /** A single search result */
      articleData: {
        /** Article information */
        article: {
          /**
           * Article ID
           * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
           */
          id: string
          /**
           * Article name
           * @example "Drilling machine"
           */
          name: string
          /**
           * Article number (Vendor’s unique article number)
           * @example "ABC123456"
           */
          number: string
          /**
           * EAN number
           * @example 12345678
           */
          ean: string
          eclass?: {
            /** Version of eClass specification */
            version: ModelGetShoppingCartVersionEnum2
            /** eClass value formatted with dashes between groups ("12-34-56-78") */
            code?: string | null
            /**
             * eClass name
             * @example "Catering machinery"
             */
            name?: string | null
          }
          /**
           * Article number used in customer systems
           * @example "VABC123456"
           */
          customerArticleNumber: string
          /**
           * Article number used by manufacturer
           * @example "ABC123"
           */
          manufacturerArticleNumber?: string
          /**
           * Manufacturer name
           * @example "ACME Inc."
           */
          manufacturer: string
          /**
           * Maximum delivery time in days declared by vendor
           * @deprecated
           * @min 0
           * @max 999999
           * @example 3
           */
          deliveryTime: number
          /** Delivery time details */
          deliveryInfo: {
            /**
             * Maximum delivery time in days declared by vendor
             * @min 0
             * @example 3
             */
            days: number
            source: ModelGetShoppingCartSourceEnum2
            /**
             * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          /** Stock level info */
          stockInfo: {
            value: ModelGetShoppingCartValueEnum2
            /**
             * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          minimumOrder?: number
          quantityInterval?: number
          contentUnit?: string
          /** @example 0.001 */
          contentAmount?: string
          /** @format double */
          tax: number
          /** Pricing, order unit and constraints on quantity */
          orderOptions?: {
            /** Flag describing if this article is restricted from adding to the cart */
            restricted?: boolean
            /**
             * Article quantity for which price (pricePerOrderUnit) is defined
             * @example 0.001
             */
            priceQuantity: string
            /** Prices of the article */
            pricing: {
              /** @example 0.001 */
              quantity?: string
              /** Amount of money */
              price?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
              /** Pricing source information, returned only for vendor preview */
              source?: {
                type: ModelGetShoppingCartTypeEnum2
                priceListFilename?: string | null
              }
            }[]
          }
          coreArticle: boolean
          closedMarketplace: boolean
        } & {
          /**
           * URL to the thumbnail of the article
           * @format url
           * @example "https://simplesystem.com/images/1234.jpg"
           */
          thumbnailUrl?: string
          /** Unit in which the article is sold */
          orderUnit: string
          /** @default false */
          isIncludedInArticleList: boolean
          /** Explanation of search score calculation */
          explanation?: string
        }
        /** Vendor information */
        vendor: {
          /**
           * Vendor ID
           * @example "V12345"
           */
          id: string
          /**
           * Name of the vendor
           * @example "Hoffmann Group"
           */
          name: string
        }
      }
      /**
       * If true, it is impossible to change item quantity
       * @example false
       */
      immutable: boolean
      /**
       * @format int32
       * @min 1
       */
      quantity: number
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Amount of money */
      priceUsed: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Shopper's additional texts on some order positions
       * @example "for Machine 4711"
       */
      itemComment?: string
    } & {
      account: {
        /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
        typeId: string
        /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
        valueId?: string
        /** @example "Temporary account type value" */
        freeTextValue?: string
      } | null
      /**
       * Id of selected general ledger for the cart item
       * @example "b89c18ef-140f-418c-9d84-e99066062de6"
       */
      generalLedgerId: string | null
      /** Description of a single assigned general ledger */
      defaultGeneralLedger?: {
        /**
         * General Ledger ID
         * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
         */
        id: string
        /**
         * Unique General Ledger number
         * @example "11081"
         */
        number: string
        /**
         * General ledger description
         * @example "Ledger name 01"
         */
        description: string
      }
      /**
       * Additional comment made to the selected account
       * @example "CEBIT / EXPO"
       */
      additionalAccountingInfo: string
    })[]
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Date when the order should be delivered
     * @format date
     * @example "2023-08-08"
     */
    desiredDeliveryDate?: string
    /**
     * Custom order number set by the customer user
     * @example "123ABC"
     */
    customOrderNumber?: string
    /**
     * Message from vendor
     * @example "Deliveries will be delayed..."
     */
    vendorCartInfo?: string
  }[]
  /** List of articles that are no longer available */
  unavailableArticles?: ({
    /**
     * ID of the cart item
     * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
     */
    id: string
    /** A single search result */
    articleData: {
      /** Article information */
      article: {
        /**
         * Article ID
         * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
         */
        id: string
        /**
         * Article name
         * @example "Drilling machine"
         */
        name: string
        /**
         * Article number (Vendor’s unique article number)
         * @example "ABC123456"
         */
        number: string
        /**
         * EAN number
         * @example 12345678
         */
        ean: string
        eclass?: {
          /** Version of eClass specification */
          version: ModelGetShoppingCartVersionEnum3
          /** eClass value formatted with dashes between groups ("12-34-56-78") */
          code?: string | null
          /**
           * eClass name
           * @example "Catering machinery"
           */
          name?: string | null
        }
        /**
         * Article number used in customer systems
         * @example "VABC123456"
         */
        customerArticleNumber: string
        /**
         * Article number used by manufacturer
         * @example "ABC123"
         */
        manufacturerArticleNumber?: string
        /**
         * Manufacturer name
         * @example "ACME Inc."
         */
        manufacturer: string
        /**
         * Maximum delivery time in days declared by vendor
         * @deprecated
         * @min 0
         * @max 999999
         * @example 3
         */
        deliveryTime: number
        /** Delivery time details */
        deliveryInfo: {
          /**
           * Maximum delivery time in days declared by vendor
           * @min 0
           * @example 3
           */
          days: number
          source: ModelGetShoppingCartSourceEnum3
          /**
           * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        /** Stock level info */
        stockInfo: {
          value: ModelGetShoppingCartValueEnum3
          /**
           * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        minimumOrder?: number
        quantityInterval?: number
        contentUnit?: string
        /** @example 0.001 */
        contentAmount?: string
        /** @format double */
        tax: number
        /** Pricing, order unit and constraints on quantity */
        orderOptions?: {
          /** Flag describing if this article is restricted from adding to the cart */
          restricted?: boolean
          /**
           * Article quantity for which price (pricePerOrderUnit) is defined
           * @example 0.001
           */
          priceQuantity: string
          /** Prices of the article */
          pricing: {
            /** @example 0.001 */
            quantity?: string
            /** Amount of money */
            price?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
            /** Pricing source information, returned only for vendor preview */
            source?: {
              type: ModelGetShoppingCartTypeEnum3
              priceListFilename?: string | null
            }
          }[]
        }
        coreArticle: boolean
        closedMarketplace: boolean
      } & {
        /**
         * URL to the thumbnail of the article
         * @format url
         * @example "https://simplesystem.com/images/1234.jpg"
         */
        thumbnailUrl?: string
        /** Unit in which the article is sold */
        orderUnit: string
        /** @default false */
        isIncludedInArticleList: boolean
        /** Explanation of search score calculation */
        explanation?: string
      }
      /** Vendor information */
      vendor: {
        /**
         * Vendor ID
         * @example "V12345"
         */
        id: string
        /**
         * Name of the vendor
         * @example "Hoffmann Group"
         */
        name: string
      }
    }
    /**
     * If true, it is impossible to change item quantity
     * @example false
     */
    immutable: boolean
    /**
     * @format int32
     * @min 1
     */
    quantity: number
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of money */
    priceUsed: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Shopper's additional texts on some order positions
     * @example "for Machine 4711"
     */
    itemComment?: string
  } & {
    account: {
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      typeId: string
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      valueId?: string
      /** @example "Temporary account type value" */
      freeTextValue?: string
    } | null
    /**
     * Id of selected general ledger for the cart item
     * @example "b89c18ef-140f-418c-9d84-e99066062de6"
     */
    generalLedgerId: string | null
    /** Description of a single assigned general ledger */
    defaultGeneralLedger?: {
      /**
       * General Ledger ID
       * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
       */
      id: string
      /**
       * Unique General Ledger number
       * @example "11081"
       */
      number: string
      /**
       * General ledger description
       * @example "Ledger name 01"
       */
      description: string
    }
    /**
     * Additional comment made to the selected account
     * @example "CEBIT / EXPO"
     */
    additionalAccountingInfo: string
  })[]
  account: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    typeId: string
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    valueId?: string
    /** @example "Temporary account type value" */
    freeTextValue?: string
  } | null
  /**
   * Id of selected default general ledger for the cart
   * @example "8582c62d-4662-41b0-91aa-b903384a7c48"
   */
  generalLedgerId: string | null
  /** List of required approvals */
  requiredApprovals: {
    /**
     * @format uuid
     * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
     */
    approvalFlowId: string
    mainApprover: {
      /**
       * @format uuid
       * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
       */
      id: string
      /** @example "Hans Muller" */
      name: string
    }
    deputyApprover?: {
      /**
       * @format uuid
       * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
       */
      id: string
      /** @example "Hans Muller" */
      name: string
    }
    rule?:
      | ({
          template: ModelGetShoppingCartTemplateEnum
        } & {
          articlePriceBiggerThanRuleConfiguration?: {
            /** Amount of money */
            priceGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelGetShoppingCartTemplateEnum1
        } & {
          orderTotalBiggerThanSimpleRuleConfiguration?: {
            /** Amount of money */
            totalGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelGetShoppingCartTemplateEnum2
        } & {
          vendorIsAnyOfRuleConfiguration?: {
            vendorIds?: string[] | null
            selectionType?: ModelGetShoppingCartSelectionTypeEnum
          }
        })
      | ({
          template: ModelGetShoppingCartTemplateEnum3
        } & {
          accountValueIsAnyOfRuleConfiguration?: {
            accountValueIds?: string[]
          } | null
        })
      | ({
          template: ModelGetShoppingCartTemplateEnum4
        } & {
          articleEClassAnyOfConfiguration?: {
            /**
             * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
             * When eClass node has `true` value its selected and all of its children are also selected.
             * Mapping to `false` clears mapping defined by parents
             * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
             */
            eClasses?: Record<string, boolean>
          } | null
        })
  }[]
  /**
   * If true, the cart cannot be checked out, and custom order number cannot be generated anymore
   * @example false
   */
  customOrderNumberSequenceExhausted: boolean
  /**
   * True if the account types/values are mandatory for checkout
   * @example true
   */
  accountTypeAndValueMandatory: boolean
  /**
   * True if the general ledger are mandatory for checkout
   * @example true
   */
  generalLedgerMandatory: boolean
  /**
   * True if the general ledger can be changed on whole cart
   * @example true
   */
  generalLedgerCartLevelEnabled: boolean
  /**
   * Additional comment made to the selected account
   * @example "CEBIT / EXPO"
   */
  additionalAccountingInfo: string
  /** Comment added to the entire cart */
  orderComment?: string
}

export type ModelDeleteCartData = any

/** Cart update request */
export interface ModelUpdateCurrentCartPayload {
  account?: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    typeId: string
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    valueId?: string
    /** @example "Temporary account type value" */
    freeTextValue?: string
  } | null
  /**
   * Id of selected general ledger
   * @example "bb6bf664-33b8-4026-ad59-64993bb2137b"
   */
  generalLedgerId?: string | null
  /**
   * Additional comment made to the selected account
   * @example "Expo"
   */
  additionalAccountingInfo?: string | null
  /** Comment added to the entire cart */
  orderComment?: string | null
}

export type ModelUpdateCurrentCartData = any

/** Error */
export type ModelUpdateCurrentCartError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Validity period for budgets */
export type ModelGetShoppingCartExceededBudgetsRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** Validity period for budgets */
export type ModelGetShoppingCartExceededBudgetsRefreshPeriodEnum1 =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** List of all exceeded budgets */
export interface ModelGetShoppingCartExceededBudgetsData {
  /** List of accounting based budgets that have been exceeded */
  exceededBudgets: (({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetShoppingCartExceededBudgetsRefreshPeriodEnum
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget enabled flag
     * @example true
     */
    enabled: boolean
  }) & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /** An amount of money left to spend on a given budget in a given time span */
    budgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** An amount of money spent on a given budget in a given time span */
    budgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit without additional amount */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget for current period */
    additionalBudgetAmount: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of already spent additional budget for current period (total amount of spent budget minus current budget limit) */
    additionalBudgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget left in the current period */
    additionalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount */
    totalBudgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount left in the current period */
    totalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    accountType?: {
      /**
       * Account type ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account type prefix
       * @example "K"
       */
      prefix: string
      /**
       * Account type description
       * @example "Project Name"
       */
      description: string
    }
    accountValue?: {
      /**
       * Account value ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account value number
       * @example "12213131"
       */
      number: string
      /**
       * Account value description (null for free-text values)
       * @example "Next Gen"
       */
      description: string
    }
    /**
     * Name and surname of user who has added the account type
     * @example "Erika Mustermann"
     */
    createdBy?: string | null
    /**
     * Percentage of utilised budget
     * @min 0
     * @example 80
     */
    percentageUsed: number
  })[]
  /** List of individual budgets that have been exceeded */
  exceededIndividualBudgets: (({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetShoppingCartExceededBudgetsRefreshPeriodEnum1
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name and surname of user who has added the budget
     * @example "Erika Gonzalez"
     */
    createdBy: string
    /**
     * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
     * When eClass node has `true` value its selected and all of its children are also selected.
     * Mapping to `false` clears mapping defined by parents
     * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
     */
    eClasses?: Record<string, boolean>
    assignments?: {
      /**
       * Count of users assigned to the resource
       * @format int64
       * @example 10
       */
      assignedUsersCount: number
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned: boolean
      /**
       * True if user groups are assigned to the resource
       * @example true
       */
      userGroupsAssigned: boolean
    }
  }) & {
    /** Budget limit */
    budgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** An amount of money spent on a given budget in a given time span */
    budgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Percentage of utilised budget
     * @min 0
     * @example 80
     */
    percentageUsed: number
    /**
     * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
     * When eClass node has `true` value its selected and all of its children are also selected.
     * Mapping to `false` clears mapping defined by parents
     * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
     */
    eClasses?: Record<string, boolean>
  })[]
  /** List of cart item IDs that are blocked by exceeded budgets */
  cartItemIdsBlockedByBudgets: string[]
}

/** Cart vendor properties section */
export interface ModelUpdateCartVendorSectionPayload {
  /**
   * Date when the order should be delivered
   * @format date
   * @example "2023-08-08"
   */
  desiredDeliveryDate?: string | null
  /**
   * Custom order number. This is available only if order number range is not defined.
   * @example "123ABC"
   */
  customOrderNumber?: string | null
}

export type ModelUpdateCartVendorSectionData = any

/** Error */
export type ModelUpdateCartVendorSectionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** a batch of cart item summaries */
export interface ModelGetArticlesQuantitiesInCartData {
  /** List of items */
  items: {
    /**
     * ID of the cart item
     * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
     */
    cartItemId: string
    /**
     * Article ID
     * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
     */
    articleId: string
    /**
     * @format int32
     * @min 1
     */
    inCartQuantity: number
  }[]
}

export type ModelAddArticlesToCartPerformedOperationEnum =
  | 'CREATED'
  | 'UPDATED'
  | 'REPLICATED'

export type ModelAddArticlesToCartPayload = {
  /**
   * Article ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  articleId: string
  /**
   * Amount of the article quantity.
   * @format int32
   * @min 1
   */
  changeQuantityBy: number
}[]

export type ModelAddArticlesToCartData = {
  /**
   * Article ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  articleId: string
  /**
   * @format int32
   * @min 1
   */
  inCartQuantity: number
  performedOperation: ModelAddArticlesToCartPerformedOperationEnum
}[]

/**
 * Error
 * Error
 */
export type ModelAddArticlesToCartError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelUpdateCartItemPayload {
  /**
   * @format int32
   * @min 1
   */
  quantity?: number
  account?: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    typeId: string
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    valueId?: string
    /** @example "Temporary account type value" */
    freeTextValue?: string
  } | null
  /**
   * Id of selected general ledger
   * @example "bb6bf664-33b8-4026-ad59-64993bb2137b"
   */
  generalLedgerId?: string | null
  /**
   * Additional comment made to the selected account
   * @example "CEBIT / EXPO"
   */
  additionalAccountingInfo?: string | null
  /**
   * Shopper's additional texts on some order positions
   * @example "for Machine 4711"
   */
  itemComment?: string | null
}

export type ModelUpdateCartItemData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCartItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteItemFromCartData = any

export type ModelReplicateCartItemData = any

/** Error */
export type ModelReplicateCartItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Cart checkout request */
export interface ModelCartCheckoutPayload {
  /** Delivery address of an order */
  deliveryAddress: {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string
    /** Basic information about an Address */
    address: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
    /**
     * User-assigned label
     * @example "Second Office"
     */
    label?: string
  }
  /** Delivery address fields that can be customized by a specific customer user */
  deliveryAddressCustomizations?: {
    /**
     * Alternate recipient name
     * @example "Jan Schmidt"
     */
    alternateRecipient?: string
    /**
     * Additional address information line 1
     * @example "6th floor"
     */
    additionalInformation?: string
    /**
     * Additional address information line 2
     * @example "desk 12"
     */
    additionalInformation2?: string
    /** @example "Track 001" */
    unloadingPoint?: string
  }
}

export interface ModelCartCheckoutData {
  /** @example true */
  approvalsRequired: boolean
}

/** Error */
export type ModelCartCheckoutError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Filtering parameters for order count */
export interface ModelGetCustomerOrdersCountersPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
}

/** Response with type of orders and their quantity */
export interface ModelGetCustomerOrdersCountersData {
  /**
   * Count of orders made by the customer user
   * @format int64
   * @example 2
   */
  currentUserOrderCount: number
  /**
   * Count of orders awaiting approval from the customer user
   * @format int64
   * @example 3
   */
  awaitingCurrentUserApprovalOrderCount?: number
  /**
   * Count of platform orders made by the company
   * @format int64
   * @example 2
   */
  companyPlatformOrderCount?: number
  /**
   * Count of erp orders made by the company
   * @format int64
   * @example 3
   */
  companyErpOrderCount?: number
  /**
   * Count of OCI cart transfers made by the company
   * @format int64
   * @example 2
   */
  companyOciTransferredCartCount?: number
}

/** Error */
export type ModelGetCustomerOrdersCountersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelSearchCustomerPlatformOrdersStatusEnum =
  | 'DOES_NOT_NEED_APPROVAL'
  | 'WAITING_FOR_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'

export type ModelSearchCustomerPlatformOrdersDeliveryStatusEnum =
  | 'IN_DELIVERY'
  | 'PARTLY_DELIVERED'
  | 'DELIVERED'

export type ModelSearchCustomerPlatformOrdersOrderPaymentStatusEnum =
  | 'UNPAID'
  | 'PARTLY_PAID'
  | 'FULLY_PAID'

export type ModelSearchCustomerPlatformOrdersKindEnum =
  | 'ALL_COMPANY_ORDERS'
  | 'MY_PERSONAL_ORDERS'
  | 'WAITING_FOR_MY_APPROVALS'

/** Search & filtering parameters for order listing */
export interface ModelSearchCustomerPlatformOrdersPayload {
  kind: ModelSearchCustomerPlatformOrdersKindEnum
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
  /**
   * Chosen vendors scope for the current search
   * @example ["fe903a8c-4e26-49de-95a6-33d4f4b0883b","93f6947e-2295-4f85-bec9-d610d18d373e"]
   */
  vendorIds?: string[]
  /**
   * Chosen shopper scope for the current search
   * @example ["906af5c1-6979-4f5f-a400-0651d4aa0e60","25d6ff94-7b9b-4560-90a0-5e5bdcffeb5b"]
   */
  shopperIds?: string[]
  /**
   * First day of the date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-02"
   */
  startDate?: string
  /**
   * Last day of the date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-09"
   */
  endDate?: string
}

export interface ModelSearchCustomerPlatformOrdersParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`orderNumber,desc`, `orderNumber,asc`, `createdAt,desc`, `createdAt,asc`,
   * `vendor.name,asc`, `vendor.name,desc`, `customerUser.name,asc`, `customerUser.name,desc`]).
   * Default sorting: `createdAt,desc`.
   */
  sort?: string[]
}

/** Response with orders search results */
export interface ModelSearchCustomerPlatformOrdersData {
  /** List of search results */
  items: {
    /**
     * order ID
     * @format uuid
     * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
     */
    id: string
    /**
     * Internal or customer specific order number
     * @example 8059203
     */
    orderNumber: string
    /**
     * Number of a batch which the order is part of
     * @format int64
     * @example 9203987
     */
    orderBatchNumber: number
    /**
     * Timestamp when the order was created in the system but not passed to the vendor yet. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    /**
     * Timestamp when the order was passed to the vendor. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    placedAt?: string
    /** Approval data */
    approval: {
      /** Customer user data to be presented along the orders */
      approver?: {
        /**
         * Customer User ID
         * @format uuid
         * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
         */
        id: string
        /**
         * Name of the customer user
         * @example "John Doe"
         */
        name: string
        /**
         * Email of the customer user
         * @example "john.doe@example.com"
         */
        email: string
      }
      /** Customer user data to be presented along the orders */
      deputyApprover?: {
        /**
         * Customer User ID
         * @format uuid
         * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
         */
        id: string
        /**
         * Name of the customer user
         * @example "John Doe"
         */
        name: string
        /**
         * Email of the customer user
         * @example "john.doe@example.com"
         */
        email: string
      }
      /**
       * Timestamp of the date when order can be approved. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       * @format date-time
       * @example "2022-11-25T11:10:57.849+00:00"
       */
      approverValidUntil?: string
      /**
       * Timestamp when the order got final approve. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       * @format date-time
       * @example "2022-11-25T11:10:57.849+00:00"
       */
      statusChangedAt?: string
      status?: ModelSearchCustomerPlatformOrdersStatusEnum
      /** Customer user data to be presented along the orders */
      statusChangedBy?: {
        /**
         * Customer User ID
         * @format uuid
         * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
         */
        id: string
        /**
         * Name of the customer user
         * @example "John Doe"
         */
        name: string
        /**
         * Email of the customer user
         * @example "john.doe@example.com"
         */
        email: string
      }
    }
    /** Vendor data to be presented along the orders */
    vendor: {
      /**
       * Vendor ID
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Vendor company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Customer specific vendor numer
       * @example "ACM"
       */
      customerVendorNumber?: string
    }
    /** Customer user data to be presented along the orders */
    customerUser: {
      /**
       * Customer User ID
       * @format uuid
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Name of the customer user
       * @example "John Doe"
       */
      name: string
      /**
       * Email of the customer user
       * @example "john.doe@example.com"
       */
      email: string
    }
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    deliveryStatus: ModelSearchCustomerPlatformOrdersDeliveryStatusEnum
    orderPaymentStatus: ModelSearchCustomerPlatformOrdersOrderPaymentStatusEnum
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchCustomerPlatformOrdersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetCustomerPlatformOrderVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Version of eClass specification */
export type ModelGetCustomerPlatformOrderVersionEnum1 =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelGetCustomerPlatformOrderStatusEnum =
  | 'WAITING_FOR_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'

export type ModelGetCustomerPlatformOrderRejectionReasonEnum =
  | 'NOT_REJECTED'
  | 'BY_APPROVER'
  | 'FLOW_DELETED'
  | 'FLOW_DISABLED'
  | 'SHOPPER_DELETED'
  | 'APPROVER_DELETED'

export type ModelGetCustomerPlatformOrderStatusEnum1 =
  | 'WAITING_FOR_APPROVAL'
  | 'NOT_READY_FOR_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'
  | 'DOES_NOT_NEED_APPROVAL'
  | 'APPROVAL_OMITTED'

export type ModelGetCustomerPlatformOrderTemplateEnum =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetCustomerPlatformOrderTemplateEnum1 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetCustomerPlatformOrderTemplateEnum2 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetCustomerPlatformOrderSelectionTypeEnum =
  | 'SELECTED_VENDORS'
  | 'ALL_VENDORS'
  | 'VENDORS_FROM_OPEN_MARKETPLACE'
  | 'VENDORS_FROM_PARTNER_MARKETPLACE'

export type ModelGetCustomerPlatformOrderTemplateEnum3 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetCustomerPlatformOrderTemplateEnum4 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetCustomerPlatformOrderWaitingForApprovalFromEnum =
  | 'MAIN_APPROVER'
  | 'BOTH_APPROVERS'

/** Platform order details */
export interface ModelGetCustomerPlatformOrderData {
  /** Vendor data to be displayed on order details */
  vendor: {
    /**
     * Vendor ID
     * @example "05e68d55-d624-468a-baeb-6992a03e32e4"
     */
    id: string
    /** @example "ACME Ltd." */
    name: string
  }
  /** Data of platform order shopper (customerUser) */
  shopper: {
    /**
     * Customer user (shopper) ID
     * @example "c70717d5-d6bc-4757-b729-9263539630e5"
     */
    customerUserId: string
    /**
     * Name of the shopper
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the shopper
     * @example "john.doe@example.com"
     */
    email: string
  }
  /** Platform order */
  order: {
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** OrderItems */
    orderItems?: ({
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    } & {
      eclass: {
        /** Version of eClass specification */
        version: ModelGetCustomerPlatformOrderVersionEnum
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
    })[]
    /**
     * Customer comment to the order
     * @example "As per contract 123"
     */
    comment?: string
    /**
     * Timestamp when the order was created. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
  } & {
    /**
     * ID of the platform order
     * @example "72fce181-649a-4cae-831a-80667ed8d11a"
     */
    id: string
    /**
     * Internal or customer specific order number
     * @example 19
     */
    orderNumber: string
    /**
     * ID of the order batch
     * @example "ea0031df-0a5e-412d-9a48-0fcdf0eaac07"
     */
    batchId: string
    /**
     * Order batch number (other name on designs - cart ID)
     * @format int64
     * @example 18
     */
    batchNumber?: number
    orderItems: (({
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    } & {
      eclass: {
        /** Version of eClass specification */
        version: ModelGetCustomerPlatformOrderVersionEnum1
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
    }) & {
      /**
       * URL to the thumbnail of the item article
       * @format url
       * @example "https://simplesystem.com/images/1234.jpg"
       */
      thumbnailUrl?: string
      accountType?: {
        /**
         * Account type prefix
         * @example "K"
         */
        prefix: string
        /**
         * Account type description
         * @example "Project Name"
         */
        description: string
      }
      accountValue?: {
        /**
         * Account value number
         * @example "12213131"
         */
        number: string
        /**
         * Account value description (null for free-text values)
         * @example "Next Gen"
         */
        description?: string
      }
      generalLedger?: {
        /**
         * General Ledger number
         * @example "1231313"
         */
        number: string
        /**
         * General Ledger description
         * @example "Marketing"
         */
        description: string
      }
      /** true if the article comes from restricted catalog */
      closedMarketArticle?: boolean
      /**
       * Additional comment made to the selected account
       * @example "CEBIT / EXPO"
       */
      additionalAccountingInfo?: string
      /**
       * Shopper's additional texts on some order positions
       * @example "for Machine 4711"
       */
      itemComment?: string
    })[]
  }
  /** Delivery address of an order */
  deliveryAddress: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /**
     * Alternate recipient name
     * @example "Jan Schmidt"
     */
    alternateRecipient?: string
    /**
     * Additional address information line 1
     * @example "6th floor"
     */
    additionalInformation?: string
    /**
     * Additional address information line 2
     * @example "desk 12"
     */
    additionalInformation2?: string
    /** @example "Track 001" */
    unloadingPoint?: string
  } & {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string
  }
  /**
   * True if the user can modify the order number during the approval process
   * @example true
   */
  orderNumberModificationAllowed: boolean
  /** A process of approving an order */
  approvalProcessState?: {
    /**
     * Approval Process ID
     * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
     */
    id: string
    status: ModelGetCustomerPlatformOrderStatusEnum
    rejectionReason?: ModelGetCustomerPlatformOrderRejectionReasonEnum
    /**
     * Currently executed step (if 'null' - no step is executed, which means the process finished either as approved or rejected)
     * @min 0
     * @example 2
     */
    currentStepIndex?: number | null
    steps: {
      status: ModelGetCustomerPlatformOrderStatusEnum1
      mainApprover: {
        /**
         * @format uuid
         * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
         */
        id: string
        /** @example "Hans Muller" */
        name: string
      }
      deputyApprover?: {
        /**
         * @format uuid
         * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
         */
        id: string
        /** @example "Hans Muller" */
        name: string
      }
      rule:
        | ({
            template: ModelGetCustomerPlatformOrderTemplateEnum
          } & {
            articlePriceBiggerThanRuleConfiguration?: {
              /** Amount of money */
              priceGreaterThan?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
            } | null
          })
        | ({
            template: ModelGetCustomerPlatformOrderTemplateEnum1
          } & {
            orderTotalBiggerThanSimpleRuleConfiguration?: {
              /** Amount of money */
              totalGreaterThan?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
            } | null
          })
        | ({
            template: ModelGetCustomerPlatformOrderTemplateEnum2
          } & {
            vendorIsAnyOfRuleConfiguration?: {
              vendorIds?: string[] | null
              selectionType?: ModelGetCustomerPlatformOrderSelectionTypeEnum
            }
          })
        | ({
            template: ModelGetCustomerPlatformOrderTemplateEnum3
          } & {
            accountValueIsAnyOfRuleConfiguration?: {
              accountValueIds?: string[]
            } | null
          })
        | ({
            template: ModelGetCustomerPlatformOrderTemplateEnum4
          } & {
            articleEClassAnyOfConfiguration?: {
              /**
               * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
               * When eClass node has `true` value its selected and all of its children are also selected.
               * Mapping to `false` clears mapping defined by parents
               * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
               */
              eClasses?: Record<string, boolean>
            } | null
          })
      waitingForApprovalFrom?: ModelGetCustomerPlatformOrderWaitingForApprovalFromEnum
      /**
       * Timestamp of the date when order can be approved. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       * @format date-time
       * @example "2022-11-25T11:10:57.849+00:00"
       */
      waitingForApprovalUntil?: string
      /**
       * Timestamp when the step's status was changed (i.e. approved). Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       * @format date-time
       * @example "2022-11-25T11:10:57.849+00:00"
       */
      statusChangedAt: string
      statusChangedBy?: {
        /**
         * @format uuid
         * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
         */
        id: string
        /** @example "Hans Muller" */
        name: string
      }
      /**
       * @example "Last year, a new rule was introduced under which we cannot allow orders from this supplier to be made under office orders. Please use the predefined lists created by the company's managers.
       * "
       */
      reasonOfRejection?: string
    }[]
  }
  /**
   * Date when the order should be delivered
   * @format date
   * @example "2023-08-08"
   */
  desiredDeliveryDate?: string
}

/** Error */
export type ModelGetCustomerPlatformOrderError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelReorderCustomerPlatformOrderPerformedOperationEnum =
  | 'CREATED'
  | 'UPDATED'
  | 'REPLICATED'

export type ModelReorderCustomerPlatformOrderReasonEnum =
  | 'OCI_ITEM'
  | 'RESTRICTED_VIEW'
  | 'NOT_AVAILABLE'
  | 'RESTRICTED_PRICE'

/** List of order items Ids to be reordered */
export interface ModelReorderCustomerPlatformOrderPayload {
  orderItemIds?: string[]
}

/** Response with items successfully added and not added to cart */
export interface ModelReorderCustomerPlatformOrderData {
  /** List of order items successfully added to cart */
  successfullyAdded: {
    /**
     * Order Item ID
     * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
     */
    orderItemId: string
    /**
     * @format int32
     * @min 1
     */
    inCartQuantity: number
    performedOperation: ModelReorderCustomerPlatformOrderPerformedOperationEnum
  }[]
  /** List of order items not added to cart */
  notAdded: {
    /**
     * Order Item ID
     * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
     */
    orderItemId: string
    reason: ModelReorderCustomerPlatformOrderReasonEnum
  }[]
}

/**
 * Error
 * Error
 */
export type ModelReorderCustomerPlatformOrderError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerPlatformOrdersVendorsParams {
  /** Defines order scope to search */
  kind?: ModelKindEnum
}

/** Defines order scope to search */
export type ModelKindEnum =
  | 'ALL_COMPANY_ORDERS'
  | 'MY_PERSONAL_ORDERS'
  | 'WAITING_FOR_MY_APPROVALS'

export type ModelGetCustomerPlatformOrdersVendorsData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

/** Defines order scope to search */
export type ModelGetCustomerPlatformOrdersVendorsParams1KindEnum =
  | 'ALL_COMPANY_ORDERS'
  | 'MY_PERSONAL_ORDERS'
  | 'WAITING_FOR_MY_APPROVALS'

/** Status of custom order numbering */
export interface ModelGetCustomerCustomOrderNumberingData {
  /** True when custom order numbers are to be generated automatically */
  enabled: boolean
  /** If custom order number generation is enabled defines how the numbers are generated */
  schema?: {
    /**
     * Prefix - common part for all generated custom order numbers
     * @example "HF-30-X/Y-"
     */
    prefix: string
    /**
     * First number of the custom numbering sequence. This is a string accepting only digits.  The length determines adding leading zeros to the number, so it is never shorter that the length of the start value.
     * @example "0000"
     */
    start: string
    /**
     * Last possible number of the custom numbering sequence. It is used to determine length of the variable part of the custom order numbers.
     * @format int64
     * @min 0
     * @example 999999
     */
    maximum: number
  } & {
    /**
     * Next custom order number to be generated - serves as example and information how much the current sequence is close to the end
     * @example "HF-30-X/Y-000701"
     */
    nextAvailable: string
    /**
     * If true, the cart cannot be checked out, and custom order number cannot be generated anymore
     * @example false
     */
    customOrderNumberSequenceExhausted: boolean
  }
}

/** Error */
export type ModelGetCustomerCustomOrderNumberingError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to change custom order numbering */
export interface ModelPostCustomerCustomOrderNumberingPayload {
  /** True when custom order numbers are to be generated automatically */
  enabled: boolean
  /** Configuration of custom order numbering */
  schema?: {
    /**
     * Prefix - common part for all generated custom order numbers
     * @example "HF-30-X/Y-"
     */
    prefix: string
    /**
     * First number of the custom numbering sequence. This is a string accepting only digits.  The length determines adding leading zeros to the number, so it is never shorter that the length of the start value.
     * @example "0000"
     */
    start: string
    /**
     * Last possible number of the custom numbering sequence. It is used to determine length of the variable part of the custom order numbers.
     * @format int64
     * @min 0
     * @example 999999
     */
    maximum: number
  }
}

export type ModelPostCustomerCustomOrderNumberingData = any

/** Error */
export type ModelPostCustomerCustomOrderNumberingError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerPlatformOrderCustomerUsersParams {
  /** Defines order scope to search */
  kind?: ModelKindEnum1
}

/** Defines order scope to search */
export type ModelKindEnum1 =
  | 'ALL_COMPANY_ORDERS'
  | 'MY_PERSONAL_ORDERS'
  | 'WAITING_FOR_MY_APPROVALS'

export type ModelGetCustomerPlatformOrderCustomerUsersData = {
  /**
   * @format uuid
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  id: string
  /** @example "Hans Muller" */
  name: string
}[]

/** Defines order scope to search */
export type ModelGetCustomerPlatformOrderCustomerUsersParams1KindEnum =
  | 'ALL_COMPANY_ORDERS'
  | 'MY_PERSONAL_ORDERS'
  | 'WAITING_FOR_MY_APPROVALS'

/** Configuration of custom order numbering */
export interface ModelPostCustomerCustomOrderNumberingPreviewPayload {
  /**
   * Prefix - common part for all generated custom order numbers
   * @example "HF-30-X/Y-"
   */
  prefix: string
  /**
   * First number of the custom numbering sequence. This is a string accepting only digits.  The length determines adding leading zeros to the number, so it is never shorter that the length of the start value.
   * @example "0000"
   */
  start: string
  /**
   * Last possible number of the custom numbering sequence. It is used to determine length of the variable part of the custom order numbers.
   * @format int64
   * @min 0
   * @example 999999
   */
  maximum: number
}

/** Example of custom order number */
export interface ModelPostCustomerCustomOrderNumberingPreviewData {
  /**
   * Example value of custom order number for given configuration
   * @example "HF-30-X/Y-000001"
   */
  example: string
}

/** Error */
export type ModelPostCustomerCustomOrderNumberingPreviewError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelListCustomerErpOrdersDeliveryStatusEnum =
  | 'IN_DELIVERY'
  | 'PARTLY_DELIVERED'
  | 'DELIVERED'

export type ModelListCustomerErpOrdersOrderPaymentStatusEnum =
  | 'UNPAID'
  | 'PARTLY_PAID'
  | 'FULLY_PAID'

/** Search & filtering parameters for ERP order listing */
export interface ModelListCustomerErpOrdersPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
  /**
   * Chosen vendors scope for the current search
   * @example ["fe903a8c-4e26-49de-95a6-33d4f4b0883b","93f6947e-2295-4f85-bec9-d610d18d373e"]
   */
  vendorIds?: string[]
  /**
   * First day of the date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-02"
   */
  startDate?: string
  /**
   * Last day of the date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-09"
   */
  endDate?: string
}

export interface ModelListCustomerErpOrdersParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [externalOrderNumber,desc`, `externalOrderNumber,asc`, `createdAt,desc`, `createdAt,asc`]).
   * Default sorting: `createdAt,desc`.
   */
  sort?: string[]
}

/** Response with ERP orders search results */
export interface ModelListCustomerErpOrdersData {
  /** List of search results */
  items: {
    /**
     * ERP order ID
     * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
     */
    id: string
    /**
     * Order number in customer's ERP system
     * @example "SLSODR1398059203"
     */
    externalOrderNumber: string
    /**
     * Internal order number
     * @format int64
     * @example 19
     */
    orderNumber: number
    /**
     * Timestamp when the order was created. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    /** Vendor data to be presented along the orders */
    vendor: {
      /**
       * Vendor ID
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Vendor company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Customer specific vendor numer
       * @example "ACM"
       */
      customerVendorNumber?: string
    }
    /** Data of ERP order shopper */
    shopper: {
      /**
       * Name of the shopper
       * @example "John Doe"
       */
      name: string
      /**
       * Email of the shopper
       * @example "john.doe@example.com"
       */
      email?: string
    }
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    deliveryStatus: ModelListCustomerErpOrdersDeliveryStatusEnum
    orderPaymentStatus: ModelListCustomerErpOrdersOrderPaymentStatusEnum
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelListCustomerErpOrdersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetCustomerErpOrderVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

/** ERP order details */
export interface ModelGetCustomerErpOrderData {
  /** Vendor data to be displayed on order details */
  vendor: {
    /**
     * Vendor ID
     * @example "05e68d55-d624-468a-baeb-6992a03e32e4"
     */
    id: string
    /** @example "ACME Ltd." */
    name: string
  }
  /** Data of ERP order shopper */
  shopper: {
    /**
     * Name of the shopper
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the shopper
     * @example "john.doe@example.com"
     */
    email?: string
  }
  /** ERP order */
  order: {
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** OrderItems */
    orderItems?: ({
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    } & {
      eclass: {
        /** Version of eClass specification */
        version: ModelGetCustomerErpOrderVersionEnum
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
    })[]
    /**
     * Customer comment to the order
     * @example "As per contract 123"
     */
    comment?: string
    /**
     * Timestamp when the order was created. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
  } & {
    /**
     * Id of the ERP order
     * @example "96c35df9-5097-4d9b-b628-8b0f4f872c78"
     */
    id: string
    /**
     * @format int64
     * @example 19
     */
    orderNumber: number
    /**
     * Order number in customer's ERP system
     * @example 5900001845
     */
    externalOrderNumber: string
    orderItems: {
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    }[]
  }
  /** Delivery address of an order */
  deliveryAddress: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /**
     * Alternate recipient name
     * @example "Jan Schmidt"
     */
    alternateRecipient?: string
    /**
     * Additional address information line 1
     * @example "6th floor"
     */
    additionalInformation?: string
    /**
     * Additional address information line 2
     * @example "desk 12"
     */
    additionalInformation2?: string
    /** @example "Track 001" */
    unloadingPoint?: string
  } & {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string
  }
}

/** Error */
export type ModelGetCustomerErpOrderError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerErpOrdersVendorsData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

/** New price restriction */
export interface ModelAddNewPriceRestrictionPayload {
  /**
   * Price restriction name
   * @example "Marketing C level"
   */
  name: string
  /** Amount of money */
  limit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * True if the price restriction is a default restriction
   * @example false
   */
  defaultRestriction: boolean
}

export type ModelAddNewPriceRestrictionData = any

/**
 * Error
 * Error
 */
export type ModelAddNewPriceRestrictionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Search & filtering parameters for price restrictions list */
export interface ModelSearchPriceRestrictionsPayload {
  /**
   * Chosen customer users for the current search
   * @example ["fe903a8c-4e26-49de-95a6-33d4f4b0883b","93f6947e-2295-4f85-bec9-d610d18d373e"]
   */
  customerUserIds?: string[]
}

export interface ModelSearchPriceRestrictionsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,desc`, `name,asc`]).
   * Default sorting: `name,desc`.
   */
  sort?: string[]
}

/** Response with price restrictions search results */
export interface ModelSearchPriceRestrictionsData {
  /** List of price restrictions */
  items: ({
    /**
     * Price Restriction ID
     * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
     */
    id: string
    /**
     * Price Restriction name
     * @example "Marketing C level"
     */
    name: string
    /** Amount of money */
    limit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * True if the price restriction is a default restriction
     * @example false
     */
    defaultRestriction: boolean
  } & {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  /**
   * True if default price restriction available
   * @example false
   */
  hasDefault: boolean
}

/** Error */
export type ModelSearchPriceRestrictionsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** New price restriction */
export interface ModelGetPriceRestrictionData {
  /**
   * Price restriction name
   * @example "Marketing C level"
   */
  name: string
  /** Amount of money */
  limit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * True if the price restriction is a default restriction
   * @example false
   */
  defaultRestriction: boolean
}

/** Price restriction update request */
export interface ModelUpdatePriceRestrictionPayload {
  /**
   * Price restriction name
   * @example "Marketing C level"
   */
  name: string
  /** Amount of money */
  limit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
}

export type ModelUpdatePriceRestrictionData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdatePriceRestrictionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeletePriceRestrictionData = any

/** Error */
export type ModelDeletePriceRestrictionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetCustomerPriceRestrictionAssignmentsData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetCustomerPriceRestrictionAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelPutCustomerPriceRestrictionAssignmentPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelPutCustomerPriceRestrictionAssignmentData = any

/**
 * Error
 * Error
 */
export type ModelPutCustomerPriceRestrictionAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Cart restriction */
export interface ModelCreateCartRestrictionPayload {
  /**
   * Cart restriction name
   * @example "Marketing C level"
   */
  name: string
  /** Amount of money */
  limit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * True if the cart restriction is a default restriction
   * @example false
   */
  defaultRestriction: boolean
}

export type ModelCreateCartRestrictionData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelCreateCartRestrictionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelSearchCartRestrictionsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,desc`, `name,asc`]).
   * Default sorting: `name,desc`.
   */
  sort?: string[]
}

/** Response with cart restrictions search results */
export interface ModelSearchCartRestrictionsData {
  /** List of cart restrictions */
  items: ({
    /**
     * Cart Restriction ID
     * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
     */
    id: string
  } & {
    /**
     * Cart restriction name
     * @example "Marketing C level"
     */
    name: string
    /** Amount of money */
    limit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * True if the cart restriction is a default restriction
     * @example false
     */
    defaultRestriction: boolean
  } & {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  /**
   * True if default cart restriction available
   * @example false
   */
  hasDefault: boolean
}

/** Error */
export type ModelSearchCartRestrictionsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Cart restriction */
export interface ModelUpdateCartRestrictionPayload {
  /**
   * Cart restriction name
   * @example "Marketing C level"
   */
  name: string
  /** Amount of money */
  limit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * True if the cart restriction is a default restriction
   * @example false
   */
  defaultRestriction: boolean
}

export type ModelUpdateCartRestrictionData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateCartRestrictionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCartRestrictionData = any

/** Error */
export type ModelDeleteCartRestrictionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetCustomerCartRestrictionAssignmentsData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetCustomerCartRestrictionAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelPutCustomerCartRestrictionAssignmentPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelPutCustomerCartRestrictionAssignmentData = any

/** Error */
export type ModelPutCustomerCartRestrictionAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Search & filtering parameters for catalog views listing */
export interface ModelSearchCustomerCatalogViewsPayload {
  /**
   * Chosen customer users for the current search
   * @example ["fe903a8c-4e26-49de-95a6-33d4f4b0883b","93f6947e-2295-4f85-bec9-d610d18d373e"]
   */
  customerUserIds?: string[]
}

export interface ModelSearchCustomerCatalogViewsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,desc`, `name,asc`]).
   * Default sorting: `name,desc`.
   */
  sort?: string[]
}

/** Response with catalog views search results */
export interface ModelSearchCustomerCatalogViewsData {
  /** List of search results */
  items: ({
    /**
     * catalog view ID
     * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
     */
    id: string
    /**
     * catalog view name
     * @example "Marketing below 10k"
     */
    name: string
    /**
     * number of all vendors that customer have relationship with
     * @min 0
     * @example 6
     */
    myVendorsCount: number
    /**
     * number of all vendors that customer does not have relationship with
     * @min 0
     * @example 12
     */
    otherVendorsCount: number
    /**
     * Marks the catalog as default catalog view
     * @example false
     */
    defaultView: boolean
  } & {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  /**
   * Is 'default' catalog view configured
   * @example false
   */
  hasDefault: any
}

/** Error */
export type ModelSearchCustomerCatalogViewsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerCatalogViewData = any

/** Error */
export type ModelDeleteCustomerCatalogViewError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetCustomerCatalogViewAssignmentsData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetCustomerCatalogViewAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelPutCustomerCatalogViewAssignmentPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelPutCustomerCatalogViewAssignmentData = any

/**
 * Error
 * Error
 */
export type ModelPutCustomerCatalogViewAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with catalog view draft Id */
export interface ModelCreateNewCustomerCatalogViewDraftPayload {
  /**
   * Catalog view the draft is created for, if `null` then it is a draft for new catalog view
   * @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b"
   */
  catalogViewId?: string
  /**
   * Marks the catalog as default catalog view (required only when creating new catalog view)
   * @default false
   * @example false
   */
  defaultView?: boolean
}

/** Response with catalog view draft Id */
export interface ModelCreateNewCustomerCatalogViewDraftData {
  /** @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b" */
  id: string
}

/** Error */
export type ModelCreateNewCustomerCatalogViewDraftError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Catalog View Draft */
export interface ModelGetCustomerCatalogViewDraftData {
  /** @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b" */
  id: string
  /**
   * Catalog view name
   * @example "Finance above 10k"
   */
  name?: string
  /** Settings for new vendors */
  newVendorsSettings: {
    myVendors: {
      visible: boolean
      closedMarketplace: boolean
      openMarketplace: boolean
      coreArticlesOnly: boolean
      /** Blocked eclasses definition. Fields 'version' and 'nodeSelection' are required if `enabled` == `true`, ignored otherwise */
      blockedEClasses: {
        /**
         * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
         * When eClass node has `true` value its selected and all of its children are also selected.
         * Mapping to `false` clears mapping defined by parents
         * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
         */
        nodeSelection?: Record<string, boolean>
        enabled: boolean
      }
    }
    otherVendors: {
      visible: boolean
      /** Blocked eclasses definition. Fields 'version' and 'nodeSelection' are required if `enabled` == `true`, ignored otherwise */
      blockedEClasses: {
        /**
         * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
         * When eClass node has `true` value its selected and all of its children are also selected.
         * Mapping to `false` clears mapping defined by parents
         * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
         */
        nodeSelection?: Record<string, boolean>
        enabled: boolean
      }
    }
  }
  /**
   * Marks the catalog as default catalog view
   * @example false
   */
  defaultView: boolean
}

/** Error */
export type ModelGetCustomerCatalogViewDraftError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelUpdateCustomerCatalogViewDraftPayload {
  /** @example "Finance over 10k" */
  name?: string | null
  /** Settings for new vendors */
  newVendorsSettings?: {
    myVendors?: {
      visible?: boolean | null
      closedMarketplace?: boolean | null
      openMarketplace?: boolean | null
      coreArticlesOnly?: boolean | null
      /** Blocked eclasses definition. Fields 'version' and 'nodeSelection' are required if `enabled` == `true`, ignored otherwise */
      blockedEClasses?: {
        /**
         * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
         * When eClass node has `true` value its selected and all of its children are also selected.
         * Mapping to `false` clears mapping defined by parents
         * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
         */
        nodeSelection?: Record<string, boolean>
        enabled: boolean
      } | null
    }
    otherVendors?: {
      visible?: boolean | null
      /** Blocked eclasses definition. Fields 'version' and 'nodeSelection' are required if `enabled` == `true`, ignored otherwise */
      blockedEClasses?: {
        /**
         * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
         * When eClass node has `true` value its selected and all of its children are also selected.
         * Mapping to `false` clears mapping defined by parents
         * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
         */
        nodeSelection?: Record<string, boolean>
        enabled: boolean
      } | null
    }
  }
}

export type ModelUpdateCustomerCatalogViewDraftData = any

/** Error */
export type ModelUpdateCustomerCatalogViewDraftError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerCatalogViewDraftData = any

/** Error */
export type ModelDeleteCustomerCatalogViewDraftError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with catalog view draft promotion */
export interface ModelPromoteCustomerCatalogViewDraftData {
  /** @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b" */
  catalogViewId: string
}

/**
 * Error
 * Error
 */
export type ModelPromoteCustomerCatalogViewDraftError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Count visible vendors respecting given search terms */
export interface ModelCountCustomerCatalogViewDraftMyVendorsPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
}

/** Counted visible vendors */
export interface ModelCountCustomerCatalogViewDraftMyVendorsData {
  /**
   * number of all visible vendors for the customer
   * @min 0
   * @example 2
   */
  vendorsVisibleCount: number
  /**
   * all relationships with vendors count
   * @min 0
   * @example 6
   */
  vendorsTotal: number
  /**
   * chosen vendors scope for the current search
   * @uniqueItems true
   */
  vendorsIds: string[]
}

/** Error */
export type ModelCountCustomerCatalogViewDraftMyVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Status of Vendor-Customer relationship */
export type ModelSearchCustomerCatalogViewDraftMyVendorsRelationStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

/** Search parameters for catalog views vendors listing */
export interface ModelSearchCustomerCatalogViewDraftMyVendorsPayload {
  /**
   * Search term
   * @example "Zotware"
   */
  term?: string
}

export interface ModelSearchCustomerCatalogViewDraftMyVendorsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Catalog View Draft ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  catalogViewDraftId: string
}

/** Catalog View My Vendors List */
export interface ModelSearchCustomerCatalogViewDraftMyVendorsData {
  items: {
    /**
     * Vendor ID
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    vendorId: string
    /**
     * URL to the thumbnail of vendor
     * @format url
     * @example "https://simplesystem.com/images/1234.jpg"
     */
    logo: string
    /**
     * Vendor company name
     * @example "ACME Ltd."
     */
    name: string
    /**
     * Customer specific vendor number
     * @example "ACM"
     */
    customerSpecificVendorNumber: string
    visible: boolean
    closedMarketplace: boolean
    openMarketplace: boolean
    coreArticlesOnly: boolean
    /** Status of Vendor-Customer relationship */
    relationStatus: ModelSearchCustomerCatalogViewDraftMyVendorsRelationStatusEnum
    /** Blocked eclasses definition. Fields 'version' and 'nodeSelection' are required if `enabled` == `true`, ignored otherwise */
    blockedEClasses: {
      /**
       * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
       * When eClass node has `true` value its selected and all of its children are also selected.
       * Mapping to `false` clears mapping defined by parents
       * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
       */
      nodeSelection?: Record<string, boolean>
      enabled: boolean
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  defaultVendorNumber?: string
}

/** Error */
export type ModelSearchCustomerCatalogViewDraftMyVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPatchCustomerCatalogViewDraftMyVendorUpdateModeEnum =
  | 'ADD'
  | 'REPLACE'

/** Catalog View My Vendors bulk update */
export interface ModelPatchCustomerCatalogViewDraftMyVendorPayload {
  /**
   * List of Vendor IDs
   * @minItems 1
   */
  vendorIds: string[]
  values: {
    visible?: boolean | null
    closedMarketplace?: boolean | null
    openMarketplace?: boolean | null
    coreArticlesOnly?: boolean | null
    eClassBlockingEnabled?: boolean | null
    /** Blocked eClasses update */
    blockedEClasses?: {
      updateMode: ModelPatchCustomerCatalogViewDraftMyVendorUpdateModeEnum
      /**
       * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
       * When eClass node has `true` value its selected and all of its children are also selected.
       * Mapping to `false` clears mapping defined by parents
       * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
       */
      nodeSelection: Record<string, boolean>
    } | null
  }
}

export type ModelPatchCustomerCatalogViewDraftMyVendorData = any

/** Error */
export type ModelPatchCustomerCatalogViewDraftMyVendorError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Count visible vendors respecting given search terms */
export interface ModelCountCustomerCatalogViewDraftOtherVendorsPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
}

/** Counted visible vendors */
export interface ModelCountCustomerCatalogViewDraftOtherVendorsData {
  /**
   * number of all visible vendors for the customer
   * @min 0
   * @example 2
   */
  vendorsVisibleCount: number
  /**
   * all relationships with vendors count
   * @min 0
   * @example 6
   */
  vendorsTotal: number
  /**
   * chosen vendors scope for the current search
   * @uniqueItems true
   */
  vendorsIds: string[]
}

/** Error */
export type ModelCountCustomerCatalogViewDraftOtherVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Search parameters for catalog views vendors listing */
export interface ModelSearchCustomerCatalogViewDraftOtherVendorsPayload {
  /**
   * Search term
   * @example "Zotware"
   */
  term?: string
}

export interface ModelSearchCustomerCatalogViewDraftOtherVendorsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Catalog View Draft ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  catalogViewDraftId: string
}

/** Catalog View Other Vendors List */
export interface ModelSearchCustomerCatalogViewDraftOtherVendorsData {
  items: {
    /**
     * Vendor ID
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    vendorId: string
    /**
     * URL to the thumbnail of vendor
     * @format url
     * @example "https://simplesystem.com/images/1234.jpg"
     */
    logo: string
    /**
     * Vendor company name
     * @example "ACME Ltd."
     */
    name: string
    visible: boolean
    /** Blocked eclasses definition. Fields 'version' and 'nodeSelection' are required if `enabled` == `true`, ignored otherwise */
    blockedEClasses: {
      /**
       * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
       * When eClass node has `true` value its selected and all of its children are also selected.
       * Mapping to `false` clears mapping defined by parents
       * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
       */
      nodeSelection?: Record<string, boolean>
      enabled: boolean
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchCustomerCatalogViewDraftOtherVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPatchCustomerCatalogViewDraftOtherVendorUpdateModeEnum =
  | 'ADD'
  | 'REPLACE'

/** Catalog View Other Vendors bulk update */
export interface ModelPatchCustomerCatalogViewDraftOtherVendorPayload {
  /**
   * List of Vendor IDs
   * @minItems 1
   */
  vendorIds: string[]
  values: {
    visible?: boolean | null
    eClassBlockingEnabled?: boolean | null
    /** Blocked eClasses update */
    blockedEClasses?: {
      updateMode: ModelPatchCustomerCatalogViewDraftOtherVendorUpdateModeEnum
      /**
       * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
       * When eClass node has `true` value its selected and all of its children are also selected.
       * Mapping to `false` clears mapping defined by parents
       * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
       */
      nodeSelection: Record<string, boolean>
    } | null
  }
}

export type ModelPatchCustomerCatalogViewDraftOtherVendorData = any

/** Error */
export type ModelPatchCustomerCatalogViewDraftOtherVendorError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Filtering parameters for order count */
export interface ModelGetCustomerOrdersCountersForDeliveriesAndPaymentsPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
}

/** Response with type of orders and their quantity */
export interface ModelGetCustomerOrdersCountersForDeliveriesAndPaymentsData {
  /**
   * Count of orders made by the customer user
   * @format int64
   * @example 2
   */
  currentUserOrderCount: number
  /**
   * Count of platform orders made by the company
   * @format int64
   * @example 2
   */
  companyPlatformOrderCount?: number
  /**
   * Count of erp orders made by the company
   * @format int64
   * @example 3
   */
  companyErpOrderCount?: number
}

/** Error */
export type ModelGetCustomerOrdersCountersForDeliveriesAndPaymentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersDeliveryStatusEnum =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

export type ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersPaymentStatusEnum =
  'UNPAID' | 'PARTLY_PAID' | 'FULLY_PAID'

export type ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersPaymentStatusesEnum =
  'UNPAID' | 'PARTLY_PAID' | 'FULLY_PAID'

export type ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersDeliveryStatusesEnum =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

/** Search & filtering parameters for ERP order listing */
export interface ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
  /** Chosen payment scope for the current search */
  paymentStatuses?: ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersPaymentStatusesEnum[]
  /** Chosen delivery scope for the current search */
  deliveryStatuses?: ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersDeliveryStatusesEnum[]
  /**
   * Chosen vendors scope for the current search
   * @example ["fe903a8c-4e26-49de-95a6-33d4f4b0883b","93f6947e-2295-4f85-bec9-d610d18d373e"]
   */
  vendorIds?: string[]
  /**
   * First day of the placedAt date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-02"
   */
  startDate?: string
  /**
   * Last day of the placedAt date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-09"
   */
  endDate?: string
}

export interface ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [externalOrderNumber,desc`, `externalOrderNumber,asc`, `createdAt,desc`, `createdAt,asc`]).
   * Default sorting: `createdAt,desc`.
   */
  sort?: string[]
}

/** Response with ERP orders search results */
export interface ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersData {
  /** List of search results */
  items: {
    /**
     * ERP order ID
     * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
     */
    id: string
    /**
     * Order number in customer's ERP system
     * @example "SLSODR1398059203"
     */
    externalOrderNumber: string
    /**
     * Internal order number
     * @format int64
     * @example 8059203
     */
    orderNumber: number
    /**
     * Timestamp when the order was created. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    deliveryStatus: ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersDeliveryStatusEnum
    paymentStatus: ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersPaymentStatusEnum
    /** Vendor data to be presented along the orders */
    vendor: {
      /**
       * Vendor ID
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Vendor company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Customer specific vendor numer
       * @example "ACM"
       */
      customerVendorNumber?: string
    }
    /** Data of ERP order shopper */
    shopper: {
      /**
       * Name of the shopper
       * @example "John Doe"
       */
      name: string
      /**
       * Email of the shopper
       * @example "john.doe@example.com"
       */
      email?: string
    }
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetCustomerDeliveriesAndPaymentsForErpOrderVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelGetCustomerDeliveriesAndPaymentsForErpOrderDeliveryStatusEnum =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

export type ModelGetCustomerDeliveriesAndPaymentsForErpOrderDeliveryStatusEnum1 =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

export type ModelGetCustomerDeliveriesAndPaymentsForErpOrderPaymentStatusEnum =
  | 'UNPAID'
  | 'PARTLY_PAID'
  | 'FULLY_PAID'

export type ModelGetCustomerDeliveriesAndPaymentsForErpOrderItemsPaymentStatusEnum =
  'UNPAID' | 'FULLY_PAID'

/** ERP order details */
export interface ModelGetCustomerDeliveriesAndPaymentsForErpOrderData {
  /** Vendor data to be displayed on order details */
  vendor: {
    /**
     * Vendor ID
     * @example "05e68d55-d624-468a-baeb-6992a03e32e4"
     */
    id: string
    /** @example "ACME Ltd." */
    name: string
  }
  /** Data of ERP order shopper */
  shopper: {
    /**
     * Name of the shopper
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the shopper
     * @example "john.doe@example.com"
     */
    email?: string
  }
  /** ERP order */
  order: {
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** OrderItems */
    orderItems?: ({
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    } & {
      eclass: {
        /** Version of eClass specification */
        version: ModelGetCustomerDeliveriesAndPaymentsForErpOrderVersionEnum
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
    })[]
    /**
     * Customer comment to the order
     * @example "As per contract 123"
     */
    comment?: string
    /**
     * Timestamp when the order was created. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
  } & {
    /**
     * Id of the ERP order
     * @example "96c35df9-5097-4d9b-b628-8b0f4f872c78"
     */
    id: string
    /**
     * @format int64
     * @example 19
     */
    orderNumber: number
    /**
     * Order number in customer's ERP system
     * @example 5900001845
     */
    externalOrderNumber: string
    orderItems: {
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    }[]
  }
  /** Delivery and payment data for the order */
  deliveryAndPaymentDetails?: {
    deliveryStatus: ModelGetCustomerDeliveriesAndPaymentsForErpOrderDeliveryStatusEnum
    /**
     * Key value pairs where the key is order item id and the value is delivery status
     * @example {"11aa7d85-dd51-4191-8c26-6d446d48ef45":{"deliveryStatus":"IN_DELIVERY","deliveredQuantity":0,"totalQuantity":12},"ea674491-6699-46c0-9fd5-54720710e18f":{"deliveryStatus":"PARTLY_DELIVERED","deliveredQuantity":3,"totalQuantity":10}}
     */
    itemsDeliveryStatus: Record<
      string,
      {
        deliveryStatus: ModelGetCustomerDeliveriesAndPaymentsForErpOrderDeliveryStatusEnum1
        /**
         * Delivery comments
         * @min 0
         */
        deliveredQuantity: number
        /**
         * Delivery comments
         * @min 1
         */
        totalQuantity: number
      }
    >
    paymentStatus: ModelGetCustomerDeliveriesAndPaymentsForErpOrderPaymentStatusEnum
    /**
     * Key value pairs where the key is order item id and the value is payment status
     * @example {"11aa7d85-dd51-4191-8c26-6d446d48ef45":"PAID","ea674491-6699-46c0-9fd5-54720710e18f":"UNPAID"}
     */
    itemsPaymentStatus: Record<
      string,
      ModelGetCustomerDeliveriesAndPaymentsForErpOrderItemsPaymentStatusEnum
    >
    /** Delivery comments */
    deliveryComments?: string
    /** Payment comments */
    paymentComments?: string
    /**
     * Timestamp when the order delivery and payment details were last edited. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    lastUpdatedAt?: string
    /** Customer user data to be presented along the orders */
    lastUpdatedBy?: {
      /**
       * Customer User ID
       * @format uuid
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Name of the customer user
       * @example "John Doe"
       */
      name: string
      /**
       * Email of the customer user
       * @example "john.doe@example.com"
       */
      email: string
    }
  }
}

/** Error */
export type ModelGetCustomerDeliveriesAndPaymentsForErpOrderError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerDeliveriesAndPaymentsVendorsForErpOrdersData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

export type ModelUpdateErpOrderPaymentStatusStatusEnum = 'UNPAID' | 'PAID'

/** Update of order payment status */
export interface ModelUpdateErpOrderPaymentStatusPayload {
  status: ModelUpdateErpOrderPaymentStatusStatusEnum
}

export type ModelUpdateErpOrderPaymentStatusData = any

/** Error */
export type ModelUpdateErpOrderPaymentStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** OrderPaymentComment */
export interface ModelUpdateErpOrderPaymentCommentPayload {
  /** Order payment comment */
  comment: string
}

export type ModelUpdateErpOrderPaymentCommentData = any

/** Error */
export type ModelUpdateErpOrderPaymentCommentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateErpOrderItemPaymentStatusStatusEnum = 'UNPAID' | 'PAID'

/** Update of order item payment status */
export interface ModelUpdateErpOrderItemPaymentStatusPayload {
  status: ModelUpdateErpOrderItemPaymentStatusStatusEnum
}

export type ModelUpdateErpOrderItemPaymentStatusData = any

/** Error */
export type ModelUpdateErpOrderItemPaymentStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateErpOrderDeliveryStatusStatusEnum =
  | 'IN_DELIVERY'
  | 'DELIVERED'

/** Update of order delivery status */
export interface ModelUpdateErpOrderDeliveryStatusPayload {
  status: ModelUpdateErpOrderDeliveryStatusStatusEnum
}

export type ModelUpdateErpOrderDeliveryStatusData = any

/** Error */
export type ModelUpdateErpOrderDeliveryStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** OrderDeliveryComment */
export interface ModelUpdateErpOrderDeliveryCommentPayload {
  /** ERP order delivery comment */
  comment: string
}

export type ModelUpdateErpOrderDeliveryCommentData = any

/** Error */
export type ModelUpdateErpOrderDeliveryCommentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateErpItemDeliveryStatusStatusEnum =
  | 'IN_DELIVERY'
  | 'PARTLY_DELIVERED'
  | 'DELIVERED'

/** Update of order item delivery status */
export interface ModelUpdateErpItemDeliveryStatusPayload {
  status: ModelUpdateErpItemDeliveryStatusStatusEnum
  /**
   * @format int32
   * @min 0
   */
  quantity?: number
}

export type ModelUpdateErpItemDeliveryStatusData = any

/** Error */
export type ModelUpdateErpItemDeliveryStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersDeliveryStatusEnum =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

export type ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersPaymentStatusEnum =
  'UNPAID' | 'PARTLY_PAID' | 'FULLY_PAID'

export type ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersKindEnum =
  | 'ALL_COMPANY_ORDERS'
  | 'MY_PERSONAL_ORDERS'

export type ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersPaymentStatusesEnum =
  'UNPAID' | 'PARTLY_PAID' | 'FULLY_PAID'

export type ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersDeliveryStatusesEnum =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

/** Search & filtering parameters for order listing */
export interface ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersPayload {
  kind: ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersKindEnum
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
  /** Chosen payment scope for the current search */
  paymentStatuses?: ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersPaymentStatusesEnum[]
  /** Chosen delivery scope for the current search */
  deliveryStatuses?: ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersDeliveryStatusesEnum[]
  /**
   * Chosen vendors scope for the current search
   * @example ["fe903a8c-4e26-49de-95a6-33d4f4b0883b","93f6947e-2295-4f85-bec9-d610d18d373e"]
   */
  vendorIds?: string[]
  /**
   * Chosen shopper scope for the current search
   * @example ["fe903a8c-4e26-49de-95a6-33d4f4b0883b","93f6947e-2295-4f85-bec9-d610d18d373e"]
   */
  shopperIds?: string[]
  /**
   * First day of the placedAt date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-02"
   */
  startDate?: string
  /**
   * Last day of the placedAt date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-09"
   */
  endDate?: string
}

export interface ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`orderNumber,desc`, `orderNumber,asc`, `createdAt,desc`, `createdAt,asc`,
   * `vendor.name,asc`, `vendor.name,desc`, `customerUser.name,asc`, `customerUser.name,desc`]).
   * Default sorting: `createdAt,desc`.
   */
  sort?: string[]
}

/** Response with orders search results */
export interface ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersData {
  /** List of search results */
  items: {
    /**
     * order ID
     * @format uuid
     * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
     */
    id: string
    /**
     * Internal or customer specific order number
     * @example 8059203
     */
    orderNumber: string
    /**
     * Number of a batch which the order is part of
     * @format int64
     * @example 9203987
     */
    orderBatchNumber: number
    /**
     * Timestamp when the order was passed to the vendor. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    placedAt: string
    deliveryStatus: ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersDeliveryStatusEnum
    paymentStatus: ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersPaymentStatusEnum
    /** Vendor data to be presented along the orders */
    vendor: {
      /**
       * Vendor ID
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Vendor company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Customer specific vendor numer
       * @example "ACM"
       */
      customerVendorNumber?: string
    }
    /** Customer user data to be presented along the orders */
    shopper: {
      /**
       * Customer User ID
       * @format uuid
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Name of the customer user
       * @example "John Doe"
       */
      name: string
      /**
       * Email of the customer user
       * @example "john.doe@example.com"
       */
      email: string
    }
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Version of eClass specification */
export type ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderVersionEnum1 =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderDeliveryStatusEnum =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

export type ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderDeliveryStatusEnum1 =
  'IN_DELIVERY' | 'PARTLY_DELIVERED' | 'DELIVERED'

export type ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderPaymentStatusEnum =
  'UNPAID' | 'PARTLY_PAID' | 'FULLY_PAID'

export type ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderItemsPaymentStatusEnum =
  'UNPAID' | 'FULLY_PAID'

/** Platform order details */
export interface ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderData {
  /** Vendor data to be displayed on order details */
  vendor: {
    /**
     * Vendor ID
     * @example "05e68d55-d624-468a-baeb-6992a03e32e4"
     */
    id: string
    /** @example "ACME Ltd." */
    name: string
  }
  /** Customer user data to be presented along the orders */
  shopper: {
    /**
     * Customer User ID
     * @format uuid
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "john.doe@example.com"
     */
    email: string
  }
  /** Platform order */
  order: {
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** OrderItems */
    orderItems?: ({
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    } & {
      eclass: {
        /** Version of eClass specification */
        version: ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderVersionEnum
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
    })[]
    /**
     * Customer comment to the order
     * @example "As per contract 123"
     */
    comment?: string
    /**
     * Timestamp when the order was created. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
  } & {
    /**
     * ID of the platform order
     * @example "72fce181-649a-4cae-831a-80667ed8d11a"
     */
    id: string
    /**
     * Internal or customer specific order number
     * @example 19
     */
    orderNumber: string
    /**
     * ID of the order batch
     * @example "ea0031df-0a5e-412d-9a48-0fcdf0eaac07"
     */
    batchId: string
    /**
     * Order batch number (other name on designs - cart ID)
     * @format int64
     * @example 18
     */
    batchNumber?: number
    orderItems: (({
      /**
       * order item id / ERP order item index
       * @example "b1e538d4-3179-4d71-b1a0-eedad21acc26"
       */
      id: string
      /**
       * Item position on the order - for platform order plain numbering started from 1, for ERP orders transferred in the same form as received.
       * @example "00010"
       */
      positionNumber?: string
      /**
       * Item name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number of this item assigned by the article's vendor
       * @example "ABC123456"
       */
      number: string
      /**
       * The European Article Number of this item
       * @example 9099999543217
       */
      ean?: string
      /** @example "50qgJQNUGgTdM7Q5xlkcOZ:bnVtYmVy" */
      articleId?: string | null
      /** @example false */
      fromExternalCatalog?: boolean
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /** Amount of money */
      pricePerOrderUnit: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Unit in which the item is sold */
      orderUnit?: string
      /**
       * @min 0
       * @exclusiveMin true
       */
      quantity: number
      /**
       * Additional comment information for the item placed by shopper
       * @example "Preferred color - green"
       */
      additionalInformation?: string
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Article quantity for which price (pricePerOrderUnit) is defined
       * @example 0.001
       */
      priceQuantity?: string
    } & {
      eclass: {
        /** Version of eClass specification */
        version: ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderVersionEnum1
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
    }) & {
      /**
       * URL to the thumbnail of the item article
       * @format url
       * @example "https://simplesystem.com/images/1234.jpg"
       */
      thumbnailUrl?: string
      accountType?: {
        /**
         * Account type prefix
         * @example "K"
         */
        prefix: string
        /**
         * Account type description
         * @example "Project Name"
         */
        description: string
      }
      accountValue?: {
        /**
         * Account value number
         * @example "12213131"
         */
        number: string
        /**
         * Account value description (null for free-text values)
         * @example "Next Gen"
         */
        description?: string
      }
      generalLedger?: {
        /**
         * General Ledger number
         * @example "1231313"
         */
        number: string
        /**
         * General Ledger description
         * @example "Marketing"
         */
        description: string
      }
      /** true if the article comes from restricted catalog */
      closedMarketArticle?: boolean
      /**
       * Additional comment made to the selected account
       * @example "CEBIT / EXPO"
       */
      additionalAccountingInfo?: string
      /**
       * Shopper's additional texts on some order positions
       * @example "for Machine 4711"
       */
      itemComment?: string
    })[]
  }
  /** Delivery and payment data for the order */
  deliveryAndPaymentDetails?: {
    deliveryStatus: ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderDeliveryStatusEnum
    /**
     * Key value pairs where the key is order item id and the value is delivery status
     * @example {"11aa7d85-dd51-4191-8c26-6d446d48ef45":{"deliveryStatus":"IN_DELIVERY","deliveredQuantity":0,"totalQuantity":12},"ea674491-6699-46c0-9fd5-54720710e18f":{"deliveryStatus":"PARTLY_DELIVERED","deliveredQuantity":3,"totalQuantity":10}}
     */
    itemsDeliveryStatus: Record<
      string,
      {
        deliveryStatus: ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderDeliveryStatusEnum1
        /**
         * Delivery comments
         * @min 0
         */
        deliveredQuantity: number
        /**
         * Delivery comments
         * @min 1
         */
        totalQuantity: number
      }
    >
    paymentStatus: ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderPaymentStatusEnum
    /**
     * Key value pairs where the key is order item id and the value is payment status
     * @example {"11aa7d85-dd51-4191-8c26-6d446d48ef45":"PAID","ea674491-6699-46c0-9fd5-54720710e18f":"UNPAID"}
     */
    itemsPaymentStatus: Record<
      string,
      ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderItemsPaymentStatusEnum
    >
    /** Delivery comments */
    deliveryComments?: string
    /** Payment comments */
    paymentComments?: string
    /**
     * Timestamp when the order delivery and payment details were last edited. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    lastUpdatedAt?: string
    /** Customer user data to be presented along the orders */
    lastUpdatedBy?: {
      /**
       * Customer User ID
       * @format uuid
       * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
       */
      id: string
      /**
       * Name of the customer user
       * @example "John Doe"
       */
      name: string
      /**
       * Email of the customer user
       * @example "john.doe@example.com"
       */
      email: string
    }
  }
}

/** Error */
export type ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateOrderPaymentStatusStatusEnum = 'UNPAID' | 'PAID'

/** Update of order payment status */
export interface ModelUpdateOrderPaymentStatusPayload {
  status: ModelUpdateOrderPaymentStatusStatusEnum
}

export type ModelUpdateOrderPaymentStatusData = any

/** Error */
export type ModelUpdateOrderPaymentStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** OrderPaymentComment */
export interface ModelUpdateOrderPaymentCommentPayload {
  /** Order payment comment */
  comment: string
}

export type ModelUpdateOrderPaymentCommentData = any

/** Error */
export type ModelUpdateOrderPaymentCommentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateOrderItemPaymentStatusStatusEnum = 'UNPAID' | 'PAID'

/** Update of order item payment status */
export interface ModelUpdateOrderItemPaymentStatusPayload {
  status: ModelUpdateOrderItemPaymentStatusStatusEnum
}

export type ModelUpdateOrderItemPaymentStatusData = any

/** Error */
export type ModelUpdateOrderItemPaymentStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateOrderDeliveryStatusStatusEnum =
  | 'IN_DELIVERY'
  | 'DELIVERED'

/** Update of order delivery status */
export interface ModelUpdateOrderDeliveryStatusPayload {
  status: ModelUpdateOrderDeliveryStatusStatusEnum
}

export type ModelUpdateOrderDeliveryStatusData = any

/** Error */
export type ModelUpdateOrderDeliveryStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** OrderDeliveryComment */
export interface ModelUpdateOrderDeliveryCommentPayload {
  /** Order delivery comment */
  comment: string
}

export type ModelUpdateOrderDeliveryCommentData = any

/** Error */
export type ModelUpdateOrderDeliveryCommentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateItemDeliveryStatusStatusEnum =
  | 'IN_DELIVERY'
  | 'PARTLY_DELIVERED'
  | 'DELIVERED'

/** Update of order item delivery status */
export interface ModelUpdateItemDeliveryStatusPayload {
  status: ModelUpdateItemDeliveryStatusStatusEnum
  /**
   * @format int32
   * @min 0
   */
  quantity?: number
}

export type ModelUpdateItemDeliveryStatusData = any

/** Error */
export type ModelUpdateItemDeliveryStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerDeliveriesAndPaymentsVendorsParams {
  /** Defines order scope to search */
  kind?: ModelKindEnum2
}

/** Defines order scope to search */
export type ModelKindEnum2 = 'ALL_COMPANY_ORDERS' | 'MY_PERSONAL_ORDERS'

export type ModelGetCustomerDeliveriesAndPaymentsVendorsData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

/** Defines order scope to search */
export type ModelGetCustomerDeliveriesAndPaymentsVendorsParams1KindEnum =
  | 'ALL_COMPANY_ORDERS'
  | 'MY_PERSONAL_ORDERS'

export type ModelGetCustomerDeliveriesAndPaymentsCustomerUsersData = {
  /**
   * @format uuid
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  id: string
  /** @example "Hans Muller" */
  name: string
}[]

/** Search & filtering parameters for delivery address listing */
export interface ModelGetDeliveryAddressesPayload {
  /**
   * Search term
   * @example "Simple System"
   */
  term?: string
}

export interface ModelGetDeliveryAddressesParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

export interface ModelGetDeliveryAddressesData {
  /** List of delivery addresses with assignment info */
  deliveryAddresses: {
    /** @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b" */
    id: string
    editable: boolean
    deletable: boolean
    assignable: boolean
    /** Delivery address of an order */
    deliveryAddress?: {
      /**
       * Name of the company
       * @example "Company GmbH"
       */
      companyName: string
      /** @example "FAC991939931" */
      factoryIdentification?: string
      /** Basic information about an Address */
      address: {
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
      }
      /**
       * User-assigned label
       * @example "Second Office"
       */
      label?: string
    }
    /** Delivery address fields that can be customized by a specific customer user */
    customizations?: {
      /**
       * Alternate recipient name
       * @example "Jan Schmidt"
       */
      alternateRecipient?: string
      /**
       * Additional address information line 1
       * @example "6th floor"
       */
      additionalInformation?: string
      /**
       * Additional address information line 2
       * @example "desk 12"
       */
      additionalInformation2?: string
      /** @example "Track 001" */
      unloadingPoint?: string
    }
    sharedUserInfo?: {
      /**
       * Count of users assigned to the resource
       * @format int64
       * @example 10
       */
      assignedUsersCount: number
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned: boolean
      /**
       * True if all oci users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
      /**
       * True if user groups are assigned to the resource
       * @example true
       */
      userGroupsAssigned: boolean
    }
    /**
     * Delivery address creator data
     * @example {"name":"Joe Doe","customerUserId":"db7cf15b-2a1a-4f80-b2d9-a63d0be08773","currentUser":false}
     */
    createdBy?: {
      /**
       * Name of delivery address owner
       * @example "Joe Doe"
       */
      name: string
      /**
       * Customer user ID of the owner
       * @example "db7cf15b-2a1a-4f80-b2d9-a63d0be08773"
       */
      customerUserId: string
      /**
       * True if it is the current user
       * @example false
       */
      currentUser: boolean
    }
  }[]
  /**
   * Default delivery address Id
   * @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b"
   */
  defaultDeliveryAddressId?: string
  /** If true, user is allowed to create own delivery addresses */
  canCreatePrivateDeliveryAddresses: boolean
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Search & filtering parameters for delivery address listing */
export interface ModelSearchCustomerUserDeliveryAddressesPayload {
  /**
   * Search term
   * @example "Simple System"
   */
  term?: string
}

export interface ModelSearchCustomerUserDeliveryAddressesParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Customer user ID
   * @example "b2aa3d67-50d9-432e-8c44-869f1d23453f"
   */
  customerUserId: string
}

export interface ModelSearchCustomerUserDeliveryAddressesData {
  /** List of delivery addresses */
  deliveryAddresses: {
    /** @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b" */
    id: string
    editable: boolean
    deletable: boolean
    assignable: boolean
    /** Delivery address of an order */
    deliveryAddress?: {
      /**
       * Name of the company
       * @example "Company GmbH"
       */
      companyName: string
      /** @example "FAC991939931" */
      factoryIdentification?: string
      /** Basic information about an Address */
      address: {
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
      }
      /**
       * User-assigned label
       * @example "Second Office"
       */
      label?: string
    }
    /** Delivery address fields that can be customized by a specific customer user */
    customizations?: {
      /**
       * Alternate recipient name
       * @example "Jan Schmidt"
       */
      alternateRecipient?: string
      /**
       * Additional address information line 1
       * @example "6th floor"
       */
      additionalInformation?: string
      /**
       * Additional address information line 2
       * @example "desk 12"
       */
      additionalInformation2?: string
      /** @example "Track 001" */
      unloadingPoint?: string
    }
  }[]
  /**
   * Default delivery address Id
   * @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b"
   */
  defaultDeliveryAddressId?: string
  /** If true, user is allowed to create own delivery addresses */
  canCreatePrivateDeliveryAddresses: boolean
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

export interface ModelCreateDeliveryAddressPayload {
  /** Delivery address of an order */
  deliveryAddress?: {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string
    /** Basic information about an Address */
    address: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
    /**
     * User-assigned label
     * @example "Second Office"
     */
    label?: string
  }
  /** Delivery address fields that can be customized by a specific customer user */
  customizations?: {
    /**
     * Alternate recipient name
     * @example "Jan Schmidt"
     */
    alternateRecipient?: string
    /**
     * Additional address information line 1
     * @example "6th floor"
     */
    additionalInformation?: string
    /**
     * Additional address information line 2
     * @example "desk 12"
     */
    additionalInformation2?: string
    /** @example "Track 001" */
    unloadingPoint?: string
  }
  /** @default false */
  default?: boolean
}

/** Delivery address of an order */
export interface ModelCreateDeliveryAddressData {
  /** @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b" */
  id: string
}

export interface ModelUpdateCustomerDeliveryAddressPayload {
  /** Delivery address of an order */
  deliveryAddress?: {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string
    /** Basic information about an Address */
    address: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
    /**
     * User-assigned label
     * @example "Second Office"
     */
    label?: string
  }
  /** Delivery address fields that can be customized by a specific customer user */
  customizations?: {
    /**
     * Alternate recipient name
     * @example "Jan Schmidt"
     */
    alternateRecipient?: string
    /**
     * Additional address information line 1
     * @example "6th floor"
     */
    additionalInformation?: string
    /**
     * Additional address information line 2
     * @example "desk 12"
     */
    additionalInformation2?: string
    /** @example "Track 001" */
    unloadingPoint?: string
  }
  /** @default false */
  default?: boolean
}

export type ModelUpdateCustomerDeliveryAddressData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerDeliveryAddressError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerDeliveryAddressData = any

/** Error */
export type ModelDeleteCustomerDeliveryAddressError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetCustomerDeliveryAddressAssignmentsData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetCustomerDeliveryAddressAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelPutCustomerDeliveryAddressAssignmentPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelPutCustomerDeliveryAddressAssignmentData = any

/**
 * Error
 * Error
 */
export type ModelPutCustomerDeliveryAddressAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetCustomerDeliveryAddressPermissionsData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** List of users assigned to the article permission */
export type ModelSetCustomerDeliveryAddressPermissionsPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelSetCustomerDeliveryAddressPermissionsData = any

/** Error */
export type ModelSetCustomerDeliveryAddressPermissionsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelListVendingMachinesTypeEnum =
  | 'GARANT_TOOL24'
  | 'HOLEX_24SEVEN'
  | 'EXTERNAL_ORDER_SYSTEM'

export type ModelListVendingMachinesAssignmentTypeEnum =
  | 'MANUAL'
  | 'AUTOMATIC'
  | 'NONE'

export interface ModelListVendingMachinesParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** Response with list of vending machines */
export interface ModelListVendingMachinesData {
  /** List of vending machines */
  items: ({
    type: ModelListVendingMachinesTypeEnum
    /**
     * Vending machine name
     * @example "Verkaufsautomat"
     */
    name: string
    /**
     * Vending machine description
     * @example "Verkaufsautomat"
     */
    description?: string | null
    /**
     * E-mail address of contact person
     * @format email
     * @example "john.doe@example.com"
     */
    contactPerson: string
    /** Delivery address of an order */
    deliveryAddress: {
      /**
       * Name of the company
       * @example "Company GmbH"
       */
      companyName: string
      /** @example "FAC991939931" */
      factoryIdentification?: string | null
      /** Basic information about an Address */
      address: {
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
      }
      /** Delivery address fields that can be customized by a specific customer user */
      customizations?: {
        /**
         * Alternate recipient name
         * @example "Jan Schmidt"
         */
        alternateRecipient?: string
        /**
         * Additional address information line 1
         * @example "6th floor"
         */
        additionalInformation?: string
        /**
         * Additional address information line 2
         * @example "desk 12"
         */
        additionalInformation2?: string
        /** @example "Track 001" */
        unloadingPoint?: string
      } | null
    }
  } & {
    /**
     * Vending machine internal ID
     * @example "1a37b3d9-dc5d-40ef-b6f6-e4705cb12f31"
     */
    id: string
    /**
     * Vending machine external ID
     * @example "5ilfXYmLdto26JaaHEl8cR"
     */
    externalId: string
    account?: {
      type: {
        /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
        id: string
        /** @example "123434" */
        name: string
        description: string
        /** @example true */
        enabled: boolean
      }
      value: {
        /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
        id: string
        /** @example "123434" */
        name: string
        description: string
        /** @example true */
        enabled: boolean
      }
    } | null
    generalLedger: {
      assignmentType: ModelListVendingMachinesAssignmentTypeEnum
      manual?: {
        /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
        id: string
        /** General ledger name */
        name: string
        /** General ledger description */
        description: string
        /** @example true */
        enabled: boolean
      } | null
    }
    /**
     * Additional accounting information
     * @example "CEBIT / EXPO"
     */
    additionalAccountingInfo: string
    /** List of approval flows */
    approvalFlows: {
      /** @example "1a37b3d9-dc5d-40ef-b6f6-e4705cb12f31" */
      id: string
      /** Unique name of an approval flow */
      name: string
      /** @example true */
      enabled: boolean
    }[]
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelListVendingMachinesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelCreateVendingMachineTypeEnum =
  | 'GARANT_TOOL24'
  | 'HOLEX_24SEVEN'
  | 'EXTERNAL_ORDER_SYSTEM'

export type ModelCreateVendingMachineAssignmentTypeEnum =
  | 'MANUAL'
  | 'AUTOMATIC'
  | 'NONE'

export type ModelCreateVendingMachineTypeEnum1 =
  | 'GARANT_TOOL24'
  | 'HOLEX_24SEVEN'
  | 'EXTERNAL_ORDER_SYSTEM'

export type ModelCreateVendingMachinePayload = {
  type: ModelCreateVendingMachineTypeEnum1
  /**
   * Vending machine name
   * @example "Verkaufsautomat"
   */
  name: string
  /**
   * Vending machine description
   * @example "Verkaufsautomat"
   */
  description?: string | null
  /**
   * E-mail address of contact person
   * @format email
   * @example "john.doe@example.com"
   */
  contactPerson: string
  /** Delivery address of an order */
  deliveryAddress: {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string | null
    /** Basic information about an Address */
    address: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
    /** Delivery address fields that can be customized by a specific customer user */
    customizations?: {
      /**
       * Alternate recipient name
       * @example "Jan Schmidt"
       */
      alternateRecipient?: string
      /**
       * Additional address information line 1
       * @example "6th floor"
       */
      additionalInformation?: string
      /**
       * Additional address information line 2
       * @example "desk 12"
       */
      additionalInformation2?: string
      /** @example "Track 001" */
      unloadingPoint?: string
    } | null
  }
}

export type ModelCreateVendingMachineData = {
  type: ModelCreateVendingMachineTypeEnum
  /**
   * Vending machine name
   * @example "Verkaufsautomat"
   */
  name: string
  /**
   * Vending machine description
   * @example "Verkaufsautomat"
   */
  description?: string | null
  /**
   * E-mail address of contact person
   * @format email
   * @example "john.doe@example.com"
   */
  contactPerson: string
  /** Delivery address of an order */
  deliveryAddress: {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string | null
    /** Basic information about an Address */
    address: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
    /** Delivery address fields that can be customized by a specific customer user */
    customizations?: {
      /**
       * Alternate recipient name
       * @example "Jan Schmidt"
       */
      alternateRecipient?: string
      /**
       * Additional address information line 1
       * @example "6th floor"
       */
      additionalInformation?: string
      /**
       * Additional address information line 2
       * @example "desk 12"
       */
      additionalInformation2?: string
      /** @example "Track 001" */
      unloadingPoint?: string
    } | null
  }
} & {
  /**
   * Vending machine internal ID
   * @example "1a37b3d9-dc5d-40ef-b6f6-e4705cb12f31"
   */
  id: string
  /**
   * Vending machine external ID
   * @example "5ilfXYmLdto26JaaHEl8cR"
   */
  externalId: string
  account?: {
    type: {
      /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
      id: string
      /** @example "123434" */
      name: string
      description: string
      /** @example true */
      enabled: boolean
    }
    value: {
      /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
      id: string
      /** @example "123434" */
      name: string
      description: string
      /** @example true */
      enabled: boolean
    }
  } | null
  generalLedger: {
    assignmentType: ModelCreateVendingMachineAssignmentTypeEnum
    manual?: {
      /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
      id: string
      /** General ledger name */
      name: string
      /** General ledger description */
      description: string
      /** @example true */
      enabled: boolean
    } | null
  }
  /**
   * Additional accounting information
   * @example "CEBIT / EXPO"
   */
  additionalAccountingInfo: string
  /** List of approval flows */
  approvalFlows: {
    /** @example "1a37b3d9-dc5d-40ef-b6f6-e4705cb12f31" */
    id: string
    /** Unique name of an approval flow */
    name: string
    /** @example true */
    enabled: boolean
  }[]
}

/**
 * Error
 * Error
 * Error
 */
export type ModelCreateVendingMachineError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendingMachineTypeEnum =
  | 'GARANT_TOOL24'
  | 'HOLEX_24SEVEN'
  | 'EXTERNAL_ORDER_SYSTEM'

export type ModelGetVendingMachineAssignmentTypeEnum =
  | 'MANUAL'
  | 'AUTOMATIC'
  | 'NONE'

export type ModelGetVendingMachineData = {
  type: ModelGetVendingMachineTypeEnum
  /**
   * Vending machine name
   * @example "Verkaufsautomat"
   */
  name: string
  /**
   * Vending machine description
   * @example "Verkaufsautomat"
   */
  description?: string | null
  /**
   * E-mail address of contact person
   * @format email
   * @example "john.doe@example.com"
   */
  contactPerson: string
  /** Delivery address of an order */
  deliveryAddress: {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string | null
    /** Basic information about an Address */
    address: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
    /** Delivery address fields that can be customized by a specific customer user */
    customizations?: {
      /**
       * Alternate recipient name
       * @example "Jan Schmidt"
       */
      alternateRecipient?: string
      /**
       * Additional address information line 1
       * @example "6th floor"
       */
      additionalInformation?: string
      /**
       * Additional address information line 2
       * @example "desk 12"
       */
      additionalInformation2?: string
      /** @example "Track 001" */
      unloadingPoint?: string
    } | null
  }
} & {
  /**
   * Vending machine internal ID
   * @example "1a37b3d9-dc5d-40ef-b6f6-e4705cb12f31"
   */
  id: string
  /**
   * Vending machine external ID
   * @example "5ilfXYmLdto26JaaHEl8cR"
   */
  externalId: string
  account?: {
    type: {
      /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
      id: string
      /** @example "123434" */
      name: string
      description: string
      /** @example true */
      enabled: boolean
    }
    value: {
      /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
      id: string
      /** @example "123434" */
      name: string
      description: string
      /** @example true */
      enabled: boolean
    }
  } | null
  generalLedger: {
    assignmentType: ModelGetVendingMachineAssignmentTypeEnum
    manual?: {
      /** @example "435d3558-97ea-4ec1-a138-cb876ef466e1" */
      id: string
      /** General ledger name */
      name: string
      /** General ledger description */
      description: string
      /** @example true */
      enabled: boolean
    } | null
  }
  /**
   * Additional accounting information
   * @example "CEBIT / EXPO"
   */
  additionalAccountingInfo: string
  /** List of approval flows */
  approvalFlows: {
    /** @example "1a37b3d9-dc5d-40ef-b6f6-e4705cb12f31" */
    id: string
    /** Unique name of an approval flow */
    name: string
    /** @example true */
    enabled: boolean
  }[]
}

/** Error */
export type ModelGetVendingMachineError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateVendingMachineTypeEnum =
  | 'GARANT_TOOL24'
  | 'HOLEX_24SEVEN'
  | 'EXTERNAL_ORDER_SYSTEM'

export type ModelUpdateVendingMachinePayload = {
  type: ModelUpdateVendingMachineTypeEnum
  /**
   * Vending machine name
   * @example "Verkaufsautomat"
   */
  name: string
  /**
   * Vending machine description
   * @example "Verkaufsautomat"
   */
  description?: string | null
  /**
   * E-mail address of contact person
   * @format email
   * @example "john.doe@example.com"
   */
  contactPerson: string
  /** Delivery address of an order */
  deliveryAddress: {
    /**
     * Name of the company
     * @example "Company GmbH"
     */
    companyName: string
    /** @example "FAC991939931" */
    factoryIdentification?: string | null
    /** Basic information about an Address */
    address: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
    /** Delivery address fields that can be customized by a specific customer user */
    customizations?: {
      /**
       * Alternate recipient name
       * @example "Jan Schmidt"
       */
      alternateRecipient?: string
      /**
       * Additional address information line 1
       * @example "6th floor"
       */
      additionalInformation?: string
      /**
       * Additional address information line 2
       * @example "desk 12"
       */
      additionalInformation2?: string
      /** @example "Track 001" */
      unloadingPoint?: string
    } | null
  }
}

export type ModelUpdateVendingMachineData = any

/** Error */
export type ModelUpdateVendingMachineError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteVendingMachineData = any

/** Error */
export type ModelDeleteVendingMachineError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateVendingMachineAccountingAssignmentTypeEnum =
  | 'MANUAL'
  | 'AUTOMATIC'
  | 'NONE'

export interface ModelUpdateVendingMachineAccountingPayload {
  account: {
    /** @example "2f19e40e-9c7d-423e-a190-f9bf1b340362" */
    accountValueId?: string | null
    /** @example "123434" */
    additionalAccountingInfo: string
    generalLedger: {
      assignmentType?: ModelUpdateVendingMachineAccountingAssignmentTypeEnum
      /** @example "2f19e40e-9c7d-423e-a190-f9bf1b340362" */
      assignedGeneralLedgerId?: string | null
    }
  }
}

export type ModelUpdateVendingMachineAccountingData = any

/** Error */
export type ModelUpdateVendingMachineAccountingError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelUpdateVendingMachineApprovalFlowsPayload {
  /** List of approval flow Ids */
  approvalFlowIds?: string[]
}

export type ModelUpdateVendingMachineApprovalFlowsData = any

/** Error */
export type ModelUpdateVendingMachineApprovalFlowsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerVendorsTitleEnum = 'MS' | 'MR' | 'OTHER'

/** Status of Vendor-Customer relationship */
export type ModelGetCustomerVendorsRelationshipStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

export type ModelGetCustomerVendorsOnHoldReasonEnum =
  | 'PROCESSING_REQUEST'
  | 'CREATING_CATALOG'
  | 'CLARIFYING_SALES_CONDITIONS'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelGetCustomerVendorsRejectionReasonEnum =
  | 'WRONG_SUPPLIER'
  | 'LOW_VOLUME'
  | 'NO_NEW_CUSTOMERS'
  | 'LIMITED_TARGET_GROUP'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelGetCustomerVendorsBusinessCategoriesEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

export interface ModelGetCustomerVendorsParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /** Vendor business category */
  category?: ModelCategoryEnum
  /** the way how customers view vendors */
  viewOnVendors?: ModelViewOnVendorsEnum
}

/** Vendor business category */
export type ModelCategoryEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

/** the way how customers view vendors */
export type ModelViewOnVendorsEnum =
  | 'ALL_VENDORS'
  | 'MY_VENDORS'
  | 'OPEN_MARKETPLACE_VENDORS'
  | 'REQUESTED_VENDORS'

/** CustomerVendorList */
export interface ModelGetCustomerVendorsData {
  defaultVendorNumber?: string
  items: ({
    /** Common information of the company being registered as either Customer or Vendor */
    company: ({
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }) & {
      /**
       * ISO 4217 currency code
       * @pattern ^[A-Z]{3}$
       * @example "EUR"
       */
      currency: string
    }
    /** Person with additional contact info */
    contactPerson: {
      title: ModelGetCustomerVendorsTitleEnum
      firstName: string
      lastName: string
      phoneNumber: string
    } & {
      email: string
    }
    /** Status of Vendor-Customer relationship */
    relationshipStatus?: ModelGetCustomerVendorsRelationshipStatusEnum
  } & {
    vendorId: string
    customerSpecificVendorNumber?: string
    logoUrl?: string
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    statusLastChangedAt?: string
    onHoldReason?: ModelGetCustomerVendorsOnHoldReasonEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    onHoldUntil?: string
    rejectionReason?: ModelGetCustomerVendorsRejectionReasonEnum
    vendorsComment?: string
    customersComment?: string
    hasPlatformCatalogs?: boolean
    ociCatalogs?: string[]
    /** Vendor business categories */
    businessCategories?: ModelGetCustomerVendorsBusinessCategoriesEnum[]
    /** @format uri */
    websiteUrl?: string | null
    /** @format uri */
    termsAndConditionsUrl?: string | null
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelGetCustomerVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Vendor business category */
export type ModelGetCustomerVendorsParams1CategoryEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

/** the way how customers view vendors */
export type ModelGetCustomerVendorsParams1ViewOnVendorsEnum =
  | 'ALL_VENDORS'
  | 'MY_VENDORS'
  | 'OPEN_MARKETPLACE_VENDORS'
  | 'REQUESTED_VENDORS'

/** CustomerVendorRequest */
export interface ModelPostCustomerVendorsPayload {
  vendorId: string
  /** Optional comment to the vendor request */
  customerComment?: string
}

export type ModelPostCustomerVendorsData = any

/**
 * Error
 * Error
 */
export type ModelPostCustomerVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelPatchCustomerSpecificVendorNumberPayload {
  customerSpecificVendorNumber: string
}

export type ModelPatchCustomerSpecificVendorNumberData = any

/** Error */
export type ModelPatchCustomerSpecificVendorNumberError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Title of contact person */
export type ModelPostCustomerVendorInvitesTitleEnum = 'MS' | 'MR' | 'OTHER'

/** Send vendor request for not listed vendor */
export interface ModelPostCustomerVendorInvitesPayload {
  /**
   * VAT identifier
   * @example "DE123456789"
   */
  vatId: string
  /**
   * Company name
   * @example "ACME Inc."
   */
  name: string
  /** Title of contact person */
  title?: ModelPostCustomerVendorInvitesTitleEnum
  /**
   * First name of contact person
   * @example "John"
   */
  firstName: string
  /**
   * Last name of contact person
   * @example "Doe"
   */
  lastName: string
  /**
   * Company congtact person email address
   * @format email
   * @example "user@comapny.com"
   */
  email: string
  /** Optional comment to the vendor request */
  customerComment?: string
}

export type ModelPostCustomerVendorInvitesData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelPostCustomerVendorInvitesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Vendor counts */
export interface ModelCountCustomerVendorsData {
  /** @example 19 */
  allPublic: number
  /** @example 10 */
  accepted: number
  /** @example 1 */
  pending: number
  /** @example 1 */
  onHold: number
  /** @example 1 */
  rejected: number
  /** @example {"OCCUPATIONAL_SAFETY_PPE":5,"OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY":4,"TOOLS":10} */
  categories: Record<string, number>
}

/** Error */
export type ModelCountCustomerVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetAvailableCustomerVendorTypeEnum = 'OPEN' | 'CLOSED'

/** AvailableCustomerVendorDataList */
export interface ModelGetAvailableCustomerVendorData {
  vendors?: {
    /**
     * @format uuid
     * @example "97484465-8710-4322-b44b-26419c9948a6"
     */
    id: string
    /** @example "John" */
    name: string
    /**
     * URL to the vendor company logo
     * @format url
     * @example "https://simplesystem.com/images/logo_url.jpg"
     */
    logoUrl?: string
    type: ModelGetAvailableCustomerVendorTypeEnum
  }[]
}

/** CustomerUserVendorDataList */
export interface ModelGetCustomerUserVendorsData {
  vendors: {
    /**
     * @format uuid
     * @example "97484465-8710-4322-b44b-26419c9948a6"
     */
    id: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: ({
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }) & {
      /**
       * ISO 4217 currency code
       * @pattern ^[A-Z]{3}$
       * @example "EUR"
       */
      currency: string
    }
    /**
     * URL to the vendor company logo
     * @format url
     * @example "https://simplesystem.com/images/logo_url.jpg"
     */
    logoUrl: string | null
    hasPlatformCatalogs: boolean
    ociCatalogs: string[]
    /** @example false */
    pinned: boolean
  }[]
}

/** Error */
export type ModelGetCustomerUserVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelCreateCustomerUserVendorsPinData = any

/** Error */
export type ModelCreateCustomerUserVendorsPinError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerUserVendorsPinData = any

/** Error */
export type ModelDeleteCustomerUserVendorsPinError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetOciCustomerVendorsTitleEnum = 'MS' | 'MR' | 'OTHER'

/** Status of Vendor-Customer relationship */
export type ModelGetOciCustomerVendorsRelationshipStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

export type ModelGetOciCustomerVendorsOnHoldReasonEnum =
  | 'PROCESSING_REQUEST'
  | 'CREATING_CATALOG'
  | 'CLARIFYING_SALES_CONDITIONS'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelGetOciCustomerVendorsRejectionReasonEnum =
  | 'WRONG_SUPPLIER'
  | 'LOW_VOLUME'
  | 'NO_NEW_CUSTOMERS'
  | 'LIMITED_TARGET_GROUP'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelGetOciCustomerVendorsBusinessCategoriesEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

export interface ModelGetOciCustomerVendorsParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /** Vendor business category */
  category?: ModelCategoryEnum1
  /** the way how customers view vendors */
  viewOnVendors?: ModelViewOnVendorsEnum1
}

/** Vendor business category */
export type ModelCategoryEnum1 =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

/** the way how customers view vendors */
export type ModelViewOnVendorsEnum1 =
  | 'ALL_VENDORS'
  | 'MY_VENDORS'
  | 'OPEN_MARKETPLACE_VENDORS'
  | 'REQUESTED_VENDORS'

/** CustomerVendorList */
export interface ModelGetOciCustomerVendorsData {
  defaultVendorNumber?: string
  items: ({
    /** Common information of the company being registered as either Customer or Vendor */
    company: ({
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }) & {
      /**
       * ISO 4217 currency code
       * @pattern ^[A-Z]{3}$
       * @example "EUR"
       */
      currency: string
    }
    /** Person with additional contact info */
    contactPerson: {
      title: ModelGetOciCustomerVendorsTitleEnum
      firstName: string
      lastName: string
      phoneNumber: string
    } & {
      email: string
    }
    /** Status of Vendor-Customer relationship */
    relationshipStatus?: ModelGetOciCustomerVendorsRelationshipStatusEnum
  } & {
    vendorId: string
    customerSpecificVendorNumber?: string
    logoUrl?: string
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    statusLastChangedAt?: string
    onHoldReason?: ModelGetOciCustomerVendorsOnHoldReasonEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    onHoldUntil?: string
    rejectionReason?: ModelGetOciCustomerVendorsRejectionReasonEnum
    vendorsComment?: string
    customersComment?: string
    hasPlatformCatalogs?: boolean
    ociCatalogs?: string[]
    /** Vendor business categories */
    businessCategories?: ModelGetOciCustomerVendorsBusinessCategoriesEnum[]
    /** @format uri */
    websiteUrl?: string | null
    /** @format uri */
    termsAndConditionsUrl?: string | null
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Vendor business category */
export type ModelGetOciCustomerVendorsParams1CategoryEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

/** the way how customers view vendors */
export type ModelGetOciCustomerVendorsParams1ViewOnVendorsEnum =
  | 'ALL_VENDORS'
  | 'MY_VENDORS'
  | 'OPEN_MARKETPLACE_VENDORS'
  | 'REQUESTED_VENDORS'

/** CustomerUserVendorDataList */
export interface ModelGetOciCustomerUserVendorsData {
  vendors?: {
    /**
     * @format uuid
     * @example "97484465-8710-4322-b44b-26419c9948a6"
     */
    id: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: ({
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }) & {
      /**
       * ISO 4217 currency code
       * @pattern ^[A-Z]{3}$
       * @example "EUR"
       */
      currency: string
    }
    /**
     * URL to the vendor company logo
     * @format url
     * @example "https://simplesystem.com/images/logo_url.jpg"
     */
    logoUrl: string | null
    hasPlatformCatalogs: boolean
    ociCatalogs: string[]
    /** @example false */
    pinned: boolean
  }[]
}

export type ModelGetCustomerMarketplaceVendorsData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

/** Error */
export type ModelGetCustomerMarketplaceVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelArticleDetailVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelArticleDetailSourceEnum = 'CATALOG' | 'STOCK_AND_DELIVERY_INFO'

export type ModelArticleDetailValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelArticleDetailTypeEnum = 'CATALOG' | 'PRICE_LIST'

export type ModelArticleDetailTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelArticleDetailFileTypeEnum =
  | 'PDF'
  | 'TXT'
  | 'IMG'
  | 'URL'
  | 'OTHER'

/** Article with basic properties */
export type ModelArticleDetailData = {
  /**
   * Article ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  id: string
  /**
   * Article name
   * @example "Drilling machine"
   */
  name: string
  /**
   * Article number (Vendor’s unique article number)
   * @example "ABC123456"
   */
  number: string
  /**
   * EAN number
   * @example 12345678
   */
  ean: string
  eclass?: {
    /** Version of eClass specification */
    version: ModelArticleDetailVersionEnum
    /** eClass value formatted with dashes between groups ("12-34-56-78") */
    code?: string | null
    /**
     * eClass name
     * @example "Catering machinery"
     */
    name?: string | null
  }
  /**
   * Article number used by manufacturer
   * @example "ABC123"
   */
  manufacturerArticleNumber?: string
  /**
   * Manufacturer name
   * @example "ACME Inc."
   */
  manufacturer: string
  /**
   * Maximum delivery time in days declared by vendor
   * @deprecated
   * @min 0
   * @max 999999
   * @example 3
   */
  deliveryTime: number
  /** Delivery time details */
  deliveryInfo: {
    /**
     * Maximum delivery time in days declared by vendor
     * @min 0
     * @example 3
     */
    days: number
    source: ModelArticleDetailSourceEnum
    /**
     * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  /** Stock level info */
  stockInfo: {
    value: ModelArticleDetailValueEnum
    /**
     * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  /** @example 10 */
  minimumOrder?: number
  /** @example 5 */
  quantityInterval?: number
  /** @example "C62" */
  contentUnit?: string
  /** @example 25 */
  contentAmount?: string
  /**
   * @format double
   * @example 0.19
   */
  tax: number
  /** Pricing, order unit and constraints on quantity */
  orderOptions?: {
    /** Flag describing if this article is restricted from adding to the cart */
    restricted?: boolean
    /**
     * Article quantity for which price (pricePerOrderUnit) is defined
     * @example 0.001
     */
    priceQuantity: string
    /** Prices of the article */
    pricing: {
      /** @example 0.001 */
      quantity?: string
      /** Amount of money */
      price?: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Pricing source information, returned only for vendor preview */
      source?: {
        type: ModelArticleDetailTypeEnum
        priceListFilename?: string | null
      }
    }[]
  }
  coreArticle: boolean
  closedMarketplace: boolean
} & {
  /** Vendor information */
  vendor: {
    /**
     * Vendor ID
     * @example "V12345"
     */
    id: string
    /**
     * Name of the vendor
     * @example "Hoffmann Group"
     */
    name: string
  }
  /** Person with additional contact info */
  vendorContact: {
    title: ModelArticleDetailTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  } & {
    email: string
  }
  /** @example "PCE" */
  orderUnit: string
  /** @example "Long description to the article that may contain HTML" */
  description?: string
  /** @example "0101 – 0106" */
  hsCode?: string
  manufacturerArticleType?: string
  vendorRemarks?: string
  dependent?: boolean
  expired?: boolean
  /** Features of the article */
  features: {
    /**
     * Name of the feature
     * @example "Length"
     */
    name?: string
    /**
     * Values of the feature
     * @example ["5","8"]
     */
    values?: string[]
    unit?: string
    description?: string
    valueDetails?: string
  }[]
  /**
   * @format url
   * @example "https://cdn.shopify.com/s/files/1/0078/2555/5571/products/YousikOddieOfficeTableb.png?v=1639373568"
   */
  thumbnailUrl?: string
  images: {
    /**
     * URL pointing to a thumbnail of an image (72px x 72px)
     * @format url
     * @example "https://cataas.com/cat/says/thumbnail"
     */
    thumbnail: string
    /**
     * URL pointing to the file for display on page (360px x 360px)
     * @format url
     * @example "https://cataas.com/cat/says/display"
     */
    pageDisplay: string
  }[]
  additionalMedia: {
    /**
     * @format url
     * @example "https://cataas.com/cat/says/download"
     */
    url: string
    description: string
    fileType: ModelArticleDetailFileTypeEnum
  }[]
  /** @default false */
  isIncludedInArticleList: boolean
  customerArticleNumberInfo: {
    /**
     * Calculated customer article number value.
     * @example 12345
     */
    value: string
    /** @example true */
    isEdited: boolean
    /**
     * Customer article number value from file or catalog.
     * @example 67890
     */
    defaultValue: string
  }
}

/** Error */
export type ModelArticleDetailError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Platform categories */
export type ModelGetCustomerPlatformCategoriesData = {
  /**
   * Product Category ID
   * @format uuid
   * @example "2cb0a005-3aec-4e67-89ed-9bf89bece0a1"
   */
  id: string
  /**
   * Name in the current user language
   * @example "Tooling"
   */
  name: string
  /** If category contains some articles, currently always set to true */
  active: boolean
  /**
   * Image in base64 encoded
   * @example "data:image/png;base64,iVBORw0KGgoAAA"
   */
  thumbnail: string
}[]

export type ModelFetchExternalPricesTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** List of articleIds of articles that need external prices to be provided */
export type ModelFetchExternalPricesPayload = any[]

/** List of fetched external prices */
export type ModelFetchExternalPricesData = {
  /** @example "b4334fbf-6033-4cf9-985f-5aec2d0bedea" */
  articleId?: string
  /** Pricing, order unit and constraints on quantity */
  orderOptions?: {
    /** Flag describing if this article is restricted from adding to the cart */
    restricted?: boolean
    /**
     * Article quantity for which price (pricePerOrderUnit) is defined
     * @example 0.001
     */
    priceQuantity: string
    /** Prices of the article */
    pricing: {
      /** @example 0.001 */
      quantity?: string
      /** Amount of money */
      price?: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Pricing source information, returned only for vendor preview */
      source?: {
        type: ModelFetchExternalPricesTypeEnum
        priceListFilename?: string | null
      }
    }[]
  }
}[]

/** Error */
export type ModelFetchExternalPricesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerVendorOciCatalogsCategoriesEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

export type ModelGetCustomerVendorOciCatalogsTitleEnum = 'MS' | 'MR' | 'OTHER'

export interface ModelGetCustomerVendorOciCatalogsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** CustomerVendorOciCatalogsList */
export interface ModelGetCustomerVendorOciCatalogsData {
  items?: {
    /**
     * OCI catalog id
     * @example "OCI564734857"
     */
    id: string
    /**
     * OCI catalog name
     * @example "Production equipment"
     */
    name: string
    /** Vendor data for tile (with details) */
    vendor: {
      /**
       * Vendor id
       * @example "V12345"
       */
      id: string
      /**
       * URL to the vendor company logo
       * @format url
       * @example "https://simplesystem.com/images/logo_url.jpg"
       */
      logoUrl?: string
      /** Vendor business categories */
      categories: ModelGetCustomerVendorOciCatalogsCategoriesEnum[]
      /** Common information of the company being registered as either Customer or Vendor */
      company: {
        name: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
        companyRegistrationNumber?: string
        /** @example "München" */
        cityCourt?: string
        vatId: string
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
      } & {
        humanReadableId?: string
      }
      /** Person with additional contact info */
      contactPerson: {
        title: ModelGetCustomerVendorOciCatalogsTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      }
    }
  }[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelGetCustomerVendorOciCatalogsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** the method use to redirect user to vendor's OCI page */
export type ModelGetOciUrlWithSessionMethodEnum = 'POST' | 'GET'

/** Information needed for frontend to redirect user to vendor's oci website */
export interface ModelGetOciUrlWithSessionData {
  /**
   * the URL to vendor's OCI page, may contain all parameters including credential for GET method type
   * @format url
   * @example "https://www.test.com/oci"
   */
  url: string
  /** the method use to redirect user to vendor's OCI page */
  method: ModelGetOciUrlWithSessionMethodEnum
  /**
   * OCI connection parameters for POST method
   * @example {"USERNAME":"testUser","PASSWORD":"$up3rS!mp73","Lancode":"EN","SAPUser":"lerhvms87","HOOK_URL":"https://nextgen-dev.simplesystem.com/oci/customers/carts/bb0096ad-a42b-4984-9cd8-ea7c1e40b129"}
   */
  params?: Record<string, string>
}

/** Error */
export type ModelGetOciUrlWithSessionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with customer account types and values */
export interface ModelGetCustomerAccountTypesAndValuesData {
  /** List of account types */
  accountTypes: {
    /**
     * Account Type ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Account Type prefix
     * @example "KHY"
     */
    prefix: string
    /**
     * Account Type description
     * @example "New account type for product category1"
     */
    description: string
    /**
     * Account Type enabled flag
     * @example true
     */
    enabled: boolean
  }[]
  /**
   * Key value pairs where the key is account type and the value is account value
   * @example {"11aa7d85-dd51-4191-8c26-6d446d48ef45":[{"id":"619b1a5d-688c-48d4-b506-ec8f818ef5a0","number":123434,"description":"New account type value for product category1"},{"id":"8460897c-b05a-49cd-b8b3-39f6b4062119","number":32183,"description":"New account type value for product category2"}],"ea674491-6699-46c0-9fd5-54720710e18f":[{"id":"8b98780a-309a-40d6-a032-10dc5edc4995","number":559823,"description":"New account type value for product category3"}]}
   */
  accountValuesByType: Record<
    string,
    {
      /**
       * Account Type Value ID
       * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
       */
      id: string
      /**
       * Account Type Value number
       * @example "123434"
       */
      number: string
      /**
       * Account Type Value description
       * @example "New account type value for product category1"
       */
      description: string
      /**
       * Account Value enabled flag
       * @example true
       */
      enabled: boolean
    }[]
  >
}

/** Error */
export type ModelGetCustomerAccountTypesAndValuesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with customer user account types and values */
export interface ModelGetCustomerAssignedAccountTypesAndValuesData {
  /** List of assigned account types */
  accountTypes: {
    /**
     * Account Type ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Account Type prefix
     * @example "KHY"
     */
    prefix: string
    /**
     * Account Type description
     * @example "New account type for product category1"
     */
    description: string
  }[]
  /**
   * Key-value pairs with account type as key and the list of assigned account values as value. An empty list means a free-text value is available for the given account type.
   * @example {"11aa7d85-dd51-4191-8c26-6d446d48ef45":[{"id":"619b1a5d-688c-48d4-b506-ec8f818ef5a0","number":"123434","description":"New account type value for product category1"},{"id":"8460897c-b05a-49cd-b8b3-39f6b4062119","number":"32183","description":"New account type value for product category2"}],"ea674491-6699-46c0-9fd5-54720710e18f":[{"id":"8b98780a-309a-40d6-a032-10dc5edc4995","number":"559823","description":"New account type value for product category3"}]}
   */
  accountValuesByType: Record<
    string,
    {
      /**
       * Account Type Value ID
       * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
       */
      id: string
      /**
       * Account Type Value number
       * @example "123434"
       */
      number: string
      /**
       * Account Type Value description
       * @example "New account type value for product category1"
       */
      description: string
    }[]
  >
}

/** Error */
export type ModelGetCustomerAssignedAccountTypesAndValuesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetCustomerAccountTypesAndValuesListStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/** Response with customer account types and values automation */
export interface ModelGetCustomerAccountTypesAndValuesListData {
  accountTypesAndValuesFile?: {
    /** @example "antonio" */
    name: string
    /** @example "Customer_article_number_template.csv" */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetCustomerAccountTypesAndValuesListStatusEnum
    /**
     * Number of items successfully processed
     * @example 0
     */
    errors: number
    /**
     * Number of items which encountered warnings during import
     * @example 0
     */
    warnings: number
    successfullyProcessed: {
      /** @example 1 */
      accountingTypes: number
      /** @example 22 */
      accountingValues: number
    }
    /**
     * Full date and time formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
  } | null
  /** @example true */
  hasAnyProcessedFile: boolean
}

/** Error */
export type ModelGetCustomerAccountTypesAndValuesListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCustomerAccountTypesAndValuesFileDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetCustomerAccountTypesAndValuesFileDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerAccountTypeAndValuesFileSettingsData {
  transportSettings: {
    /** Host or IP of the FTP/SFTP server */
    hostname: string
    /**
     * Directory to which files will be uploaded
     * @example "/accounting"
     */
    directory: string
    /** @example "test" */
    username: string
    /** @example "super$3cret!" */
    password: string
    /**
     * @min 1
     * @max 65535
     * @example 21
     */
    ftpPort: number
    /**
     * @min 1
     * @max 65535
     * @example 22
     */
    sftpPort: number
  }
}

/** Error */
export type ModelGetCustomerAccountTypeAndValuesFileSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCustomerAccountTypesAndValuesReportDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetCustomerAccountTypesAndValuesReportDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerAccountTypesParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`prefix,asc`, `prefix,desc`]).
   * Default sorting: `prefix,asc`.
   */
  sort?: string[]
}

/** Response with list of customer account types */
export interface ModelGetCustomerAccountTypesData {
  /** List of account types */
  accountTypes?: ({
    /**
     * Account Type ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Account Type prefix
     * @example "KHY"
     */
    prefix: string
    /**
     * Account Type description
     * @example "New account type for product category1"
     */
    description: string
    /**
     * Account Type enabled flag
     * @example true
     */
    enabled: boolean
    /**
     * Name and surname of user who has added the account type
     * @example "Erika Mustermann"
     */
    createdBy?: string | null
    /**
     * Full date and time of account type added formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    createdAt: string
    usedByVendingMachines: string[]
    /**
     * Flag to indicate if Account Type Value is set as a user default
     * @example false
     */
    usedAsUserDefault: boolean
  } & {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  })[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelGetCustomerAccountTypesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The values needed to successfully create the customer account type */
export interface ModelPostCustomerAccountTypePayload {
  /**
   * Account Type prefix
   * @example "KHY"
   */
  prefix: string
  /**
   * Account Type description
   * @example "New account type for product category1"
   */
  description: string
  /**
   * Account Type enabled flag
   * @example true
   */
  enabled: boolean
}

export type ModelPostCustomerAccountTypeData = any

/**
 * Error
 * Error
 */
export type ModelPostCustomerAccountTypeError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The update values needed to successfully update the customer account type */
export interface ModelUpdateCustomerAccountTypePayload {
  /**
   * Account Type enabled flag
   * @example true
   */
  enabled?: boolean
  /**
   * Account Type prefix
   * @example "KHY"
   */
  prefix?: string
  /**
   * Account Type description
   * @example "New account type for product category1"
   */
  description?: string
}

export type ModelUpdateCustomerAccountTypeData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerAccountTypeError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerAccountTypeData = any

/** Response with list of customer account type values */
export interface ModelGetCustomerAccountTypeValuesData {
  /** List of account type values */
  accountTypeValues?: ({
    /**
     * Account Type Value ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Account Type Value number
     * @example "123434"
     */
    number: string
    /**
     * Account Type Value description
     * @example "New account type value for product category1"
     */
    description: string
    /**
     * Account Type Value enabled flag
     * @example true
     */
    enabled: boolean
    /**
     * Name and surname of user who has added the account type value
     * @example "Erika Mustermann"
     */
    createdBy?: string | null
    /**
     * Full date and time of account type value added according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    createdAt: string
    usedByVendingMachines: string[]
    /**
     * Flag to indicate if Account Type Value is set as a user default
     * @example false
     */
    usedAsUserDefault: boolean
  } & {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  })[]
}

/** Error */
export type ModelGetCustomerAccountTypeValuesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The values needed to successfully create the customer account type value */
export interface ModelPostCustomerAccountTypeValuePayload {
  /**
   * Account Type Value number
   * @example "123434"
   */
  number: string
  /**
   * Account Type Value description
   * @example "New account type value for product category1"
   */
  description: string
  /**
   * Account Type Value enabled flag
   * @example true
   */
  enabled: boolean
}

export type ModelPostCustomerAccountTypeValueData = any

/**
 * Error
 * Error
 */
export type ModelPostCustomerAccountTypeValueError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The update values needed to successfully update the customer account type value */
export interface ModelUpdateCustomerAccountTypeValuePayload {
  /**
   * Account Type Value enabled flag
   * @example true
   */
  enabled?: boolean
  /**
   * Account Type Value number
   * @example "123434"
   */
  number?: string
  /**
   * Account Type Value description
   * @example "New account type value for product category1"
   */
  description?: string
}

export type ModelUpdateCustomerAccountTypeValueData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerAccountTypeValueError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerAccountTypeValueData = any

/** The account type settings of the customer */
export interface ModelGetCustomerAccountTypeSettingsData {
  /**
   * True if the account types/values are mandatory for checkout
   * @example true
   */
  accountTypeAndValueMandatory: boolean
}

/** Error */
export type ModelGetCustomerAccountTypeSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The account type settings of the customer */
export interface ModelUpdateCustomerAccountTypeSettingsPayload {
  /**
   * True if the account types/values are mandatory for checkout
   * @example true
   */
  accountTypeAndValueMandatory: boolean
}

export type ModelUpdateCustomerAccountTypeSettingsData = any

/** Error */
export type ModelUpdateCustomerAccountTypeSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with list of all customer account types with account values counts */
export interface ModelGetAccountTypesListData {
  /** List of account types */
  accountTypes: {
    /**
     * Account Type ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Account Type prefix
     * @example "KHY"
     */
    prefix: string
    /**
     * Account Type description
     * @example "New account type for product category1"
     */
    description: string
    /**
     * Number of account values for given account type
     * @example 12
     */
    accountTypeValuesCount: number
  }[]
}

/** Error */
export type ModelGetAccountTypesListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with list of all customer account type values */
export interface ModelGetAccountTypeValuesListData {
  /** List of account types */
  accountTypeValues: {
    /**
     * Account Type Value ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Account Type Value number
     * @example "123434"
     */
    number: string
    /**
     * Account Type Value description
     * @example "New account type value for product category1"
     */
    description: string
  }[]
}

/** Error */
export type ModelGetAccountTypeValuesListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetAccountTypeAssignedCustomerUsersData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetAccountTypeAssignedCustomerUsersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelAssignCustomersToAccountTypePayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelAssignCustomersToAccountTypeData = any

/**
 * Error
 * Error
 */
export type ModelAssignCustomersToAccountTypeError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetAccountTypeValueAssignedCustomerUsersData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetAccountTypeValueAssignedCustomerUsersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelAssignCustomersToAccountTypeValuePayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelAssignCustomersToAccountTypeValueData = any

/**
 * Error
 * Error
 */
export type ModelAssignCustomersToAccountTypeValueError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerGeneralLedgersParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Whether to paginate results or not; defaults to true
   * @default true
   * @example false
   */
  paginated?: boolean
  /**
   * Sort field and direction (available: [`number,asc`, `number,desc`]).
   * Default sorting: `number,asc`.
   */
  sort?: string[]
}

/** Response with list of customer general ledgers */
export interface ModelGetCustomerGeneralLedgersData {
  /** List of general ledgers */
  generalLedgers: {
    /**
     * General Ledger ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Unique General Ledger number
     * @example "11081"
     */
    number: string
    /**
     * General ledger description
     * @example "Ledger name 01"
     */
    description: string
    /**
     * General Ledger enabled flag
     * @example true
     */
    enabled: boolean
    /**
     * Full date and time of general ledger added formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    createdAt: string
    /**
     * Count of users assigned to the general ledger
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the general ledger
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if user groups are assigned to the general ledger
     * @example true
     */
    userGroupsAssigned?: boolean
    usedByVendingMachines: string[]
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelGetCustomerGeneralLedgersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The values needed to successfully create the customer general ledger */
export interface ModelAddCustomerGeneralLedgerPayload {
  /**
   * General Ledger number
   * @example "11081"
   */
  number: string
  /**
   * General Ledger description
   * @example "Ledger name 01"
   */
  description: string
  /**
   * General Ledger enabled flag
   * @example true
   */
  enabled: boolean
}

export type ModelAddCustomerGeneralLedgerData = any

/**
 * Error
 * Error
 */
export type ModelAddCustomerGeneralLedgerError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The update values needed to successfully update the customer general ledger */
export interface ModelUpdateCustomerGeneralLedgerPayload {
  /**
   * General Ledger enabled flag
   * @example true
   */
  enabled?: boolean
  /**
   * General Ledger number
   * @example "11081"
   */
  number?: string
  /**
   * General Ledger description
   * @example "Ledger name 01"
   */
  description?: string
}

export type ModelUpdateCustomerGeneralLedgerData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerGeneralLedgerError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerGeneralLedgerData = any

/** The general ledger settings of the customer */
export interface ModelGetCustomerGeneralLedgerSettingsData {
  /**
   * True if the general ledger are mandatory for checkout
   * @example true
   */
  generalLedgerMandatory: boolean
}

/** Error */
export type ModelGetCustomerGeneralLedgerSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The general ledger settings of the customer */
export interface ModelUpdateCustomerGeneralLedgerSettingsPayload {
  /**
   * True if the general ledger are mandatory for checkout
   * @example true
   */
  generalLedgerMandatory: boolean
}

export type ModelUpdateCustomerGeneralLedgerSettingsData = any

/** Error */
export type ModelUpdateCustomerGeneralLedgerSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * Response with mapping of eClass nodes to general ledgers
 * @example {"09-87-65-43":"8cf88411-0ee3-466c-9d83-5db76b21a205","12-34-56-78":"fc575070-35cf-4891-93c3-e12cb680dfdd","34-00-00-00":"48477c3f-4ef5-4871-974b-19bb0274d3f9","34-20-00-00":null,"34-20-07-00":"60ffda33-c18c-4548-a968-d8f14c1cd6ff"}
 */
export interface ModelGetGeneralLedgerEclassMappingsData {
  /** Map of mappings with eClass node as a key and general ledger Id as a value. Mapping to `null` clears mapping defined by parents */
  mappings: Record<string, string>
}

/** Error */
export type ModelGetGeneralLedgerEclassMappingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetGeneralLedgerEclassMappingsParamsEclassVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Version of eClass specification */
export type ModelGetGeneralLedgerEclassMappingsParamsEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Update request for single eClass node mapping */
export interface ModelPutGeneralLedgerEclassMappingPayload {
  /**
   * Id of assigned general ledger - if `null` then this node is clearing assignment defined by its parents
   * @example "32b50780-a82d-4c2e-991d-c2fffa892e14"
   */
  generalLedgerId: string
  /**
   * If `true` all mappings of children eClasses will be changed to given `generalLedgerId`
   * @default false
   */
  overrideChildren?: boolean
}

export type ModelPutGeneralLedgerEclassMappingData = any

/** Error */
export type ModelPutGeneralLedgerEclassMappingError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelPutGeneralLedgerEclassMappingParamsEclassVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Version of eClass specification */
export type ModelPutGeneralLedgerEclassMappingParamsEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Response with list of assigned general ledgers */
export interface ModelGetAssignedCustomerGeneralLedgerData {
  /** List of assigned general ledgers */
  generalLedgers: {
    /**
     * General Ledger ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Unique General Ledger number
     * @example "11081"
     */
    number: string
    /**
     * General ledger description
     * @example "Ledger name 01"
     */
    description: string
  }[]
}

/** Error */
export type ModelGetAssignedCustomerGeneralLedgerError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetAssignedCustomerUsersToGeneralLedgerData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetAssignedCustomerUsersToGeneralLedgerError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelAssignCustomersToGeneralLedgerPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelAssignCustomersToGeneralLedgerData = any

/**
 * Error
 * Error
 */
export type ModelAssignCustomersToGeneralLedgerError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Customer user privileges */
export type ModelSearchCustomerUserPrivilegesReturnPrivilegesEnum =
  | 'VIEW_ALL_ORDERS'
  | 'EDIT_CORE_ARTICLE_MARKERS'
  | 'EDIT_CUSTOMER_ARTICLE_NUMBERS'
  | 'EDIT_DELIVERIES'
  | 'EDIT_EXTERNAL_REFERENCES'
  | 'EDIT_PAYMENTS'
  | 'CREATE_DELIVERY_ADDRESSES'

/** Customer user privileges search criteria */
export interface ModelSearchCustomerUserPrivilegesPayload {
  term?: string
  groupIds: string[]
  returnPrivileges: ModelSearchCustomerUserPrivilegesReturnPrivilegesEnum[]
}

export interface ModelSearchCustomerUserPrivilegesParams {
  /**
   * Sort field and direction (available: [`name,desc`, `name,asc`]).
   * Default sorting: `name,asc`.
   */
  sort?: string[]
}

/** List of found customer user privileges */
export interface ModelSearchCustomerUserPrivilegesData {
  items?: {
    /**
     * Customer User ID
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    customerUserId: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "john.doe@example.com"
     */
    email: string
    groupIds: string[]
    /** Customer user privileges state */
    privileges: {
      VIEW_ALL_ORDERS?: boolean
      EDIT_CORE_ARTICLE_MARKERS?: boolean
      EDIT_CUSTOMER_ARTICLE_NUMBERS?: boolean
      EDIT_DELIVERIES?: boolean
      EDIT_EXTERNAL_REFERENCES?: boolean
      EDIT_PAYMENTS?: boolean
      CREATE_DELIVERY_ADDRESSES?: boolean
    }
  }[]
}

/** User groups that shoppers are assigned to */
export type ModelGetUserGroupsForCustomerData = {
  id: string
  name: string
  description: string
}[]

/** List of customer users to change privileges */
export interface ModelUpdateCustomerUserPrivilegesPayload {
  items: {
    /** @format uuid */
    customerUserId: string
    /** Customer user privileges state */
    privileges: {
      VIEW_ALL_ORDERS?: boolean
      EDIT_CORE_ARTICLE_MARKERS?: boolean
      EDIT_CUSTOMER_ARTICLE_NUMBERS?: boolean
      EDIT_DELIVERIES?: boolean
      EDIT_EXTERNAL_REFERENCES?: boolean
      EDIT_PAYMENTS?: boolean
      CREATE_DELIVERY_ADDRESSES?: boolean
    }
  }[]
}

export type ModelUpdateCustomerUserPrivilegesData = any

/** Error */
export type ModelUpdateCustomerUserPrivilegesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method to use for upload */
export type ModelGetCustomerDataFileUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetCustomerDataFileUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum5
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum5 = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetCustomerDataFileUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetCustomerDataFileUploadUrlMethodEnum
}

/** Error */
export type ModelGetCustomerDataFileUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetCustomerDataFileUploadUrlParams1MethodEnum = 'PUT' | 'POST'

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetCoreArticleListsStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

export interface ModelGetCoreArticleListsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** customer's core article lists grouped by assigned and unassigned */
export interface ModelGetCoreArticleListsData {
  /** List of core article lists */
  coreArticleLists: {
    /**
     * Core article list ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the core article list
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the core article list file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetCoreArticleListsStatusEnum
    /**
     * Vendor ID
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    vendorId: string
    /**
     * Name of the vendor
     * @example "Shire GmbH"
     */
    vendorName: string
    /**
     * VAT ID of vendor company
     * @example "DE123456789"
     */
    vatId: string
    /**
     * Customer specific vendor number
     * @example "ACM"
     */
    customerSpecificVendorNumber?: string
    /**
     * Number of core articles which encoutered errors during import
     * @example 2
     */
    errors: number
    /**
     * Number of core articles which encoutered warnings during import
     * @example 4
     */
    warnings: number
    /**
     * Number of core articles which were successfully imported
     * @example 21
     */
    successfullyProcessed: number
    /**
     * Full date and time of core article list upload formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /**
     * Name and surname of user who has uploaded the CoreArticleList
     * @example "Erika Mustermann"
     */
    uploadedBy: string
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Definition of uploaded core article list file to be processed */
export interface ModelPostCoreArticleListPayload {
  /**
   * Vendor ID
   * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
   */
  vendorId: string
  /**
   * Name of the core article list to be created for this file
   * @example "Small customers"
   */
  name: string
  /**
   * Original file name of the uploaded file
   * @example "CoreArticleListForSmallCustomers.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/CoreArticleListForSmallCustomers.csv"
   */
  path: string
}

export type ModelPostCoreArticleListData = any

/**
 * Error
 * Error
 */
export type ModelPostCoreArticleListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Definition of uploaded core article list file to be processed to update existing core article list */
export interface ModelUpdateCoreArticleListFilePayload {
  /**
   * Original file name of the uploaded file
   * @example "CoreArticleListForSmallCustomersUpdated.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/CoreArticleListForSmallCustomersUpdated.csv"
   */
  path: string
}

export type ModelUpdateCoreArticleListFileData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCoreArticleListFileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request for changing core article list name and file name */
export interface ModelUpdateCoreArticleListPayload {
  /**
   * The new name to be set for this core article list
   * @minLength 1
   * @example "Medium customers"
   */
  name: string
  /**
   * The new file name to be set for this core article list
   * @minLength 1
   * @example "medium_customers.csv"
   */
  fileName: string
}

export type ModelUpdateCoreArticleListData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateCoreArticleListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCoreArticleListData = any

/**
 * Error
 * Error
 */
export type ModelDeleteCoreArticleListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCoreArticleListImportReportData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCoreArticleListDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetCoreArticleListDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Search parameters for core article list vendors listing */
export interface ModelSearchCustomerCoreArticleListMyVendorsPayload {
  /**
   * Search term
   * @example "Zotware"
   */
  term?: string
}

export interface ModelSearchCustomerCoreArticleListMyVendorsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** My vendors list for customer */
export interface ModelSearchCustomerCoreArticleListMyVendorsData {
  items: {
    /**
     * Vendor ID
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    vendorId: string
    /**
     * Vendor company name
     * @example "ACME Ltd."
     */
    name: string
    /**
     * VAT ID of vendor company
     * @example "DE123456789"
     */
    vatId: string
    /**
     * Customer specific vendor number
     * @example "ACM"
     */
    customerSpecificVendorNumber: string
    alreadyAssigned: boolean
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  defaultVendorNumber?: string
}

/** Error */
export type ModelSearchCustomerCoreArticleListMyVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * Current state of the file being processed
 * @example "PROCESSED"
 */
export type ModelGetCustomerArticleNumbersListStatusEnum =
  | 'PENDING'
  | 'PROCESSING'
  | 'PROCESSED'
  | 'FAILED'

/** customer article numbers list */
export interface ModelGetCustomerArticleNumbersListData {
  /** The customer article numbers list data */
  customerArticleNumberList: {
    /**
     * Name of the customer article numbers list
     * @example "Small customers"
     */
    name: string
    /**
     * Name of the customer article numbers list file
     * @example "small_customers.csv"
     */
    fileName: string
    /**
     * Current state of the file being processed
     * @example "PROCESSED"
     */
    status: ModelGetCustomerArticleNumbersListStatusEnum
    /**
     * Number of customer article numbers which encoutered errors during import
     * @example 2
     */
    errors: number
    /**
     * Number of customer article numbers which encoutered warnings during import
     * @example 4
     */
    warnings: number
    /**
     * Number of customer article numbers which were successfully imported
     * @example 21
     */
    successfullyProcessed: number
    /**
     * Full date and time of customer article numbers list upload formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    uploadedAt: string
    /**
     * Name and surname of user who has uploaded the CustomerArticleNumbersList
     * @example "Erika Mustermann"
     */
    uploadedBy: string
  }
}

/** Definition of uploaded customer article numbers list file to be processed */
export interface ModelPostCustomerArticleNumbersListPayload {
  /**
   * Name of the customer article numbers list to be created for this file
   * @example "Small customers"
   */
  name: string
  /**
   * Original file name of the uploaded file
   * @example "CustomerArticleNumbersListForSmallCustomers.csv"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/CustomerArticleNumbersListForSmallCustomers.csv"
   */
  path: string
}

export type ModelPostCustomerArticleNumbersListData = any

/** Error */
export type ModelPostCustomerArticleNumbersListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request for changing customer article numbers list name and file name */
export interface ModelUpdateCustomerArticleNumbersListPayload {
  /**
   * The new name to be set for this customer article numbers list
   * @minLength 1
   * @example "Medium customers"
   */
  name: string
  /**
   * The new file name to be set for this customer article numbers list
   * @minLength 1
   * @example "medium_customers.csv"
   */
  fileName: string
}

export type ModelUpdateCustomerArticleNumbersListData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerArticleNumbersListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerArticleNumbersListData = any

/**
 * Error
 * Error
 */
export type ModelDeleteCustomerArticleNumbersListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCustomerArticleNumbersListImportReportData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetCustomerArticleNumbersListDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

/** Error */
export type ModelGetCustomerArticleNumbersListDownloadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerReportsData {
  /** List of available reports */
  reports: {
    /**
     * Report ID
     * @example "774fa69a-c260-40a1-bdd9-8eea0f5bcfd2"
     */
    id: string
    /**
     * Report translation key used to get language specific report name
     * @example "bi_power_bi_ext"
     */
    nameTranslationKey: string
  }[]
}

/** Data needed to embed report in FE */
export interface ModelGetCustomerReportViewDataData {
  /** Report data */
  reportConfig: {
    /**
     * Report ID
     * @example "4f110669-d1d8-4907-b675-476684a2ba62"
     */
    id: string
    /**
     * Url to embed report
     * @example "https://app.powerbi.com/reportEmbed?reportId=48a36565-34b3-41b2-9715-1dd2f0836fa2"
     */
    embedUrl: string
    /**
     * ID of the dataset
     * @example "0bfc3bba-79ad-494e-9297-00fdb8e47907"
     */
    datasetId: string
  }
  /** Access token data */
  token: {
    /** @example "token_data" */
    token?: string
    /** @example "aa_token" */
    tokenId?: string
    /**
     * @format date-time
     * @example "2023-10-25T11:10:57.849+00:00"
     */
    expiration?: string
  }
  /**
   * page name of the report
   * @example "ReportSection0c5dee2344585e8d40a423"
   */
  pageName?: string
}

export interface ModelGetVendorReportsData {
  /** List of available reports */
  reports: {
    /**
     * Report ID
     * @example "774fa69a-c260-40a1-bdd9-8eea0f5bcfd2"
     */
    id: string
    /**
     * Report translation key used to get language specific report name
     * @example "bi_power_bi_ext"
     */
    nameTranslationKey: string
  }[]
}

/** Data needed to embed report in FE */
export interface ModelGetVendorReportViewDataData {
  /** Report data */
  reportConfig: {
    /**
     * Report ID
     * @example "4f110669-d1d8-4907-b675-476684a2ba62"
     */
    id: string
    /**
     * Url to embed report
     * @example "https://app.powerbi.com/reportEmbed?reportId=48a36565-34b3-41b2-9715-1dd2f0836fa2"
     */
    embedUrl: string
    /**
     * ID of the dataset
     * @example "0bfc3bba-79ad-494e-9297-00fdb8e47907"
     */
    datasetId: string
  }
  /** Access token data */
  token: {
    /** @example "token_data" */
    token?: string
    /** @example "aa_token" */
    tokenId?: string
    /**
     * @format date-time
     * @example "2023-10-25T11:10:57.849+00:00"
     */
    expiration?: string
  }
  /**
   * page name of the report
   * @example "ReportSection0c5dee2344585e8d40a423"
   */
  pageName?: string
}

export type ModelPostVendorCompanyTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelPostVendorCompanyCategoriesEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

/** Data for Vendor's company registration */
export interface ModelPostVendorCompanyPayload {
  /** Login request body */
  user: {
    /**
     * The login identifier of the user. The login identifier can be either the email or the username.
     * @example "Customer2@local.localhost"
     */
    loginId: string
    /**
     * User password.
     * @format password
     * @minLength 1
     * @maxLength 256
     * @example "secret!23"
     */
    password: string
  }
  /** Basic information of the person registering the Customer or Vendor */
  person: {
    title: ModelPostVendorCompanyTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  }
  /** Common information of the company being registered as either Customer or Vendor */
  company: {
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    /** @default true */
    publicVendor?: boolean
    /** @default false */
    migrated?: boolean
    /**
     * @maxItems 5
     * @minItems 1
     * @uniqueItems true
     */
    categories: ModelPostVendorCompanyCategoriesEnum[]
    /** DAC7 data required for new Vendor's company registration */
    dac7: {
      isStateEntityOrPubliclyTraded: boolean
    }
  }
  consents: {
    /** @default false */
    newsletter: boolean
  }
  acceptedProvisioningContract: {
    /** @format uuid */
    versionId: string
  }
  acceptedDataPrivacyPolicy: {
    /** @format uuid */
    versionId: string
  }
}

export interface ModelPostVendorCompanyData {
  /** @example "4906cd7b-2810-4671-963b-81be91d2ad25" */
  userId: string
}

/** Error */
export type ModelPostVendorCompanyError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorCustomersTitleEnum = 'MS' | 'MR' | 'OTHER'

/** Status of Vendor-Customer relationship */
export type ModelGetVendorCustomersRelationshipStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

export type ModelGetVendorCustomersOnHoldReasonEnum =
  | 'PROCESSING_REQUEST'
  | 'CREATING_CATALOG'
  | 'CLARIFYING_SALES_CONDITIONS'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelGetVendorCustomersRejectionReasonEnum =
  | 'WRONG_SUPPLIER'
  | 'LOW_VOLUME'
  | 'NO_NEW_CUSTOMERS'
  | 'LIMITED_TARGET_GROUP'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

/** Type of the catalog */
export type ModelGetVendorCustomersTypeEnum = 'PLATFORM' | 'OCI'

/** Type of the cart info */
export type ModelGetVendorCustomersTypeEnum1 =
  | 'DEFAULT_OPEN_MP'
  | 'DEFAULT_PARTNER_MP'
  | 'PERSONALISED_PARTNER'

export interface ModelGetVendorCustomersParams {
  /** Status of the vendor request */
  status?: ModelStatusEnum[]
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** Status of Vendor-Customer relationship */
export type ModelStatusEnum = 'PENDING' | 'ACCEPTED' | 'ON_HOLD' | 'REJECTED'

/** VendorCustomerList */
export interface ModelGetVendorCustomersData {
  items?: ({
    /** Common information of the company being registered as either Customer or Vendor */
    company: ({
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }) & {
      /**
       * ISO 4217 currency code
       * @pattern ^[A-Z]{3}$
       * @example "EUR"
       */
      currency: string
    }
    /** Person with additional contact info */
    contactPerson: {
      title: ModelGetVendorCustomersTitleEnum
      firstName: string
      lastName: string
      phoneNumber: string
    } & {
      email: string
    }
    /** Status of Vendor-Customer relationship */
    relationshipStatus?: ModelGetVendorCustomersRelationshipStatusEnum
  } & {
    customerId: string
    vendorSpecificCustomerNumber?: string
    onHoldReason?: ModelGetVendorCustomersOnHoldReasonEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    onHoldUntil?: string
    rejectionReason?: ModelGetVendorCustomersRejectionReasonEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    statusLastChangedAt?: string
    vendorsComment?: string
    customersComment?: string
    /** List of catalogs (Platform and OCI catalogs) that are assigned to this customer */
    assignedCatalogs: {
      /**
       * Catalog name
       * @example "Winter collection"
       */
      name: string
      /**
       * Name of the source file of the catalog
       * @example "Winter_collection.zip"
       */
      fileName?: string
      /** ID and type of the catalog */
      genericCatalogId: {
        /**
         * Id of the catalog
         * @example "e02f4c97-98e0-49e8-bd0d-5ed0a2d8ab68"
         */
        id: string
        /** Type of the catalog */
        type: ModelGetVendorCustomersTypeEnum
      }
    }[]
    /** List of price lists that are assigned to this customer */
    assignedPriceLists: {
      /**
       * Price list name
       * @example "Black friday 2023"
       */
      name: string
      /**
       * Name of the source file of the price list
       * @example "BlackFriday2023.zip"
       */
      fileName: string
      /**
       * Id of the price list
       * @example "6d14b341-25f4-45ba-ac5b-b912f28a6a95"
       */
      id: string
    }[]
    /** List of customer article number lists that are assigned to this customer */
    assignedCustomerArticleNumberLists: {
      /** Customer article numbers list name */
      name: string
      /** Name of the source file of the customer article number list */
      fileName: string
      /**
       * Id of the customer article number list
       * @example "a24be6ef-6889-4212-936f-357356245276"
       */
      id: string
    }[]
    assignedStockAndDeliveryList?: {
      /** @example "a24be6ef-6889-4212-936f-357356245276" */
      id: string
      name: string
      fileName: string
    } | null
    assignedCartInfo?: {
      /** @example "General tax message" */
      name: string
      /** Type of the cart info */
      type: ModelGetVendorCustomersTypeEnum1
      /** @example "A W9 from vendors is not required when payments..." */
      message: string
    }
  })[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelGetVendorCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Status of Vendor-Customer relationship */
export type ModelGetVendorCustomersParams1StatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

export interface ModelCountVendorCustomersParams {
  /** Search term */
  term?: string
}

/** Search hit counts for each status */
export interface ModelCountVendorCustomersData {
  /** @example 19 */
  accepted: number
  /** @example 3 */
  pending: number
  /** @example 1 */
  onHold: number
  /** @example 2 */
  rejected: number
  /**
   * Search term
   * @example "GmbH"
   */
  term: string
}

/** Error */
export type ModelCountVendorCustomersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Type of the catalog */
export type ModelPatchVendorCustomerTypeEnum = 'PLATFORM' | 'OCI'

export type ModelPatchVendorCustomerReasonEnum =
  | 'WRONG_SUPPLIER'
  | 'LOW_VOLUME'
  | 'NO_NEW_CUSTOMERS'
  | 'LIMITED_TARGET_GROUP'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelPatchVendorCustomerReasonEnum1 =
  | 'PROCESSING_REQUEST'
  | 'CREATING_CATALOG'
  | 'CLARIFYING_SALES_CONDITIONS'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

/** @example {"accept":{"genericCatalogIds":[{"id":"e02f4c97-98e0-49e8-bd0d-5ed0a2d8ab68","type":"PLATFORM"}],"priceListIds":["a1004216-1dcf-402c-84af-cd5033cd071a"]},"vendorSpecificCustomerNumber":"007"} */
export type ModelPatchVendorCustomerPayload =
  | {
      accept?: {
        /** List of IDs of catalogs (with type) to be assigned to the customer */
        genericCatalogIds?: {
          /**
           * Id of the catalog
           * @example "e02f4c97-98e0-49e8-bd0d-5ed0a2d8ab68"
           */
          id: string
          /** Type of the catalog */
          type: ModelPatchVendorCustomerTypeEnum
        }[]
        /** List of IDs of price lists to be assigned to the customer */
        priceListIds?: string[]
      }
      reject?: {
        reason: ModelPatchVendorCustomerReasonEnum
        comment?: string
      }
      putOnHold?: {
        /**
         * @format date-time
         * @example "2021-11-25T11:10:57.849+00:00"
         */
        until: string
        reason: ModelPatchVendorCustomerReasonEnum1
        comment?: string
      }
    }
  | {
      vendorSpecificCustomerNumber?: string | null
    }

export type ModelPatchVendorCustomerData = any

/**
 * Error
 * Error
 */
export type ModelPatchVendorCustomerError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Type of the catalog */
export type ModelGetVendorCustomerAssignableCatalogsTypeEnum =
  | 'PLATFORM'
  | 'OCI'

export interface ModelGetVendorCustomerAssignableCatalogsParams {
  /** Search term */
  term?: string
  /**
   * Customer ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  customerId: string
}

/** Search assignable catalogs (platform & OCI) of vendor response */
export interface ModelGetVendorCustomerAssignableCatalogsData {
  /** List of assignable catalogs (platform & OCI) of vendor */
  items: {
    /**
     * Catalog name
     * @example "Winter collection"
     */
    name: string
    /**
     * Name of the source file of the catalog
     * @example "Winter_collection.zip"
     */
    fileName?: string
    /** ID and type of the catalog */
    genericCatalogId: {
      /**
       * Id of the catalog
       * @example "e02f4c97-98e0-49e8-bd0d-5ed0a2d8ab68"
       */
      id: string
      /** Type of the catalog */
      type: ModelGetVendorCustomerAssignableCatalogsTypeEnum
    }
  }[]
}

export interface ModelGetVendorCustomerAssignablePriceListsParams {
  /** Search term */
  term?: string
  /**
   * Customer ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  customerId: string
}

/** Search assignable price lists of vendor response */
export interface ModelGetVendorCustomerAssignablePriceListsData {
  /** List of assignable assignable price lists of vendor */
  items: {
    /**
     * Price list name
     * @example "Black friday 2023"
     */
    name: string
    /**
     * Name of the source file of the price list
     * @example "BlackFriday2023.zip"
     */
    fileName: string
    /**
     * Id of the price list
     * @example "6d14b341-25f4-45ba-ac5b-b912f28a6a95"
     */
    id: string
  }[]
}

/** Type of the order */
export type ModelSearchVendorOrdersOrderTypeEnum =
  | 'ERP_ORDER'
  | 'PLATFORM_ORDER'

/** Search & filtering parameters for order listing */
export interface ModelSearchVendorOrdersPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
  /**
   * Chosen customers scope for the current search
   * @example ["d7bf9569-85ab-4315-b86a-365935ed4c04","873e99b2-0370-4084-9653-cd6086c725a8"]
   */
  customerIds?: string[]
  /**
   * First day of the date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-02"
   */
  startDate?: string
  /**
   * Last day of the date range to be filtered-out (inclusive)
   * @format date
   * @example "2022-12-09"
   */
  endDate?: string
}

export interface ModelSearchVendorOrdersParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** Response with orders search results */
export interface ModelSearchVendorOrdersData {
  /** List of search results */
  items: {
    /**
     * Order ID. Depending on `orderType` it is id of platform or ERP order.
     * @format uuid
     * @example "a10326b8-8801-4a6c-96ea-736c8b94b5ec"
     */
    id: string
    /** Type of the order */
    orderType: ModelSearchVendorOrdersOrderTypeEnum
    /**
     * Internal order number
     * @format int64
     * @example 8059203
     */
    orderNumber: number
    /**
     * Timestamp when the order was created from the vendor's point of view - this means `placed` for platform orders. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    placedAt: string
    /** Vendor data to be presented along the orders */
    customer: {
      /**
       * Customer ID
       * @example "20bc70a9-98a5-4146-85fe-c653ab8b3c22"
       */
      id: string
      /**
       * Customer company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Vendor specific customer numer
       * @example "ACM"
       */
      vendorCustomerNumber?: string
    }
    /**
     * Name of customer user that placed the order
     * @example "John Doe"
     */
    shopperName?: string
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchVendorOrdersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Basic user data and information about assigned customers and vendors */
export interface ModelGetMyUserData {
  /**
   * User ID (the same as in identity provider)
   * @example "ef1b8af4-f511-4fe6-9b6b-0a548a9819fa"
   */
  id: string
  /**
   * Username (used to log-in to the system)
   * @example "user@example.com"
   */
  username: string
  /** List of vendors assigned to user, first is the default (if present). */
  vendors: ({
    /**
     * ID of vendor/customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Vendor/customer company name
     * @example "ACME Ltd."
     */
    name: string
  } & {
    /**
     * company VAT-ID
     * @example "FR12345678901"
     */
    vatId?: string
    /** Basic information about an Address */
    address?: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
  })[]
  /** List of customers assigned to user, first is the default (if present). */
  customers: ({
    /**
     * ID of vendor/customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Vendor/customer company name
     * @example "ACME Ltd."
     */
    name: string
  } & {
    /**
     * company VAT-ID
     * @example "FR12345678901"
     */
    vatId?: string
    /** Basic information about an Address */
    address?: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
  })[]
  /** User's settings */
  settings: {
    /** @example "en" */
    locale: string
    viewSettings?: Record<string, any>
  }
}

export interface ModelPatchMyUserSettingsPayload {
  /**
   * UserSettingsUpdate
   * User's settings
   */
  settings: {
    /** @example "en" */
    locale?: string | null
    viewSettings?: Record<string, any>
  }
}

export type ModelPatchMyUserSettingsData = any

/** Response containing JWT access and refresh token */
export interface ModelVerifyAccountData {
  /** New JWT token */
  token: string
  /** Refresh token to be used for JWT refresh */
  refreshToken: string
}

/**
 * Error
 * Error
 */
export type ModelVerifyAccountError =
  | {
      /**
       * Message providing details of the error
       * @example "Validation failure"
       */
      message?: string
      /** List of error detail for each field, if the error is related to a particular form field otherwise null */
      details?: {
        /**
         * Name of the field that violates the constraint
         * @example "person.firstName"
         */
        field?: string
        /**
         * Error message that states the reason why the attached field is invalid
         * @example "must not be blank"
         */
        message?: string
      }[]
      /** List of additional data information that can be provided */
      additionalData?: {
        /**
         * Name of the property
         * @example "contactPerson"
         */
        label?: string
        /**
         * Value of the property
         * @example "example@gmail.com"
         */
        value?: string
      }[]
      /**
       * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       * @format date-time
       * @example "2021-10-25T11:10:57.849+00:00"
       */
      timestamp?: string
    }
  | {
      /** @example "4906cd7b-2810-4671-963b-81be91d2ad25" */
      userId: string
    }

export type ModelResendAccountVerificationData = any

/** Error */
export type ModelResendAccountVerificationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetInvitationStateCompanyTypeEnum = 'CUSTOMER' | 'VENDOR'

/** Current state of the invitation used as an indication for next action after user uses invitation link */
export interface ModelGetInvitationStateData {
  email: string
  passwordExists: boolean
  companyName: string
  companyType: ModelGetInvitationStateCompanyTypeEnum
  expired: boolean
  alreadyAccepted: boolean
}

export interface ModelUseInvitationPasswordPayload {
  password?: string
  consents?: {
    /** @default false */
    newsletter: boolean
  }
}

/** Response containing JWT access and refresh token */
export interface ModelUseInvitationPasswordData {
  /** New JWT token */
  token: string
  /** Refresh token to be used for JWT refresh */
  refreshToken: string
}

/** Error */
export type ModelUseInvitationPasswordError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelUseInvitationLoginPayload {
  password?: string
  consents?: {
    /** @default false */
    newsletter: boolean
  }
}

/** Response containing JWT access and refresh token */
export interface ModelUseInvitationLoginData {
  /** New JWT token */
  token: string
  /** Refresh token to be used for JWT refresh */
  refreshToken: string
}

export type ModelGetVendorProfileBusinessCategoriesEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

/** Profile of the company contains data (legal info, contact details, logos) presented to other users of the system */
export type ModelGetVendorProfileData = {
  /** Basic information about an Address */
  invoiceAddress?: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /** Company name for invoices Should be marked as required when deprecated values are removed. */
    companyName?: string
    /** Invoice recipient Should be marked as required when deprecated values are removed. */
    invoiceRecipient?: string
    /** Invoice text/field used for booking Should be marked as required when deprecated values are removed */
    invoiceBookingText?: string
  }
  bigLogoUrl?: string
} & {
  /** Company public profile */
  businessInfo: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryOfOperation: string
    /** @default false */
    publiclyNamed: boolean
    /** @format uri */
    websiteUrl?: string
    /** @format uri */
    termsOfBusinessUrl?: string
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency?: string
  } & {
    /**
     * @maxItems 5
     * @minItems 1
     * @uniqueItems true
     */
    businessCategories?: ModelGetVendorProfileBusinessCategoriesEnum[]
    /** True if vendor updated DAC7 required information */
    dac7Completed?: boolean
  }
  /** Common information of the company being registered as either Customer or Vendor */
  companyRegistration: ({
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    humanReadableId?: string
  }) & {
    /** @default true */
    publicVendor?: boolean
    /** @default false */
    migrated?: boolean
  }
}

/** Error */
export type ModelGetVendorProfileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateVendorProfileBusinessCategoriesEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

export type ModelUpdateVendorProfileBusinessCategoriesEnum1 =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

export type ModelUpdateVendorProfilePayload = {
  invoiceAddress?:
    | ({
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
      } & {
        /** Company name for invoices Should be marked as required when deprecated values are removed. */
        companyName?: string
        /** Invoice recipient Should be marked as required when deprecated values are removed. */
        invoiceRecipient?: string
        /** Invoice text/field used for booking Should be marked as required when deprecated values are removed */
        invoiceBookingText?: string
      })
    | null
  bigLogoUrl?: string | null
} & {
  businessInfo?: {
    /** @default false */
    publiclyNamed?: boolean | null
    /** @format uri */
    websiteUrl?: string | null
    /** @format uri */
    termsOfBusinessUrl?: string | null
  } & {
    /**
     * @maxItems 5
     * @minItems 1
     * @uniqueItems true
     */
    businessCategories?:
      | ModelUpdateVendorProfileBusinessCategoriesEnum1[]
      | null
  }
}

/** Profile of the company contains data (legal info, contact details, logos) presented to other users of the system */
export type ModelUpdateVendorProfileData = {
  /** Basic information about an Address */
  invoiceAddress?: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /** Company name for invoices Should be marked as required when deprecated values are removed. */
    companyName?: string
    /** Invoice recipient Should be marked as required when deprecated values are removed. */
    invoiceRecipient?: string
    /** Invoice text/field used for booking Should be marked as required when deprecated values are removed */
    invoiceBookingText?: string
  }
  bigLogoUrl?: string
} & {
  /** Company public profile */
  businessInfo: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryOfOperation: string
    /** @default false */
    publiclyNamed: boolean
    /** @format uri */
    websiteUrl?: string
    /** @format uri */
    termsOfBusinessUrl?: string
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency?: string
  } & {
    /**
     * @maxItems 5
     * @minItems 1
     * @uniqueItems true
     */
    businessCategories?: ModelUpdateVendorProfileBusinessCategoriesEnum[]
    /** True if vendor updated DAC7 required information */
    dac7Completed?: boolean
  }
  /** Common information of the company being registered as either Customer or Vendor */
  companyRegistration: ({
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    humanReadableId?: string
  }) & {
    /** @default true */
    publicVendor?: boolean
    /** @default false */
    migrated?: boolean
  }
}

/**
 * Error
 * Error
 */
export type ModelUpdateVendorProfileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorDac7Data = {
  isStateEntityOrPubliclyTraded?: boolean
} & {
  /** @example "Cedric Diggory" */
  lastUpdatedBy?: string
  /**
   * Full date and time of company DAC7 data update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2024-07-21T17:32:28.682Z"
   */
  lastUpdatedAt?: string
} & {
  companyRegistrationNumber?: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode?: string
    /** @example "HRA123456" */
    value?: string | null
  }
  taxIdentificationNumber?: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode?: string
    /** @example "123/4567899/10" */
    value?: string | null
  }
  /**
   * @uniqueItems true
   * @example ["DE","FR","PL"]
   */
  euCountriesWithEstablishment?: string[]
  bankAccount?: {
    /** @example "DE89370400440532" */
    iban?: string | null
    /** @example "DEUTDE2H" */
    bic?: string | null
    /** @example "ACME GmbH" */
    accountHolder?: string | null
  }
}

export type ModelUpdateVendorDac7Payload = {
  isStateEntityOrPubliclyTraded: boolean
} & {
  companyRegistrationNumber?: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode?: string
    /** @example "HRA123456" */
    value?: string | null
  }
  taxIdentificationNumber?: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode?: string
    /** @example "123/4567899/10" */
    value?: string | null
  }
  /**
   * @uniqueItems true
   * @example ["DE","FR","PL"]
   */
  euCountriesWithEstablishment?: string[]
  bankAccount?: {
    /** @example "DE89370400440532" */
    iban?: string | null
    /** @example "DEUTDE2H" */
    bic?: string | null
    /** @example "ACME GmbH" */
    accountHolder?: string | null
  }
}

export type ModelUpdateVendorDac7Data = any

export type ModelGetVendorUsersTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetVendorUsersStatusEnum = 'PENDING' | 'ACCEPTED' | 'EXPIRED'

export type ModelGetVendorUsersTitleEnum1 = 'MS' | 'MR' | 'OTHER'

/** vendor user role in the company */
export type ModelGetVendorUsersRoleInCompanyEnum =
  | 'HEAD_OF_SALES'
  | 'SALES_MANAGER'
  | 'CHANNEL_MANAGER'
  | 'EBUSINESS_MANAGER'
  | 'SALES_REPRESENTATIVE'
  | 'EBUSINESS_REPRESENTATIVE'

export interface ModelGetVendorUsersParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** VendorUserList */
export interface ModelGetVendorUsersData {
  items?: ({
    /**
     * @format uuid
     * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
     */
    companyUserId: string
    /** Contains information about basic user information */
    profile: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelGetVendorUsersTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    }
    /** Contains data about user invitation */
    invitation: {
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      createdAt: string
      status: ModelGetVendorUsersStatusEnum
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      acceptedAt?: string
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      expiresAt?: string
    } | null
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    lastActiveAt: string
    /** True if the user is support user that cannot be deleted */
    supportUser: boolean
  } & {
    /** Contains information about basic user information */
    profile?: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelGetVendorUsersTitleEnum1
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    } & {
      /** vendor user role in the company */
      roleInCompany?: ModelGetVendorUsersRoleInCompanyEnum | null
    }
  })[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

export type ModelPostVendorUserTitleEnum = 'MS' | 'MR' | 'OTHER'

/** vendor user role in the company */
export type ModelPostVendorUserRoleInCompanyEnum =
  | 'HEAD_OF_SALES'
  | 'SALES_MANAGER'
  | 'CHANNEL_MANAGER'
  | 'EBUSINESS_MANAGER'
  | 'SALES_REPRESENTATIVE'
  | 'EBUSINESS_REPRESENTATIVE'

/** Contains information about basic user information */
export type ModelPostVendorUserPayload = {
  /** Basic information of the person registering the Customer or Vendor */
  person: {
    title: ModelPostVendorUserTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  }
  email: string
  /** user role in the company */
  roleInCompany?: string
} & {
  /** vendor user role in the company */
  roleInCompany?: ModelPostVendorUserRoleInCompanyEnum | null
}

/** CreateVendorUserResponse */
export interface ModelPostVendorUserData {
  vendorUserId: string
}

/** Error */
export type ModelPostVendorUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetMeVendorUserTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetMeVendorUserStatusEnum = 'PENDING' | 'ACCEPTED' | 'EXPIRED'

export type ModelGetMeVendorUserTitleEnum1 = 'MS' | 'MR' | 'OTHER'

/** vendor user role in the company */
export type ModelGetMeVendorUserRoleInCompanyEnum =
  | 'HEAD_OF_SALES'
  | 'SALES_MANAGER'
  | 'CHANNEL_MANAGER'
  | 'EBUSINESS_MANAGER'
  | 'SALES_REPRESENTATIVE'
  | 'EBUSINESS_REPRESENTATIVE'

export type ModelGetMeVendorUserAvailableFeaturesEnum = 'OCI_CATALOGS'

export type ModelGetMeVendorUserData = ({
  /**
   * @format uuid
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  companyUserId: string
  /** Contains information about basic user information */
  profile: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetMeVendorUserTitleEnum
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  }
  /** Contains data about user invitation */
  invitation: {
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    status: ModelGetMeVendorUserStatusEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    acceptedAt?: string
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    expiresAt?: string
  } | null
  /**
   * @format date-time
   * @example "2021-11-25T11:10:57.849+00:00"
   */
  lastActiveAt: string
  /** True if the user is support user that cannot be deleted */
  supportUser: boolean
} & {
  /** Contains information about basic user information */
  profile?: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetMeVendorUserTitleEnum1
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  } & {
    /** vendor user role in the company */
    roleInCompany?: ModelGetMeVendorUserRoleInCompanyEnum | null
  }
}) & {
  /**
   * List of conditional features enabled for this vendor user
   * @uniqueItems true
   */
  availableFeatures?: ModelGetMeVendorUserAvailableFeaturesEnum[]
}

export type ModelPutVendorUserTitleEnum = 'MS' | 'MR' | 'OTHER'

/** vendor user role in the company */
export type ModelPutVendorUserRoleInCompanyEnum =
  | 'HEAD_OF_SALES'
  | 'SALES_MANAGER'
  | 'CHANNEL_MANAGER'
  | 'EBUSINESS_MANAGER'
  | 'SALES_REPRESENTATIVE'
  | 'EBUSINESS_REPRESENTATIVE'

/** Contains information for vendor user profile update */
export interface ModelPutVendorUserPayload {
  /** Basic information of the person registering the Customer or Vendor */
  person: {
    title: ModelPutVendorUserTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  }
  /** vendor user role in the company */
  roleInCompany?: ModelPutVendorUserRoleInCompanyEnum | null
}

export type ModelPutVendorUserData = any

/** Error */
export type ModelPutVendorUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteVendorUserData = any

/** Error */
export type ModelDeleteVendorUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorUserTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetVendorUserStatusEnum = 'PENDING' | 'ACCEPTED' | 'EXPIRED'

export type ModelGetVendorUserTitleEnum1 = 'MS' | 'MR' | 'OTHER'

/** vendor user role in the company */
export type ModelGetVendorUserRoleInCompanyEnum =
  | 'HEAD_OF_SALES'
  | 'SALES_MANAGER'
  | 'CHANNEL_MANAGER'
  | 'EBUSINESS_MANAGER'
  | 'SALES_REPRESENTATIVE'
  | 'EBUSINESS_REPRESENTATIVE'

/** Contains all information about company user profile */
export type ModelGetVendorUserData = {
  /**
   * @format uuid
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  companyUserId: string
  /** Contains information about basic user information */
  profile: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetVendorUserTitleEnum
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  }
  /** Contains data about user invitation */
  invitation: {
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    status: ModelGetVendorUserStatusEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    acceptedAt?: string
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    expiresAt?: string
  } | null
  /**
   * @format date-time
   * @example "2021-11-25T11:10:57.849+00:00"
   */
  lastActiveAt: string
  /** True if the user is support user that cannot be deleted */
  supportUser: boolean
} & {
  /** Contains information about basic user information */
  profile?: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetVendorUserTitleEnum1
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  } & {
    /** vendor user role in the company */
    roleInCompany?: ModelGetVendorUserRoleInCompanyEnum | null
  }
}

/** Error */
export type ModelGetVendorUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPostVendorUserResendInvitationData = any

/**
 * Error
 * Error
 */
export type ModelPostVendorUserResendInvitationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorContactsTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetVendorContactsTitleEnum1 = 'MS' | 'MR' | 'OTHER'

export type ModelGetVendorContactsTitleEnum2 = 'MS' | 'MR' | 'OTHER'

export type ModelGetVendorContactsTitleEnum3 = 'MS' | 'MR' | 'OTHER'

export type ModelGetVendorContactsTitleEnum4 = 'MS' | 'MR' | 'OTHER'

/** A map of contactType to contactDetails */
export interface ModelGetVendorContactsData {
  /** Vendor contact data */
  main?:
    | ({
        title: ModelGetVendorContactsTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  customerSupport?:
    | ({
        title: ModelGetVendorContactsTitleEnum1
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  technicalIntegration?:
    | ({
        title: ModelGetVendorContactsTitleEnum2
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  billingAccounting?:
    | ({
        title: ModelGetVendorContactsTitleEnum3
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  dataPrivacy?:
    | ({
        title: ModelGetVendorContactsTitleEnum4
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  platformInvoicesEmail?: string | null
  vendorRequestNotificationEmail?: string | null
}

/** Error */
export type ModelGetVendorContactsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPatchVendorContactsTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum1 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum2 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum3 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum4 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum5 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum6 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum7 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum8 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchVendorContactsTitleEnum9 = 'MS' | 'MR' | 'OTHER'

/** A map of contactType to contactDetails */
export interface ModelPatchVendorContactsPayload {
  /** Vendor contact data */
  main?:
    | ({
        title: ModelPatchVendorContactsTitleEnum5
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  customerSupport?:
    | ({
        title: ModelPatchVendorContactsTitleEnum6
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  technicalIntegration?:
    | ({
        title: ModelPatchVendorContactsTitleEnum7
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  billingAccounting?:
    | ({
        title: ModelPatchVendorContactsTitleEnum8
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  dataPrivacy?:
    | ({
        title: ModelPatchVendorContactsTitleEnum9
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  platformInvoicesEmail?: string | null
  vendorRequestNotificationEmail?: string | null
}

/** A map of contactType to contactDetails */
export interface ModelPatchVendorContactsData {
  /** Vendor contact data */
  main?:
    | ({
        title: ModelPatchVendorContactsTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  customerSupport?:
    | ({
        title: ModelPatchVendorContactsTitleEnum1
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  technicalIntegration?:
    | ({
        title: ModelPatchVendorContactsTitleEnum2
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  billingAccounting?:
    | ({
        title: ModelPatchVendorContactsTitleEnum3
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Vendor contact data */
  dataPrivacy?:
    | ({
        title: ModelPatchVendorContactsTitleEnum4
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  platformInvoicesEmail?: string | null
  vendorRequestNotificationEmail?: string | null
}

/**
 * Error
 * Error
 */
export type ModelPatchVendorContactsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetVendorContactByTypeTitleEnum = 'MS' | 'MR' | 'OTHER'

/**
 * Vendor contact data
 * @example {"title":"MS","firstName":"Elise","lastName":"Tiedermann","phoneNumber":"+99 000010001","email":"elise.tiedermann@formonix.de"}
 */
export type ModelGetVendorContactByTypeData = {
  title: ModelGetVendorContactByTypeTitleEnum
  firstName: string
  lastName: string
  phoneNumber: string
} & {
  email: string
}

/** Error */
export type ModelGetVendorContactByTypeError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** contact types for vendor */
export type ModelGetVendorContactByTypeParamsContactTypeEnum =
  | 'main'
  | 'customerSupport'
  | 'technicalIntegration'
  | 'billingAccounting'
  | 'dataPrivacy'

/** contact types for vendor */
export type ModelGetVendorContactByTypeParamsEnum =
  | 'main'
  | 'customerSupport'
  | 'technicalIntegration'
  | 'billingAccounting'
  | 'dataPrivacy'

export interface ModelGetAgreementsData {
  provisioningContract?: {
    file?: {
      id?: string
    }
    /** Full name of the user that registered Vendor */
    acceptedBy?: string
    /**
     * @format date-time
     * @example "2021-10-25T11:10:57.849Z"
     */
    acceptedAt?: string
  } | null
  dataPrivacyPolicy?: {
    file?: {
      id?: string
    }
    /** Full name of the user that registered Vendor */
    acceptedBy?: string
    /**
     * @format date-time
     * @example "2021-10-25T11:10:57.849Z"
     */
    acceptedAt?: string
  } | null
}

/** Error */
export type ModelGetAgreementsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with download URL and other data required to perform file download. */
export interface ModelGetAgreementDownloadUrlData {
  /**
   * Presigned url for file download
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  downloadUrl: string
  /**
   * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
}

export interface ModelGetEligibleApproversParams {
  /** Search term */
  term?: string
}

/** EligibleApproversList */
export interface ModelGetEligibleApproversData {
  items?: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    customerUserId: string
    /** @example "John" */
    firstName: string
    /** @example "Smith" */
    lastName: string
    /**
     * User email address
     * @format email
     * @example "user@comapny.com"
     */
    email: string
  }[]
}

export type ModelAddNewApprovalFlowTemplateEnum =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelAddNewApprovalFlowTemplateEnum1 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelAddNewApprovalFlowTemplateEnum2 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelAddNewApprovalFlowSelectionTypeEnum =
  | 'SELECTED_VENDORS'
  | 'ALL_VENDORS'
  | 'VENDORS_FROM_OPEN_MARKETPLACE'
  | 'VENDORS_FROM_PARTNER_MARKETPLACE'

export type ModelAddNewApprovalFlowTemplateEnum3 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelAddNewApprovalFlowTemplateEnum4 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

/** New approval flow */
export interface ModelAddNewApprovalFlowPayload {
  /**
   * Unique name of an approval flow
   * @example "Full approval flow with every rule possible!"
   */
  name: string
  /**
   * Configured steps
   * @minItems 1
   */
  steps: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    mainApprover: string
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    deputyApprover?: string
    /** @min 1 */
    notifyDeputyAfterDays?: number
    rule:
      | ({
          template: ModelAddNewApprovalFlowTemplateEnum
        } & {
          articlePriceBiggerThanRuleConfiguration?: {
            /** Amount of money */
            priceGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelAddNewApprovalFlowTemplateEnum1
        } & {
          orderTotalBiggerThanSimpleRuleConfiguration?: {
            /** Amount of money */
            totalGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelAddNewApprovalFlowTemplateEnum2
        } & {
          vendorIsAnyOfRuleConfiguration?: {
            vendorIds?: string[] | null
            selectionType?: ModelAddNewApprovalFlowSelectionTypeEnum
          }
        })
      | ({
          template: ModelAddNewApprovalFlowTemplateEnum3
        } & {
          accountValueIsAnyOfRuleConfiguration?: {
            accountValueIds?: string[]
          } | null
        })
      | ({
          template: ModelAddNewApprovalFlowTemplateEnum4
        } & {
          articleEClassAnyOfConfiguration?: {
            /**
             * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
             * When eClass node has `true` value its selected and all of its children are also selected.
             * Mapping to `false` clears mapping defined by parents
             * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
             */
            eClasses?: Record<string, boolean>
          } | null
        })
  }[]
  /** @example true */
  enabled: boolean
}

export type ModelAddNewApprovalFlowData = any

/**
 * Error
 * Error
 */
export type ModelAddNewApprovalFlowError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetApprovalFlowTemplateEnum =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetApprovalFlowTemplateEnum1 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetApprovalFlowTemplateEnum2 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetApprovalFlowSelectionTypeEnum =
  | 'SELECTED_VENDORS'
  | 'ALL_VENDORS'
  | 'VENDORS_FROM_OPEN_MARKETPLACE'
  | 'VENDORS_FROM_PARTNER_MARKETPLACE'

export type ModelGetApprovalFlowTemplateEnum3 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelGetApprovalFlowTemplateEnum4 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

/** New approval flow */
export interface ModelGetApprovalFlowData {
  /**
   * Unique name of an approval flow
   * @example "Full approval flow with every rule possible!"
   */
  name: string
  /**
   * Configured steps
   * @minItems 1
   */
  steps: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    mainApprover: string
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    deputyApprover?: string
    /** @min 1 */
    notifyDeputyAfterDays?: number
    rule:
      | ({
          template: ModelGetApprovalFlowTemplateEnum
        } & {
          articlePriceBiggerThanRuleConfiguration?: {
            /** Amount of money */
            priceGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelGetApprovalFlowTemplateEnum1
        } & {
          orderTotalBiggerThanSimpleRuleConfiguration?: {
            /** Amount of money */
            totalGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelGetApprovalFlowTemplateEnum2
        } & {
          vendorIsAnyOfRuleConfiguration?: {
            vendorIds?: string[] | null
            selectionType?: ModelGetApprovalFlowSelectionTypeEnum
          }
        })
      | ({
          template: ModelGetApprovalFlowTemplateEnum3
        } & {
          accountValueIsAnyOfRuleConfiguration?: {
            accountValueIds?: string[]
          } | null
        })
      | ({
          template: ModelGetApprovalFlowTemplateEnum4
        } & {
          articleEClassAnyOfConfiguration?: {
            /**
             * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
             * When eClass node has `true` value its selected and all of its children are also selected.
             * Mapping to `false` clears mapping defined by parents
             * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
             */
            eClasses?: Record<string, boolean>
          } | null
        })
  }[]
  /** @example true */
  enabled: boolean
}

export type ModelUpdateApprovalFlowTemplateEnum =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelUpdateApprovalFlowTemplateEnum1 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelUpdateApprovalFlowTemplateEnum2 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelUpdateApprovalFlowSelectionTypeEnum =
  | 'SELECTED_VENDORS'
  | 'ALL_VENDORS'
  | 'VENDORS_FROM_OPEN_MARKETPLACE'
  | 'VENDORS_FROM_PARTNER_MARKETPLACE'

export type ModelUpdateApprovalFlowTemplateEnum3 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelUpdateApprovalFlowTemplateEnum4 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

/** Update of approval flow */
export interface ModelUpdateApprovalFlowPayload {
  /**
   * Unique name of an approval flow
   * @example "Full approval flow with every rule possible!"
   */
  name?: string | null
  /**
   * Configured steps
   * @minItems 1
   */
  steps?: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    mainApprover: string
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    deputyApprover?: string
    /** @min 1 */
    notifyDeputyAfterDays?: number
    rule:
      | ({
          template: ModelUpdateApprovalFlowTemplateEnum
        } & {
          articlePriceBiggerThanRuleConfiguration?: {
            /** Amount of money */
            priceGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelUpdateApprovalFlowTemplateEnum1
        } & {
          orderTotalBiggerThanSimpleRuleConfiguration?: {
            /** Amount of money */
            totalGreaterThan?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
          } | null
        })
      | ({
          template: ModelUpdateApprovalFlowTemplateEnum2
        } & {
          vendorIsAnyOfRuleConfiguration?: {
            vendorIds?: string[] | null
            selectionType?: ModelUpdateApprovalFlowSelectionTypeEnum
          }
        })
      | ({
          template: ModelUpdateApprovalFlowTemplateEnum3
        } & {
          accountValueIsAnyOfRuleConfiguration?: {
            accountValueIds?: string[]
          } | null
        })
      | ({
          template: ModelUpdateApprovalFlowTemplateEnum4
        } & {
          articleEClassAnyOfConfiguration?: {
            /**
             * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
             * When eClass node has `true` value its selected and all of its children are also selected.
             * Mapping to `false` clears mapping defined by parents
             * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
             */
            eClasses?: Record<string, boolean>
          } | null
        })
  }[]
  /** @example true */
  enabled?: boolean | null
}

export type ModelUpdateApprovalFlowData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateApprovalFlowError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteApprovalFlowData = any

/** Error */
export type ModelDeleteApprovalFlowError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPostApprovalFlowSearchTemplateEnum =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelPostApprovalFlowSearchTemplateEnum1 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelPostApprovalFlowSearchTemplateEnum2 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelPostApprovalFlowSearchSelectionTypeEnum =
  | 'SELECTED_VENDORS'
  | 'ALL_VENDORS'
  | 'VENDORS_FROM_OPEN_MARKETPLACE'
  | 'VENDORS_FROM_PARTNER_MARKETPLACE'

export type ModelPostApprovalFlowSearchTemplateEnum3 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

export type ModelPostApprovalFlowSearchTemplateEnum4 =
  | 'ARTICLE_PRICE_BIGGER_THAN'
  | 'ORDER_TOTAL_BIGGER_THAN'
  | 'VENDOR_IS_ANY_OF'
  | 'ACCOUNT_VALUE_IS_ANY_OF'
  | 'ARTICLE_E_CLASS_ANY_OF'

/** Parameters of approval flow search */
export interface ModelPostApprovalFlowSearchPayload {
  /**
   * Search term
   * @example "Articles exceeding 1000 EUR"
   */
  term?: string
}

export interface ModelPostApprovalFlowSearchParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Whether to paginate results or not; defaults to true
   * @default true
   * @example false
   */
  paginated?: boolean
  /**
   * Sort field and direction (available: [`name,desc`, `name,asc`, `enabled,desc`, `enabled,asc`, `createdAt,desc`, `createdAt,asc`]).
   * Default sorting: `createdAt,desc`.
   */
  sort?: string[]
}

/** Response with list of approval flows */
export interface ModelPostApprovalFlowSearchData {
  /** List of approval flows */
  approvalFlows: ({
    /**
     * Approval flow ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Unique name of an approval flow
     * @example "Full approval flow with every rule possible!"
     */
    name: string
    /**
     * Approval Flow enabled flag
     * @example true
     */
    enabled: boolean
    /**
     * Full date and time of approval flow added according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    createdAt: string
    /**
     * Configured steps
     * @minItems 1
     */
    steps: {
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      mainApprover: string
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      deputyApprover?: string
      /** @min 1 */
      notifyDeputyAfterDays?: number
      rule:
        | ({
            template: ModelPostApprovalFlowSearchTemplateEnum
          } & {
            articlePriceBiggerThanRuleConfiguration?: {
              /** Amount of money */
              priceGreaterThan?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
            } | null
          })
        | ({
            template: ModelPostApprovalFlowSearchTemplateEnum1
          } & {
            orderTotalBiggerThanSimpleRuleConfiguration?: {
              /** Amount of money */
              totalGreaterThan?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
            } | null
          })
        | ({
            template: ModelPostApprovalFlowSearchTemplateEnum2
          } & {
            vendorIsAnyOfRuleConfiguration?: {
              vendorIds?: string[] | null
              selectionType?: ModelPostApprovalFlowSearchSelectionTypeEnum
            }
          })
        | ({
            template: ModelPostApprovalFlowSearchTemplateEnum3
          } & {
            accountValueIsAnyOfRuleConfiguration?: {
              accountValueIds?: string[]
            } | null
          })
        | ({
            template: ModelPostApprovalFlowSearchTemplateEnum4
          } & {
            articleEClassAnyOfConfiguration?: {
              /**
               * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
               * When eClass node has `true` value its selected and all of its children are also selected.
               * Mapping to `false` clears mapping defined by parents
               * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
               */
              eClasses?: Record<string, boolean>
            } | null
          })
    }[]
    usedByVendingMachines: string[]
  } & {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelPostApprovalFlowSearchError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The approval flow settings of the customer */
export interface ModelGetCustomerApprovalFlowSettingsData {
  /**
   * True if the user has to be authorized to approve an order
   * @example true
   */
  authorizationRequired: boolean
  /**
   * True if the user can modify the order number during the approval process
   * @example true
   */
  orderNumberModificationAllowed: boolean
}

/** Error */
export type ModelGetCustomerApprovalFlowSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** The approval flow settings of the customer */
export interface ModelUpdateCustomerApprovalFlowSettingsPayload {
  /**
   * True if the user has to be authorized to approve an order
   * @example true
   */
  authorizationRequired: boolean
  /**
   * True if the user can modify the order number during the approval process
   * @example true
   */
  orderNumberModificationAllowed: boolean
}

export type ModelUpdateCustomerApprovalFlowSettingsData = any

/** Error */
export type ModelUpdateCustomerApprovalFlowSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetApprovalFlowAssignedUsersData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetApprovalFlowAssignedUsersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of users assigned to the article permission */
export type ModelPutApprovalFlowAssignedUsersPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelPutApprovalFlowAssignedUsersData = any

/**
 * Error
 * Error
 */
export type ModelPutApprovalFlowAssignedUsersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelApproveStepData = any

/** Error */
export type ModelApproveStepError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Reason of rejection */
export interface ModelRejectStepPayload {
  /**
   * Reason of rejection
   * @example "Last year, a new rule was introduced under which we cannot allow orders from this supplier to be made under office orders. Please use the predefined lists created by the company's managers.
   * "
   */
  reason: string
}

export type ModelRejectStepData = any

/**
 * Error
 * Error
 */
export type ModelRejectStepError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Modifications on the order lever done during the approval process */
export interface ModelModifyOrderStepPayload {
  /**
   * Custom order number set during the approval process
   * @minLength 1
   * @example "ALDI-1773"
   */
  orderNumber: string
}

export type ModelModifyOrderStepData = any

/**
 * Error
 * Error
 */
export type ModelModifyOrderStepError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetApprovalLinkStatusEnum =
  | 'WAITING_FOR_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'

/** Approval link view */
export interface ModelGetApprovalLinkData {
  status: ModelGetApprovalLinkStatusEnum
  /** @example "Franz Kafka" */
  shopper: string
}

/** Error */
export type ModelGetApprovalLinkError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelApproveStepUsingLinkStatusEnum =
  | 'WAITING_FOR_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'

export type ModelApproveStepUsingLinkStatusEnum1 =
  | 'WAITING_FOR_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'

/** Update Approval link */
export interface ModelApproveStepUsingLinkPayload {
  status: ModelApproveStepUsingLinkStatusEnum1
}

/** Approval link view */
export interface ModelApproveStepUsingLinkData {
  status: ModelApproveStepUsingLinkStatusEnum
  /** @example "Franz Kafka" */
  shopper: string
}

/**
 * Error
 * Error
 * Error
 */
export type ModelApproveStepUsingLinkError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerProfileBusinessCategoryEnum =
  | 'AGRICULTURE'
  | 'CONSTRUCTION'
  | 'CHEMISTRY_RAW_MATERIALS'
  | 'SERVICES_MANUFACTURING'
  | 'E_COMMERCE'
  | 'ENERGY_ENVIRONMENT'
  | 'FINANCE_INSURANCE_REAL_ESTATE'
  | 'LEISURE'
  | 'SOCIETY'
  | 'TRADE'
  | 'IT_TELECOMMUNICATIONS'
  | 'RETAIL'
  | 'PHARMACY'
  | 'SPORTS'
  | 'ELECTRONICS'
  | 'TOURISM_TRAVEL'
  | 'MARKETING'
  | 'POLITICS'

/** Profile of the company contains data (legal info, contact details, logos) presented to other users of the system */
export type ModelGetCustomerProfileData = {
  /** Basic information about an Address */
  invoiceAddress?: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /** Company name for invoices Should be marked as required when deprecated values are removed. */
    companyName?: string
    /** Invoice recipient Should be marked as required when deprecated values are removed. */
    invoiceRecipient?: string
    /** Invoice text/field used for booking Should be marked as required when deprecated values are removed */
    invoiceBookingText?: string
  }
  bigLogoUrl?: string
} & {
  /** Company public profile */
  businessInfo: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryOfOperation: string
    /** @default false */
    publiclyNamed: boolean
    /** @format uri */
    websiteUrl?: string
    /** @format uri */
    termsOfBusinessUrl?: string
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency?: string
  } & {
    businessCategory?: ModelGetCustomerProfileBusinessCategoryEnum
  }
  /** Common information of the company being registered as either Customer or Vendor */
  companyRegistration: {
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    humanReadableId?: string
  }
}

/** Error */
export type ModelGetCustomerProfileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateCustomerProfileBusinessCategoryEnum =
  | 'AGRICULTURE'
  | 'CONSTRUCTION'
  | 'CHEMISTRY_RAW_MATERIALS'
  | 'SERVICES_MANUFACTURING'
  | 'E_COMMERCE'
  | 'ENERGY_ENVIRONMENT'
  | 'FINANCE_INSURANCE_REAL_ESTATE'
  | 'LEISURE'
  | 'SOCIETY'
  | 'TRADE'
  | 'IT_TELECOMMUNICATIONS'
  | 'RETAIL'
  | 'PHARMACY'
  | 'SPORTS'
  | 'ELECTRONICS'
  | 'TOURISM_TRAVEL'
  | 'MARKETING'
  | 'POLITICS'

export type ModelUpdateCustomerProfileBusinessCategoryEnum1 =
  | 'AGRICULTURE'
  | 'CONSTRUCTION'
  | 'CHEMISTRY_RAW_MATERIALS'
  | 'SERVICES_MANUFACTURING'
  | 'E_COMMERCE'
  | 'ENERGY_ENVIRONMENT'
  | 'FINANCE_INSURANCE_REAL_ESTATE'
  | 'LEISURE'
  | 'SOCIETY'
  | 'TRADE'
  | 'IT_TELECOMMUNICATIONS'
  | 'RETAIL'
  | 'PHARMACY'
  | 'SPORTS'
  | 'ELECTRONICS'
  | 'TOURISM_TRAVEL'
  | 'MARKETING'
  | 'POLITICS'

export type ModelUpdateCustomerProfilePayload = {
  invoiceAddress?:
    | ({
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
      } & {
        /** Company name for invoices Should be marked as required when deprecated values are removed. */
        companyName?: string
        /** Invoice recipient Should be marked as required when deprecated values are removed. */
        invoiceRecipient?: string
        /** Invoice text/field used for booking Should be marked as required when deprecated values are removed */
        invoiceBookingText?: string
      })
    | null
  bigLogoUrl?: string | null
} & {
  businessInfo?: {
    /** @default false */
    publiclyNamed?: boolean | null
    /** @format uri */
    websiteUrl?: string | null
    /** @format uri */
    termsOfBusinessUrl?: string | null
  } & {
    businessCategory?: ModelUpdateCustomerProfileBusinessCategoryEnum1 | null
  }
}

/** Profile of the company contains data (legal info, contact details, logos) presented to other users of the system */
export type ModelUpdateCustomerProfileData = {
  /** Basic information about an Address */
  invoiceAddress?: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /** Company name for invoices Should be marked as required when deprecated values are removed. */
    companyName?: string
    /** Invoice recipient Should be marked as required when deprecated values are removed. */
    invoiceRecipient?: string
    /** Invoice text/field used for booking Should be marked as required when deprecated values are removed */
    invoiceBookingText?: string
  }
  bigLogoUrl?: string
} & {
  /** Company public profile */
  businessInfo: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryOfOperation: string
    /** @default false */
    publiclyNamed: boolean
    /** @format uri */
    websiteUrl?: string
    /** @format uri */
    termsOfBusinessUrl?: string
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency?: string
  } & {
    businessCategory?: ModelUpdateCustomerProfileBusinessCategoryEnum
  }
  /** Common information of the company being registered as either Customer or Vendor */
  companyRegistration: {
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    humanReadableId?: string
  }
}

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerProfileError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetCustomerOrderTransferSettingsEclassVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** The format in which customer will receive the orders */
export type ModelGetCustomerOrderTransferSettingsFormatEnum =
  | 'S2XML'
  | 'ASCII_FILE'
  | 'PLAIN_TEXT'
  | 'BMD_ERP'

/** Order transfer method */
export type ModelGetCustomerOrderTransferSettingsMethodEnum =
  | 'REST_API'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'
  | 'FTP_PULL'
  | 'SFTP_PULL'
  | 'EMAIL'

export interface ModelGetCustomerOrderTransferSettingsData {
  defaultVendorNumber?: string
  /** Version of eClass specification */
  eclassVersion?: ModelGetCustomerOrderTransferSettingsEclassVersionEnum
  /** Settings that enables customer to receive orders */
  transportSettings?: {
    /** The format in which customer will receive the orders */
    format: ModelGetCustomerOrderTransferSettingsFormatEnum
    /** Order transfer method */
    method: ModelGetCustomerOrderTransferSettingsMethodEnum
    /** The settings needed to successfully send orders via the chosen method */
    connectionSettings: {
      /**
       * URL where to upload files
       * @example "https://www.abc.com"
       */
      url?: string
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /** Host or IP of the FTP server */
      hostname?: string
      /**
       * Optional; path where the files for orders will be located
       * @example "/orders"
       */
      directory?: string
      /**
       * @min 1
       * @max 65535
       * @example 21
       */
      port?: number
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /**
       * The email address to which the orders will be sent
       * @example "orders@vendor1.com"
       */
      email?: string
      /**
       * The email subject used for sent emails
       * @maxLength 988
       * @example "SimpleSystem ERP Integration"
       */
      subject?: string
    }
  }
}

/** Version of eClass specification */
export type ModelPatchCustomerOrderTransferSettingsEclassVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** The format in which customer will receive the orders */
export type ModelPatchCustomerOrderTransferSettingsFormatEnum =
  | 'S2XML'
  | 'ASCII_FILE'
  | 'PLAIN_TEXT'
  | 'BMD_ERP'

/** Order transfer method */
export type ModelPatchCustomerOrderTransferSettingsMethodEnum =
  | 'REST_API'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'
  | 'FTP_PULL'
  | 'SFTP_PULL'
  | 'EMAIL'

export interface ModelPatchCustomerOrderTransferSettingsPayload {
  defaultVendorNumber?: string | null
  /** Version of eClass specification */
  eclassVersion?: ModelPatchCustomerOrderTransferSettingsEclassVersionEnum | null
  /** Settings that enables customer to receive orders */
  transportSettings?: {
    /** The format in which customer will receive the orders */
    format: ModelPatchCustomerOrderTransferSettingsFormatEnum
    /** Order transfer method */
    method: ModelPatchCustomerOrderTransferSettingsMethodEnum
    /** The settings needed to successfully send orders via the chosen method */
    connectionSettings: {
      /**
       * URL where to upload files
       * @example "https://www.abc.com"
       */
      url?: string
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /** Host or IP of the FTP server */
      hostname?: string
      /**
       * Optional; path where the files for orders will be located
       * @example "/orders"
       */
      directory?: string
      /**
       * @min 1
       * @max 65535
       * @example 21
       */
      port?: number
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /**
       * The email address to which the orders will be sent
       * @example "orders@vendor1.com"
       */
      email?: string
      /**
       * The email subject used for sent emails
       * @maxLength 988
       * @example "SimpleSystem ERP Integration"
       */
      subject?: string
    }
  } | null
}

export type ModelPatchCustomerOrderTransferSettingsData = any

/** Error */
export type ModelPatchCustomerOrderTransferSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerBackOrderInterfaceSettingsFormatEnum =
  | 'ORDERS05'
  | 'OPENTRANS_10'
  | 'OPENTRANS_21'
  | 'S2XML'
  | 'BMD_ERP'

export type ModelGetCustomerBackOrderInterfaceSettingsMethodEnum =
  | 'EMAIL'
  | 'HTTPS'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'

export interface ModelGetCustomerBackOrderInterfaceSettingsData {
  erpGroup?: {
    master: boolean
    accountingArea: string
    /** Common information of the company being registered as either Customer or Vendor */
    masterAccount: {
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }
    members: {
      /** Common information of the company being registered as either Customer or Vendor */
      company: {
        name: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
        companyRegistrationNumber?: string
        /** @example "München" */
        cityCourt?: string
        vatId: string
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
      } & {
        humanReadableId?: string
      }
      accountingArea: string
    }[]
  } | null
  transportSettings?: {
    format?: ModelGetCustomerBackOrderInterfaceSettingsFormatEnum
    method?: ModelGetCustomerBackOrderInterfaceSettingsMethodEnum
    /** @example "some@email.com" */
    contactPersonEmail?: string
    /** @example "default@email.com" */
    defaultEmail?: string
    keepLeadingZerosInVendorNumber?: boolean
    connectionSettings?: {
      /**
       * @maxItems 16
       * @minItems 1
       */
      emailSenders?: string[]
      shouldContainSubjectPhrase?: boolean
      /** @example "SimpleSystem ERP Integration" */
      subjectPhrase?: string
    } & {
      /** Host or IP of the FTP server */
      hostname?: string
      /**
       * Optional; path where the files for orders will be located
       * @example "/orders"
       */
      directory?: string
      /**
       * @min 1
       * @max 65535
       * @example 21
       */
      port?: number
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
    } & {
      /** @example "orders@simplesystem.com" */
      emailSendTo?: string
      /** @example "https://www.abc.com" */
      url?: string
    }
    defaultSettings?: {
      /** @example "test" */
      username?: string
      /** @example "super$3cret!" */
      password?: string
      ftp?: {
        /** Host or IP of the FTP server */
        hostname?: string
        /**
         * Optional; path where the files for orders will be located
         * @example "/orders"
         */
        directory?: string
        /**
         * @min 1
         * @max 65535
         * @example 21
         */
        port?: number
      }
      sftp?: {
        /** Host or IP of the FTP server */
        hostname?: string
        /**
         * Optional; path where the files for orders will be located
         * @example "/orders"
         */
        directory?: string
        /**
         * @min 1
         * @max 65535
         * @example 21
         */
        port?: number
      }
      https?: {
        /** @example "https://www.abc.com" */
        url?: string
      }
      email?: {
        /** @example "orders@simplesystem.com" */
        emailSendTo?: string
      }
    }
  }
}

export type ModelPatchCustomerBackOrderInterfaceSettingsFormatEnum =
  | 'ORDERS05'
  | 'OPENTRANS_10'
  | 'OPENTRANS_21'
  | 'S2XML'
  | 'BMD_ERP'

export type ModelPatchCustomerBackOrderInterfaceSettingsMethodEnum =
  | 'EMAIL'
  | 'HTTPS'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'

export interface ModelPatchCustomerBackOrderInterfaceSettingsPayload {
  transportSettings?: {
    format?: ModelPatchCustomerBackOrderInterfaceSettingsFormatEnum
    method?: ModelPatchCustomerBackOrderInterfaceSettingsMethodEnum
    /** @example "some@email.com" */
    contactPersonEmail?: string
    /** @example "default@email.com" */
    defaultEmail?: string
    keepLeadingZerosInVendorNumber?: boolean
    connectionSettings?: {
      /**
       * @maxItems 16
       * @minItems 1
       */
      emailSenders?: string[]
      shouldContainSubjectPhrase?: boolean
      /** @example "SimpleSystem ERP Integration" */
      subjectPhrase?: string
    }
  } | null
}

export type ModelPatchCustomerBackOrderInterfaceSettingsData = any

/** Error */
export type ModelPatchCustomerBackOrderInterfaceSettingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Order transfer method */
export type ModelGetOrderTransferSettingsPullConfigurationMethodEnum =
  | 'REST_API'
  | 'FTP_PUSH'
  | 'SFTP_PUSH'
  | 'FTP_PULL'
  | 'SFTP_PULL'
  | 'EMAIL'

/** Configuration data for FTP_PULL and SFTP_PULL */
export type ModelGetOrderTransferSettingsPullConfigurationData = (({
  /** Host or IP of the FTP server */
  hostname: string
  /**
   * Optional; path where the files for orders will be located
   * @example "/orders"
   */
  directory?: string
  /**
   * @min 1
   * @max 65535
   * @example 21
   */
  port: number
  /** @example "test" */
  username: string
  /** @example "super$3cret!" */
  password: string
} & object) & {
  /** Order transfer method */
  method: ModelGetOrderTransferSettingsPullConfigurationMethodEnum
})[]

/** Configuration data for REST-API transfer method */
export type ModelGetOrderTransferSettingsRestApiConfigurationData = {
  /**
   * URL where to upload files
   * @example "https://www.abc.com"
   */
  url?: string
  /** @example "test" */
  username: string
  /** @example "super$3cret!" */
  password: string
} & object

/** Response with mapping of default units to delivered units */
export interface ModelGetCustomerUnitMappingsData {
  /**
   * Unit piece
   * @minLength 1
   */
  PCE: string
  /**
   * Unit piece
   * @minLength 1
   */
  EA: string
  /**
   * Unit meter
   * @minLength 1
   */
  MTR: string
  /**
   * Unit kilogram
   * @minLength 1
   */
  KGM: string
  /**
   * Unit piece
   * @minLength 1
   */
  C62: string
  /**
   * Unit piece
   * @minLength 1
   */
  ST: string
  /**
   * Unit bag
   * @minLength 1
   */
  BG: string
  /**
   * Unit bottle
   * @minLength 1
   */
  BO: string
  /**
   * Unit carton
   * @minLength 1
   */
  CT: string
  /**
   * Unit packset
   * @minLength 1
   */
  PA: string
  /**
   * Unit pack
   * @minLength 1
   */
  PK: string
  /**
   * Unit tray
   * @minLength 1
   */
  PU: string
  /**
   * Unit pair
   * @minLength 1
   */
  PR: string
  /**
   * Unit reel
   * @minLength 1
   */
  RL: string
  /**
   * Unit roll
   * @minLength 1
   */
  RO: string
  /**
   * Unit sack
   * @minLength 1
   */
  SA: string
  /**
   * Unit crate
   * @minLength 1
   */
  CR: string
  /**
   * Any Other Unit
   * @minLength 1
   */
  other?: string
}

/** Response with mapping of default units to delivered units */
export interface ModelUpdateCustomerUnitMappingsPayload {
  /**
   * Unit piece
   * @minLength 1
   */
  PCE: string
  /**
   * Unit piece
   * @minLength 1
   */
  EA: string
  /**
   * Unit meter
   * @minLength 1
   */
  MTR: string
  /**
   * Unit kilogram
   * @minLength 1
   */
  KGM: string
  /**
   * Unit piece
   * @minLength 1
   */
  C62: string
  /**
   * Unit piece
   * @minLength 1
   */
  ST: string
  /**
   * Unit bag
   * @minLength 1
   */
  BG: string
  /**
   * Unit bottle
   * @minLength 1
   */
  BO: string
  /**
   * Unit carton
   * @minLength 1
   */
  CT: string
  /**
   * Unit packset
   * @minLength 1
   */
  PA: string
  /**
   * Unit pack
   * @minLength 1
   */
  PK: string
  /**
   * Unit tray
   * @minLength 1
   */
  PU: string
  /**
   * Unit pair
   * @minLength 1
   */
  PR: string
  /**
   * Unit reel
   * @minLength 1
   */
  RL: string
  /**
   * Unit roll
   * @minLength 1
   */
  RO: string
  /**
   * Unit sack
   * @minLength 1
   */
  SA: string
  /**
   * Unit crate
   * @minLength 1
   */
  CR: string
  /**
   * Any Other Unit
   * @minLength 1
   */
  other?: string
}

export type ModelUpdateCustomerUnitMappingsData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerUnitMappingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerUsersTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetCustomerUsersStatusEnum = 'PENDING' | 'ACCEPTED' | 'EXPIRED'

export type ModelGetCustomerUsersTitleEnum1 = 'MS' | 'MR' | 'OTHER'

/** named set of permissions for given user */
export type ModelGetCustomerUsersPermissionGroupEnum = 'ADMIN' | 'SHOPPER'

export interface ModelGetCustomerUsersParams {
  /**
   * Whether to paginate results or not; defaults to true
   * @default true
   * @example false
   */
  paginated?: boolean
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** CustomerUserList */
export interface ModelGetCustomerUsersData {
  items?: ({
    /**
     * @format uuid
     * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
     */
    companyUserId: string
    /** Contains information about basic user information */
    profile: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelGetCustomerUsersTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    }
    /** Contains data about user invitation */
    invitation: {
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      createdAt: string
      status: ModelGetCustomerUsersStatusEnum
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      acceptedAt?: string
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      expiresAt?: string
    } | null
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    lastActiveAt: string
    /** True if the user is support user that cannot be deleted */
    supportUser: boolean
  } & {
    /** Contains information about basic user information */
    profile?: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelGetCustomerUsersTitleEnum1
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    } & {
      /** named set of permissions for given user */
      permissionGroup: ModelGetCustomerUsersPermissionGroupEnum
    }
  })[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

export type ModelPostCustomerUserTitleEnum = 'MS' | 'MR' | 'OTHER'

/** customer user role in the company */
export type ModelPostCustomerUserRoleInCompanyEnum =
  | 'HEAD_OF_PROCUREMENT'
  | 'PROCUREMENT_MANAGER'
  | 'IT_MANAGER'
  | 'INTERNAL_CONSUMER'
  | 'HEAD_OF_DEPARTMENT'
  | 'MANAGING_DIRECTOR'

/** named set of permissions for given user */
export type ModelPostCustomerUserPermissionGroupEnum = 'ADMIN' | 'SHOPPER'

/**
 * Validation based on templateCustomerUserId field:
 * If templateCustomerUserId is filled then permissionGroup and userGroupId cannot be filled.
 * If templateCustomerUserId is not filled then permissionGroup must be filled and userGroupId is optional.
 */
export type ModelPostCustomerUserPayload = ({
  /** Basic information of the person registering the Customer or Vendor */
  person: {
    title: ModelPostCustomerUserTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  }
  email: string
  /** user role in the company */
  roleInCompany?: string
} & {
  /** customer user role in the company */
  roleInCompany?: ModelPostCustomerUserRoleInCompanyEnum | null
} & {
  /** named set of permissions for given user */
  permissionGroup?: ModelPostCustomerUserPermissionGroupEnum
} & {
  userGroupId?: string
} & {
  /**
   * Will be included in orders created by the user.
   * Can be used by customer ERP system for internal processing.
   * @maxLength 30
   */
  additionalInformation?: string | null
}) & {
  /**
   * An existing customer user id whose system permissions, feature assignments
   * and user privileges will be copied to the newly created user.
   */
  templateCustomerUserId?: string | null
}

/** CreateCustomerUserResponse */
export interface ModelPostCustomerUserData {
  customerUserId: string
}

/**
 * Error
 * Error
 */
export type ModelPostCustomerUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetMeCustomerUserTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetMeCustomerUserStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'EXPIRED'

export type ModelGetMeCustomerUserTitleEnum1 = 'MS' | 'MR' | 'OTHER'

/** customer user role in the company */
export type ModelGetMeCustomerUserRoleInCompanyEnum =
  | 'HEAD_OF_PROCUREMENT'
  | 'PROCUREMENT_MANAGER'
  | 'IT_MANAGER'
  | 'INTERNAL_CONSUMER'
  | 'HEAD_OF_DEPARTMENT'
  | 'MANAGING_DIRECTOR'

/** named set of permissions for given user */
export type ModelGetMeCustomerUserPermissionGroupEnum = 'ADMIN' | 'SHOPPER'

/** Customer user privileges */
export type ModelGetMeCustomerUserAssignedPrivilegesEnum =
  | 'VIEW_ALL_ORDERS'
  | 'EDIT_CORE_ARTICLE_MARKERS'
  | 'EDIT_CUSTOMER_ARTICLE_NUMBERS'
  | 'EDIT_DELIVERIES'
  | 'EDIT_EXTERNAL_REFERENCES'
  | 'EDIT_PAYMENTS'
  | 'CREATE_DELIVERY_ADDRESSES'

export type ModelGetMeCustomerUserAvailableFeaturesEnum =
  | 'OCI_INTEGRATION'
  | 'SET_ORDER_NUMBER'
  | 'COCKPIT_ORDER_INTEGRATION'
  | 'VENDING_MACHINE_INTEGRATION'
  | 'NO_VENDOR_ORDERS'
  | 'ACCOUNTING_AUTOMATION'

export type ModelGetMeCustomerUserData = ({
  /**
   * @format uuid
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  companyUserId: string
  /** Contains information about basic user information */
  profile: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetMeCustomerUserTitleEnum
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  }
  /** Contains data about user invitation */
  invitation: {
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    status: ModelGetMeCustomerUserStatusEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    acceptedAt?: string
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    expiresAt?: string
  } | null
  /**
   * @format date-time
   * @example "2021-11-25T11:10:57.849+00:00"
   */
  lastActiveAt: string
  /** True if the user is support user that cannot be deleted */
  supportUser: boolean
} & {
  /** Contains information about basic user information */
  profile?: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetMeCustomerUserTitleEnum1
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  } & {
    /** customer user role in the company */
    roleInCompany?: ModelGetMeCustomerUserRoleInCompanyEnum | null
  } & {
    /** named set of permissions for given user */
    permissionGroup: ModelGetMeCustomerUserPermissionGroupEnum
  } & {
    /**
     * List of assigned user privileges
     * @uniqueItems true
     */
    assignedPrivileges?: ModelGetMeCustomerUserAssignedPrivilegesEnum[]
  } & {
    userGroupId?: string
  } & {
    /**
     * Will be included in orders created by the user.
     * Can be used by customer ERP system for internal processing.
     * @maxLength 30
     */
    additionalInformation?: string | null
  }
}) & {
  /**
   * List of conditional features enabled for this customer user
   * @uniqueItems true
   */
  availableFeatures: ModelGetMeCustomerUserAvailableFeaturesEnum[]
}

export type ModelSearchCustomerUsersTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelSearchCustomerUsersStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'EXPIRED'

export type ModelSearchCustomerUsersTitleEnum1 = 'MS' | 'MR' | 'OTHER'

/** customer user role in the company */
export type ModelSearchCustomerUsersRoleInCompanyEnum =
  | 'HEAD_OF_PROCUREMENT'
  | 'PROCUREMENT_MANAGER'
  | 'IT_MANAGER'
  | 'INTERNAL_CONSUMER'
  | 'HEAD_OF_DEPARTMENT'
  | 'MANAGING_DIRECTOR'

/** named set of permissions for given user */
export type ModelSearchCustomerUsersPermissionGroupEnum = 'ADMIN' | 'SHOPPER'

/** Customer user privileges */
export type ModelSearchCustomerUsersAssignedPrivilegesEnum =
  | 'VIEW_ALL_ORDERS'
  | 'EDIT_CORE_ARTICLE_MARKERS'
  | 'EDIT_CUSTOMER_ARTICLE_NUMBERS'
  | 'EDIT_DELIVERIES'
  | 'EDIT_EXTERNAL_REFERENCES'
  | 'EDIT_PAYMENTS'
  | 'CREATE_DELIVERY_ADDRESSES'

/** named set of permissions for given user */
export type ModelSearchCustomerUsersPermissionGroupsEnum = 'ADMIN' | 'SHOPPER'

export interface ModelSearchCustomerUsersPayload {
  term?: string
  /** @uniqueItems true */
  groupIds: string[]
  permissionGroups?: ModelSearchCustomerUsersPermissionGroupsEnum[]
}

export interface ModelSearchCustomerUsersParams {
  /** Sort field and direction (available: [`lastName,desc`, `lastName,asc`. */
  sort?: string[]
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** CustomerUserList */
export interface ModelSearchCustomerUsersData {
  items: (({
    /**
     * @format uuid
     * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
     */
    companyUserId: string
    /** Contains information about basic user information */
    profile: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelSearchCustomerUsersTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    }
    /** Contains data about user invitation */
    invitation: {
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      createdAt: string
      status: ModelSearchCustomerUsersStatusEnum
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      acceptedAt?: string
      /**
       * @format date-time
       * @example "2021-11-25T11:10:57.849+00:00"
       */
      expiresAt?: string
    } | null
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    lastActiveAt: string
    /** True if the user is support user that cannot be deleted */
    supportUser: boolean
  } & {
    /** Contains information about basic user information */
    profile?: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelSearchCustomerUsersTitleEnum1
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    } & {
      /** customer user role in the company */
      roleInCompany?: ModelSearchCustomerUsersRoleInCompanyEnum | null
    } & {
      /** named set of permissions for given user */
      permissionGroup: ModelSearchCustomerUsersPermissionGroupEnum
    } & {
      /**
       * List of assigned user privileges
       * @uniqueItems true
       */
      assignedPrivileges?: ModelSearchCustomerUsersAssignedPrivilegesEnum[]
    } & {
      userGroupId?: string
    } & {
      /**
       * Will be included in orders created by the user.
       * Can be used by customer ERP system for internal processing.
       * @maxLength 30
       */
      additionalInformation?: string | null
    }
  }) & {
    groupId?: string | null
    groupName?: string | null
    /** Features to which the customer user is assigned */
    assignedFeatures: {
      accountingValues: number
      generalLedgers: number
      approvalFlows: {
        assigned: number
        mainApprover: number
        deputyApprover: number
      }
      catalogViews: number
      priceRestrictions: number
      cartRestrictions: number
      articleLists: number
      deliveryAddresses: number
      budgets: {
        /** @example 0 */
        accounting: number
        /** @example 0 */
        individual: number
      }
    }
  })[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchCustomerUsersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** User groups that shoppers are assigned to */
export type ModelGetUserGroupsForCustomer2Data = {
  id: string
  name: string
  description: string
}[]

export type ModelPutCustomerUserTitleEnum = 'MS' | 'MR' | 'OTHER'

/** customer user role in the company */
export type ModelPutCustomerUserRoleInCompanyEnum =
  | 'HEAD_OF_PROCUREMENT'
  | 'PROCUREMENT_MANAGER'
  | 'IT_MANAGER'
  | 'INTERNAL_CONSUMER'
  | 'HEAD_OF_DEPARTMENT'
  | 'MANAGING_DIRECTOR'

/** named set of permissions for given user */
export type ModelPutCustomerUserPermissionGroupEnum = 'ADMIN' | 'SHOPPER'

/** Contains information for customer user profile update */
export type ModelPutCustomerUserPayload = ({
  userGroupId?: string
} & {
  /**
   * Will be included in orders created by the user.
   * Can be used by customer ERP system for internal processing.
   * @maxLength 30
   */
  additionalInformation?: string | null
}) & {
  /** Basic information of the person registering the Customer or Vendor */
  person: {
    title: ModelPutCustomerUserTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  }
  /** customer user role in the company */
  roleInCompany?: ModelPutCustomerUserRoleInCompanyEnum | null
  /** named set of permissions for given user */
  permissionGroup: ModelPutCustomerUserPermissionGroupEnum
  /**
   * User email address
   * @format email
   * @example "user@company.com"
   */
  email: string
}

export type ModelPutCustomerUserData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelPutCustomerUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerUserTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetCustomerUserStatusEnum = 'PENDING' | 'ACCEPTED' | 'EXPIRED'

export type ModelGetCustomerUserTitleEnum1 = 'MS' | 'MR' | 'OTHER'

/** customer user role in the company */
export type ModelGetCustomerUserRoleInCompanyEnum =
  | 'HEAD_OF_PROCUREMENT'
  | 'PROCUREMENT_MANAGER'
  | 'IT_MANAGER'
  | 'INTERNAL_CONSUMER'
  | 'HEAD_OF_DEPARTMENT'
  | 'MANAGING_DIRECTOR'

/** named set of permissions for given user */
export type ModelGetCustomerUserPermissionGroupEnum = 'ADMIN' | 'SHOPPER'

/** Customer user privileges */
export type ModelGetCustomerUserAssignedPrivilegesEnum =
  | 'VIEW_ALL_ORDERS'
  | 'EDIT_CORE_ARTICLE_MARKERS'
  | 'EDIT_CUSTOMER_ARTICLE_NUMBERS'
  | 'EDIT_DELIVERIES'
  | 'EDIT_EXTERNAL_REFERENCES'
  | 'EDIT_PAYMENTS'
  | 'CREATE_DELIVERY_ADDRESSES'

export type ModelGetCustomerUserStateEnum =
  | 'DISABLED'
  | 'ASSIGNED'
  | 'UNASSIGNED'

export type ModelGetCustomerUserData = ({
  /**
   * @format uuid
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  companyUserId: string
  /** Contains information about basic user information */
  profile: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetCustomerUserTitleEnum
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  }
  /** Contains data about user invitation */
  invitation: {
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    status: ModelGetCustomerUserStatusEnum
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    acceptedAt?: string
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    expiresAt?: string
  } | null
  /**
   * @format date-time
   * @example "2021-11-25T11:10:57.849+00:00"
   */
  lastActiveAt: string
  /** True if the user is support user that cannot be deleted */
  supportUser: boolean
} & {
  /** Contains information about basic user information */
  profile?: {
    /** Basic information of the person registering the Customer or Vendor */
    person: {
      title: ModelGetCustomerUserTitleEnum1
      firstName: string
      lastName: string
      phoneNumber: string
    }
    email: string
    /** user role in the company */
    roleInCompany?: string
  } & {
    /** customer user role in the company */
    roleInCompany?: ModelGetCustomerUserRoleInCompanyEnum | null
  } & {
    /** named set of permissions for given user */
    permissionGroup: ModelGetCustomerUserPermissionGroupEnum
  } & {
    /**
     * List of assigned user privileges
     * @uniqueItems true
     */
    assignedPrivileges?: ModelGetCustomerUserAssignedPrivilegesEnum[]
  } & {
    userGroupId?: string
  } & {
    /**
     * Will be included in orders created by the user.
     * Can be used by customer ERP system for internal processing.
     * @maxLength 30
     */
    additionalInformation?: string | null
  }
}) & {
  /** Features to which the customer user is assigned */
  assignedFeatures: {
    userGroup: {
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      id: string
      /** @example "Example name" */
      name: string
    } | null
    catalogView: {
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      id: string
      /** @example "Example name" */
      name: string
      /** @example true */
      isDefault: boolean
    } | null
    priceRestriction: {
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      id: string
      /** @example "Example name" */
      name: string
      /** @example true */
      isDefault: boolean
    } | null
    cartRestriction: {
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      id: string
      /** @example "Example name" */
      name: string
      /** @example true */
      isDefault: boolean
    } | null
    approvalFlows: {
      assigned: number
      mainApprover: number
      deputyApprover: number
    }
    accountingValues: number
    generalLedgers: number
    articleLists: number
    deliveryAddresses: number
    defaultAccountValue?: {
      /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
      id: string
      /** @example "Example name" */
      name: string
      /** @example "New account type for product category1" */
      description: string
      state: ModelGetCustomerUserStateEnum
    } | null
    budgets: {
      /** @example 0 */
      accounting: number
      /** @example 0 */
      individual: number
    } | null
  }
  /** Defines which customer user attributes can be edited by the current user */
  editOptions: {
    /** If email address can be edited */
    email: boolean
  }
}

/**
 * Error
 * Error
 */
export type ModelGetCustomerUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerUserData = any

/** Error */
export type ModelDeleteCustomerUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPostCustomerUserResendInvitationData = any

/**
 * Error
 * Error
 */
export type ModelPostCustomerUserResendInvitationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** customer user role in the company */
export type ModelGetCustomerUserDeletionBlockersTypeEnum =
  'USER_IS_APPROVER_IN_APPROVAL_FLOW'

/**
 * CustomerUserDeletionBlockers
 * customer user deletion blockers
 */
export interface ModelGetCustomerUserDeletionBlockersData {
  items: {
    /** customer user role in the company */
    type: ModelGetCustomerUserDeletionBlockersTypeEnum
    entityNames?: string[]
  }[]
}

/** Error */
export type ModelGetCustomerUserDeletionBlockersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerUserAssignedApprovalFlowsData {
  approvalFlows: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    id: string
    /** @example "Example name" */
    name: string
  }[]
}

/** Error */
export type ModelGetCustomerUserAssignedApprovalFlowsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerUserApprovalFlowsAsApproverData {
  mainApproverApprovalFlows: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    id: string
    /** @example "Example name" */
    name: string
  }[]
  deputyApproverApprovalFlows: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    id: string
    /** @example "Example name" */
    name: string
  }[]
}

/** Error */
export type ModelGetCustomerUserApprovalFlowsAsApproverError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with customer account types and values */
export interface ModelGetCustomerUserAccountTypesAndValuesData {
  /** List of account types */
  accountTypes: {
    /**
     * Account Type ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Account Type prefix
     * @example "KHY"
     */
    prefix: string
    /**
     * Account Type description
     * @example "New account type for product category1"
     */
    description: string
    /**
     * Account Type enabled flag
     * @example true
     */
    enabled: boolean
  }[]
  /**
   * Key value pairs where the key is account type and the value is account value
   * @example {"11aa7d85-dd51-4191-8c26-6d446d48ef45":[{"id":"619b1a5d-688c-48d4-b506-ec8f818ef5a0","number":123434,"description":"New account type value for product category1"},{"id":"8460897c-b05a-49cd-b8b3-39f6b4062119","number":32183,"description":"New account type value for product category2"}],"ea674491-6699-46c0-9fd5-54720710e18f":[{"id":"8b98780a-309a-40d6-a032-10dc5edc4995","number":559823,"description":"New account type value for product category3"}]}
   */
  accountValuesByType: Record<
    string,
    {
      /**
       * Account Type Value ID
       * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
       */
      id: string
      /**
       * Account Type Value number
       * @example "123434"
       */
      number: string
      /**
       * Account Type Value description
       * @example "New account type value for product category1"
       */
      description: string
      /**
       * Account Value enabled flag
       * @example true
       */
      enabled: boolean
    }[]
  >
}

/** Error */
export type ModelGetCustomerUserAccountTypesAndValuesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelUpdateCustomerUserDefaultAccountValuePayload {
  /**
   * Account value ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  id?: string | null
}

export type ModelUpdateCustomerUserDefaultAccountValueData = any

/** Error */
export type ModelUpdateCustomerUserDefaultAccountValueError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Response with list of assigned general ledgers */
export interface ModelGetCustomerUserGeneralLedgersData {
  /** List of assigned general ledgers */
  generalLedgers: {
    /**
     * General Ledger ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Unique General Ledger number
     * @example "11081"
     */
    number: string
    /**
     * General ledger description
     * @example "Ledger name 01"
     */
    description: string
  }[]
}

/** Error */
export type ModelGetCustomerUserGeneralLedgersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerUserDeliveryAddressesData {
  items: {
    /** @example "2d56f5d7-89e1-4df6-8224-c3aa0a26980b" */
    id: string
    /** Delivery address of an order */
    deliveryAddress: {
      /**
       * Name of the company
       * @example "Company GmbH"
       */
      companyName: string
      /** @example "FAC991939931" */
      factoryIdentification?: string
      /** Basic information about an Address */
      address: {
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
      }
      /**
       * User-assigned label
       * @example "Second Office"
       */
      label?: string
    }
  }[]
}

/** Error */
export type ModelGetCustomerUserDeliveryAddressesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerUserArticleListsData {
  items: {
    /** @example "b2aa3d67-48c9-432e-8c44-869f1d20173e" */
    id: string
    /** @example "Example name" */
    name: string
  }[]
}

/** Error */
export type ModelGetCustomerUserArticleListsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCustomerUserGroupsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,asc`, `name,desc`]).
   * Default sorting: `name,asc`.
   */
  sort?: string[]
}

/** CustomerUserGroups */
export interface ModelGetCustomerUserGroupsData {
  userGroups: {
    id: string
    name: string
    description: string
    /** @min 0 */
    membersCount: number
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    createdAt: string
    /**
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    updatedAt: string
  }[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

export interface ModelPostCustomerUserGroupPayload {
  name: string
  description: string
}

export type ModelPostCustomerUserGroupData = any

/** Error */
export type ModelPostCustomerUserGroupError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** User groups that a customer user can be assigned to */
export interface ModelGetUserGroupsNamesForCustomerData {
  items: {
    id: string
    name: string
  }[]
}

export interface ModelPutCustomerUserGroupPayload {
  name: string
  description: string
}

export type ModelPutCustomerUserGroupData = any

/**
 * Error
 * Error
 */
export type ModelPutCustomerUserGroupError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerUserGroupData = any

/** Error */
export type ModelDeleteCustomerUserGroupError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** CustomerUserGroupAssignments */
export interface ModelGetCustomerUserGroupAssignmentsData {
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** CustomerUserGroupAssignmentEdit */
export interface ModelPutCustomerUserGroupAssignmentsPayload {
  customerUserIds: string[]
}

export type ModelPutCustomerUserGroupAssignmentsData = any

/**
 * Error
 * Error
 */
export type ModelPutCustomerUserGroupAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerContactsTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetCustomerContactsTitleEnum1 = 'MS' | 'MR' | 'OTHER'

export type ModelGetCustomerContactsTitleEnum2 = 'MS' | 'MR' | 'OTHER'

export type ModelGetCustomerContactsTitleEnum3 = 'MS' | 'MR' | 'OTHER'

/** A map of contactType to contactDetails */
export interface ModelGetCustomerContactsData {
  /** Customer contact data */
  main?:
    | ({
        title: ModelGetCustomerContactsTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  technicalIntegration?:
    | ({
        title: ModelGetCustomerContactsTitleEnum1
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  billingAccounting?:
    | ({
        title: ModelGetCustomerContactsTitleEnum2
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  dataPrivacy?:
    | ({
        title: ModelGetCustomerContactsTitleEnum3
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
}

/** Error */
export type ModelGetCustomerContactsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelPatchCustomerContactsTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelPatchCustomerContactsTitleEnum1 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchCustomerContactsTitleEnum2 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchCustomerContactsTitleEnum3 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchCustomerContactsTitleEnum4 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchCustomerContactsTitleEnum5 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchCustomerContactsTitleEnum6 = 'MS' | 'MR' | 'OTHER'

export type ModelPatchCustomerContactsTitleEnum7 = 'MS' | 'MR' | 'OTHER'

/** A map of contactType to contactDetails */
export interface ModelPatchCustomerContactsPayload {
  /** Customer contact data */
  main?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum4
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  technicalIntegration?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum5
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  billingAccounting?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum6
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  dataPrivacy?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum7
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
}

/** A map of contactType to contactDetails */
export interface ModelPatchCustomerContactsData {
  /** Customer contact data */
  main?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  technicalIntegration?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum1
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  billingAccounting?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum2
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /** Customer contact data */
  dataPrivacy?:
    | ({
        title: ModelPatchCustomerContactsTitleEnum3
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
}

/**
 * Error
 * Error
 */
export type ModelPatchCustomerContactsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerContactByTypeTitleEnum = 'MS' | 'MR' | 'OTHER'

/**
 * Customer contact data
 * @example {"title":"MS","firstName":"Elise","lastName":"Tiedermann","phoneNumber":"+99 000010001","email":"elise.tiedermann@formonix.de"}
 */
export type ModelGetCustomerContactByTypeData = {
  title: ModelGetCustomerContactByTypeTitleEnum
  firstName: string
  lastName: string
  phoneNumber: string
} & {
  email: string
}

/** Error */
export type ModelGetCustomerContactByTypeError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** contact types for customer */
export type ModelGetCustomerContactByTypeParamsContactTypeEnum =
  | 'main'
  | 'technicalIntegration'
  | 'billingAccounting'
  | 'dataPrivacy'

/** contact types for customer */
export type ModelGetCustomerContactByTypeParamsEnum =
  | 'main'
  | 'technicalIntegration'
  | 'billingAccounting'
  | 'dataPrivacy'

/** Version of eClass specification */
export type ModelGetCustomerOciConfigurationEclassVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Parameters of Customer OCI integration */
export interface ModelGetCustomerOciConfigurationData {
  /**
   * OCI catalog URL for ERP system
   * @format url
   * @example "https://api-nextgen-dev.simplesystem.com/customer-oci-session/login"
   */
  url: string
  /** Version of eClass specification */
  eclassVersion: ModelGetCustomerOciConfigurationEclassVersionEnum
}

/** Version of eClass specification */
export type ModelPatchCustomerOciConfigurationEclassVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

/** Parameters of Customer OCI integration */
export interface ModelPatchCustomerOciConfigurationPayload {
  /** Version of eClass specification */
  eclassVersion: ModelPatchCustomerOciConfigurationEclassVersionEnum
}

export type ModelPatchCustomerOciConfigurationData = any

/** Error */
export type ModelPatchCustomerOciConfigurationError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** List of customer's OCI users */
export type ModelGetCustomerOciUsersData = ({
  /**
   * @minLength 1
   * @example "oci_technical_user_1"
   */
  username: string
  /**
   * @format password
   * @minLength 8
   * @example "S3cure!!!"
   */
  password: string
} & {
  /**
   * OCI user ID
   * @example "f081d08f-5a26-406e-9293-b4043d4a4751"
   */
  id: string
})[]

/** OCI user data */
export interface ModelPostCustomerOciUserPayload {
  /**
   * @minLength 1
   * @example "oci_technical_user_1"
   */
  username: string
  /**
   * @format password
   * @minLength 8
   * @example "S3cure!!!"
   */
  password: string
}

export interface ModelPostCustomerOciUserData {
  ociUserId: string
}

/**
 * Error
 * Error
 */
export type ModelPostCustomerOciUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** OCI user */
export type ModelGetCustomerOciUserData = {
  /**
   * @minLength 1
   * @example "oci_technical_user_1"
   */
  username: string
  /**
   * @format password
   * @minLength 8
   * @example "S3cure!!!"
   */
  password: string
} & {
  /**
   * OCI user ID
   * @example "f081d08f-5a26-406e-9293-b4043d4a4751"
   */
  id: string
}

/** OCI user data */
export interface ModelPutCustomerOciUserPayload {
  /**
   * @minLength 1
   * @example "oci_technical_user_1"
   */
  username: string
  /**
   * @format password
   * @minLength 8
   * @example "S3cure!!!"
   */
  password: string
}

export type ModelPutCustomerOciUserData = any

/** Error */
export type ModelPutCustomerOciUserError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteCustomerOciUserData = any

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum1 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum2 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum3 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum4 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum5 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum6 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum7 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum8 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum9 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum10 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelPostOciFieldsMappingsTypeEnum11 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci fields mappings settings */
export type ModelPostOciFieldsMappingsPayload = {
  /** Matgroup oci field mapping */
  matgroup: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** LongText oci field mapping */
  longText: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum1
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Matnr oci field mapping */
  matnr: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum2
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field1 oci field mapping */
  cust_field1: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum3
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field2 oci field mapping */
  cust_field2: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum4
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field3 oci field mapping */
  cust_field3: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum5
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field4 oci field mapping */
  cust_field4: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum6
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field5 oci field mapping */
  cust_field5: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum7
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Contract oci field mapping */
  contract: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum8
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Contract_item oci field mapping */
  contract_item: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum9
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Unit oci field mapping */
  unit: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum10
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Leadtime oci field mapping */
  leadtime: {
    /** Oci field mapping type */
    type: ModelPostOciFieldsMappingsTypeEnum11
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
} & {
  /**
   * Vendor ID
   * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
   */
  vendorId: string
  /**
   * Name of the oci fields mappings
   * @example "Oci field mapping name"
   */
  name: string
}

export type ModelPostOciFieldsMappingsData = any

/**
 * Error
 * Error
 */
export type ModelPostOciFieldsMappingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum1 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum2 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum3 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum4 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum5 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum6 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum7 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum8 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum9 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum10 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelGetOciFieldsMappingTypeEnum11 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci fields mappings */
export type ModelGetOciFieldsMappingData = {
  /** Matgroup oci field mapping */
  matgroup: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** LongText oci field mapping */
  longText: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum1
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Matnr oci field mapping */
  matnr: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum2
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field1 oci field mapping */
  cust_field1: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum3
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field2 oci field mapping */
  cust_field2: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum4
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field3 oci field mapping */
  cust_field3: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum5
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field4 oci field mapping */
  cust_field4: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum6
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field5 oci field mapping */
  cust_field5: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum7
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Contract oci field mapping */
  contract: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum8
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Contract_item oci field mapping */
  contract_item: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum9
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Unit oci field mapping */
  unit: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum10
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Leadtime oci field mapping */
  leadtime: {
    /** Oci field mapping type */
    type: ModelGetOciFieldsMappingTypeEnum11
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
} & {
  /**
   * Oci fields mapping ID
   * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
   */
  id: string
  /**
   * Name of the oci fields mapping
   * @example "Oci fields mapping name"
   */
  name: string
  /**
   * date when the oci fields mapping was created
   * @format date-time
   * @example "2023-07-21T17:32:28.682Z"
   */
  createdAt: string
  /**
   * State if the oci fields mapping is default mapping
   * @example true
   */
  isDefault: boolean
  /**
   * Vendor ID
   * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
   */
  vendorId?: string
  /**
   * Name of the vendor
   * @example "Shire GmbH"
   */
  vendorName?: string
  /**
   * Vat id
   * @example "DE999999999"
   */
  vatId?: string
  /**
   * Customer specific vendor number
   * @example "ACM"
   */
  customerSpecificVendorNumber?: string
}

/** Error */
export type ModelGetOciFieldsMappingError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum1 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum2 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum3 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum4 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum5 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum6 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum7 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum8 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum9 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum10 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci field mapping type */
export type ModelUpdateOciFieldsMappingsTypeEnum11 =
  | 'EMPTY'
  | 'BLOCKED'
  | 'CUSTOM'
  | 'ECLASS_NUMBER'
  | 'GENERAL_LEDGER'
  | 'LONG_DESCRIPTION'
  | 'CUSTOM_ARTICLE_NUMBER'
  | 'ITEM_COMMENT'
  | 'ARTICLE_UNIT'
  | 'ARTICLE_LEADTIME'
  | 'CUST_FIELD_1'
  | 'CUST_FIELD_2'
  | 'CUST_FIELD_3'
  | 'CUST_FIELD_4'
  | 'CUST_FIELD_5'
  | 'CUSTOMS_TARIFF_NUMBER'
  | 'COUNTRY_OF_ORIGIN'
  | 'WEIGHT'

/** Oci fields mappings settings */
export type ModelUpdateOciFieldsMappingsPayload = {
  /** Matgroup oci field mapping */
  matgroup: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** LongText oci field mapping */
  longText: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum1
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Matnr oci field mapping */
  matnr: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum2
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field1 oci field mapping */
  cust_field1: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum3
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field2 oci field mapping */
  cust_field2: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum4
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field3 oci field mapping */
  cust_field3: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum5
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field4 oci field mapping */
  cust_field4: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum6
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Cust_field5 oci field mapping */
  cust_field5: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum7
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Contract oci field mapping */
  contract: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum8
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Contract_item oci field mapping */
  contract_item: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum9
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Unit oci field mapping */
  unit: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum10
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
  /** Leadtime oci field mapping */
  leadtime: {
    /** Oci field mapping type */
    type: ModelUpdateOciFieldsMappingsTypeEnum11
    /**
     * Custom value of oci field mapping
     * @example "CUSTOM_VALUE"
     */
    customValue?: string
  }
} & {
  /**
   * Oci field mapping name
   * @example "Oci field mapping name"
   */
  name: string
}

export type ModelUpdateOciFieldsMappingsData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateOciFieldsMappingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteOciFieldsMappingData = any

/**
 * Error
 * Error
 */
export type ModelDeleteOciFieldsMappingError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Search parameters for oci fields mappings */
export interface ModelGetCustomerOciFieldsMappingsPayload {
  /**
   * Search term
   * @example "Zotware"
   */
  term?: string
}

export interface ModelGetCustomerOciFieldsMappingsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,desc`, `name,asc`, createdAt,desc, createdAt,asc]).
   * Default sorting: `name,desc`.
   */
  sort?: string[]
}

/** customer's oci fields mappings settings */
export interface ModelGetCustomerOciFieldsMappingsData {
  /** List of oci fields mappings */
  items: {
    /**
     * Oci fields mapping ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name of the oci fields mapping
     * @example "Oci fields mapping name"
     */
    name: string
    /**
     * date when the oci fields mapping was created
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    createdAt: string
    /**
     * State if the oci fields mapping is default mapping
     * @example true
     */
    isDefault: boolean
    /**
     * Count of all custom oci mapping fields
     * @format int32
     * @example 13
     */
    customOciValuesCount: number
    /**
     * Count of all blocked oci mapping fields
     * @format int32
     * @example 13
     */
    blockedOciFieldsCount: number
    /**
     * Vendor ID
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    vendorId?: string
    /**
     * Name of the vendor
     * @example "Shire GmbH"
     */
    vendorName?: string
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelGetCustomerOciFieldsMappingsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Search parameters for oci fields mappings vendors listing */
export interface ModelSearchCustomerOciFieldsMappingsMyVendorsPayload {
  /**
   * Search term
   * @example "Zotware"
   */
  term?: string
}

export interface ModelSearchCustomerOciFieldsMappingsMyVendorsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** My vendors list for customer */
export interface ModelSearchCustomerOciFieldsMappingsMyVendorsData {
  items: {
    /**
     * Vendor ID
     * @example "e148ad8d-d686-4534-8304-c6b2907dbea2"
     */
    vendorId: string
    /**
     * Vendor company name
     * @example "ACME Ltd."
     */
    name: string
    /**
     * VAT ID of vendor company
     * @example "DE123456789"
     */
    vatId: string
    /**
     * Customer specific vendor number
     * @example "ACM"
     */
    customerSpecificVendorNumber: string
    alreadyAssigned: boolean
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  defaultVendorNumber?: string
}

/** Error */
export type ModelSearchCustomerOciFieldsMappingsMyVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Login request body */
export interface ModelLoginPayload {
  /**
   * The login identifier of the user. The login identifier can be either the email or the username.
   * @example "Customer2@local.localhost"
   */
  loginId: string
  /**
   * User password.
   * @format password
   * @minLength 1
   * @maxLength 256
   * @example "secret!23"
   */
  password: string
}

/** Response containing JWT access and refresh token */
export interface ModelLoginData {
  /** New JWT token */
  token: string
  /** Refresh token to be used for JWT refresh */
  refreshToken: string
}

/** Error */
export type ModelLoginError =
  | {
      /**
       * Message providing details of the error
       * @example "Validation failure"
       */
      message?: string
      /** List of error detail for each field, if the error is related to a particular form field otherwise null */
      details?: {
        /**
         * Name of the field that violates the constraint
         * @example "person.firstName"
         */
        field?: string
        /**
         * Error message that states the reason why the attached field is invalid
         * @example "must not be blank"
         */
        message?: string
      }[]
      /** List of additional data information that can be provided */
      additionalData?: {
        /**
         * Name of the property
         * @example "contactPerson"
         */
        label?: string
        /**
         * Value of the property
         * @example "example@gmail.com"
         */
        value?: string
      }[]
      /**
       * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
       * @format date-time
       * @example "2021-10-25T11:10:57.849+00:00"
       */
      timestamp?: string
    }
  | {
      /** @example "4906cd7b-2810-4671-963b-81be91d2ad25" */
      userId: string
    }

/** Logout request body */
export interface ModelLogoutPayload {
  /** Refresh token to be invalidated */
  refreshToken: string
}

export type ModelLogoutData = any

/** JWT token refresh request body */
export interface ModelJwtRefreshPayload {
  /** Refresh token to be used to get new JWT */
  refreshToken: string
}

/** Response containing JWT access and refresh token */
export interface ModelJwtRefreshData {
  /** New JWT token */
  token: string
  /** Refresh token to be used for JWT refresh */
  refreshToken: string
}

/** PasswordResetRequest body */
export interface ModelInitiatePasswordResetPayload {
  /**
   * loginId which is also the email address the password reset email will be sent to
   * @format email
   */
  loginId: string
}

export type ModelInitiatePasswordResetData = any

/** PasswordReset body */
export interface ModelPasswordResetPayload {
  /**
   * New user password
   * @format password
   * @example "secret!23"
   */
  newPassword: string
}

/** Response containing JWT access and refresh token */
export interface ModelPasswordResetData {
  /** New JWT token */
  token: string
  /** Refresh token to be used for JWT refresh */
  refreshToken: string
}

/**
 * Error
 * Error
 */
export type ModelPasswordResetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelLoginWithGetParams {
  /**
   * The login identifier of the user
   * @example "Customer2"
   */
  USERNAME: string
  /**
   * User password.
   * @format password
   * @example "secret!23"
   */
  PASSWORD: string
  /**
   * Language code
   * @example "de"
   */
  LANCODE?: string
  /**
   * Hook URL for OCI cart transfer
   * @example "https://example.com/oci-transferred-cart"
   */
  HOOK_URL: string
  /**
   * Customer Id
   * @format uuid
   * @example "f712cd86-0428-4985-8d8d-cfb98cafa866"
   */
  customerId: string
}

/** Login request body */
export interface ModelLoginWithPostPayload {
  /**
   * The login identifier of the user. The login identifier can be either the email or the username.
   * @example "Customer2@local.localhost"
   */
  USERNAME: string
  /**
   * User password.
   * @format password
   * @example "secret!23"
   */
  PASSWORD: string
  /**
   * Language code
   * @example "de"
   */
  LANCODE?: string
  /**
   * Language code
   * @deprecated
   * @example "de"
   */
  Lancode?: string
  /**
   * Hook URL for OCI cart transfer
   * @example "https://example.com/oci-transferred-cart"
   */
  HOOK_URL: string
}

/** Basic user data and information about assigned customers and vendors */
export interface ModelGetOciUserOfCustomerOciSessionData {
  /**
   * User ID (the same as in identity provider)
   * @example "ef1b8af4-f511-4fe6-9b6b-0a548a9819fa"
   */
  id: string
  /**
   * Username (used to log-in to the system)
   * @example "user@example.com"
   */
  username: string
  /** List of vendors assigned to user, first is the default (if present). */
  vendors: ({
    /**
     * ID of vendor/customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Vendor/customer company name
     * @example "ACME Ltd."
     */
    name: string
  } & {
    /**
     * company VAT-ID
     * @example "FR12345678901"
     */
    vatId?: string
    /** Basic information about an Address */
    address?: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
  })[]
  /** List of customers assigned to user, first is the default (if present). */
  customers: ({
    /**
     * ID of vendor/customer
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Vendor/customer company name
     * @example "ACME Ltd."
     */
    name: string
  } & {
    /**
     * company VAT-ID
     * @example "FR12345678901"
     */
    vatId?: string
    /** Basic information about an Address */
    address?: {
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
    }
  })[]
  /** User's settings */
  settings: {
    /** @example "en" */
    locale: string
    viewSettings?: Record<string, any>
  }
}

export interface ModelPatchCustomerOciSessionSettingsPayload {
  /**
   * UserSettingsUpdate
   * User's settings
   */
  settings: {
    /** @example "en" */
    locale?: string | null
    viewSettings?: Record<string, any>
  }
}

export type ModelPatchCustomerOciSessionSettingsData = any

export type ModelGetOciCustomerProfileBusinessCategoryEnum =
  | 'AGRICULTURE'
  | 'CONSTRUCTION'
  | 'CHEMISTRY_RAW_MATERIALS'
  | 'SERVICES_MANUFACTURING'
  | 'E_COMMERCE'
  | 'ENERGY_ENVIRONMENT'
  | 'FINANCE_INSURANCE_REAL_ESTATE'
  | 'LEISURE'
  | 'SOCIETY'
  | 'TRADE'
  | 'IT_TELECOMMUNICATIONS'
  | 'RETAIL'
  | 'PHARMACY'
  | 'SPORTS'
  | 'ELECTRONICS'
  | 'TOURISM_TRAVEL'
  | 'MARKETING'
  | 'POLITICS'

/** Profile of the company contains data (legal info, contact details, logos) presented to other users of the system */
export type ModelGetOciCustomerProfileData = {
  /** Basic information about an Address */
  invoiceAddress?: {
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
  } & {
    /** Company name for invoices Should be marked as required when deprecated values are removed. */
    companyName?: string
    /** Invoice recipient Should be marked as required when deprecated values are removed. */
    invoiceRecipient?: string
    /** Invoice text/field used for booking Should be marked as required when deprecated values are removed */
    invoiceBookingText?: string
  }
  bigLogoUrl?: string
} & {
  /** Company public profile */
  businessInfo: {
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryOfOperation: string
    /** @default false */
    publiclyNamed: boolean
    /** @format uri */
    websiteUrl?: string
    /** @format uri */
    termsOfBusinessUrl?: string
    /**
     * ISO 4217 currency code
     * @pattern ^[A-Z]{3}$
     * @example "EUR"
     */
    currency?: string
  } & {
    businessCategory?: ModelGetOciCustomerProfileBusinessCategoryEnum
  }
  /** Common information of the company being registered as either Customer or Vendor */
  companyRegistration: {
    name: string
    /**
     * Country code in ISO 3166-1 alfa-2
     * @example "DE"
     */
    countryCode: string
    companyRegistrationNumber?: string
    /** @example "München" */
    cityCourt?: string
    vatId: string
    /**
     * Name of the street
     * @example "Bodenseestraße"
     */
    streetName: string
    /**
     * Number of the street, building or house
     * @example "21A"
     */
    streetNumber: string
    /**
     * Post code
     * @example 81241
     */
    postalCode: string
    /**
     * Name of the city
     * @example "München"
     */
    city: string
  } & {
    humanReadableId?: string
  }
}

/** Version of eClass specification */
export type ModelGetCustomerOciCartVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelGetCustomerOciCartSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelGetCustomerOciCartValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelGetCustomerOciCartTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** Version of eClass specification */
export type ModelGetCustomerOciCartVersionEnum1 = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelGetCustomerOciCartSourceEnum1 =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelGetCustomerOciCartValueEnum1 =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelGetCustomerOciCartTypeEnum1 = 'CATALOG' | 'PRICE_LIST'

/** Content of the shopping cart */
export interface ModelGetCustomerOciCartData {
  /** a batch of cart items per vendor */
  items: {
    /** Basic data of customer or vendor */
    vendor: {
      /**
       * ID of vendor/customer
       * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
       */
      id: string
      /**
       * Vendor/customer company name
       * @example "ACME Ltd."
       */
      name: string
    }
    /** List of items that belong to the same vendor */
    items: {
      /**
       * ID of the cart item
       * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
       */
      id: string
      /** A single search result */
      articleData: {
        /** Article information */
        article: {
          /**
           * Article ID
           * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
           */
          id: string
          /**
           * Article name
           * @example "Drilling machine"
           */
          name: string
          /**
           * Article number (Vendor’s unique article number)
           * @example "ABC123456"
           */
          number: string
          /**
           * EAN number
           * @example 12345678
           */
          ean: string
          eclass?: {
            /** Version of eClass specification */
            version: ModelGetCustomerOciCartVersionEnum
            /** eClass value formatted with dashes between groups ("12-34-56-78") */
            code?: string | null
            /**
             * eClass name
             * @example "Catering machinery"
             */
            name?: string | null
          }
          /**
           * Article number used in customer systems
           * @example "VABC123456"
           */
          customerArticleNumber: string
          /**
           * Article number used by manufacturer
           * @example "ABC123"
           */
          manufacturerArticleNumber?: string
          /**
           * Manufacturer name
           * @example "ACME Inc."
           */
          manufacturer: string
          /**
           * Maximum delivery time in days declared by vendor
           * @deprecated
           * @min 0
           * @max 999999
           * @example 3
           */
          deliveryTime: number
          /** Delivery time details */
          deliveryInfo: {
            /**
             * Maximum delivery time in days declared by vendor
             * @min 0
             * @example 3
             */
            days: number
            source: ModelGetCustomerOciCartSourceEnum
            /**
             * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          /** Stock level info */
          stockInfo: {
            value: ModelGetCustomerOciCartValueEnum
            /**
             * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          minimumOrder?: number
          quantityInterval?: number
          contentUnit?: string
          /** @example 0.001 */
          contentAmount?: string
          /** @format double */
          tax: number
          /** Pricing, order unit and constraints on quantity */
          orderOptions?: {
            /** Flag describing if this article is restricted from adding to the cart */
            restricted?: boolean
            /**
             * Article quantity for which price (pricePerOrderUnit) is defined
             * @example 0.001
             */
            priceQuantity: string
            /** Prices of the article */
            pricing: {
              /** @example 0.001 */
              quantity?: string
              /** Amount of money */
              price?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
              /** Pricing source information, returned only for vendor preview */
              source?: {
                type: ModelGetCustomerOciCartTypeEnum
                priceListFilename?: string | null
              }
            }[]
          }
          coreArticle: boolean
          closedMarketplace: boolean
        } & {
          /**
           * URL to the thumbnail of the article
           * @format url
           * @example "https://simplesystem.com/images/1234.jpg"
           */
          thumbnailUrl?: string
          /** Unit in which the article is sold */
          orderUnit: string
          /** @default false */
          isIncludedInArticleList: boolean
          /** Explanation of search score calculation */
          explanation?: string
        }
        /** Vendor information */
        vendor: {
          /**
           * Vendor ID
           * @example "V12345"
           */
          id: string
          /**
           * Name of the vendor
           * @example "Hoffmann Group"
           */
          name: string
        }
      }
      /**
       * If true, it is impossible to change item quantity
       * @example false
       */
      immutable: boolean
      /**
       * @format int32
       * @min 1
       */
      quantity: number
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Amount of money */
      priceUsed: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Shopper's additional texts on some order positions
       * @example "for Machine 4711"
       */
      itemComment?: string
    }[]
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Message from vendor
     * @example "Deliveries will be delayed..."
     */
    vendorCartInfo?: string
  }[]
  /** Amount of money */
  total: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * List of IDs of articles that are no longer available
   * @deprecated
   */
  unavailableArticleIds?: string[]
  /** List of articles that are no longer available */
  unavailableArticles?: {
    /**
     * ID of the cart item
     * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
     */
    id: string
    /** A single search result */
    articleData: {
      /** Article information */
      article: {
        /**
         * Article ID
         * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
         */
        id: string
        /**
         * Article name
         * @example "Drilling machine"
         */
        name: string
        /**
         * Article number (Vendor’s unique article number)
         * @example "ABC123456"
         */
        number: string
        /**
         * EAN number
         * @example 12345678
         */
        ean: string
        eclass?: {
          /** Version of eClass specification */
          version: ModelGetCustomerOciCartVersionEnum1
          /** eClass value formatted with dashes between groups ("12-34-56-78") */
          code?: string | null
          /**
           * eClass name
           * @example "Catering machinery"
           */
          name?: string | null
        }
        /**
         * Article number used in customer systems
         * @example "VABC123456"
         */
        customerArticleNumber: string
        /**
         * Article number used by manufacturer
         * @example "ABC123"
         */
        manufacturerArticleNumber?: string
        /**
         * Manufacturer name
         * @example "ACME Inc."
         */
        manufacturer: string
        /**
         * Maximum delivery time in days declared by vendor
         * @deprecated
         * @min 0
         * @max 999999
         * @example 3
         */
        deliveryTime: number
        /** Delivery time details */
        deliveryInfo: {
          /**
           * Maximum delivery time in days declared by vendor
           * @min 0
           * @example 3
           */
          days: number
          source: ModelGetCustomerOciCartSourceEnum1
          /**
           * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        /** Stock level info */
        stockInfo: {
          value: ModelGetCustomerOciCartValueEnum1
          /**
           * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        minimumOrder?: number
        quantityInterval?: number
        contentUnit?: string
        /** @example 0.001 */
        contentAmount?: string
        /** @format double */
        tax: number
        /** Pricing, order unit and constraints on quantity */
        orderOptions?: {
          /** Flag describing if this article is restricted from adding to the cart */
          restricted?: boolean
          /**
           * Article quantity for which price (pricePerOrderUnit) is defined
           * @example 0.001
           */
          priceQuantity: string
          /** Prices of the article */
          pricing: {
            /** @example 0.001 */
            quantity?: string
            /** Amount of money */
            price?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
            /** Pricing source information, returned only for vendor preview */
            source?: {
              type: ModelGetCustomerOciCartTypeEnum1
              priceListFilename?: string | null
            }
          }[]
        }
        coreArticle: boolean
        closedMarketplace: boolean
      } & {
        /**
         * URL to the thumbnail of the article
         * @format url
         * @example "https://simplesystem.com/images/1234.jpg"
         */
        thumbnailUrl?: string
        /** Unit in which the article is sold */
        orderUnit: string
        /** @default false */
        isIncludedInArticleList: boolean
        /** Explanation of search score calculation */
        explanation?: string
      }
      /** Vendor information */
      vendor: {
        /**
         * Vendor ID
         * @example "V12345"
         */
        id: string
        /**
         * Name of the vendor
         * @example "Hoffmann Group"
         */
        name: string
      }
    }
    /**
     * If true, it is impossible to change item quantity
     * @example false
     */
    immutable: boolean
    /**
     * @format int32
     * @min 1
     */
    quantity: number
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of money */
    priceUsed: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Shopper's additional texts on some order positions
     * @example "for Machine 4711"
     */
    itemComment?: string
  }[]
  cartRestriction?: {
    /** Amount of money */
    limit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of money */
    exceededBy?: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    } | null
  }
}

export type ModelDeleteCustomerOciCartData = any

/** a batch of cart item summaries */
export interface ModelGetArticlesQuantitiesInOciCartData {
  /** List of items */
  items: {
    /**
     * ID of the cart item
     * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
     */
    cartItemId: string
    /**
     * Article ID
     * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
     */
    articleId: string
    /**
     * @format int32
     * @min 1
     */
    inCartQuantity: number
  }[]
}

export type ModelUpdateArticlesQuantitiesInOciCartPayload = {
  /**
   * Article ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  articleId: string
  /**
   * Amount of the article quantity.
   * @format int32
   * @min 1
   */
  changeQuantityBy: number
}[]

export type ModelUpdateArticlesQuantitiesInOciCartData = any

/**
 * Error
 * Error
 */
export type ModelUpdateArticlesQuantitiesInOciCartError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** New quantity of item in the cart */
export interface ModelUpdateOciCartItemPayload {
  /**
   * @format int32
   * @min 1
   */
  quantity?: number
  /**
   * Shopper's additional texts on some order positions
   * @example "for Machine 4711"
   */
  itemComment?: string
}

export type ModelUpdateOciCartItemData = any

/**
 * Error
 * Error
 */
export type ModelUpdateOciCartItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteItemFromOciCartData = any

/**
 * Cart content mapped to OCI fields and hook URL for OCI cart transfer
 * @example {"hookUrl":"https://example.com/oci-transferred-cart","ociFields":[{"field":"NEW_ITEM-DESCRIPTION[1]","value":"Abrasive block pack, 24 pieces hard 100"},{"field":"NEW_ITEM-QUANTITY[1]","value":"3"},{"field":"NEW_ITEM-UNIT[1]","value":"PCE"},{"field":"NEW_ITEM-PRICE[1]","value":"20.62"},{"field":"NEW_ITEM-VENDORMAT[1]","value":"557540100"},{"field":"NEW_ITEM-LONGTEXT_1:132[]","value":"Version: <b>Abrasive block</b> in <b>hard</b> version."}]}
 */
export interface ModelOciCartTransferData {
  /**
   * Hook URL where OCI cart data should be posted
   * @format url
   */
  hookUrl: string
  /** List of OCI fields with values */
  ociFields: {
    /** Name of the OCI field */
    field: string
    /** OCI field value */
    value: string
  }[]
}

/** Error */
export type ModelOciCartTransferError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelOciCustomerArticleSearchVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelOciCustomerArticleSearchSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelOciCustomerArticleSearchValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelOciCustomerArticleSearchTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** Type of classification */
export type ModelOciCustomerArticleSearchTypeEnum1 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelOciCustomerArticleSearchTypeEnum2 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelOciCustomerArticleSearchTypeEnum3 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** Type of classification */
export type ModelOciCustomerArticleSearchTypeEnum4 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** @default "ALL_ATTRIBUTES" */
export type ModelOciCustomerArticleSearchSearchArticlesByEnum =
  | 'ALL_ATTRIBUTES'
  | 'ARTICLE_NUMBER'
  | 'CUSTOMER_ARTICLE_NUMBER'
  | 'VENDOR_ARTICLE_NUMBER'
  | 'EAN'
  | 'STANDARD'
  | 'TEST_PROFILE_01'
  | 'TEST_PROFILE_02'
  | 'TEST_PROFILE_03'
  | 'TEST_PROFILE_04'
  | 'TEST_PROFILE_05'
  | 'TEST_PROFILE_06'
  | 'TEST_PROFILE_07'
  | 'TEST_PROFILE_08'
  | 'TEST_PROFILE_09'
  | 'TEST_PROFILE_10'
  | 'TEST_PROFILE_11'
  | 'TEST_PROFILE_12'
  | 'TEST_PROFILE_13'
  | 'TEST_PROFILE_14'
  | 'TEST_PROFILE_15'
  | 'TEST_PROFILE_16'
  | 'TEST_PROFILE_17'
  | 'TEST_PROFILE_18'
  | 'TEST_PROFILE_19'
  | 'TEST_PROFILE_20'

/** Type of classification */
export type ModelOciCustomerArticleSearchTypeEnum5 =
  | 'CUSTOM'
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'
  | 'PLATFORM_CATEGORIES'

/** reference type to be searched */
export type ModelOciCustomerArticleSearchReferenceTypeEnum =
  | 'ACCESSORIES'
  | 'SIMILAR_ARTICLES'
  | 'SPARE_PARTS'

export type ModelOciCustomerArticleSearchCountsEnum =
  | 'CATEGORIES'
  | 'ECLASS5'
  | 'ECLASS7'
  | 'VENDORS'
  | 'MANUFACTURERS'
  | 'FEATURES'
  | 'PRICES'
  | 'PLATFORM_CATEGORIES'

export type ModelOciCustomerArticleSearchPayload = {
  /** @default "ALL_ATTRIBUTES" */
  searchArticlesBy?: ModelOciCustomerArticleSearchSearchArticlesByEnum
  term?: string
  /** chosen category path for the current search */
  categoryPath?: {
    /** Type of classification */
    type: ModelOciCustomerArticleSearchTypeEnum5
    /**
     * Path items beginning from top level to given group
     * @example ["12345678","23456789","34567890"]
     */
    pathGroupIds: string[]
  }
  /** chosen manufacturers scope for the current search */
  manufacturerNames?: string[]
  /**
   * delivery time scope (in days) for the current search
   * @format int32
   * @min 1
   */
  maxDeliveryTime?: number
  /** Range of money */
  priceRange?: {
    /**
     * Min amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    minAmount?: number
    /**
     * Max amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    maxAmount?: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** chosen features scope for the current search */
  features?: {
    name: string
    value: string
  }[]
  /**
   * RelatedArticleSearchParam
   * reference information to be searched
   */
  referenceBy?: {
    /** id of the article whose article references will be searched */
    articleId?: string
    /** reference type to be searched */
    referenceType?: ModelOciCustomerArticleSearchReferenceTypeEnum
  }
  /** returns only items marked as Core Article if set to true */
  coreArticle?: boolean
  /** returns only items from Partner Catalogs if set to true */
  closedMarketplace?: boolean
  /**
   * optional list of properties/attributes to count; absent to count all, empty to not count anything at all
   * @uniqueItems true
   */
  counts?: ModelOciCustomerArticleSearchCountsEnum[]
  /**
   * return only counts, i.e. without search hits
   * @default false
   */
  countsOnly?: boolean
} & {
  /** chosen vendors scope for the current search */
  vendorIds?: string[]
}

export interface ModelOciCustomerArticleSearchParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`relevance`, `name,asc`, `name,desc`, `price,asc`, `price,desc`]).
   * Default sorting: `relevance`.
   */
  sort?: string[]
}

/** Main search results response */
export interface ModelOciCustomerArticleSearchData {
  /** List of search results */
  items: {
    /** Article information */
    article: {
      /**
       * Article ID
       * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
       */
      id: string
      /**
       * Article name
       * @example "Drilling machine"
       */
      name: string
      /**
       * Article number (Vendor’s unique article number)
       * @example "ABC123456"
       */
      number: string
      /**
       * EAN number
       * @example 12345678
       */
      ean: string
      eclass?: {
        /** Version of eClass specification */
        version: ModelOciCustomerArticleSearchVersionEnum
        /** eClass value formatted with dashes between groups ("12-34-56-78") */
        code?: string | null
        /**
         * eClass name
         * @example "Catering machinery"
         */
        name?: string | null
      }
      /**
       * Article number used in customer systems
       * @example "VABC123456"
       */
      customerArticleNumber: string
      /**
       * Article number used by manufacturer
       * @example "ABC123"
       */
      manufacturerArticleNumber?: string
      /**
       * Manufacturer name
       * @example "ACME Inc."
       */
      manufacturer: string
      /**
       * Maximum delivery time in days declared by vendor
       * @deprecated
       * @min 0
       * @max 999999
       * @example 3
       */
      deliveryTime: number
      /** Delivery time details */
      deliveryInfo: {
        /**
         * Maximum delivery time in days declared by vendor
         * @min 0
         * @example 3
         */
        days: number
        source: ModelOciCustomerArticleSearchSourceEnum
        /**
         * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
         * @format date-time
         * @example "2024-08-22T17:32:28.682Z"
         */
        updatedAt?: string | null
      }
      /** Stock level info */
      stockInfo: {
        value: ModelOciCustomerArticleSearchValueEnum
        /**
         * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
         * @format date-time
         * @example "2024-08-22T17:32:28.682Z"
         */
        updatedAt?: string | null
      }
      minimumOrder?: number
      quantityInterval?: number
      contentUnit?: string
      /** @example 0.001 */
      contentAmount?: string
      /** @format double */
      tax: number
      /** Pricing, order unit and constraints on quantity */
      orderOptions?: {
        /** Flag describing if this article is restricted from adding to the cart */
        restricted?: boolean
        /**
         * Article quantity for which price (pricePerOrderUnit) is defined
         * @example 0.001
         */
        priceQuantity: string
        /** Prices of the article */
        pricing: {
          /** @example 0.001 */
          quantity?: string
          /** Amount of money */
          price?: {
            /**
             * Amount in minor currency (smallest indivisible unit) of the specified currency
             * @format int64
             * @min -1000000000000000
             * @max 1000000000000000
             * @example 1234
             */
            amount: number
            /**
             * ISO 4217 currency code
             * @example "EUR"
             */
            currency: string
          }
          /** Pricing source information, returned only for vendor preview */
          source?: {
            type: ModelOciCustomerArticleSearchTypeEnum
            priceListFilename?: string | null
          }
        }[]
      }
      coreArticle: boolean
      closedMarketplace: boolean
    } & {
      /**
       * URL to the thumbnail of the article
       * @format url
       * @example "https://simplesystem.com/images/1234.jpg"
       */
      thumbnailUrl?: string
      /** Unit in which the article is sold */
      orderUnit: string
      /** @default false */
      isIncludedInArticleList: boolean
      /** Explanation of search score calculation */
      explanation?: string
    }
    /** Vendor information */
    vendor: {
      /**
       * Vendor ID
       * @example "V12345"
       */
      id: string
      /**
       * Name of the vendor
       * @example "Hoffmann Group"
       */
      name: string
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
  /** Definition of subtree to display for custom classification (if present for given search context) */
  customCategories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelOciCustomerArticleSearchTypeEnum1
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for platform categories classification (if present for given search context) */
  platformCategories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelOciCustomerArticleSearchTypeEnum2
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for eClass 5 classification (if present for given search context) */
  eClass5Categories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelOciCustomerArticleSearchTypeEnum3
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Definition of subtree to display for eClass 7 classification (if present for given search context) */
  eClass7Categories?: {
    /** Path selected for current search */
    selectedPath: {
      /** Type of classification */
      type: ModelOciCustomerArticleSearchTypeEnum4
      /** Path elements beginning from top level to given group */
      pathGroups: {
        /**
         * Id of group - eClass code for eClasses or name for custom classification
         * @example "12345678"
         */
        id: string
        /**
         * Classification group name
         * @example "Tools"
         */
        name: string
      }[]
    }
    /** List of the groups on the same level as the selected one, empty when nothing is selected */
    sameLevel: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
    /** List of children groups of the selected one, or root level groups when nothing is selected */
    children: ({
      /**
       * Id of group - eClass code for eClasses or name for custom classification
       * @example "12345678"
       */
      id: string
      /**
       * Classification group name
       * @example "Tools"
       */
      name: string
    } & {
      /** Count of articles found in given group */
      count: number
    })[]
  }
  /** Vendor hits for the current search */
  vendors?: {
    /** @example "3fa742bc-7867-4397-a8b5-d97fe58869dd" */
    vendorId: string
    /**
     * Number of article hits sold by this vendor
     * @example 19
     */
    count: number
  }[]
  /** Available manufacturers for the current search */
  manufacturers?: {
    /**
     * Manufacturer name
     * @example "Hoffmann"
     */
    name: string
    /**
     * Number of article hits with this manufacturer name
     * @example 319
     */
    count: number
  }[]
  /** List of most popular features with most popular values */
  features?: {
    /**
     * Article feature name
     * @example "Inhalt"
     */
    name: string
    /**
     * Number articles with feature defined
     * @example 8753
     */
    count: number
    /** List of most popular feature values */
    values: {
      /**
       * Article feature value
       * @example "10 Stk."
       */
      value: string
      /**
       * Number articles with the feature value
       * @example 977
       */
      count: number
    }[]
  }[]
  /** List of price ranges in various currencies (one entry for every currency used) */
  prices?: {
    /**
     * Min amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    minAmount?: number
    /**
     * Max amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @example 1234
     */
    maxAmount?: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }[]
}

/** Error */
export type ModelOciCustomerArticleSearchError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelCustomerOciArticleDetailVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelCustomerOciArticleDetailSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelCustomerOciArticleDetailValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelCustomerOciArticleDetailTypeEnum = 'CATALOG' | 'PRICE_LIST'

export type ModelCustomerOciArticleDetailTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelCustomerOciArticleDetailFileTypeEnum =
  | 'PDF'
  | 'TXT'
  | 'IMG'
  | 'URL'
  | 'OTHER'

/** Article with basic properties */
export type ModelCustomerOciArticleDetailData = {
  /**
   * Article ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  id: string
  /**
   * Article name
   * @example "Drilling machine"
   */
  name: string
  /**
   * Article number (Vendor’s unique article number)
   * @example "ABC123456"
   */
  number: string
  /**
   * EAN number
   * @example 12345678
   */
  ean: string
  eclass?: {
    /** Version of eClass specification */
    version: ModelCustomerOciArticleDetailVersionEnum
    /** eClass value formatted with dashes between groups ("12-34-56-78") */
    code?: string | null
    /**
     * eClass name
     * @example "Catering machinery"
     */
    name?: string | null
  }
  /**
   * Article number used by manufacturer
   * @example "ABC123"
   */
  manufacturerArticleNumber?: string
  /**
   * Manufacturer name
   * @example "ACME Inc."
   */
  manufacturer: string
  /**
   * Maximum delivery time in days declared by vendor
   * @deprecated
   * @min 0
   * @max 999999
   * @example 3
   */
  deliveryTime: number
  /** Delivery time details */
  deliveryInfo: {
    /**
     * Maximum delivery time in days declared by vendor
     * @min 0
     * @example 3
     */
    days: number
    source: ModelCustomerOciArticleDetailSourceEnum
    /**
     * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  /** Stock level info */
  stockInfo: {
    value: ModelCustomerOciArticleDetailValueEnum
    /**
     * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2024-08-22T17:32:28.682Z"
     */
    updatedAt?: string | null
  }
  /** @example 10 */
  minimumOrder?: number
  /** @example 5 */
  quantityInterval?: number
  /** @example "C62" */
  contentUnit?: string
  /** @example 25 */
  contentAmount?: string
  /**
   * @format double
   * @example 0.19
   */
  tax: number
  /** Pricing, order unit and constraints on quantity */
  orderOptions?: {
    /** Flag describing if this article is restricted from adding to the cart */
    restricted?: boolean
    /**
     * Article quantity for which price (pricePerOrderUnit) is defined
     * @example 0.001
     */
    priceQuantity: string
    /** Prices of the article */
    pricing: {
      /** @example 0.001 */
      quantity?: string
      /** Amount of money */
      price?: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Pricing source information, returned only for vendor preview */
      source?: {
        type: ModelCustomerOciArticleDetailTypeEnum
        priceListFilename?: string | null
      }
    }[]
  }
  coreArticle: boolean
  closedMarketplace: boolean
} & {
  /** Vendor information */
  vendor: {
    /**
     * Vendor ID
     * @example "V12345"
     */
    id: string
    /**
     * Name of the vendor
     * @example "Hoffmann Group"
     */
    name: string
  }
  /** Person with additional contact info */
  vendorContact: {
    title: ModelCustomerOciArticleDetailTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  } & {
    email: string
  }
  /** @example "PCE" */
  orderUnit: string
  /** @example "Long description to the article that may contain HTML" */
  description?: string
  /** @example "0101 – 0106" */
  hsCode?: string
  manufacturerArticleType?: string
  vendorRemarks?: string
  dependent?: boolean
  expired?: boolean
  /** Features of the article */
  features: {
    /**
     * Name of the feature
     * @example "Length"
     */
    name?: string
    /**
     * Values of the feature
     * @example ["5","8"]
     */
    values?: string[]
    unit?: string
    description?: string
    valueDetails?: string
  }[]
  /**
   * @format url
   * @example "https://cdn.shopify.com/s/files/1/0078/2555/5571/products/YousikOddieOfficeTableb.png?v=1639373568"
   */
  thumbnailUrl?: string
  images: {
    /**
     * URL pointing to a thumbnail of an image (72px x 72px)
     * @format url
     * @example "https://cataas.com/cat/says/thumbnail"
     */
    thumbnail: string
    /**
     * URL pointing to the file for display on page (360px x 360px)
     * @format url
     * @example "https://cataas.com/cat/says/display"
     */
    pageDisplay: string
  }[]
  additionalMedia: {
    /**
     * @format url
     * @example "https://cataas.com/cat/says/download"
     */
    url: string
    description: string
    fileType: ModelCustomerOciArticleDetailFileTypeEnum
  }[]
  /** @default false */
  isIncludedInArticleList: boolean
  customerArticleNumberInfo: {
    /**
     * Calculated customer article number value.
     * @example 12345
     */
    value: string
    /** @example true */
    isEdited: boolean
    /**
     * Customer article number value from file or catalog.
     * @example 67890
     */
    defaultValue: string
  }
}

/** Error */
export type ModelCustomerOciArticleDetailError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelCustomerOciFetchExternalPricesTypeEnum =
  | 'CATALOG'
  | 'PRICE_LIST'

/** List of articleIds of articles that need external prices to be provided */
export type ModelCustomerOciFetchExternalPricesPayload = any[]

/** List of fetched external prices */
export type ModelCustomerOciFetchExternalPricesData = {
  /** @example "b4334fbf-6033-4cf9-985f-5aec2d0bedea" */
  articleId?: string
  /** Pricing, order unit and constraints on quantity */
  orderOptions?: {
    /** Flag describing if this article is restricted from adding to the cart */
    restricted?: boolean
    /**
     * Article quantity for which price (pricePerOrderUnit) is defined
     * @example 0.001
     */
    priceQuantity: string
    /** Prices of the article */
    pricing: {
      /** @example 0.001 */
      quantity?: string
      /** Amount of money */
      price?: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Pricing source information, returned only for vendor preview */
      source?: {
        type: ModelCustomerOciFetchExternalPricesTypeEnum
        priceListFilename?: string | null
      }
    }[]
  }
}[]

/** Error */
export type ModelCustomerOciFetchExternalPricesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Platform categories */
export type ModelGetCustomerOciPlatformCategoriesData = {
  /**
   * Product Category ID
   * @format uuid
   * @example "2cb0a005-3aec-4e67-89ed-9bf89bece0a1"
   */
  id: string
  /**
   * Name in the current user language
   * @example "Tooling"
   */
  name: string
  /** If category contains some articles, currently always set to true */
  active: boolean
  /**
   * Image in base64 encoded
   * @example "data:image/png;base64,iVBORw0KGgoAAA"
   */
  thumbnail: string
}[]

export type ModelGetCustomerOciMarketplaceVendorsData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

/** Error */
export type ModelGetCustomerOciMarketplaceVendorsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetOciCustomerVendorOciCatalogsCategoriesEnum =
  | 'OCCUPATIONAL_SAFETY_PPE'
  | 'FASTENING_TECHNOLOGY'
  | 'FITTING_TECHNOLOGY'
  | 'OFFICE_SUPPLIES_EQUIPMENT_TECHNOLOGY'
  | 'CHEMICAL_TECHNICAL_PRODUCTS'
  | 'ELECTRICAL_ENGINEERING_ELECTRONICS'
  | 'TECHNICAL_BOOKS_MAGAZINES'
  | 'CATERING_SUPPLIES_FOOD'
  | 'HEATING_SANITARY_AIR_CONDITIONING'
  | 'HYDRAULICS_PNEUMATICS'
  | 'INDUSTRIAL_SUPPLIES'
  | 'IT_COMMUNICATION_MEDIA_TECHNOLOGY'
  | 'WAREHOUSE_FACTORY_EQUIPMENT'
  | 'MATERIAL_PROCESSING'
  | 'MEDICAL_AND_LABORATORY_SUPPLIES'
  | 'WELDING_AND_SOLDERING_TECHNOLOGY'
  | 'TECHNICAL_TRADE'
  | 'PACKING_SHIPPING'
  | 'TOOLS'
  | 'TRAINING_AND_EDUCATION'

export type ModelGetOciCustomerVendorOciCatalogsTitleEnum =
  | 'MS'
  | 'MR'
  | 'OTHER'

export interface ModelGetOciCustomerVendorOciCatalogsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** CustomerVendorOciCatalogsList */
export interface ModelGetOciCustomerVendorOciCatalogsData {
  items?: {
    /**
     * OCI catalog id
     * @example "OCI564734857"
     */
    id: string
    /**
     * OCI catalog name
     * @example "Production equipment"
     */
    name: string
    /** Vendor data for tile (with details) */
    vendor: {
      /**
       * Vendor id
       * @example "V12345"
       */
      id: string
      /**
       * URL to the vendor company logo
       * @format url
       * @example "https://simplesystem.com/images/logo_url.jpg"
       */
      logoUrl?: string
      /** Vendor business categories */
      categories: ModelGetOciCustomerVendorOciCatalogsCategoriesEnum[]
      /** Common information of the company being registered as either Customer or Vendor */
      company: {
        name: string
        /**
         * Country code in ISO 3166-1 alfa-2
         * @example "DE"
         */
        countryCode: string
        companyRegistrationNumber?: string
        /** @example "München" */
        cityCourt?: string
        vatId: string
        /**
         * Name of the street
         * @example "Bodenseestraße"
         */
        streetName: string
        /**
         * Number of the street, building or house
         * @example "21A"
         */
        streetNumber: string
        /**
         * Post code
         * @example 81241
         */
        postalCode: string
        /**
         * Name of the city
         * @example "München"
         */
        city: string
      } & {
        humanReadableId?: string
      }
      /** Person with additional contact info */
      contactPerson: {
        title: ModelGetOciCustomerVendorOciCatalogsTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      }
    }
  }[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** the method use to redirect user to vendor's OCI page */
export type ModelGetOciCustomerOciUrlWithSessionMethodEnum = 'POST' | 'GET'

/** Information needed for frontend to redirect user to vendor's oci website */
export interface ModelGetOciCustomerOciUrlWithSessionData {
  /**
   * the URL to vendor's OCI page, may contain all parameters including credential for GET method type
   * @format url
   * @example "https://www.test.com/oci"
   */
  url: string
  /** the method use to redirect user to vendor's OCI page */
  method: ModelGetOciCustomerOciUrlWithSessionMethodEnum
  /**
   * OCI connection parameters for POST method
   * @example {"USERNAME":"testUser","PASSWORD":"$up3rS!mp73","Lancode":"EN","SAPUser":"lerhvms87","HOOK_URL":"https://nextgen-dev.simplesystem.com/oci/customers/carts/bb0096ad-a42b-4984-9cd8-ea7c1e40b129"}
   */
  params?: Record<string, string>
}

/** Error */
export type ModelGetOciCustomerOciUrlWithSessionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Parameters of OCI cart transfer search */
export interface ModelSearchCustomerOciCartTransfersPayload {
  /**
   * Search term
   * @example "Hoffmann"
   */
  term?: string
  /**
   * List of vendor Ids to filter results - empty or missing results in no filtering.
   * @example ["b4fe26f6-644b-4576-9a10-cce7bad288a5","7fe2569e-56ca-4c9a-b6c6-4223fbc14926"]
   */
  vendorIds?: string[]
}

export interface ModelSearchCustomerOciCartTransfersParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`number,desc`, `number,asc`]).
   * Default sorting: `number,asc`.
   */
  sort?: string[]
}

export interface ModelSearchCustomerOciCartTransfersData {
  items: {
    /**
     * Id of transferred cart
     * @example "120a39bb-0f2e-4589-90d3-a9951a9ee392"
     */
    id: string
    /**
     * Human friendly number of transferred cart
     * @format int64
     * @example 10347
     */
    number: number
    /**
     * Timestamp when the cart was transferred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2022-11-25T11:10:57.849+00:00"
     */
    transferredAt: string
    /** OCI user that transferred the cart */
    createdBy: {
      /**
       * OCI user ID
       * @example "f081d08f-5a26-406e-9293-b4043d4a4751"
       */
      id: string
      /**
       * @minLength 1
       * @example "oci_technical_user_1"
       */
      username: string
    }
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** List of summaries of cart items by vendor */
    perVendorSummaries: {
      /** Basic data of customer or vendor */
      vendor: {
        /**
         * ID of vendor/customer
         * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
         */
        id: string
        /**
         * Vendor/customer company name
         * @example "ACME Ltd."
         */
        name: string
      }
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
    }[]
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchCustomerOciCartTransfersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetCustomerOciCartTransferDetailsVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelGetCustomerOciCartTransferDetailsSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelGetCustomerOciCartTransferDetailsValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelGetCustomerOciCartTransferDetailsTypeEnum =
  | 'CATALOG'
  | 'PRICE_LIST'

/** Details of transferred OCI cart */
export interface ModelGetCustomerOciCartTransferDetailsData {
  /**
   * Id of transferred cart
   * @example "120a39bb-0f2e-4589-90d3-a9951a9ee392"
   */
  id: string
  /**
   * Human friendly number of transferred cart
   * @format int64
   * @example 10347
   */
  number: number
  /** a batch of cart items per vendor */
  items: {
    /** Basic data of customer or vendor */
    vendor: {
      /**
       * ID of vendor/customer
       * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
       */
      id: string
      /**
       * Vendor/customer company name
       * @example "ACME Ltd."
       */
      name: string
    }
    /** List of items that belong to the same vendor */
    items: {
      /**
       * ID of the cart item
       * @example "3b6fa8b2-ad3c-4530-a997-e02ec4abdd73"
       */
      id: string
      /** A single search result */
      articleData: {
        /** Article information */
        article: {
          /**
           * Article ID
           * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
           */
          id: string
          /**
           * Article name
           * @example "Drilling machine"
           */
          name: string
          /**
           * Article number (Vendor’s unique article number)
           * @example "ABC123456"
           */
          number: string
          /**
           * EAN number
           * @example 12345678
           */
          ean: string
          eclass?: {
            /** Version of eClass specification */
            version: ModelGetCustomerOciCartTransferDetailsVersionEnum
            /** eClass value formatted with dashes between groups ("12-34-56-78") */
            code?: string | null
            /**
             * eClass name
             * @example "Catering machinery"
             */
            name?: string | null
          }
          /**
           * Article number used in customer systems
           * @example "VABC123456"
           */
          customerArticleNumber: string
          /**
           * Article number used by manufacturer
           * @example "ABC123"
           */
          manufacturerArticleNumber?: string
          /**
           * Manufacturer name
           * @example "ACME Inc."
           */
          manufacturer: string
          /**
           * Maximum delivery time in days declared by vendor
           * @deprecated
           * @min 0
           * @max 999999
           * @example 3
           */
          deliveryTime: number
          /** Delivery time details */
          deliveryInfo: {
            /**
             * Maximum delivery time in days declared by vendor
             * @min 0
             * @example 3
             */
            days: number
            source: ModelGetCustomerOciCartTransferDetailsSourceEnum
            /**
             * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          /** Stock level info */
          stockInfo: {
            value: ModelGetCustomerOciCartTransferDetailsValueEnum
            /**
             * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
             * @format date-time
             * @example "2024-08-22T17:32:28.682Z"
             */
            updatedAt?: string | null
          }
          minimumOrder?: number
          quantityInterval?: number
          contentUnit?: string
          /** @example 0.001 */
          contentAmount?: string
          /** @format double */
          tax: number
          /** Pricing, order unit and constraints on quantity */
          orderOptions?: {
            /** Flag describing if this article is restricted from adding to the cart */
            restricted?: boolean
            /**
             * Article quantity for which price (pricePerOrderUnit) is defined
             * @example 0.001
             */
            priceQuantity: string
            /** Prices of the article */
            pricing: {
              /** @example 0.001 */
              quantity?: string
              /** Amount of money */
              price?: {
                /**
                 * Amount in minor currency (smallest indivisible unit) of the specified currency
                 * @format int64
                 * @min -1000000000000000
                 * @max 1000000000000000
                 * @example 1234
                 */
                amount: number
                /**
                 * ISO 4217 currency code
                 * @example "EUR"
                 */
                currency: string
              }
              /** Pricing source information, returned only for vendor preview */
              source?: {
                type: ModelGetCustomerOciCartTransferDetailsTypeEnum
                priceListFilename?: string | null
              }
            }[]
          }
          coreArticle: boolean
          closedMarketplace: boolean
        } & {
          /**
           * URL to the thumbnail of the article
           * @format url
           * @example "https://simplesystem.com/images/1234.jpg"
           */
          thumbnailUrl?: string
          /** Unit in which the article is sold */
          orderUnit: string
          /** @default false */
          isIncludedInArticleList: boolean
          /** Explanation of search score calculation */
          explanation?: string
        }
        /** Vendor information */
        vendor: {
          /**
           * Vendor ID
           * @example "V12345"
           */
          id: string
          /**
           * Name of the vendor
           * @example "Hoffmann Group"
           */
          name: string
        }
      }
      /**
       * If true, it is impossible to change item quantity
       * @example false
       */
      immutable: boolean
      /**
       * @format int32
       * @min 1
       */
      quantity: number
      /** Amount of money */
      total: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /** Amount of money */
      priceUsed: {
        /**
         * Amount in minor currency (smallest indivisible unit) of the specified currency
         * @format int64
         * @min -1000000000000000
         * @max 1000000000000000
         * @example 1234
         */
        amount: number
        /**
         * ISO 4217 currency code
         * @example "EUR"
         */
        currency: string
      }
      /**
       * Shopper's additional texts on some order positions
       * @example "for Machine 4711"
       */
      itemComment?: string
    }[]
    /** Amount of money */
    total: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Message from vendor
     * @example "Deliveries will be delayed..."
     */
    vendorCartInfo?: string
  }[]
  /** Amount of money */
  total: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * Timestamp when the cart was transferred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2022-11-25T11:10:57.849+00:00"
   */
  transferredAt: string
  /** OCI user that transferred the cart */
  createdBy: {
    /**
     * OCI user ID
     * @example "f081d08f-5a26-406e-9293-b4043d4a4751"
     */
    id: string
    /**
     * @minLength 1
     * @example "oci_technical_user_1"
     */
    username: string
  }
}

/** Error */
export type ModelGetCustomerOciCartTransferDetailsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetCustomerOciCartTransferHistoryVendorsData = {
  /**
   * ID of vendor/customer
   * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
   */
  id: string
  /**
   * Vendor/customer company name
   * @example "ACME Ltd."
   */
  name: string
}[]

export type ModelGetBackofficeCustomersAssignedFeaturesEnum =
  | 'OCI_INTEGRATION'
  | 'SET_ORDER_NUMBER'
  | 'COCKPIT_ORDER_INTEGRATION'
  | 'VENDING_MACHINE_INTEGRATION'
  | 'NO_VENDOR_ORDERS'
  | 'ACCOUNTING_AUTOMATION'

export interface ModelGetBackofficeCustomersParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** BackofficeCustomerList */
export interface ModelGetBackofficeCustomersData {
  items?: ({
    customerId: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: {
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }
    /** @default false */
    deleted?: boolean
  } & {
    /**
     * List of conditional features enabled for this customer user
     * @uniqueItems true
     */
    assignedFeatures: ModelGetBackofficeCustomersAssignedFeaturesEnum[]
  })[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

export interface ModelUpdateBackofficeCustomerPayload {
  ociIntegrationEnabled?: boolean | null
}

export type ModelUpdateBackofficeCustomerData = any

export type ModelDeleteBackofficeCustomerData = any

export type ModelGetBackofficeCustomerUsersTitleEnum = 'MS' | 'MR' | 'OTHER'

/** named set of permissions for given user */
export type ModelGetBackofficeCustomerUsersPermissionGroupEnum =
  | 'ADMIN'
  | 'SHOPPER'

export interface ModelGetBackofficeCustomerUsersParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Customer ID
   * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
   */
  customerId: string
}

/** BackofficeCustomerUserList */
export interface ModelGetBackofficeCustomerUsersData {
  items: {
    /**
     * @format uuid
     * @example "97484465-8710-4322-b44b-26419c9948a6"
     */
    userId: string
    /** Contains information about basic user information */
    profile: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelGetBackofficeCustomerUsersTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    } & {
      /** named set of permissions for given user */
      permissionGroup: ModelGetBackofficeCustomerUsersPermissionGroupEnum
    }
    active: boolean
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

export interface ModelGetBackofficeCustomerFeaturesStateData {
  OCI_INTEGRATION: {
    enabled: boolean
    /**
     * @format date-time
     * @example "2023-10-21T17:32:28.682Z"
     */
    updatedAt?: string
    updatedBy?: string
  }
  COCKPIT_ORDER_INTEGRATION: {
    enabled: boolean
    /**
     * @format date-time
     * @example "2023-10-21T17:32:28.682Z"
     */
    updatedAt?: string
    updatedBy?: string
  }
  VENDING_MACHINE_INTEGRATION: {
    enabled: boolean
    /**
     * @format date-time
     * @example "2023-10-21T17:32:28.682Z"
     */
    updatedAt?: string
    updatedBy?: string
  }
  NO_VENDOR_ORDERS: {
    enabled: boolean
    /**
     * @format date-time
     * @example "2023-10-21T17:32:28.682Z"
     */
    updatedAt?: string
    updatedBy?: string
  }
  ACCOUNTING_AUTOMATION: {
    enabled: boolean
    /**
     * @format date-time
     * @example "2023-10-21T17:32:28.682Z"
     */
    updatedAt?: string
    updatedBy?: string
  }
}

/** Error */
export type ModelGetBackofficeCustomerFeaturesStateError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Customer user available features state */
export interface ModelUpdateBackofficeCustomerFeaturesStatePayload {
  OCI_INTEGRATION?: boolean
  COCKPIT_ORDER_INTEGRATION?: boolean
  VENDING_MACHINE_INTEGRATION?: boolean
  NO_VENDOR_ORDERS?: boolean
  ACCOUNTING_AUTOMATION?: boolean
}

export type ModelUpdateBackofficeCustomerFeaturesStateData = any

/**
 * Error
 * Error
 */
export type ModelUpdateBackofficeCustomerFeaturesStateError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetBackofficeVendorsParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/** BackofficeVendorList */
export interface ModelGetBackofficeVendorsData {
  items?: {
    vendorId: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: {
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }
    /** @default false */
    deleted?: boolean
  }[]
  /** Search metadata */
  metadata?: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Contains requested vendor related fields to be updated */
export interface ModelUpdateBackofficeVendorPayload {
  /** @default false */
  publicVendor?: boolean | null
  ociCatalogsEnabled?: boolean | null
}

export type ModelUpdateBackofficeVendorData = any

export type ModelDeleteBackofficeVendorData = any

export type ModelGetBackofficeVendorUsersTitleEnum = 'MS' | 'MR' | 'OTHER'

export interface ModelGetBackofficeVendorUsersParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Vendor ID
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  vendorId: string
}

/** BackofficeVendorUserList */
export interface ModelGetBackofficeVendorUsersData {
  items: {
    /**
     * @format uuid
     * @example "97484465-8710-4322-b44b-26419c9948a6"
     */
    userId: string
    /** Contains information about basic user information */
    profile: {
      /** Basic information of the person registering the Customer or Vendor */
      person: {
        title: ModelGetBackofficeVendorUsersTitleEnum
        firstName: string
        lastName: string
        phoneNumber: string
      }
      email: string
      /** user role in the company */
      roleInCompany?: string
    }
    active: boolean
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Definition of uploaded document file to be processed */
export interface ModelPostDocumentVersionPayload {
  /**
   * Original file name of the uploaded document file
   * @example "vendor_fr_v1.pdf"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/file123456.pdf"
   */
  path: string
  /**
   * name of the document version
   * @example "version_1"
   */
  label: string
  /**
   * document type of the signed agreement can be one of [DATA_PRIVACY_POLICY, VENDOR_PROVISIONING_CONTRACT]
   * @example "VENDOR_PROVISIONING_CONTRACT"
   */
  type: string
  /**
   * country where the document is binding
   * @example "DE"
   */
  countryOfOperation: string
}

export type ModelPostDocumentVersionData = any

/** Error */
export type ModelPostDocumentVersionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method to use for upload */
export type ModelGetDocumentUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetDocumentUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum6
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum6 = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetDocumentUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetDocumentUploadUrlMethodEnum
}

/**
 * Error
 * Error
 */
export type ModelGetDocumentUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetDocumentUploadUrlParams1MethodEnum = 'PUT' | 'POST'

/** Definition of uploaded contract file to be processed */
export interface ModelPostVendorAgreementPayload {
  /**
   * Original file name of the uploaded file
   * @example "media.zip"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/file123456.pdf"
   */
  path: string
  /**
   * document type of the signed agreement can be one of [DATA_PRIVACY_POLICY, VENDOR_PROVISIONING_CONTRACT]
   * @example "VENDOR_PROVISIONING_CONTRACT"
   */
  documentType: string
}

export type ModelPostVendorAgreementData = any

/** Error */
export type ModelPostVendorAgreementError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Definition of uploaded contract file to be processed */
export interface ModelPostCustomerAgreementPayload {
  /**
   * Original file name of the uploaded file
   * @example "media.zip"
   */
  fileName: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key.
   * @example "uploads/file123456.pdf"
   */
  path: string
  /**
   * document type of the signed agreement can be one of [DATA_PRIVACY_POLICY, VENDOR_PROVISIONING_CONTRACT]
   * @example "VENDOR_PROVISIONING_CONTRACT"
   */
  documentType: string
}

export type ModelPostCustomerAgreementData = any

/** Error */
export type ModelPostCustomerAgreementError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method to use for upload */
export type ModelGetCompanyAgreementUploadUrlMethodEnum = 'PUT' | 'POST'

export interface ModelGetCompanyAgreementUploadUrlParams {
  /** Filename of the file to be uploaded (with extension). */
  filename: string
  /** HTTP method used to upload file, default PUT. */
  method?: ModelMethodEnum7
  /**
   * Either vendorId or customerId of a company
   * @example "b2aa3d67-48c9-432e-8c44-869f1d20173e"
   */
  companyId: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelMethodEnum7 = 'PUT' | 'POST'

/** Response with upload URL and other data required to perform file upload. */
export interface ModelGetCompanyAgreementUploadUrlData {
  /**
   * Presigned url for file upload
   * @format url
   * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
   */
  url: string
  /**
   * Relative path of the uploaded file in storage system, e.g. S3 bucket object key. To be used when notifying backend on uploaded file.
   * @example "uploads/file123456.xml"
   */
  path: string
  /**
   * Date and time of presigned URL expiration. The upload has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  validUntil: string
  /** HTTP method to use for upload */
  method: ModelGetCompanyAgreementUploadUrlMethodEnum
}

/** Error */
export type ModelGetCompanyAgreementUploadUrlError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** HTTP method used to upload file, default PUT. */
export type ModelGetCompanyAgreementUploadUrlParams1MethodEnum = 'PUT' | 'POST'

export type ModelPostVendorEvaluationStatusTierEnum =
  | 'IN_EVALUATION'
  | 'BASIC'
  | 'BRONZE'
  | 'CLASSIC'
  | 'SILVER'
  | 'GOLD'
  | 'VIP'

export type ModelPostVendorEvaluationStatusTierEnum1 =
  | 'IN_EVALUATION'
  | 'BASIC'
  | 'BRONZE'
  | 'CLASSIC'
  | 'SILVER'
  | 'GOLD'
  | 'VIP'

/** Data needed to create VendorEvaluationStatus */
export type ModelPostVendorEvaluationStatusPayload = ({
  tier: ModelPostVendorEvaluationStatusTierEnum1
  /**
   * percentage number (0-100) as a string; should NOT contain % sign
   * @format number
   * @min 0
   * @max 100
   * @example 0.5
   */
  openMarketCommissionRate: string
  /**
   * percentage number (0-100) as a string; should NOT contain % sign
   * @format number
   * @min 0
   * @max 100
   * @example 6.25
   */
  closedMarketCommissionRate: string
} & object) & {
  /**
   * date when VendorEvaluationStatus will be activated at; defaults to creation date if not given
   * @format date-time
   * @example "2021-06-25T11:10:57.849+00:00"
   */
  activeAt?: string
  /**
   * date when VendorEvaluationStatus will be re-evaluated; defaults to 3 months since activation date
   * @format date-time
   * @example "2021-09-25T11:10:57.849+00:00"
   */
  reevaluateAt?: string
}

/** Full details of VendorEvaluationStatus */
export type ModelPostVendorEvaluationStatusData = ({
  tier: ModelPostVendorEvaluationStatusTierEnum
  /**
   * percentage number (0-100) as a string; should NOT contain % sign
   * @format number
   * @min 0
   * @max 100
   * @example 0.5
   */
  openMarketCommissionRate: string
  /**
   * percentage number (0-100) as a string; should NOT contain % sign
   * @format number
   * @min 0
   * @max 100
   * @example 6.25
   */
  closedMarketCommissionRate: string
} & object) & {
  /**
   * @format date-time
   * @example "2021-06-25T11:10:57.849+00:00"
   */
  activeAt: string
  /**
   * @format date-time
   * @example "2021-09-25T11:10:57.849+00:00"
   */
  reevaluateAt?: string
}

/** Error */
export type ModelPostVendorEvaluationStatusError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Status of Vendor-Customer relationship */
export type ModelGetBackofficeVendorRequestsStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

export interface ModelGetBackofficeVendorRequestsParams {
  /** Search term */
  term?: string
  /**
   * Kind of vendor request
   * @default "PUBLIC"
   */
  kind?: ModelKindEnum3
  /** Status of the vendor request */
  status?: ModelStatusEnum1[]
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [
   * `vendor.name,asc`, `vendor.name,desc`,
   * `vendor.humanReadableId,asc`, `vendor.humanReadableId,desc`,
   * `vendor.vatId,asc`, `vendor.vatId,desc`,
   * `customer.name,asc`, `customer.name,desc`,
   * `customer.humanReadableId,asc`, `customer.humanReadableId,desc`,
   * `customer.requestingPersonName,asc`, `customer.requestingPersonName,desc`,
   * `requestedAt,asc`, `requestedAt,desc`
   * ]).
   * Default sorting: `vendor.name,asc`.
   */
  sort?: string[]
}

/**
 * Kind of vendor request
 * @default "PUBLIC"
 */
export type ModelKindEnum3 = 'PUBLIC' | 'NON_PUBLIC' | 'NOT_EXISTING'

/** Status of Vendor-Customer relationship */
export type ModelStatusEnum1 = 'PENDING' | 'ACCEPTED' | 'ON_HOLD' | 'REJECTED'

/**
 * BackofficeVendorList
 * Response with list of vendor requests
 */
export interface ModelGetBackofficeVendorRequestsData {
  /** List of found vendor request matching selected criteria */
  vendorRequests: {
    /**
     * Vendor request ID
     * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
     */
    id: string
    vendor: {
      /**
       * Vendor company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Human readable vendor ID
       * @example "42"
       */
      humanReadableId: string
      /**
       * VAT ID of vendor company
       * @example "DE123456789"
       */
      vatId: string
    }
    customer: {
      /**
       * Customer company name
       * @example "Road Runner Inc."
       */
      name: string
      /** Human readable customer ID */
      humanReadableId: string
      /** Full name of person that created vendor request */
      requestingPersonName: string
    }
    /** Status of Vendor-Customer relationship */
    status: ModelGetBackofficeVendorRequestsStatusEnum
    /**
     * Date when the vendor request has been created
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    requestedAt: string
  }[]
  /** Counts of found vendor request of all kinds */
  vendorRequestsMetadata: {
    /**
     * Count of all found vendor requests to public vendors
     * @format int32
     * @example 42
     */
    publicCount: number
    /**
     * Count of all found vendor requests to non-public vendors
     * @format int32
     * @example 13
     */
    nonPublicCount: number
    /**
     * Count of all found vendor requests to new vendors
     * @format int32
     * @example 11
     */
    notExistingCount: number
  }
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/**
 * Kind of vendor request
 * @default "PUBLIC"
 */
export type ModelGetBackofficeVendorRequestsParams1KindEnum =
  | 'PUBLIC'
  | 'NON_PUBLIC'
  | 'NOT_EXISTING'

/** Status of Vendor-Customer relationship */
export type ModelGetBackofficeVendorRequestsParams1StatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

/** Status of Vendor-Customer relationship */
export type ModelGetBackofficeVendorRequestStatusEnum =
  | 'PENDING'
  | 'ACCEPTED'
  | 'ON_HOLD'
  | 'REJECTED'

/**
 * Kind of vendor request
 * @default "PUBLIC"
 */
export type ModelGetBackofficeVendorRequestKindEnum =
  | 'PUBLIC'
  | 'NON_PUBLIC'
  | 'NOT_EXISTING'

export type ModelGetBackofficeVendorRequestTitleEnum = 'MS' | 'MR' | 'OTHER'

export type ModelGetBackofficeVendorRequestTitleEnum1 = 'MS' | 'MR' | 'OTHER'

export type ModelGetBackofficeVendorRequestOnHoldReasonEnum =
  | 'PROCESSING_REQUEST'
  | 'CREATING_CATALOG'
  | 'CLARIFYING_SALES_CONDITIONS'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelGetBackofficeVendorRequestRejectionReasonEnum =
  | 'WRONG_SUPPLIER'
  | 'LOW_VOLUME'
  | 'NO_NEW_CUSTOMERS'
  | 'LIMITED_TARGET_GROUP'
  | 'CLARIFICATION_REQUIRED'
  | 'OTHER'
  | 'NO_INFORMATION'

export type ModelGetBackofficeVendorRequestTitleEnum2 = 'MS' | 'MR' | 'OTHER'

/** Details of specific vendor request */
export interface ModelGetBackofficeVendorRequestData {
  /**
   * Vendor request ID
   * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
   */
  id: string
  /** Status of Vendor-Customer relationship */
  status: ModelGetBackofficeVendorRequestStatusEnum
  /**
   * Kind of vendor request
   * @default "PUBLIC"
   */
  kind: ModelGetBackofficeVendorRequestKindEnum
  /** Vendor data for tile (with details) */
  vendor: {
    vendorId: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: {
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }
    /** @default false */
    deleted?: boolean
  }
  /** Person with additional contact info */
  vendorContactPerson: {
    title: ModelGetBackofficeVendorRequestTitleEnum
    firstName: string
    lastName: string
    phoneNumber: string
  } & {
    email: string
  }
  /** Customer data for tile (with details) */
  customer: {
    customerId: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: {
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }
    /** @default false */
    deleted?: boolean
  }
  /** Person with additional contact info */
  requestingCustomerUser: {
    title: ModelGetBackofficeVendorRequestTitleEnum1
    firstName: string
    lastName: string
    phoneNumber: string
  } & {
    email: string
  }
  /** Customer comment to the vendor request */
  customersComment?: string
  /**
   * Date of creating this vendor request
   * @format date-time
   * @example "2021-11-25T11:10:57.849+00:00"
   */
  requestedAt: string
  /**
   * The vendor specific customer number of the requesting customer
   * @example "RR"
   */
  vendorSpecificCustomerNumber: string | null
  onHoldReason?: ModelGetBackofficeVendorRequestOnHoldReasonEnum | null
  /**
   * @format date-time
   * @example "2021-12-25T11:10:57.849+00:00"
   */
  onHoldUntil?: string | null
  rejectionReason?: ModelGetBackofficeVendorRequestRejectionReasonEnum | null
  /** Optional vendor comment to the rejected or put on hold vendor request */
  vendorsComment?: string | null
  /** Person with additional contact info */
  statusLastChangedBy?:
    | ({
        title: ModelGetBackofficeVendorRequestTitleEnum2
        firstName: string
        lastName: string
        phoneNumber: string
      } & {
        email: string
      })
    | null
  /**
   * Date of las change of the status
   * @format date-time
   */
  statusLastChangedAt?: string | null
}

/** Error */
export type ModelGetBackofficeVendorRequestError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteMarketRelationshipData = any

export interface ModelGetBackofficeErpRoutingGroupsParams {
  /** Search term */
  term?: string
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
}

/**
 * BackofficeErpRoutingGroups
 * Response with list of erp order routing groups
 */
export interface ModelGetBackofficeErpRoutingGroupsData {
  /** List of erp order routing groups */
  items: {
    /**
     * Erp order routing group ID
     * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
     */
    id: string
    /**
     * Erp order routing group name
     * @example "Hoffman Groups."
     */
    name: string
    /**
     * Erp order routing group description
     * @example "This is a basic and short description of the group"
     */
    description: string
    /** Master customer of the erp order routing group */
    masterAccount: {
      /**
       * Customer company ID
       * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
       */
      id: string
      /**
       * Customer company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Human readable customer ID
       * @example "42"
       */
      humanReadableId: string
      /**
       * Customer accounting area
       * @example "8093asd12"
       */
      accountingArea: string
    }
    /** List of group members (including master) */
    members?: {
      /**
       * Customer company ID
       * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
       */
      customerId: string
      /**
       * Customer company name
       * @example "ACME Ltd."
       */
      name: string
      /**
       * Customer accounting area
       * @example "8093asd12"
       */
      accountingArea: string
    }[]
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Erp order routing group creation object */
export interface ModelPostBackofficeErpRoutingGroupsPayload {
  /**
   * Erp order routing group name
   * @example "Hoffman Groups."
   */
  name: string
  /**
   * Erp order routing group description
   * @example "This is a basic and short description of the group"
   */
  description: string
  /**
   * Master customer company ID
   * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
   */
  masterAccountId: string
  /** List of group members (including master) */
  members: {
    /**
     * Customer company ID
     * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
     */
    customerId: string
    /**
     * Customer accounting area
     * @example "8093asd12"
     */
    accountingArea: string
  }[]
}

export type ModelPostBackofficeErpRoutingGroupsData = any

/**
 * Error
 * Error
 */
export type ModelPostBackofficeErpRoutingGroupsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Erp order routing group creation object */
export interface ModelPutBackofficeErpRoutingGroupPayload {
  /**
   * Erp order routing group name
   * @example "Hoffman Groups."
   */
  name: string
  /**
   * Erp order routing group description
   * @example "This is a basic and short description of the group"
   */
  description: string
  /**
   * Master customer company ID
   * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
   */
  masterAccountId: string
  /** List of group members (including master) */
  members: {
    /**
     * Customer company ID
     * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
     */
    customerId: string
    /**
     * Customer accounting area
     * @example "8093asd12"
     */
    accountingArea: string
  }[]
}

export type ModelPutBackofficeErpRoutingGroupData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelPutBackofficeErpRoutingGroupError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteBackofficeErpRoutingGroupData = any

/** Error */
export type ModelDeleteBackofficeErpRoutingGroupError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * BackofficeErpRoutingMembers
 * Response with list of erp order routing group members
 */
export interface ModelGetBackofficeErpRoutingGroupAssignmentsData {
  /** List of erp order routing group members */
  items: {
    /**
     * Customer company ID
     * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
     */
    id: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: {
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }
    /**
     * True if customer is assigned to the specified group or to any group if no group was specified
     * @example true
     */
    assigned: boolean
    /**
     * True if customer can be assigned to the group or in general if no group was specified.
     * @example true
     */
    isAssignable: boolean
    /**
     * name of the group the customer is assigned to
     * @example "Hoffman Groups."
     */
    assignedTo: string | null
  }[]
}

/** Error */
export type ModelGetBackofficeErpRoutingGroupAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/**
 * BackofficeErpRoutingMembers
 * Response with list of erp order routing group members
 */
export interface ModelGetBackofficeErpRoutingGroupAllAssignmentsData {
  /** List of erp order routing group members */
  items: {
    /**
     * Customer company ID
     * @example "4afd3bf0-2d40-47dd-a957-51950e111480"
     */
    id: string
    /** Common information of the company being registered as either Customer or Vendor */
    company: {
      name: string
      /**
       * Country code in ISO 3166-1 alfa-2
       * @example "DE"
       */
      countryCode: string
      companyRegistrationNumber?: string
      /** @example "München" */
      cityCourt?: string
      vatId: string
      /**
       * Name of the street
       * @example "Bodenseestraße"
       */
      streetName: string
      /**
       * Number of the street, building or house
       * @example "21A"
       */
      streetNumber: string
      /**
       * Post code
       * @example 81241
       */
      postalCode: string
      /**
       * Name of the city
       * @example "München"
       */
      city: string
    } & {
      humanReadableId?: string
    }
    /**
     * True if customer is assigned to the specified group or to any group if no group was specified
     * @example true
     */
    assigned: boolean
    /**
     * True if customer can be assigned to the group or in general if no group was specified.
     * @example true
     */
    isAssignable: boolean
    /**
     * name of the group the customer is assigned to
     * @example "Hoffman Groups."
     */
    assignedTo: string | null
  }[]
}

/** Response with list of product categories */
export interface ModelGetBackofficeProductCategoriesData {
  /** List of found product categories */
  items: (({
    /**
     * Product Category ID
     * @format uuid
     * @example "2cb0a005-3aec-4e67-89ed-9bf89bece0a1"
     */
    id: string
    /** Names in each of the platform supported languages */
    names: {
      /**
       * Category name in english language
       * @example "Tools"
       */
      en: string
      /**
       * Category name in german language
       * @example "Werkzeuge"
       */
      de: string
      /**
       * Category name in french language
       * @example "Outils"
       */
      fr: string
      /**
       * Category name in italian language
       * @example "Strumenti"
       */
      it: string
      /**
       * Category name in czech language
       * @example "Nástroje"
       */
      cs: string
    }
    /**
     * Image in base64 encoded
     * @example "data:image/png;base64,iVBORw0KGgoAAA"
     */
    thumbnail: string
    /** Category is enabled or not */
    active: boolean
  } & {
    /**
     * Date when the category was updated
     * @format date-time
     * @example "2021-11-25T11:10:57.849+00:00"
     */
    lastUpdatedAt: string
    /**
     * User that performed the last update of the category
     * @example "John Wick"
     */
    lastUpdatedBy: string
  }) & {
    /**
     * Amount of subcategories that this category have
     * @min 0
     * @example 5
     */
    subcategoriesCount: number
    /**
     * Amount of mapped eClasses to all subcategories that this category have
     * @min 0
     * @example 5
     */
    mappedEClassesCount: number
  })[]
}

export interface ModelPostBackofficeProductCategoriesPayload {
  /** Names in each of the platform supported languages */
  names: {
    /**
     * Category name in english language
     * @example "Tools"
     */
    en: string
    /**
     * Category name in german language
     * @example "Werkzeuge"
     */
    de: string
    /**
     * Category name in french language
     * @example "Outils"
     */
    fr: string
    /**
     * Category name in italian language
     * @example "Strumenti"
     */
    it: string
    /**
     * Category name in czech language
     * @example "Nástroje"
     */
    cs: string
  }
  /**
   * Image in base64 encoded
   * @example "data:image/png;base64,iVBORw0KGgoAAA"
   */
  thumbnail: string
  /** @minItems 1 */
  subcategories: {
    /** Names in each of the platform supported languages */
    names: {
      /**
       * Category name in english language
       * @example "Tools"
       */
      en: string
      /**
       * Category name in german language
       * @example "Werkzeuge"
       */
      de: string
      /**
       * Category name in french language
       * @example "Outils"
       */
      fr: string
      /**
       * Category name in italian language
       * @example "Strumenti"
       */
      it: string
      /**
       * Category name in czech language
       * @example "Nástroje"
       */
      cs: string
    }
  }[]
}

export type ModelPostBackofficeProductCategoriesData = any

/** Error */
export type ModelPostBackofficeProductCategoriesError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelPutBackofficeProductCategoryPositionPayload {
  ids: string[]
}

export type ModelPutBackofficeProductCategoryPositionData = any

/** Error */
export type ModelPutBackofficeProductCategoryPositionError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelGetBackofficeProductCategoryData = {
  /**
   * Product Category ID
   * @format uuid
   * @example "2cb0a005-3aec-4e67-89ed-9bf89bece0a1"
   */
  id: string
  /** Names in each of the platform supported languages */
  names: {
    /**
     * Category name in english language
     * @example "Tools"
     */
    en: string
    /**
     * Category name in german language
     * @example "Werkzeuge"
     */
    de: string
    /**
     * Category name in french language
     * @example "Outils"
     */
    fr: string
    /**
     * Category name in italian language
     * @example "Strumenti"
     */
    it: string
    /**
     * Category name in czech language
     * @example "Nástroje"
     */
    cs: string
  }
  /**
   * Image in base64 encoded
   * @example "data:image/png;base64,iVBORw0KGgoAAA"
   */
  thumbnail: string
  /** Category is enabled or not */
  active: boolean
} & {
  subcategories: (({
    /** Names in each of the platform supported languages */
    names: {
      /**
       * Category name in english language
       * @example "Tools"
       */
      en: string
      /**
       * Category name in german language
       * @example "Werkzeuge"
       */
      de: string
      /**
       * Category name in french language
       * @example "Outils"
       */
      fr: string
      /**
       * Category name in italian language
       * @example "Strumenti"
       */
      it: string
      /**
       * Category name in czech language
       * @example "Nástroje"
       */
      cs: string
    }
  } & {
    /**
     * Subcategory ID
     * @format uuid
     * @example "2cb0a005-3aec-4e67-89ed-9bf89bece0a1"
     */
    id: string
  }) & {
    eClassMapping?: string[]
  })[]
}

/** Error */
export type ModelGetBackofficeProductCategoryError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelPatchBackofficeProductCategoryPayload {
  /** Names in each of the platform supported languages */
  names?: {
    /**
     * Category name in english language
     * @example "Tools"
     */
    en: string
    /**
     * Category name in german language
     * @example "Werkzeuge"
     */
    de: string
    /**
     * Category name in french language
     * @example "Outils"
     */
    fr: string
    /**
     * Category name in italian language
     * @example "Strumenti"
     */
    it: string
    /**
     * Category name in czech language
     * @example "Nástroje"
     */
    cs: string
  }
  active?: boolean
  /**
   * Image in base64 encoded
   * @example "data:image/png;base64,iVBORw0KGgoAAA"
   */
  thumbnail?: string
  /** @minItems 1 */
  subcategories?: ({
    /** Names in each of the platform supported languages */
    names: {
      /**
       * Category name in english language
       * @example "Tools"
       */
      en: string
      /**
       * Category name in german language
       * @example "Werkzeuge"
       */
      de: string
      /**
       * Category name in french language
       * @example "Outils"
       */
      fr: string
      /**
       * Category name in italian language
       * @example "Strumenti"
       */
      it: string
      /**
       * Category name in czech language
       * @example "Nástroje"
       */
      cs: string
    }
  } & {
    /**
     * Subcategory ID
     * @format uuid
     * @example "2cb0a005-3aec-4e67-89ed-9bf89bece0a1"
     */
    id?: string
  })[]
}

export type ModelPatchBackofficeProductCategoryData = any

/**
 * Error
 * Error
 */
export type ModelPatchBackofficeProductCategoryError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteBackofficeProductCategoryData = any

/** Error */
export type ModelDeleteBackofficeProductCategoryError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelPostBackofficeProductCategorySubcategoryEClassAssignmentsPayload {
  eClassMapping: string[]
}

export type ModelPostBackofficeProductCategorySubcategoryEClassAssignmentsData =
  any

/**
 * Error
 * Error
 */
export type ModelPostBackofficeProductCategorySubcategoryEClassAssignmentsError =
  {
    /**
     * Message providing details of the error
     * @example "Validation failure"
     */
    message?: string
    /** List of error detail for each field, if the error is related to a particular form field otherwise null */
    details?: {
      /**
       * Name of the field that violates the constraint
       * @example "person.firstName"
       */
      field?: string
      /**
       * Error message that states the reason why the attached field is invalid
       * @example "must not be blank"
       */
      message?: string
    }[]
    /** List of additional data information that can be provided */
    additionalData?: {
      /**
       * Name of the property
       * @example "contactPerson"
       */
      label?: string
      /**
       * Value of the property
       * @example "example@gmail.com"
       */
      value?: string
    }[]
    /**
     * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
     * @format date-time
     * @example "2021-10-25T11:10:57.849+00:00"
     */
    timestamp?: string
  }

/**
 * All current document versions available to vendors
 * @example {"versions":{"DE":{"DATA_PRIVACY_POLICY":{"id":"91511dea-37d6-48d1-b47b-53c05a44b8b9","file":{"downloadUrl":"https://nextgen-vendor-uploads-test.com/document-versions/data_policy_v1_DE.pdf","validUntil":null}},"VENDOR_PROVISIONING_CONTRACT":{"id":"2aa5fa55-1809-4d84-804f-e0070646e3f3","file":{"downloadUrl":"https://nextgen-vendor-uploads-test.com/document-versions/provisioning_contract_v1_DE.pdf","validUntil":null}}},"FR":{"DATA_PRIVACY_POLICY":{"id":"6af25ce4-9a69-49f5-9de8-be355466f7a3","file":{"downloadUrl":"https://nextgen-vendor-uploads-test.com/document-versions/data_policy_v1_FR.pdf","validUntil":null}},"VENDOR_PROVISIONING_CONTRACT":{"id":"da28b4b2-f7ca-41bf-9191-28de8af92f0b","file":{"downloadUrl":"https://nextgen-vendor-uploads-test.com/document-versions/provisioning_contract_v1_FR.pdf","validUntil":null}}}}}
 */
export type ModelGetVendorCurrentDocumentsData = {
  versions?: Record<
    string,
    Record<
      string,
      {
        /**
         * Document Version ID that should be provided during registration process
         * @format uuid
         * @example "2d56f5d7-89e1-4df9-8224-c3aa0a26980c"
         */
        id: string
        /** Response with download URL and other data required to perform file download. */
        file: {
          /**
           * Presigned url for file download
           * @format url
           * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
           */
          downloadUrl: string
          /**
           * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2017-07-21T17:32:28.682Z"
           */
          validUntil: string
        }
      }
    >
  >
}

/**
 * All current document versions available to customers
 * @example {"versions":{"DE":{"DATA_PRIVACY_POLICY":{"id":"91511dea-37d6-48d1-b47b-53c05a44b8b9","file":{"downloadUrl":"https://nextgen-vendor-uploads-test.com/document-versions/data_policy_v1_DE.pdf","validUntil":null}}},"FR":{"DATA_PRIVACY_POLICY":{"id":"6af25ce4-9a69-49f5-9de8-be355466f7a3","file":{"downloadUrl":"https://nextgen-vendor-uploads-test.com/document-versions/data_policy_v1_FR.pdf","validUntil":null}}}}}
 */
export type ModelGetCustomerCurrentDocumentsData = {
  versions?: Record<
    string,
    Record<
      string,
      {
        /**
         * Document Version ID that should be provided during registration process
         * @format uuid
         * @example "2d56f5d7-89e1-4df9-8224-c3aa0a26980c"
         */
        id: string
        /** Response with download URL and other data required to perform file download. */
        file: {
          /**
           * Presigned url for file download
           * @format url
           * @example "https://bucket.region.amazonaws.com/path/to/file.xml"
           */
          downloadUrl: string
          /**
           * Date and time of pre-signed URL expiration. The download has to be started before this moment. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2017-07-21T17:32:28.682Z"
           */
          validUntil: string
        }
      }
    >
  >
}

/** access level to the article list */
export type ModelCustomerArticleListsSearchAccessLevelEnum =
  | 'ADMIN'
  | 'WRITE'
  | 'READ'

/** Search parameters for Article lists */
export interface ModelCustomerArticleListsSearchPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
}

export interface ModelCustomerArticleListsSearchParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,asc`, `name,desc`]).
   * Default sorting: `name,asc`.
   */
  sort?: string[]
}

/** Response with article list search results */
export interface ModelCustomerArticleListsSearchData {
  /** List of search results */
  items: {
    /**
     * Article list ID
     * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
     */
    id: string
    /**
     * Name of article list
     * @example "Office supplies"
     */
    name: string
    /**
     * Full date and time of article list added formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    createdAt: string
    /**
     * Full date and time of article list updated formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    updatedAt: string
    /**
     * Number of articles added to the list
     * @example 11
     */
    articlesCount: number
    /**
     * Number of vendors that are providing articles on the list
     * @example 3
     */
    vendorsCount: number
    /**
     * Article list owner data
     * @example {"name":"Joe Doe","customerUserId":"db7cf15b-2a1a-4f80-b2d9-a63d0be08773","currentUser":false}
     */
    createdBy: {
      /**
       * Name of article list owner
       * @example "Joe Doe"
       */
      name: string
      /**
       * Customer user ID if the owner is a customer user, null otherwise
       * @example "db7cf15b-2a1a-4f80-b2d9-a63d0be08773"
       */
      customerUserId?: string
      /**
       * OCI user ID if the owner is an OCI user, null otherwise
       * @example "907647b9-1f2d-400b-971a-7a62c3978d36"
       */
      ociUserId?: string
      /**
       * True if it is the current user
       * @example false
       */
      currentUser: boolean
    }
    /** access level to the article list */
    accessLevel: ModelCustomerArticleListsSearchAccessLevelEnum
    sharedUserInfo?: {
      /**
       * Count of users assigned to the resource
       * @format int64
       * @example 10
       */
      assignedUsersCount: number
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned: boolean
      /**
       * True if all oci users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
      /**
       * True if user groups are assigned to the resource
       * @example true
       */
      userGroupsAssigned: boolean
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelCustomerArticleListsSearchError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** access level to the article list */
export type ModelCustomerArticleListsSearchOciAccessLevelEnum =
  | 'ADMIN'
  | 'WRITE'
  | 'READ'

/** Search parameters for Article lists */
export interface ModelCustomerArticleListsSearchOciPayload {
  /**
   * Search term
   * @example "Gryffindor 321"
   */
  term?: string
}

export interface ModelCustomerArticleListsSearchOciParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Sort field and direction (available: [`name,asc`, `name,desc`]).
   * Default sorting: `name,asc`.
   */
  sort?: string[]
}

/** Response with article list search results */
export interface ModelCustomerArticleListsSearchOciData {
  /** List of search results */
  items: {
    /**
     * Article list ID
     * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
     */
    id: string
    /**
     * Name of article list
     * @example "Office supplies"
     */
    name: string
    /**
     * Full date and time of article list added formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    createdAt: string
    /**
     * Full date and time of article list updated formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    updatedAt: string
    /**
     * Number of articles added to the list
     * @example 11
     */
    articlesCount: number
    /**
     * Number of vendors that are providing articles on the list
     * @example 3
     */
    vendorsCount: number
    /**
     * Article list owner data
     * @example {"name":"Joe Doe","customerUserId":"db7cf15b-2a1a-4f80-b2d9-a63d0be08773","currentUser":false}
     */
    createdBy: {
      /**
       * Name of article list owner
       * @example "Joe Doe"
       */
      name: string
      /**
       * Customer user ID if the owner is a customer user, null otherwise
       * @example "db7cf15b-2a1a-4f80-b2d9-a63d0be08773"
       */
      customerUserId?: string
      /**
       * OCI user ID if the owner is an OCI user, null otherwise
       * @example "907647b9-1f2d-400b-971a-7a62c3978d36"
       */
      ociUserId?: string
      /**
       * True if it is the current user
       * @example false
       */
      currentUser: boolean
    }
    /** access level to the article list */
    accessLevel: ModelCustomerArticleListsSearchOciAccessLevelEnum
    sharedUserInfo?: {
      /**
       * Count of users assigned to the resource
       * @format int64
       * @example 10
       */
      assignedUsersCount: number
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned: boolean
      /**
       * True if all oci users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
      /**
       * True if user groups are assigned to the resource
       * @example true
       */
      userGroupsAssigned: boolean
    }
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelCustomerArticleListsSearchOciError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Basic definition of article list */
export interface ModelCreateArticleListPayload {
  /**
   * Name of article list
   * @minLength 1
   * @example "Office supplies"
   */
  name: string
}

/** Basic definition of article list */
export interface ModelCreateArticleListData {
  /**
   * Article list ID
   * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
   */
  id: string
}

/** access level to the article list */
export type ModelGetArticleListAccessLevelEnum = 'ADMIN' | 'WRITE' | 'READ'

/** Article list basic data for listing */
export interface ModelGetArticleListData {
  /**
   * Article list ID
   * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
   */
  id: string
  /**
   * Name of article list
   * @example "Office supplies"
   */
  name: string
  /**
   * Full date and time of article list added formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  createdAt: string
  /**
   * Full date and time of article list updated formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  updatedAt: string
  /**
   * Number of articles added to the list
   * @example 11
   */
  articlesCount: number
  /**
   * Number of vendors that are providing articles on the list
   * @example 3
   */
  vendorsCount: number
  /**
   * Article list owner data
   * @example {"name":"Joe Doe","customerUserId":"db7cf15b-2a1a-4f80-b2d9-a63d0be08773","currentUser":false}
   */
  createdBy: {
    /**
     * Name of article list owner
     * @example "Joe Doe"
     */
    name: string
    /**
     * Customer user ID if the owner is a customer user, null otherwise
     * @example "db7cf15b-2a1a-4f80-b2d9-a63d0be08773"
     */
    customerUserId?: string
    /**
     * OCI user ID if the owner is an OCI user, null otherwise
     * @example "907647b9-1f2d-400b-971a-7a62c3978d36"
     */
    ociUserId?: string
    /**
     * True if it is the current user
     * @example false
     */
    currentUser: boolean
  }
  /** access level to the article list */
  accessLevel: ModelGetArticleListAccessLevelEnum
  sharedUserInfo?: {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  }
}

/** Basic definition of article list */
export interface ModelUpdateArticleListPayload {
  /**
   * Name of article list
   * @minLength 1
   * @example "Office supplies"
   */
  name: string
}

export type ModelUpdateArticleListData = any

/** Error */
export type ModelUpdateArticleListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteArticleListData = any

/** Error */
export type ModelDeleteArticleListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** access level to the article list */
export type ModelGetOciArticleListAccessLevelEnum = 'ADMIN' | 'WRITE' | 'READ'

/** Article list basic data for listing */
export interface ModelGetOciArticleListData {
  /**
   * Article list ID
   * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
   */
  id: string
  /**
   * Name of article list
   * @example "Office supplies"
   */
  name: string
  /**
   * Full date and time of article list added formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  createdAt: string
  /**
   * Full date and time of article list updated formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
   * @format date-time
   * @example "2017-07-21T17:32:28.682Z"
   */
  updatedAt: string
  /**
   * Number of articles added to the list
   * @example 11
   */
  articlesCount: number
  /**
   * Number of vendors that are providing articles on the list
   * @example 3
   */
  vendorsCount: number
  /**
   * Article list owner data
   * @example {"name":"Joe Doe","customerUserId":"db7cf15b-2a1a-4f80-b2d9-a63d0be08773","currentUser":false}
   */
  createdBy: {
    /**
     * Name of article list owner
     * @example "Joe Doe"
     */
    name: string
    /**
     * Customer user ID if the owner is a customer user, null otherwise
     * @example "db7cf15b-2a1a-4f80-b2d9-a63d0be08773"
     */
    customerUserId?: string
    /**
     * OCI user ID if the owner is an OCI user, null otherwise
     * @example "907647b9-1f2d-400b-971a-7a62c3978d36"
     */
    ociUserId?: string
    /**
     * True if it is the current user
     * @example false
     */
    currentUser: boolean
  }
  /** access level to the article list */
  accessLevel: ModelGetOciArticleListAccessLevelEnum
  sharedUserInfo?: {
    /**
     * Count of users assigned to the resource
     * @format int64
     * @example 10
     */
    assignedUsersCount: number
    /**
     * True if all customer users are assigned to the resource
     * @example true
     */
    allCustomerUsersAssigned: boolean
    /**
     * True if all oci users are assigned to the resource
     * @example true
     */
    allOciUsersAssigned?: boolean
    /**
     * True if user groups are assigned to the resource
     * @example true
     */
    userGroupsAssigned: boolean
  }
}

/** Basic definition of article list */
export interface ModelUpdateOciArticleListPayload {
  /**
   * Name of article list
   * @minLength 1
   * @example "Office supplies"
   */
  name: string
}

export type ModelUpdateOciArticleListData = any

/** Error */
export type ModelUpdateOciArticleListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteOciArticleListData = any

/** Error */
export type ModelDeleteOciArticleListError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** access level to the article list */
export type ModelGetArticleListAssignmentAccessLevelEnum =
  | 'ADMIN'
  | 'WRITE'
  | 'READ'

/** Article lists with article assignment information */
export type ModelGetArticleListAssignmentData = {
  /**
   * Article list ID
   * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
   */
  articleListId: string
  /**
   * Name of article list
   * @example "Office supplies"
   */
  name: string
  /**
   * Number of articles added to the list
   * @example 11
   */
  articlesCount: number
  /**
   * Number of vendors that are providing articles on the list
   * @example 3
   */
  vendorsCount: number
  /** access level to the article list */
  accessLevel?: ModelGetArticleListAssignmentAccessLevelEnum
  /** True if the article is assigned to this list */
  articleAssigned: boolean
}[]

/** Error */
export type ModelGetArticleListAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to change multiple article lists article assignment information */
export interface ModelUpdateArticleListAssignmentPayload {
  assignments: {
    /**
     * Article list ID
     * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
     */
    articleListId: string
    /** True if the article is assigned to this list */
    articleAssigned: boolean
  }[]
  /**
   * @format int32
   * @min 1
   */
  initialQuantity?: number
}

export type ModelUpdateArticleListAssignmentData = any

/**
 * Error
 * Error
 */
export type ModelUpdateArticleListAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** access level to the article list */
export type ModelGetOciArticleListAssignmentAccessLevelEnum =
  | 'ADMIN'
  | 'WRITE'
  | 'READ'

/** Article lists with article assignment information */
export type ModelGetOciArticleListAssignmentData = {
  /**
   * Article list ID
   * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
   */
  articleListId: string
  /**
   * Name of article list
   * @example "Office supplies"
   */
  name: string
  /**
   * Number of articles added to the list
   * @example 11
   */
  articlesCount: number
  /**
   * Number of vendors that are providing articles on the list
   * @example 3
   */
  vendorsCount: number
  /** access level to the article list */
  accessLevel?: ModelGetOciArticleListAssignmentAccessLevelEnum
  /** True if the article is assigned to this list */
  articleAssigned: boolean
}[]

/** Error */
export type ModelGetOciArticleListAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Request to change multiple article lists article assignment information */
export interface ModelUpdateOciArticleListAssignmentPayload {
  assignments: {
    /**
     * Article list ID
     * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
     */
    articleListId: string
    /** True if the article is assigned to this list */
    articleAssigned: boolean
  }[]
  /**
   * @format int32
   * @min 1
   */
  initialQuantity?: number
}

export type ModelUpdateOciArticleListAssignmentData = any

/**
 * Error
 * Error
 */
export type ModelUpdateOciArticleListAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelSearchArticleListItemsVersionEnum = 'ECLASS_5_1' | 'ECLASS_7_1'

export type ModelSearchArticleListItemsSourceEnum =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelSearchArticleListItemsValueEnum =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelSearchArticleListItemsTypeEnum = 'CATALOG' | 'PRICE_LIST'

/** Search parameters for article list items */
export interface ModelSearchArticleListItemsPayload {
  /**
   * Search term
   * @example "articleListItemX"
   */
  term?: string
}

export interface ModelSearchArticleListItemsParams {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Whether to paginate results or not; defaults to true
   * @default true
   * @example false
   */
  paginated?: boolean
  /**
   * Article list Id
   * @example "05bb8184-fd2f-4a2a-9592-3ea2fc6c13e5"
   */
  articleListId: string
}

export interface ModelSearchArticleListItemsData {
  /** Articles in a given article list */
  items: {
    articleData: {
      /** Article information */
      article: {
        /**
         * Article ID
         * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
         */
        id: string
        /**
         * Article name
         * @example "Drilling machine"
         */
        name: string
        /**
         * Article number (Vendor’s unique article number)
         * @example "ABC123456"
         */
        number: string
        /**
         * EAN number
         * @example 12345678
         */
        ean: string
        eclass?: {
          /** Version of eClass specification */
          version: ModelSearchArticleListItemsVersionEnum
          /** eClass value formatted with dashes between groups ("12-34-56-78") */
          code?: string | null
          /**
           * eClass name
           * @example "Catering machinery"
           */
          name?: string | null
        }
        /**
         * Article number used in customer systems
         * @example "VABC123456"
         */
        customerArticleNumber: string
        /**
         * Article number used by manufacturer
         * @example "ABC123"
         */
        manufacturerArticleNumber?: string
        /**
         * Manufacturer name
         * @example "ACME Inc."
         */
        manufacturer: string
        /**
         * Maximum delivery time in days declared by vendor
         * @deprecated
         * @min 0
         * @max 999999
         * @example 3
         */
        deliveryTime: number
        /** Delivery time details */
        deliveryInfo: {
          /**
           * Maximum delivery time in days declared by vendor
           * @min 0
           * @example 3
           */
          days: number
          source: ModelSearchArticleListItemsSourceEnum
          /**
           * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        /** Stock level info */
        stockInfo: {
          value: ModelSearchArticleListItemsValueEnum
          /**
           * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        minimumOrder?: number
        quantityInterval?: number
        contentUnit?: string
        /** @example 0.001 */
        contentAmount?: string
        /** @format double */
        tax: number
        /** Pricing, order unit and constraints on quantity */
        orderOptions?: {
          /** Flag describing if this article is restricted from adding to the cart */
          restricted?: boolean
          /**
           * Article quantity for which price (pricePerOrderUnit) is defined
           * @example 0.001
           */
          priceQuantity: string
          /** Prices of the article */
          pricing: {
            /** @example 0.001 */
            quantity?: string
            /** Amount of money */
            price?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
            /** Pricing source information, returned only for vendor preview */
            source?: {
              type: ModelSearchArticleListItemsTypeEnum
              priceListFilename?: string | null
            }
          }[]
        }
        coreArticle: boolean
        closedMarketplace: boolean
      } & {
        /**
         * URL to the thumbnail of the article
         * @format url
         * @example "https://simplesystem.com/images/1234.jpg"
         */
        thumbnailUrl?: string
        /** Unit in which the article is sold */
        orderUnit: string
        /** @default false */
        isIncludedInArticleList: boolean
        /** Explanation of search score calculation */
        explanation?: string
      }
      vendor: {
        /**
         * Vendor ID
         * @example "V12345"
         */
        id: string
        /**
         * Name of the vendor
         * @example "Hoffmann Group"
         */
        name: string
        /**
         * URL to the vendor company logo
         * @format url
         * @example "https://simplesystem.com/images/logo_url.jpg"
         */
        logoUrl?: string
      }
      externalReferences?: {
        /** @example "07844154-281c-4bf0-97de-bd890c615192" */
        id: string
        /** @example "12345678" */
        barcode: string
        /**
         * @min 1
         * @example 9
         */
        quantity: number
      }[]
    }
    /** Amount of money */
    priceUsed: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * @format int32
     * @min 1
     */
    quantity: number
    /**
     * Indicates whether an item on the list is still available
     * @example true
     */
    available: boolean
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchArticleListItemsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelSearchArticleListItemsVersionEnum1 =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export type ModelSearchArticleListItemsSourceEnum1 =
  | 'CATALOG'
  | 'STOCK_AND_DELIVERY_INFO'

export type ModelSearchArticleListItemsValueEnum1 =
  | 'IN_STOCK'
  | 'OUT_OF_STOCK'
  | 'NOT_PROVIDED'

export type ModelSearchArticleListItemsTypeEnum1 = 'CATALOG' | 'PRICE_LIST'

/** Search parameters for article list items */
export interface ModelSearchArticleListItemsBody {
  /**
   * Search term
   * @example "articleListItemX"
   */
  term?: string
}

export interface ModelSearchArticleListItemsParams2 {
  /**
   * Page number (starting with 1)
   * @format int32
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Number of items on the page
   * @format int32
   * @min 1
   * @max 50
   * @default 10
   */
  pageSize?: number
  /**
   * Whether to paginate results or not; defaults to true
   * @default true
   * @example false
   */
  paginated?: boolean
  /**
   * Article list Id
   * @example "05bb8184-fd2f-4a2a-9592-3ea2fc6c13e5"
   */
  articleListId: string
}

export interface ModelSearchArticleListItemsResult {
  /** Articles in a given article list */
  items: {
    articleData: {
      /** Article information */
      article: {
        /**
         * Article ID
         * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
         */
        id: string
        /**
         * Article name
         * @example "Drilling machine"
         */
        name: string
        /**
         * Article number (Vendor’s unique article number)
         * @example "ABC123456"
         */
        number: string
        /**
         * EAN number
         * @example 12345678
         */
        ean: string
        eclass?: {
          /** Version of eClass specification */
          version: ModelSearchArticleListItemsVersionEnum1
          /** eClass value formatted with dashes between groups ("12-34-56-78") */
          code?: string | null
          /**
           * eClass name
           * @example "Catering machinery"
           */
          name?: string | null
        }
        /**
         * Article number used in customer systems
         * @example "VABC123456"
         */
        customerArticleNumber: string
        /**
         * Article number used by manufacturer
         * @example "ABC123"
         */
        manufacturerArticleNumber?: string
        /**
         * Manufacturer name
         * @example "ACME Inc."
         */
        manufacturer: string
        /**
         * Maximum delivery time in days declared by vendor
         * @deprecated
         * @min 0
         * @max 999999
         * @example 3
         */
        deliveryTime: number
        /** Delivery time details */
        deliveryInfo: {
          /**
           * Maximum delivery time in days declared by vendor
           * @min 0
           * @example 3
           */
          days: number
          source: ModelSearchArticleListItemsSourceEnum1
          /**
           * Full date and time of delivery info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        /** Stock level info */
        stockInfo: {
          value: ModelSearchArticleListItemsValueEnum1
          /**
           * Full date and time of stock info update formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
           * @format date-time
           * @example "2024-08-22T17:32:28.682Z"
           */
          updatedAt?: string | null
        }
        minimumOrder?: number
        quantityInterval?: number
        contentUnit?: string
        /** @example 0.001 */
        contentAmount?: string
        /** @format double */
        tax: number
        /** Pricing, order unit and constraints on quantity */
        orderOptions?: {
          /** Flag describing if this article is restricted from adding to the cart */
          restricted?: boolean
          /**
           * Article quantity for which price (pricePerOrderUnit) is defined
           * @example 0.001
           */
          priceQuantity: string
          /** Prices of the article */
          pricing: {
            /** @example 0.001 */
            quantity?: string
            /** Amount of money */
            price?: {
              /**
               * Amount in minor currency (smallest indivisible unit) of the specified currency
               * @format int64
               * @min -1000000000000000
               * @max 1000000000000000
               * @example 1234
               */
              amount: number
              /**
               * ISO 4217 currency code
               * @example "EUR"
               */
              currency: string
            }
            /** Pricing source information, returned only for vendor preview */
            source?: {
              type: ModelSearchArticleListItemsTypeEnum1
              priceListFilename?: string | null
            }
          }[]
        }
        coreArticle: boolean
        closedMarketplace: boolean
      } & {
        /**
         * URL to the thumbnail of the article
         * @format url
         * @example "https://simplesystem.com/images/1234.jpg"
         */
        thumbnailUrl?: string
        /** Unit in which the article is sold */
        orderUnit: string
        /** @default false */
        isIncludedInArticleList: boolean
        /** Explanation of search score calculation */
        explanation?: string
      }
      vendor: {
        /**
         * Vendor ID
         * @example "V12345"
         */
        id: string
        /**
         * Name of the vendor
         * @example "Hoffmann Group"
         */
        name: string
        /**
         * URL to the vendor company logo
         * @format url
         * @example "https://simplesystem.com/images/logo_url.jpg"
         */
        logoUrl?: string
      }
      externalReferences?: {
        /** @example "07844154-281c-4bf0-97de-bd890c615192" */
        id: string
        /** @example "12345678" */
        barcode: string
        /**
         * @min 1
         * @example 9
         */
        quantity: number
      }[]
    }
    /** Amount of money */
    priceUsed: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * @format int32
     * @min 1
     */
    quantity: number
    /**
     * Indicates whether an item on the list is still available
     * @example true
     */
    available: boolean
  }[]
  /** Search metadata */
  metadata: {
    /**
     * Page number
     * @format int32
     * @example 1
     */
    page: number
    /**
     * Number of items on the page
     * @format int32
     * @example 10
     */
    pageSize: number
    /**
     * Number of pages available
     * @format int32
     * @example 5
     */
    pageCount: number
    /**
     * Search term
     * @example "drill"
     */
    term?: string
    /**
     * Number of search hits
     * @format int64
     * @example 19
     */
    hitCount?: number
  }
}

/** Error */
export type ModelSearchArticleListItemsFail = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetBarcodeArticleListItemsVersionEnum =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export interface ModelGetBarcodeArticleListItemsData {
  /** Articles in a given article list */
  items: {
    articleData: {
      /**
       * eClass value formatted with dashes between groups ("12-34-56-78")
       * @pattern ^\d{2}-\d{2}-\d{2}-\d{2}$
       * @example "12-34-56-78"
       */
      eclassCode: string
      vendor: {
        /**
         * Vendor ID
         * @example "V12345"
         */
        id: string
        /**
         * Name of the vendor
         * @example "Hoffmann Group"
         */
        name: string
        /**
         * URL to the vendor company logo
         * @format url
         * @example "https://simplesystem.com/images/logo_url.jpg"
         */
        logoUrl?: string
      }
      externalReferences?: {
        /** @example "07844154-281c-4bf0-97de-bd890c615192" */
        id: string
        /** @example "12345678" */
        barcode: string
        /**
         * @min 1
         * @example 9
         */
        quantity: number
      }[]
      article: {
        /**
         * Article ID
         * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
         */
        id: string
        /**
         * Article name
         * @example "Drilling machine"
         */
        name: string
        /**
         * Article number (Vendor’s unique article number)
         * @example "ABC123456"
         */
        number: string
        /**
         * EAN number
         * @example 12345678
         */
        ean: string
        eclass?: {
          /** Version of eClass specification */
          version: ModelGetBarcodeArticleListItemsVersionEnum
          /** eClass value formatted with dashes between groups ("12-34-56-78") */
          code?: string | null
          /**
           * eClass name
           * @example "Catering machinery"
           */
          name?: string | null
        }
        /**
         * Article number used in customer systems
         * @example "VABC123456"
         */
        customerArticleNumber: string
        /**
         * Article number used by manufacturer
         * @example "ABC123"
         */
        manufacturerArticleNumber?: string
        /**
         * Manufacturer name
         * @example "ACME Inc."
         */
        manufacturer: string
        /**
         * Maximum delivery time in days declared by vendor
         * @min 0
         * @max 999999
         * @example 3
         */
        deliveryTime: number
        minimumOrder?: number
        quantityInterval?: number
        contentUnit?: string
        /** @example 0.001 */
        contentAmount?: string
        /** @format double */
        tax: number
        coreArticle: boolean
        closedMarketplace: boolean
        /**
         * URL to the thumbnail of the article
         * @format url
         * @example "https://simplesystem.com/images/1234.jpg"
         */
        thumbnailUrl?: string
        /** Unit in which the article is sold */
        orderUnit?: string
      }
    }
    /**
     * @format int32
     * @min 1
     */
    quantity: number
    /**
     * Indicates whether an item on the list is still available
     * @example true
     */
    available: boolean
  }[]
}

/** Error */
export type ModelGetBarcodeArticleListItemsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Version of eClass specification */
export type ModelGetBarcodeArticleListItemsVersionEnum1 =
  | 'ECLASS_5_1'
  | 'ECLASS_7_1'

export interface ModelGetBarcodeArticleListItemsResult {
  /** Articles in a given article list */
  items: {
    articleData: {
      /**
       * eClass value formatted with dashes between groups ("12-34-56-78")
       * @pattern ^\d{2}-\d{2}-\d{2}-\d{2}$
       * @example "12-34-56-78"
       */
      eclassCode: string
      vendor: {
        /**
         * Vendor ID
         * @example "V12345"
         */
        id: string
        /**
         * Name of the vendor
         * @example "Hoffmann Group"
         */
        name: string
        /**
         * URL to the vendor company logo
         * @format url
         * @example "https://simplesystem.com/images/logo_url.jpg"
         */
        logoUrl?: string
      }
      externalReferences?: {
        /** @example "07844154-281c-4bf0-97de-bd890c615192" */
        id: string
        /** @example "12345678" */
        barcode: string
        /**
         * @min 1
         * @example 9
         */
        quantity: number
      }[]
      article: {
        /**
         * Article ID
         * @example "4906cd7b-2810-4671-963b-81be91d2ad25"
         */
        id: string
        /**
         * Article name
         * @example "Drilling machine"
         */
        name: string
        /**
         * Article number (Vendor’s unique article number)
         * @example "ABC123456"
         */
        number: string
        /**
         * EAN number
         * @example 12345678
         */
        ean: string
        eclass?: {
          /** Version of eClass specification */
          version: ModelGetBarcodeArticleListItemsVersionEnum1
          /** eClass value formatted with dashes between groups ("12-34-56-78") */
          code?: string | null
          /**
           * eClass name
           * @example "Catering machinery"
           */
          name?: string | null
        }
        /**
         * Article number used in customer systems
         * @example "VABC123456"
         */
        customerArticleNumber: string
        /**
         * Article number used by manufacturer
         * @example "ABC123"
         */
        manufacturerArticleNumber?: string
        /**
         * Manufacturer name
         * @example "ACME Inc."
         */
        manufacturer: string
        /**
         * Maximum delivery time in days declared by vendor
         * @min 0
         * @max 999999
         * @example 3
         */
        deliveryTime: number
        minimumOrder?: number
        quantityInterval?: number
        contentUnit?: string
        /** @example 0.001 */
        contentAmount?: string
        /** @format double */
        tax: number
        coreArticle: boolean
        closedMarketplace: boolean
        /**
         * URL to the thumbnail of the article
         * @format url
         * @example "https://simplesystem.com/images/1234.jpg"
         */
        thumbnailUrl?: string
        /** Unit in which the article is sold */
        orderUnit?: string
      }
    }
    /**
     * @format int32
     * @min 1
     */
    quantity: number
    /**
     * Indicates whether an item on the list is still available
     * @example true
     */
    available: boolean
  }[]
}

/** Error */
export type ModelGetBarcodeArticleListItemsFail = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Article list item update */
export interface ModelUpdateArticleListItemPayload {
  /**
   * Quantity of articles for given item
   * @min 1
   * @example 5
   */
  quantity: number
}

export type ModelUpdateArticleListItemData = any

/**
 * Error
 * Error
 */
export type ModelUpdateArticleListItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteArticleListItemData = any

/**
 * Error
 * Error
 */
export type ModelDeleteArticleListItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Article list item update */
export interface ModelUpdateOciArticleListItemPayload {
  /**
   * Quantity of articles for given item
   * @min 1
   * @example 5
   */
  quantity: number
}

export type ModelUpdateOciArticleListItemData = any

/**
 * Error
 * Error
 */
export type ModelUpdateOciArticleListItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteOciArticleListItemData = any

/**
 * Error
 * Error
 */
export type ModelDeleteOciArticleListItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetSharedCustomerUsersToArticleListsData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetSharedCustomerUsersToArticleListsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelGetCollaboratedCustomerUsersToArticleListsData {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetCollaboratedCustomerUsersToArticleListsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelCollaborateArticleListsWithCustomerUsersPayload {
  /** List of IDs of customer users assigned to the resource */
  customerUserIds: string[]
  /** List of IDs of OCI users assigned to the resource */
  ociUserIds?: string[]
}

export type ModelCollaborateArticleListsWithCustomerUsersData = any

/**
 * Error
 * Error
 */
export type ModelCollaborateArticleListsWithCustomerUsersError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Validity period for budgets */
export type ModelGetCurrentUserBudgetsListRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** Validity period for budgets */
export type ModelGetCurrentUserBudgetsListRefreshPeriodEnum1 =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** List of all budgets assigned to user */
export interface ModelGetCurrentUserBudgetsListData {
  /** List of accounting based budgets that have been exceeded */
  budgets: (({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetCurrentUserBudgetsListRefreshPeriodEnum
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget enabled flag
     * @example true
     */
    enabled: boolean
  }) & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /** An amount of money left to spend on a given budget in a given time span */
    budgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** An amount of money spent on a given budget in a given time span */
    budgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit without additional amount */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget for current period */
    additionalBudgetAmount: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of already spent additional budget for current period (total amount of spent budget minus current budget limit) */
    additionalBudgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget left in the current period */
    additionalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount */
    totalBudgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount left in the current period */
    totalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    accountType?: {
      /**
       * Account type ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account type prefix
       * @example "K"
       */
      prefix: string
      /**
       * Account type description
       * @example "Project Name"
       */
      description: string
    }
    accountValue?: {
      /**
       * Account value ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account value number
       * @example "12213131"
       */
      number: string
      /**
       * Account value description (null for free-text values)
       * @example "Next Gen"
       */
      description: string
    }
    /**
     * Name and surname of user who has added the account type
     * @example "Erika Mustermann"
     */
    createdBy?: string | null
    /**
     * Percentage of utilised budget
     * @min 0
     * @example 80
     */
    percentageUsed: number
  })[]
  /** List of individual budgets that have been exceeded */
  individualBudgets: (({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetCurrentUserBudgetsListRefreshPeriodEnum1
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name and surname of user who has added the budget
     * @example "Erika Gonzalez"
     */
    createdBy: string
    /**
     * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
     * When eClass node has `true` value its selected and all of its children are also selected.
     * Mapping to `false` clears mapping defined by parents
     * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
     */
    eClasses?: Record<string, boolean>
    assignments?: {
      /**
       * Count of users assigned to the resource
       * @format int64
       * @example 10
       */
      assignedUsersCount: number
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned: boolean
      /**
       * True if user groups are assigned to the resource
       * @example true
       */
      userGroupsAssigned: boolean
    }
  }) & {
    /** Budget limit */
    budgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** An amount of money spent on a given budget in a given time span */
    budgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Percentage of utilised budget
     * @min 0
     * @example 80
     */
    percentageUsed: number
    /**
     * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
     * When eClass node has `true` value its selected and all of its children are also selected.
     * Mapping to `false` clears mapping defined by parents
     * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
     */
    eClasses?: Record<string, boolean>
  })[]
}

/** Validity period for budgets */
export type ModelGetUserBudgetsListRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** Validity period for budgets */
export type ModelGetUserBudgetsListRefreshPeriodEnum1 =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** List of all budgets assigned to user */
export interface ModelGetUserBudgetsListData {
  /** List of accounting based budgets that have been exceeded */
  budgets: (({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetUserBudgetsListRefreshPeriodEnum
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget enabled flag
     * @example true
     */
    enabled: boolean
  }) & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /** An amount of money left to spend on a given budget in a given time span */
    budgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** An amount of money spent on a given budget in a given time span */
    budgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit without additional amount */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget for current period */
    additionalBudgetAmount: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of already spent additional budget for current period (total amount of spent budget minus current budget limit) */
    additionalBudgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget left in the current period */
    additionalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount */
    totalBudgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount left in the current period */
    totalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    accountType?: {
      /**
       * Account type ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account type prefix
       * @example "K"
       */
      prefix: string
      /**
       * Account type description
       * @example "Project Name"
       */
      description: string
    }
    accountValue?: {
      /**
       * Account value ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account value number
       * @example "12213131"
       */
      number: string
      /**
       * Account value description (null for free-text values)
       * @example "Next Gen"
       */
      description: string
    }
    /**
     * Name and surname of user who has added the account type
     * @example "Erika Mustermann"
     */
    createdBy?: string | null
    /**
     * Percentage of utilised budget
     * @min 0
     * @example 80
     */
    percentageUsed: number
  })[]
  /** List of individual budgets that have been exceeded */
  individualBudgets: (({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetUserBudgetsListRefreshPeriodEnum1
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name and surname of user who has added the budget
     * @example "Erika Gonzalez"
     */
    createdBy: string
    /**
     * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
     * When eClass node has `true` value its selected and all of its children are also selected.
     * Mapping to `false` clears mapping defined by parents
     * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
     */
    eClasses?: Record<string, boolean>
    assignments?: {
      /**
       * Count of users assigned to the resource
       * @format int64
       * @example 10
       */
      assignedUsersCount: number
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned: boolean
      /**
       * True if user groups are assigned to the resource
       * @example true
       */
      userGroupsAssigned: boolean
    }
  }) & {
    /** Budget limit */
    budgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** An amount of money spent on a given budget in a given time span */
    budgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /**
     * Percentage of utilised budget
     * @min 0
     * @example 80
     */
    percentageUsed: number
    /**
     * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
     * When eClass node has `true` value its selected and all of its children are also selected.
     * Mapping to `false` clears mapping defined by parents
     * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
     */
    eClasses?: Record<string, boolean>
  })[]
}

/** Validity period for budgets */
export type ModelGetBudgetsListRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

export interface ModelGetBudgetsListData {
  /** List of budgets */
  budgets: (({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetBudgetsListRefreshPeriodEnum
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget enabled flag
     * @example true
     */
    enabled: boolean
  }) & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /** An amount of money left to spend on a given budget in a given time span */
    budgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** An amount of money spent on a given budget in a given time span */
    budgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit without additional amount */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget for current period */
    additionalBudgetAmount: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of already spent additional budget for current period (total amount of spent budget minus current budget limit) */
    additionalBudgetSpent: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Amount of additional budget left in the current period */
    additionalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount */
    totalBudgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    /** Budget limit together with additional amount left in the current period */
    totalBudgetLeft: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
    accountType?: {
      /**
       * Account type ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account type prefix
       * @example "K"
       */
      prefix: string
      /**
       * Account type description
       * @example "Project Name"
       */
      description: string
    }
    accountValue?: {
      /**
       * Account value ID
       * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
       */
      id: string
      /**
       * Account value number
       * @example "12213131"
       */
      number: string
      /**
       * Account value description (null for free-text values)
       * @example "Next Gen"
       */
      description: string
    }
    /**
     * Name and surname of user who has added the account type
     * @example "Erika Mustermann"
     */
    createdBy?: string | null
    /**
     * Percentage of utilised budget
     * @min 0
     * @example 80
     */
    percentageUsed: number
  })[]
}

/** Validity period for budgets */
export type ModelAddNewBudgetRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

export type ModelAddNewBudgetPayload = ({
  /**
   * Budget name
   * @minLength 1
   * @example "Sales budget for Q4"
   */
  name: string
  /** Validity period for budgets */
  refreshPeriod: ModelAddNewBudgetRefreshPeriodEnum
  /**
   * In case of YEARLY refresh period, month in which the year starts
   * @min 1
   * @max 12
   * @example 6
   */
  yearlyRefreshPeriodStartingFromMonth?: number
  /** Allowed total value of articles bought */
  budgetLimit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
} & {
  /**
   * Budget enabled flag
   * @example true
   */
  enabled: boolean
}) & {
  /**
   * Account value ID
   * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
   */
  accountValueId: string
}

export type ModelAddNewBudgetData = any

/**
 * Error
 * Error
 */
export type ModelAddNewBudgetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Validity period for budgets */
export type ModelGetBudgetRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** Description of single budget */
export type ModelGetBudgetData = ({
  /**
   * Budget name
   * @minLength 1
   * @example "Sales budget for Q4"
   */
  name: string
  /** Validity period for budgets */
  refreshPeriod: ModelGetBudgetRefreshPeriodEnum
  /**
   * In case of YEARLY refresh period, month in which the year starts
   * @min 1
   * @max 12
   * @example 6
   */
  yearlyRefreshPeriodStartingFromMonth?: number
  /** Allowed total value of articles bought */
  budgetLimit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
} & {
  /**
   * Budget enabled flag
   * @example true
   */
  enabled: boolean
}) & {
  /**
   * Budget ID
   * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
   */
  id: string
  /** An amount of money left to spend on a given budget in a given time span */
  budgetLeft: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** An amount of money spent on a given budget in a given time span */
  budgetSpent: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** Budget limit without additional amount */
  budgetLimit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** Amount of additional budget for current period */
  additionalBudgetAmount: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** Amount of already spent additional budget for current period (total amount of spent budget minus current budget limit) */
  additionalBudgetSpent: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** Amount of additional budget left in the current period */
  additionalBudgetLeft: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** Budget limit together with additional amount */
  totalBudgetLimit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /** Budget limit together with additional amount left in the current period */
  totalBudgetLeft: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  accountType?: {
    /**
     * Account type ID
     * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
     */
    id: string
    /**
     * Account type prefix
     * @example "K"
     */
    prefix: string
    /**
     * Account type description
     * @example "Project Name"
     */
    description: string
  }
  accountValue?: {
    /**
     * Account value ID
     * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
     */
    id: string
    /**
     * Account value number
     * @example "12213131"
     */
    number: string
    /**
     * Account value description (null for free-text values)
     * @example "Next Gen"
     */
    description: string
  }
  /**
   * Name and surname of user who has added the account type
   * @example "Erika Mustermann"
   */
  createdBy?: string | null
  /**
   * Percentage of utilised budget
   * @min 0
   * @example 80
   */
  percentageUsed: number
}

/** Validity period for budgets */
export type ModelUpdateBudgetRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

export interface ModelUpdateBudgetPayload {
  /**
   * Budget name
   * @minLength 1
   * @example "Sales budget for Q4"
   */
  name?: string
  /** Validity period for budgets */
  refreshPeriod?: ModelUpdateBudgetRefreshPeriodEnum
  /**
   * In case of YEARLY refresh period, month in which the year starts
   * @min 1
   * @max 12
   * @example 6
   */
  yearlyRefreshPeriodStartingFromMonth?: number | null
  /** Allowed total value of articles bought */
  budgetLimit?: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
  /**
   * Account value ID
   * @example "7eced8a1-daaf-4020-879e-99e9ea66a041"
   */
  accountValueId?: string
  /**
   * Budget enabled flag
   * @example true
   */
  enabled?: boolean
}

export type ModelUpdateBudgetData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateBudgetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteBudgetData = any

/** Error */
export type ModelDeleteBudgetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Update of additional budget amount */
export interface ModelUpdateAdditionalBudgetPayload {
  /** Amount of additional budget for current period. Use 0 to delete additional budget. */
  additionalBudgetAmount: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
}

export type ModelUpdateAdditionalBudgetData = any

/**
 * Error
 * Error
 */
export type ModelUpdateAdditionalBudgetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Validity period for budgets */
export type ModelGetIndividualBudgetsListRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

export interface ModelGetIndividualBudgetsListData {
  /** List of individual budgets */
  individualBudgets: ({
    /**
     * Budget name
     * @minLength 1
     * @example "Sales budget for Q4"
     */
    name: string
    /** Validity period for budgets */
    refreshPeriod: ModelGetIndividualBudgetsListRefreshPeriodEnum
    /**
     * In case of YEARLY refresh period, month in which the year starts
     * @min 1
     * @max 12
     * @example 6
     */
    yearlyRefreshPeriodStartingFromMonth?: number
    /** Allowed total value of articles bought */
    budgetLimit: {
      /**
       * Amount in minor currency (smallest indivisible unit) of the specified currency
       * @format int64
       * @min -1000000000000000
       * @max 1000000000000000
       * @example 1234
       */
      amount: number
      /**
       * ISO 4217 currency code
       * @example "EUR"
       */
      currency: string
    }
  } & {
    /**
     * Budget ID
     * @example "bb50273a-5200-44d4-80e1-3a150d1b4149"
     */
    id: string
    /**
     * Name and surname of user who has added the budget
     * @example "Erika Gonzalez"
     */
    createdBy: string
    /**
     * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
     * When eClass node has `true` value its selected and all of its children are also selected.
     * Mapping to `false` clears mapping defined by parents
     * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
     */
    eClasses?: Record<string, boolean>
    assignments?: {
      /**
       * Count of users assigned to the resource
       * @format int64
       * @example 10
       */
      assignedUsersCount: number
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned: boolean
      /**
       * True if user groups are assigned to the resource
       * @example true
       */
      userGroupsAssigned: boolean
    }
  })[]
}

/** Validity period for budgets */
export type ModelAddNewIndividualBudgetRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** Budget basic data */
export type ModelAddNewIndividualBudgetPayload = {
  /**
   * Budget name
   * @minLength 1
   * @example "Sales budget for Q4"
   */
  name: string
  /** Validity period for budgets */
  refreshPeriod: ModelAddNewIndividualBudgetRefreshPeriodEnum
  /**
   * In case of YEARLY refresh period, month in which the year starts
   * @min 1
   * @max 12
   * @example 6
   */
  yearlyRefreshPeriodStartingFromMonth?: number
  /** Allowed total value of articles bought */
  budgetLimit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
} & {
  /**
   * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
   * When eClass node has `true` value its selected and all of its children are also selected.
   * Mapping to `false` clears mapping defined by parents
   * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
   */
  eClasses?: Record<string, boolean>
}

export type ModelAddNewIndividualBudgetData = any

/**
 * Error
 * Error
 */
export type ModelAddNewIndividualBudgetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteIndividualBudgetData = any

/** Error */
export type ModelDeleteIndividualBudgetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Validity period for budgets */
export type ModelUpdateIndividualBudgetRefreshPeriodEnum =
  | 'NONE'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'DAILY'

/** Budget basic data */
export type ModelUpdateIndividualBudgetPayload = {
  /**
   * Budget name
   * @minLength 1
   * @example "Sales budget for Q4"
   */
  name: string
  /** Validity period for budgets */
  refreshPeriod: ModelUpdateIndividualBudgetRefreshPeriodEnum
  /**
   * In case of YEARLY refresh period, month in which the year starts
   * @min 1
   * @max 12
   * @example 6
   */
  yearlyRefreshPeriodStartingFromMonth?: number
  /** Allowed total value of articles bought */
  budgetLimit: {
    /**
     * Amount in minor currency (smallest indivisible unit) of the specified currency
     * @format int64
     * @min -1000000000000000
     * @max 1000000000000000
     * @example 1234
     */
    amount: number
    /**
     * ISO 4217 currency code
     * @example "EUR"
     */
    currency: string
  }
} & {
  /**
   * Key value pairs where the key is eClass node and the value is a boolean representing the selected state.
   * When eClass node has `true` value its selected and all of its children are also selected.
   * Mapping to `false` clears mapping defined by parents
   * @example {"17-00-00-00":true,"17-01-01-00":false,"34-00-00-00":true,"34-20-00-00":false,"34-20-07-00":true}
   */
  eClasses?: Record<string, boolean>
}

export type ModelUpdateIndividualBudgetData = any

/**
 * Error
 * Error
 * Error
 */
export type ModelUpdateIndividualBudgetError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Matched field */
export type ModelGetFastOrderListMatchedFieldEnum =
  | 'EXTERNAL_REFERENCE'
  | 'CUSTOMER_ARTICLE_NUMBER'
  | 'VENDOR_ARTICLE_NUMBER'
  | 'EAN'

/** Response with list of fast order content */
export interface ModelGetFastOrderListData {
  /** List of fast order list items */
  items: {
    /**
     * id of the item
     * @example "07844154-281c-4bf0-97de-bd890c615192"
     */
    id: string
    /**
     * Scanned barcode
     * @example "12345678;8"
     */
    barcode: string
    /**
     * Number of scans performed for this barcode
     * @example 1
     */
    scanCount?: number
    /**
     * Full date and time of last scan of this barcode [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6)
     * @format date-time
     * @example "2017-07-21T17:32:28.682Z"
     */
    lastAddedAt: string
    /**
     * ID of the selected article from the `foundArticles` list
     * @example "e852e67c-263f-47e5-9cd8-9ee9479f8dc6"
     */
    selectedArticle?: string
    /**
     * current quantity of the article
     * @example 7
     */
    quantity?: number
    /**
     * True if article search is done
     * @example true
     */
    articleSearchDone: boolean
    /** List of articles matching scanned barcode */
    foundArticles: {
      /**
       * Id of the matched article
       * @example "3cdc0e6c-fe6e-4774-b37a-7723455dfdbe"
       */
      id: string
      /**
       * ArticleId of the matched article
       * @example "4cJC107hdSvW0NmKeouXXK:NTA4MDgyNA"
       */
      articleId: string
      /**
       * The article number specific to this customer
       * @example "C1ABC123456"
       */
      customerArticleNumber?: string
      /**
       * Article number of the matched article
       * @example 5080824
       */
      articleNumber: string
      /**
       * Article name of the matched article
       * @example "3-Monats-Tisch-Kalender JK2 Rahmen weiß 110 mm x 180 mm"
       */
      name: string
      /**
       * The European Article Number of this item
       * @example 4010730011647
       */
      ean?: string
      /**
       * Manufacturer name
       * @example "ACME Inc."
       */
      manufacturerName?: string
      /**
       * Manufacturer's article number of the matched article
       * @example "kal-3"
       */
      manufacturerArticleNumber?: string
      /**
       * Vendor name
       * @example "Hoffmann Group"
       */
      vendorName: string
      /** Matched field */
      matchedField?: ModelGetFastOrderListMatchedFieldEnum
      /**
       * Original quantity of the article associated to the scancode
       * @example 7
       */
      originalQuantity: number
      /**
       * Minimum quantity to order
       * @example 1
       */
      minimumOrder: number
      /**
       * Amount of quantity increase
       * @example 1
       */
      quantityInterval: number
      /** @format url */
      thumbnailUrl?: string
      /**
       * True if the article is still available to order
       * @example true
       */
      available: boolean
      /**
       * True if the article is not available to order due to user restrictions
       * @example true
       */
      restricted: boolean
      /** @example "PCE" */
      orderUnit: string
    }[]
  }[]
}

/** Type of the fast order list */
export type ModelGetFastOrderListParamsFastOrderListEnumEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Type of the fast order list */
export type ModelGetFastOrderListParamsEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

export interface ModelGetNumberOfFastOrderListItemsData {
  /** @example 5 */
  count: number
}

/** Type of the fast order list */
export type ModelGetNumberOfFastOrderListItemsParamsFastOrderListEnumEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Type of the fast order list */
export type ModelGetNumberOfFastOrderListItemsParamsEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Scanned barcode content */
export interface ModelAddItemToFastOrderListPayload {
  /**
   * Scanned barcode
   * @example "12345678;8"
   */
  barcode: string
}

export type ModelAddItemToFastOrderListData = any

/** Type of the fast order list */
export type ModelAddItemToFastOrderListParamsFastOrderListEnumEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Type of the fast order list */
export type ModelAddItemToFastOrderListParamsEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

export interface ModelAddItemsToFastOrderListPayload {
  /** @minItems 1 */
  barcodes: string[]
}

export type ModelAddItemsToFastOrderListData = any

/** Type of the fast order list */
export type ModelAddItemsToFastOrderListParamsFastOrderListEnumEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Type of the fast order list */
export type ModelAddItemsToFastOrderListParamsEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** List of fast order list item IDs */
export interface ModelDeleteFromFastOrderListPayload {
  /**
   * List of fast order list item IDs
   * @example ["9288f11c-219c-4e9f-addd-bc269f5e5b54","2eb4818f-d10f-486f-866e-bb51984f5936"]
   */
  fastOrderListItemIds: string[]
}

export type ModelDeleteFromFastOrderListData = any

/** Type of the fast order list */
export type ModelDeleteFromFastOrderListParamsFastOrderListEnumEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Type of the fast order list */
export type ModelDeleteFromFastOrderListParamsEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** List of fast order list item IDs */
export interface ModelMoveFromFastOrderListToCartPayload {
  /**
   * List of fast order list item IDs
   * @example ["9288f11c-219c-4e9f-addd-bc269f5e5b54","2eb4818f-d10f-486f-866e-bb51984f5936"]
   */
  fastOrderListItemIds: string[]
}

/** List of fast order list items moved to the cart */
export interface ModelMoveFromFastOrderListToCartData {
  /** List of fast order list items moved to the cart */
  successfullyAdded: {
    /**
     * Id of the matched article
     * @example "3cdc0e6c-fe6e-4774-b37a-7723455dfdbe"
     */
    id: string
    /**
     * ArticleId of the matched article
     * @example "4cJC107hdSvW0NmKeouXXK:NTA4MDgyNA"
     */
    articleId: string
    /**
     * URL to the thumbnail of the article
     * @format url
     * @example "https://simplesystem.com/images/1234.jpg"
     */
    thumbnailUrl?: string
  }[]
}

/** Error */
export type ModelMoveFromFastOrderListToCartError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Type of the fast order list */
export type ModelMoveFromFastOrderListToCartParamsFastOrderListEnumEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Type of the fast order list */
export type ModelMoveFromFastOrderListToCartParamsEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Fast order list item update */
export interface ModelUpdateFastOrderListItemPayload {
  /**
   * ID of the selected article from the `foundArticles` list
   * @example "e852e67c-263f-47e5-9cd8-9ee9479f8dc6"
   */
  selectedArticle?: string | null
  /**
   * New quantity of the article
   * @example 7
   */
  quantity?: number | null
}

export type ModelUpdateFastOrderListItemData = any

/**
 * Error
 * Error
 */
export type ModelUpdateFastOrderListItemError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Type of the fast order list */
export type ModelUpdateFastOrderListItemParamsFastOrderListEnumEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

/** Type of the fast order list */
export type ModelUpdateFastOrderListItemParamsEnum =
  | 'SCANNING_LIST'
  | 'FAST_ORDER_LIST'

export interface ModelGetArticleExternalReferencesData {
  /** List of article external references */
  items: {
    /** @example "07844154-281c-4bf0-97de-bd890c615192" */
    id: string
    /** @example "12345678" */
    barcode: string
    /**
     * @min 1
     * @example 9
     */
    quantity: number
  }[]
}

export interface ModelCreateArticleExternalReferencePayload {
  /** @example "12345678" */
  barcode: string
  /**
   * @min 1
   * @example 9
   */
  quantity: number
}

export type ModelCreateArticleExternalReferenceData = any

/** Error */
export type ModelCreateArticleExternalReferenceError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export interface ModelUpdateArticleExternalReferencePayload {
  /**
   * @min 1
   * @example 9
   */
  quantity: number
}

export type ModelUpdateArticleExternalReferenceData = any

/**
 * Error
 * Error
 */
export type ModelUpdateArticleExternalReferenceError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelDeleteArticleExternalReferenceData = any

export type ModelGetCustomerArticleNumberDetailsSourceEnum =
  | 'DEFAULT'
  | 'CUSTOM'
  | 'NONE'

export type ModelGetCustomerArticleNumberDetailsData = {
  source: ModelGetCustomerArticleNumberDetailsSourceEnum
  customValue?: string | null
} & {
  defaultValue: string
}

/** Error */
export type ModelGetCustomerArticleNumberDetailsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

export type ModelUpdateCustomerArticleNumberSourceEnum =
  | 'DEFAULT'
  | 'CUSTOM'
  | 'NONE'

export interface ModelUpdateCustomerArticleNumberPayload {
  source: ModelUpdateCustomerArticleNumberSourceEnum
  customValue?: string | null
}

export type ModelUpdateCustomerArticleNumberData = any

/**
 * Error
 * Error
 */
export type ModelUpdateCustomerArticleNumberError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** Users with assignment status for the article permission */
export type ModelGetCustomerResourceAssignmentsData = {
  /**
   * True if all customer users are assigned to the resource
   * @example true
   */
  allCustomerUsersAssigned?: boolean
  /**
   * True if all OCI users are assigned to the resource
   * @example true
   */
  allOciUsersAssigned?: boolean
} & {
  /** List of customer users with assignment status for the resource */
  customerUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
  /** List of OCI users with assignment status for the resource */
  ociUsers: {
    /**
     * ID of customer user
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user
     * @example "John Doe"
     */
    name: string
    /**
     * Email of the customer user
     * @example "user@company.com"
     */
    email?: string
    /**
     * True if customer user is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user has admin permissions
     * @example true
     */
    isAdmin?: boolean
    /**
     * True if customer user can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
} & {
  /** List of customer user groups with assignment status for the resource */
  customerUserGroups: {
    /**
     * ID of customer user group
     * @example "722e31c7-c5ed-43d2-8690-994726e332dc"
     */
    id: string
    /**
     * Name of the customer user group
     * @example "Sales group"
     */
    name: string
    /**
     * Description of the customer user group
     * @example "This is a basic and short description of the group"
     */
    description: string
    /**
     * True if customer user group is assigned to given resource
     * @example true
     */
    assigned: boolean
    /**
     * True if customer user group can be assigned to given resource
     * @example true
     */
    isAssignable?: boolean
    /**
     * name of the resource the user group is assigned to
     * @example "Account type 50"
     */
    assignedTo?: string
  }[]
}

/** Error */
export type ModelGetCustomerResourceAssignmentsError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** type of assignable resource */
export type ModelGetCustomerResourceAssignmentsParamsResourceTypeEnum =
  'INDIVIDUAL_BUDGETS'

export type ModelGetCustomerResourceAssignmentsParamsEnum = 'INDIVIDUAL_BUDGETS'

/** List of users assigned to the article permission */
export type ModelPutCustomerResourceAssignmentPayload =
  | {
      /**
       * True if all customer users are assigned to the resource
       * @example true
       */
      allCustomerUsersAssigned?: boolean
      /**
       * True if all OCI users are assigned to the resource
       * @example true
       */
      allOciUsersAssigned?: boolean
    }
  | {
      /** List of IDs of customer users assigned to the resource */
      customerUserIds: string[]
      /** List of IDs of OCI users assigned to the resource */
      ociUserIds?: string[]
    }
  | {
      /** List of IDs of customer user groups assigned to the resource */
      customerUserGroupIds: string[]
    }

export type ModelPutCustomerResourceAssignmentData = any

/** Error */
export type ModelPutCustomerResourceAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** type of assignable resource */
export type ModelPutCustomerResourceAssignmentParamsResourceTypeEnum =
  'INDIVIDUAL_BUDGETS'

export type ModelPutCustomerResourceAssignmentParamsEnum = 'INDIVIDUAL_BUDGETS'

export type ModelUnAssignCustomerResourceAssignmentData = any

/** Error */
export type ModelUnAssignCustomerResourceAssignmentError = {
  /**
   * Message providing details of the error
   * @example "Validation failure"
   */
  message?: string
  /** List of error detail for each field, if the error is related to a particular form field otherwise null */
  details?: {
    /**
     * Name of the field that violates the constraint
     * @example "person.firstName"
     */
    field?: string
    /**
     * Error message that states the reason why the attached field is invalid
     * @example "must not be blank"
     */
    message?: string
  }[]
  /** List of additional data information that can be provided */
  additionalData?: {
    /**
     * Name of the property
     * @example "contactPerson"
     */
    label?: string
    /**
     * Value of the property
     * @example "example@gmail.com"
     */
    value?: string
  }[]
  /**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   */
  timestamp?: string
}

/** type of assignable resource */
export type ModelUnAssignCustomerResourceAssignmentParamsResourceTypeEnum =
  'INDIVIDUAL_BUDGETS'

export type ModelUnAssignCustomerResourceAssignmentParamsEnum =
  'INDIVIDUAL_BUDGETS'

export interface ModelGetEmailParams {
  /**
   * Email recipient address.
   * @example "publicvendor1@local.de"
   */
  Recipient: string
}

export interface ModelGetEmailData {
  /** @example "user_registration:email@test" */
  id: string
  /** @example "publicvendor1@local.de" */
  recipient: string
  /** @example "USER_REGISTERED" */
  template?: string | null
  /** @example "Approval confirmation" */
  subject?: string | null
  /** A list of links that the email contains */
  links?: string[]
}

export type ModelClearCacheData = any

import type {
  AxiosInstance,
  AxiosRequestConfig,
  HeadersDefaults,
  ResponseType,
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://localhost:8080/',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title S2NG backend API
 * @version 0.0.3
 * @baseUrl http://localhost:8080/
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  vendors = {
    /**
     * @description Search and retrieve list of vendor platform and OCI catalogs with basic data - ID (with catalog type),  name & file name. Ordered by name.
     *
     * @tags vendors / catalogs
     * @name GetAssignableCatalogs
     * @summary Search and retrieve list of vendor platform and OCI catalogs with basic data.
     * @request GET:/catalogs/assignable
     * @deprecated
     * @secure
     * @response `200` `ModelGetAssignableCatalogsData` List of assignable catalogs for current vendor
     */
    getAssignableCatalogs: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetAssignableCatalogsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetAssignableCatalogsData, any>({
          path: `/catalogs/assignable`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/catalogs/assignable` as const,
    },

    /**
     * @description Searches catalogs by one of selected category for current vendor.
     *
     * @tags vendors / catalogs
     * @name SearchCatalogs
     * @summary Searches catalogs by specified category
     * @request POST:/catalogs/search
     * @secure
     * @response `200` `ModelSearchCatalogsData` List of found catalogs for current vendor
     */
    searchCatalogs: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCatalogsParams
        data: ModelSearchCatalogsPayload
        params?: RequestParams
      }) =>
        this.request<ModelSearchCatalogsData, any>({
          path: `/catalogs/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/catalogs/search` as const,
    },

    /**
 * @description Operation performed after successful catalog file upload to S3 bucket in order to register it for further processing.
 *
 * @tags vendors / catalogs
 * @name PostCatalog
 * @summary Registers new catalog file uploaded
 * @request POST:/platform-catalogs
 * @secure
 * @response `201` `ModelPostCatalogData` Catalog successfully registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog name already used by this vendor
 */
    postCatalog: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCatalogPayload
        params?: RequestParams
      }) =>
        this.request<ModelPostCatalogData, ModelPostCatalogError>({
          path: `/platform-catalogs`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/platform-catalogs` as const,
    },

    /**
     * @description Retrieve list of vendor catalogs brief info - ID, catalog version ID and name.
     *
     * @tags vendors / catalogs
     * @name GetCatalogNames
     * @summary Retrieve list of vendor catalogs brief info.
     * @request GET:/platform-catalogs/names
     * @secure
     * @response `200` `ModelGetCatalogNamesData` List of catalog ids and names for current vendor
     */
    getCatalogNames: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCatalogNamesData, any>({
          path: `/platform-catalogs/names`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-catalogs/names` as const,
    },

    /**
 * @description Creates and returns presigned URL for catalog upload
 *
 * @tags vendors / catalogs
 * @name GetCatalogUploadUrl
 * @summary Creates and returns presigned URL for catalog upload
 * @request GET:/platform-catalogs/upload/url
 * @secure
 * @response `200` `ModelGetCatalogUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` do not exist
 */
    getCatalogUploadUrl: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCatalogUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCatalogUploadUrlData,
          ModelGetCatalogUploadUrlError
        >({
          path: `/platform-catalogs/upload/url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-catalogs/upload/url` as const,
    },

    /**
 * @description Operation performed after successful catalog file upload to S3 bucket in order to register it for further processing under existing catalog.
 *
 * @tags vendors / catalogs
 * @name UpdateCatalogFile
 * @summary Registers new uploaded file for existing catalog
 * @request POST:/platform-catalogs/{catalogId}
 * @secure
 * @response `200` `ModelUpdateCatalogFileData` Catalog successfully registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog with given `vendorId` and `catalogId` does not exist
 */
    updateCatalogFile: {
      request: ({
        catalogId,
        data,
        params = {},
      }: {
        catalogId: string
        data: ModelUpdateCatalogFilePayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateCatalogFileData, ModelUpdateCatalogFileError>({
          path: `/platform-catalogs/${catalogId}`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/platform-catalogs/:catalogId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name RenameCatalog
 * @summary Renames a catalog or catalog file name
 * @request PUT:/platform-catalogs/{catalogId}
 * @secure
 * @response `200` `ModelRenameCatalogData` Catalog successfully renamed
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog with given `vendorId` and `catalogId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog name already used by this vendor
 */
    renameCatalog: {
      request: ({
        catalogId,
        data,
        params = {},
      }: {
        catalogId: string
        data: ModelRenameCatalogPayload
        params?: RequestParams
      }) =>
        this.request<ModelRenameCatalogData, ModelRenameCatalogError>({
          path: `/platform-catalogs/${catalogId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/platform-catalogs/:catalogId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name DeleteCatalog
 * @summary Deletes an unassigned/unpublished catalog
 * @request DELETE:/platform-catalogs/{catalogId}
 * @secure
 * @response `204` `ModelDeleteCatalogData` Catalog successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog with given `vendorId` and `catalogId` does not exist
 * @response `422` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Published catalog cannot be deleted
 */
    deleteCatalog: {
      request: ({
        catalogId,
        params = {},
      }: {
        catalogId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteCatalogData, ModelDeleteCatalogError>({
          path: `/platform-catalogs/${catalogId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/platform-catalogs/:catalogId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name ListCatalogAsignmentToCustomers
 * @summary List of catalog and customer associations for vendor
 * @request GET:/platform-catalogs/{catalogId}/assignments
 * @secure
 * @response `200` `ModelListCatalogAsignmentToCustomersData` List of vendor customers with assignment to catalog status
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog is open and no assignments are possible
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of resources referenced by path variables is not present
 */
    listCatalogAsignmentToCustomers: {
      request: ({
        catalogId,
        params = {},
      }: {
        catalogId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelListCatalogAsignmentToCustomersData,
          ModelListCatalogAsignmentToCustomersError
        >({
          path: `/platform-catalogs/${catalogId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-catalogs/:catalogId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name AssignCustomersToCatalog
 * @summary Makes catalog closed and updates list of customers assigned to catalog
 * @request POST:/platform-catalogs/{catalogId}/assignments
 * @secure
 * @response `204` `ModelAssignCustomersToCatalogData` Assignments updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of resources referenced by path variables is not present
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer currency does not match the catalog currency
 */
    assignCustomersToCatalog: {
      request: ({
        catalogId,
        data,
        params = {},
      }: {
        catalogId: string
        data: ModelAssignCustomersToCatalogPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAssignCustomersToCatalogData,
          ModelAssignCustomersToCatalogError
        >({
          path: `/platform-catalogs/${catalogId}/assignments`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/platform-catalogs/:catalogId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name OpenCatalog
 * @summary Publish catalog as open and remove any existing assignments
 * @request POST:/platform-catalogs/{catalogId}/open
 * @secure
 * @response `204` `ModelOpenCatalogData` Catalog published as open and assignments removed
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of resources referenced by path variables is not present
 */
    openCatalog: {
      request: ({
        catalogId,
        params = {},
      }: {
        catalogId: string
        params?: RequestParams
      }) =>
        this.request<ModelOpenCatalogData, ModelOpenCatalogError>({
          path: `/platform-catalogs/${catalogId}/open`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/platform-catalogs/:catalogId/open` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name UnpublishCatalog
 * @summary Makes catalog unpublished (closed and no assigned customers)
 * @request POST:/platform-catalogs/{catalogId}/unpublish
 * @secure
 * @response `204` `ModelUnpublishCatalogData` Catalog unpublished and assignments removed
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of resources referenced by path variables is not present
 */
    unpublishCatalog: {
      request: ({
        catalogId,
        params = {},
      }: {
        catalogId: string
        params?: RequestParams
      }) =>
        this.request<ModelUnpublishCatalogData, ModelUnpublishCatalogError>({
          path: `/platform-catalogs/${catalogId}/unpublish`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/platform-catalogs/:catalogId/unpublish` as const,
    },

    /**
     * @description Retrieve CSV report of catalog file import. Currently, we assume that single file is a catalog.
     *
     * @tags vendors / catalogs
     * @name GetCatalogImportReport
     * @summary Retrieve CSV report of catalog file import
     * @request GET:/platform-catalogs/{catalogId}/report
     * @secure
     * @response `200` `ModelGetCatalogImportReportData` contains the download url of CSV import report file
     */
    getCatalogImportReport: {
      request: ({
        catalogId,
        params = {},
      }: {
        catalogId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCatalogImportReportData, any>({
          path: `/platform-catalogs/${catalogId}/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-catalogs/:catalogId/report` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name GetCatalogFileDownloadUrl
 * @summary Creates and returns presigned URL for catalog file download
 * @request GET:/platform-catalogs/{catalogId}/file
 * @secure
 * @response `200` `ModelGetCatalogFileDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog file with given `vendorId` and `catalogId` does not exist
 */
    getCatalogFileDownloadUrl: {
      request: ({
        catalogId,
        params = {},
      }: {
        catalogId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCatalogFileDownloadUrlData,
          ModelGetCatalogFileDownloadUrlError
        >({
          path: `/platform-catalogs/${catalogId}/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-catalogs/:catalogId/file` as const,
    },

    /**
 * No description
 *
 * @tags vendors / catalogs
 * @name GetCatalogFtpConnectionConfiguration
 * @summary Returns FTP connection configuration along with credentials
 * @request GET:/platform-catalogs/ftp-connection-configuration
 * @secure
 * @response `200` `ModelGetCatalogFtpConnectionConfigurationData` The FTP connection configuration
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Unknown `vendorId`
 */
    getCatalogFtpConnectionConfiguration: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCatalogFtpConnectionConfigurationData,
          ModelGetCatalogFtpConnectionConfigurationError
        >({
          path: `/platform-catalogs/ftp-connection-configuration`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-catalogs/ftp-connection-configuration` as const,
    },

    /**
 * @description Registers new OCI catalog in the database
 *
 * @tags vendors / oci-catalogs
 * @name PostOciCatalog
 * @summary Registers new OCI catalog
 * @request POST:/oci-catalogs
 * @secure
 * @response `201` `ModelPostOciCatalogData` OCI Catalog successfully added
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog name already used by this vendor
 */
    postOciCatalog: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostOciCatalogPayload
        params?: RequestParams
      }) =>
        this.request<ModelPostOciCatalogData, ModelPostOciCatalogError>({
          path: `/oci-catalogs`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/oci-catalogs` as const,
    },

    /**
     * No description
     *
     * @tags vendors / oci-catalogs
     * @name GetOciCatalog
     * @summary Get OCI Catalog details
     * @request GET:/oci-catalogs/{ociCatalogId}
     * @secure
     * @response `200` `ModelGetOciCatalogData` OCI Catalog details
     */
    getOciCatalog: {
      request: ({
        ociCatalogId,
        params = {},
      }: {
        ociCatalogId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetOciCatalogData, any>({
          path: `/oci-catalogs/${ociCatalogId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/oci-catalogs/:ociCatalogId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / oci-catalogs
 * @name UpdateOciCatalog
 * @summary Update OCI Catalog details
 * @request PUT:/oci-catalogs/{ociCatalogId}
 * @secure
 * @response `200` `ModelUpdateOciCatalogData` OCI Catalog successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog with given `vendorId` and `ociCatalogId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog name already used by this vendor
 */
    updateOciCatalog: {
      request: ({
        ociCatalogId,
        data,
        params = {},
      }: {
        ociCatalogId: string
        data: ModelUpdateOciCatalogPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateOciCatalogData, ModelUpdateOciCatalogError>({
          path: `/oci-catalogs/${ociCatalogId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/oci-catalogs/:ociCatalogId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / oci-catalogs
 * @name DeleteOciCatalog
 * @summary Deletes an unassigned/unpublished OCI Catalog
 * @request DELETE:/oci-catalogs/{ociCatalogId}
 * @secure
 * @response `204` `ModelDeleteOciCatalogData` OCI Catalog successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog with given `vendorId` and `catalogId` does not exist
 * @response `422` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Assigned catalog cannot be deleted
 */
    deleteOciCatalog: {
      request: ({
        ociCatalogId,
        params = {},
      }: {
        ociCatalogId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteOciCatalogData, ModelDeleteOciCatalogError>({
          path: `/oci-catalogs/${ociCatalogId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/oci-catalogs/:ociCatalogId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / oci-catalogs
 * @name ListOciCatalogAssignmentToCustomers
 * @summary List of OCI catalog and customer associations for vendor
 * @request GET:/oci-catalogs/{ociCatalogId}/assignments
 * @secure
 * @response `200` `ModelListOciCatalogAssignmentToCustomersData` List of vendor customers with assignment to OCI catalog status
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of resources referenced by path variables is not present
 */
    listOciCatalogAssignmentToCustomers: {
      request: ({
        ociCatalogId,
        params = {},
      }: {
        ociCatalogId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelListOciCatalogAssignmentToCustomersData,
          ModelListOciCatalogAssignmentToCustomersError
        >({
          path: `/oci-catalogs/${ociCatalogId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/oci-catalogs/:ociCatalogId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / oci-catalogs
 * @name AssignCustomersToOciCatalog
 * @summary Updates list of customers assigned to catalog
 * @request POST:/oci-catalogs/{ociCatalogId}/assignments
 * @secure
 * @response `204` `ModelAssignCustomersToOciCatalogData` Assignments updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of resources referenced by path variables is not present
 */
    assignCustomersToOciCatalog: {
      request: ({
        ociCatalogId,
        data,
        params = {},
      }: {
        ociCatalogId: string
        data: ModelAssignCustomersToOciCatalogPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAssignCustomersToOciCatalogData,
          ModelAssignCustomersToOciCatalogError
        >({
          path: `/oci-catalogs/${ociCatalogId}/assignments`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/oci-catalogs/:ociCatalogId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / oci-catalogs
 * @name UnpublishOciCatalog
 * @summary Makes OCI catalog unpublished (no assigned customers)
 * @request POST:/oci-catalogs/{ociCatalogId}/unpublish
 * @secure
 * @response `204` `ModelUnpublishOciCatalogData` OCI Catalog unpublished and assignments removed
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of resources referenced by path variables is not present
 */
    unpublishOciCatalog: {
      request: ({
        ociCatalogId,
        params = {},
      }: {
        ociCatalogId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelUnpublishOciCatalogData,
          ModelUnpublishOciCatalogError
        >({
          path: `/oci-catalogs/${ociCatalogId}/unpublish`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/oci-catalogs/:ociCatalogId/unpublish` as const,
    },

    /**
     * @description Returns vendor's current price lists. Without pagination nor sorting.
     *
     * @tags vendors / pricelists
     * @name GetPriceLists
     * @summary Retrieve vendor's current price lists
     * @request GET:/price-lists
     * @secure
     * @response `200` `ModelGetPriceListsData` Price lists for current vendor
     */
    getPriceLists: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetPriceListsData, any>({
          path: `/price-lists`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-lists` as const,
    },

    /**
 * @description Operation performed after successful price list file upload to S3 bucket in order to register it for further processing.
 *
 * @tags vendors / pricelists
 * @name PostPriceList
 * @summary Registers new uploaded price list file
 * @request POST:/price-lists
 * @secure
 * @response `201` `ModelPostPriceListData` Price list successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price list name already used by this vendor
 */
    postPriceList: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostPriceListPayload
        params?: RequestParams
      }) =>
        this.request<ModelPostPriceListData, ModelPostPriceListError>({
          path: `/price-lists`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/price-lists` as const,
    },

    /**
     * @description Search and retrieve list of vendor price lists with basic data - ID, name & file name. Ordered by name.
     *
     * @tags vendors / pricelists
     * @name GetAssignablePriceLists
     * @summary Search and retrieve list of vendor price lists with basic data.
     * @request GET:/price-lists/assignable
     * @deprecated
     * @secure
     * @response `200` `ModelGetAssignablePriceListsData` List of assignable price lists for current vendor
     */
    getAssignablePriceLists: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetAssignablePriceListsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetAssignablePriceListsData, any>({
          path: `/price-lists/assignable`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-lists/assignable` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name GetPriceListUploadUrl
 * @summary Creates and returns presigned URL for price list upload
 * @request GET:/price-lists/upload/url
 * @secure
 * @response `200` `ModelGetPriceListUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    getPriceListUploadUrl: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetPriceListUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetPriceListUploadUrlData,
          ModelGetPriceListUploadUrlError
        >({
          path: `/price-lists/upload/url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-lists/upload/url` as const,
    },

    /**
 * @description Operation performed after successful price list file upload to S3 bucket in order to register it for further processing under existing price list.
 *
 * @tags vendors / pricelists
 * @name UpdatePriceListFile
 * @summary Registers new uploaded file for existing price list
 * @request POST:/price-lists/{priceListId}
 * @secure
 * @response `200` `ModelUpdatePriceListFileData` Price list successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price list with given `vendorId` and `priceListId` does not exist
 */
    updatePriceListFile: {
      request: ({
        priceListId,
        data,
        params = {},
      }: {
        priceListId: string
        data: ModelUpdatePriceListFilePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdatePriceListFileData,
          ModelUpdatePriceListFileError
        >({
          path: `/price-lists/${priceListId}`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/price-lists/:priceListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name UpdatePriceList
 * @summary Update price list name or file name
 * @request PUT:/price-lists/{priceListId}
 * @secure
 * @response `200` `ModelUpdatePriceListData` Price list successfully renamed
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price list with given `vendorId` and `priceListId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price list name already used by this vendor
 */
    updatePriceList: {
      request: ({
        priceListId,
        data,
        params = {},
      }: {
        priceListId: string
        data: ModelUpdatePriceListPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdatePriceListData, ModelUpdatePriceListError>({
          path: `/price-lists/${priceListId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/price-lists/:priceListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name DeletePriceList
 * @summary Deletes an unassigned price list
 * @request DELETE:/price-lists/{priceListId}
 * @secure
 * @response `204` `ModelDeletePriceListData` Price list successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price list with given `vendorId` and `priceListId` does not exist
 * @response `422` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Assigned price list cannot be deleted
 */
    deletePriceList: {
      request: ({
        priceListId,
        params = {},
      }: {
        priceListId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeletePriceListData, ModelDeletePriceListError>({
          path: `/price-lists/${priceListId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/price-lists/:priceListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name ListPriceListAssignmentToCustomers
 * @summary List of vendor customers with assignment to the price list
 * @request GET:/price-lists/{priceListId}/assignments
 * @secure
 * @response `200` `ModelListPriceListAssignmentToCustomersData` List of customers with assignment to price list
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Either vendor or price list does not exist
 */
    listPriceListAssignmentToCustomers: {
      request: ({
        priceListId,
        params = {},
      }: {
        priceListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelListPriceListAssignmentToCustomersData,
          ModelListPriceListAssignmentToCustomersError
        >({
          path: `/price-lists/${priceListId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-lists/:priceListId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name AssignCustomersToPriceList
 * @summary Updates list of customers assigned to price list
 * @request POST:/price-lists/{priceListId}/assignments
 * @secure
 * @response `204` `ModelAssignCustomersToPriceListData` Price list assignments updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Either vendor or price list does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer currency does not match the price list currency
 */
    assignCustomersToPriceList: {
      request: ({
        priceListId,
        data,
        params = {},
      }: {
        priceListId: string
        data: ModelAssignCustomersToPriceListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAssignCustomersToPriceListData,
          ModelAssignCustomersToPriceListError
        >({
          path: `/price-lists/${priceListId}/assignments`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/price-lists/:priceListId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name UnassignPriceList
 * @summary Unassign price list from all customers
 * @request POST:/price-lists/{priceListId}/unassign
 * @secure
 * @response `204` `ModelUnassignPriceListData` Price list unassigned from all customers
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Either vendor or price list does not exist
 */
    unassignPriceList: {
      request: ({
        priceListId,
        params = {},
      }: {
        priceListId: string
        params?: RequestParams
      }) =>
        this.request<ModelUnassignPriceListData, ModelUnassignPriceListError>({
          path: `/price-lists/${priceListId}/unassign`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/price-lists/:priceListId/unassign` as const,
    },

    /**
     * @description Retrieve CSV report of price list file import
     *
     * @tags vendors / pricelists
     * @name GetPriceListImportReport
     * @summary Retrieve CSV report of price list file import
     * @request GET:/price-lists/{priceListId}/report
     * @secure
     * @response `200` `ModelGetPriceListImportReportData` contains the download url of CSV import report file
     */
    getPriceListImportReport: {
      request: ({
        priceListId,
        params = {},
      }: {
        priceListId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetPriceListImportReportData, any>({
          path: `/price-lists/${priceListId}/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-lists/:priceListId/report` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name GetPriceListDownloadUrl
 * @summary Creates and returns presigned URL for price list file download
 * @request GET:/price-lists/{priceListId}/file
 * @secure
 * @response `200` `ModelGetPriceListDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price list with given `vendorId` and `priceListId` does not exist
 */
    getPriceListDownloadUrl: {
      request: ({
        priceListId,
        params = {},
      }: {
        priceListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetPriceListDownloadUrlData,
          ModelGetPriceListDownloadUrlError
        >({
          path: `/price-lists/${priceListId}/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-lists/:priceListId/file` as const,
    },

    /**
 * No description
 *
 * @tags vendors / pricelists
 * @name GetPriceListFtpConnectionConfiguration
 * @summary Returns FTP connection configuration along with credentials
 * @request GET:/price-lists/ftp-connection-configuration
 * @secure
 * @response `200` `ModelGetPriceListFtpConnectionConfigurationData` The FTP connection configuration
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Unknown `vendorId`
 */
    getPriceListFtpConnectionConfiguration: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetPriceListFtpConnectionConfigurationData,
          ModelGetPriceListFtpConnectionConfigurationError
        >({
          path: `/price-lists/ftp-connection-configuration`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-lists/ftp-connection-configuration` as const,
    },

    /**
     * @description Returns vendor's current stock and delivery lists. Without pagination nor sorting.
     *
     * @tags vendors / stock and delivery lists
     * @name GetStockAndDeliveryLists
     * @summary Retrieve vendor's current stock and delivery lists
     * @request GET:/stock-and-delivery-lists
     * @secure
     * @response `200` `ModelGetStockAndDeliveryListsData` Stock and delivery lists
     */
    getStockAndDeliveryLists: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetStockAndDeliveryListsData, any>({
          path: `/stock-and-delivery-lists`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/stock-and-delivery-lists` as const,
    },

    /**
 * @description Operation performed after successful stock and delivery list file upload to S3 bucket in order to register it for further processing.
 *
 * @tags vendors / stock and delivery lists
 * @name CreateStockAndDeliveryList
 * @summary Registers new uploaded stock and delivery list file
 * @request POST:/stock-and-delivery-lists
 * @secure
 * @response `201` `ModelCreateStockAndDeliveryListData` Stock and delivery list successfully registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Stock and delivery list name already used by this vendor
 */
    createStockAndDeliveryList: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCreateStockAndDeliveryListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCreateStockAndDeliveryListData,
          ModelCreateStockAndDeliveryListError
        >({
          path: `/stock-and-delivery-lists`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/stock-and-delivery-lists` as const,
    },

    /**
 * No description
 *
 * @tags vendors / stock and delivery lists
 * @name GetStockAndDeliveryListFtpConnectionConfiguration
 * @summary Returns FTP connection configuration along with credentials
 * @request GET:/stock-and-delivery-lists/settings
 * @secure
 * @response `200` `ModelGetStockAndDeliveryListFtpConnectionConfigurationData` The FTP connection configuration
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Unknown `vendorId`
 */
    getStockAndDeliveryListFtpConnectionConfiguration: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetStockAndDeliveryListFtpConnectionConfigurationData,
          ModelGetStockAndDeliveryListFtpConnectionConfigurationError
        >({
          path: `/stock-and-delivery-lists/settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/stock-and-delivery-lists/settings` as const,
    },

    /**
 * No description
 *
 * @tags vendors / stock and delivery lists
 * @name GetStockAndDeliveryListUploadUrl
 * @summary Creates and returns presigned URL for stock and delivery list upload
 * @request GET:/stock-and-delivery-lists/upload/url
 * @secure
 * @response `200` `ModelGetStockAndDeliveryListUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    getStockAndDeliveryListUploadUrl: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetStockAndDeliveryListUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetStockAndDeliveryListUploadUrlData,
          ModelGetStockAndDeliveryListUploadUrlError
        >({
          path: `/stock-and-delivery-lists/upload/url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/stock-and-delivery-lists/upload/url` as const,
    },

    /**
 * @description Operation performed after successful stock and delivery list file upload to S3 bucket in order to register it for further processing under existing stock and delivery list.
 *
 * @tags vendors / stock and delivery lists
 * @name UpdateStockAndDeliveryListFile
 * @summary Registers new uploaded file for existing stock and delivery list
 * @request POST:/stock-and-delivery-lists/{stockAndDeliveryListId}
 * @secure
 * @response `202` `ModelUpdateStockAndDeliveryListFileData` Stock and delivery list successfully registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Stock and delivery list with given `vendorId` and `stockAndDeliveryListId` does not exist
 */
    updateStockAndDeliveryListFile: {
      request: ({
        stockAndDeliveryListId,
        data,
        params = {},
      }: {
        stockAndDeliveryListId: string
        data: ModelUpdateStockAndDeliveryListFilePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateStockAndDeliveryListFileData,
          ModelUpdateStockAndDeliveryListFileError
        >({
          path: `/stock-and-delivery-lists/${stockAndDeliveryListId}`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/stock-and-delivery-lists/:stockAndDeliveryListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / stock and delivery lists
 * @name UpdateStockAndDeliveryList
 * @summary Update stock and delivery list name or file name
 * @request PUT:/stock-and-delivery-lists/{stockAndDeliveryListId}
 * @secure
 * @response `200` `ModelUpdateStockAndDeliveryListData` Stock and delivery list successfully renamed
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Stock and delivery list with given `vendorId` and `stockAndDeliveryListId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Stock and delivery list name already used by this vendor
 */
    updateStockAndDeliveryList: {
      request: ({
        stockAndDeliveryListId,
        data,
        params = {},
      }: {
        stockAndDeliveryListId: string
        data: ModelUpdateStockAndDeliveryListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateStockAndDeliveryListData,
          ModelUpdateStockAndDeliveryListError
        >({
          path: `/stock-and-delivery-lists/${stockAndDeliveryListId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/stock-and-delivery-lists/:stockAndDeliveryListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / stock and delivery lists
 * @name DeleteStockAndDeliveryList
 * @summary Delete a stock and delivery list
 * @request DELETE:/stock-and-delivery-lists/{stockAndDeliveryListId}
 * @secure
 * @response `204` `ModelDeleteStockAndDeliveryListData` Stock and delivery list successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Stock and delivery list with given `vendorId` and `stockAndDeliveryListId` does not exist
 */
    deleteStockAndDeliveryList: {
      request: ({
        stockAndDeliveryListId,
        params = {},
      }: {
        stockAndDeliveryListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteStockAndDeliveryListData,
          ModelDeleteStockAndDeliveryListError
        >({
          path: `/stock-and-delivery-lists/${stockAndDeliveryListId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/stock-and-delivery-lists/:stockAndDeliveryListId` as const,
    },

    /**
     * @description Retrieve CSV report of stock and delivery list file import
     *
     * @tags vendors / stock and delivery lists
     * @name GetStockAndDeliveryListImportReport
     * @summary Retrieve CSV report of stock and delivery list file import
     * @request GET:/stock-and-delivery-lists/{stockAndDeliveryListId}/report
     * @secure
     * @response `200` `ModelGetStockAndDeliveryListImportReportData` contains the download url of CSV import report file
     */
    getStockAndDeliveryListImportReport: {
      request: ({
        stockAndDeliveryListId,
        params = {},
      }: {
        stockAndDeliveryListId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetStockAndDeliveryListImportReportData, any>({
          path: `/stock-and-delivery-lists/${stockAndDeliveryListId}/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/stock-and-delivery-lists/:stockAndDeliveryListId/report` as const,
    },

    /**
 * No description
 *
 * @tags vendors / stock and delivery lists
 * @name GetStockAndDeliveryListDownloadUrl
 * @summary Creates and returns presigned URL for stock and delivery list file download
 * @request GET:/stock-and-delivery-lists/{stockAndDeliveryListId}/file
 * @secure
 * @response `200` `ModelGetStockAndDeliveryListDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Stock and delivery list with given `vendorId` and `stockAndDeliveryListId` does not exist
 */
    getStockAndDeliveryListDownloadUrl: {
      request: ({
        stockAndDeliveryListId,
        params = {},
      }: {
        stockAndDeliveryListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetStockAndDeliveryListDownloadUrlData,
          ModelGetStockAndDeliveryListDownloadUrlError
        >({
          path: `/stock-and-delivery-lists/${stockAndDeliveryListId}/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/stock-and-delivery-lists/:stockAndDeliveryListId/file` as const,
    },

    /**
     * @description Returns vendor's current article numbers lists. Without pagination nor sorting.
     *
     * @tags vendors / customer article numbers
     * @name GetCustomerArticleNumbers
     * @summary Retrieve vendor's current article numbers lists
     * @request GET:/customer-article-numbers-lists
     * @secure
     * @response `200` `ModelGetCustomerArticleNumbersData` Customer article numbers for current vendor
     */
    getCustomerArticleNumbers: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerArticleNumbersData, any>({
          path: `/customer-article-numbers-lists`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-lists` as const,
    },

    /**
 * @description Operation performed after successful customer article numbers file upload to S3 bucket in order to register it for further processing.
 *
 * @tags vendors / customer article numbers
 * @name PostCustomerArticleNumbers
 * @summary Registers new uploaded customer article numbers file
 * @request POST:/customer-article-numbers-lists
 * @secure
 * @response `201` `ModelPostCustomerArticleNumbersData` Customer article numbers successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers name already used by this vendor
 */
    postCustomerArticleNumbers: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerArticleNumbersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerArticleNumbersData,
          ModelPostCustomerArticleNumbersError
        >({
          path: `/customer-article-numbers-lists`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customer-article-numbers-lists` as const,
    },

    /**
     * No description
     *
     * @tags vendors / customer article numbers
     * @name GetVendorCustomerArticleNumbersUploadSettings
     * @summary Retrieve the FTP and SFTP configuration for CAN upload
     * @request GET:/customer-article-numbers-lists/settings
     * @secure
     * @response `200` `ModelGetVendorCustomerArticleNumbersUploadSettingsData` FTP and SFTP configuration
     */
    getVendorCustomerArticleNumbersUploadSettings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetVendorCustomerArticleNumbersUploadSettingsData,
          any
        >({
          path: `/customer-article-numbers-lists/settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-lists/settings` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customer article numbers
 * @name GetCustomerArticleNumbersUploadUrl
 * @summary Creates and returns presigned URL for customer article numbers list upload
 * @request GET:/customer-article-numbers-lists/upload/url
 * @secure
 * @response `200` `ModelGetCustomerArticleNumbersUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    getCustomerArticleNumbersUploadUrl: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerArticleNumbersUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerArticleNumbersUploadUrlData,
          ModelGetCustomerArticleNumbersUploadUrlError
        >({
          path: `/customer-article-numbers-lists/upload/url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-lists/upload/url` as const,
    },

    /**
 * @description Operation performed after successful customer article numbers file upload to S3 bucket in order to register it for further processing under existing customer article numbers.
 *
 * @tags vendors / customer article numbers
 * @name UpdateCustomerArticleNumbersFile
 * @summary Registers new uploaded file for existing customer article numbers
 * @request POST:/customer-article-numbers-lists/{customerArticleNumbersListId}
 * @secure
 * @response `202` `ModelUpdateCustomerArticleNumbersFileData` Customer article numbers successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers with given `vendorId` and `customerArticleNumbersListId` does not exist
 */
    updateCustomerArticleNumbersFile: {
      request: ({
        customerArticleNumbersListId,
        data,
        params = {},
      }: {
        customerArticleNumbersListId: string
        data: ModelUpdateCustomerArticleNumbersFilePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerArticleNumbersFileData,
          ModelUpdateCustomerArticleNumbersFileError
        >({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customer article numbers
 * @name UpdateCustomerArticleNumbers
 * @summary Update customer article numbers name or file name
 * @request PUT:/customer-article-numbers-lists/{customerArticleNumbersListId}
 * @secure
 * @response `200` `ModelUpdateCustomerArticleNumbersData` Customer article numbers successfully renamed
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers with given `vendorId` and `customerArticleNumbersListId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers name already used by this vendor
 */
    updateCustomerArticleNumbers: {
      request: ({
        customerArticleNumbersListId,
        data,
        params = {},
      }: {
        customerArticleNumbersListId: string
        data: ModelUpdateCustomerArticleNumbersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerArticleNumbersData,
          ModelUpdateCustomerArticleNumbersError
        >({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customer article numbers
 * @name DeleteVendorCustomerArticleNumbers
 * @summary Deletes unassigned customer article numbers
 * @request DELETE:/customer-article-numbers-lists/{customerArticleNumbersListId}
 * @secure
 * @response `204` `ModelDeleteVendorCustomerArticleNumbersData` Customer article numbers successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers with given `vendorId` and `customerArticleNumbersListId` does not exist
 * @response `422` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Assigned customer article numbers cannot be deleted
 */
    deleteVendorCustomerArticleNumbers: {
      request: ({
        customerArticleNumbersListId,
        params = {},
      }: {
        customerArticleNumbersListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteVendorCustomerArticleNumbersData,
          ModelDeleteVendorCustomerArticleNumbersError
        >({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customer article numbers
 * @name ListCustomerArticleNumbersAssignmentToCustomers
 * @summary List of vendor's customers with assignment to the customer article numbers
 * @request GET:/customer-article-numbers-lists/{customerArticleNumbersListId}/assignments
 * @secure
 * @response `200` `ModelListCustomerArticleNumbersAssignmentToCustomersData` List of customers with assignment to customer article numbers
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Either vendor or customer article numbers does not exist
 */
    listCustomerArticleNumbersAssignmentToCustomers: {
      request: ({
        customerArticleNumbersListId,
        params = {},
      }: {
        customerArticleNumbersListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelListCustomerArticleNumbersAssignmentToCustomersData,
          ModelListCustomerArticleNumbersAssignmentToCustomersError
        >({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customer article numbers
 * @name AssignCustomersToCustomerArticleNumbers
 * @summary Updates list of customers assigned to customer article numbers
 * @request POST:/customer-article-numbers-lists/{customerArticleNumbersListId}/assignments
 * @secure
 * @response `204` `ModelAssignCustomersToCustomerArticleNumbersData` Customer article numbers assignments updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Either vendor or customer article numbers does not exist
 */
    assignCustomersToCustomerArticleNumbers: {
      request: ({
        customerArticleNumbersListId,
        data,
        params = {},
      }: {
        customerArticleNumbersListId: string
        data: ModelAssignCustomersToCustomerArticleNumbersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAssignCustomersToCustomerArticleNumbersData,
          ModelAssignCustomersToCustomerArticleNumbersError
        >({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}/assignments`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customer article numbers
 * @name UnassignCustomerArticleNumbers
 * @summary Unassign customer article numbers from all customers
 * @request POST:/customer-article-numbers-lists/{customerArticleNumbersListId}/unassign
 * @secure
 * @response `204` `ModelUnassignCustomerArticleNumbersData` Customer article numbers unassigned from all customers
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Either vendor or customer article numbers does not exist
 */
    unassignCustomerArticleNumbers: {
      request: ({
        customerArticleNumbersListId,
        data,
        params = {},
      }: {
        customerArticleNumbersListId: string
        data: ModelUnassignCustomerArticleNumbersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUnassignCustomerArticleNumbersData,
          ModelUnassignCustomerArticleNumbersError
        >({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}/unassign`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId/unassign` as const,
    },

    /**
     * @description Retrieve CSV report of customer article numbers file import
     *
     * @tags vendors / customer article numbers
     * @name GetCustomerArticleNumbersImportReport
     * @summary Retrieve CSV report of customer article numbers file import
     * @request GET:/customer-article-numbers-lists/{customerArticleNumbersListId}/report
     * @secure
     * @response `200` `ModelGetCustomerArticleNumbersImportReportData` contains the download url of CSV import report file
     */
    getCustomerArticleNumbersImportReport: {
      request: ({
        customerArticleNumbersListId,
        params = {},
      }: {
        customerArticleNumbersListId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerArticleNumbersImportReportData, any>({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId/report` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customer article numbers
 * @name GetCustomerArticleNumbersDownloadUrl
 * @summary Creates and returns presigned URL for customer article numbers file download
 * @request GET:/customer-article-numbers-lists/{customerArticleNumbersListId}/file
 * @secure
 * @response `200` `ModelGetCustomerArticleNumbersDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers with given `vendorId` and `customerArticleNumbersListId` does not exist
 */
    getCustomerArticleNumbersDownloadUrl: {
      request: ({
        customerArticleNumbersListId,
        params = {},
      }: {
        customerArticleNumbersListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerArticleNumbersDownloadUrlData,
          ModelGetCustomerArticleNumbersDownloadUrlError
        >({
          path: `/customer-article-numbers-lists/${customerArticleNumbersListId}/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-lists/:customerArticleNumbersListId/file` as const,
    },

    /**
     * No description
     *
     * @tags vendors / media
     * @name GetMediaArchives
     * @summary Retrieve list of media archives
     * @request GET:/media-archives
     * @secure
     * @response `200` `ModelGetMediaArchivesData` List media archives
     */
    getMediaArchives: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetMediaArchivesParams
        params?: RequestParams
      }) =>
        this.request<ModelGetMediaArchivesData, any>({
          path: `/media-archives`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/media-archives` as const,
    },

    /**
 * @description Operation performed after successful catalog file upload to S3 bucket in order to register it for further processing.
 *
 * @tags vendors / media
 * @name PostMediaArchive
 * @summary Registers new media archive uploaded
 * @request POST:/media-archives
 * @secure
 * @response `201` `ModelPostMediaArchiveData` Media archive successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 */
    postMediaArchive: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostMediaArchivePayload
        params?: RequestParams
      }) =>
        this.request<ModelPostMediaArchiveData, ModelPostMediaArchiveError>({
          path: `/media-archives`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/media-archives` as const,
    },

    /**
 * @description Creates and returns presigned URL for catalog upload
 *
 * @tags vendors / media
 * @name GetMediaArchiveUploadUrl
 * @summary Creates and returns presigned URL for catalog upload
 * @request GET:/media-archives/upload/url
 * @secure
 * @response `200` `ModelGetMediaArchiveUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` do not exist
 */
    getMediaArchiveUploadUrl: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetMediaArchiveUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetMediaArchiveUploadUrlData,
          ModelGetMediaArchiveUploadUrlError
        >({
          path: `/media-archives/upload/url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/media-archives/upload/url` as const,
    },

    /**
     * No description
     *
     * @tags vendors / media
     * @name GetMediaArchiveFile
     * @summary Retrieve uploaded media file
     * @request GET:/media-archives/{mediaArchiveId}/file
     * @secure
     * @response `200` `ModelGetMediaArchiveFileData` contains the download url to download media archive zip file for the give mediaArchiveId
     */
    getMediaArchiveFile: {
      request: ({
        mediaArchiveId,
        params = {},
      }: {
        mediaArchiveId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetMediaArchiveFileData, any>({
          path: `/media-archives/${mediaArchiveId}/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/media-archives/:mediaArchiveId/file` as const,
    },

    /**
     * @description Retrieve CSV report of media archive file import
     *
     * @tags vendors / media
     * @name GetMediaArchiveReport
     * @summary Retrieve CSV report of media archive file import
     * @request GET:/media-archives/{mediaArchiveId}/report
     * @secure
     * @response `200` `ModelGetMediaArchiveReportData` contains the download url of CSV import report file
     */
    getMediaArchiveReport: {
      request: ({
        mediaArchiveId,
        params = {},
      }: {
        mediaArchiveId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetMediaArchiveReportData, any>({
          path: `/media-archives/${mediaArchiveId}/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/media-archives/:mediaArchiveId/report` as const,
    },

    /**
 * No description
 *
 * @tags vendors / contract details
 * @name GetVendorEvaluationStatusDetails
 * @summary Retrieve the current VendorEvaluationStatusDetails for the given `{vendorId}`
 * @request GET:/contract-details/evaluation-status/current
 * @secure
 * @response `200` `ModelGetVendorEvaluationStatusDetailsData` Current VendorEvaluationStatusDetails for given `{vendorId}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `{vendorId}` does not exist
 */
    getVendorEvaluationStatusDetails: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetVendorEvaluationStatusDetailsData,
          ModelGetVendorEvaluationStatusDetailsError
        >({
          path: `/contract-details/evaluation-status/current`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/contract-details/evaluation-status/current` as const,
    },

    /**
     * No description
     *
     * @tags vendors
     * @name GetOrderDataExchange
     * @summary Retrieve the OrderDataExchange of the given `{vendorId}`
     * @request GET:/settings/order-data-exchange
     * @secure
     * @response `200` `ModelGetOrderDataExchangeData` OrderDataExchange of the given `{vendorId}`
     */
    getOrderDataExchange: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetOrderDataExchangeData, any>({
          path: `/settings/order-data-exchange`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/order-data-exchange` as const,
    },

    /**
 * No description
 *
 * @tags vendors
 * @name PatchOrderDataExchange
 * @summary Update OrderDataExchange of the given `{vendorId}`
 * @request PATCH:/settings/order-data-exchange
 * @secure
 * @response `200` `ModelPatchOrderDataExchangeData` OrderDataExchange successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    patchOrderDataExchange: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchOrderDataExchangePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchOrderDataExchangeData,
          ModelPatchOrderDataExchangeError
        >({
          path: `/settings/order-data-exchange`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/settings/order-data-exchange` as const,
    },

    /**
 * No description
 *
 * @tags vendors
 * @name GetOrderDataExchangePullConfiguration
 * @summary Retrieve the FTP and SFTP PULL configuration for the given `{vendorId}`
 * @request GET:/settings/pull-configuration
 * @secure
 * @response `200` `ModelGetOrderDataExchangePullConfigurationData` Configuration data generated for FTP_PULL and SFTP_PULL of the given `{vendorId}`
 * @response `500` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Unexpected error
 */
    getOrderDataExchangePullConfiguration: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetOrderDataExchangePullConfigurationData,
          ModelGetOrderDataExchangePullConfigurationError
        >({
          path: `/settings/pull-configuration`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/pull-configuration` as const,
    },

    /**
     * No description
     *
     * @tags vendors / sales
     * @name GetVendorSalesOverview
     * @summary Retrieve vendor's sales overview
     * @request GET:/sales
     * @secure
     * @response `200` `ModelGetVendorSalesOverviewData` Sales overview of vendor
     */
    getVendorSalesOverview: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetVendorSalesOverviewData, any>({
          path: `/sales`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/sales` as const,
    },

    /**
     * No description
     *
     * @tags vendors / sales
     * @name GetVendorMonthlySales
     * @summary Retrieve vendor's sales statistics for the month
     * @request GET:/sales/{year}/{month}
     * @secure
     * @response `200` `ModelGetVendorMonthlySalesData` Sales statistics for the specified month and year
     */
    getVendorMonthlySales: {
      request: ({
        year,
        month,
        params = {},
      }: {
        year: number
        month: number
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorMonthlySalesData, any>({
          path: `/sales/${year}/${month}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/sales/:year/:month` as const,
    },

    /**
     * @description Retrieve list of vendor cart infos.
     *
     * @tags vendors / cart infos
     * @name GetVendorCartInfos
     * @summary Get vendor cart infos.
     * @request GET:/cart-infos
     * @secure
     * @response `200` `ModelGetVendorCartInfosData` List of vendor cart infos
     */
    getVendorCartInfos: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetVendorCartInfosParams
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorCartInfosData, any>({
          path: `/cart-infos`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/cart-infos` as const,
    },

    /**
 * No description
 *
 * @tags vendors / cart infos
 * @name PostVendorCartInfos
 * @summary Create vendor cart info
 * @request POST:/cart-infos
 * @secure
 * @response `201` `ModelPostVendorCartInfosData` Vendor cart info created
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor cart info name already used by this vendor
 */
    postVendorCartInfos: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostVendorCartInfosPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostVendorCartInfosData,
          ModelPostVendorCartInfosError
        >({
          path: `/cart-infos`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/cart-infos` as const,
    },

    /**
 * No description
 *
 * @tags vendors / cart infos
 * @name UpdateVendorCartInfo
 * @summary Update vendor cart info
 * @request PUT:/cart-infos/{cartInfoId}
 * @secure
 * @response `204` `ModelUpdateVendorCartInfoData` Vendor cart info successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor cart info with given `vendorId` and `cartInfoId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor cart info name already used by this vendor
 */
    updateVendorCartInfo: {
      request: ({
        cartInfoId,
        data,
        params = {},
      }: {
        cartInfoId: string
        data: ModelUpdateVendorCartInfoPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateVendorCartInfoData,
          ModelUpdateVendorCartInfoError
        >({
          path: `/cart-infos/${cartInfoId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/cart-infos/:cartInfoId` as const,
    },

    /**
     * No description
     *
     * @tags vendors / cart infos
     * @name DeleteVendorCartInfo
     * @summary Delete vendor cart info
     * @request DELETE:/cart-infos/{cartInfoId}
     * @secure
     * @response `204` `ModelDeleteVendorCartInfoData` Vendor cart info successfully deleted
     */
    deleteVendorCartInfo: {
      request: ({
        cartInfoId,
        params = {},
      }: {
        cartInfoId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteVendorCartInfoData, any>({
          path: `/cart-infos/${cartInfoId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/cart-infos/:cartInfoId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / cart infos
 * @name ListCartInfoAssignableCustomers
 * @summary List of vendor assignable customers to cart info
 * @request GET:/cart-infos/{cartInfoId}/assignments
 * @secure
 * @response `200` `ModelListCartInfoAssignableCustomersData` List of assignable customers to cart info
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor cart info with given `vendorId` and `cartInfoId` does not exist
 */
    listCartInfoAssignableCustomers: {
      request: ({
        cartInfoId,
        params = {},
      }: {
        cartInfoId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelListCartInfoAssignableCustomersData,
          ModelListCartInfoAssignableCustomersError
        >({
          path: `/cart-infos/${cartInfoId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/cart-infos/:cartInfoId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags vendors / cart infos
 * @name UpdateVendorCartInfoAssignments
 * @summary Update vendor cart info assignments
 * @request PUT:/cart-infos/{cartInfoId}/assignments
 * @secure
 * @response `204` `ModelUpdateVendorCartInfoAssignmentsData` Vendor cart info assignments successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor cart info with given `vendorId` and `cartInfoId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer has already other cart info assigned
 */
    updateVendorCartInfoAssignments: {
      request: ({
        cartInfoId,
        data,
        params = {},
      }: {
        cartInfoId: string
        data: ModelUpdateVendorCartInfoAssignmentsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateVendorCartInfoAssignmentsData,
          ModelUpdateVendorCartInfoAssignmentsError
        >({
          path: `/cart-infos/${cartInfoId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/cart-infos/:cartInfoId/assignments` as const,
    },

    /**
 * @description Allows searching for articles based on search term. Supports pagination.
 *
 * @tags vendors / article preview
 * @name VendorPreviewArticlesSearch
 * @summary Search for articles in catalog preview
 * @request POST:/marketplace-preview/articles/search
 * @secure
 * @response `200` `ModelVendorPreviewArticlesSearchData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    vendorPreviewArticlesSearch: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelVendorPreviewArticlesSearchParams
        data: ModelVendorPreviewArticlesSearchPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelVendorPreviewArticlesSearchData,
          ModelVendorPreviewArticlesSearchError
        >({
          path: `/marketplace-preview/articles/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/marketplace-preview/articles/search` as const,
    },

    /**
 * @description Gives the detailed information for an article preview
 *
 * @tags vendors / article preview
 * @name PreviewArticleDetail
 * @summary Returns the details of an article preview
 * @request GET:/marketplace-preview/articles/{articleId}
 * @secure
 * @response `200` `ModelPreviewArticleDetailData` Article preview details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided article id does not exist
 */
    previewArticleDetail: {
      request: ({
        pathAndQueryParams: { articleId, ...query },
        params = {},
      }: {
        pathAndQueryParams: ModelPreviewArticleDetailParams
        params?: RequestParams
      }) =>
        this.request<
          ModelPreviewArticleDetailData,
          ModelPreviewArticleDetailError
        >({
          path: `/marketplace-preview/articles/${articleId}`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace-preview/articles/:articleId` as const,
    },

    /**
 * @description Request external prices for list of articles previews that were returned without internal system prices.
 *
 * @tags vendors / article preview
 * @name FetchPreviewExternalPrices
 * @summary Request external prices for list of articles previews
 * @request POST:/marketplace-preview/customers/{customerId}/external-prices
 * @secure
 * @response `200` `ModelFetchPreviewExternalPricesData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    fetchPreviewExternalPrices: {
      request: ({
        customerId,
        data,
        params = {},
      }: {
        customerId: string
        data: ModelFetchPreviewExternalPricesPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelFetchPreviewExternalPricesData,
          ModelFetchPreviewExternalPricesError
        >({
          path: `/marketplace-preview/customers/${customerId}/external-prices`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/marketplace-preview/customers/:customerId/external-prices` as const,
    },

    /**
 * @description Returns a list of price lists assigned to a given Customer
 *
 * @tags vendors / article preview
 * @name GetVendorMarketplaceCustomersPriceLists
 * @summary Returns a list of price lists assigned to a given Customer
 * @request GET:/marketplace-preview/customers/{customerId}/price-lists
 * @secure
 * @response `200` `ModelGetVendorMarketplaceCustomersPriceListsData` List of price lists assigned to a given Customer sorted alphabetically ascending
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist in the context of a given vendor
 */
    getVendorMarketplaceCustomersPriceLists: {
      request: ({
        customerId,
        params = {},
      }: {
        customerId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetVendorMarketplaceCustomersPriceListsData,
          ModelGetVendorMarketplaceCustomersPriceListsError
        >({
          path: `/marketplace-preview/customers/${customerId}/price-lists`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace-preview/customers/:customerId/price-lists` as const,
    },

    /**
 * @description Returns a list of connected Customers of a given Customer
 *
 * @tags vendors / article preview
 * @name GetVendorMarketplaceCustomers
 * @summary Returns a list of connected Customers of a given Vendor
 * @request GET:/marketplace/customers
 * @deprecated
 * @secure
 * @response `200` `ModelGetVendorMarketplaceCustomersData` List of connected Customers of a given Vendor sorted alphabetically ascending
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Access to Vendor with given `vendorId` is forbidden
 */
    getVendorMarketplaceCustomers: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetVendorMarketplaceCustomersData,
          ModelGetVendorMarketplaceCustomersError
        >({
          path: `/marketplace/customers`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/customers` as const,
    },

    /**
 * @description Returns a list of connected Customers of a given Vendor and currency
 *
 * @tags vendors / article preview
 * @name GetVendorMarketplaceCustomersWithCurrency
 * @summary Returns a list of connected Customers of a given Vendor
 * @request GET:/marketplace/{currencyCode}/customers
 * @secure
 * @response `200` `ModelGetVendorMarketplaceCustomersWithCurrencyData` List of connected Customers of a given Vendor filtered by currency and sorted alphabetically ascending
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Access to Vendor with given `vendorId` is forbidden
 */
    getVendorMarketplaceCustomersWithCurrency: {
      request: ({
        currencyCode,
        params = {},
      }: {
        currencyCode: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetVendorMarketplaceCustomersWithCurrencyData,
          ModelGetVendorMarketplaceCustomersWithCurrencyError
        >({
          path: `/marketplace/${currencyCode}/customers`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/:currencyCode/customers` as const,
    },

    /**
 * @description Returns a list of connected Customers assigned to catalog of a given Vendor
 *
 * @tags vendors / article preview
 * @name GetVendorMarketplaceCatalogAssignedCustomers
 * @summary Returns a list of Customers assigned to a catalog of a given Vendor
 * @request GET:/platform-catalogs/{catalogId}/assigned-customers
 * @secure
 * @response `200` `ModelGetVendorMarketplaceCatalogAssignedCustomersData` List of connected Customers of a given Vendor assigned to the given catalog
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Access to Vendor with given `vendorId` is forbidden
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog with a given ID does not exist
 */
    getVendorMarketplaceCatalogAssignedCustomers: {
      request: ({
        catalogId,
        params = {},
      }: {
        catalogId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetVendorMarketplaceCatalogAssignedCustomersData,
          ModelGetVendorMarketplaceCatalogAssignedCustomersError
        >({
          path: `/platform-catalogs/${catalogId}/assigned-customers`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-catalogs/:catalogId/assigned-customers` as const,
    },

    /**
     * @description Retrieve list of reports defined for vendor. Unpaginated, sorted by `nameTranslationKey`
     *
     * @tags vendors / reports
     * @name GetVendorReports
     * @summary Retrieve list of reports defined for vendor
     * @request GET:/reports
     * @secure
     * @response `200` `ModelGetVendorReportsData` List of reports
     */
    getVendorReports: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetVendorReportsData, any>({
          path: `/reports`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/reports` as const,
    },

    /**
     * No description
     *
     * @tags vendors / reports
     * @name GetVendorReportViewData
     * @summary Gets report data necessary to embed report in FE application
     * @request GET:/reports/{vendorReportId}
     * @secure
     * @response `200` `ModelGetVendorReportViewDataData` Report view data
     */
    getVendorReportViewData: {
      request: ({
        vendorReportId,
        params = {},
      }: {
        vendorReportId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorReportViewDataData, any>({
          path: `/reports/${vendorReportId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/reports/:vendorReportId` as const,
    },

    /**
 * @description In order to sell products you need to register company data. Results in creating a new Vendor
 *
 * @tags vendors
 * @name PostVendorCompany
 * @summary Registers new Vendor Company
 * @request POST:/vendors
 * @response `201` `ModelPostVendorCompanyData` Company registered successfully.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    postVendorCompany: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostVendorCompanyPayload
        params?: RequestParams
      }) =>
        this.request<ModelPostVendorCompanyData, ModelPostVendorCompanyError>({
          path: `/vendors`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/vendors` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customers
 * @name GetVendorCustomers
 * @summary Returns a list of Customers (pending or accepted) for given Vendor
 * @request GET:/customers
 * @secure
 * @response `200` `ModelGetVendorCustomersData` List of Customers (pending or accepted) for given Vendor
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` do not exist
 */
    getVendorCustomers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetVendorCustomersParams
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorCustomersData, ModelGetVendorCustomersError>(
          {
            path: `/customers`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
          },
        ),
      url: `/customers` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customers
 * @name CountVendorCustomers
 * @summary Counts search hits for each relationship status
 * @request GET:/customers/counts
 * @secure
 * @response `200` `ModelCountVendorCustomersData` Search hit counts for each status
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` does not exist
 */
    countVendorCustomers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelCountVendorCustomersParams
        params?: RequestParams
      }) =>
        this.request<
          ModelCountVendorCustomersData,
          ModelCountVendorCustomersError
        >({
          path: `/customers/counts`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customers/counts` as const,
    },

    /**
 * No description
 *
 * @tags vendors / customers
 * @name PatchVendorCustomer
 * @summary Update the state of relation between Customer `{customerId}` and Vendor `{vendorId}`
 * @request PATCH:/customers/{customerId}
 * @secure
 * @response `204` `ModelPatchVendorCustomerData` Vendor Request updated successfully.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` do not exist or customer with given `customerId` do not exist
 */
    patchVendorCustomer: {
      request: ({
        customerId,
        data,
        params = {},
      }: {
        customerId: string
        data: ModelPatchVendorCustomerPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchVendorCustomerData,
          ModelPatchVendorCustomerError
        >({
          path: `/customers/${customerId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customers/:customerId` as const,
    },

    /**
     * @description Search and retrieve list of vendor platform and OCI catalogs assignable for given customer. Basic data - ID (with catalog type), name & file name. Ordered by name.
     *
     * @tags vendors / customers
     * @name GetVendorCustomerAssignableCatalogs
     * @summary Search and retrieve list of vendor platform and OCI catalogs with basic data.
     * @request GET:/customers/{customerId}/catalogs/assignable
     * @secure
     * @response `200` `ModelGetVendorCustomerAssignableCatalogsData` List of assignable vendor catalogs for given customer
     */
    getVendorCustomerAssignableCatalogs: {
      request: ({
        pathAndQueryParams: { customerId, ...query },
        params = {},
      }: {
        pathAndQueryParams: ModelGetVendorCustomerAssignableCatalogsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorCustomerAssignableCatalogsData, any>({
          path: `/customers/${customerId}/catalogs/assignable`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customers/:customerId/catalogs/assignable` as const,
    },

    /**
     * @description Search and retrieve list of vendor price lists assignable for given customer.
     *
     * @tags vendors / customers
     * @name GetVendorCustomerAssignablePriceLists
     * @summary Search and retrieve list of vendor price lists with basic data.
     * @request GET:/customers/{customerId}/price-lists/assignable
     * @secure
     * @response `200` `ModelGetVendorCustomerAssignablePriceListsData` List of assignable vendor price lists for given customer
     */
    getVendorCustomerAssignablePriceLists: {
      request: ({
        pathAndQueryParams: { customerId, ...query },
        params = {},
      }: {
        pathAndQueryParams: ModelGetVendorCustomerAssignablePriceListsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorCustomerAssignablePriceListsData, any>({
          path: `/customers/${customerId}/price-lists/assignable`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customers/:customerId/price-lists/assignable` as const,
    },

    /**
 * @description Search orders of vendor both platform and ERP originated
 *
 * @tags vendors / orders
 * @name SearchVendorOrders
 * @summary Search for orders
 * @request POST:/orders/search
 * @secure
 * @response `200` `ModelSearchVendorOrdersData` List of orders of vendor
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Invalid search request
 */
    searchVendorOrders: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchVendorOrdersParams
        data: ModelSearchVendorOrdersPayload
        params?: RequestParams
      }) =>
        this.request<ModelSearchVendorOrdersData, ModelSearchVendorOrdersError>(
          {
            path: `/orders/search`,
            method: 'POST',
            query: query,
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
          },
        ),
      url: `/orders/search` as const,
    },

    /**
 * No description
 *
 * @tags vendors
 * @name GetVendorProfile
 * @summary Retrieve the Vendor's profile for given `{vendorId}`
 * @request GET:/profile
 * @secure
 * @response `200` `ModelGetVendorProfileData` Company profile for given `{vendorId}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` do not exist
 */
    getVendorProfile: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetVendorProfileData, ModelGetVendorProfileError>({
          path: `/profile`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/profile` as const,
    },

    /**
 * No description
 *
 * @tags vendors
 * @name UpdateVendorProfile
 * @summary Update the Vendor's profile for given `{vendorId}`
 * @request PATCH:/profile
 * @secure
 * @response `200` `ModelUpdateVendorProfileData` Company profile for given `{vendorId}`
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` do not exist
 */
    updateVendorProfile: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateVendorProfilePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateVendorProfileData,
          ModelUpdateVendorProfileError
        >({
          path: `/profile`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/profile` as const,
    },

    /**
     * No description
     *
     * @tags vendors / dac7
     * @name GetVendorDac7
     * @summary Retrieve the Vendor's DAC7 data for given `{vendorId}`
     * @request GET:/dac7
     * @secure
     * @response `200` `ModelGetVendorDac7Data` Vendor's DAC7 data
     */
    getVendorDac7: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetVendorDac7Data, any>({
          path: `/dac7`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/dac7` as const,
    },

    /**
     * No description
     *
     * @tags vendors / dac7
     * @name UpdateVendorDac7
     * @summary Update the Vendor's DAC7 data for given `{vendorId}`
     * @request PUT:/dac7
     * @secure
     * @response `204` `ModelUpdateVendorDac7Data` Vendor's DAC7 data successfully updated
     */
    updateVendorDac7: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateVendorDac7Payload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateVendorDac7Data, any>({
          path: `/dac7`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/dac7` as const,
    },

    /**
     * No description
     *
     * @tags vendors / users
     * @name GetVendorUsers
     * @summary Get vendor users
     * @request GET:/users
     * @secure
     * @response `200` `ModelGetVendorUsersData` List of vendor users
     */
    getVendorUsers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetVendorUsersParams
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorUsersData, any>({
          path: `/users`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users` as const,
    },

    /**
 * No description
 *
 * @tags vendors / users
 * @name PostVendorUser
 * @summary Create vendor user
 * @request POST:/users
 * @secure
 * @response `201` `ModelPostVendorUserData` Vendor user was successfully created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    postVendorUser: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostVendorUserPayload
        params?: RequestParams
      }) =>
        this.request<ModelPostVendorUserData, ModelPostVendorUserError>({
          path: `/users`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/users` as const,
    },

    /**
     * No description
     *
     * @tags vendors / users
     * @name GetMeVendorUser
     * @summary Get profile for current vendor user
     * @request GET:/users/me
     * @secure
     * @response `200` `ModelGetMeVendorUserData` Vendor User with details
     */
    getMeVendorUser: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetMeVendorUserData, any>({
          path: `/users/me`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/me` as const,
    },

    /**
 * No description
 *
 * @tags vendors / users
 * @name PutVendorUser
 * @summary Update vendor user
 * @request PUT:/users/{vendorUserId}
 * @secure
 * @response `200` `ModelPutVendorUserData` User was successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    putVendorUser: {
      request: ({
        vendorUserId,
        data,
        params = {},
      }: {
        vendorUserId: string
        data: ModelPutVendorUserPayload
        params?: RequestParams
      }) =>
        this.request<ModelPutVendorUserData, ModelPutVendorUserError>({
          path: `/users/${vendorUserId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/users/:vendorUserId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / users
 * @name DeleteVendorUser
 * @summary Delete vendor user
 * @request DELETE:/users/{vendorUserId}
 * @secure
 * @response `204` `ModelDeleteVendorUserData` Vendor user deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided vendorUserId does not exist
 */
    deleteVendorUser: {
      request: ({
        vendorUserId,
        params = {},
      }: {
        vendorUserId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteVendorUserData, ModelDeleteVendorUserError>({
          path: `/users/${vendorUserId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/users/:vendorUserId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / users
 * @name GetVendorUser
 * @summary Get vendor user
 * @request GET:/users/{vendorUserId}
 * @secure
 * @response `200` `ModelGetVendorUserData` Vendor User with details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided vendorUserId does not exist
 */
    getVendorUser: {
      request: ({
        vendorUserId,
        params = {},
      }: {
        vendorUserId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetVendorUserData, ModelGetVendorUserError>({
          path: `/users/${vendorUserId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:vendorUserId` as const,
    },

    /**
 * No description
 *
 * @tags vendors / users
 * @name PostVendorUserResendInvitation
 * @summary Resend invitation
 * @request POST:/users/{vendorUserId}/resend-invitation
 * @secure
 * @response `200` `ModelPostVendorUserResendInvitationData` Invitation succesfully resent
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid invitation state.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided vendorUserId does not exist
 */
    postVendorUserResendInvitation: {
      request: ({
        vendorUserId,
        params = {},
      }: {
        vendorUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelPostVendorUserResendInvitationData,
          ModelPostVendorUserResendInvitationError
        >({
          path: `/users/${vendorUserId}/resend-invitation`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/users/:vendorUserId/resend-invitation` as const,
    },

    /**
 * No description
 *
 * @tags vendors / contacts
 * @name GetVendorContacts
 * @summary Retrieve all contacts that belong to the given `{vendorId}`
 * @request GET:/contacts
 * @secure
 * @response `200` `ModelGetVendorContactsData` All available contacts for given `{vendorId}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` does not exist
 */
    getVendorContacts: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetVendorContactsData, ModelGetVendorContactsError>({
          path: `/contacts`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/contacts` as const,
    },

    /**
 * No description
 *
 * @tags vendors / contacts
 * @name PatchVendorContacts
 * @summary Patch contact details for the given `{vendorId}`
 * @request PATCH:/contacts
 * @secure
 * @response `200` `ModelPatchVendorContactsData` Contact details was successfully saved
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` does not exist
 */
    patchVendorContacts: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchVendorContactsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchVendorContactsData,
          ModelPatchVendorContactsError
        >({
          path: `/contacts`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/contacts` as const,
    },

    /**
 * @description Retrieve the contact detail for the given `{vendorId}` and `{contactType}`
 *
 * @tags vendors / contacts
 * @name GetVendorContactByType
 * @summary Retrieve the contact detail for the given `{vendorId}` and `{contactType}`. Defaults to main contact if the requested contactType does not exist.
 * @request GET:/contacts/{contactType}
 * @secure
 * @response `200` `ModelGetVendorContactByTypeData` Contact detail for the given `{vendorId}` and `{contactType}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` does not exist
 */
    getVendorContactByType: {
      request: ({
        contactType,
        params = {},
      }: {
        contactType: ModelGetVendorContactByTypeParamsEnum
        params?: RequestParams
      }) =>
        this.request<
          ModelGetVendorContactByTypeData,
          ModelGetVendorContactByTypeError
        >({
          path: `/contacts/${contactType}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/contacts/:contactType` as const,
    },

    /**
 * No description
 *
 * @tags vendors / contract details
 * @name GetAgreements
 * @summary Retrieve the Vendor's agreements for given `{vendorId}`
 * @request GET:/contract-details/agreements
 * @secure
 * @response `200` `ModelGetAgreementsData` Vendor's agreements for given `{vendorId}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` does not exist
 */
    getAgreements: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetAgreementsData, ModelGetAgreementsError>({
          path: `/contract-details/agreements`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/contract-details/agreements` as const,
    },

    /**
     * No description
     *
     * @tags vendors / contract details
     * @name GetAgreementDownloadUrl
     * @summary Retrieve the Vendor's contract downloadUrl by vendorId and agreementId
     * @request GET:/contract-details/agreements/{agreementId}
     * @secure
     * @response `200` `ModelGetAgreementDownloadUrlData` contains the download url to download agreement pdf
     */
    getAgreementDownloadUrl: {
      request: ({
        agreementId,
        params = {},
      }: {
        agreementId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetAgreementDownloadUrlData, any>({
          path: `/contract-details/agreements/${agreementId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/contract-details/agreements/:agreementId` as const,
    },
  }
  customers = {
    /**
 * @description In order to buy products you need to register company data. Results in creating a new Customer
 *
 * @tags customers
 * @name PostCustomerCompanyRegistration
 * @summary Registers new Customer Company
 * @request POST:/customers
 * @response `201` `ModelPostCustomerCompanyRegistrationData` Company registered successfully.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    postCustomerCompanyRegistration: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerCompanyRegistrationPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerCompanyRegistrationData,
          ModelPostCustomerCompanyRegistrationError
        >({
          path: `/customers`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/customers` as const,
    },

    /**
 * @description Allows searching for articles based on search term. Supports pagination.
 *
 * @tags customers / article search
 * @name CustomerArticleSearch
 * @summary Search for articles
 * @request POST:/marketplace/articles/search
 * @secure
 * @response `200` `ModelCustomerArticleSearchData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    customerArticleSearch: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelCustomerArticleSearchParams
        data: ModelCustomerArticleSearchPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCustomerArticleSearchData,
          ModelCustomerArticleSearchError
        >({
          path: `/marketplace/articles/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/articles/search` as const,
    },

    /**
     * @description Allows retrieving full content of the shopping cart
     *
     * @tags cart and checkout
     * @name GetShoppingCart
     * @summary Retrieves content of shopping cart
     * @request GET:/carts/current
     * @secure
     * @response `200` `ModelGetShoppingCartData` Success
     */
    getShoppingCart: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetShoppingCartData, any>({
          path: `/carts/current`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/carts/current` as const,
    },

    /**
     * No description
     *
     * @tags cart and checkout
     * @name DeleteCart
     * @summary Deletes shopping cart
     * @request DELETE:/carts/current
     * @secure
     * @response `204` `ModelDeleteCartData` Cart successfully deleted
     */
    deleteCart: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelDeleteCartData, any>({
          path: `/carts/current`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/carts/current` as const,
    },

    /**
 * @description Allow to update global cart properties that will apply for all items
 *
 * @tags cart and checkout
 * @name UpdateCurrentCart
 * @summary Update cart properties
 * @request PATCH:/carts/current
 * @secure
 * @response `204` `ModelUpdateCurrentCartData` Cart successfully modified
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    updateCurrentCart: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCurrentCartPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateCurrentCartData, ModelUpdateCurrentCartError>({
          path: `/carts/current`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/carts/current` as const,
    },

    /**
     * @description Allows retrieving full content of budgets exceeded due to the shopping cart
     *
     * @tags cart and checkout
     * @name GetShoppingCartExceededBudgets
     * @summary Retrieves all budgets that are exceeded due to shopping cart
     * @request GET:/carts/current/exceeded-budgets
     * @secure
     * @response `200` `ModelGetShoppingCartExceededBudgetsData` Success
     */
    getShoppingCartExceededBudgets: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetShoppingCartExceededBudgetsData, any>({
          path: `/carts/current/exceeded-budgets`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/carts/current/exceeded-budgets` as const,
    },

    /**
 * @description Allows update cart vendor section values.
 *
 * @tags cart and checkout
 * @name UpdateCartVendorSection
 * @summary Update cart vendor section
 * @request PATCH:/carts/current/vendors/{vendorId}
 * @secure
 * @response `204` `ModelUpdateCartVendorSectionData` Vendor cart section updated successfully
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    updateCartVendorSection: {
      request: ({
        vendorId,
        data,
        params = {},
      }: {
        vendorId: string
        data: ModelUpdateCartVendorSectionPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCartVendorSectionData,
          ModelUpdateCartVendorSectionError
        >({
          path: `/carts/current/vendors/${vendorId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/carts/current/vendors/:vendorId` as const,
    },

    /**
     * @description Get articles quantities in the cart.
     *
     * @tags cart and checkout
     * @name GetArticlesQuantitiesInCart
     * @summary Get article quantities in the cart
     * @request GET:/carts/current/items
     * @secure
     * @response `200` `ModelGetArticlesQuantitiesInCartData` Success
     */
    getArticlesQuantitiesInCart: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetArticlesQuantitiesInCartData, any>({
          path: `/carts/current/items`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/carts/current/items` as const,
    },

    /**
 * @description - Add new articles to the cart. If the item with the given articleId does not already exist in the cart, a new cart item will be created. - Increase cart item quantity. If a not replicated item with the given articleId already exists in the cart, its quantity will be increased by the specified amount. - Replicate cart item. If a replicated items with the given articleId already exists in the cart, a new replicated item will be created with the specified quantity.
 *
 * @tags cart and checkout
 * @name AddArticlesToCart
 * @summary Add articles to the cart
 * @request POST:/carts/current/items
 * @secure
 * @response `201` `ModelAddArticlesToCartData` Items successfully added/updated/replicated in the shopping cart
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Some items cannot be added, as article does not exist
 */
    addArticlesToCart: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelAddArticlesToCartPayload
        params?: RequestParams
      }) =>
        this.request<ModelAddArticlesToCartData, ModelAddArticlesToCartError>({
          path: `/carts/current/items`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/carts/current/items` as const,
    },

    /**
 * @description Allows modification of the quantity or account type information of given article in the shopping cart. Only present fields will have effect - quantity can be updated without affecting account.
 *
 * @tags cart and checkout
 * @name UpdateCartItem
 * @summary Update item properties in the shopping cart
 * @request PATCH:/carts/current/items/{cartItemId}
 * @secure
 * @response `204` `ModelUpdateCartItemData` Item successfully modified in the cart
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article with given articleId not present in the shopping cart
 */
    updateCartItem: {
      request: ({
        cartItemId,
        data,
        params = {},
      }: {
        cartItemId: string
        data: ModelUpdateCartItemPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateCartItemData, ModelUpdateCartItemError>({
          path: `/carts/current/items/${cartItemId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/carts/current/items/:cartItemId` as const,
    },

    /**
     * @description Allows removal of the of given article from the shopping cart
     *
     * @tags cart and checkout
     * @name DeleteItemFromCart
     * @summary Delete item from the shopping cart
     * @request DELETE:/carts/current/items/{cartItemId}
     * @secure
     * @response `204` `ModelDeleteItemFromCartData` Item successfully removed from the shopping cart
     */
    deleteItemFromCart: {
      request: ({
        cartItemId,
        params = {},
      }: {
        cartItemId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteItemFromCartData, any>({
          path: `/carts/current/items/${cartItemId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/carts/current/items/:cartItemId` as const,
    },

    /**
 * No description
 *
 * @tags cart and checkout
 * @name ReplicateCartItem
 * @summary Replicate cart item
 * @request POST:/carts/current/items/{cartItemId}/replicate
 * @secure
 * @response `201` `ModelReplicateCartItemData` Item successfully replicated in the shopping cart
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Cart item with given cartItemId does not exist
 */
    replicateCartItem: {
      request: ({
        cartItemId,
        params = {},
      }: {
        cartItemId: string
        params?: RequestParams
      }) =>
        this.request<ModelReplicateCartItemData, ModelReplicateCartItemError>({
          path: `/carts/current/items/${cartItemId}/replicate`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/carts/current/items/:cartItemId/replicate` as const,
    },

    /**
 * @description Checks out current shopping cart, places an order, and then cleans up the current shopping cart content.
 *
 * @tags cart and checkout
 * @name CartCheckout
 * @summary Check out current shopping cart
 * @request POST:/carts/current/checkout
 * @secure
 * @response `200` `ModelCartCheckoutData` Cart successfully checked out
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Checkout cannot be completed due to a conflict in the cart's state or its contents.
 */
    cartCheckout: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCartCheckoutPayload
        params?: RequestParams
      }) =>
        this.request<ModelCartCheckoutData, ModelCartCheckoutError>({
          path: `/carts/current/checkout`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/carts/current/checkout` as const,
    },

    /**
 * @description Quantity of orders
 *
 * @tags customers / orders
 * @name GetCustomerOrdersCounters
 * @summary Returns quantity of orders of every kind
 * @request POST:/orders/counts
 * @secure
 * @response `200` `ModelGetCustomerOrdersCountersData` List of orders of customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerOrdersCounters: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelGetCustomerOrdersCountersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerOrdersCountersData,
          ModelGetCustomerOrdersCountersError
        >({
          path: `/orders/counts`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/orders/counts` as const,
    },

    /**
 * @description Search orders of customer
 *
 * @tags customers / orders
 * @name SearchCustomerPlatformOrders
 * @summary Search for orders
 * @request POST:/platform-orders
 * @secure
 * @response `200` `ModelSearchCustomerPlatformOrdersData` List of orders of customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    searchCustomerPlatformOrders: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerPlatformOrdersParams
        data: ModelSearchCustomerPlatformOrdersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerPlatformOrdersData,
          ModelSearchCustomerPlatformOrdersError
        >({
          path: `/platform-orders`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/platform-orders` as const,
    },

    /**
 * @description Get details of platform order of customer
 *
 * @tags customers / orders
 * @name GetCustomerPlatformOrder
 * @request GET:/platform-orders/{platformOrderId}
 * @secure
 * @response `200` `ModelGetCustomerPlatformOrderData` Details of platform order
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or platform order does not exist
 */
    getCustomerPlatformOrder: {
      request: ({
        platformOrderId,
        params = {},
      }: {
        platformOrderId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerPlatformOrderData,
          ModelGetCustomerPlatformOrderError
        >({
          path: `/platform-orders/${platformOrderId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-orders/:platformOrderId` as const,
    },

    /**
 * @description List of order items to be reordered from an order
 *
 * @tags customers / orders
 * @name ReorderCustomerPlatformOrder
 * @summary List of order items to be reordered from an order
 * @request POST:/platform-orders/{platformOrderId}/reorder
 * @secure
 * @response `201` `ModelReorderCustomerPlatformOrderData` List of items successfully added and not added to cart
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or platform order does not exist
 */
    reorderCustomerPlatformOrder: {
      request: ({
        platformOrderId,
        data,
        params = {},
      }: {
        platformOrderId: string
        data: ModelReorderCustomerPlatformOrderPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelReorderCustomerPlatformOrderData,
          ModelReorderCustomerPlatformOrderError
        >({
          path: `/platform-orders/${platformOrderId}/reorder`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/platform-orders/:platformOrderId/reorder` as const,
    },

    /**
     * @description Returns a list of Vendors of a given Customer that have placed orders
     *
     * @tags customers / orders
     * @name GetCustomerPlatformOrdersVendors
     * @summary Returns a list of Vendors of a given Customer that have placed orders
     * @request GET:/platform-orders/vendors
     * @secure
     * @response `200` `ModelGetCustomerPlatformOrdersVendorsData` List of Vendors of a given Customer that have placed orders
     */
    getCustomerPlatformOrdersVendors: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerPlatformOrdersVendorsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerPlatformOrdersVendorsData, any>({
          path: `/platform-orders/vendors`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-orders/vendors` as const,
    },

    /**
 * @description Gets configuration of custom order numbering
 *
 * @tags customers / orders
 * @name GetCustomerCustomOrderNumbering
 * @summary Gets configuration of custom order numbering
 * @request GET:/platform-orders/custom-numbering
 * @secure
 * @response `200` `ModelGetCustomerCustomOrderNumberingData` Custom order numbering configured successfully
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerCustomOrderNumbering: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerCustomOrderNumberingData,
          ModelGetCustomerCustomOrderNumberingError
        >({
          path: `/platform-orders/custom-numbering`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-orders/custom-numbering` as const,
    },

    /**
 * @description Configure new custom order numbering
 *
 * @tags customers / orders
 * @name PostCustomerCustomOrderNumbering
 * @summary Configure custom order numbering
 * @request POST:/platform-orders/custom-numbering
 * @secure
 * @response `204` `ModelPostCustomerCustomOrderNumberingData` Custom order numbering configured successfully
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    postCustomerCustomOrderNumbering: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerCustomOrderNumberingPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerCustomOrderNumberingData,
          ModelPostCustomerCustomOrderNumberingError
        >({
          path: `/platform-orders/custom-numbering`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/platform-orders/custom-numbering` as const,
    },

    /**
     * @description Returns a list of shoppers of a given Customer that have placed orders
     *
     * @tags customers / orders
     * @name GetCustomerPlatformOrderCustomerUsers
     * @summary Returns a list of shoppers of a given Customer that have placed orders
     * @request GET:/platform-orders/shoppers
     * @secure
     * @response `200` `ModelGetCustomerPlatformOrderCustomerUsersData` List of shoppers of a given Customer that have placed orders
     */
    getCustomerPlatformOrderCustomerUsers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerPlatformOrderCustomerUsersParams
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerPlatformOrderCustomerUsersData, any>({
          path: `/platform-orders/shoppers`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/platform-orders/shoppers` as const,
    },

    /**
 * @description Request to generate example order number for given settings
 *
 * @tags customers / orders
 * @name PostCustomerCustomOrderNumberingPreview
 * @summary Request to generate example order number for given settings
 * @request POST:/platform-orders/custom-numbering/preview
 * @secure
 * @response `200` `ModelPostCustomerCustomOrderNumberingPreviewData` Configuration of custom order numbering
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    postCustomerCustomOrderNumberingPreview: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerCustomOrderNumberingPreviewPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerCustomOrderNumberingPreviewData,
          ModelPostCustomerCustomOrderNumberingPreviewError
        >({
          path: `/platform-orders/custom-numbering/preview`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/platform-orders/custom-numbering/preview` as const,
    },

    /**
 * @description Search for list of ERP orders of customer
 *
 * @tags customers / erp-orders
 * @name ListCustomerErpOrders
 * @summary Search for ERP orders
 * @request POST:/erp-orders
 * @secure
 * @response `200` `ModelListCustomerErpOrdersData` List of ERP orders of customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    listCustomerErpOrders: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelListCustomerErpOrdersParams
        data: ModelListCustomerErpOrdersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelListCustomerErpOrdersData,
          ModelListCustomerErpOrdersError
        >({
          path: `/erp-orders`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/erp-orders` as const,
    },

    /**
 * @description Get details of ERP order of customer
 *
 * @tags customers / erp-orders
 * @name GetCustomerErpOrder
 * @request GET:/erp-orders/{erpOrderId}
 * @secure
 * @response `200` `ModelGetCustomerErpOrderData` Details of ERP order
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or ERP order does not exist
 */
    getCustomerErpOrder: {
      request: ({
        erpOrderId,
        params = {},
      }: {
        erpOrderId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerErpOrderData,
          ModelGetCustomerErpOrderError
        >({
          path: `/erp-orders/${erpOrderId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/erp-orders/:erpOrderId` as const,
    },

    /**
     * @description Returns a list of Vendors of a given Customer that have places orders via ERP backinterface
     *
     * @tags customers / erp-orders
     * @name GetCustomerErpOrdersVendors
     * @summary Returns a list of Vendors of a given Customer that have places orders via ERP backinterface
     * @request GET:/erp-orders/vendors
     * @secure
     * @response `200` `ModelGetCustomerErpOrdersVendorsData` List of Vendors of a given Customer that have places orders via ERP backinterface
     */
    getCustomerErpOrdersVendors: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerErpOrdersVendorsData, any>({
          path: `/erp-orders/vendors`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/erp-orders/vendors` as const,
    },

    /**
 * No description
 *
 * @tags customers / price restrictions
 * @name AddNewPriceRestriction
 * @summary Add new price restriction
 * @request POST:/price-restrictions
 * @secure
 * @response `201` `ModelAddNewPriceRestrictionData` New price restriction created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price restriction with provided name already exists
 */
    addNewPriceRestriction: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelAddNewPriceRestrictionPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAddNewPriceRestrictionData,
          ModelAddNewPriceRestrictionError
        >({
          path: `/price-restrictions`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/price-restrictions` as const,
    },

    /**
 * No description
 *
 * @tags customers / price restrictions
 * @name SearchPriceRestrictions
 * @summary Search for price restrictions
 * @request POST:/price-restrictions/search
 * @secure
 * @response `200` `ModelSearchPriceRestrictionsData` List of price restrictions of customer with default price restriction on top
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    searchPriceRestrictions: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchPriceRestrictionsParams
        data: ModelSearchPriceRestrictionsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchPriceRestrictionsData,
          ModelSearchPriceRestrictionsError
        >({
          path: `/price-restrictions/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/price-restrictions/search` as const,
    },

    /**
     * No description
     *
     * @tags customers / price restrictions
     * @name GetPriceRestriction
     * @summary Get price restriction
     * @request GET:/price-restrictions/{priceRestrictionId}
     * @secure
     * @response `200` `ModelGetPriceRestrictionData` Price restriction definition
     */
    getPriceRestriction: {
      request: ({
        priceRestrictionId,
        params = {},
      }: {
        priceRestrictionId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetPriceRestrictionData, any>({
          path: `/price-restrictions/${priceRestrictionId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-restrictions/:priceRestrictionId` as const,
    },

    /**
 * No description
 *
 * @tags customers / price restrictions
 * @name UpdatePriceRestriction
 * @summary Update price restriction
 * @request PUT:/price-restrictions/{priceRestrictionId}
 * @secure
 * @response `204` `ModelUpdatePriceRestrictionData` Price restriction updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price restriction does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price restriction with provided name already exists
 */
    updatePriceRestriction: {
      request: ({
        priceRestrictionId,
        data,
        params = {},
      }: {
        priceRestrictionId: string
        data: ModelUpdatePriceRestrictionPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdatePriceRestrictionData,
          ModelUpdatePriceRestrictionError
        >({
          path: `/price-restrictions/${priceRestrictionId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/price-restrictions/:priceRestrictionId` as const,
    },

    /**
 * No description
 *
 * @tags customers / price restrictions
 * @name DeletePriceRestriction
 * @summary Delete price restriction
 * @request DELETE:/price-restrictions/{priceRestrictionId}
 * @secure
 * @response `204` `ModelDeletePriceRestrictionData` Price restriction deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Price restriction does not exist
 */
    deletePriceRestriction: {
      request: ({
        priceRestrictionId,
        params = {},
      }: {
        priceRestrictionId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeletePriceRestrictionData,
          ModelDeletePriceRestrictionError
        >({
          path: `/price-restrictions/${priceRestrictionId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/price-restrictions/:priceRestrictionId` as const,
    },

    /**
 * No description
 *
 * @tags customers / price restrictions
 * @name GetCustomerPriceRestrictionAssignments
 * @summary Get customer article price restriction assignments
 * @request GET:/price-restrictions/{priceRestrictionId}/assignments
 * @secure
 * @response `200` `ModelGetCustomerPriceRestrictionAssignmentsData` Assigned customer users for article price restrictions
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getCustomerPriceRestrictionAssignments: {
      request: ({
        priceRestrictionId,
        params = {},
      }: {
        priceRestrictionId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerPriceRestrictionAssignmentsData,
          ModelGetCustomerPriceRestrictionAssignmentsError
        >({
          path: `/price-restrictions/${priceRestrictionId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/price-restrictions/:priceRestrictionId/assignments` as const,
    },

    /**
 * @description Assign list of customer users to an article price restriction
 *
 * @tags customers / price restrictions
 * @name PutCustomerPriceRestrictionAssignment
 * @summary Assign list of customer users to an article price restriction
 * @request PUT:/price-restrictions/{priceRestrictionId}/assignments
 * @secure
 * @response `200` `ModelPutCustomerPriceRestrictionAssignmentData` Assignments updated for the article price restriction
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    putCustomerPriceRestrictionAssignment: {
      request: ({
        priceRestrictionId,
        data,
        params = {},
      }: {
        priceRestrictionId: string
        data: ModelPutCustomerPriceRestrictionAssignmentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutCustomerPriceRestrictionAssignmentData,
          ModelPutCustomerPriceRestrictionAssignmentError
        >({
          path: `/price-restrictions/${priceRestrictionId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/price-restrictions/:priceRestrictionId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags customers / cart restrictions
 * @name CreateCartRestriction
 * @summary Create cart restriction
 * @request POST:/cart-restrictions
 * @secure
 * @response `201` `ModelCreateCartRestrictionData` New cart restriction created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    createCartRestriction: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCreateCartRestrictionPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCreateCartRestrictionData,
          ModelCreateCartRestrictionError
        >({
          path: `/cart-restrictions`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/cart-restrictions` as const,
    },

    /**
 * No description
 *
 * @tags customers / cart restrictions
 * @name SearchCartRestrictions
 * @summary Search for cart restrictions
 * @request POST:/cart-restrictions/search
 * @secure
 * @response `200` `ModelSearchCartRestrictionsData` List of cart restrictions of customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    searchCartRestrictions: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelSearchCartRestrictionsParams
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCartRestrictionsData,
          ModelSearchCartRestrictionsError
        >({
          path: `/cart-restrictions/search`,
          method: 'POST',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/cart-restrictions/search` as const,
    },

    /**
 * No description
 *
 * @tags customers / cart restrictions
 * @name UpdateCartRestriction
 * @summary Update cart restriction
 * @request PUT:/cart-restrictions/{cartRestrictionId}
 * @secure
 * @response `204` `ModelUpdateCartRestrictionData` Cart restriction updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    updateCartRestriction: {
      request: ({
        cartRestrictionId,
        data,
        params = {},
      }: {
        cartRestrictionId: string
        data: ModelUpdateCartRestrictionPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCartRestrictionData,
          ModelUpdateCartRestrictionError
        >({
          path: `/cart-restrictions/${cartRestrictionId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/cart-restrictions/:cartRestrictionId` as const,
    },

    /**
 * No description
 *
 * @tags customers / cart restrictions
 * @name DeleteCartRestriction
 * @summary Delete cart restriction
 * @request DELETE:/cart-restrictions/{cartRestrictionId}
 * @secure
 * @response `204` `ModelDeleteCartRestrictionData` Cart restriction deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    deleteCartRestriction: {
      request: ({
        cartRestrictionId,
        params = {},
      }: {
        cartRestrictionId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteCartRestrictionData,
          ModelDeleteCartRestrictionError
        >({
          path: `/cart-restrictions/${cartRestrictionId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/cart-restrictions/:cartRestrictionId` as const,
    },

    /**
 * No description
 *
 * @tags customers / cart restrictions
 * @name GetCustomerCartRestrictionAssignments
 * @summary Get customer cart restriction assignments
 * @request GET:/cart-restrictions/{cartRestrictionId}/assignments
 * @secure
 * @response `200` `ModelGetCustomerCartRestrictionAssignmentsData` Assigned customer users for cart restrictions
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    getCustomerCartRestrictionAssignments: {
      request: ({
        cartRestrictionId,
        params = {},
      }: {
        cartRestrictionId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerCartRestrictionAssignmentsData,
          ModelGetCustomerCartRestrictionAssignmentsError
        >({
          path: `/cart-restrictions/${cartRestrictionId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/cart-restrictions/:cartRestrictionId/assignments` as const,
    },

    /**
 * @description Assign list of customer users to a cart restriction
 *
 * @tags customers / cart restrictions
 * @name PutCustomerCartRestrictionAssignment
 * @summary Assign list of customer users to a cart restriction
 * @request PUT:/cart-restrictions/{cartRestrictionId}/assignments
 * @secure
 * @response `204` `ModelPutCustomerCartRestrictionAssignmentData` Assignments updated for the cart restriction
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    putCustomerCartRestrictionAssignment: {
      request: ({
        cartRestrictionId,
        data,
        params = {},
      }: {
        cartRestrictionId: string
        data: ModelPutCustomerCartRestrictionAssignmentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutCustomerCartRestrictionAssignmentData,
          ModelPutCustomerCartRestrictionAssignmentError
        >({
          path: `/cart-restrictions/${cartRestrictionId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/cart-restrictions/:cartRestrictionId/assignments` as const,
    },

    /**
 * @description Search catalog views of customer
 *
 * @tags customers / catalog views
 * @name SearchCustomerCatalogViews
 * @summary Search for catalog views
 * @request POST:/catalog-views/search
 * @secure
 * @response `200` `ModelSearchCustomerCatalogViewsData` List of catalog views of customer with default view on top
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    searchCustomerCatalogViews: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerCatalogViewsParams
        data: ModelSearchCustomerCatalogViewsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerCatalogViewsData,
          ModelSearchCustomerCatalogViewsError
        >({
          path: `/catalog-views/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/catalog-views/search` as const,
    },

    /**
 * @description Deletes catalog view
 *
 * @tags customers / catalog views
 * @name DeleteCustomerCatalogView
 * @summary Deletes catalog view
 * @request DELETE:/catalog-views/{catalogViewId}
 * @secure
 * @response `204` `ModelDeleteCustomerCatalogViewData` Catalog View deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    deleteCustomerCatalogView: {
      request: ({
        catalogViewId,
        params = {},
      }: {
        catalogViewId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteCustomerCatalogViewData,
          ModelDeleteCustomerCatalogViewError
        >({
          path: `/catalog-views/${catalogViewId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/catalog-views/:catalogViewId` as const,
    },

    /**
 * No description
 *
 * @tags customers / catalog views
 * @name GetCustomerCatalogViewAssignments
 * @summary Get customer catalog view assignments
 * @request GET:/catalog-views/{catalogViewId}/assignments
 * @secure
 * @response `200` `ModelGetCustomerCatalogViewAssignmentsData` Assigned customer users for catalog view
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getCustomerCatalogViewAssignments: {
      request: ({
        catalogViewId,
        params = {},
      }: {
        catalogViewId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerCatalogViewAssignmentsData,
          ModelGetCustomerCatalogViewAssignmentsError
        >({
          path: `/catalog-views/${catalogViewId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/catalog-views/:catalogViewId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags customers / catalog views
 * @name PutCustomerCatalogViewAssignment
 * @summary Assign list of customer users to a catalog view
 * @request PUT:/catalog-views/{catalogViewId}/assignments
 * @secure
 * @response `200` `ModelPutCustomerCatalogViewAssignmentData` Assignments updated for the catalog view
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    putCustomerCatalogViewAssignment: {
      request: ({
        catalogViewId,
        data,
        params = {},
      }: {
        catalogViewId: string
        data: ModelPutCustomerCatalogViewAssignmentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutCustomerCatalogViewAssignmentData,
          ModelPutCustomerCatalogViewAssignmentError
        >({
          path: `/catalog-views/${catalogViewId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/catalog-views/:catalogViewId/assignments` as const,
    },

    /**
 * @description Create new catalog view draft for customer from existing catalog view or for new catalog view
 *
 * @tags customers / catalog views / drafts
 * @name CreateNewCustomerCatalogViewDraft
 * @summary Create new catalog view draft
 * @request POST:/catalog-view-drafts
 * @secure
 * @response `201` `ModelCreateNewCustomerCatalogViewDraftData` Successfully crated new catalog view draft
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    createNewCustomerCatalogViewDraft: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCreateNewCustomerCatalogViewDraftPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCreateNewCustomerCatalogViewDraftData,
          ModelCreateNewCustomerCatalogViewDraftError
        >({
          path: `/catalog-view-drafts`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/catalog-view-drafts` as const,
    },

    /**
 * @description Get catalog view draft
 *
 * @tags customers / catalog views / drafts
 * @name GetCustomerCatalogViewDraft
 * @summary Get catalog view draft
 * @request GET:/catalog-view-drafts/{catalogViewDraftId}
 * @secure
 * @response `200` `ModelGetCustomerCatalogViewDraftData` Catalog view draft
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    getCustomerCatalogViewDraft: {
      request: ({
        catalogViewDraftId,
        params = {},
      }: {
        catalogViewDraftId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerCatalogViewDraftData,
          ModelGetCustomerCatalogViewDraftError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId` as const,
    },

    /**
 * @description Update attributes of catalog view draft
 *
 * @tags customers / catalog views / drafts
 * @name UpdateCustomerCatalogViewDraft
 * @summary Update attributes of catalog view draft
 * @request PATCH:/catalog-view-drafts/{catalogViewDraftId}
 * @secure
 * @response `204` `ModelUpdateCustomerCatalogViewDraftData` Catalog view draft updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    updateCustomerCatalogViewDraft: {
      request: ({
        catalogViewDraftId,
        data,
        params = {},
      }: {
        catalogViewDraftId: string
        data: ModelUpdateCustomerCatalogViewDraftPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerCatalogViewDraftData,
          ModelUpdateCustomerCatalogViewDraftError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId` as const,
    },

    /**
 * @description Deletes catalog view draft
 *
 * @tags customers / catalog views / drafts
 * @name DeleteCustomerCatalogViewDraft
 * @summary Deletes catalog view draft
 * @request DELETE:/catalog-view-drafts/{catalogViewDraftId}
 * @secure
 * @response `204` `ModelDeleteCustomerCatalogViewDraftData` Catalog View Draft deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    deleteCustomerCatalogViewDraft: {
      request: ({
        catalogViewDraftId,
        params = {},
      }: {
        catalogViewDraftId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteCustomerCatalogViewDraftData,
          ModelDeleteCustomerCatalogViewDraftError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId` as const,
    },

    /**
 * @description Promotes draft to current configuration customer catalog view
 *
 * @tags customers / catalog views / drafts
 * @name PromoteCustomerCatalogViewDraft
 * @summary Promote draft to become current configuration
 * @request POST:/catalog-view-drafts/{catalogViewDraftId}/promote
 * @secure
 * @response `200` `ModelPromoteCustomerCatalogViewDraftData` Successfully promoted draft to become new catalog view
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Catalog view with provided name already exists
 */
    promoteCustomerCatalogViewDraft: {
      request: ({
        catalogViewDraftId,
        params = {},
      }: {
        catalogViewDraftId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelPromoteCustomerCatalogViewDraftData,
          ModelPromoteCustomerCatalogViewDraftError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}/promote`,
          method: 'POST',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId/promote` as const,
    },

    /**
 * @description Count 'my' vendors including search terms in catalog view draft
 *
 * @tags customers / catalog views / drafts
 * @name CountCustomerCatalogViewDraftMyVendors
 * @summary Count 'my' vendors including search terms
 * @request POST:/catalog-view-drafts/{catalogViewDraftId}/my-vendors/counts
 * @secure
 * @response `200` `ModelCountCustomerCatalogViewDraftMyVendorsData` Counted visible vendors
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    countCustomerCatalogViewDraftMyVendors: {
      request: ({
        catalogViewDraftId,
        data,
        params = {},
      }: {
        catalogViewDraftId: string
        data: ModelCountCustomerCatalogViewDraftMyVendorsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCountCustomerCatalogViewDraftMyVendorsData,
          ModelCountCustomerCatalogViewDraftMyVendorsError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}/my-vendors/counts`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId/my-vendors/counts` as const,
    },

    /**
 * @description Search for vendors that customer has relationship with
 *
 * @tags customers / catalog views / drafts
 * @name SearchCustomerCatalogViewDraftMyVendors
 * @summary Search for my vendors list
 * @request POST:/catalog-view-drafts/{catalogViewDraftId}/my-vendors/search
 * @secure
 * @response `200` `ModelSearchCustomerCatalogViewDraftMyVendorsData` List of my vendors
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    searchCustomerCatalogViewDraftMyVendors: {
      request: ({
        pathAndQueryParams: { catalogViewDraftId, ...query },
        data,
        params = {},
      }: {
        pathAndQueryParams: ModelSearchCustomerCatalogViewDraftMyVendorsParams
        data: ModelSearchCustomerCatalogViewDraftMyVendorsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerCatalogViewDraftMyVendorsData,
          ModelSearchCustomerCatalogViewDraftMyVendorsError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}/my-vendors/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId/my-vendors/search` as const,
    },

    /**
 * @description Edit configuration for vendors that customer has relationship with
 *
 * @tags customers / catalog views / drafts
 * @name PatchCustomerCatalogViewDraftMyVendor
 * @summary Edit configuration for some of my vendors
 * @request PATCH:/catalog-view-drafts/{catalogViewDraftId}/my-vendors
 * @secure
 * @response `204` `ModelPatchCustomerCatalogViewDraftMyVendorData` My vendor's configuration updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    patchCustomerCatalogViewDraftMyVendor: {
      request: ({
        catalogViewDraftId,
        data,
        params = {},
      }: {
        catalogViewDraftId: string
        data: ModelPatchCustomerCatalogViewDraftMyVendorPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchCustomerCatalogViewDraftMyVendorData,
          ModelPatchCustomerCatalogViewDraftMyVendorError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}/my-vendors`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId/my-vendors` as const,
    },

    /**
 * @description Count 'other' vendors including search terms in catalog view draft
 *
 * @tags customers / catalog views / drafts
 * @name CountCustomerCatalogViewDraftOtherVendors
 * @summary Count 'other' vendors including search terms
 * @request POST:/catalog-view-drafts/{catalogViewDraftId}/other-vendors/counts
 * @secure
 * @response `200` `ModelCountCustomerCatalogViewDraftOtherVendorsData` Counted visible vendors
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    countCustomerCatalogViewDraftOtherVendors: {
      request: ({
        catalogViewDraftId,
        data,
        params = {},
      }: {
        catalogViewDraftId: string
        data: ModelCountCustomerCatalogViewDraftOtherVendorsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCountCustomerCatalogViewDraftOtherVendorsData,
          ModelCountCustomerCatalogViewDraftOtherVendorsError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}/other-vendors/counts`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId/other-vendors/counts` as const,
    },

    /**
 * @description Search for vendors that customer does not have relationship with, but it is in open marketplace
 *
 * @tags customers / catalog views / drafts
 * @name SearchCustomerCatalogViewDraftOtherVendors
 * @summary Search for other vendors list (only public vendors)
 * @request POST:/catalog-view-drafts/{catalogViewDraftId}/other-vendors/search
 * @secure
 * @response `200` `ModelSearchCustomerCatalogViewDraftOtherVendorsData` Catalog view draft
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    searchCustomerCatalogViewDraftOtherVendors: {
      request: ({
        pathAndQueryParams: { catalogViewDraftId, ...query },
        data,
        params = {},
      }: {
        pathAndQueryParams: ModelSearchCustomerCatalogViewDraftOtherVendorsParams
        data: ModelSearchCustomerCatalogViewDraftOtherVendorsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerCatalogViewDraftOtherVendorsData,
          ModelSearchCustomerCatalogViewDraftOtherVendorsError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}/other-vendors/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId/other-vendors/search` as const,
    },

    /**
 * @description Edit configuration for vendors that customer does not have relationship with (only public vendors)
 *
 * @tags customers / catalog views / drafts
 * @name PatchCustomerCatalogViewDraftOtherVendor
 * @summary Edit configuration for other vendors
 * @request PATCH:/catalog-view-drafts/{catalogViewDraftId}/other-vendors
 * @secure
 * @response `204` `ModelPatchCustomerCatalogViewDraftOtherVendorData` Other (public) vendor's configuration updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    patchCustomerCatalogViewDraftOtherVendor: {
      request: ({
        catalogViewDraftId,
        data,
        params = {},
      }: {
        catalogViewDraftId: string
        data: ModelPatchCustomerCatalogViewDraftOtherVendorPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchCustomerCatalogViewDraftOtherVendorData,
          ModelPatchCustomerCatalogViewDraftOtherVendorError
        >({
          path: `/catalog-view-drafts/${catalogViewDraftId}/other-vendors`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/catalog-view-drafts/:catalogViewDraftId/other-vendors` as const,
    },

    /**
 * @description Quantity of orders
 *
 * @tags customers / deliveries and payments
 * @name GetCustomerOrdersCountersForDeliveriesAndPayments
 * @summary Returns quantity of orders of every kind
 * @request POST:/deliveries-and-payments/orders/counts
 * @secure
 * @response `200` `ModelGetCustomerOrdersCountersForDeliveriesAndPaymentsData` List of orders of customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerOrdersCountersForDeliveriesAndPayments: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelGetCustomerOrdersCountersForDeliveriesAndPaymentsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerOrdersCountersForDeliveriesAndPaymentsData,
          ModelGetCustomerOrdersCountersForDeliveriesAndPaymentsError
        >({
          path: `/deliveries-and-payments/orders/counts`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/orders/counts` as const,
    },

    /**
 * @description Search for list of ERP orders of customer
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name SearchCustomerDeliveriesAndPaymentsForErpOrders
 * @summary Search for ERP orders
 * @request POST:/deliveries-and-payments/erp-orders/search
 * @secure
 * @response `200` `ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersData` List of ERP orders of customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    searchCustomerDeliveriesAndPaymentsForErpOrders: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersParams
        data: ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersData,
          ModelSearchCustomerDeliveriesAndPaymentsForErpOrdersError
        >({
          path: `/deliveries-and-payments/erp-orders/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/search` as const,
    },

    /**
 * @description Get details of ERP order of customer
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name GetCustomerDeliveriesAndPaymentsForErpOrder
 * @request GET:/deliveries-and-payments/erp-orders/{erpOrderId}
 * @secure
 * @response `200` `ModelGetCustomerDeliveriesAndPaymentsForErpOrderData` Details of ERP order
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or ERP order does not exist
 */
    getCustomerDeliveriesAndPaymentsForErpOrder: {
      request: ({
        erpOrderId,
        params = {},
      }: {
        erpOrderId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerDeliveriesAndPaymentsForErpOrderData,
          ModelGetCustomerDeliveriesAndPaymentsForErpOrderError
        >({
          path: `/deliveries-and-payments/erp-orders/${erpOrderId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/:erpOrderId` as const,
    },

    /**
     * @description Returns a list of Vendors of a given Customer for ERP orders
     *
     * @tags customers / deliveries and payments / ERP orders
     * @name GetCustomerDeliveriesAndPaymentsVendorsForErpOrders
     * @summary Returns a list of Vendors of a given Customer for ERP orders
     * @request GET:/deliveries-and-payments/erp-orders/vendors
     * @secure
     * @response `200` `ModelGetCustomerDeliveriesAndPaymentsVendorsForErpOrdersData` List of Vendors of a given Customer for ERP orders
     */
    getCustomerDeliveriesAndPaymentsVendorsForErpOrders: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerDeliveriesAndPaymentsVendorsForErpOrdersData,
          any
        >({
          path: `/deliveries-and-payments/erp-orders/vendors`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/vendors` as const,
    },

    /**
 * @description Update whole ERP order payment status
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name UpdateErpOrderPaymentStatus
 * @summary Update ERP order payment status
 * @request POST:/deliveries-and-payments/erp-orders/{erpOrderId}/payment-status
 * @secure
 * @response `204` `ModelUpdateErpOrderPaymentStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or ERP order does not exist
 */
    updateErpOrderPaymentStatus: {
      request: ({
        erpOrderId,
        data,
        params = {},
      }: {
        erpOrderId: string
        data: ModelUpdateErpOrderPaymentStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateErpOrderPaymentStatusData,
          ModelUpdateErpOrderPaymentStatusError
        >({
          path: `/deliveries-and-payments/erp-orders/${erpOrderId}/payment-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/:erpOrderId/payment-status` as const,
    },

    /**
 * @description Update whole ERP order payment comment
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name UpdateErpOrderPaymentComment
 * @summary Update ERP order payment comment
 * @request PUT:/deliveries-and-payments/erp-orders/{erpOrderId}/payment-comment
 * @secure
 * @response `204` `ModelUpdateErpOrderPaymentCommentData` Comment successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or ERP order does not exist
 */
    updateErpOrderPaymentComment: {
      request: ({
        erpOrderId,
        data,
        params = {},
      }: {
        erpOrderId: string
        data: ModelUpdateErpOrderPaymentCommentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateErpOrderPaymentCommentData,
          ModelUpdateErpOrderPaymentCommentError
        >({
          path: `/deliveries-and-payments/erp-orders/${erpOrderId}/payment-comment`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/:erpOrderId/payment-comment` as const,
    },

    /**
 * @description Update payment status of a single item within an ERP order
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name UpdateErpOrderItemPaymentStatus
 * @summary Update ERP order item payment status
 * @request POST:/deliveries-and-payments/erp-orders/{erpOrderId}/items/{erpOrderItemId}/payment-status
 * @secure
 * @response `204` `ModelUpdateErpOrderItemPaymentStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer, order or item does not exist
 */
    updateErpOrderItemPaymentStatus: {
      request: ({
        erpOrderId,
        erpOrderItemId,
        data,
        params = {},
      }: {
        erpOrderId: string
        erpOrderItemId: number
        data: ModelUpdateErpOrderItemPaymentStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateErpOrderItemPaymentStatusData,
          ModelUpdateErpOrderItemPaymentStatusError
        >({
          path: `/deliveries-and-payments/erp-orders/${erpOrderId}/items/${erpOrderItemId}/payment-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/:erpOrderId/items/:erpOrderItemId/payment-status` as const,
    },

    /**
 * @description Update whole ERP order delivery status
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name UpdateErpOrderDeliveryStatus
 * @summary Update ERP order delivery status
 * @request POST:/deliveries-and-payments/erp-orders/{erpOrderId}/delivery-status
 * @secure
 * @response `204` `ModelUpdateErpOrderDeliveryStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or ERP order does not exist
 */
    updateErpOrderDeliveryStatus: {
      request: ({
        erpOrderId,
        data,
        params = {},
      }: {
        erpOrderId: string
        data: ModelUpdateErpOrderDeliveryStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateErpOrderDeliveryStatusData,
          ModelUpdateErpOrderDeliveryStatusError
        >({
          path: `/deliveries-and-payments/erp-orders/${erpOrderId}/delivery-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/:erpOrderId/delivery-status` as const,
    },

    /**
 * @description Update whole ERP order delivery comment
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name UpdateErpOrderDeliveryComment
 * @summary Update ERP order delivery comment
 * @request PUT:/deliveries-and-payments/erp-orders/{erpOrderId}/delivery-comment
 * @secure
 * @response `204` `ModelUpdateErpOrderDeliveryCommentData` Comment successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or ERP order does not exist
 */
    updateErpOrderDeliveryComment: {
      request: ({
        erpOrderId,
        data,
        params = {},
      }: {
        erpOrderId: string
        data: ModelUpdateErpOrderDeliveryCommentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateErpOrderDeliveryCommentData,
          ModelUpdateErpOrderDeliveryCommentError
        >({
          path: `/deliveries-and-payments/erp-orders/${erpOrderId}/delivery-comment`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/:erpOrderId/delivery-comment` as const,
    },

    /**
 * @description Update delivery status of a single item within an ERP order
 *
 * @tags customers / deliveries and payments / ERP orders
 * @name UpdateErpItemDeliveryStatus
 * @summary Update ERP order item delivery status
 * @request POST:/deliveries-and-payments/erp-orders/{erpOrderId}/items/{erpOrderItemId}/delivery-status
 * @secure
 * @response `204` `ModelUpdateErpItemDeliveryStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer, ERP order or item does not exist
 */
    updateErpItemDeliveryStatus: {
      request: ({
        erpOrderId,
        erpOrderItemId,
        data,
        params = {},
      }: {
        erpOrderId: string
        erpOrderItemId: number
        data: ModelUpdateErpItemDeliveryStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateErpItemDeliveryStatusData,
          ModelUpdateErpItemDeliveryStatusError
        >({
          path: `/deliveries-and-payments/erp-orders/${erpOrderId}/items/${erpOrderItemId}/delivery-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/erp-orders/:erpOrderId/items/:erpOrderItemId/delivery-status` as const,
    },

    /**
 * @description Search orders of customer
 *
 * @tags customers / deliveries and payments / platform orders
 * @name SearchCustomerDeliveriesAndPaymentsForPlatformOrders
 * @summary Search for orders
 * @request POST:/deliveries-and-payments/platform-orders
 * @secure
 * @response `200` `ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersData` List of orders of customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    searchCustomerDeliveriesAndPaymentsForPlatformOrders: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersParams
        data: ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersData,
          ModelSearchCustomerDeliveriesAndPaymentsForPlatformOrdersError
        >({
          path: `/deliveries-and-payments/platform-orders`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders` as const,
    },

    /**
 * @description Get details of platform order of customer
 *
 * @tags customers / deliveries and payments / platform orders
 * @name GetCustomerDeliveriesAndPaymentsForPlatformOrder
 * @request GET:/deliveries-and-payments/platform-orders/{platformOrderId}
 * @secure
 * @response `200` `ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderData` Details of platform order
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or platform order does not exist
 */
    getCustomerDeliveriesAndPaymentsForPlatformOrder: {
      request: ({
        platformOrderId,
        params = {},
      }: {
        platformOrderId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderData,
          ModelGetCustomerDeliveriesAndPaymentsForPlatformOrderError
        >({
          path: `/deliveries-and-payments/platform-orders/${platformOrderId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/:platformOrderId` as const,
    },

    /**
 * @description Update whole order payment status
 *
 * @tags customers / deliveries and payments / platform orders
 * @name UpdateOrderPaymentStatus
 * @summary Update order payment status
 * @request POST:/deliveries-and-payments/platform-orders/{platformOrderId}/payment-status
 * @secure
 * @response `204` `ModelUpdateOrderPaymentStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or order does not exist
 */
    updateOrderPaymentStatus: {
      request: ({
        platformOrderId,
        data,
        params = {},
      }: {
        platformOrderId: string
        data: ModelUpdateOrderPaymentStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOrderPaymentStatusData,
          ModelUpdateOrderPaymentStatusError
        >({
          path: `/deliveries-and-payments/platform-orders/${platformOrderId}/payment-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/:platformOrderId/payment-status` as const,
    },

    /**
 * @description Update whole order payment comment
 *
 * @tags customers / deliveries and payments / platform orders
 * @name UpdateOrderPaymentComment
 * @summary Update order payment comment
 * @request PUT:/deliveries-and-payments/platform-orders/{platformOrderId}/payment-comment
 * @secure
 * @response `204` `ModelUpdateOrderPaymentCommentData` Comment successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or order does not exist
 */
    updateOrderPaymentComment: {
      request: ({
        platformOrderId,
        data,
        params = {},
      }: {
        platformOrderId: string
        data: ModelUpdateOrderPaymentCommentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOrderPaymentCommentData,
          ModelUpdateOrderPaymentCommentError
        >({
          path: `/deliveries-and-payments/platform-orders/${platformOrderId}/payment-comment`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/:platformOrderId/payment-comment` as const,
    },

    /**
 * @description Update payment status of a single item within an order
 *
 * @tags customers / deliveries and payments / platform orders
 * @name UpdateOrderItemPaymentStatus
 * @summary Update order item payment status
 * @request POST:/deliveries-and-payments/platform-orders/{platformOrderId}/items/{orderItemId}/payment-status
 * @secure
 * @response `204` `ModelUpdateOrderItemPaymentStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer, order or item does not exist
 */
    updateOrderItemPaymentStatus: {
      request: ({
        platformOrderId,
        orderItemId,
        data,
        params = {},
      }: {
        platformOrderId: string
        orderItemId: string
        data: ModelUpdateOrderItemPaymentStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOrderItemPaymentStatusData,
          ModelUpdateOrderItemPaymentStatusError
        >({
          path: `/deliveries-and-payments/platform-orders/${platformOrderId}/items/${orderItemId}/payment-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/:platformOrderId/items/:orderItemId/payment-status` as const,
    },

    /**
 * @description Update whole order delivery status
 *
 * @tags customers / deliveries and payments / platform orders
 * @name UpdateOrderDeliveryStatus
 * @summary Update order delivery status
 * @request POST:/deliveries-and-payments/platform-orders/{platformOrderId}/delivery-status
 * @secure
 * @response `204` `ModelUpdateOrderDeliveryStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or order does not exist
 */
    updateOrderDeliveryStatus: {
      request: ({
        platformOrderId,
        data,
        params = {},
      }: {
        platformOrderId: string
        data: ModelUpdateOrderDeliveryStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOrderDeliveryStatusData,
          ModelUpdateOrderDeliveryStatusError
        >({
          path: `/deliveries-and-payments/platform-orders/${platformOrderId}/delivery-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/:platformOrderId/delivery-status` as const,
    },

    /**
 * @description Update whole order delivery comment
 *
 * @tags customers / deliveries and payments / platform orders
 * @name UpdateOrderDeliveryComment
 * @summary Update order delivery comment
 * @request PUT:/deliveries-and-payments/platform-orders/{platformOrderId}/delivery-comment
 * @secure
 * @response `204` `ModelUpdateOrderDeliveryCommentData` Comment successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer or order does not exist
 */
    updateOrderDeliveryComment: {
      request: ({
        platformOrderId,
        data,
        params = {},
      }: {
        platformOrderId: string
        data: ModelUpdateOrderDeliveryCommentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOrderDeliveryCommentData,
          ModelUpdateOrderDeliveryCommentError
        >({
          path: `/deliveries-and-payments/platform-orders/${platformOrderId}/delivery-comment`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/:platformOrderId/delivery-comment` as const,
    },

    /**
 * @description Update delivery status of a single item within an order
 *
 * @tags customers / deliveries and payments / platform orders
 * @name UpdateItemDeliveryStatus
 * @summary Update order item delivery status
 * @request POST:/deliveries-and-payments/platform-orders/{platformOrderId}/items/{orderItemId}/delivery-status
 * @secure
 * @response `204` `ModelUpdateItemDeliveryStatusData` Status successfully updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer, order or item does not exist
 */
    updateItemDeliveryStatus: {
      request: ({
        platformOrderId,
        orderItemId,
        data,
        params = {},
      }: {
        platformOrderId: string
        orderItemId: string
        data: ModelUpdateItemDeliveryStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateItemDeliveryStatusData,
          ModelUpdateItemDeliveryStatusError
        >({
          path: `/deliveries-and-payments/platform-orders/${platformOrderId}/items/${orderItemId}/delivery-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/:platformOrderId/items/:orderItemId/delivery-status` as const,
    },

    /**
     * @description Returns a list of Vendors of a given Customer that have placed orders
     *
     * @tags customers / deliveries and payments / platform orders
     * @name GetCustomerDeliveriesAndPaymentsVendors
     * @summary Returns a list of Vendors of a given Customer that have placed orders
     * @request GET:/deliveries-and-payments/platform-orders/vendors
     * @secure
     * @response `200` `ModelGetCustomerDeliveriesAndPaymentsVendorsData` List of Vendors of a given Customer that have placed orders
     */
    getCustomerDeliveriesAndPaymentsVendors: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerDeliveriesAndPaymentsVendorsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerDeliveriesAndPaymentsVendorsData, any>({
          path: `/deliveries-and-payments/platform-orders/vendors`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/vendors` as const,
    },

    /**
     * @description Returns a list of shoppers of a given Customer that have placed orders
     *
     * @tags customers / deliveries and payments / platform orders
     * @name GetCustomerDeliveriesAndPaymentsCustomerUsers
     * @summary Returns a list of shoppers of a given Customer that have placed orders
     * @request GET:/deliveries-and-payments/platform-orders/shoppers
     * @secure
     * @response `200` `ModelGetCustomerDeliveriesAndPaymentsCustomerUsersData` List of shoppers of a given Customer that have placed orders
     */
    getCustomerDeliveriesAndPaymentsCustomerUsers: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerDeliveriesAndPaymentsCustomerUsersData,
          any
        >({
          path: `/deliveries-and-payments/platform-orders/shoppers`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/deliveries-and-payments/platform-orders/shoppers` as const,
    },

    /**
     * @description Returns all the delivery addresses available to the given customerId
     *
     * @tags customers / delivery addresses
     * @name GetDeliveryAddresses
     * @summary Returns a list of delivery addresses that can be edited
     * @request POST:/delivery-addresses/search
     * @secure
     * @response `200` `ModelGetDeliveryAddressesData` List of delivery addresses available to the given customerId
     */
    getDeliveryAddresses: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelGetDeliveryAddressesParams
        data: ModelGetDeliveryAddressesPayload
        params?: RequestParams
      }) =>
        this.request<ModelGetDeliveryAddressesData, any>({
          path: `/delivery-addresses/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/delivery-addresses/search` as const,
    },

    /**
     * @description Returns all the delivery addresses available to be used in orders by customerUserId.
     *
     * @tags customers / delivery addresses
     * @name SearchCustomerUserDeliveryAddresses
     * @summary Returns a list of delivery addresses assigned to a shopper
     * @request POST:/users/{customerUserId}/delivery-addresses/search
     * @secure
     * @response `200` `ModelSearchCustomerUserDeliveryAddressesData` List of delivery addresses available to the given customerUserId
     */
    searchCustomerUserDeliveryAddresses: {
      request: ({
        pathAndQueryParams: { customerUserId, ...query },
        data,
        params = {},
      }: {
        pathAndQueryParams: ModelSearchCustomerUserDeliveryAddressesParams
        data: ModelSearchCustomerUserDeliveryAddressesPayload
        params?: RequestParams
      }) =>
        this.request<ModelSearchCustomerUserDeliveryAddressesData, any>({
          path: `/users/${customerUserId}/delivery-addresses/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/delivery-addresses/search` as const,
    },

    /**
     * @description Saves new delivery address for the customer
     *
     * @tags customers / delivery addresses
     * @name CreateDeliveryAddress
     * @summary Create new delivery address
     * @request POST:/delivery-addresses
     * @secure
     * @response `201` `ModelCreateDeliveryAddressData` Successfully saved new delivery address for customer
     */
    createDeliveryAddress: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCreateDeliveryAddressPayload
        params?: RequestParams
      }) =>
        this.request<ModelCreateDeliveryAddressData, any>({
          path: `/delivery-addresses`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/delivery-addresses` as const,
    },

    /**
 * @description Updates the specified `deliveryAddressId` of the `customerId` with possible customizations
 *
 * @tags customers / delivery addresses
 * @name UpdateCustomerDeliveryAddress
 * @summary Updates a delivery address
 * @request PUT:/delivery-addresses/{deliveryAddressId}
 * @secure
 * @response `200` `ModelUpdateCustomerDeliveryAddressData` Delivery address successfully updated
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Delivery address cannot be modified by customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer or delivery address does not exist
 */
    updateCustomerDeliveryAddress: {
      request: ({
        deliveryAddressId,
        data,
        params = {},
      }: {
        deliveryAddressId: string
        data: ModelUpdateCustomerDeliveryAddressPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerDeliveryAddressData,
          ModelUpdateCustomerDeliveryAddressError
        >({
          path: `/delivery-addresses/${deliveryAddressId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/delivery-addresses/:deliveryAddressId` as const,
    },

    /**
 * @description Deletes the specified `deliveryAddressId` of the `customerId`
 *
 * @tags customers / delivery addresses
 * @name DeleteCustomerDeliveryAddress
 * @summary Deletes the delivery address
 * @request DELETE:/delivery-addresses/{deliveryAddressId}
 * @secure
 * @response `204` `ModelDeleteCustomerDeliveryAddressData` Successfully deleted delivery address of customer
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Delivery address cannot be deleted by customer user
 */
    deleteCustomerDeliveryAddress: {
      request: ({
        deliveryAddressId,
        params = {},
      }: {
        deliveryAddressId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteCustomerDeliveryAddressData,
          ModelDeleteCustomerDeliveryAddressError
        >({
          path: `/delivery-addresses/${deliveryAddressId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/delivery-addresses/:deliveryAddressId` as const,
    },

    /**
 * No description
 *
 * @tags customers / delivery addresses
 * @name GetCustomerDeliveryAddressAssignments
 * @summary Get delivery address assignments
 * @request GET:/delivery-addresses/{deliveryAddressId}/assignments
 * @secure
 * @response `200` `ModelGetCustomerDeliveryAddressAssignmentsData` Assigned customer users for a delivery address
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getCustomerDeliveryAddressAssignments: {
      request: ({
        deliveryAddressId,
        params = {},
      }: {
        deliveryAddressId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerDeliveryAddressAssignmentsData,
          ModelGetCustomerDeliveryAddressAssignmentsError
        >({
          path: `/delivery-addresses/${deliveryAddressId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/delivery-addresses/:deliveryAddressId/assignments` as const,
    },

    /**
 * @description Assign list of entities to an delivery address
 *
 * @tags customers / delivery addresses
 * @name PutCustomerDeliveryAddressAssignment
 * @summary Assign list of entites to delivery address
 * @request PUT:/delivery-addresses/{deliveryAddressId}/assignments
 * @secure
 * @response `200` `ModelPutCustomerDeliveryAddressAssignmentData` Assignments updated for the delivery address
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    putCustomerDeliveryAddressAssignment: {
      request: ({
        deliveryAddressId,
        data,
        params = {},
      }: {
        deliveryAddressId: string
        data: ModelPutCustomerDeliveryAddressAssignmentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutCustomerDeliveryAddressAssignmentData,
          ModelPutCustomerDeliveryAddressAssignmentError
        >({
          path: `/delivery-addresses/${deliveryAddressId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/delivery-addresses/:deliveryAddressId/assignments` as const,
    },

    /**
     * @description Retrieve list of customer users / groups permitted to create private delivery addresses - no pagination
     *
     * @tags customers / delivery addresses
     * @name GetCustomerDeliveryAddressPermissions
     * @summary Get permissions to create private delivery addresses
     * @request GET:/delivery-addresses/private-addresses-permission
     * @secure
     * @response `200` `ModelGetCustomerDeliveryAddressPermissionsData` Retrieve list of customer users / groups permitted to create private delivery addresses
     */
    getCustomerDeliveryAddressPermissions: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerDeliveryAddressPermissionsData, any>({
          path: `/delivery-addresses/private-addresses-permission`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/delivery-addresses/private-addresses-permission` as const,
    },

    /**
 * @description Sets list of customer users / groups permitted to create private delivery addresses. Assignment of OCI users not allowed.
 *
 * @tags customers / delivery addresses
 * @name SetCustomerDeliveryAddressPermissions
 * @summary Sets permissions to create private delivery addresses
 * @request PUT:/delivery-addresses/private-addresses-permission
 * @secure
 * @response `204` `ModelSetCustomerDeliveryAddressPermissionsData` Permissions updated for the article list
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    setCustomerDeliveryAddressPermissions: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelSetCustomerDeliveryAddressPermissionsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSetCustomerDeliveryAddressPermissionsData,
          ModelSetCustomerDeliveryAddressPermissionsError
        >({
          path: `/delivery-addresses/private-addresses-permission`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/delivery-addresses/private-addresses-permission` as const,
    },

    /**
 * No description
 *
 * @tags customers / vending machines
 * @name ListVendingMachines
 * @summary Returns a list of vending machines
 * @request GET:/vending-machines
 * @secure
 * @response `200` `ModelListVendingMachinesData` List of vending machines
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    listVendingMachines: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelListVendingMachinesParams
        params?: RequestParams
      }) =>
        this.request<
          ModelListVendingMachinesData,
          ModelListVendingMachinesError
        >({
          path: `/vending-machines`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/vending-machines` as const,
    },

    /**
 * No description
 *
 * @tags customers / vending machines
 * @name CreateVendingMachine
 * @summary Create vending machine
 * @request POST:/vending-machines
 * @secure
 * @response `201` `ModelCreateVendingMachineData` Vending machine successfully created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vending machine with provided name already exists
 */
    createVendingMachine: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCreateVendingMachinePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCreateVendingMachineData,
          ModelCreateVendingMachineError
        >({
          path: `/vending-machines`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/vending-machines` as const,
    },

    /**
 * No description
 *
 * @tags customers / vending machines
 * @name GetVendingMachine
 * @summary Get vending machine summary
 * @request GET:/vending-machines/{vendingMachineId}
 * @secure
 * @response `200` `ModelGetVendingMachineData` Vending machine details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vending machine does not exist
 */
    getVendingMachine: {
      request: ({
        vendingMachineId,
        params = {},
      }: {
        vendingMachineId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetVendingMachineData, ModelGetVendingMachineError>({
          path: `/vending-machines/${vendingMachineId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/vending-machines/:vendingMachineId` as const,
    },

    /**
 * No description
 *
 * @tags customers / vending machines
 * @name UpdateVendingMachine
 * @summary Updates vending machine
 * @request PUT:/vending-machines/{vendingMachineId}
 * @secure
 * @response `204` `ModelUpdateVendingMachineData` Vending machine updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vending machine does not exist
 */
    updateVendingMachine: {
      request: ({
        vendingMachineId,
        data,
        params = {},
      }: {
        vendingMachineId: string
        data: ModelUpdateVendingMachinePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateVendingMachineData,
          ModelUpdateVendingMachineError
        >({
          path: `/vending-machines/${vendingMachineId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/vending-machines/:vendingMachineId` as const,
    },

    /**
 * No description
 *
 * @tags customers / vending machines
 * @name DeleteVendingMachine
 * @summary Delete vending machine
 * @request DELETE:/vending-machines/{vendingMachineId}
 * @secure
 * @response `204` `ModelDeleteVendingMachineData` Vending machine deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vending machine does not exist
 */
    deleteVendingMachine: {
      request: ({
        vendingMachineId,
        params = {},
      }: {
        vendingMachineId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteVendingMachineData,
          ModelDeleteVendingMachineError
        >({
          path: `/vending-machines/${vendingMachineId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/vending-machines/:vendingMachineId` as const,
    },

    /**
 * No description
 *
 * @tags customers / vending machines
 * @name UpdateVendingMachineAccounting
 * @summary Updates vending machine accounting info
 * @request PUT:/vending-machines/{vendingMachineId}/accounting
 * @secure
 * @response `204` `ModelUpdateVendingMachineAccountingData` Vending machine accounting updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vending machine does not exist
 */
    updateVendingMachineAccounting: {
      request: ({
        vendingMachineId,
        data,
        params = {},
      }: {
        vendingMachineId: string
        data: ModelUpdateVendingMachineAccountingPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateVendingMachineAccountingData,
          ModelUpdateVendingMachineAccountingError
        >({
          path: `/vending-machines/${vendingMachineId}/accounting`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/vending-machines/:vendingMachineId/accounting` as const,
    },

    /**
 * No description
 *
 * @tags customers / vending machines
 * @name UpdateVendingMachineApprovalFlows
 * @summary Updates vending machine approval flows
 * @request PUT:/vending-machines/{vendingMachineId}/approval-flows
 * @secure
 * @response `204` `ModelUpdateVendingMachineApprovalFlowsData` Vending machine approval flows updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    updateVendingMachineApprovalFlows: {
      request: ({
        vendingMachineId,
        data,
        params = {},
      }: {
        vendingMachineId: string
        data: ModelUpdateVendingMachineApprovalFlowsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateVendingMachineApprovalFlowsData,
          ModelUpdateVendingMachineApprovalFlowsError
        >({
          path: `/vending-machines/${vendingMachineId}/approval-flows`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/vending-machines/:vendingMachineId/approval-flows` as const,
    },

    /**
 * @description Returns a list of Vendors for given Customer depending on view type
 *
 * @tags customers / vendors
 * @name GetCustomerVendors
 * @summary Returns a list of Vendors for given Customer
 * @request GET:/vendors
 * @secure
 * @response `200` `ModelGetCustomerVendorsData` List of Vendors (requested or public) for given Customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist
 */
    getCustomerVendors: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerVendorsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerVendorsData, ModelGetCustomerVendorsError>(
          {
            path: `/vendors`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
          },
        ),
      url: `/vendors` as const,
    },

    /**
 * No description
 *
 * @tags customers / vendors
 * @name PostCustomerVendors
 * @summary Requests a new vendor for given `{customerId}`
 * @request POST:/vendors
 * @secure
 * @response `204` `ModelPostCustomerVendorsData` Vendor requested successfully.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Either customer or vendor does not exist
 */
    postCustomerVendors: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerVendorsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerVendorsData,
          ModelPostCustomerVendorsError
        >({
          path: `/vendors`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/vendors` as const,
    },

    /**
 * No description
 *
 * @tags customers / vendors
 * @name PatchCustomerSpecificVendorNumber
 * @summary Update the customerSpecificVendorNumber for the given Vendor '{vendorId}'
 * @request PATCH:/vendors/{vendorId}
 * @secure
 * @response `204` `ModelPatchCustomerSpecificVendorNumberData` CustomerSpecificVendorNumber updated successfully.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist or vendor with given `vendorId` do not exist
 */
    patchCustomerSpecificVendorNumber: {
      request: ({
        vendorId,
        data,
        params = {},
      }: {
        vendorId: string
        data: ModelPatchCustomerSpecificVendorNumberPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchCustomerSpecificVendorNumberData,
          ModelPatchCustomerSpecificVendorNumberError
        >({
          path: `/vendors/${vendorId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/vendors/:vendorId` as const,
    },

    /**
 * No description
 *
 * @tags customers / vendors
 * @name PostCustomerVendorInvites
 * @summary Requests a new not listed vendor for given `{customerId}`
 * @request POST:/vendors/invite
 * @secure
 * @response `204` `ModelPostCustomerVendorInvitesData` Vendor requested successfully.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor already invited
 */
    postCustomerVendorInvites: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerVendorInvitesPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerVendorInvitesData,
          ModelPostCustomerVendorInvitesError
        >({
          path: `/vendors/invite`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/vendors/invite` as const,
    },

    /**
 * No description
 *
 * @tags customers / vendors
 * @name CountCustomerVendors
 * @summary Gets public (by category), connected, and requested vendors counts
 * @request GET:/vendors/counts
 * @secure
 * @response `200` `ModelCountCustomerVendorsData` Vendor counts for public, connected, and requested, as well as counts by category
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    countCustomerVendors: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelCountCustomerVendorsData,
          ModelCountCustomerVendorsError
        >({
          path: `/vendors/counts`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/vendors/counts` as const,
    },

    /**
     * No description
     *
     * @tags customers / vendors
     * @name GetAvailableCustomerVendor
     * @summary Get vendor list for the customer
     * @request GET:/vendors/available
     * @secure
     * @response `200` `ModelGetAvailableCustomerVendorData` Vendors available for the customer
     */
    getAvailableCustomerVendor: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetAvailableCustomerVendorData, any>({
          path: `/vendors/available`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/vendors/available` as const,
    },

    /**
 * No description
 *
 * @tags customers / vendors
 * @name GetCustomerUserVendors
 * @summary Get vendor list with catalog urls available for the customer user
 * @request GET:/my-vendors
 * @secure
 * @response `200` `ModelGetCustomerUserVendorsData` Vendor list available for the customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist
 */
    getCustomerUserVendors: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerUserVendorsData,
          ModelGetCustomerUserVendorsError
        >({
          path: `/my-vendors`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/my-vendors` as const,
    },

    /**
 * No description
 *
 * @tags customers / vendors
 * @name CreateCustomerUserVendorsPin
 * @summary Pin the available vendor for the customer user
 * @request POST:/my-vendors/{vendorId}/pin
 * @secure
 * @response `204` `ModelCreateCustomerUserVendorsPinData` Vendor pinned by the customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist
 */
    createCustomerUserVendorsPin: {
      request: ({
        vendorId,
        params = {},
      }: {
        vendorId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelCreateCustomerUserVendorsPinData,
          ModelCreateCustomerUserVendorsPinError
        >({
          path: `/my-vendors/${vendorId}/pin`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/my-vendors/:vendorId/pin` as const,
    },

    /**
 * No description
 *
 * @tags customers / vendors
 * @name DeleteCustomerUserVendorsPin
 * @summary Unpin the available vendor for the customer user
 * @request DELETE:/my-vendors/{vendorId}/pin
 * @secure
 * @response `204` `ModelDeleteCustomerUserVendorsPinData` Vendor unpinned by the customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist
 */
    deleteCustomerUserVendorsPin: {
      request: ({
        vendorId,
        params = {},
      }: {
        vendorId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteCustomerUserVendorsPinData,
          ModelDeleteCustomerUserVendorsPinError
        >({
          path: `/my-vendors/${vendorId}/pin`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/my-vendors/:vendorId/pin` as const,
    },

    /**
 * @description Returns a list of Vendors (public or with ACCEPTED request status) of a given Customer
 *
 * @tags customers / article search
 * @name GetCustomerMarketplaceVendors
 * @summary Returns a list of public and connected Vendors of a given Customer
 * @request GET:/marketplace/vendors
 * @secure
 * @response `200` `ModelGetCustomerMarketplaceVendorsData` List of public and connected Vendors of a given Customer sorted alphabetically ascending
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Access to Customer with given `customerId` is forbidden
 */
    getCustomerMarketplaceVendors: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerMarketplaceVendorsData,
          ModelGetCustomerMarketplaceVendorsError
        >({
          path: `/marketplace/vendors`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/vendors` as const,
    },

    /**
 * @description Gives the detailed information for an article
 *
 * @tags customers / article search
 * @name ArticleDetail
 * @summary Returns the details of an article
 * @request GET:/marketplace/articles/{articleId}
 * @secure
 * @response `200` `ModelArticleDetailData` Article details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided article id does not exist
 */
    articleDetail: {
      request: ({
        articleId,
        params = {},
      }: {
        articleId: string
        params?: RequestParams
      }) =>
        this.request<ModelArticleDetailData, ModelArticleDetailError>({
          path: `/marketplace/articles/${articleId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/articles/:articleId` as const,
    },

    /**
     * @description Returns a list of platform categories for products.
     *
     * @tags customers / platform categories
     * @name GetCustomerPlatformCategories
     * @summary List product platform categories
     * @request GET:/marketplace/platform-categories
     * @secure
     * @response `200` `ModelGetCustomerPlatformCategoriesData` List of product platform categories
     */
    getCustomerPlatformCategories: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerPlatformCategoriesData, any>({
          path: `/marketplace/platform-categories`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/platform-categories` as const,
    },

    /**
 * @description Request external prices for list of articles that were returned without internal system prices.
 *
 * @tags customers / article search
 * @name FetchExternalPrices
 * @summary Request external prices for list of articles
 * @request POST:/marketplace/external-prices
 * @secure
 * @response `200` `ModelFetchExternalPricesData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    fetchExternalPrices: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelFetchExternalPricesPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelFetchExternalPricesData,
          ModelFetchExternalPricesError
        >({
          path: `/marketplace/external-prices`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/external-prices` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci
 * @name GetCustomerVendorOciCatalogs
 * @summary Returns a list of OCI catalogs available for given Customer
 * @request GET:/vendoroci
 * @response `200` `ModelGetCustomerVendorOciCatalogsData` List of OCI catalogs for given Customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    getCustomerVendorOciCatalogs: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerVendorOciCatalogsParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerVendorOciCatalogsData,
          ModelGetCustomerVendorOciCatalogsError
        >({
          path: `/vendoroci`,
          method: 'GET',
          query: query,
          format: 'json',
          ...params,
        }),
      url: `/vendoroci` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci
 * @name GetOciUrlWithSession
 * @summary Get a url with session for the given ociCatalogId
 * @request GET:/vendoroci/{ociCatalogId}/url-with-session
 * @response `200` `ModelGetOciUrlWithSessionData` response containing url, credentials, ociSessionId and other settings available to the corresponding Vendor OCI Catalog
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Access to OCI Catalog with given `{ociCatalogId}` is forbidden
 */
    getOciUrlWithSession: {
      request: ({
        ociCatalogId,
        params = {},
      }: {
        ociCatalogId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetOciUrlWithSessionData,
          ModelGetOciUrlWithSessionError
        >({
          path: `/vendoroci/${ociCatalogId}/url-with-session`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/vendoroci/:ociCatalogId/url-with-session` as const,
    },

    /**
 * @description Retrieve list of all account types and values
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypesAndValues
 * @summary Retrieve list of customer account types and values
 * @request GET:/account-types-and-values
 * @secure
 * @response `200` `ModelGetCustomerAccountTypesAndValuesData` List of account types and values for customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAccountTypesAndValues: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerAccountTypesAndValuesData,
          ModelGetCustomerAccountTypesAndValuesError
        >({
          path: `/account-types-and-values`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types-and-values` as const,
    },

    /**
 * @description Retrieve list of account types and values assigned to the current customer user
 *
 * @tags customer / account types
 * @name GetCustomerAssignedAccountTypesAndValues
 * @summary Retrieve list of account types and values assigned to the current customer user
 * @request GET:/assigned-account-types-and-values
 * @secure
 * @response `200` `ModelGetCustomerAssignedAccountTypesAndValuesData` List of account types and values assigned to the current customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAssignedAccountTypesAndValues: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerAssignedAccountTypesAndValuesData,
          ModelGetCustomerAssignedAccountTypesAndValuesError
        >({
          path: `/assigned-account-types-and-values`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/assigned-account-types-and-values` as const,
    },

    /**
 * @description Retrieve customer account types and values list
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypesAndValuesList
 * @summary Retrieve customer account types and values list
 * @request GET:/account-types-and-values/list
 * @secure
 * @response `200` `ModelGetCustomerAccountTypesAndValuesListData` Account types and values automation for customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAccountTypesAndValuesList: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerAccountTypesAndValuesListData,
          ModelGetCustomerAccountTypesAndValuesListError
        >({
          path: `/account-types-and-values/list`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types-and-values/list` as const,
    },

    /**
 * No description
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypesAndValuesFileDownloadUrl
 * @summary Creates and returns presigned URL for customer account type and values file download
 * @request GET:/account-types-and-values/file
 * @secure
 * @response `200` `ModelGetCustomerAccountTypesAndValuesFileDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAccountTypesAndValuesFileDownloadUrl: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerAccountTypesAndValuesFileDownloadUrlData,
          ModelGetCustomerAccountTypesAndValuesFileDownloadUrlError
        >({
          path: `/account-types-and-values/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types-and-values/file` as const,
    },

    /**
 * No description
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypeAndValuesFileSettings
 * @summary Get accounting automation file upload settings
 * @request GET:/account-types-and-values/file/settings
 * @secure
 * @response `200` `ModelGetCustomerAccountTypeAndValuesFileSettingsData` Settings to be used for accounting automation file upload
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAccountTypeAndValuesFileSettings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerAccountTypeAndValuesFileSettingsData,
          ModelGetCustomerAccountTypeAndValuesFileSettingsError
        >({
          path: `/account-types-and-values/file/settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types-and-values/file/settings` as const,
    },

    /**
 * No description
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypesAndValuesReportDownloadUrl
 * @summary Creates and returns presigned URL for customer account type and values report download
 * @request GET:/account-types-and-values/report
 * @secure
 * @response `200` `ModelGetCustomerAccountTypesAndValuesReportDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAccountTypesAndValuesReportDownloadUrl: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerAccountTypesAndValuesReportDownloadUrlData,
          ModelGetCustomerAccountTypesAndValuesReportDownloadUrlError
        >({
          path: `/account-types-and-values/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types-and-values/report` as const,
    },

    /**
 * @description Retrieve list of all account types for a customer with pagination and sort on prefix
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypes
 * @summary Retrieve list of customer account types
 * @request GET:/account-types
 * @secure
 * @response `200` `ModelGetCustomerAccountTypesData` List of account types for current customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAccountTypes: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerAccountTypesParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerAccountTypesData,
          ModelGetCustomerAccountTypesError
        >({
          path: `/account-types`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types` as const,
    },

    /**
 * @description New account type will be registered for the given customer
 *
 * @tags customer / account types
 * @name PostCustomerAccountType
 * @summary Registers new account type for the customer
 * @request POST:/account-types
 * @secure
 * @response `201` `ModelPostCustomerAccountTypeData` Account Type successfully registered for the customer
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, prefix is not present.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` prefix already used by this customer
 */
    postCustomerAccountType: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerAccountTypePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerAccountTypeData,
          ModelPostCustomerAccountTypeError
        >({
          path: `/account-types`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/account-types` as const,
    },

    /**
 * @description Used to update, enable or disable an account type for the customer
 *
 * @tags customer / account types
 * @name UpdateCustomerAccountType
 * @summary Update the customer account type
 * @request PATCH:/account-types/{accountTypeId}
 * @secure
 * @response `200` `ModelUpdateCustomerAccountTypeData` CustomerAccountType successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - prefix cannot be empty
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` prefix already used by this customer
 */
    updateCustomerAccountType: {
      request: ({
        accountTypeId,
        data,
        params = {},
      }: {
        accountTypeId: string
        data: ModelUpdateCustomerAccountTypePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerAccountTypeData,
          ModelUpdateCustomerAccountTypeError
        >({
          path: `/account-types/${accountTypeId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/account-types/:accountTypeId` as const,
    },

    /**
     * @description Used to delete an account type for the customer
     *
     * @tags customer / account types
     * @name DeleteCustomerAccountType
     * @summary Delete the customer account type
     * @request DELETE:/account-types/{accountTypeId}
     * @secure
     * @response `204` `ModelDeleteCustomerAccountTypeData` Account type successfully removed for the customer
     */
    deleteCustomerAccountType: {
      request: ({
        accountTypeId,
        params = {},
      }: {
        accountTypeId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteCustomerAccountTypeData, any>({
          path: `/account-types/${accountTypeId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/account-types/:accountTypeId` as const,
    },

    /**
 * @description Retrieve list of all values for a customer and an account type
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypeValues
 * @summary Retrieve list of customer account type values
 * @request GET:/account-types/{accountTypeId}/values
 * @secure
 * @response `200` `ModelGetCustomerAccountTypeValuesData` List of values of an account type for current customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided account type id does not exist
 */
    getCustomerAccountTypeValues: {
      request: ({
        accountTypeId,
        params = {},
      }: {
        accountTypeId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerAccountTypeValuesData,
          ModelGetCustomerAccountTypeValuesError
        >({
          path: `/account-types/${accountTypeId}/values`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types/:accountTypeId/values` as const,
    },

    /**
 * @description New value for account type will be registered for the given customer
 *
 * @tags customer / account types
 * @name PostCustomerAccountTypeValue
 * @summary Registers new value for account type of the customer
 * @request POST:/account-types/{accountTypeId}/values
 * @secure
 * @response `201` `ModelPostCustomerAccountTypeValueData` Value for account Type successfully registered for the customer
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, number is not present.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` number already used by this customer for this account type
 */
    postCustomerAccountTypeValue: {
      request: ({
        accountTypeId,
        data,
        params = {},
      }: {
        accountTypeId: string
        data: ModelPostCustomerAccountTypeValuePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerAccountTypeValueData,
          ModelPostCustomerAccountTypeValueError
        >({
          path: `/account-types/${accountTypeId}/values`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/account-types/:accountTypeId/values` as const,
    },

    /**
 * @description Used to update, enable or disable a value of account type for the customer
 *
 * @tags customer / account types
 * @name UpdateCustomerAccountTypeValue
 * @summary Update the customer account type value
 * @request PATCH:/account-types/{accountTypeId}/values/{valueId}
 * @secure
 * @response `200` `ModelUpdateCustomerAccountTypeValueData` CustomerAccountTypeValue successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - number cannot be empty
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` number already used by this customer for this account type
 */
    updateCustomerAccountTypeValue: {
      request: ({
        accountTypeId,
        valueId,
        data,
        params = {},
      }: {
        accountTypeId: string
        valueId: string
        data: ModelUpdateCustomerAccountTypeValuePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerAccountTypeValueData,
          ModelUpdateCustomerAccountTypeValueError
        >({
          path: `/account-types/${accountTypeId}/values/${valueId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/account-types/:accountTypeId/values/:valueId` as const,
    },

    /**
     * @description Used to delete a value of an account type for the customer
     *
     * @tags customer / account types
     * @name DeleteCustomerAccountTypeValue
     * @summary Delete the customer account type value
     * @request DELETE:/account-types/{accountTypeId}/values/{valueId}
     * @secure
     * @response `204` `ModelDeleteCustomerAccountTypeValueData` Account type value successfully removed for the account type
     */
    deleteCustomerAccountTypeValue: {
      request: ({
        accountTypeId,
        valueId,
        params = {},
      }: {
        accountTypeId: string
        valueId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteCustomerAccountTypeValueData, any>({
          path: `/account-types/${accountTypeId}/values/${valueId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/account-types/:accountTypeId/values/:valueId` as const,
    },

    /**
 * @description Retrieve customer account type settings
 *
 * @tags customer / account types
 * @name GetCustomerAccountTypeSettings
 * @summary Retrieve customer account type settings
 * @request GET:/account-types/settings
 * @secure
 * @response `200` `ModelGetCustomerAccountTypeSettingsData` Account type setting for the customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerAccountTypeSettings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerAccountTypeSettingsData,
          ModelGetCustomerAccountTypeSettingsError
        >({
          path: `/account-types/settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types/settings` as const,
    },

    /**
 * @description Update account type settings for the customer
 *
 * @tags customer / account types
 * @name UpdateCustomerAccountTypeSettings
 * @summary Update account type settings for the customer
 * @request PUT:/account-types/settings
 * @secure
 * @response `200` `ModelUpdateCustomerAccountTypeSettingsData` Account type settings updated for the customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    updateCustomerAccountTypeSettings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCustomerAccountTypeSettingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerAccountTypeSettingsData,
          ModelUpdateCustomerAccountTypeSettingsError
        >({
          path: `/account-types/settings`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/account-types/settings` as const,
    },

    /**
 * @description Retrieve list of all customer account types with account values counts - no pagination
 *
 * @tags customer / account types
 * @name GetAccountTypesList
 * @summary Retrieve list of all customer account types with account values counts
 * @request GET:/account-types/list
 * @secure
 * @response `200` `ModelGetAccountTypesListData` List of all customer account types
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getAccountTypesList: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetAccountTypesListData,
          ModelGetAccountTypesListError
        >({
          path: `/account-types/list`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types/list` as const,
    },

    /**
 * @description Retrieve list of all customer account type values - no pagination
 *
 * @tags customer / account types
 * @name GetAccountTypeValuesList
 * @summary Retrieve list of all customer account type values
 * @request GET:/account-types/{accountTypeId}/values/list
 * @secure
 * @response `200` `ModelGetAccountTypeValuesListData` List of all customer account type values
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getAccountTypeValuesList: {
      request: ({
        accountTypeId,
        params = {},
      }: {
        accountTypeId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetAccountTypeValuesListData,
          ModelGetAccountTypeValuesListError
        >({
          path: `/account-types/${accountTypeId}/values/list`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types/:accountTypeId/values/list` as const,
    },

    /**
 * @description Retrieve list of customer users assigned to an account type - no pagination
 *
 * @tags customer / account types
 * @name GetAccountTypeAssignedCustomerUsers
 * @summary Retrieve list of customer users assigned to an account type
 * @request GET:/account-types/{accountTypeId}/assignments
 * @secure
 * @response `200` `ModelGetAccountTypeAssignedCustomerUsersData` List of customer users with assignment status for the account type
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getAccountTypeAssignedCustomerUsers: {
      request: ({
        accountTypeId,
        params = {},
      }: {
        accountTypeId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetAccountTypeAssignedCustomerUsersData,
          ModelGetAccountTypeAssignedCustomerUsersError
        >({
          path: `/account-types/${accountTypeId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types/:accountTypeId/assignments` as const,
    },

    /**
 * @description Assign list of customer users to an account type
 *
 * @tags customer / account types
 * @name AssignCustomersToAccountType
 * @summary Assign list of customer users to an account type
 * @request PUT:/account-types/{accountTypeId}/assignments
 * @secure
 * @response `200` `ModelAssignCustomersToAccountTypeData` Assignments updated for the account type
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    assignCustomersToAccountType: {
      request: ({
        accountTypeId,
        data,
        params = {},
      }: {
        accountTypeId: string
        data: ModelAssignCustomersToAccountTypePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAssignCustomersToAccountTypeData,
          ModelAssignCustomersToAccountTypeError
        >({
          path: `/account-types/${accountTypeId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/account-types/:accountTypeId/assignments` as const,
    },

    /**
 * @description Retrieve list of customer users assigned to an account type value - no pagination
 *
 * @tags customer / account types
 * @name GetAccountTypeValueAssignedCustomerUsers
 * @summary Retrieve list of customer users assigned to an account type value
 * @request GET:/account-types/{accountTypeId}/values/{valueId}/assignments
 * @secure
 * @response `200` `ModelGetAccountTypeValueAssignedCustomerUsersData` List of customer users with assignment status for the account type value
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getAccountTypeValueAssignedCustomerUsers: {
      request: ({
        accountTypeId,
        valueId,
        params = {},
      }: {
        accountTypeId: string
        valueId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetAccountTypeValueAssignedCustomerUsersData,
          ModelGetAccountTypeValueAssignedCustomerUsersError
        >({
          path: `/account-types/${accountTypeId}/values/${valueId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/account-types/:accountTypeId/values/:valueId/assignments` as const,
    },

    /**
 * @description Assign list of customer users to an account type value
 *
 * @tags customer / account types
 * @name AssignCustomersToAccountTypeValue
 * @summary Assign list of customer users to an account type value
 * @request PUT:/account-types/{accountTypeId}/values/{valueId}/assignments
 * @secure
 * @response `200` `ModelAssignCustomersToAccountTypeValueData` Assignments updated for the account type value
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    assignCustomersToAccountTypeValue: {
      request: ({
        accountTypeId,
        valueId,
        data,
        params = {},
      }: {
        accountTypeId: string
        valueId: string
        data: ModelAssignCustomersToAccountTypeValuePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAssignCustomersToAccountTypeValueData,
          ModelAssignCustomersToAccountTypeValueError
        >({
          path: `/account-types/${accountTypeId}/values/${valueId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/account-types/:accountTypeId/values/:valueId/assignments` as const,
    },

    /**
 * @description Retrieve list of all general ledgers for a customer with pagination and sort by number
 *
 * @tags customer / general ledgers
 * @name GetCustomerGeneralLedgers
 * @summary Retrieve list of customer general ledgers
 * @request GET:/general-ledgers
 * @secure
 * @response `200` `ModelGetCustomerGeneralLedgersData` List of general ledgers for current customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerGeneralLedgers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerGeneralLedgersParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerGeneralLedgersData,
          ModelGetCustomerGeneralLedgersError
        >({
          path: `/general-ledgers`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/general-ledgers` as const,
    },

    /**
 * @description New general ledger will be registered for the given customer
 *
 * @tags customer / general ledgers
 * @name AddCustomerGeneralLedger
 * @summary Registers new general ledger for the customer
 * @request POST:/general-ledgers
 * @secure
 * @response `201` `ModelAddCustomerGeneralLedgerData` General ledger successfully registered for the customer
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, number is not present.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` number already used by this customer
 */
    addCustomerGeneralLedger: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelAddCustomerGeneralLedgerPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAddCustomerGeneralLedgerData,
          ModelAddCustomerGeneralLedgerError
        >({
          path: `/general-ledgers`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/general-ledgers` as const,
    },

    /**
 * @description Used to update, enable or disable a general ledger for the customer
 *
 * @tags customer / general ledgers
 * @name UpdateCustomerGeneralLedger
 * @summary Update the customer general ledger
 * @request PATCH:/general-ledgers/{generalLedgerId}
 * @secure
 * @response `200` `ModelUpdateCustomerGeneralLedgerData` Customer general ledger successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - number cannot be empty
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` number already used by this customer
 */
    updateCustomerGeneralLedger: {
      request: ({
        generalLedgerId,
        data,
        params = {},
      }: {
        generalLedgerId: string
        data: ModelUpdateCustomerGeneralLedgerPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerGeneralLedgerData,
          ModelUpdateCustomerGeneralLedgerError
        >({
          path: `/general-ledgers/${generalLedgerId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/general-ledgers/:generalLedgerId` as const,
    },

    /**
     * @description Used to delete an general ledger for the customer
     *
     * @tags customer / general ledgers
     * @name DeleteCustomerGeneralLedger
     * @summary Delete the customer general ledger
     * @request DELETE:/general-ledgers/{generalLedgerId}
     * @secure
     * @response `204` `ModelDeleteCustomerGeneralLedgerData` General ledger successfully removed for the customer
     */
    deleteCustomerGeneralLedger: {
      request: ({
        generalLedgerId,
        params = {},
      }: {
        generalLedgerId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteCustomerGeneralLedgerData, any>({
          path: `/general-ledgers/${generalLedgerId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/general-ledgers/:generalLedgerId` as const,
    },

    /**
 * @description Retrieve customer general ledger settings
 *
 * @tags customer / general ledgers
 * @name GetCustomerGeneralLedgerSettings
 * @summary Retrieve customer general ledger settings
 * @request GET:/general-ledgers/settings
 * @secure
 * @response `200` `ModelGetCustomerGeneralLedgerSettingsData` General ledger setting for the customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerGeneralLedgerSettings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerGeneralLedgerSettingsData,
          ModelGetCustomerGeneralLedgerSettingsError
        >({
          path: `/general-ledgers/settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/general-ledgers/settings` as const,
    },

    /**
 * @description Update general ledger settings for the customer
 *
 * @tags customer / general ledgers
 * @name UpdateCustomerGeneralLedgerSettings
 * @summary Update general ledger settings for the customer
 * @request PUT:/general-ledgers/settings
 * @secure
 * @response `200` `ModelUpdateCustomerGeneralLedgerSettingsData` General ledger settings updated for the customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    updateCustomerGeneralLedgerSettings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCustomerGeneralLedgerSettingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerGeneralLedgerSettingsData,
          ModelUpdateCustomerGeneralLedgerSettingsError
        >({
          path: `/general-ledgers/settings`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/general-ledgers/settings` as const,
    },

    /**
 * @description Retrieve list of eClass nodes with defined mappings. List is sorted ascending, so it is guaranteed, that the parent node is always earlier on the list that its  children.
 *
 * @tags customer / general ledgers
 * @name GetGeneralLedgerEclassMappings
 * @summary Retrieve list of eClass nodes with defined mappings
 * @request GET:/general-ledgers/mappings/{eclassVersion}
 * @secure
 * @response `200` `ModelGetGeneralLedgerEclassMappingsData` List of eclass nodes with defined mappings
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getGeneralLedgerEclassMappings: {
      request: ({
        eclassVersion,
        params = {},
      }: {
        eclassVersion: ModelGetGeneralLedgerEclassMappingsParamsEnum
        params?: RequestParams
      }) =>
        this.request<
          ModelGetGeneralLedgerEclassMappingsData,
          ModelGetGeneralLedgerEclassMappingsError
        >({
          path: `/general-ledgers/mappings/${eclassVersion}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/general-ledgers/mappings/:eclassVersion` as const,
    },

    /**
 * No description
 *
 * @tags customer / general ledgers
 * @name PutGeneralLedgerEclassMapping
 * @summary Update eClass mapping to general ledger on specific eClass node
 * @request PUT:/general-ledgers/mappings/{eclassVersion}/{eclassCode}
 * @secure
 * @response `204` `ModelPutGeneralLedgerEclassMappingData` Mapping updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    putGeneralLedgerEclassMapping: {
      request: ({
        eclassVersion,
        eclassCode,
        data,
        params = {},
      }: {
        eclassVersion: ModelPutGeneralLedgerEclassMappingParamsEnum
        eclassCode: string
        data: ModelPutGeneralLedgerEclassMappingPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutGeneralLedgerEclassMappingData,
          ModelPutGeneralLedgerEclassMappingError
        >({
          path: `/general-ledgers/mappings/${eclassVersion}/${eclassCode}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/general-ledgers/mappings/:eclassVersion/:eclassCode` as const,
    },

    /**
 * @description Retrieve list of general ledgers available for customer user without pagination
 *
 * @tags customer / general ledgers
 * @name GetAssignedCustomerGeneralLedger
 * @summary Retrieve list of assigned to customer user general ledgers
 * @request GET:/assigned-general-ledgers
 * @secure
 * @response `200` `ModelGetAssignedCustomerGeneralLedgerData` List of general ledgers for current customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getAssignedCustomerGeneralLedger: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetAssignedCustomerGeneralLedgerData,
          ModelGetAssignedCustomerGeneralLedgerError
        >({
          path: `/assigned-general-ledgers`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/assigned-general-ledgers` as const,
    },

    /**
 * @description Retrieve list of customer users assigned to a general ledger - no pagination
 *
 * @tags customer / general ledgers
 * @name GetAssignedCustomerUsersToGeneralLedger
 * @summary Retrieve list of customer users assigned to a general ledger
 * @request GET:/general-ledgers/{generalLedgerId}/assignments
 * @secure
 * @response `200` `ModelGetAssignedCustomerUsersToGeneralLedgerData` List of customer users with assignment status for the general ledger
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getAssignedCustomerUsersToGeneralLedger: {
      request: ({
        generalLedgerId,
        params = {},
      }: {
        generalLedgerId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetAssignedCustomerUsersToGeneralLedgerData,
          ModelGetAssignedCustomerUsersToGeneralLedgerError
        >({
          path: `/general-ledgers/${generalLedgerId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/general-ledgers/:generalLedgerId/assignments` as const,
    },

    /**
 * @description Assign list of customer users to a general ledger
 *
 * @tags customer / general ledgers
 * @name AssignCustomersToGeneralLedger
 * @summary Assign list of customer users to a general ledger
 * @request PUT:/general-ledgers/{generalLedgerId}/assignments
 * @secure
 * @response `200` `ModelAssignCustomersToGeneralLedgerData` Assignments updated for the general ledger
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    assignCustomersToGeneralLedger: {
      request: ({
        generalLedgerId,
        data,
        params = {},
      }: {
        generalLedgerId: string
        data: ModelAssignCustomersToGeneralLedgerPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAssignCustomersToGeneralLedgerData,
          ModelAssignCustomersToGeneralLedgerError
        >({
          path: `/general-ledgers/${generalLedgerId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/general-ledgers/:generalLedgerId/assignments` as const,
    },

    /**
     * @description Search for customer privileges based on specified criteria. Only available for ADMINS.
     *
     * @tags customer users / privileges
     * @name SearchCustomerUserPrivileges
     * @summary Search for customer user privileges
     * @request POST:/privileges/search
     * @secure
     * @response `200` `ModelSearchCustomerUserPrivilegesData` List of customer users with granted privileges
     */
    searchCustomerUserPrivileges: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerUserPrivilegesParams
        data: ModelSearchCustomerUserPrivilegesPayload
        params?: RequestParams
      }) =>
        this.request<ModelSearchCustomerUserPrivilegesData, any>({
          path: `/privileges/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/privileges/search` as const,
    },

    /**
     * @description Retrieve a list of user groups that shoppers are assigned to.
     *
     * @tags customer users / privileges
     * @name GetUserGroupsForCustomer
     * @summary Get user groups that shoppers are assigned to
     * @request GET:/privileges/user-groups
     * @secure
     * @response `200` `ModelGetUserGroupsForCustomerData` List of user groups that shoppers are assigned to.
     */
    getUserGroupsForCustomer: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetUserGroupsForCustomerData, any>({
          path: `/privileges/user-groups`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/privileges/user-groups` as const,
    },

    /**
 * @description Update privileges for a customer user. Only available for ADMINS.
 *
 * @tags customer users / privileges
 * @name UpdateCustomerUserPrivileges
 * @summary Update customer user privileges
 * @request PUT:/privileges
 * @secure
 * @response `204` `ModelUpdateCustomerUserPrivilegesData` Privileges updated successfully
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    updateCustomerUserPrivileges: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCustomerUserPrivilegesPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerUserPrivilegesData,
          ModelUpdateCustomerUserPrivilegesError
        >({
          path: `/privileges`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/privileges` as const,
    },

    /**
 * No description
 *
 * @tags customers / datafiles
 * @name GetCustomerDataFileUploadUrl
 * @summary Creates and returns presigned URL for data file upload
 * @request GET:/data-files/upload/url
 * @secure
 * @response `200` `ModelGetCustomerDataFileUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    getCustomerDataFileUploadUrl: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerDataFileUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerDataFileUploadUrlData,
          ModelGetCustomerDataFileUploadUrlError
        >({
          path: `/data-files/upload/url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/data-files/upload/url` as const,
    },

    /**
     * @description Returns customer's current core article lists.
     *
     * @tags customers / core article lists
     * @name GetCoreArticleLists
     * @summary Retrieve customer's current core article lists
     * @request GET:/core-article-lists
     * @secure
     * @response `200` `ModelGetCoreArticleListsData` Core article lists for current customer
     */
    getCoreArticleLists: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCoreArticleListsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetCoreArticleListsData, any>({
          path: `/core-article-lists`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/core-article-lists` as const,
    },

    /**
 * @description Operation performed after successful core article list file upload to S3 bucket in order to register it for further processing.
 *
 * @tags customers / core article lists
 * @name PostCoreArticleList
 * @summary Registers new uploaded core article list file
 * @request POST:/core-article-lists
 * @secure
 * @response `201` `ModelPostCoreArticleListData` Core article list successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Core article list name already used by this customer
 */
    postCoreArticleList: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCoreArticleListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCoreArticleListData,
          ModelPostCoreArticleListError
        >({
          path: `/core-article-lists`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/core-article-lists` as const,
    },

    /**
 * @description Operation performed after successful core article list file upload to S3 bucket in order to register it for further processing under existing core article list.
 *
 * @tags customers / core article lists
 * @name UpdateCoreArticleListFile
 * @summary Registers new uploaded file for existing core article list
 * @request POST:/core-article-lists/{coreArticleListId}
 * @secure
 * @response `204` `ModelUpdateCoreArticleListFileData` Core article list successfully registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Core article list with given `customerId` and `CoreArticleListId` does not exist
 */
    updateCoreArticleListFile: {
      request: ({
        coreArticleListId,
        data,
        params = {},
      }: {
        coreArticleListId: string
        data: ModelUpdateCoreArticleListFilePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCoreArticleListFileData,
          ModelUpdateCoreArticleListFileError
        >({
          path: `/core-article-lists/${coreArticleListId}`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/core-article-lists/:coreArticleListId` as const,
    },

    /**
 * No description
 *
 * @tags customers / core article lists
 * @name UpdateCoreArticleList
 * @summary Update core article list name or file name
 * @request PUT:/core-article-lists/{coreArticleListId}
 * @secure
 * @response `204` `ModelUpdateCoreArticleListData` Core article list successfully renamed
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Core article list with given `customerId` and `CoreArticleListId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Core article list name already used by this customer
 */
    updateCoreArticleList: {
      request: ({
        coreArticleListId,
        data,
        params = {},
      }: {
        coreArticleListId: string
        data: ModelUpdateCoreArticleListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCoreArticleListData,
          ModelUpdateCoreArticleListError
        >({
          path: `/core-article-lists/${coreArticleListId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/core-article-lists/:coreArticleListId` as const,
    },

    /**
 * No description
 *
 * @tags customers / core article lists
 * @name DeleteCoreArticleList
 * @summary Deletes core article list
 * @request DELETE:/core-article-lists/{coreArticleListId}
 * @secure
 * @response `204` `ModelDeleteCoreArticleListData` Core article list successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Core article list with given `customerId` and `CoreArticleListId` does not exist
 * @response `422` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Assigned core article list cannot be deleted
 */
    deleteCoreArticleList: {
      request: ({
        coreArticleListId,
        params = {},
      }: {
        coreArticleListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteCoreArticleListData,
          ModelDeleteCoreArticleListError
        >({
          path: `/core-article-lists/${coreArticleListId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/core-article-lists/:coreArticleListId` as const,
    },

    /**
     * @description Retrieve CSV report of core article list file import
     *
     * @tags customers / core article lists
     * @name GetCoreArticleListImportReport
     * @summary Retrieve CSV report of core article list file import
     * @request GET:/core-article-lists/{coreArticleListId}/report
     * @secure
     * @response `200` `ModelGetCoreArticleListImportReportData` contains the download url of CSV import report file
     */
    getCoreArticleListImportReport: {
      request: ({
        coreArticleListId,
        params = {},
      }: {
        coreArticleListId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCoreArticleListImportReportData, any>({
          path: `/core-article-lists/${coreArticleListId}/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/core-article-lists/:coreArticleListId/report` as const,
    },

    /**
 * No description
 *
 * @tags customers / core article lists
 * @name GetCoreArticleListDownloadUrl
 * @summary Creates and returns presigned URL for core article list file download
 * @request GET:/core-article-lists/{coreArticleListId}/file
 * @secure
 * @response `200` `ModelGetCoreArticleListDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Core article list with given `customerId` and `CoreArticleListId` does not exist
 */
    getCoreArticleListDownloadUrl: {
      request: ({
        coreArticleListId,
        params = {},
      }: {
        coreArticleListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCoreArticleListDownloadUrlData,
          ModelGetCoreArticleListDownloadUrlError
        >({
          path: `/core-article-lists/${coreArticleListId}/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/core-article-lists/:coreArticleListId/file` as const,
    },

    /**
 * @description Search for vendors that customer has relationship with
 *
 * @tags customers / core article lists
 * @name SearchCustomerCoreArticleListMyVendors
 * @summary Search for my vendors list
 * @request POST:/core-article-lists/my-vendors/search
 * @secure
 * @response `200` `ModelSearchCustomerCoreArticleListMyVendorsData` List of my vendors
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    searchCustomerCoreArticleListMyVendors: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerCoreArticleListMyVendorsParams
        data: ModelSearchCustomerCoreArticleListMyVendorsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerCoreArticleListMyVendorsData,
          ModelSearchCustomerCoreArticleListMyVendorsError
        >({
          path: `/core-article-lists/my-vendors/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/core-article-lists/my-vendors/search` as const,
    },

    /**
     * @description Returns customer's current customer article numbers list. Without pagination nor sorting.
     *
     * @tags customers / customer article numbers list
     * @name GetCustomerArticleNumbersList
     * @summary Retrieve customer's current customer article numbers list
     * @request GET:/customer-article-numbers-list
     * @secure
     * @response `200` `ModelGetCustomerArticleNumbersListData` Customer article numbers list for current customer
     */
    getCustomerArticleNumbersList: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerArticleNumbersListData, any>({
          path: `/customer-article-numbers-list`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-list` as const,
    },

    /**
 * @description Operation performed after successful customer article numbers list file upload to S3 bucket in order to register it for further processing.
 *
 * @tags customers / customer article numbers list
 * @name PostCustomerArticleNumbersList
 * @summary Registers new uploaded customer article numbers list file
 * @request POST:/customer-article-numbers-list
 * @secure
 * @response `201` `ModelPostCustomerArticleNumbersListData` Customer article numbers list successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 */
    postCustomerArticleNumbersList: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerArticleNumbersListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerArticleNumbersListData,
          ModelPostCustomerArticleNumbersListError
        >({
          path: `/customer-article-numbers-list`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customer-article-numbers-list` as const,
    },

    /**
 * No description
 *
 * @tags customers / customer article numbers list
 * @name UpdateCustomerArticleNumbersList
 * @summary Update customer article numbers list name or file name
 * @request PUT:/customer-article-numbers-list
 * @secure
 * @response `204` `ModelUpdateCustomerArticleNumbersListData` Customer article numbers list successfully renamed
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers list with given `customerId` does not exist
 */
    updateCustomerArticleNumbersList: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCustomerArticleNumbersListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerArticleNumbersListData,
          ModelUpdateCustomerArticleNumbersListError
        >({
          path: `/customer-article-numbers-list`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/customer-article-numbers-list` as const,
    },

    /**
 * No description
 *
 * @tags customers / customer article numbers list
 * @name DeleteCustomerArticleNumbersList
 * @summary Deletes customer article numbers list
 * @request DELETE:/customer-article-numbers-list
 * @secure
 * @response `204` `ModelDeleteCustomerArticleNumbersListData` Customer article numbers list successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers list with given `customerId` does not exist
 * @response `422` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Assigned customer article numbers list cannot be deleted
 */
    deleteCustomerArticleNumbersList: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelDeleteCustomerArticleNumbersListData,
          ModelDeleteCustomerArticleNumbersListError
        >({
          path: `/customer-article-numbers-list`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/customer-article-numbers-list` as const,
    },

    /**
     * @description Retrieve CSV report of customer article numbers list file import
     *
     * @tags customers / customer article numbers list
     * @name GetCustomerArticleNumbersListImportReport
     * @summary Retrieve CSV report of customer article numbers list file import
     * @request GET:/customer-article-numbers-list/report
     * @secure
     * @response `200` `ModelGetCustomerArticleNumbersListImportReportData` contains the download url of CSV import report file
     */
    getCustomerArticleNumbersListImportReport: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerArticleNumbersListImportReportData, any>({
          path: `/customer-article-numbers-list/report`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-list/report` as const,
    },

    /**
 * No description
 *
 * @tags customers / customer article numbers list
 * @name GetCustomerArticleNumbersListDownloadUrl
 * @summary Creates and returns presigned URL for customer article numbers list file download
 * @request GET:/customer-article-numbers-list/file
 * @secure
 * @response `200` `ModelGetCustomerArticleNumbersListDownloadUrlData` Created presigned download URL
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer article numbers list with given `customerId` does not exist
 */
    getCustomerArticleNumbersListDownloadUrl: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerArticleNumbersListDownloadUrlData,
          ModelGetCustomerArticleNumbersListDownloadUrlError
        >({
          path: `/customer-article-numbers-list/file`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/customer-article-numbers-list/file` as const,
    },

    /**
     * @description Retrieve list of reports defined for customer. Unpaginated, sorted by `nameTranslationKey`
     *
     * @tags customers / reports
     * @name GetCustomerReports
     * @summary Retrieve list of reports defined for customer
     * @request GET:/reports
     * @secure
     * @response `200` `ModelGetCustomerReportsData` List of reports
     */
    getCustomerReports: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerReportsData, any>({
          path: `/reports`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/reports` as const,
    },

    /**
     * No description
     *
     * @tags customers / reports
     * @name GetCustomerReportViewData
     * @summary Gets report data necessary to embed report in FE application
     * @request GET:/reports/{customerReportId}
     * @secure
     * @response `200` `ModelGetCustomerReportViewDataData` Report view data
     */
    getCustomerReportViewData: {
      request: ({
        customerReportId,
        params = {},
      }: {
        customerReportId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerReportViewDataData, any>({
          path: `/reports/${customerReportId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/reports/:customerReportId` as const,
    },

    /**
     * No description
     *
     * @tags customers / approval flows
     * @name GetEligibleApprovers
     * @summary Get all customer's users that can approve approval flows
     * @request GET:/approval-flows/eligible-approvers
     * @secure
     * @response `200` `ModelGetEligibleApproversData` List of all customer's users eligible to approve approval flows
     */
    getEligibleApprovers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetEligibleApproversParams
        params?: RequestParams
      }) =>
        this.request<ModelGetEligibleApproversData, any>({
          path: `/approval-flows/eligible-approvers`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/approval-flows/eligible-approvers` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval flows
 * @name AddNewApprovalFlow
 * @summary Add new approval flow
 * @request POST:/approval-flows
 * @secure
 * @response `201` `ModelAddNewApprovalFlowData` New Approval Flow Created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval Flow with provided name already exists
 */
    addNewApprovalFlow: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelAddNewApprovalFlowPayload
        params?: RequestParams
      }) =>
        this.request<ModelAddNewApprovalFlowData, ModelAddNewApprovalFlowError>(
          {
            path: `/approval-flows`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
          },
        ),
      url: `/approval-flows` as const,
    },

    /**
     * No description
     *
     * @tags customers / approval flows
     * @name GetApprovalFlow
     * @summary Get approval flow
     * @request GET:/approval-flows/{approvalFlowId}
     * @secure
     * @response `200` `ModelGetApprovalFlowData` Approval Flow definition
     */
    getApprovalFlow: {
      request: ({
        approvalFlowId,
        params = {},
      }: {
        approvalFlowId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetApprovalFlowData, any>({
          path: `/approval-flows/${approvalFlowId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/approval-flows/:approvalFlowId` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval flows
 * @name UpdateApprovalFlow
 * @summary Update approval flow
 * @request PATCH:/approval-flows/{approvalFlowId}
 * @secure
 * @response `204` `ModelUpdateApprovalFlowData` Approval Flow updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval flow does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval Flow with provided name already exists
 */
    updateApprovalFlow: {
      request: ({
        approvalFlowId,
        data,
        params = {},
      }: {
        approvalFlowId: string
        data: ModelUpdateApprovalFlowPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateApprovalFlowData, ModelUpdateApprovalFlowError>(
          {
            path: `/approval-flows/${approvalFlowId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
          },
        ),
      url: `/approval-flows/:approvalFlowId` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval flows
 * @name DeleteApprovalFlow
 * @summary Delete approval flow
 * @request DELETE:/approval-flows/{approvalFlowId}
 * @secure
 * @response `204` `ModelDeleteApprovalFlowData` Approval Flow deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval flow does not exist
 */
    deleteApprovalFlow: {
      request: ({
        approvalFlowId,
        params = {},
      }: {
        approvalFlowId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteApprovalFlowData, ModelDeleteApprovalFlowError>(
          {
            path: `/approval-flows/${approvalFlowId}`,
            method: 'DELETE',
            secure: true,
            ...params,
          },
        ),
      url: `/approval-flows/:approvalFlowId` as const,
    },

    /**
 * @description Search approval flows of a customer with pagination and sort by last created
 *
 * @tags customers / approval flows
 * @name PostApprovalFlowSearch
 * @summary Search for approval flows
 * @request POST:/approval-flows/search
 * @secure
 * @response `200` `ModelPostApprovalFlowSearchData` List of approval flows for current customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    postApprovalFlowSearch: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelPostApprovalFlowSearchParams
        data: ModelPostApprovalFlowSearchPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostApprovalFlowSearchData,
          ModelPostApprovalFlowSearchError
        >({
          path: `/approval-flows/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/approval-flows/search` as const,
    },

    /**
 * @description Retrieve customer approval flow settings
 *
 * @tags customer / approval flows
 * @name GetCustomerApprovalFlowSettings
 * @summary Retrieve customer approval flow settings
 * @request GET:/approval-flows/settings
 * @secure
 * @response `200` `ModelGetCustomerApprovalFlowSettingsData` Approval flow setting for the customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    getCustomerApprovalFlowSettings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerApprovalFlowSettingsData,
          ModelGetCustomerApprovalFlowSettingsError
        >({
          path: `/approval-flows/settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/approval-flows/settings` as const,
    },

    /**
 * @description Update approval flow settings for the customer
 *
 * @tags customer / approval flows
 * @name UpdateCustomerApprovalFlowSettings
 * @summary Update approval flow settings for the customer
 * @request PUT:/approval-flows/settings
 * @secure
 * @response `200` `ModelUpdateCustomerApprovalFlowSettingsData` Approval flow settings updated for the customer
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    updateCustomerApprovalFlowSettings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCustomerApprovalFlowSettingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerApprovalFlowSettingsData,
          ModelUpdateCustomerApprovalFlowSettingsError
        >({
          path: `/approval-flows/settings`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/approval-flows/settings` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval flows
 * @name GetApprovalFlowAssignedUsers
 * @summary Retrieve list of customer users and groups assigned to an approval flow
 * @request GET:/approval-flows/{approvalFlowId}/assignments
 * @secure
 * @response `200` `ModelGetApprovalFlowAssignedUsersData` List of customer users and groups assigned to an approval flow
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getApprovalFlowAssignedUsers: {
      request: ({
        approvalFlowId,
        params = {},
      }: {
        approvalFlowId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetApprovalFlowAssignedUsersData,
          ModelGetApprovalFlowAssignedUsersError
        >({
          path: `/approval-flows/${approvalFlowId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/approval-flows/:approvalFlowId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval flows
 * @name PutApprovalFlowAssignedUsers
 * @summary Assign customer users or user groups to an approval flow
 * @request PUT:/approval-flows/{approvalFlowId}/assignments
 * @secure
 * @response `200` `ModelPutApprovalFlowAssignedUsersData` Assignments are updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    putApprovalFlowAssignedUsers: {
      request: ({
        approvalFlowId,
        data,
        params = {},
      }: {
        approvalFlowId: string
        data: ModelPutApprovalFlowAssignedUsersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutApprovalFlowAssignedUsersData,
          ModelPutApprovalFlowAssignedUsersError
        >({
          path: `/approval-flows/${approvalFlowId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/approval-flows/:approvalFlowId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval processes
 * @name ApproveStep
 * @summary Approve a step in approval process
 * @request POST:/approval-processes/{approvalProcessId}/{step}/approve
 * @secure
 * @response `200` `ModelApproveStepData` Step approved
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Step in approval process is already approved or rejected
 */
    approveStep: {
      request: ({
        approvalProcessId,
        step,
        params = {},
      }: {
        approvalProcessId: string
        step: number
        params?: RequestParams
      }) =>
        this.request<ModelApproveStepData, ModelApproveStepError>({
          path: `/approval-processes/${approvalProcessId}/${step}/approve`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/approval-processes/:approvalProcessId/:step/approve` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval processes
 * @name RejectStep
 * @summary Reject a step in approval process
 * @request POST:/approval-processes/{approvalProcessId}/{step}/reject
 * @secure
 * @response `200` `ModelRejectStepData` Step rejected
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Step in approval process is already approved or rejected
 */
    rejectStep: {
      request: ({
        approvalProcessId,
        step,
        data,
        params = {},
      }: {
        approvalProcessId: string
        step: number
        data: ModelRejectStepPayload
        params?: RequestParams
      }) =>
        this.request<ModelRejectStepData, ModelRejectStepError>({
          path: `/approval-processes/${approvalProcessId}/${step}/reject`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/approval-processes/:approvalProcessId/:step/reject` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval processes
 * @name ModifyOrderStep
 * @summary Modify order during the approval step
 * @request PATCH:/approval-processes/{approvalProcessId}/{step}/order
 * @secure
 * @response `204` `ModelModifyOrderStepData` Order details modified
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Step in approval process is already approved or rejected
 */
    modifyOrderStep: {
      request: ({
        approvalProcessId,
        step,
        data,
        params = {},
      }: {
        approvalProcessId: string
        step: number
        data: ModelModifyOrderStepPayload
        params?: RequestParams
      }) =>
        this.request<ModelModifyOrderStepData, ModelModifyOrderStepError>({
          path: `/approval-processes/${approvalProcessId}/${step}/order`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/approval-processes/:approvalProcessId/:step/order` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval processes
 * @name GetApprovalLink
 * @summary Returns approval link state
 * @request GET:/customers/approval-link/{linkId}
 * @response `200` `ModelGetApprovalLinkData` Approval Link State
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval link expired
 */
    getApprovalLink: {
      request: ({
        linkId,
        params = {},
      }: {
        linkId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetApprovalLinkData, ModelGetApprovalLinkError>({
          path: `/customers/approval-link/${linkId}`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/customers/approval-link/:linkId` as const,
    },

    /**
 * No description
 *
 * @tags customers / approval processes
 * @name ApproveStepUsingLink
 * @summary Approve or reject a step in approval process using generated link
 * @request PUT:/customers/approval-link/{linkId}
 * @response `200` `ModelApproveStepUsingLinkData` Updated Approval Link State
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval link expired
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval process is already approved
 * @response `439` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Approval process is already rejected
 */
    approveStepUsingLink: {
      request: ({
        linkId,
        data,
        params = {},
      }: {
        linkId: string
        data: ModelApproveStepUsingLinkPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelApproveStepUsingLinkData,
          ModelApproveStepUsingLinkError
        >({
          path: `/customers/approval-link/${linkId}`,
          method: 'PUT',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/customers/approval-link/:linkId` as const,
    },

    /**
 * No description
 *
 * @tags customers
 * @name GetCustomerProfile
 * @summary Retrieve the Customer's profile for given `{customerId}`
 * @request GET:/profile
 * @secure
 * @response `200` `ModelGetCustomerProfileData` Company profile for given `{customerId}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist
 */
    getCustomerProfile: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerProfileData, ModelGetCustomerProfileError>(
          {
            path: `/profile`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
          },
        ),
      url: `/profile` as const,
    },

    /**
 * No description
 *
 * @tags customers
 * @name UpdateCustomerProfile
 * @summary Update the Customer's profile for given `{customerId}`
 * @request PATCH:/profile
 * @secure
 * @response `200` `ModelUpdateCustomerProfileData` Company profile for given `{customerId}`
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` do not exist
 */
    updateCustomerProfile: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCustomerProfilePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerProfileData,
          ModelUpdateCustomerProfileError
        >({
          path: `/profile`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/profile` as const,
    },

    /**
     * No description
     *
     * @tags customers / order settings
     * @name GetCustomerOrderTransferSettings
     * @summary Retrieve the OrderTransferSettings of the given `{customerId}`
     * @request GET:/settings/order-transfer-settings
     * @secure
     * @response `200` `ModelGetCustomerOrderTransferSettingsData` OrderTransferSettings of the given `{customerId}`
     */
    getCustomerOrderTransferSettings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerOrderTransferSettingsData, any>({
          path: `/settings/order-transfer-settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/order-transfer-settings` as const,
    },

    /**
 * No description
 *
 * @tags customers / order settings
 * @name PatchCustomerOrderTransferSettings
 * @summary Update OrderTransferSettings of the given `{customerId}`
 * @request PATCH:/settings/order-transfer-settings
 * @secure
 * @response `200` `ModelPatchCustomerOrderTransferSettingsData` OrderTransferSettings successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    patchCustomerOrderTransferSettings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchCustomerOrderTransferSettingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchCustomerOrderTransferSettingsData,
          ModelPatchCustomerOrderTransferSettingsError
        >({
          path: `/settings/order-transfer-settings`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/settings/order-transfer-settings` as const,
    },

    /**
     * No description
     *
     * @tags customers / back order settings
     * @name GetCustomerBackOrderInterfaceSettings
     * @summary Retrieve the BackOrderInterfaceSettings of the given `{customerId}`
     * @request GET:/settings/back-order-interface-settings
     * @secure
     * @response `200` `ModelGetCustomerBackOrderInterfaceSettingsData` BackOrderInterfaceSettings of the given `{customerId}`
     */
    getCustomerBackOrderInterfaceSettings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerBackOrderInterfaceSettingsData, any>({
          path: `/settings/back-order-interface-settings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/back-order-interface-settings` as const,
    },

    /**
 * No description
 *
 * @tags customers / back order settings
 * @name PatchCustomerBackOrderInterfaceSettings
 * @summary Update BackOrderInterfaceSettings of the given `{customerId}`
 * @request PATCH:/settings/back-order-interface-settings
 * @secure
 * @response `200` `ModelPatchCustomerBackOrderInterfaceSettingsData` BackOrderInterfaceSettings successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `403` `void` User is part of a ERP group, but not its master
 */
    patchCustomerBackOrderInterfaceSettings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchCustomerBackOrderInterfaceSettingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchCustomerBackOrderInterfaceSettingsData,
          ModelPatchCustomerBackOrderInterfaceSettingsError
        >({
          path: `/settings/back-order-interface-settings`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/settings/back-order-interface-settings` as const,
    },

    /**
     * No description
     *
     * @tags customers / order settings
     * @name GetOrderTransferSettingsPullConfiguration
     * @summary Retrieve the FTP and SFTP PULL configuration for the given `{customerId}`
     * @request GET:/settings/pull-configuration
     * @secure
     * @response `200` `ModelGetOrderTransferSettingsPullConfigurationData` Configuration data generated for FTP_PULL and SFTP_PULL of the given `{customerId}`
     */
    getOrderTransferSettingsPullConfiguration: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetOrderTransferSettingsPullConfigurationData, any>({
          path: `/settings/pull-configuration`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/pull-configuration` as const,
    },

    /**
     * No description
     *
     * @tags customers
     * @name GetOrderTransferSettingsRestApiConfiguration
     * @summary Retrieve the REST-API configuration for the given `{customerId}`
     * @request GET:/settings/rest-api-configuration
     * @secure
     * @response `200` `ModelGetOrderTransferSettingsRestApiConfigurationData` REST-API configuration data for the given `{customerId}`
     */
    getOrderTransferSettingsRestApiConfiguration: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetOrderTransferSettingsRestApiConfigurationData,
          any
        >({
          path: `/settings/rest-api-configuration`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/rest-api-configuration` as const,
    },

    /**
     * No description
     *
     * @tags customers
     * @name GetCustomerUnitMappings
     * @summary Retrieve the Unit mappings of the given customer `{customerId}`
     * @request GET:/settings/erp-unit-mappings
     * @secure
     * @response `200` `ModelGetCustomerUnitMappingsData` UnitMappings of the given `{customerId}`
     */
    getCustomerUnitMappings: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerUnitMappingsData, any>({
          path: `/settings/erp-unit-mappings`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/erp-unit-mappings` as const,
    },

    /**
 * @description Update the unit mappings for the customer
 *
 * @tags customers
 * @name UpdateCustomerUnitMappings
 * @summary Update unit mappings of the given customer `{customerId}`
 * @request PUT:/settings/erp-unit-mappings
 * @secure
 * @response `200` `ModelUpdateCustomerUnitMappingsData` Unit mappings are updated for the customer
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided customer id does not exist
 */
    updateCustomerUnitMappings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateCustomerUnitMappingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerUnitMappingsData,
          ModelUpdateCustomerUnitMappingsError
        >({
          path: `/settings/erp-unit-mappings`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/settings/erp-unit-mappings` as const,
    },

    /**
     * No description
     *
     * @tags customers / users
     * @name GetCustomerUsers
     * @summary Get customer users
     * @request GET:/users
     * @secure
     * @response `200` `ModelGetCustomerUsersData` List of customer users
     */
    getCustomerUsers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerUsersParams
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerUsersData, any>({
          path: `/users`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name PostCustomerUser
 * @summary Create customer user
 * @request POST:/users
 * @secure
 * @response `201` `ModelPostCustomerUserData` Customer user was successfully created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Cannot create user due to internal conflicts.
 */
    postCustomerUser: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerUserPayload
        params?: RequestParams
      }) =>
        this.request<ModelPostCustomerUserData, ModelPostCustomerUserError>({
          path: `/users`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/users` as const,
    },

    /**
     * No description
     *
     * @tags customers / users
     * @name GetMeCustomerUser
     * @summary Get profile for current customer user
     * @request GET:/users/me
     * @secure
     * @response `200` `ModelGetMeCustomerUserData` Customer User with details
     */
    getMeCustomerUser: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetMeCustomerUserData, any>({
          path: `/users/me`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/me` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name SearchCustomerUsers
 * @summary Search customer users
 * @request POST:/users/search
 * @secure
 * @response `200` `ModelSearchCustomerUsersData` Customer user
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    searchCustomerUsers: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerUsersParams
        data: ModelSearchCustomerUsersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerUsersData,
          ModelSearchCustomerUsersError
        >({
          path: `/users/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/users/search` as const,
    },

    /**
     * @description Retrieve a list of user groups that shoppers are assigned to.
     *
     * @tags customers / users
     * @name GetUserGroupsForCustomer2
     * @summary Get user groups that shoppers are assigned to.
     * @request GET:/users/user-groups
     * @originalName getUserGroupsForCustomer
     * @duplicate
     * @secure
     * @response `200` `ModelGetUserGroupsForCustomer2Data` List of user groups that shoppers are assigned to.
     */
    getUserGroupsForCustomer2: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetUserGroupsForCustomer2Data, any>({
          path: `/users/user-groups`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/user-groups` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name PutCustomerUser
 * @summary Update customer user
 * @request PUT:/users/{customerUserId}
 * @secure
 * @response `200` `ModelPutCustomerUserData` User was successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User is not permitted to change email
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Email is already assigned to a different user
 */
    putCustomerUser: {
      request: ({
        customerUserId,
        data,
        params = {},
      }: {
        customerUserId: string
        data: ModelPutCustomerUserPayload
        params?: RequestParams
      }) =>
        this.request<ModelPutCustomerUserData, ModelPutCustomerUserError>({
          path: `/users/${customerUserId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/users/:customerUserId` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUser
 * @summary Get customer user with edit options
 * @request GET:/users/{customerUserId}
 * @secure
 * @response `200` `ModelGetCustomerUserData` Customer User with details and edit options
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Shopper cannot access other customer user details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUser: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerUserData, ModelGetCustomerUserError>({
          path: `/users/${customerUserId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name DeleteCustomerUser
 * @summary Delete customer user
 * @request DELETE:/users/{customerUserId}
 * @secure
 * @response `204` `ModelDeleteCustomerUserData` Customer User is deleted.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    deleteCustomerUser: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteCustomerUserData, ModelDeleteCustomerUserError>(
          {
            path: `/users/${customerUserId}`,
            method: 'DELETE',
            secure: true,
            ...params,
          },
        ),
      url: `/users/:customerUserId` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name PostCustomerUserResendInvitation
 * @summary Resend invitation
 * @request POST:/users/{customerUserId}/resend-invitation
 * @secure
 * @response `200` `ModelPostCustomerUserResendInvitationData` Invitation succesfully resent
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid invitation state.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    postCustomerUserResendInvitation: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerUserResendInvitationData,
          ModelPostCustomerUserResendInvitationError
        >({
          path: `/users/${customerUserId}/resend-invitation`,
          method: 'POST',
          secure: true,
          ...params,
        }),
      url: `/users/:customerUserId/resend-invitation` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUserDeletionBlockers
 * @summary Get customer user deletion blockers
 * @request GET:/users/{customerUserId}/deletion-blockers
 * @secure
 * @response `200` `ModelGetCustomerUserDeletionBlockersData` Customer User deletion blockers
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUserDeletionBlockers: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerUserDeletionBlockersData,
          ModelGetCustomerUserDeletionBlockersError
        >({
          path: `/users/${customerUserId}/deletion-blockers`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/deletion-blockers` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUserAssignedApprovalFlows
 * @summary Get customer user assigned approval flows
 * @request GET:/users/{customerUserId}/approval-flows/assigned
 * @secure
 * @response `200` `ModelGetCustomerUserAssignedApprovalFlowsData` Customer user assigned approval flows
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUserAssignedApprovalFlows: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerUserAssignedApprovalFlowsData,
          ModelGetCustomerUserAssignedApprovalFlowsError
        >({
          path: `/users/${customerUserId}/approval-flows/assigned`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/approval-flows/assigned` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUserApprovalFlowsAsApprover
 * @summary Get customer user approval flows where the user is an approver
 * @request GET:/users/{customerUserId}/approval-flows/as-approver
 * @secure
 * @response `200` `ModelGetCustomerUserApprovalFlowsAsApproverData` Customer user approval flows where the user is an approver
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUserApprovalFlowsAsApprover: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerUserApprovalFlowsAsApproverData,
          ModelGetCustomerUserApprovalFlowsAsApproverError
        >({
          path: `/users/${customerUserId}/approval-flows/as-approver`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/approval-flows/as-approver` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUserAccountTypesAndValues
 * @summary Get customer user account types and values
 * @request GET:/users/{customerUserId}/account-types-and-values
 * @secure
 * @response `200` `ModelGetCustomerUserAccountTypesAndValuesData` Customer user account types and values
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUserAccountTypesAndValues: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerUserAccountTypesAndValuesData,
          ModelGetCustomerUserAccountTypesAndValuesError
        >({
          path: `/users/${customerUserId}/account-types-and-values`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/account-types-and-values` as const,
    },

    /**
 * @description Update default account value for the customer user
 *
 * @tags customers / users
 * @name UpdateCustomerUserDefaultAccountValue
 * @summary Update default account value for the customer user
 * @request PUT:/users/{customerUserId}/default-accounting-value
 * @secure
 * @response `200` `ModelUpdateCustomerUserDefaultAccountValueData` Default value updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    updateCustomerUserDefaultAccountValue: {
      request: ({
        customerUserId,
        data,
        params = {},
      }: {
        customerUserId: string
        data: ModelUpdateCustomerUserDefaultAccountValuePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerUserDefaultAccountValueData,
          ModelUpdateCustomerUserDefaultAccountValueError
        >({
          path: `/users/${customerUserId}/default-accounting-value`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/users/:customerUserId/default-accounting-value` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUserGeneralLedgers
 * @summary Get customer user general ledgers
 * @request GET:/users/{customerUserId}/general-ledgers
 * @secure
 * @response `200` `ModelGetCustomerUserGeneralLedgersData` Customer user general ledgers
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUserGeneralLedgers: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerUserGeneralLedgersData,
          ModelGetCustomerUserGeneralLedgersError
        >({
          path: `/users/${customerUserId}/general-ledgers`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/general-ledgers` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUserDeliveryAddresses
 * @summary Get customer user delivery addresses
 * @request GET:/users/{customerUserId}/delivery-addresses
 * @secure
 * @response `200` `ModelGetCustomerUserDeliveryAddressesData` Customer user delivery addresses
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUserDeliveryAddresses: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerUserDeliveryAddressesData,
          ModelGetCustomerUserDeliveryAddressesError
        >({
          path: `/users/${customerUserId}/delivery-addresses`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/delivery-addresses` as const,
    },

    /**
 * No description
 *
 * @tags customers / users
 * @name GetCustomerUserArticleLists
 * @summary Get customer user article lists
 * @request GET:/users/{customerUserId}/article-lists
 * @secure
 * @response `200` `ModelGetCustomerUserArticleListsData` Customer user article lists
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    getCustomerUserArticleLists: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerUserArticleListsData,
          ModelGetCustomerUserArticleListsError
        >({
          path: `/users/${customerUserId}/article-lists`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/article-lists` as const,
    },

    /**
     * @description Retrieve list of all customer user groups with pagination and sorted by name
     *
     * @tags customers / user groups
     * @name GetCustomerUserGroups
     * @summary Get customer user groups
     * @request GET:/user-groups
     * @secure
     * @response `200` `ModelGetCustomerUserGroupsData` List of customer user groups
     */
    getCustomerUserGroups: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetCustomerUserGroupsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerUserGroupsData, any>({
          path: `/user-groups`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/user-groups` as const,
    },

    /**
 * No description
 *
 * @tags customers / user groups
 * @name PostCustomerUserGroup
 * @summary Create customer user group
 * @request POST:/user-groups
 * @secure
 * @response `201` `ModelPostCustomerUserGroupData` User group was successfully created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    postCustomerUserGroup: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerUserGroupPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerUserGroupData,
          ModelPostCustomerUserGroupError
        >({
          path: `/user-groups`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/user-groups` as const,
    },

    /**
     * @description Retrieve a list of user groups names that a customer user can be assigned to.
     *
     * @tags customers / user groups
     * @name GetUserGroupsNamesForCustomer
     * @summary Get available user groups names that a customer user can be assigned to.
     * @request GET:/user-groups/names
     * @secure
     * @response `200` `ModelGetUserGroupsNamesForCustomerData` List of user groups names that a customer user can be assigned to.
     */
    getUserGroupsNamesForCustomer: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetUserGroupsNamesForCustomerData, any>({
          path: `/user-groups/names`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/user-groups/names` as const,
    },

    /**
 * No description
 *
 * @tags customers / user groups
 * @name PutCustomerUserGroup
 * @summary Update customer user group
 * @request PUT:/user-groups/{customerUserGroupId}
 * @secure
 * @response `200` `ModelPutCustomerUserGroupData` User group was successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    putCustomerUserGroup: {
      request: ({
        customerUserGroupId,
        data,
        params = {},
      }: {
        customerUserGroupId: string
        data: ModelPutCustomerUserGroupPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutCustomerUserGroupData,
          ModelPutCustomerUserGroupError
        >({
          path: `/user-groups/${customerUserGroupId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/user-groups/:customerUserGroupId` as const,
    },

    /**
 * No description
 *
 * @tags customers / user groups
 * @name DeleteCustomerUserGroup
 * @summary Delete customer user group
 * @request DELETE:/user-groups/{customerUserGroupId}
 * @secure
 * @response `204` `ModelDeleteCustomerUserGroupData` User group is deleted.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    deleteCustomerUserGroup: {
      request: ({
        customerUserGroupId,
        params = {},
      }: {
        customerUserGroupId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteCustomerUserGroupData,
          ModelDeleteCustomerUserGroupError
        >({
          path: `/user-groups/${customerUserGroupId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/user-groups/:customerUserGroupId` as const,
    },

    /**
     * No description
     *
     * @tags customers / user groups
     * @name GetCustomerUserGroupAssignments
     * @summary Get customer user group assignments
     * @request GET:/user-groups/{customerUserGroupId}/assignments
     * @secure
     * @response `200` `ModelGetCustomerUserGroupAssignmentsData` Assigned customer users
     */
    getCustomerUserGroupAssignments: {
      request: ({
        customerUserGroupId,
        params = {},
      }: {
        customerUserGroupId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerUserGroupAssignmentsData, any>({
          path: `/user-groups/${customerUserGroupId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/user-groups/:customerUserGroupId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags customers / user groups
 * @name PutCustomerUserGroupAssignments
 * @summary Update customer user group assignments
 * @request PUT:/user-groups/{customerUserGroupId}/assignments
 * @secure
 * @response `204` `ModelPutCustomerUserGroupAssignmentsData` User group assignments were successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User with provided customerUserId does not exist
 */
    putCustomerUserGroupAssignments: {
      request: ({
        customerUserGroupId,
        data,
        params = {},
      }: {
        customerUserGroupId: string
        data: ModelPutCustomerUserGroupAssignmentsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutCustomerUserGroupAssignmentsData,
          ModelPutCustomerUserGroupAssignmentsError
        >({
          path: `/user-groups/${customerUserGroupId}/assignments`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/user-groups/:customerUserGroupId/assignments` as const,
    },

    /**
 * No description
 *
 * @tags customers / contacts
 * @name GetCustomerContacts
 * @summary Retrieve all contacts that belong to the given `{customerId}`
 * @request GET:/contacts
 * @secure
 * @response `200` `ModelGetCustomerContactsData` All available contacts for given `{customerId}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    getCustomerContacts: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerContactsData,
          ModelGetCustomerContactsError
        >({
          path: `/contacts`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/contacts` as const,
    },

    /**
 * No description
 *
 * @tags customers / contacts
 * @name PatchCustomerContacts
 * @summary Patch contact details for the given `{customerId}`
 * @request PATCH:/contacts
 * @secure
 * @response `200` `ModelPatchCustomerContactsData` Contact details was successfully saved
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    patchCustomerContacts: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchCustomerContactsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchCustomerContactsData,
          ModelPatchCustomerContactsError
        >({
          path: `/contacts`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/contacts` as const,
    },

    /**
 * @description Retrieve the contact detail for the given `{customerId}` and `{contactType}`
 *
 * @tags customers / contacts
 * @name GetCustomerContactByType
 * @summary Retrieve the contact detail for the given `{customerId}` and `{contactType}`. Defaults to main contact if the requested contactType does not exist.
 * @request GET:/contacts/{contactType}
 * @secure
 * @response `200` `ModelGetCustomerContactByTypeData` Contact detail for the given `{customerId}` and `{contactType}`
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    getCustomerContactByType: {
      request: ({
        contactType,
        params = {},
      }: {
        contactType: ModelGetCustomerContactByTypeParamsEnum
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerContactByTypeData,
          ModelGetCustomerContactByTypeError
        >({
          path: `/contacts/${contactType}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/contacts/:contactType` as const,
    },

    /**
     * No description
     *
     * @tags customers / oci users
     * @name GetCustomerOciConfiguration
     * @summary Get customer OCI configuration data
     * @request GET:/settings/oci-configuration
     * @secure
     * @response `200` `ModelGetCustomerOciConfigurationData` Parameters of Customer OCI integration
     */
    getCustomerOciConfiguration: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerOciConfigurationData, any>({
          path: `/settings/oci-configuration`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/oci-configuration` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name PatchCustomerOciConfiguration
 * @summary Update customer OCI configuration data
 * @request PATCH:/settings/oci-configuration
 * @secure
 * @response `204` `ModelPatchCustomerOciConfigurationData` Customer OCI configuration is updated.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    patchCustomerOciConfiguration: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchCustomerOciConfigurationPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchCustomerOciConfigurationData,
          ModelPatchCustomerOciConfigurationError
        >({
          path: `/settings/oci-configuration`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/settings/oci-configuration` as const,
    },

    /**
     * No description
     *
     * @tags customers / oci users
     * @name GetCustomerOciUsers
     * @summary Get customer OCI users
     * @request GET:/settings/oci-configuration/users
     * @secure
     * @response `200` `ModelGetCustomerOciUsersData` List of customer OCI users
     */
    getCustomerOciUsers: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerOciUsersData, any>({
          path: `/settings/oci-configuration/users`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/oci-configuration/users` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name PostCustomerOciUser
 * @summary Create customer OCI user
 * @request POST:/settings/oci-configuration/users
 * @secure
 * @response `201` `ModelPostCustomerOciUserData` Customer OCI user was successfully created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` OCI user with given name already exists for this customer.
 */
    postCustomerOciUser: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostCustomerOciUserPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerOciUserData,
          ModelPostCustomerOciUserError
        >({
          path: `/settings/oci-configuration/users`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/settings/oci-configuration/users` as const,
    },

    /**
     * No description
     *
     * @tags customers / oci users
     * @name GetCustomerOciUser
     * @summary Get customer OCI user
     * @request GET:/settings/oci-configuration/users/{ociUserId}
     * @secure
     * @response `200` `ModelGetCustomerOciUserData` OCI user data
     */
    getCustomerOciUser: {
      request: ({
        ociUserId,
        params = {},
      }: {
        ociUserId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerOciUserData, any>({
          path: `/settings/oci-configuration/users/${ociUserId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/oci-configuration/users/:ociUserId` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name PutCustomerOciUser
 * @summary Update customer OCI user
 * @request PUT:/settings/oci-configuration/users/{ociUserId}
 * @secure
 * @response `204` `ModelPutCustomerOciUserData` OCI user successfully updated
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` OCI user with given name already exists for this customer.
 */
    putCustomerOciUser: {
      request: ({
        ociUserId,
        data,
        params = {},
      }: {
        ociUserId: string
        data: ModelPutCustomerOciUserPayload
        params?: RequestParams
      }) =>
        this.request<ModelPutCustomerOciUserData, ModelPutCustomerOciUserError>(
          {
            path: `/settings/oci-configuration/users/${ociUserId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
          },
        ),
      url: `/settings/oci-configuration/users/:ociUserId` as const,
    },

    /**
     * No description
     *
     * @tags customers / oci users
     * @name DeleteCustomerOciUser
     * @summary Remove customer OCI user
     * @request DELETE:/settings/oci-configuration/users/{ociUserId}
     * @secure
     * @response `204` `ModelDeleteCustomerOciUserData` OCI user successfully deleted
     */
    deleteCustomerOciUser: {
      request: ({
        ociUserId,
        params = {},
      }: {
        ociUserId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteCustomerOciUserData, any>({
          path: `/settings/oci-configuration/users/${ociUserId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/settings/oci-configuration/users/:ociUserId` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name PostOciFieldsMappings
 * @summary Create oci fields mappings
 * @request POST:/settings/oci-fields-mappings
 * @secure
 * @response `201` `ModelPostOciFieldsMappingsData` Oci fields mappings successfully saved
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Invalid oci field mapping value
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Oci fields mappings name already used by this customer
 */
    postOciFieldsMappings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostOciFieldsMappingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostOciFieldsMappingsData,
          ModelPostOciFieldsMappingsError
        >({
          path: `/settings/oci-fields-mappings`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/settings/oci-fields-mappings` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name GetOciFieldsMapping
 * @summary Get oci fields mapping
 * @request GET:/settings/oci-fields-mappings/{ociFieldsMappingId}
 * @secure
 * @response `200` `ModelGetOciFieldsMappingData` Customer's current oci fields mappings
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Oci fields mappings with given `customerId` and `ociFieldsMappingId` does not exist
 */
    getOciFieldsMapping: {
      request: ({
        ociFieldsMappingId,
        params = {},
      }: {
        ociFieldsMappingId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetOciFieldsMappingData,
          ModelGetOciFieldsMappingError
        >({
          path: `/settings/oci-fields-mappings/${ociFieldsMappingId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/settings/oci-fields-mappings/:ociFieldsMappingId` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name UpdateOciFieldsMappings
 * @summary Update oci fields mappings
 * @request PUT:/settings/oci-fields-mappings/{ociFieldsMappingId}
 * @secure
 * @response `204` `ModelUpdateOciFieldsMappingsData` Oci fields mappings successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Oci fields mappings with given `customerId` and `ociFieldsMappingId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` OciFieldsMappings name already used by this customer
 */
    updateOciFieldsMappings: {
      request: ({
        ociFieldsMappingId,
        data,
        params = {},
      }: {
        ociFieldsMappingId: string
        data: ModelUpdateOciFieldsMappingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOciFieldsMappingsData,
          ModelUpdateOciFieldsMappingsError
        >({
          path: `/settings/oci-fields-mappings/${ociFieldsMappingId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/settings/oci-fields-mappings/:ociFieldsMappingId` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name DeleteOciFieldsMapping
 * @summary Deletes oci fields mapping
 * @request DELETE:/settings/oci-fields-mappings/{ociFieldsMappingId}
 * @secure
 * @response `204` `ModelDeleteOciFieldsMappingData` Oci fields mappings successfully deleted
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Default oci fields mapping cannot be deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Oci fields mappings with given `customerId` and `ociFieldsMappingId` does not exist
 */
    deleteOciFieldsMapping: {
      request: ({
        ociFieldsMappingId,
        params = {},
      }: {
        ociFieldsMappingId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteOciFieldsMappingData,
          ModelDeleteOciFieldsMappingError
        >({
          path: `/settings/oci-fields-mappings/${ociFieldsMappingId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/settings/oci-fields-mappings/:ociFieldsMappingId` as const,
    },

    /**
 * No description
 *
 * @tags customers / oci users
 * @name GetCustomerOciFieldsMappings
 * @summary Retrieve customer's current oci fields mappings
 * @request POST:/settings/oci-fields-mappings/search
 * @secure
 * @response `200` `ModelGetCustomerOciFieldsMappingsData` Customer's current oci fields mappings
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    getCustomerOciFieldsMappings: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelGetCustomerOciFieldsMappingsParams
        data: ModelGetCustomerOciFieldsMappingsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerOciFieldsMappingsData,
          ModelGetCustomerOciFieldsMappingsError
        >({
          path: `/settings/oci-fields-mappings/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/settings/oci-fields-mappings/search` as const,
    },

    /**
 * @description Search for vendors that customer has relationship with
 *
 * @tags customers / oci users
 * @name SearchCustomerOciFieldsMappingsMyVendors
 * @summary Search for my vendors list
 * @request POST:/settings/oci-fields-mappings/my-vendors/search
 * @secure
 * @response `200` `ModelSearchCustomerOciFieldsMappingsMyVendorsData` List of my vendors
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    searchCustomerOciFieldsMappingsMyVendors: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerOciFieldsMappingsMyVendorsParams
        data: ModelSearchCustomerOciFieldsMappingsMyVendorsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerOciFieldsMappingsMyVendorsData,
          ModelSearchCustomerOciFieldsMappingsMyVendorsError
        >({
          path: `/settings/oci-fields-mappings/my-vendors/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/settings/oci-fields-mappings/my-vendors/search` as const,
    },

    /**
 * No description
 *
 * @tags customer / oci cart transfer history
 * @name SearchCustomerOciCartTransfers
 * @summary Search for cart transfers
 * @request POST:/oci-transferred-carts/search
 * @secure
 * @response `200` `ModelSearchCustomerOciCartTransfersData` Parameters of Customer OCI integration
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    searchCustomerOciCartTransfers: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelSearchCustomerOciCartTransfersParams
        data: ModelSearchCustomerOciCartTransfersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchCustomerOciCartTransfersData,
          ModelSearchCustomerOciCartTransfersError
        >({
          path: `/oci-transferred-carts/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/oci-transferred-carts/search` as const,
    },

    /**
 * No description
 *
 * @tags customer / oci cart transfer history
 * @name GetCustomerOciCartTransferDetails
 * @summary Details of transferred OCI cart
 * @request GET:/oci-transferred-carts/{ociTransferredCartId}
 * @secure
 * @response `200` `ModelGetCustomerOciCartTransferDetailsData` Detailed data of transferred OCI cart
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer or transferred OCI cart with given ID does not exist
 */
    getCustomerOciCartTransferDetails: {
      request: ({
        ociTransferredCartId,
        params = {},
      }: {
        ociTransferredCartId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerOciCartTransferDetailsData,
          ModelGetCustomerOciCartTransferDetailsError
        >({
          path: `/oci-transferred-carts/${ociTransferredCartId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/oci-transferred-carts/:ociTransferredCartId` as const,
    },

    /**
     * @description Returns a list of Vendors of a given Customer that have oci transferred carts
     *
     * @tags customers / oci cart transfer history
     * @name GetCustomerOciCartTransferHistoryVendors
     * @summary Returns a list of Vendors of a given Customer that have oci transferred carts
     * @request GET:/oci-transferred-carts/vendors
     * @secure
     * @response `200` `ModelGetCustomerOciCartTransferHistoryVendorsData` List of Vendors of a given Customer that have placed oci transferred carts
     */
    getCustomerOciCartTransferHistoryVendors: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerOciCartTransferHistoryVendorsData, any>({
          path: `/oci-transferred-carts/vendors`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/oci-transferred-carts/vendors` as const,
    },

    /**
 * @description Allows searching for article lists based on search term. Supports pagination.
 *
 * @tags customers / article lists
 * @name CustomerArticleListsSearch
 * @summary Search for article lists
 * @request POST:/article-lists/search
 * @secure
 * @response `200` `ModelCustomerArticleListsSearchData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    customerArticleListsSearch: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelCustomerArticleListsSearchParams
        data: ModelCustomerArticleListsSearchPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCustomerArticleListsSearchData,
          ModelCustomerArticleListsSearchError
        >({
          path: `/article-lists/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/search` as const,
    },

    /**
     * @description Creates a new empty article list
     *
     * @tags customers / article lists
     * @name CreateArticleList
     * @summary Creates a new empty article list
     * @request POST:/article-lists
     * @secure
     * @response `201` `ModelCreateArticleListData` Article list created
     */
    createArticleList: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCreateArticleListPayload
        params?: RequestParams
      }) =>
        this.request<ModelCreateArticleListData, any>({
          path: `/article-lists`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/article-lists` as const,
    },

    /**
     * @description Get all articles from the specific article list with their details and details of the list itself
     *
     * @tags customers / article lists
     * @name GetArticleList
     * @summary Get article list details of a customer user
     * @request GET:/article-lists/{articleListId}
     * @secure
     * @response `200` `ModelGetArticleListData` Article list details of a customer user
     */
    getArticleList: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetArticleListData, any>({
          path: `/article-lists/${articleListId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId` as const,
    },

    /**
 * @description Updates definition of article list
 *
 * @tags customers / article lists
 * @name UpdateArticleList
 * @summary Updates definition of article list
 * @request PUT:/article-lists/{articleListId}
 * @secure
 * @response `204` `ModelUpdateArticleListData` Article list updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    updateArticleList: {
      request: ({
        articleListId,
        data,
        params = {},
      }: {
        articleListId: string
        data: ModelUpdateArticleListPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateArticleListData, ModelUpdateArticleListError>({
          path: `/article-lists/${articleListId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/article-lists/:articleListId` as const,
    },

    /**
 * @description Delete article list of a customer user
 *
 * @tags customers / article lists
 * @name DeleteArticleList
 * @summary Delete article lists of a customer user
 * @request DELETE:/article-lists/{articleListId}
 * @secure
 * @response `204` `ModelDeleteArticleListData` Article lists deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    deleteArticleList: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteArticleListData, ModelDeleteArticleListError>({
          path: `/article-lists/${articleListId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/article-lists/:articleListId` as const,
    },

    /**
 * @description Get user manageable article lists with article assignment information
 *
 * @tags customers / article lists
 * @name GetArticleListAssignment
 * @summary Get user manageable article lists with article assignment information
 * @request GET:/article-lists/article-assignments/{articleId}
 * @secure
 * @response `200` `ModelGetArticleListAssignmentData` Article lists with article assignment information
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article does not exist
 */
    getArticleListAssignment: {
      request: ({
        articleId,
        params = {},
      }: {
        articleId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetArticleListAssignmentData,
          ModelGetArticleListAssignmentError
        >({
          path: `/article-lists/article-assignments/${articleId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/article-assignments/:articleId` as const,
    },

    /**
 * @description Updates user manageable article lists with article assignment information.  If a manageable article list is not mentioned, the assignment state for given article is not changed.
 *
 * @tags customers / article lists
 * @name UpdateArticleListAssignment
 * @summary Updates user manageable article lists with article assignment information
 * @request PUT:/article-lists/article-assignments/{articleId}
 * @secure
 * @response `204` `ModelUpdateArticleListAssignmentData` Article lists assignment information updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One or more list requested to change assignment are not available for changes by the user
 */
    updateArticleListAssignment: {
      request: ({
        articleId,
        data,
        params = {},
      }: {
        articleId: string
        data: ModelUpdateArticleListAssignmentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateArticleListAssignmentData,
          ModelUpdateArticleListAssignmentError
        >({
          path: `/article-lists/article-assignments/${articleId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/article-lists/article-assignments/:articleId` as const,
    },

    /**
 * @description Search articles from the specific article list with their details
 *
 * @tags customers / article lists
 * @name SearchArticleListItems
 * @summary Search article list items
 * @request POST:/article-lists/{articleListId}/articles/search
 * @secure
 * @response `200` `ModelSearchArticleListItemsData` Article list items
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    searchArticleListItems: {
      request: ({
        pathAndQueryParams: { articleListId, ...query },
        data,
        params = {},
      }: {
        pathAndQueryParams: ModelSearchArticleListItemsParams
        data: ModelSearchArticleListItemsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchArticleListItemsData,
          ModelSearchArticleListItemsError
        >({
          path: `/article-lists/${articleListId}/articles/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/search` as const,
    },

    /**
 * No description
 *
 * @tags customers / article lists
 * @name GetBarcodeArticleListItems
 * @summary Get article list items for barcode generation
 * @request GET:/article-lists/{articleListId}/articles/barcode-items
 * @secure
 * @response `200` `ModelGetBarcodeArticleListItemsData` Article list items for barcode generation
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    getBarcodeArticleListItems: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetBarcodeArticleListItemsData,
          ModelGetBarcodeArticleListItemsError
        >({
          path: `/article-lists/${articleListId}/articles/barcode-items`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/barcode-items` as const,
    },

    /**
 * @description Updates article list item quantity
 *
 * @tags customers / article lists
 * @name UpdateArticleListItem
 * @summary Updates article list item quantity
 * @request PUT:/article-lists/{articleListId}/articles/{articleId}
 * @secure
 * @response `204` `ModelUpdateArticleListItemData` Article lists item information updated
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User is not allowed to update this article list
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    updateArticleListItem: {
      request: ({
        articleListId,
        articleId,
        data,
        params = {},
      }: {
        articleListId: string
        articleId: string
        data: ModelUpdateArticleListItemPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateArticleListItemData,
          ModelUpdateArticleListItemError
        >({
          path: `/article-lists/${articleListId}/articles/${articleId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/:articleId` as const,
    },

    /**
 * @description Delete article list item
 *
 * @tags customers / article lists
 * @name DeleteArticleListItem
 * @summary Delete article list item
 * @request DELETE:/article-lists/{articleListId}/articles/{articleId}
 * @secure
 * @response `204` `ModelDeleteArticleListItemData` Article lists item deleted
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User is not allowed to update this article list
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    deleteArticleListItem: {
      request: ({
        articleListId,
        articleId,
        params = {},
      }: {
        articleListId: string
        articleId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteArticleListItemData,
          ModelDeleteArticleListItemError
        >({
          path: `/article-lists/${articleListId}/articles/${articleId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/:articleId` as const,
    },

    /**
 * @description Retrieve list of customer users to whom the article list is shared - no pagination
 *
 * @tags customers / article lists
 * @name GetSharedCustomerUsersToArticleLists
 * @summary Retrieve list of customer users to whom the article list is shared
 * @request GET:/article-lists/{articleListId}/assignments/shared
 * @secure
 * @response `200` `ModelGetSharedCustomerUsersToArticleListsData` List of customer users with assignment/shared status for the article list
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getSharedCustomerUsersToArticleLists: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetSharedCustomerUsersToArticleListsData,
          ModelGetSharedCustomerUsersToArticleListsError
        >({
          path: `/article-lists/${articleListId}/assignments/shared`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId/assignments/shared` as const,
    },

    /**
 * @description Retrieve list of customer users with whom the article list is collaborated - no pagination
 *
 * @tags customers / article lists
 * @name GetCollaboratedCustomerUsersToArticleLists
 * @summary Retrieve list of customer users with whom the article list is collaborated
 * @request GET:/article-lists/{articleListId}/assignments/collaborated
 * @secure
 * @response `200` `ModelGetCollaboratedCustomerUsersToArticleListsData` List of customer users with assignment/collaborated status for the article list
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    getCollaboratedCustomerUsersToArticleLists: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCollaboratedCustomerUsersToArticleListsData,
          ModelGetCollaboratedCustomerUsersToArticleListsError
        >({
          path: `/article-lists/${articleListId}/assignments/collaborated`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId/assignments/collaborated` as const,
    },

    /**
 * @description Invite list of customer users as collaborators for the article list
 *
 * @tags customers / article lists
 * @name CollaborateArticleListsWithCustomerUsers
 * @summary Invite list of customer users as collaborators for the article list
 * @request PUT:/article-lists/{articleListId}/assignments/collaborated
 * @secure
 * @response `200` `ModelCollaborateArticleListsWithCustomerUsersData` Assignments updated for the article list
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One of the resources referenced by path variables is not present
 */
    collaborateArticleListsWithCustomerUsers: {
      request: ({
        articleListId,
        data,
        params = {},
      }: {
        articleListId: string
        data: ModelCollaborateArticleListsWithCustomerUsersPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCollaborateArticleListsWithCustomerUsersData,
          ModelCollaborateArticleListsWithCustomerUsersError
        >({
          path: `/article-lists/${articleListId}/assignments/collaborated`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/article-lists/:articleListId/assignments/collaborated` as const,
    },

    /**
     * @description Retrieve list of all budgets for the current customer's user
     *
     * @tags customers / budgets
     * @name GetCurrentUserBudgetsList
     * @summary Retrieve list of the current customer's users budgets utilisation
     * @request GET:/users/budgets
     * @deprecated
     * @secure
     * @response `200` `ModelGetCurrentUserBudgetsListData` Current utilisation of available Budgets
     */
    getCurrentUserBudgetsList: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCurrentUserBudgetsListData, any>({
          path: `/users/budgets`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/budgets` as const,
    },

    /**
     * @description Retrieve list of all budgets for the given customer's user
     *
     * @tags customers / budgets
     * @name GetUserBudgetsList
     * @summary Retrieve list of a given customer's user budgets utilisation
     * @request GET:/users/{customerUserId}/budgets
     * @secure
     * @response `200` `ModelGetUserBudgetsListData` Current utilisation of available Budgets
     */
    getUserBudgetsList: {
      request: ({
        customerUserId,
        params = {},
      }: {
        customerUserId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetUserBudgetsListData, any>({
          path: `/users/${customerUserId}/budgets`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/:customerUserId/budgets` as const,
    },

    /**
     * @description Retrieve list of all budgets for the given customer
     *
     * @tags customers / budgets
     * @name GetBudgetsList
     * @summary Retrieve list of customer budgets
     * @request GET:/budgets
     * @secure
     * @response `200` `ModelGetBudgetsListData` Budget list for a customer
     */
    getBudgetsList: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetBudgetsListData, any>({
          path: `/budgets`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/budgets` as const,
    },

    /**
 * @description Create new budget
 *
 * @tags customers / budgets
 * @name AddNewBudget
 * @summary Create new budget
 * @request POST:/budgets
 * @secure
 * @response `201` `ModelAddNewBudgetData` New Budget created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Budget with provided name already exists
 */
    addNewBudget: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelAddNewBudgetPayload
        params?: RequestParams
      }) =>
        this.request<ModelAddNewBudgetData, ModelAddNewBudgetError>({
          path: `/budgets`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/budgets` as const,
    },

    /**
     * No description
     *
     * @tags customers / budgets
     * @name GetBudget
     * @summary Get budget
     * @request GET:/budgets/{budgetId}
     * @secure
     * @response `200` `ModelGetBudgetData` Budget definition
     */
    getBudget: {
      request: ({
        budgetId,
        params = {},
      }: {
        budgetId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetBudgetData, any>({
          path: `/budgets/${budgetId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/budgets/:budgetId` as const,
    },

    /**
 * No description
 *
 * @tags customers / budgets
 * @name UpdateBudget
 * @summary Update budget
 * @request PATCH:/budgets/{budgetId}
 * @secure
 * @response `204` `ModelUpdateBudgetData` Budget updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Budget does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Budget with provided name already exists
 */
    updateBudget: {
      request: ({
        budgetId,
        data,
        params = {},
      }: {
        budgetId: string
        data: ModelUpdateBudgetPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateBudgetData, ModelUpdateBudgetError>({
          path: `/budgets/${budgetId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/budgets/:budgetId` as const,
    },

    /**
 * No description
 *
 * @tags customers / budgets
 * @name DeleteBudget
 * @summary Delete budget
 * @request DELETE:/budgets/{budgetId}
 * @secure
 * @response `204` `ModelDeleteBudgetData` Budget deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Budget does not exist
 */
    deleteBudget: {
      request: ({
        budgetId,
        params = {},
      }: {
        budgetId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteBudgetData, ModelDeleteBudgetError>({
          path: `/budgets/${budgetId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/budgets/:budgetId` as const,
    },

    /**
 * No description
 *
 * @tags customers / budgets
 * @name UpdateAdditionalBudget
 * @summary Update additional budget
 * @request PUT:/budgets/{budgetId}/additional
 * @secure
 * @response `204` `ModelUpdateAdditionalBudgetData` Additional budget updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Budget does not exist
 */
    updateAdditionalBudget: {
      request: ({
        budgetId,
        data,
        params = {},
      }: {
        budgetId: string
        data: ModelUpdateAdditionalBudgetPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateAdditionalBudgetData,
          ModelUpdateAdditionalBudgetError
        >({
          path: `/budgets/${budgetId}/additional`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/budgets/:budgetId/additional` as const,
    },

    /**
     * @description Retrieve list of all individual budgets for the given customer
     *
     * @tags customers / individual budgets
     * @name GetIndividualBudgetsList
     * @summary Retrieve list of customer individual budgets
     * @request GET:/individual-budgets
     * @secure
     * @response `200` `ModelGetIndividualBudgetsListData` Budget list for a customer
     */
    getIndividualBudgetsList: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetIndividualBudgetsListData, any>({
          path: `/individual-budgets`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/individual-budgets` as const,
    },

    /**
 * @description Create new individual budget
 *
 * @tags customers / individual budgets
 * @name AddNewIndividualBudget
 * @summary Create new individual budget
 * @request POST:/individual-budgets
 * @secure
 * @response `201` `ModelAddNewIndividualBudgetData` New Budget created
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Budget with provided name already exists
 */
    addNewIndividualBudget: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelAddNewIndividualBudgetPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelAddNewIndividualBudgetData,
          ModelAddNewIndividualBudgetError
        >({
          path: `/individual-budgets`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/individual-budgets` as const,
    },

    /**
 * No description
 *
 * @tags customers / individual budgets
 * @name DeleteIndividualBudget
 * @summary Delete individual budget
 * @request DELETE:/individual-budgets/{budgetId}
 * @secure
 * @response `204` `ModelDeleteIndividualBudgetData` Budget deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Budget does not exist
 */
    deleteIndividualBudget: {
      request: ({
        budgetId,
        params = {},
      }: {
        budgetId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteIndividualBudgetData,
          ModelDeleteIndividualBudgetError
        >({
          path: `/individual-budgets/${budgetId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/individual-budgets/:budgetId` as const,
    },

    /**
 * @description Update individual budget
 *
 * @tags customers / individual budgets
 * @name UpdateIndividualBudget
 * @summary Update individual budget
 * @request PUT:/individual-budgets/{budgetId}
 * @secure
 * @response `204` `ModelUpdateIndividualBudgetData` Individual Budget successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Individual Budget with given `budgetId` does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Individual Budget name already used
 */
    updateIndividualBudget: {
      request: ({
        budgetId,
        data,
        params = {},
      }: {
        budgetId: string
        data: ModelUpdateIndividualBudgetPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateIndividualBudgetData,
          ModelUpdateIndividualBudgetError
        >({
          path: `/individual-budgets/${budgetId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/individual-budgets/:budgetId` as const,
    },

    /**
     * No description
     *
     * @tags customers / fast order
     * @name GetFastOrderList
     * @summary Get the content of the fast order list
     * @request GET:/fast-order/{fastOrderListEnum}
     * @secure
     * @response `200` `ModelGetFastOrderListData` Item added to the list
     */
    getFastOrderList: {
      request: ({
        fastOrderListEnum,
        params = {},
      }: {
        fastOrderListEnum: ModelGetFastOrderListParamsEnum
        params?: RequestParams
      }) =>
        this.request<ModelGetFastOrderListData, any>({
          path: `/fast-order/${fastOrderListEnum}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/fast-order/:fastOrderListEnum` as const,
    },

    /**
     * No description
     *
     * @tags customers / fast order
     * @name GetNumberOfFastOrderListItems
     * @summary Get the number of fast order list items
     * @request GET:/fast-order/{fastOrderListEnum}/counter
     * @secure
     * @response `200` `ModelGetNumberOfFastOrderListItemsData` The number of fast order list items
     */
    getNumberOfFastOrderListItems: {
      request: ({
        fastOrderListEnum,
        params = {},
      }: {
        fastOrderListEnum: ModelGetNumberOfFastOrderListItemsParamsEnum
        params?: RequestParams
      }) =>
        this.request<ModelGetNumberOfFastOrderListItemsData, any>({
          path: `/fast-order/${fastOrderListEnum}/counter`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/fast-order/:fastOrderListEnum/counter` as const,
    },

    /**
     * No description
     *
     * @tags customers / fast order
     * @name AddItemToFastOrderList
     * @summary Add scanned barcode to the list
     * @request POST:/fast-order/{fastOrderListEnum}/add-item
     * @secure
     * @response `201` `ModelAddItemToFastOrderListData` Item added to the list
     */
    addItemToFastOrderList: {
      request: ({
        fastOrderListEnum,
        data,
        params = {},
      }: {
        fastOrderListEnum: ModelAddItemToFastOrderListParamsEnum
        data: ModelAddItemToFastOrderListPayload
        params?: RequestParams
      }) =>
        this.request<ModelAddItemToFastOrderListData, any>({
          path: `/fast-order/${fastOrderListEnum}/add-item`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/fast-order/:fastOrderListEnum/add-item` as const,
    },

    /**
     * No description
     *
     * @tags customers / fast order
     * @name AddItemsToFastOrderList
     * @summary Add fast order items to the list
     * @request POST:/fast-order/{fastOrderListEnum}/add-items
     * @secure
     * @response `201` `ModelAddItemsToFastOrderListData` Items added to the list
     */
    addItemsToFastOrderList: {
      request: ({
        fastOrderListEnum,
        data,
        params = {},
      }: {
        fastOrderListEnum: ModelAddItemsToFastOrderListParamsEnum
        data: ModelAddItemsToFastOrderListPayload
        params?: RequestParams
      }) =>
        this.request<ModelAddItemsToFastOrderListData, any>({
          path: `/fast-order/${fastOrderListEnum}/add-items`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/fast-order/:fastOrderListEnum/add-items` as const,
    },

    /**
     * No description
     *
     * @tags customers / fast order
     * @name DeleteFromFastOrderList
     * @summary Delete some scanned barcodes from the list
     * @request POST:/fast-order/{fastOrderListEnum}/delete-items
     * @secure
     * @response `204` `ModelDeleteFromFastOrderListData` Items removed from the list
     */
    deleteFromFastOrderList: {
      request: ({
        fastOrderListEnum,
        data,
        params = {},
      }: {
        fastOrderListEnum: ModelDeleteFromFastOrderListParamsEnum
        data: ModelDeleteFromFastOrderListPayload
        params?: RequestParams
      }) =>
        this.request<ModelDeleteFromFastOrderListData, any>({
          path: `/fast-order/${fastOrderListEnum}/delete-items`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/fast-order/:fastOrderListEnum/delete-items` as const,
    },

    /**
 * No description
 *
 * @tags customers / fast order
 * @name MoveFromFastOrderListToCart
 * @summary Move some list items to the shopping cart
 * @request POST:/fast-order/{fastOrderListEnum}/move-items-to-cart
 * @secure
 * @response `200` `ModelMoveFromFastOrderListToCartData` Items moved to shopping cart
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Cannot add list item to cart as it's unavailable or restricted
 */
    moveFromFastOrderListToCart: {
      request: ({
        fastOrderListEnum,
        data,
        params = {},
      }: {
        fastOrderListEnum: ModelMoveFromFastOrderListToCartParamsEnum
        data: ModelMoveFromFastOrderListToCartPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelMoveFromFastOrderListToCartData,
          ModelMoveFromFastOrderListToCartError
        >({
          path: `/fast-order/${fastOrderListEnum}/move-items-to-cart`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/fast-order/:fastOrderListEnum/move-items-to-cart` as const,
    },

    /**
 * No description
 *
 * @tags customers / fast order
 * @name UpdateFastOrderListItem
 * @summary Update item of the fast order list
 * @request PATCH:/fast-order/{fastOrderListEnum}/{fastOrderListItemId}
 * @secure
 * @response `204` `ModelUpdateFastOrderListItemData` Item updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Fast order list item do not exist
 */
    updateFastOrderListItem: {
      request: ({
        fastOrderListEnum,
        fastOrderListItemId,
        data,
        params = {},
      }: {
        fastOrderListEnum: ModelUpdateFastOrderListItemParamsEnum
        fastOrderListItemId: string
        data: ModelUpdateFastOrderListItemPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateFastOrderListItemData,
          ModelUpdateFastOrderListItemError
        >({
          path: `/fast-order/${fastOrderListEnum}/${fastOrderListItemId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/fast-order/:fastOrderListEnum/:fastOrderListItemId` as const,
    },

    /**
     * No description
     *
     * @tags customers / article external references
     * @name GetArticleExternalReferences
     * @summary Get article external references list
     * @request GET:/articles/{articleId}/external-references
     * @secure
     * @response `200` `ModelGetArticleExternalReferencesData`
     */
    getArticleExternalReferences: {
      request: ({
        articleId,
        params = {},
      }: {
        articleId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetArticleExternalReferencesData, any>({
          path: `/articles/${articleId}/external-references`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/articles/:articleId/external-references` as const,
    },

    /**
 * No description
 *
 * @tags customers / article external references
 * @name CreateArticleExternalReference
 * @summary Create external reference for article
 * @request POST:/articles/{articleId}/external-references
 * @secure
 * @response `201` `ModelCreateArticleExternalReferenceData` Article external reference created
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Combination of barcode and quantity already exists
 */
    createArticleExternalReference: {
      request: ({
        articleId,
        data,
        params = {},
      }: {
        articleId: string
        data: ModelCreateArticleExternalReferencePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCreateArticleExternalReferenceData,
          ModelCreateArticleExternalReferenceError
        >({
          path: `/articles/${articleId}/external-references`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/articles/:articleId/external-references` as const,
    },

    /**
 * No description
 *
 * @tags customers / article external references
 * @name UpdateArticleExternalReference
 * @summary Update external reference for article
 * @request PATCH:/articles/{articleId}/external-references/{externalReferenceId}
 * @secure
 * @response `204` `ModelUpdateArticleExternalReferenceData` Article external reference updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article external reference does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Combination of barcode and quantity already exists
 */
    updateArticleExternalReference: {
      request: ({
        articleId,
        externalReferenceId,
        data,
        params = {},
      }: {
        articleId: string
        externalReferenceId: string
        data: ModelUpdateArticleExternalReferencePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateArticleExternalReferenceData,
          ModelUpdateArticleExternalReferenceError
        >({
          path: `/articles/${articleId}/external-references/${externalReferenceId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/articles/:articleId/external-references/:externalReferenceId` as const,
    },

    /**
     * No description
     *
     * @tags customers / article external references
     * @name DeleteArticleExternalReference
     * @summary Delete external reference for article
     * @request DELETE:/articles/{articleId}/external-references/{externalReferenceId}
     * @secure
     * @response `204` `ModelDeleteArticleExternalReferenceData` Article external reference deleted
     */
    deleteArticleExternalReference: {
      request: ({
        articleId,
        externalReferenceId,
        params = {},
      }: {
        articleId: string
        externalReferenceId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteArticleExternalReferenceData, any>({
          path: `/articles/${articleId}/external-references/${externalReferenceId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/articles/:articleId/external-references/:externalReferenceId` as const,
    },

    /**
 * No description
 *
 * @tags customers / customer article number
 * @name GetCustomerArticleNumberDetails
 * @summary Returns customer article number details for article
 * @request GET:/articles/{articleId}/customer-article-number
 * @secure
 * @response `200` `ModelGetCustomerArticleNumberDetailsData` Customer article number details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided article id does not exist
 */
    getCustomerArticleNumberDetails: {
      request: ({
        articleId,
        params = {},
      }: {
        articleId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerArticleNumberDetailsData,
          ModelGetCustomerArticleNumberDetailsError
        >({
          path: `/articles/${articleId}/customer-article-number`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/articles/:articleId/customer-article-number` as const,
    },

    /**
 * No description
 *
 * @tags customers / customer article number
 * @name UpdateCustomerArticleNumber
 * @summary Update customer article number for article
 * @request PUT:/articles/{articleId}/customer-article-number
 * @secure
 * @response `204` `ModelUpdateCustomerArticleNumberData` Customer article number updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` The 'customValue' field value can not be present for DEFAULT source
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided article id does not exist
 */
    updateCustomerArticleNumber: {
      request: ({
        articleId,
        data,
        params = {},
      }: {
        articleId: string
        data: ModelUpdateCustomerArticleNumberPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateCustomerArticleNumberData,
          ModelUpdateCustomerArticleNumberError
        >({
          path: `/articles/${articleId}/customer-article-number`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/articles/:articleId/customer-article-number` as const,
    },

    /**
 * @description Retrieve assignment information for a customer
 *
 * @tags customers / assignments
 * @name GetCustomerResourceAssignments
 * @summary Retrieve assignment information for a customer
 * @request GET:/resource-assignments/{resourceType}/{resourceId}
 * @secure
 * @response `200` `ModelGetCustomerResourceAssignmentsData` Assigned customer users for the resource
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    getCustomerResourceAssignments: {
      request: ({
        resourceType,
        resourceId,
        params = {},
      }: {
        resourceType: ModelGetCustomerResourceAssignmentsParamsEnum
        resourceId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCustomerResourceAssignmentsData,
          ModelGetCustomerResourceAssignmentsError
        >({
          path: `/resource-assignments/${resourceType}/${resourceId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/resource-assignments/:resourceType/:resourceId` as const,
    },

    /**
 * @description Assign list of customer users to a resource
 *
 * @tags customers / assignments
 * @name PutCustomerResourceAssignment
 * @summary Assign list of customer users to a resource
 * @request PUT:/resource-assignments/{resourceType}/{resourceId}
 * @secure
 * @response `204` `ModelPutCustomerResourceAssignmentData` Assignments updated for the resource
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    putCustomerResourceAssignment: {
      request: ({
        resourceType,
        resourceId,
        data,
        params = {},
      }: {
        resourceType: ModelPutCustomerResourceAssignmentParamsEnum
        resourceId: string
        data: ModelPutCustomerResourceAssignmentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutCustomerResourceAssignmentData,
          ModelPutCustomerResourceAssignmentError
        >({
          path: `/resource-assignments/${resourceType}/${resourceId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/resource-assignments/:resourceType/:resourceId` as const,
    },

    /**
 * @description UnAssign all users for a resource
 *
 * @tags customers / assignments
 * @name UnAssignCustomerResourceAssignment
 * @summary UnAssign all users for a resource
 * @request PUT:/resource-assignments/{resourceType}/{resourceId}/unassign
 * @secure
 * @response `204` `ModelUnAssignCustomerResourceAssignmentData` Unassigned all users for the resource
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}`
 */
    unAssignCustomerResourceAssignment: {
      request: ({
        resourceType,
        resourceId,
        params = {},
      }: {
        resourceType: ModelUnAssignCustomerResourceAssignmentParamsEnum
        resourceId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelUnAssignCustomerResourceAssignmentData,
          ModelUnAssignCustomerResourceAssignmentError
        >({
          path: `/resource-assignments/${resourceType}/${resourceId}/unassign`,
          method: 'PUT',
          secure: true,
          ...params,
        }),
      url: `/resource-assignments/:resourceType/:resourceId/unassign` as const,
    },
  }
  customerOciSession = {
    /**
     * @description Returns a list of Vendors for current Customer depending on view type
     *
     * @tags customer oci session / vendors
     * @name GetOciCustomerVendors
     * @summary Returns a list of Vendors for current Customer
     * @request GET:/vendors
     * @secure
     * @response `200` `ModelGetOciCustomerVendorsData` List of Vendors (requested or public) for given Customer
     */
    getOciCustomerVendors: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetOciCustomerVendorsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetOciCustomerVendorsData, any>({
          path: `/vendors`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/vendors` as const,
    },

    /**
     * No description
     *
     * @tags customer oci session / vendors
     * @name GetOciCustomerUserVendors
     * @summary Get vendor list with catalog urls available for the OCI customer user
     * @request GET:/my-vendors
     * @secure
     * @response `200` `ModelGetOciCustomerUserVendorsData` Vendor list available for the customer user
     */
    getOciCustomerUserVendors: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetOciCustomerUserVendorsData, any>({
          path: `/my-vendors`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/my-vendors` as const,
    },

    /**
     * No description
     *
     * @tags customer oci session
     * @name LoginWithGet
     * @summary Login user and return OCI session
     * @request GET:/customer-oci-session/login/{customerId}
     * @response `302` `void` Redirect to frontend with new OCI session id
     * @response `400` `void` Invalid request
     * @response `401` `void` Invalid credentials
     */
    loginWithGet: {
      request: ({
        pathAndQueryParams: { customerId, ...query },
        params = {},
      }: {
        pathAndQueryParams: ModelLoginWithGetParams
        params?: RequestParams
      }) =>
        this.request<any, void>({
          path: `/customer-oci-session/login/${customerId}`,
          method: 'GET',
          query: query,
          ...params,
        }),
      url: `/customer-oci-session/login/:customerId` as const,
    },

    /**
     * No description
     *
     * @tags customer oci session
     * @name LoginWithPost
     * @summary Login user and return OCI session
     * @request POST:/customer-oci-session/login/{customerId}
     * @response `302` `void` Redirect to frontend with new OCI session id
     * @response `400` `void` Invalid request body
     * @response `401` `void` Invalid credentials
     */
    loginWithPost: {
      request: ({
        customerId,
        data,
        params = {},
      }: {
        customerId: string
        data: ModelLoginWithPostPayload
        params?: RequestParams
      }) =>
        this.request<any, void>({
          path: `/customer-oci-session/login/${customerId}`,
          method: 'POST',
          body: data,
          type: ContentType.UrlEncoded,
          ...params,
        }),
      url: `/customer-oci-session/login/:customerId` as const,
    },

    /**
     * @description Returns basic information about the current OCI user including assignments to customers. Assignment to vendors is always empty.
     *
     * @tags customer oci session
     * @name GetOciUserOfCustomerOciSession
     * @summary Returns basic information about the current OCI user
     * @request GET:/
     * @response `200` `ModelGetOciUserOfCustomerOciSessionData` Current user data
     */
    getOciUserOfCustomerOciSession: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetOciUserOfCustomerOciSessionData, any>({
          path: `/`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/` as const,
    },

    /**
     * No description
     *
     * @tags customer oci session
     * @name PatchCustomerOciSessionSettings
     * @summary Updates OCI user settings
     * @request PATCH:/
     * @response `200` `ModelPatchCustomerOciSessionSettingsData` Settings updated
     */
    patchCustomerOciSessionSettings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchCustomerOciSessionSettingsPayload
        params?: RequestParams
      }) =>
        this.request<ModelPatchCustomerOciSessionSettingsData, any>({
          path: `/`,
          method: 'PATCH',
          body: data,
          type: ContentType.Json,
          ...params,
        }),
      url: `/` as const,
    },

    /**
     * No description
     *
     * @tags customer oci session
     * @name GetOciCustomerProfile
     * @summary Retrieve the Customer's profile for customer owning given OCI session
     * @request GET:/profile
     * @response `200` `ModelGetOciCustomerProfileData` Company profile for customer owning given OCI session
     */
    getOciCustomerProfile: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetOciCustomerProfileData, any>({
          path: `/profile`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/profile` as const,
    },

    /**
     * @description Allows retrieving full content of the shopping cart
     *
     * @tags customer oci session / cart
     * @name GetCustomerOciCart
     * @summary Retrieves content of OCI shopping cart
     * @request GET:/carts/current
     * @response `200` `ModelGetCustomerOciCartData` Success
     */
    getCustomerOciCart: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerOciCartData, any>({
          path: `/carts/current`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/carts/current` as const,
    },

    /**
     * No description
     *
     * @tags customer oci session / cart
     * @name DeleteCustomerOciCart
     * @summary Deletes OCI shopping cart
     * @request DELETE:/carts/current
     * @response `204` `ModelDeleteCustomerOciCartData` Cart successfully deleted
     */
    deleteCustomerOciCart: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelDeleteCustomerOciCartData, any>({
          path: `/carts/current`,
          method: 'DELETE',
          ...params,
        }),
      url: `/carts/current` as const,
    },

    /**
     * @description Get articles quantities in the cart.
     *
     * @tags customer oci session / cart
     * @name GetArticlesQuantitiesInOciCart
     * @summary Get article quantities in the cart
     * @request GET:/carts/current/items
     * @secure
     * @response `200` `ModelGetArticlesQuantitiesInOciCartData` Success
     */
    getArticlesQuantitiesInOciCart: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetArticlesQuantitiesInOciCartData, any>({
          path: `/carts/current/items`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/carts/current/items` as const,
    },

    /**
 * @description Change articles quantities in the cart by given amounts. Negative values can be used to decrease. In case resulting amount of article in cart is invalid, the quantity is updated to the next higher valid amount.
 *
 * @tags customer oci session / cart
 * @name UpdateArticlesQuantitiesInOciCart
 * @summary Change article quantities in the OCI shopping cart
 * @request POST:/carts/current/items
 * @response `201` `ModelUpdateArticlesQuantitiesInOciCartData` Items successfully added/updated in the OCI shopping cart
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article with given articleId does not exist
 */
    updateArticlesQuantitiesInOciCart: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelUpdateArticlesQuantitiesInOciCartPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateArticlesQuantitiesInOciCartData,
          ModelUpdateArticlesQuantitiesInOciCartError
        >({
          path: `/carts/current/items`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          ...params,
        }),
      url: `/carts/current/items` as const,
    },

    /**
 * @description Allows modification of the quantity. Only present fields will have effect.
 *
 * @tags customer oci session / cart
 * @name UpdateOciCartItem
 * @summary Update item properties in the OCI shopping cart
 * @request PATCH:/carts/current/items/{cartItemId}
 * @response `204` `ModelUpdateOciCartItemData` Item successfully modified in the cart
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Some items cannot be added, as article do not exist
 */
    updateOciCartItem: {
      request: ({
        cartItemId,
        data,
        params = {},
      }: {
        cartItemId: string
        data: ModelUpdateOciCartItemPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateOciCartItemData, ModelUpdateOciCartItemError>({
          path: `/carts/current/items/${cartItemId}`,
          method: 'PATCH',
          body: data,
          type: ContentType.Json,
          ...params,
        }),
      url: `/carts/current/items/:cartItemId` as const,
    },

    /**
     * @description Allows removal of the of given article from the OCI shopping cart
     *
     * @tags customer oci session / cart
     * @name DeleteItemFromOciCart
     * @summary Delete item from the OCI shopping cart
     * @request DELETE:/carts/current/items/{cartItemId}
     * @response `204` `ModelDeleteItemFromOciCartData` Item successfully removed from the shopping cart
     */
    deleteItemFromOciCart: {
      request: ({
        cartItemId,
        params = {},
      }: {
        cartItemId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteItemFromOciCartData, any>({
          path: `/carts/current/items/${cartItemId}`,
          method: 'DELETE',
          ...params,
        }),
      url: `/carts/current/items/:cartItemId` as const,
    },

    /**
 * No description
 *
 * @tags customer oci session / cart
 * @name OciCartTransfer
 * @summary Prepares data for transferring OCI cart to the ERP system, and finalizes customer OCI session
 * @request POST:/carts/current/transfer
 * @response `200` `ModelOciCartTransferData` Cart content mapped to OCI response fields and Hook URL where the data should be sent
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Cannot transfer cart as it is empty or contains unavailable articles
 */
    ociCartTransfer: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelOciCartTransferData, ModelOciCartTransferError>({
          path: `/carts/current/transfer`,
          method: 'POST',
          format: 'json',
          ...params,
        }),
      url: `/carts/current/transfer` as const,
    },

    /**
 * @description Allows OCI user searching for articles based on search term. Supports pagination.
 *
 * @tags customer oci session / marketplace
 * @name OciCustomerArticleSearch
 * @summary Search for articles
 * @request POST:/marketplace/articles/search
 * @response `200` `ModelOciCustomerArticleSearchData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    ociCustomerArticleSearch: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelOciCustomerArticleSearchParams
        data: ModelOciCustomerArticleSearchPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelOciCustomerArticleSearchData,
          ModelOciCustomerArticleSearchError
        >({
          path: `/marketplace/articles/search`,
          method: 'POST',
          query: query,
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/articles/search` as const,
    },

    /**
 * @description Gives the detailed information for an article
 *
 * @tags customer oci session / marketplace
 * @name CustomerOciArticleDetail
 * @summary Returns the details of an article
 * @request GET:/marketplace/articles/{articleId}
 * @response `200` `ModelCustomerOciArticleDetailData` Article details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided article id does not exist
 */
    customerOciArticleDetail: {
      request: ({
        articleId,
        params = {},
      }: {
        articleId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelCustomerOciArticleDetailData,
          ModelCustomerOciArticleDetailError
        >({
          path: `/marketplace/articles/${articleId}`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/marketplace/articles/:articleId` as const,
    },

    /**
 * @description Request external prices for list of articles that were returned without internal system prices.
 *
 * @tags customer oci session / marketplace
 * @name CustomerOciFetchExternalPrices
 * @summary Request external prices for list of articles
 * @request POST:/marketplace/external-prices
 * @response `200` `ModelCustomerOciFetchExternalPricesData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    customerOciFetchExternalPrices: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelCustomerOciFetchExternalPricesPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCustomerOciFetchExternalPricesData,
          ModelCustomerOciFetchExternalPricesError
        >({
          path: `/marketplace/external-prices`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/external-prices` as const,
    },

    /**
     * @description Returns the list of platform categories
     *
     * @tags customer oci session / platform categories
     * @name GetCustomerOciPlatformCategories
     * @summary List platform categories
     * @request GET:/marketplace/platform-categories
     * @secure
     * @response `200` `ModelGetCustomerOciPlatformCategoriesData` List of platform categories
     */
    getCustomerOciPlatformCategories: {
      request: ({
        ociSessionId,
        params = {},
      }: {
        ociSessionId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetCustomerOciPlatformCategoriesData, any>({
          path: `/marketplace/platform-categories`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/marketplace/platform-categories` as const,
    },

    /**
 * @description Returns a list of Vendors (public or with ACCEPTED request status) of a OCI Customer
 *
 * @tags customer oci session / marketplace
 * @name GetCustomerOciMarketplaceVendors
 * @summary Returns a list of public and connected Vendors of a OCI Customer
 * @request GET:/marketplace/vendors
 * @response `200` `ModelGetCustomerOciMarketplaceVendorsData` List of public and connected Vendors of a given Customer sorted alphabetically ascending
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Access to Customer with given `customerId` is forbidden
 */
    getCustomerOciMarketplaceVendors: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<
          ModelGetCustomerOciMarketplaceVendorsData,
          ModelGetCustomerOciMarketplaceVendorsError
        >({
          path: `/marketplace/vendors`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/marketplace/vendors` as const,
    },

    /**
     * No description
     *
     * @tags customer oci session / vendor oci
     * @name GetOciCustomerVendorOciCatalogs
     * @summary Returns a list of OCI catalogs available for given OCI Customer
     * @request GET:/vendoroci
     * @response `200` `ModelGetOciCustomerVendorOciCatalogsData` List of OCI catalogs for given OCI Customer
     */
    getOciCustomerVendorOciCatalogs: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetOciCustomerVendorOciCatalogsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetOciCustomerVendorOciCatalogsData, any>({
          path: `/vendoroci`,
          method: 'GET',
          query: query,
          format: 'json',
          ...params,
        }),
      url: `/vendoroci` as const,
    },

    /**
 * No description
 *
 * @tags customer oci session / vendor oci
 * @name GetOciCustomerOciUrlWithSession
 * @summary Get a url with session for the given ociCatalogId for OCI customer
 * @request GET:/vendoroci/{ociCatalogId}/url-with-session
 * @response `200` `ModelGetOciCustomerOciUrlWithSessionData` response containing url, credentials, ociSessionId and other settings available to the corresponding Vendor OCI Catalog
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Access to OCI Catalog with given `{ociCatalogId}` is forbidden
 */
    getOciCustomerOciUrlWithSession: {
      request: ({
        ociCatalogId,
        params = {},
      }: {
        ociCatalogId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetOciCustomerOciUrlWithSessionData,
          ModelGetOciCustomerOciUrlWithSessionError
        >({
          path: `/vendoroci/${ociCatalogId}/url-with-session`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/vendoroci/:ociCatalogId/url-with-session` as const,
    },

    /**
 * @description Allows searching for article lists based on search term. Supports pagination.
 *
 * @tags customers / article lists
 * @name CustomerArticleListsSearchOci
 * @summary Search for article lists
 * @request POST:/article-lists/search
 * @secure
 * @response `200` `ModelCustomerArticleListsSearchOciData` Success
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    customerArticleListsSearchOci: {
      request: ({
        query,
        data,
        params = {},
      }: {
        query: ModelCustomerArticleListsSearchOciParams
        data: ModelCustomerArticleListsSearchOciPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelCustomerArticleListsSearchOciData,
          ModelCustomerArticleListsSearchOciError
        >({
          path: `/article-lists/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/search` as const,
    },

    /**
     * @description Get all articles from the specific article list with their details and details of the list itself
     *
     * @tags customer oci session / article lists
     * @name GetOciArticleList
     * @summary Get article list details of an OCI user
     * @request GET:/article-lists/{articleListId}
     * @secure
     * @response `200` `ModelGetOciArticleListData` Article list details of an OCI user
     */
    getOciArticleList: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<ModelGetOciArticleListData, any>({
          path: `/article-lists/${articleListId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId` as const,
    },

    /**
 * @description Updates definition of article list of an OCI user
 *
 * @tags customer oci session / article lists
 * @name UpdateOciArticleList
 * @summary Updates definition of article list of an OCI user
 * @request PUT:/article-lists/{articleListId}
 * @secure
 * @response `204` `ModelUpdateOciArticleListData` Article list updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    updateOciArticleList: {
      request: ({
        articleListId,
        data,
        params = {},
      }: {
        articleListId: string
        data: ModelUpdateOciArticleListPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOciArticleListData,
          ModelUpdateOciArticleListError
        >({
          path: `/article-lists/${articleListId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/article-lists/:articleListId` as const,
    },

    /**
 * @description Delete article list of an OCI customer user
 *
 * @tags customer oci session / article lists
 * @name DeleteOciArticleList
 * @summary Delete article list of an OCI customer user
 * @request DELETE:/article-lists/{articleListId}
 * @secure
 * @response `204` `ModelDeleteOciArticleListData` Article lists deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    deleteOciArticleList: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteOciArticleListData,
          ModelDeleteOciArticleListError
        >({
          path: `/article-lists/${articleListId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/article-lists/:articleListId` as const,
    },

    /**
 * @description Get user manageable article lists with article assignment information for OCI user
 *
 * @tags customer oci session / article lists
 * @name GetOciArticleListAssignment
 * @summary Get user manageable article lists with article assignment information for OCI user
 * @request GET:/article-lists/article-assignments/{articleId}
 * @secure
 * @response `200` `ModelGetOciArticleListAssignmentData` Article lists with article assignment information
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article does not exist
 */
    getOciArticleListAssignment: {
      request: ({
        articleId,
        params = {},
      }: {
        articleId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetOciArticleListAssignmentData,
          ModelGetOciArticleListAssignmentError
        >({
          path: `/article-lists/article-assignments/${articleId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/article-assignments/:articleId` as const,
    },

    /**
 * @description Updates OCI user manageable article lists with article assignment information.  If a manageable article list is not mentioned, the assignment state for given article is not changed.
 *
 * @tags customer oci session / article lists
 * @name UpdateOciArticleListAssignment
 * @summary Updates OCI user manageable article lists with article assignment information
 * @request PUT:/article-lists/article-assignments/{articleId}
 * @secure
 * @response `204` `ModelUpdateOciArticleListAssignmentData` Article lists assignment information updated
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` One or more list requested to change assignment are not available for changes by the user
 */
    updateOciArticleListAssignment: {
      request: ({
        articleId,
        data,
        params = {},
      }: {
        articleId: string
        data: ModelUpdateOciArticleListAssignmentPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOciArticleListAssignmentData,
          ModelUpdateOciArticleListAssignmentError
        >({
          path: `/article-lists/article-assignments/${articleId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/article-lists/article-assignments/:articleId` as const,
    },

    /**
 * @description Search articles from the specific article list with their details
 *
 * @tags customers / article lists
 * @name SearchArticleListItems
 * @summary Search article list items
 * @request POST:/article-lists/{articleListId}/articles/search
 * @secure
 * @response `200` `ModelSearchArticleListItemsResult` Article list items
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    searchArticleListItems: {
      request: ({
        pathAndQueryParams: { articleListId, ...query },
        data,
        params = {},
      }: {
        pathAndQueryParams: ModelSearchArticleListItemsParams2
        data: ModelSearchArticleListItemsBody
        params?: RequestParams
      }) =>
        this.request<
          ModelSearchArticleListItemsResult,
          ModelSearchArticleListItemsFail
        >({
          path: `/article-lists/${articleListId}/articles/search`,
          method: 'POST',
          query: query,
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/search` as const,
    },

    /**
 * No description
 *
 * @tags customers / article lists
 * @name GetBarcodeArticleListItems
 * @summary Get article list items for barcode generation
 * @request GET:/article-lists/{articleListId}/articles/barcode-items
 * @secure
 * @response `200` `ModelGetBarcodeArticleListItemsResult` Article list items for barcode generation
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    getBarcodeArticleListItems: {
      request: ({
        articleListId,
        params = {},
      }: {
        articleListId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetBarcodeArticleListItemsResult,
          ModelGetBarcodeArticleListItemsFail
        >({
          path: `/article-lists/${articleListId}/articles/barcode-items`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/barcode-items` as const,
    },

    /**
 * @description Updates article list item quantity
 *
 * @tags customer oci session / article lists
 * @name UpdateOciArticleListItem
 * @summary Updates article list item quantity
 * @request PUT:/article-lists/{articleListId}/articles/{articleId}
 * @secure
 * @response `204` `ModelUpdateOciArticleListItemData` Article lists item information updated
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User is not allowed to update this article list
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    updateOciArticleListItem: {
      request: ({
        articleListId,
        articleId,
        data,
        params = {},
      }: {
        articleListId: string
        articleId: string
        data: ModelUpdateOciArticleListItemPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateOciArticleListItemData,
          ModelUpdateOciArticleListItemError
        >({
          path: `/article-lists/${articleListId}/articles/${articleId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/:articleId` as const,
    },

    /**
 * @description Delete article list item
 *
 * @tags customer oci session / article lists
 * @name DeleteOciArticleListItem
 * @summary Delete article list item
 * @request DELETE:/article-lists/{articleListId}/articles/{articleId}
 * @secure
 * @response `204` `ModelDeleteOciArticleListItemData` Article lists item deleted
 * @response `403` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` User is not allowed to update this article list
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Article list does not exist
 */
    deleteOciArticleListItem: {
      request: ({
        articleListId,
        articleId,
        params = {},
      }: {
        articleListId: string
        articleId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteOciArticleListItemData,
          ModelDeleteOciArticleListItemError
        >({
          path: `/article-lists/${articleListId}/articles/${articleId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/article-lists/:articleListId/articles/:articleId` as const,
    },
  }
  users = {
    /**
     * @description Returns basic information about the current user including assignments to vendors and customers
     *
     * @tags users
     * @name GetMyUser
     * @summary Returns basic information about the current user
     * @request GET:/users/me
     * @secure
     * @response `200` `ModelGetMyUserData` Current user data
     */
    getMyUser: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetMyUserData, any>({
          path: `/users/me`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/users/me` as const,
    },

    /**
     * No description
     *
     * @tags users
     * @name PatchMyUserSettings
     * @summary Updates user settings
     * @request PATCH:/users/me
     * @secure
     * @response `200` `ModelPatchMyUserSettingsData` Settings updated
     */
    patchMyUserSettings: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPatchMyUserSettingsPayload
        params?: RequestParams
      }) =>
        this.request<ModelPatchMyUserSettingsData, any>({
          path: `/users/me`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/users/me` as const,
    },

    /**
 * @description Verifies user account with provided key and login if valid
 *
 * @tags users
 * @name VerifyAccount
 * @summary Verify user account registration
 * @request GET:/users/account-verifications/{verificationKey}
 * @response `200` `ModelVerifyAccountData` New JWT & refresh tokens
 * @response `401` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Invalid verification key
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Account already verified
 * @response `410` `{
  \** @example "4906cd7b-2810-4671-963b-81be91d2ad25" *\
    userId: string,

}` Verification key has expired
 */
    verifyAccount: {
      request: ({
        verificationKey,
        params = {},
      }: {
        verificationKey: string
        params?: RequestParams
      }) =>
        this.request<ModelVerifyAccountData, ModelVerifyAccountError>({
          path: `/users/account-verifications/${verificationKey}`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/users/account-verifications/:verificationKey` as const,
    },

    /**
 * No description
 *
 * @tags users
 * @name ResendAccountVerification
 * @summary Send another verification email for a user account
 * @request POST:/users/account-verifications/{userId}/resend
 * @response `200` `ModelResendAccountVerificationData` Verification mail sent
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Account already verified
 */
    resendAccountVerification: {
      request: ({
        userId,
        params = {},
      }: {
        userId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelResendAccountVerificationData,
          ModelResendAccountVerificationError
        >({
          path: `/users/account-verifications/${userId}/resend`,
          method: 'POST',
          ...params,
        }),
      url: `/users/account-verifications/:userId/resend` as const,
    },
  }
  invitations = {
    /**
     * No description
     *
     * @tags users
     * @name GetInvitationState
     * @summary Get Invitation state
     * @request GET:/invitations/{invitationKey}
     * @response `200` `ModelGetInvitationStateData` State of the invitation
     */
    getInvitationState: {
      request: ({
        invitationKey,
        params = {},
      }: {
        invitationKey: string
        params?: RequestParams
      }) =>
        this.request<ModelGetInvitationStateData, any>({
          path: `/invitations/${invitationKey}`,
          method: 'GET',
          format: 'json',
          ...params,
        }),
      url: `/invitations/:invitationKey` as const,
    },

    /**
 * No description
 *
 * @tags users
 * @name UseInvitationPassword
 * @request POST:/invitations/{invitationKey}/password
 * @response `200` `ModelUseInvitationPasswordData` New JWT & refresh tokens
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    useInvitationPassword: {
      request: ({
        invitationKey,
        data,
        params = {},
      }: {
        invitationKey: string
        data: ModelUseInvitationPasswordPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUseInvitationPasswordData,
          ModelUseInvitationPasswordError
        >({
          path: `/invitations/${invitationKey}/password`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/invitations/:invitationKey/password` as const,
    },

    /**
     * No description
     *
     * @tags users
     * @name UseInvitationLogin
     * @request POST:/invitations/{invitationKey}/login
     * @response `200` `ModelUseInvitationLoginData` New JWT & refresh tokens
     * @response `401` `void` Invalid credentials
     */
    useInvitationLogin: {
      request: ({
        invitationKey,
        data,
        params = {},
      }: {
        invitationKey: string
        data: ModelUseInvitationLoginPayload
        params?: RequestParams
      }) =>
        this.request<ModelUseInvitationLoginData, void>({
          path: `/invitations/${invitationKey}/login`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/invitations/:invitationKey/login` as const,
    },
  }
  authentication = {
    /**
 * No description
 *
 * @tags authentication
 * @name Login
 * @summary Login user and return JWT access token and refresh token
 * @request POST:/authentication/login
 * @response `200` `ModelLoginData` New JWT & refresh tokens
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Invalid request body or user identity not verified
 * @response `401` `void` Invalid credentials
 * @response `409` `{
  \** @example "4906cd7b-2810-4671-963b-81be91d2ad25" *\
    userId: string,

}` Account not yet verified
 */
    login: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelLoginPayload
        params?: RequestParams
      }) =>
        this.request<ModelLoginData, ModelLoginError>({
          path: `/authentication/login`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/authentication/login` as const,
    },

    /**
     * No description
     *
     * @tags authentication
     * @name Logout
     * @summary Logout user
     * @request POST:/authentication/logout
     * @response `200` `ModelLogoutData` User logged out
     */
    logout: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelLogoutPayload
        params?: RequestParams
      }) =>
        this.request<ModelLogoutData, any>({
          path: `/authentication/logout`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          ...params,
        }),
      url: `/authentication/logout` as const,
    },

    /**
     * No description
     *
     * @tags authentication
     * @name JwtRefresh
     * @summary Refresh JWT token
     * @request POST:/authentication/refresh
     * @response `200` `ModelJwtRefreshData` Refreshed JWT & refresh tokens
     * @response `401` `void` Invalid tokens to refresh
     */
    jwtRefresh: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelJwtRefreshPayload
        params?: RequestParams
      }) =>
        this.request<ModelJwtRefreshData, void>({
          path: `/authentication/refresh`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/authentication/refresh` as const,
    },

    /**
     * No description
     *
     * @tags authentication
     * @name InitiatePasswordReset
     * @summary Initiate Password Reset process
     * @request POST:/authentication/password-reset
     * @response `200` `ModelInitiatePasswordResetData` PasswordReset email successfully sent
     */
    initiatePasswordReset: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelInitiatePasswordResetPayload
        params?: RequestParams
      }) =>
        this.request<ModelInitiatePasswordResetData, any>({
          path: `/authentication/password-reset`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          ...params,
        }),
      url: `/authentication/password-reset` as const,
    },

    /**
 * No description
 *
 * @tags authentication
 * @name PasswordReset
 * @summary Reset user password
 * @request POST:/authentication/password-reset/{passwordResetId}
 * @response `200` `ModelPasswordResetData` New JWT & refresh tokens
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` passwordResetId is either invalid or has already expired.
 */
    passwordReset: {
      request: ({
        passwordResetId,
        data,
        params = {},
      }: {
        passwordResetId: string
        data: ModelPasswordResetPayload
        params?: RequestParams
      }) =>
        this.request<ModelPasswordResetData, ModelPasswordResetError>({
          path: `/authentication/password-reset/${passwordResetId}`,
          method: 'POST',
          body: data,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/authentication/password-reset/:passwordResetId` as const,
    },
  }
  backoffice = {
    /**
     * No description
     *
     * @tags backoffice
     * @name GetBackofficeCustomers
     * @summary Returns a list of Customers
     * @request GET:/backoffice/customers
     * @secure
     * @response `200` `ModelGetBackofficeCustomersData` List of Customers
     */
    getBackofficeCustomers: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetBackofficeCustomersParams
        params?: RequestParams
      }) =>
        this.request<ModelGetBackofficeCustomersData, any>({
          path: `/backoffice/customers`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/customers` as const,
    },

    /**
     * No description
     *
     * @tags backoffice
     * @name UpdateBackofficeCustomer
     * @summary Update Customer configuration
     * @request PATCH:/backoffice/customers/{customerId}
     * @secure
     * @response `204` `ModelUpdateBackofficeCustomerData` Customer configuration is updated.
     */
    updateBackofficeCustomer: {
      request: ({
        customerId,
        data,
        params = {},
      }: {
        customerId: string
        data: ModelUpdateBackofficeCustomerPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateBackofficeCustomerData, any>({
          path: `/backoffice/customers/${customerId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/customers/:customerId` as const,
    },

    /**
     * No description
     *
     * @tags backoffice
     * @name DeleteBackofficeCustomer
     * @summary Delete Customer
     * @request DELETE:/backoffice/customers/{customerId}
     * @secure
     * @response `204` `ModelDeleteBackofficeCustomerData` Customer successfully deleted
     */
    deleteBackofficeCustomer: {
      request: ({
        customerId,
        params = {},
      }: {
        customerId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteBackofficeCustomerData, any>({
          path: `/backoffice/customers/${customerId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/backoffice/customers/:customerId` as const,
    },

    /**
     * No description
     *
     * @tags backoffice
     * @name GetBackofficeCustomerUsers
     * @summary Get customer users for Backoffice
     * @request GET:/backoffice/customers/{customerId}/users
     * @secure
     * @response `200` `ModelGetBackofficeCustomerUsersData` List of customer users
     */
    getBackofficeCustomerUsers: {
      request: ({
        pathAndQueryParams: { customerId, ...query },
        params = {},
      }: {
        pathAndQueryParams: ModelGetBackofficeCustomerUsersParams
        params?: RequestParams
      }) =>
        this.request<ModelGetBackofficeCustomerUsersData, any>({
          path: `/backoffice/customers/${customerId}/users`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/customers/:customerId/users` as const,
    },

    /**
 * No description
 *
 * @tags backoffice
 * @name GetBackofficeCustomerFeaturesState
 * @summary Returns a state of customer features
 * @request GET:/backoffice/customers/{customerId}/features
 * @secure
 * @response `200` `ModelGetBackofficeCustomerFeaturesStateData` State of Customer features
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    getBackofficeCustomerFeaturesState: {
      request: ({
        customerId,
        params = {},
      }: {
        customerId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetBackofficeCustomerFeaturesStateData,
          ModelGetBackofficeCustomerFeaturesStateError
        >({
          path: `/backoffice/customers/${customerId}/features`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/customers/:customerId/features` as const,
    },

    /**
 * No description
 *
 * @tags backoffice
 * @name UpdateBackofficeCustomerFeaturesState
 * @summary Update Customer features state
 * @request PUT:/backoffice/customers/{customerId}/features
 * @secure
 * @response `204` `ModelUpdateBackofficeCustomerFeaturesStateData` Customer configuration is updated.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Customer with given `customerId` does not exist
 */
    updateBackofficeCustomerFeaturesState: {
      request: ({
        customerId,
        data,
        params = {},
      }: {
        customerId: string
        data: ModelUpdateBackofficeCustomerFeaturesStatePayload
        params?: RequestParams
      }) =>
        this.request<
          ModelUpdateBackofficeCustomerFeaturesStateData,
          ModelUpdateBackofficeCustomerFeaturesStateError
        >({
          path: `/backoffice/customers/${customerId}/features`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/customers/:customerId/features` as const,
    },

    /**
     * No description
     *
     * @tags backoffice
     * @name GetBackofficeVendors
     * @summary Returns a list of Vendors
     * @request GET:/backoffice/vendors
     * @secure
     * @response `200` `ModelGetBackofficeVendorsData` List of Vendors
     */
    getBackofficeVendors: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetBackofficeVendorsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetBackofficeVendorsData, any>({
          path: `/backoffice/vendors`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/vendors` as const,
    },

    /**
     * No description
     *
     * @tags backoffice
     * @name UpdateBackofficeVendor
     * @summary Update vendor data
     * @request PATCH:/backoffice/vendors/{vendorId}
     * @secure
     * @response `200` `ModelUpdateBackofficeVendorData` Vendor was successfully updated
     */
    updateBackofficeVendor: {
      request: ({
        vendorId,
        data,
        params = {},
      }: {
        vendorId: string
        data: ModelUpdateBackofficeVendorPayload
        params?: RequestParams
      }) =>
        this.request<ModelUpdateBackofficeVendorData, any>({
          path: `/backoffice/vendors/${vendorId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/vendors/:vendorId` as const,
    },

    /**
     * No description
     *
     * @tags backoffice
     * @name DeleteBackofficeVendor
     * @summary Delete vendor
     * @request DELETE:/backoffice/vendors/{vendorId}
     * @secure
     * @response `204` `ModelDeleteBackofficeVendorData` Vendor successfully deleted
     */
    deleteBackofficeVendor: {
      request: ({
        vendorId,
        params = {},
      }: {
        vendorId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteBackofficeVendorData, any>({
          path: `/backoffice/vendors/${vendorId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/backoffice/vendors/:vendorId` as const,
    },

    /**
     * No description
     *
     * @tags backoffice
     * @name GetBackofficeVendorUsers
     * @summary Get vendor users for Backoffice
     * @request GET:/backoffice/vendors/{vendorId}/users
     * @secure
     * @response `200` `ModelGetBackofficeVendorUsersData` List of vendor users
     */
    getBackofficeVendorUsers: {
      request: ({
        pathAndQueryParams: { vendorId, ...query },
        params = {},
      }: {
        pathAndQueryParams: ModelGetBackofficeVendorUsersParams
        params?: RequestParams
      }) =>
        this.request<ModelGetBackofficeVendorUsersData, any>({
          path: `/backoffice/vendors/${vendorId}/users`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/vendors/:vendorId/users` as const,
    },

    /**
 * @description Operation performed after successful document file upload to S3 bucket in order to register it for further processing.
 *
 * @tags backoffice / document versions
 * @name PostDocumentVersion
 * @summary Register new document version file uploaded
 * @request POST:/backoffice/documents/versions
 * @secure
 * @response `201` `ModelPostDocumentVersionData` Contract File successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 */
    postDocumentVersion: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostDocumentVersionPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostDocumentVersionData,
          ModelPostDocumentVersionError
        >({
          path: `/backoffice/documents/versions`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/documents/versions` as const,
    },

    /**
 * @description Creates and returns presigned URL for document upload
 *
 * @tags backoffice / document versions
 * @name GetDocumentUploadUrl
 * @summary Creates and returns presigned URL for document upload
 * @request GET:/backoffice/documents/versions/upload-url
 * @secure
 * @response `200` `ModelGetDocumentUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor with given `vendorId` do not exist
 */
    getDocumentUploadUrl: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetDocumentUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetDocumentUploadUrlData,
          ModelGetDocumentUploadUrlError
        >({
          path: `/backoffice/documents/versions/upload-url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/documents/versions/upload-url` as const,
    },

    /**
 * @description Operation performed after successful agreement file upload to S3 bucket in order to register it for further processing.
 *
 * @tags backoffice / vendor agreements
 * @name PostVendorAgreement
 * @summary Register presigned vendor agreement
 * @request POST:/backoffice/vendors/{vendorId}/agreements
 * @secure
 * @response `201` `ModelPostVendorAgreementData` Agreement file successfully registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 */
    postVendorAgreement: {
      request: ({
        vendorId,
        data,
        params = {},
      }: {
        vendorId: string
        data: ModelPostVendorAgreementPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostVendorAgreementData,
          ModelPostVendorAgreementError
        >({
          path: `/backoffice/vendors/${vendorId}/agreements`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/vendors/:vendorId/agreements` as const,
    },

    /**
 * @description Operation performed after successful agreement file upload to S3 bucket in order to register it for further processing.
 *
 * @tags backoffice / customer agreements
 * @name PostCustomerAgreement
 * @summary Register presigned customer agreement
 * @request POST:/backoffice/customers/{customerId}/agreements
 * @secure
 * @response `201` `ModelPostCustomerAgreementData` Agreement file successfuly registered for processing
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body, the file is not present, unsupported file type.
 */
    postCustomerAgreement: {
      request: ({
        customerId,
        data,
        params = {},
      }: {
        customerId: string
        data: ModelPostCustomerAgreementPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostCustomerAgreementData,
          ModelPostCustomerAgreementError
        >({
          path: `/backoffice/customers/${customerId}/agreements`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/customers/:customerId/agreements` as const,
    },

    /**
 * @description Creates and returns presigned URL for company agreement upload
 *
 * @tags backoffice / company agreements
 * @name GetCompanyAgreementUploadUrl
 * @summary Creates and returns presigned URL for company agreement upload
 * @request GET:/backoffice/companies/{companyId}/agreements/upload-url
 * @secure
 * @response `200` `ModelGetCompanyAgreementUploadUrlData` Created presigned upload URL
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    getCompanyAgreementUploadUrl: {
      request: ({
        pathAndQueryParams: { companyId, ...query },
        params = {},
      }: {
        pathAndQueryParams: ModelGetCompanyAgreementUploadUrlParams
        params?: RequestParams
      }) =>
        this.request<
          ModelGetCompanyAgreementUploadUrlData,
          ModelGetCompanyAgreementUploadUrlError
        >({
          path: `/backoffice/companies/${companyId}/agreements/upload-url`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/companies/:companyId/agreements/upload-url` as const,
    },

    /**
 * @description Adds VendorEvaluationStatus for the given `{vendorId}`
 *
 * @tags backoffice / vendor contract details
 * @name PostVendorEvaluationStatus
 * @summary Create new VendorEvaluationStatus
 * @request POST:/backoffice/vendors/{vendorId}/contract-details/evaluation-status
 * @secure
 * @response `201` `ModelPostVendorEvaluationStatusData` VendorEvaluationStatus created successfully.
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body.
 */
    postVendorEvaluationStatus: {
      request: ({
        vendorId,
        data,
        params = {},
      }: {
        vendorId: string
        data: ModelPostVendorEvaluationStatusPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostVendorEvaluationStatusData,
          ModelPostVendorEvaluationStatusError
        >({
          path: `/backoffice/vendors/${vendorId}/contract-details/evaluation-status`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/vendors/:vendorId/contract-details/evaluation-status` as const,
    },

    /**
     * No description
     *
     * @tags backoffice / vendor requests
     * @name GetBackofficeVendorRequests
     * @summary Returns a list of vendor requests
     * @request GET:/backoffice/vendor-requests
     * @secure
     * @response `200` `ModelGetBackofficeVendorRequestsData` List of vendor requests
     */
    getBackofficeVendorRequests: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetBackofficeVendorRequestsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetBackofficeVendorRequestsData, any>({
          path: `/backoffice/vendor-requests`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/vendor-requests` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / vendor requests
 * @name GetBackofficeVendorRequest
 * @summary Returns a details of specific vendor request
 * @request GET:/backoffice/vendor-requests/{vendorRequestId}
 * @secure
 * @response `200` `ModelGetBackofficeVendorRequestData` Vendor request details
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Vendor request given `{vendorRequestId}` does not exist
 */
    getBackofficeVendorRequest: {
      request: ({
        vendorRequestId,
        params = {},
      }: {
        vendorRequestId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetBackofficeVendorRequestData,
          ModelGetBackofficeVendorRequestError
        >({
          path: `/backoffice/vendor-requests/${vendorRequestId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/vendor-requests/:vendorRequestId` as const,
    },

    /**
     * No description
     *
     * @tags backoffice / vendor requests
     * @name DeleteMarketRelationship
     * @summary Deletes relationship
     * @request DELETE:/backoffice/vendor-requests/{vendorRequestId}
     * @secure
     * @response `204` `ModelDeleteMarketRelationshipData` Relationship successfully deleted
     */
    deleteMarketRelationship: {
      request: ({
        vendorRequestId,
        params = {},
      }: {
        vendorRequestId: string
        params?: RequestParams
      }) =>
        this.request<ModelDeleteMarketRelationshipData, any>({
          path: `/backoffice/vendor-requests/${vendorRequestId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/backoffice/vendor-requests/:vendorRequestId` as const,
    },

    /**
     * No description
     *
     * @tags backoffice / erp routing groups
     * @name GetBackofficeErpRoutingGroups
     * @summary Returns a list of erp order routing groups
     * @request GET:/backoffice/erp-routing-groups
     * @secure
     * @response `200` `ModelGetBackofficeErpRoutingGroupsData` List of erp order routing groups
     */
    getBackofficeErpRoutingGroups: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetBackofficeErpRoutingGroupsParams
        params?: RequestParams
      }) =>
        this.request<ModelGetBackofficeErpRoutingGroupsData, any>({
          path: `/backoffice/erp-routing-groups`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/erp-routing-groups` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / erp routing groups
 * @name PostBackofficeErpRoutingGroups
 * @summary Create an erp order routing group
 * @request POST:/backoffice/erp-routing-groups
 * @secure
 * @response `201` `ModelPostBackofficeErpRoutingGroupsData` Erp order routing group successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Erp order routing group with provided name already exists or a member is already assigned
 */
    postBackofficeErpRoutingGroups: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostBackofficeErpRoutingGroupsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostBackofficeErpRoutingGroupsData,
          ModelPostBackofficeErpRoutingGroupsError
        >({
          path: `/backoffice/erp-routing-groups`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/erp-routing-groups` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / erp routing groups
 * @name PutBackofficeErpRoutingGroup
 * @summary Update an erp order routing group
 * @request PUT:/backoffice/erp-routing-groups/{groupId}
 * @secure
 * @response `200` `ModelPutBackofficeErpRoutingGroupData` Erp order routing group successfully updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided erp routing group id does not exist
 * @response `409` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Erp order routing group with provided name already exists or a member is already assigned
 */
    putBackofficeErpRoutingGroup: {
      request: ({
        groupId,
        data,
        params = {},
      }: {
        groupId: string
        data: ModelPutBackofficeErpRoutingGroupPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutBackofficeErpRoutingGroupData,
          ModelPutBackofficeErpRoutingGroupError
        >({
          path: `/backoffice/erp-routing-groups/${groupId}`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/erp-routing-groups/:groupId` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / erp routing groups
 * @name DeleteBackofficeErpRoutingGroup
 * @summary Delete an erp order routing group
 * @request DELETE:/backoffice/erp-routing-groups/{groupId}
 * @secure
 * @response `204` `ModelDeleteBackofficeErpRoutingGroupData` Erp order routing group successfully deleted
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided erp routing group id does not exist
 */
    deleteBackofficeErpRoutingGroup: {
      request: ({
        groupId,
        params = {},
      }: {
        groupId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteBackofficeErpRoutingGroupData,
          ModelDeleteBackofficeErpRoutingGroupError
        >({
          path: `/backoffice/erp-routing-groups/${groupId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/backoffice/erp-routing-groups/:groupId` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / erp routing groups
 * @name GetBackofficeErpRoutingGroupAssignments
 * @summary Returns a list of erp order routing group members
 * @request GET:/backoffice/erp-routing-groups/{groupId}/assignments
 * @secure
 * @response `200` `ModelGetBackofficeErpRoutingGroupAssignmentsData` List of erp order routing group members
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Provided erp routing group id does not exist
 */
    getBackofficeErpRoutingGroupAssignments: {
      request: ({
        groupId,
        params = {},
      }: {
        groupId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetBackofficeErpRoutingGroupAssignmentsData,
          ModelGetBackofficeErpRoutingGroupAssignmentsError
        >({
          path: `/backoffice/erp-routing-groups/${groupId}/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/erp-routing-groups/:groupId/assignments` as const,
    },

    /**
     * No description
     *
     * @tags backoffice / erp routing groups
     * @name GetBackofficeErpRoutingGroupAllAssignments
     * @summary Returns a list of erp order routing group members for all groups
     * @request GET:/backoffice/erp-routing-groups/all/assignments
     * @secure
     * @response `200` `ModelGetBackofficeErpRoutingGroupAllAssignmentsData` List of erp order routing groups members
     */
    getBackofficeErpRoutingGroupAllAssignments: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetBackofficeErpRoutingGroupAllAssignmentsData, any>({
          path: `/backoffice/erp-routing-groups/all/assignments`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/erp-routing-groups/all/assignments` as const,
    },

    /**
     * No description
     *
     * @tags backoffice / product categories
     * @name GetBackofficeProductCategories
     * @summary Returns a list of product categories
     * @request GET:/backoffice/product-categories
     * @secure
     * @response `200` `ModelGetBackofficeProductCategoriesData` List of product categories
     */
    getBackofficeProductCategories: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetBackofficeProductCategoriesData, any>({
          path: `/backoffice/product-categories`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/product-categories` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / product categories
 * @name PostBackofficeProductCategories
 * @summary Create a new Product Category
 * @request POST:/backoffice/product-categories
 * @secure
 * @response `201` `ModelPostBackofficeProductCategoriesData` Product Category successfully added
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error - invalid request body
 */
    postBackofficeProductCategories: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPostBackofficeProductCategoriesPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostBackofficeProductCategoriesData,
          ModelPostBackofficeProductCategoriesError
        >({
          path: `/backoffice/product-categories`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/product-categories` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / product categories
 * @name PutBackofficeProductCategoryPosition
 * @summary Updates the product categories position
 * @request PUT:/backoffice/product-categories/reposition
 * @secure
 * @response `204` `ModelPutBackofficeProductCategoryPositionData` Product Categories order updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 */
    putBackofficeProductCategoryPosition: {
      request: ({
        data,
        params = {},
      }: {
        data: ModelPutBackofficeProductCategoryPositionPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPutBackofficeProductCategoryPositionData,
          ModelPutBackofficeProductCategoryPositionError
        >({
          path: `/backoffice/product-categories/reposition`,
          method: 'PUT',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/product-categories/reposition` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / product categories
 * @name GetBackofficeProductCategory
 * @summary Returns the product category identified by the productCategoryId
 * @request GET:/backoffice/product-categories/{productCategoryId}
 * @secure
 * @response `200` `ModelGetBackofficeProductCategoryData` Product Category definition
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Product Category with given `productCategoryId` does not exist
 */
    getBackofficeProductCategory: {
      request: ({
        productCategoryId,
        params = {},
      }: {
        productCategoryId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelGetBackofficeProductCategoryData,
          ModelGetBackofficeProductCategoryError
        >({
          path: `/backoffice/product-categories/${productCategoryId}`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/backoffice/product-categories/:productCategoryId` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / product categories
 * @name PatchBackofficeProductCategory
 * @summary Updates the product category identified by the productCategoryId
 * @request PATCH:/backoffice/product-categories/{productCategoryId}
 * @secure
 * @response `204` `ModelPatchBackofficeProductCategoryData` Product Category updated
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Product Category with given `productCategoryId` do not exist
 */
    patchBackofficeProductCategory: {
      request: ({
        productCategoryId,
        data,
        params = {},
      }: {
        productCategoryId: string
        data: ModelPatchBackofficeProductCategoryPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPatchBackofficeProductCategoryData,
          ModelPatchBackofficeProductCategoryError
        >({
          path: `/backoffice/product-categories/${productCategoryId}`,
          method: 'PATCH',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/product-categories/:productCategoryId` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / product categories
 * @name DeleteBackofficeProductCategory
 * @summary Removes the product category identified by the productCategoryId
 * @request DELETE:/backoffice/product-categories/{productCategoryId}
 * @secure
 * @response `204` `ModelDeleteBackofficeProductCategoryData` Product Category removed
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Product Category with given `productCategoryId` do not exist
 */
    deleteBackofficeProductCategory: {
      request: ({
        productCategoryId,
        params = {},
      }: {
        productCategoryId: string
        params?: RequestParams
      }) =>
        this.request<
          ModelDeleteBackofficeProductCategoryData,
          ModelDeleteBackofficeProductCategoryError
        >({
          path: `/backoffice/product-categories/${productCategoryId}`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/backoffice/product-categories/:productCategoryId` as const,
    },

    /**
 * No description
 *
 * @tags backoffice / product categories
 * @name PostBackofficeProductCategorySubcategoryEClassAssignments
 * @summary Assign eClass nodes to a subcategory of a product category
 * @request POST:/backoffice/product-categories/{productCategoryId}/subcategories/{subcategoryId}/assignments
 * @secure
 * @response `200` `ModelPostBackofficeProductCategorySubcategoryEClassAssignmentsData` Selected eClass Nodes have been assigned
 * @response `400` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Validation error
 * @response `404` `{
  \**
   * Message providing details of the error
   * @example "Validation failure"
   *\
    message?: string,
  \** List of error detail for each field, if the error is related to a particular form field otherwise null *\
    details?: ({
  \**
   * Name of the field that violates the constraint
   * @example "person.firstName"
   *\
    field?: string,
  \**
   * Error message that states the reason why the attached field is invalid
   * @example "must not be blank"
   *\
    message?: string,

})[],
  \** List of additional data information that can be provided *\
    additionalData?: ({
  \**
   * Name of the property
   * @example "contactPerson"
   *\
    label?: string,
  \**
   * Value of the property
   * @example "example@gmail.com"
   *\
    value?: string,

})[],
  \**
   * Timestamp at which the error occurred. Formatted according to [RFC 3339, section 5.6](https://tools.ietf.org/html/rfc3339#section-5.6).
   * @format date-time
   * @example "2021-10-25T11:10:57.849+00:00"
   *\
    timestamp?: string,

}` Missing Product Category or Subcategory
 */
    postBackofficeProductCategorySubcategoryEClassAssignments: {
      request: ({
        productCategoryId,
        subcategoryId,
        data,
        params = {},
      }: {
        productCategoryId: string
        subcategoryId: string
        data: ModelPostBackofficeProductCategorySubcategoryEClassAssignmentsPayload
        params?: RequestParams
      }) =>
        this.request<
          ModelPostBackofficeProductCategorySubcategoryEClassAssignmentsData,
          ModelPostBackofficeProductCategorySubcategoryEClassAssignmentsError
        >({
          path: `/backoffice/product-categories/${productCategoryId}/subcategories/${subcategoryId}/assignments`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          ...params,
        }),
      url: `/backoffice/product-categories/:productCategoryId/subcategories/:subcategoryId/assignments` as const,
    },
  }
  documents = {
    /**
     * No description
     *
     * @tags vendors / document versions
     * @name GetVendorCurrentDocuments
     * @summary Retrieve all current document versions per country of operation available to vendors
     * @request GET:/documents/vendor/versions
     * @secure
     * @response `200` `ModelGetVendorCurrentDocumentsData` all current document versions per country of operation available to vendors
     */
    getVendorCurrentDocuments: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetVendorCurrentDocumentsData, any>({
          path: `/documents/vendor/versions`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/documents/vendor/versions` as const,
    },

    /**
     * No description
     *
     * @tags customers / document versions
     * @name GetCustomerCurrentDocuments
     * @summary Retrieve all current document versions per country of operation available to customers
     * @request GET:/documents/customer/versions
     * @secure
     * @response `200` `ModelGetCustomerCurrentDocumentsData` all current document versions per country of operation available to customers
     */
    getCustomerCurrentDocuments: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelGetCustomerCurrentDocumentsData, any>({
          path: `/documents/customer/versions`,
          method: 'GET',
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/documents/customer/versions` as const,
    },
  }
  internal = {
    /**
     * @description It's intented only to be used on dev environment for e2e tests. Backoffice role is required. A current list of email templates can be found at: https://github.com/simple-system/next-gen/blob/develop/backend/src/main/java/com/simplesystem/nextgen/backend/common/configuration/EmailTemplate.java
     *
     * @tags internal
     * @name GetEmail
     * @summary Returns a last email sent to a provided recipient address.
     * @request GET:/internal/email-outbox
     * @secure
     * @response `200` `ModelGetEmailData` Last matched email
     */
    getEmail: {
      request: ({
        query,
        params = {},
      }: {
        query: ModelGetEmailParams
        params?: RequestParams
      }) =>
        this.request<ModelGetEmailData, any>({
          path: `/internal/email-outbox`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }),
      url: `/internal/email-outbox` as const,
    },

    /**
     * No description
     *
     * @tags internal
     * @name ClearCache
     * @summary Invalidate all application caches.
     * @request DELETE:/internal/cache
     * @secure
     * @response `204` `ModelClearCacheData` Cache cleared
     */
    clearCache: {
      request: ({ params = {} }: { params?: RequestParams } = {}) =>
        this.request<ModelClearCacheData, any>({
          path: `/internal/cache`,
          method: 'DELETE',
          secure: true,
          ...params,
        }),
      url: `/internal/cache` as const,
    },
  }
}
