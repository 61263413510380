import { Box, Button as ButtonBase, forwardRef } from '@chakra-ui/react'

import { includes } from 'types/utils'

import { Typography } from 'app/components/Typography'

import type { ButtonProps } from '../types'

const smallSizes: ButtonProps['size'][] = ['sm', 'squareSm']

// forwardRef needed for proper types inside styled()(...)
export const ButtonStyledComponent = forwardRef<ButtonProps, 'button'>(
  (
    {
      isTallOnMobile,
      isFullWidth,
      width = 'fit-content',
      height = 'fit-content',
      children,
      leftIcon,
      rightIcon,
      iconSpacing = '0.5rem',
      whiteSpace = 'pre-line',
      isTextFullWidth,
      isFlatLeft,
      isFlatRight,
      isWithoutBorder,
      dataTestId,
      labelProps,
      flexShrink = 0,
      ...props
    },
    ref,
  ) => {
    // Handle centering Button with only icon
    // https://simplesystem.atlassian.net/browse/S2NG-6420
    const noOfLines = includes(smallSizes, props.size) ? 1 : 2

    return (
      <ButtonBase
        {...(isFullWidth ? { width: 'full' } : { width })}
        maxW={isFullWidth ? undefined : '240px'}
        height={height}
        flexShrink={isFullWidth ? undefined : flexShrink}
        {...(whiteSpace === 'nowrap' && { flexShrink: 0 })}
        ref={ref}
        {...props}
      >
        {!!leftIcon && (
          <Box as="span" display="inline-flex" flexShrink={0} mr={iconSpacing}>
            {leftIcon}
          </Box>
        )}

        {children && (
          <Typography
            noOfLines={noOfLines}
            whiteSpace={whiteSpace}
            color="currentColor"
            as="span"
            {...(noOfLines === 1 && { textAlign: 'start' })}
            {...(whiteSpace === 'nowrap' && { overflow: 'visible' })}
            {...labelProps}
          >
            {children}
          </Typography>
        )}

        {!!rightIcon && (
          <Box as="span" display="inline-flex" flexShrink={0} ml={iconSpacing}>
            {rightIcon}
          </Box>
        )}
      </ButtonBase>
    )
  },
)
