import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Input } from 'app/components/inputs/Input'

const messages = translations.formFields.customerNumber

type VendorSpecificCustomerNumberProps = {
  isOptional?: boolean
}

export const VendorSpecificCustomerNumber = ({
  isOptional,
}: VendorSpecificCustomerNumberProps) => {
  const { t } = useTranslation()

  return (
    <Input
      name="vendorSpecificCustomerNumber"
      label={t(messages.label.required)}
      placeholder={t(messages.placeholder)}
      isOptional={isOptional}
    />
  )
}
