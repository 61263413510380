import { http, HttpResponse } from 'msw'

import { getVendorMockUrl } from 'app/containers/vendors/utils'
import { createSingleCustomerAssignmentData } from 'app/models/customerAssignment'
import type { CustomerId } from 'app/models/user'

import { url } from './api'
import type { API__CatalogCustomersAssignmentsResponse } from './types'

export const getMockCustomerAssignmentsStaticData = ({
  withMissingVendorSpecificCustomerNumbers,
}: {
  withMissingVendorSpecificCustomerNumbers: boolean
}): API__CatalogCustomersAssignmentsResponse => ({
  customers: [
    {
      id: 'iojdf-09234-sdf' as CustomerId,
      name: 'testCompany',
      assigned: false,
      streetName: 'Test Steet name',
      streetNumber: '14',
      postalCode: '892347',
      city: 'Berlin',
      countryCode: 'DE',
      currency: 'GBP',
      vendorSpecificCustomerNumber: withMissingVendorSpecificCustomerNumbers
        ? null
        : '96sdf447',
    },
    {
      id: 'iojdf-984541-sdf' as CustomerId,
      name: 'testCompany2',
      assigned: false,
      streetName: 'Test Steet 2 name',
      streetNumber: '19',
      postalCode: '98347',
      city: 'Munich',
      countryCode: 'DE',
      currency: 'GBP',
      vendorSpecificCustomerNumber: withMissingVendorSpecificCustomerNumbers
        ? null
        : '98356573',
    },
    {
      id: 'iskmgf-984565-sdf' as CustomerId,
      name: 'testCompany3',
      assigned: false,
      streetName: 'Test Steet 3 name',
      streetNumber: '20',
      postalCode: '98897',
      city: 'Munich',
      countryCode: 'DE',
      currency: 'GBP',
      vendorSpecificCustomerNumber: '1916',
    },
    {
      id: 'iojdf-984541fg-sdf' as CustomerId,
      name: 'testCompany4',
      assigned: false,
      streetName: 'Test Steet 4 name',
      streetNumber: '194',
      postalCode: '98344',
      city: 'Munich',
      countryCode: 'DE',
      currency: 'GBP',
      vendorSpecificCustomerNumber: '9573',
    },
    {
      id: 'iojdf-071ere4-sdf' as CustomerId,
      name: 'testCompany5',
      assigned: false,
      streetName: 'Test Steet name5',
      streetNumber: '145',
      postalCode: '89235',
      city: 'Berlin',
      countryCode: 'DE',
      currency: 'GBP',
      vendorSpecificCustomerNumber: '9647126',
    },
    {
      id: 'iojdf-4were4-sdf' as CustomerId,
      name: 'testCompany6',
      assigned: false,
      streetName: 'Test Steet name6',
      streetNumber: '164',
      postalCode: '86647',
      city: 'Berlin',
      countryCode: 'DE',
      currency: 'GBP',
      vendorSpecificCustomerNumber: '964516',
    },
    {
      id: 'iojdf-984541-vzs' as CustomerId,
      name: 'testCompany7',
      assigned: false,
      streetName: 'Test Steet 2 name',
      streetNumber: '19',
      postalCode: '98347',
      city: 'Munich',
      countryCode: 'DE',
      currency: 'PLN',
      vendorSpecificCustomerNumber: '98356573',
    },
  ],
})

export const customerAssignments: API__CatalogCustomersAssignmentsResponse = {
  customers: Array.from(Array(10)).map(() =>
    createSingleCustomerAssignmentData(),
  ),
}

export const catalogCustomerAssignmentsGetMock = http.get(
  getVendorMockUrl(url),
  () => HttpResponse.json(customerAssignments),
)

export const catalogCustomerAssignmentsDataTwoMissingCustomerNumbersGetMock =
  http.get(getVendorMockUrl(url), () =>
    HttpResponse.json(
      getMockCustomerAssignmentsStaticData({
        withMissingVendorSpecificCustomerNumbers: true,
      }),
    ),
  )

export const catalogCustomerAssignmentsDataWithCustomerNumberGetMock = http.get(
  getVendorMockUrl(url),
  () =>
    HttpResponse.json(
      getMockCustomerAssignmentsStaticData({
        withMissingVendorSpecificCustomerNumbers: false,
      }),
    ),
)

export const makeAssignCustomersToCatalogPostMockWithSpy = (spyFn: jest.Mock) =>
  http.post(getVendorMockUrl(url), async ({ request }) => {
    spyFn(await request.json())

    return new HttpResponse(null, { status: 200 })
  })
