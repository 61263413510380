import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'

import { ModalWithFooter } from 'app/components/modals/ModalWithFooter'
import { Typography } from 'app/components/Typography'

import { GLOBAL_MODALS_IDS } from './consts'

const messages = translations.globalModals.accountAlreadyVerified

export const AccountAlreadyVerifiedModal = NiceModal.create(() => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <ModalWithFooter
      id={GLOBAL_MODALS_IDS.accountAlreadyActivated}
      header={t(messages.header)}
      saveTranslation={t(messages.goToLoginButton)}
      onSaveHandler={() => history.push(PATHS.root)}
    >
      <Typography textStyle="textParagraph">{t(messages.body)}</Typography>
    </ModalWithFooter>
  )
})

export const showAccountAlreadyVerifiedModal = () =>
  NiceModal.show(GLOBAL_MODALS_IDS.accountAlreadyActivated)
