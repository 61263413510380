// INFO: Thanks to
// https://github.com/markjaniczak/symbology-scanner/blob/1cc158d42a9df434bc879fd6a555081f94716f40/packages/core/src/Controller.ts
// This helper function is used in useScanDetection to detect modifier keys so we can ignore them

export const isModifierKey = (event: KeyboardEvent) => {
  if (event.shiftKey && event.key === 'Shift') return true

  if (event.ctrlKey && event.key === 'Control') return true

  if (event.altKey && event.key === 'Alt') return true

  if (event.metaKey && event.key === 'Meta') return true

  return false
}
