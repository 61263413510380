import { useEffect } from 'react'
// INFO: We can't use the useDebounce from the react-use package
// because there is no option to flush the pending callbacks
import { useDebouncedCallback } from 'use-debounce'

import type { AmountHandlersHelperHooksCommonProps } from './types'
import { MAX_INPUT_VALUE, DEBOUNCED_NETWORK_CALL_TIME } from './consts'

type PlusMinusButtonHandlersHookProps = AmountHandlersHelperHooksCommonProps & {
  quantityMinimum: number
  quantityInterval: number
  isDeleting: boolean
}

export const usePlusMinusButtonHandlers = ({
  isDeleting,
  parsedValue,
  quantityMinimum,
  quantityInterval,
  updateHandler,
  deleteHandler,
  setShownValue,
  onNetworkError,
}: PlusMinusButtonHandlersHookProps) => {
  const minusHandlers = {
    internalState: () => {
      if (plusHandlers.network.isPending()) plusHandlers.network.cancel() // INFO: keep network debounced when clicking + and - alternately

      const newValue = parsedValue - quantityInterval

      if (newValue < quantityMinimum) return setShownValue(0)

      setShownValue(newValue)
    },
    network: useDebouncedCallback(() => {
      if (parsedValue === 0) return deleteHandler(onNetworkError)

      updateHandler(parsedValue, onNetworkError)
    }, DEBOUNCED_NETWORK_CALL_TIME),
  }

  const plusHandlers = {
    internalState: () => {
      if (minusHandlers.network.isPending()) minusHandlers.network.cancel() // INFO: keep network debounced when clicking + and - alternately

      const newValue = parsedValue + quantityInterval

      if (newValue <= MAX_INPUT_VALUE) setShownValue(newValue) // add only if less than max value
    },
    network: useDebouncedCallback(() => {
      if (parsedValue <= MAX_INPUT_VALUE)
        updateHandler(parsedValue, onNetworkError) // add only if less than max value
    }, DEBOUNCED_NETWORK_CALL_TIME),
  }

  // When item is being deleted, cancel minus and plus callbacks
  useEffect(() => {
    if (isDeleting) {
      minusHandlers.network.cancel()
      plusHandlers.network.cancel()
    }
  }, [isDeleting, minusHandlers.network, plusHandlers.network])

  // When the component goes to be unmounted, we will perform the mutation
  useEffect(
    () => () => {
      minusHandlers.network.flush()
      plusHandlers.network.flush()
    },
    [minusHandlers.network, plusHandlers.network],
  )

  return {
    minusHandlers,
    plusHandlers,
  }
}
