import { PATHS } from 'consts/paths'

export const getOciSessionId = () => {
  const [, firstPathPart, secondPathPart] = window.location.pathname.split('/')

  const isOciSession =
    firstPathPart === PATHS.customer.ociSessionRoot.split('/')[1]

  return (isOciSession && secondPathPart) || undefined
}
