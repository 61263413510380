import { useUserMeQuery } from './useUserMeQuery'

export const useIsChangeCompanyAvailable = () => {
  const { data: userData } = useUserMeQuery()

  if (!userData) {
    return { isChangeCompanyAvailable: false }
  }

  return {
    isChangeCompanyAvailable:
      userData.customers.length + userData.vendors.length > 1,
  }
}
