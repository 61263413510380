import { api } from 'apiServices'

import { customersScope } from '../consts'

import type { MODEL__CustomersCounts } from './model'
import type { API__GetCustomersCountsRequestArgs } from './api.types'

export const url = `${customersScope}/counts`

export const getCustomersCountsRequest = (
  params: API__GetCustomersCountsRequestArgs,
) =>
  api.get<unknown, MODEL__CustomersCounts>(url, {
    params,
  })
