import type { IconProps } from '../types'

export const MinusIcon = ({
  width = '16px',
  height = '16px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path d="M12.666 7H3.333v2h9.333V7z" fill={color} />
  </svg>
)
