import { generatePath } from 'react-router'
import type { MutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { publicApi } from 'apiServices'

import { useLinkId } from '../useLinkId'
import type { LinkId, MODEL__ApprovalLink } from '../types'
import { approvalLinkPath } from '../consts'

type API__ProcessApprovalLinkRequestParams = Pick<
  MODEL__ApprovalLink,
  'status'
> & {
  linkId: LinkId
}

export const url = approvalLinkPath

const processApprovalLinkRequest = ({
  linkId,
  ...body
}: API__ProcessApprovalLinkRequestParams) =>
  publicApi.put<unknown, MODEL__ApprovalLink>(
    generatePath(url, { linkId }),
    body,
  )

type ProcessApprovalLinkMutationParams = Pick<
  MutationOptions<
    MODEL__ApprovalLink,
    unknown,
    Omit<API__ProcessApprovalLinkRequestParams, 'linkId'>
  >,
  'onError' | 'onSuccess'
>

export const useProcessApprovalLink = (
  options: ProcessApprovalLinkMutationParams,
) => {
  const { linkId } = useLinkId()

  return useMutation({
    mutationFn: (
      params: Omit<API__ProcessApprovalLinkRequestParams, 'linkId'>,
    ) => processApprovalLinkRequest({ linkId, ...params }),
    ...options,
  })
}
