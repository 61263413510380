import type { IconProps } from '../types'

export const CoreIcon = ({
  width = '16px',
  height = '16px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle
      cx={8}
      cy={8}
      r={6}
      transform="rotate(-90 8 8)"
      stroke={color}
      strokeWidth={2}
      strokeDasharray="8 4"
    />
    <circle cx={8} cy={8} r={2} fill={color} />
  </svg>
)
