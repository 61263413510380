import type { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'utils/@reduxjs/toolkit'

import { DELIVERY_ADDRESSES_REDUCER_NAME } from './consts'
import type {
  ManagedDeliveryAddressId,
  MODEL__ManagedDeliveryAddress,
} from './types'

export type DeliveryAddressesState = {
  temporaryDeliveryAddresses: MODEL__ManagedDeliveryAddress[]
  selectedDeliveryAddress?: MODEL__ManagedDeliveryAddress
  customizedDeliveryAddresses: Record<
    ManagedDeliveryAddressId,
    MODEL__ManagedDeliveryAddress
  >
}

export const initialState: DeliveryAddressesState = {
  temporaryDeliveryAddresses: [],
  customizedDeliveryAddresses: {},
}

export const deliveryAddressesSlice = createSlice({
  name: DELIVERY_ADDRESSES_REDUCER_NAME,
  initialState,
  reducers: {
    addTemporaryDeliveryAddress: (
      state,
      action: PayloadAction<MODEL__ManagedDeliveryAddress>,
    ) => {
      state.temporaryDeliveryAddresses.push(action.payload)
    },
    deleteTemporaryDeliveryAddress: (
      state,
      { payload: deletedAddressId }: PayloadAction<ManagedDeliveryAddressId>,
    ) => {
      state.temporaryDeliveryAddresses =
        state.temporaryDeliveryAddresses.filter(
          ({ id }) => id !== deletedAddressId,
        )
    },
    replaceSelectedDeliveryAddressIfSelected: (
      state,
      {
        payload: { deliveryAddressId, newSelectedDeliveryAddress },
      }: PayloadAction<{
        deliveryAddressId: ManagedDeliveryAddressId
        newSelectedDeliveryAddress: MODEL__ManagedDeliveryAddress | undefined
      }>,
    ) => {
      if (state.selectedDeliveryAddress?.id === deliveryAddressId) {
        state.selectedDeliveryAddress = newSelectedDeliveryAddress
      }
    },
    updateTemporaryDeliveryAddress: (
      state,
      action: PayloadAction<MODEL__ManagedDeliveryAddress>,
    ) => {
      const addressIndex = state.temporaryDeliveryAddresses.findIndex(
        ({ id }) => id === action.payload.id,
      )

      if (addressIndex >= 0) {
        state.temporaryDeliveryAddresses[addressIndex] = action.payload
      }
    },
    setSelectedDeliveryAddress: (
      state,
      action: PayloadAction<MODEL__ManagedDeliveryAddress>,
    ) => {
      state.selectedDeliveryAddress = action.payload
    },

    setCustomizedDeliveryAddress: (
      state,
      action: PayloadAction<MODEL__ManagedDeliveryAddress>,
    ) => {
      state.customizedDeliveryAddresses[action.payload.id] = action.payload
    },

    deleteCustomizedDeliveryAddress: (
      state,
      action: PayloadAction<ManagedDeliveryAddressId>,
    ) => {
      delete state.customizedDeliveryAddresses[action.payload]
    },

    clearDeliveryAddressStore: () => initialState,
  },
})

export const {
  addTemporaryDeliveryAddress,
  updateTemporaryDeliveryAddress,
  setSelectedDeliveryAddress,
  deleteTemporaryDeliveryAddress,
  replaceSelectedDeliveryAddressIfSelected,
  setCustomizedDeliveryAddress,
  deleteCustomizedDeliveryAddress,
  clearDeliveryAddressStore,
} = deliveryAddressesSlice.actions

export const { reducer: deliveryAddressesReducer } = deliveryAddressesSlice
