import type { SyntheticEvent, CSSProperties } from 'react'
import styled from '@emotion/styled'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { Row } from 'react-table'

import { TrStyled } from '../styles'
import { useGetters } from '../utils'
import type { TableProps } from '../types'
import { textStyles } from '../../Typography'

export const TdStyled = styled(
  ({
    isActive,
    ...rest
  }: BoxProps & {
    isActive?: boolean
  }) => <Box {...rest} />,
)(({ isActive, theme: { colors } }) => ({
  ...(isActive && { marginLeft: '-4px' }),
  ...textStyles.inputNormal,
  color: colors.contentA,
  wordBreak: 'break-word',
}))

export type TableRowProps<T extends object> = {
  row: Row<T>
  prepareRow: (row: Row<T>) => void
  isActive: boolean
  index: number
  customClassName?: string
  style?: CSSProperties
  rowTestId?: string
} & Pick<TableProps<T>, 'additionalRowPropsGetter' | 'rowItemsAlignment'>

export const TableRow = <T extends object>({
  row,
  prepareRow,
  additionalRowPropsGetter,
  isActive,
  index,
  rowItemsAlignment = 'start',
  customClassName,
  style,
  rowTestId,
}: TableRowProps<T>) => {
  const { getCellProps } = useGetters<T>({ rowItemsAlignment })

  prepareRow(row)

  const additionRowPropsCollection = additionalRowPropsGetter?.(row.original)
  const { onClick, style: additionalStyle } = additionRowPropsCollection ?? {}

  const onRowClickHandler = onClick
    ? (event: SyntheticEvent<HTMLElement>) => onClick(index, event)
    : undefined

  const { style: rowStyle, key: rowKey, ...rowProps } = row.getRowProps()

  return (
    <TrStyled
      key={rowKey}
      data-testid={rowTestId ?? 'tableRow'}
      {...rowProps}
      onClick={onRowClickHandler}
      isActive={isActive}
      style={{ ...rowStyle, ...style, ...additionalStyle }}
      className={customClassName}
    >
      {row.cells.map((cell, tdIndex) => {
        const { key: cellKey, ...cellProps } = cell.getCellProps(getCellProps)

        return (
          <TdStyled
            key={cellKey}
            {...cellProps}
            data-testid="tableCell"
            isActive={isActive && tdIndex === 0}
          >
            {cell.render('Cell')}
          </TdStyled>
        )
      })}
    </TrStyled>
  )
}
