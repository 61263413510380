import { LINKS } from 'consts/envVars'
import type { AvailableLanguages } from 'locales/types'

import { useLocale } from 'app/hooks/useLocale'

type AvailableLinkLocale = keyof typeof LINKS

const getIsLocaleIsInLinks = (
  locale: AvailableLanguages,
): locale is AvailableLinkLocale => Object.keys(LINKS).includes(locale)

export const useLanguageDependantDocuments = () => {
  const { currentLocale } = useLocale()

  return getIsLocaleIsInLinks(currentLocale) ? LINKS[currentLocale] : LINKS.en
}
