import { Button } from 'app/components/Buttons/Button'
import { XIcon } from 'app/components/icons'

type CloseButtonProps = {
  onCloseComplete: () => void
}

export const CloseButton = ({ onCloseComplete }: CloseButtonProps) => (
  <Button
    onClick={onCloseComplete}
    variant="tertiary"
    size="squareMd"
    leftIcon={<XIcon />}
    data-testid="modalXButton"
    mobileIconOnly
  />
)
