import styled from '@emotion/styled'
import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

export const CustomerNavWrapper = styled(Flex)<FlexProps>(
  ({ theme: { mediaQuery, zIndexes } }) => ({
    alignItems: 'center',
    columnGap: '40px',
    rowGap: '8px',
    padding: '12px 32px',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: zIndexes.header,

    [mediaQuery.large]: {
      columnGap: '48px',
    },
  }),
)

export const CustomerNavTabsWrapper = styled(Flex)<FlexProps>({
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0 32px',
  alignSelf: 'center',
  width: '100%',
})

export const HeaderActionsWrapper = styled(Flex)(
  ({ theme: { mediaQuery } }) => ({
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: '8px',
    [mediaQuery.large]: {
      flex: 1,
    },
  }),
)

export const LogoWrapper = styled(Flex)(({ theme: { mediaQuery } }) => ({
  [mediaQuery.large]: {
    flex: 1,
  },
}))
