import { CheckIcon } from '@chakra-ui/icons'
import { CircularProgressLabel } from '@chakra-ui/react'
import { closeSnackbar } from 'notistack'
import { useSpinDelay } from 'spin-delay'

import type { CodeScanActions, MobileScannerProps } from '../types'

import {
  CircleButton,
  PositioningWrapper,
  StyledCircularProgress,
} from './styles'

type ProgressButtonProps = Pick<
  CodeScanActions,
  'resumeScanning' | 'isProgressButtonShown' | 'setIsProgressButtonShown'
> &
  Pick<MobileScannerProps, 'isScanPending' | 'delayAfterSuccessfulScan'>

const SCAN_PENDING_PROGRESS_DELAY = 500
const DELAYED_SCAN_PENDING_PROGRESS_MIN_DURATION = 700

export const ProgressButton = ({
  resumeScanning,
  isScanPending,
  isProgressButtonShown,
  setIsProgressButtonShown,
  delayAfterSuccessfulScan,
}: ProgressButtonProps) => {
  const isScanPendingDelayedVisible = useSpinDelay(!!isScanPending, {
    delay: SCAN_PENDING_PROGRESS_DELAY,
    minDuration: DELAYED_SCAN_PENDING_PROGRESS_MIN_DURATION,
  })

  if (isScanPendingDelayedVisible) {
    return (
      <PositioningWrapper>
        <StyledCircularProgress isIndeterminate />
      </PositioningWrapper>
    )
  }

  if (isProgressButtonShown) {
    return (
      /*
      INFO:
      to avoid manual calculation of circular progress values, we pass `value={100}`,
      so that whole progress line is attached to DOM and then use CSS to show progress animation
      */
      <PositioningWrapper
        delayAfterSuccessfulScan={delayAfterSuccessfulScan}
        withProgressAnimation
      >
        <StyledCircularProgress value={100}>
          <CircularProgressLabel>
            <CircleButton
              onClick={() => {
                setIsProgressButtonShown(false)
                closeSnackbar()
                resumeScanning()
              }}
            >
              <CheckIcon transform="rotate(-270deg)" />
            </CircleButton>
          </CircularProgressLabel>
        </StyledCircularProgress>
      </PositioningWrapper>
    )
  }

  return null
}
