import { IS_TEST } from 'consts/envVars'
import { getConfiguredAxiosInstance } from 'libs/request'
import { withRefreshToken } from 'libs/request/utils/handleUnauthorizedRequest'

import { createDefaultRequestInterceptor } from '../requestInterceptors'

export const apiWithoutCamelised = getConfiguredAxiosInstance({
  withoutCamelisation: true,
})

apiWithoutCamelised.interceptors.request.use(
  createDefaultRequestInterceptor(),
  undefined,
  // INFO: We don't want to run this interceptor for the tests cause we inject the same interceptor manually for the tests with the proper redux store
  { runWhen: () => IS_TEST !== true },
)

withRefreshToken(apiWithoutCamelised)
