import type { IconProps } from '../types'

export const BoxesIcon = ({
  width = 503,
  height = 117,
  ...rest
}: IconProps) => (
  <svg
    viewBox="0 0 503 117"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={width}
    height={height}
    {...rest}
  >
    <path fill="#F5F5F5" d="M30.043 87H0v30.043h30.043V87Z" />
    <path fill="#EBEBEB" d="M48.522 87H30.059v30.043h18.463V87Z" />
    <path
      fill="#E0E0E0"
      d="M37.438 87v11.372l.479-.447.846.543.735-.543.942.543 1.007-.543.638.543.735-.4V87h-5.382Z"
    />
    <path
      fill="#E0E0E0"
      d="M37.438 87v11.372l.479-.447.846.543.735-.543.942.543 1.007-.543.638.543.735-.4V87h-5.382Z"
      opacity={0.7}
    />
    <path fill="#407BFF" d="M423.03 0h-99.66v58.91h99.66V0Z" />
    <path fill="#fff" d="M423.03 0h-99.66v58.91h99.66V0Z" opacity={0.4} />
    <path fill="#fff" d="M366.1 0h-42.73v58.91h42.73V0Z" opacity={0.2} />
    <path fill="#7F97CC" d="M333.17 3.24h-7.09v9.26h7.09V3.24Z" />
    <path fill="#263238" d="M418.99 42.4h-17.04v12.66h17.04V42.4Z" />
    <path
      fill="#407BFF"
      d="M413.4 44.94h-9.25v.55h9.25v-.55ZM408.77 46.84h-4.62v.55h4.62v-.55ZM411.21 48.73h-7.06v.55h7.06v-.55Z"
    />
    <path fill="#79A3FF" d="M434.57 58.53H306v58.91h128.57V58.53Z" />
    <path fill="#fff" d="M377.64 58.53H306v58.91h71.64V58.53Z" opacity={0.2} />
    <path
      fill="#263238"
      d="M344.73 58.53h-12.2v3.91h12.2v-3.91ZM344.73 113.37h-12.2v3.91h12.2v-3.91ZM389.04 61.78h-7.09v9.26h7.09v-9.26ZM397.91 61.78h-7.09v9.26h7.09v-9.26Z"
    />
    <path
      fill="#fff"
      d="m387.42 66.49-1.93-2.69-1.93 2.69h1.22v2.52h1.42v-2.52h1.22ZM396.7 63.84h-2l-.11.48h.34l-.54 1.52.2-1.22h-.4l.09-.78h-2.21a2.56 2.56 0 0 0 2.1 2.66v2.14c-.5.08-.88.37-.88.74h2.23c0-.37-.39-.66-.89-.74V66.5a2.568 2.568 0 0 0 2.07-2.66ZM327.23 97.87h-17.04v12.66h17.04V97.87Z"
    />
    <path
      fill="#407BFF"
      d="M321.64 100.41h-9.25v.55h9.25v-.55ZM317.01 102.31h-4.62v.55h4.62v-.55ZM319.45 104.2h-7.06v.55h7.06v-.55ZM144.907 46H88l10.877 14.279h56.89L144.907 46Z"
    />
    <path
      fill="#fff"
      d="M144.907 46H88l10.877 14.279h56.89L144.907 46Z"
      opacity={0.3}
    />
    <path
      fill="#407BFF"
      d="M166.069 46h34.946l-10.286 14.278h-34.962L166.069 46Z"
    />
    <path
      fill="#fff"
      d="M166.069 46h34.946l-10.286 14.278h-34.962L166.069 46Z"
      opacity={0.1}
    />
    <path fill="#407BFF" d="M155.783 60.278H98.877v56.906h56.906V60.278Z" />
    <path
      fill="#fff"
      d="M155.783 60.278H98.877v56.906h56.906V60.278Z"
      opacity={0.5}
    />
    <path
      fill="#000"
      d="M132.465 60.278h-10.302v12.809h10.302v-12.81ZM132.465 106.901h-10.302v10.286h10.302v-10.286Z"
      opacity={0.2}
    />
    <path fill="#407BFF" d="M190.713 60.278h-34.946v56.906h34.946V60.278Z" />
    <path
      fill="#fff"
      d="M190.713 60.278h-34.946v56.906h34.946V60.278Z"
      opacity={0.3}
    />
    <path
      fill="#fff"
      d="M175.508 113.447v-10.302h-15.7v10.302h15.7Z"
      opacity={0.8}
    />
    <path fill="#F5F5F5" d="M239.756 58h-59.41v59.41h59.41V58Z" />
    <path
      fill="#E6E6E6"
      d="M276.257 58h-36.5v59.41h36.5V58ZM228.41 74.705H169L180.346 58h59.41L228.41 74.705Z"
    />
    <path
      fill="#F5F5F5"
      d="M250.523 74.705H287L276.257 58h-36.5l10.766 16.705ZM484.043 87H454v30.043h30.043V87Z"
    />
    <path fill="#EBEBEB" d="M502.522 87h-18.463v30.043h18.463V87Z" />
    <path
      fill="#E0E0E0"
      d="M491.438 87v11.372l.479-.447.846.543.735-.543.942.543 1.006-.543.639.543.735-.4V87h-5.382Z"
    />
    <path
      fill="#E0E0E0"
      d="M491.438 87v11.372l.479-.447.846.543.735-.543.942.543 1.006-.543.639.543.735-.4V87h-5.382Z"
      opacity={0.7}
    />
  </svg>
)
