import { Redirect, Route, Switch } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { NewPasswordFailure } from 'app/pages/common/resetPasswordFlow/NewPasswordFailure/Loadable'
import { NewPasswordSuccess } from 'app/pages/common/resetPasswordFlow/NewPasswordSuccess/Loadable'
import { NewPassword } from 'app/pages/common/resetPasswordFlow/NewPasword/Loadable'
import { ResetPassword } from 'app/pages/common/resetPasswordFlow/ResetPasword/Loadable'
import { ResetPasswordSuccess } from 'app/pages/common/resetPasswordFlow/ResetPaswordSuccess/Loadable'

export const ResetPasswordRoutes = () => (
  <Switch>
    <Route path={PATHS.passwordReset.passwordReset}>
      <ResetPassword />
    </Route>

    <Route path={PATHS.passwordReset.resetPasswordSuccess}>
      <ResetPasswordSuccess />
    </Route>

    <Route path={PATHS.passwordReset.newPasswordSuccess} exact>
      <NewPasswordSuccess />
    </Route>

    <Route path={PATHS.passwordReset.newPasswordFailure} exact>
      <NewPasswordFailure />
    </Route>

    <Route path={PATHS.passwordReset.newPassword}>
      <NewPassword />
    </Route>

    <Redirect to={PATHS.root} />
  </Switch>
)
