import NiceModal from '@ebay/nice-modal-react'

import { GLOBAL_MODALS_IDS } from './consts'
import { GenericErrorModal } from './GenericErrorModal'
import { CustomerNumberModal } from './CustomerNumberModal'
import { CancelUploadConfirmationModal } from './CancelUploadConfirmationModal'
import { AccountAlreadyVerifiedModal } from './AccountAlreadyVerifiedModal'

NiceModal.register(GLOBAL_MODALS_IDS.genericError, GenericErrorModal)
NiceModal.register(GLOBAL_MODALS_IDS.customerNumber, CustomerNumberModal)
NiceModal.register(
  GLOBAL_MODALS_IDS.cancelUploadConfirmation,
  CancelUploadConfirmationModal,
)
NiceModal.register(
  GLOBAL_MODALS_IDS.accountAlreadyActivated,
  AccountAlreadyVerifiedModal,
)
