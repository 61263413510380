import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { useChangeCartItemsMutation } from 'app/containers/customers/CurrentCart/Items/Create'
import { Tooltip } from 'app/components/tooltips'
import { InMemoryAmountInput } from 'app/pages/common/marketplaceAndSearch/components/AmountInput'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'
import { delay } from 'app/utils/delay'

import { useAddToCartNotifications } from './useAddToCartNotifications'
import { BareAddToCartButton } from './BareAddToCartButton'
import type { AddToCartButtonProps, AddToCartButtonState } from './types'

const messages = translations.pages.customer.search.searchArticle

export const AddToCartButton = ({
  article,
  size,
  marginTop,
  isDisabled,
  color,
  customAddToCartRequestBody,
  displayOnlyButton,
  isInline,
  renderButton = ({ buttonElement }) => buttonElement,
}: AddToCartButtonProps) => {
  const { id: articleId, orderOptions, minimumOrder = 1 } = article
  const [quantity, setQuantity] = useState(minimumOrder)
  const [buttonState, setButtonState] = useState<AddToCartButtonState>('idle')
  const { t } = useTranslation()

  const { mutateAsync: addItemsToCartAsync } = useChangeCartItemsMutation()

  // INFO: When Chakra's button is disabled like <Button isDisabled /> this renders an html <button disabled>
  // and the browser swallows hover events." - from https://github.com/chakra-ui/chakra-ui/issues/500#issuecomment-781605692
  // As a result, trigger="hover" doesn't work properly (tooltip stays open), and we need to manually control its state
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const addToCartNotifications = useAddToCartNotifications()

  if (orderOptions?.restricted) {
    return (
      <ColumnWithGap flexDirection="row" gap={8}>
        {renderButton({
          buttonElement: (
            <Tooltip
              contentPadding="8px 16px"
              contentMaxWidth="320px"
              content={
                <Typography textAlign="center" color="white">
                  {t(translations.addtoCartButton.restrictedTooltipContent)}
                </Typography>
              }
              isOpen={isTooltipOpen}
              wrapperProps={{ width: '100%', minWidth: 0 }}
            >
              <BareAddToCartButton
                isDisabled
                size={size}
                color="currentColor"
                onMouseOver={() => setIsTooltipOpen(true)}
                onMouseOut={() => setIsTooltipOpen(false)}
                state={buttonState}
              />
            </Tooltip>
          ),
        })}
      </ColumnWithGap>
    )
  }

  const addToCartHandler = async () => {
    setButtonState('loading')
    const data = customAddToCartRequestBody ?? [
      { articleId, changeQuantityBy: quantity ?? minimumOrder },
    ]
    try {
      const operations = await addItemsToCartAsync({ data })

      // To simplify the logic, we are assuming that the response
      // will always have a single operation,
      // because we are updating a single article
      if (operations[0]) {
        addToCartNotifications.handleSingleOperation({
          operation: operations[0],
          article: article,
        })
      }

      setQuantity(minimumOrder)

      setButtonState('success')
      await delay(2000)
      setButtonState('idle')
    } catch (error) {
      setButtonState('idle')
    }
  }

  const shouldRenderAmountInput = !displayOnlyButton

  return (
    <ColumnWithGap
      gap={8}
      flexDirection={isInline ? 'row' : 'column'}
      marginTop={marginTop}
      width="100%"
    >
      {shouldRenderAmountInput && (
        <InMemoryAmountInput
          quantity={quantity}
          setQuantity={setQuantity}
          quantityInterval={article.quantityInterval}
          minimumOrder={article.minimumOrder}
          size={size}
          isDisabled={isDisabled}
        />
      )}
      <ColumnWithGap flexDirection="row" gap={8}>
        {renderButton({
          buttonElement: (
            <Tooltip
              isOpen={isInline ? undefined : false}
              wrapperProps={{ width: '100%', minWidth: 0 }}
              content={
                <Typography textAlign="center" color="white">
                  {t(messages.addToBasket)}
                </Typography>
              }
            >
              <BareAddToCartButton
                size={size}
                state={buttonState}
                onClick={addToCartHandler}
                isDisabled={isDisabled}
                color={color ?? 'white'}
                isInline={isInline}
                aria-label={t(messages.addToBasket)}
              />
            </Tooltip>
          ),
        })}
      </ColumnWithGap>
    </ColumnWithGap>
  )
}
