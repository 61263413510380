import { Trans } from 'react-i18next'

import { translations } from 'locales/translations'

import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'

const messages = translations.pages.customer.articleDetails

type CustomerArticleNumberTextProps = {
  customerArticleNumber: string
} & TypographyProps

export const CustomerArticleNumberText = ({
  customerArticleNumber,
  ...rest
}: CustomerArticleNumberTextProps) => (
  <Typography textStyle="textSmall" color="contentB" {...rest}>
    <Trans
      i18nKey={messages.buyerArticleNumber}
      components={[<span />]}
      values={{ number: customerArticleNumber || '—' }}
    />
  </Typography>
)
