import type { CSSProperties, MouseEvent } from 'react'
import type {
  HeaderPropGetter,
  Column,
  HeaderGroup,
  ColumnInstance,
  TableCellProps,
  Cell,
} from 'react-table'

import {
  columnAlignmentToJustifyContentDictionary,
  columnAlignmentToTextAlignmentDictionary,
} from './consts'

type GetStylesProps = {
  align?: Column['align']
  alignSelf?: CSSProperties['alignSelf']
  rowItemsAlignment?: CSSProperties['alignItems']
  padding?: CSSProperties['padding']
}

const getStyles = ({
  align = 'left',
  alignSelf,
  rowItemsAlignment,
  padding = '20px',
}: GetStylesProps) => {
  const style: Partial<CSSProperties> = {
    display: 'flex',
    justifyContent: columnAlignmentToJustifyContentDictionary[align],
    textAlign: columnAlignmentToTextAlignmentDictionary[align],
    alignItems: 'center',
    alignSelf: alignSelf ?? rowItemsAlignment ?? 'flex-start',
    padding: padding,
  }

  return { style }
}

type MetaCellProps<T extends object> = {
  cell: Cell<T> & {
    column: {
      alignSelf?: CSSProperties['alignSelf']
      padding?: CSSProperties['padding']
    }
  }
}

export const useGetters = <T extends object>({
  rowItemsAlignment,
}: Pick<GetStylesProps, 'rowItemsAlignment'> = {}) => {
  const getWidth = ({
    width,
    minWidth,
    maxWidth,
  }: HeaderGroup<T> | ColumnInstance<T>) => ({
    style: { width, minWidth, maxWidth },
  })

  const getHeaderProps: HeaderPropGetter<T> = (props, { column }) => [
    props,
    getStyles({
      align: column.align,
      rowItemsAlignment,
      padding: column.padding,
    }),
    getWidth(column),
    column.canSort ? column.getSortByToggleProps({ title: undefined }) : {},
  ]

  const getCellProps = (
    props: Partial<TableCellProps>,
    { cell: { column } }: MetaCellProps<T>,
  ) => [
    props,
    getStyles({
      align: column.align,
      alignSelf: column.alignSelf,
      rowItemsAlignment,
      padding: column.padding,
    }),
    getWidth(column),
  ]

  return {
    getHeaderProps,
    getCellProps,
  }
}

export const makeTableClickHandler =
  (fn: (event: MouseEvent<HTMLElement>) => void) =>
  (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    fn(event)
  }
