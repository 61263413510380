import { generatePath } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { api } from 'apiServices'

import type { MODEL__ArticleListAssignments } from '../../types'
import { articleListAssignmentsPath } from '../consts'

export const url = articleListAssignmentsPath

export const getArticleListAssignmentsRequest = (articleId: string) =>
  api.get<unknown, MODEL__ArticleListAssignments>(
    generatePath(url, { articleId }),
  )

export const ARTICLE_LIST_ASSIGNMENTS_QUERY_KEY =
  'ARTICLE_LIST_ASSIGNMENTS_QUERY_KEY'

export const useArticleListAssignmentsQuery = (articleId: string) =>
  useQuery({
    queryKey: [ARTICLE_LIST_ASSIGNMENTS_QUERY_KEY, articleId],
    queryFn: () => getArticleListAssignmentsRequest(articleId),
  })
