import type { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'
import { matchPath, useLocation } from 'react-router-dom'

import { useTheme } from 'theme'
import { PATHS } from 'consts/paths'

import { useCurrentUser } from 'app/containers/common/Auth'
import { Divider } from 'app/components/Divider'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { Footer } from '../Footer'

import {
  CustomerContentWrapper,
  BackofficeContentWrapper,
  PageWrapper,
  HeaderWrapper,
} from './styles'
import type { HeaderProps, SideNavigationStructure } from './components'
import {
  ImpersonationBar,
  Header,
  SideNavigation,
  SIDE_NAV_WIDTH,
} from './components'
import { usePageBackground } from './usePageBackground'

type LayoutProps = {
  withSideNavigation?: boolean
  navigationStructure?: SideNavigationStructure[]
  navigationBottomSection?: ReactNode
  children?: ReactNode
  shouldApplyWiderMobileSpacing?: boolean
} & HeaderProps

export const Layout = ({
  children,
  withSideNavigation,
  navigationStructure,
  navigationBottomSection,
  shouldApplyWiderMobileSpacing,
  breadcrumbs,
  onCancel,
}: LayoutProps) => {
  const { isCustomer, isOciCustomer } = useCurrentUser()
  const { dimensions, dimensionsRaw } = useTheme()
  const backgroundColor = usePageBackground()

  const { isMobile } = useBreakpointScreenSize()
  const { pathname } = useLocation()

  if (!children) return null

  const isCheckoutPath = !!matchPath(pathname, PATHS.customer.checkout.root)
  const isCustomerOrOciCustomer = isCustomer || isOciCustomer
  const shouldRenderSideNav = !!withSideNavigation && !!navigationStructure

  const footerPaddingLeft = shouldRenderSideNav
    ? `calc(${SIDE_NAV_WIDTH} + ${dimensionsRaw.footerPadding}px)`
    : ''

  const MainContent = isCustomerOrOciCustomer
    ? CustomerContentWrapper
    : BackofficeContentWrapper

  return (
    <Flex
      direction="column"
      justify="space-between"
      backgroundColor={backgroundColor}
      // INFO: On mobile checkout we got that fixed bottom bar, so to make the footer visible we need the padding
      pb={
        isMobile && isCheckoutPath ? dimensions.mobileFooterPaddingBottom : ''
      }
    >
      <HeaderWrapper>
        <ImpersonationBar />

        <Header breadcrumbs={breadcrumbs} onCancel={onCancel} />

        <Divider />
      </HeaderWrapper>

      <PageWrapper withSideNavigation={shouldRenderSideNav}>
        {shouldRenderSideNav && (
          <SideNavigation
            navigationStructure={navigationStructure}
            navigationBottomSection={navigationBottomSection}
          />
        )}

        <MainContent
          as="main"
          shouldApplyWiderMobileSpacing={shouldApplyWiderMobileSpacing}
        >
          {children}
        </MainContent>
      </PageWrapper>
      <Footer
        padding={dimensions.footerPadding}
        margin="0 auto"
        paddingLeft={footerPaddingLeft}
        backgroundColor={backgroundColor}
      />
    </Flex>
  )
}
