import styled from '@emotion/styled'
import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { mediaQuery } from 'theme/mediaQueries'

import { useCurrentUser } from 'app/containers/common/Auth'
import { useActiveBreadcrumbs } from 'app/App/routes/authorized/routes/utils'
import { BREADCRUMBS_MAP } from 'app/App/routes/authorized/routes/CustomerRoutes/consts'

import { usePageOffsetTop } from './usePageOffsetTop'

export const HeaderWrapper = styled((props: FlexProps) => {
  const pageOffsetTop = usePageOffsetTop()

  return <Flex h={pageOffsetTop} {...props} />
})(
  ({ theme: { zIndexes, colors } }) =>
    ({
      flexDirection: 'column',
      position: 'fixed',
      zIndex: zIndexes.header,
      width: '100vw',
      background: colors.white,

      [mediaQuery.print]: {
        display: 'none',
      },
    }) as const,
)

type PageWrapperProps = FlexProps & { withSideNavigation: boolean }

export const PageWrapper = ({
  withSideNavigation,
  backgroundColor,
  ...rest
}: PageWrapperProps) => {
  const pageOffsetTop = usePageOffsetTop()

  return (
    <Flex
      bg={backgroundColor}
      minH={`calc(100vh - ${pageOffsetTop})`}
      pt={pageOffsetTop}
      sx={{
        [mediaQuery.print]: { pt: 0 },
      }}
      // INFO: this prevents Page content jumping when scrollbar is dynamically added e.g. after expanding Table rows
      {...(withSideNavigation
        ? { mr: 'calc(-1 * (100vw - 100%))' }
        : { ml: 'calc(100vw - 100%)' })}
      {...rest}
    />
  )
}

type MainProps = {
  shouldApplyWiderMobileSpacing?: boolean
} & BoxProps

export const Main = ({
  shouldApplyWiderMobileSpacing,
  ...props
}: MainProps) => {
  const { dimensions } = useTheme()

  const mainComponentMediaStyle = {
    [mediaQuery.small]: {
      padding: shouldApplyWiderMobileSpacing
        ? dimensions.contentPaddingMobileWide
        : dimensions.contentPaddingMobile,
    },
    [mediaQuery.print]: {
      padding: 0,
    },
  }

  return (
    <Box
      sx={mainComponentMediaStyle}
      position="relative"
      flexGrow={1}
      width="100%"
      margin="0 auto"
      padding={dimensions.contentPadding}
      {...props}
    />
  )
}

export const CustomerContentWrapper = (props: MainProps) => {
  const { dimensions } = useTheme()

  return <Main maxWidth={dimensions.customerContentMaxWidth} {...props} />
}

export const BackofficeContentWrapper = (props: MainProps) => {
  const { dimensions } = useTheme()

  return <Main maxWidth={dimensions.backofficeContentMaxWidth} {...props} />
}
