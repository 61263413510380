import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'

export const ShowOnlyOnPrintout = ({
  children,
  ...rest
}: { children: ReactNode } & BoxProps) => (
  <Box
    {...rest}
    sx={{
      '@media not print': { display: 'none' },
    }}
  >
    {children}
  </Box>
)
