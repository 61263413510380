import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Button } from 'app/components/Buttons/Button'
import { UserIcon } from 'app/components/icons'
import { useCompanyUserMeQuery } from 'app/containers/common/Me/CompanyUser'
import { useUserMeQuery } from 'app/containers/common/Me/User'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import { useCurrentUser } from 'app/containers/common/Auth'

export const CustomerIconWrapper = styled(Flex)(
  ({ theme: { borders, colors } }) => ({
    width: '32px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    border: borders.primary,
    borderRadius: '6px',
    backgroundColor: colors.backgroundA,
  }),
)

export const CustomerAccountButtonTrigger = () => {
  const { isMobile } = useBreakpointScreenSize()
  const { isOciCustomer } = useCurrentUser()
  const { data: { profile } = {}, isPending: isCompanyUserDataLoading } =
    useCompanyUserMeQuery()
  const { data: { username } = {} } = useUserMeQuery()

  const { firstName, lastName } = profile?.person ?? {}

  return (
    <Button
      isLoading={!isOciCustomer && isCompanyUserDataLoading}
      isDisabled={!isOciCustomer && isCompanyUserDataLoading}
      data-testid="userMenuButton"
      size={isMobile ? 'sm' : 'md'}
      variant="secondary"
      iconSpacing={isMobile ? '0px' : '8px'}
      leftIcon={
        <CustomerIconWrapper>
          <UserIcon />
        </CustomerIconWrapper>
      }
      style={{
        padding: '8px',
      }}
      justifyContent="start"
      minW="120px"
      maxW="200px"
      labelProps={{ textStyle: 'textSmallSb', textAlign: 'left' }}
    >
      {!isMobile && (
        <>
          {isOciCustomer ? (
            username
          ) : (
            <>
              {firstName}
              <br />
              {lastName}
            </>
          )}
        </>
      )}
    </Button>
  )
}
