import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useTheme } from 'theme'

import type { PanelProps } from '../Panel/Panel'
import { Panel } from '../Panel/Panel'
import type { ButtonProps } from '../Buttons/Button'
import { Button } from '../Buttons/Button'

import type { ButtonColors, NotificationProps } from './types'

export const NotificationWrapper = ({
  isElevated,
  ...props
}: PanelProps & Pick<NotificationProps, 'type' | 'isElevated'>) => {
  const { shadows } = useTheme()

  return (
    <Panel
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing="20px"
      data-testid={`${props.type}_notification`}
      isWithoutBorder
      boxShadow={isElevated ? shadows.div : undefined}
      {...props}
    />
  )
}

export const ButtonStyled = styled(
  ({
    actionBackgroundColor,
    actionHoverBackgroundColor,
    actionActiveBackgroundColor,
    ...props
  }: ButtonProps & ButtonColors) => (
    <Button size="sm" variant="secondary" {...props} />
  ),
)(
  ({
    actionBackgroundColor,
    actionHoverBackgroundColor,
    actionActiveBackgroundColor,
  }) => ({
    whiteSpace: 'nowrap',
    backgroundColor: actionBackgroundColor,

    ':hover:not([disabled]):not(:active)': {
      backgroundColor: actionHoverBackgroundColor,
    },

    ':active': {
      backgroundColor: actionActiveBackgroundColor,
    },
  }),
)

export const DeleteIconWrapper = styled(Box)(() => ({
  width: '20px',
  cursor: 'pointer',
}))
