import { useRouteMatch } from 'react-router-dom'

import { PATHS } from 'consts/paths'

export const useIsEmailRedirectRoute = () => {
  const isEmailRedirectRoute = !!useRouteMatch({
    path: PATHS.emailRedirect,
  })

  return {
    isEmailRedirectRoute,
  }
}
