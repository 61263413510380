import { HttpResponse, http } from 'msw'

import { generatedApi } from 'apiServices'
import type {
  ModelGetFastOrderListData,
  ModelGetFastOrderListParamsEnum,
} from 'libs/openapiGeneratedApi/Api'

import { generateFastOrderListPath } from '../utils'

import { fastOrderListResultsMockData } from './mockData'

export const scanningListGetMock = http.get(
  generateFastOrderListPath({
    url: generatedApi.customers.getFastOrderList.url,
    fastOrderListEnum: 'SCANNING_LIST',
  }),
  () => HttpResponse.json(fastOrderListResultsMockData),
)

export const fastOrderListGetMock = http.get(
  generateFastOrderListPath({
    url: generatedApi.customers.getFastOrderList.url,
    fastOrderListEnum: 'FAST_ORDER_LIST',
  }),
  () => HttpResponse.json(fastOrderListResultsMockData),
)

type MakeFastOrderListGetMockWithSpyArgs = {
  fastOrderListEnum: ModelGetFastOrderListParamsEnum
  result: ModelGetFastOrderListData
  spyFn?: jest.Mock
}

export const makeFastOrderListGetMockWithSpy = ({
  fastOrderListEnum,
  result,
  spyFn,
}: MakeFastOrderListGetMockWithSpyArgs) =>
  http.get(
    generateFastOrderListPath({
      url: generatedApi.customers.getFastOrderList.url,
      fastOrderListEnum,
    }),

    () => {
      if (spyFn) spyFn()

      return HttpResponse.json(result)
    },
  )

export const makeScanningListGetMockWithSpy = (
  params: Omit<MakeFastOrderListGetMockWithSpyArgs, 'fastOrderListEnum'>,
) =>
  makeFastOrderListGetMockWithSpy({
    ...params,
    fastOrderListEnum: 'SCANNING_LIST',
  })
