import { RiQuestionLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { useGetHelpCenterOnClick } from 'app/App/routes/authorized/routes/utils'
import { ActionItem } from 'app/components/ActionsMenu/styles'
import { SupportIcon } from 'app/components/icons'
import { ActionDivider } from 'app/components/ActionsMenu/ActionsMenu'

import { showContactSupportModal } from './ContactSupportModal'

const messages = translations.navigation.common

export const SupportAndHelpActions = () => {
  const { t } = useTranslation()
  const onHelpClick = useGetHelpCenterOnClick()

  return (
    <Box data-testid="supportAndHelpActions">
      <ActionItem
        iconSpacing="16px"
        icon={<SupportIcon />}
        onClick={() => showContactSupportModal()}
      >
        {t(messages.contactSupport.rootLabel)}
      </ActionItem>
      <ActionItem
        iconSpacing="16px"
        icon={<RiQuestionLine size={20} />}
        onClick={onHelpClick}
      >
        {t(messages.helpWithFeatures.rootLabel)}
      </ActionItem>
      <ActionDivider />
    </Box>
  )
}
