// @ts-nocheck
import { isArray, isPlainObject, mapValues, mapKeys, map } from 'lodash'

// TODO: Type it https://simplesystem.atlassian.net/browse/S2NG-2230
export const parseKeysRecursive =
  (callback: (key: string) => string) => (object) => {
    if (isArray(object)) {
      return object.map((arrayItem) => parseKeysRecursive(callback)(arrayItem))
    }

    if (isPlainObject(object)) {
      return mapValues(
        mapKeys(object, (_, key) => callback(key)),

        (value) => {
          const parseKeysCallback = parseKeysRecursive(callback)

          if (isPlainObject(value)) {
            return parseKeysCallback(value)
          }

          if (isArray(value)) {
            return map(value, (arrayItem) => parseKeysCallback(arrayItem))
          }

          return value
        },
      )
    }

    return object
  }
