import type React from 'react'

import { parseToPositiveInteger } from '../../parsers'

import { MAX_INPUT_VALUE } from './consts'
import type { AmountHandlersHelperHooksCommonProps } from './types'

type AmountInputHandlersHookProps = AmountHandlersHelperHooksCommonProps & {
  quantity: number
  shownValue: number | string
  cancelPlusMinusHandlers: () => void
}

export const useAmountInputHandlers = ({
  shownValue,
  quantity,
  parsedValue,
  updateHandler,
  deleteHandler,
  setShownValue,
  onNetworkError,
  cancelPlusMinusHandlers,
}: AmountInputHandlersHookProps) => {
  const leaveHandler = () => {
    // prevent keeping empty input on leave or stale value
    if (shownValue !== parsedValue) setShownValue(parsedValue)

    if (parsedValue === 0) return deleteHandler(onNetworkError)

    updateHandler(parsedValue, onNetworkError)
  }

  const changeHandler = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    cancelPlusMinusHandlers()

    if (!value) return setShownValue(value) // allow having empty input for delete + type action

    const parsed = parseToPositiveInteger(value)

    const newValue = parsed > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : parsed // allow only values up to max value

    setShownValue(newValue)
  }

  const blurHandler = () => {
    if (quantity !== shownValue) leaveHandler() // update only if necessary
  }

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') leaveHandler() // handle Enter click
  }

  return {
    changeHandler,
    blurHandler,
    keyUpHandler,
  }
}
