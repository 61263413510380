import { ImageLoadingError } from './ImageLoadingError'

export const getMeta = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (err) => {
      reject(new ImageLoadingError())

      const errMsg =
        typeof err === 'string' ? err : `${err.type} - ${err.target}`
      console.warn(errMsg) // eslint-disable-line no-console
    }
    img.src = url
  })
