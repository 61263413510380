import type { IconProps } from '../types'

export const CopyIcon = ({
  width = 16,
  height = 16,
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    width={width}
    height={width}
    {...rest}
  >
    <path
      d="M12.2071 11.2929L11.8536 10.9393L12.2071 11.2929ZM10.6464 13.5607L12.5607 11.6464L11.8536 10.9393L9.93934 12.8536L10.6464 13.5607ZM13 10.5858V5.5H12V10.5858H13ZM11.5 4H6.5V5H11.5V4ZM5 5.5V12.5H6V5.5H5ZM6.5 14H9.58579V13H6.5V14ZM5 12.5C5 13.3284 5.67157 14 6.5 14V13C6.22386 13 6 12.7761 6 12.5H5ZM6.5 4C5.67157 4 5 4.67157 5 5.5H6C6 5.22386 6.22386 5 6.5 5V4ZM13 5.5C13 4.67157 12.3284 4 11.5 4V5C11.7761 5 12 5.22386 12 5.5H13ZM12.5607 11.6464C12.842 11.3651 13 10.9836 13 10.5858H12C12 10.7184 11.9473 10.8456 11.8536 10.9393L12.5607 11.6464ZM9.93934 12.8536C9.84557 12.9473 9.71839 13 9.58579 13V14C9.98361 14 10.3651 13.842 10.6464 13.5607L9.93934 12.8536Z"
      fill={color}
    />
    <path
      d="M9.5 13.5V11.5C9.5 10.9477 9.94772 10.5 10.5 10.5H12.5"
      stroke={color}
    />
    <path
      d="M5.5 11.5H4.5C3.94772 11.5 3.5 11.0523 3.5 10.5V3.5C3.5 2.94772 3.94772 2.5 4.5 2.5H9.5C10.0523 2.5 10.5 2.94772 10.5 3.5V4.5"
      stroke={color}
    />
  </svg>
)
