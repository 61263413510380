import { WarningTwoIcon } from '@chakra-ui/icons'

import { ErrorIcon, InfoIcon, TickIcon } from 'app/components/icons'

export const NOTIFICATION_TYPES = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
} as const

export const ICON_MAPPING = {
  [NOTIFICATION_TYPES.info]: InfoIcon,
  [NOTIFICATION_TYPES.success]: TickIcon,
  [NOTIFICATION_TYPES.error]: ErrorIcon,
  [NOTIFICATION_TYPES.warning]: WarningTwoIcon,
}
