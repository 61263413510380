import type { ReactNode } from 'react'

export type MaybeRenderProp<T> = ReactNode | ((props: T) => ReactNode)

type RenderOrInvokeProps<T> = {
  props: T
  component: MaybeRenderProp<T>
}

export const RenderOrInvoke = <T extends object>({
  props,
  component,
}: RenderOrInvokeProps<T>) => {
  if (typeof component === 'function') {
    return component(props)
  }

  return component
}
