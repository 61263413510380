import React from 'react'
import { Flex } from '@chakra-ui/react'

import { AccountMenuUsername } from './AccountMenuUsername'
import type { AccountMenuCompanyInfoProps } from './AccountMenuCompanyInfo'
import { AccountMenuCompanyInfo } from './AccountMenuCompanyInfo'

type AccountMenuUserAndCompanyInfoProps = AccountMenuCompanyInfoProps

export const AccountMenuUserAndCompanyInfo = ({
  areCompanyDetailsHidden,
}: AccountMenuUserAndCompanyInfoProps) => (
  <Flex direction="column" gap="16px" padding="8px 12px 16px 12px">
    <AccountMenuUsername />

    <AccountMenuCompanyInfo areCompanyDetailsHidden={areCompanyDetailsHidden} />
  </Flex>
)
