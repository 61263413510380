import { generatePath, useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import { throwIfAbsent } from 'types/utils'
import { publicApi } from 'apiServices'

import type { MODEL__JWTTokenResponse } from 'app/models/tokenResponse'

import type { ActivationError } from './types'

export const url = '/users/account-verifications/:verificationKey'
export const ACCOUNT_VERIFICATION_QUERY_KEY = ['accountVerification']

type VerificationKey = string

const getActivationRequest = (verificationKey?: VerificationKey) => {
  throwIfAbsent(verificationKey)

  return publicApi.get<unknown, MODEL__JWTTokenResponse>(
    generatePath(url, { verificationKey }),
  )
}

export const useActivationQuery = ({ enabled }: { enabled: boolean }) => {
  const { verificationKey } = useParams<{ verificationKey?: VerificationKey }>()

  return useQuery<MODEL__JWTTokenResponse, ActivationError>({
    queryKey: ACCOUNT_VERIFICATION_QUERY_KEY,
    queryFn: () => getActivationRequest(verificationKey),
    enabled,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    meta: { suppressGlobalOnError: true },
  })
}
