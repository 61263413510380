import { useTranslation } from 'react-i18next'

import { camelCase } from 'types/utils'
import { translations } from 'locales/translations'
import type { Title } from 'consts/titles'

const titlesOptionsMessages = translations.formFields.title.options

type Person = {
  title: Title
  firstName: string
  lastName: string
  phoneNumber: string
}

export const useFormatName = () => {
  const { t } = useTranslation()

  // TODO: Consider replacing withTitle to withoutTitle to have title added by default
  // TODO: missing unit test https://simplesystem.atlassian.net/browse/S2NG-4525
  return (person?: Person, withTitle?: boolean) => {
    const { title: titleId, firstName, lastName } = person ?? {}

    if (!firstName || !lastName) {
      return undefined
    }

    const title =
      t(titlesOptionsMessages[camelCase<Title>(titleId)]) || undefined
    const titlePart = !!title && withTitle ? `${title} ` : ''

    return `${titlePart}${firstName} ${lastName}`
  }
}
