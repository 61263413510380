import { WarningTwoIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { colors } from 'theme/colors'
import { translations } from 'locales/translations'

import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'
import { GenericArticleContentWrapper } from 'app/pages/customers/common/articles/GenericArticleContent'
import { Typography } from 'app/components/Typography'
import { NoPhotoIcon } from 'app/components/icons'
import { getAvailableNonRestrictedFoundArticles } from 'app/pages/customers/common/fastOrder/utils'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

const messages = translations.pages.customer.scanner.article

export const MultipleMatchingArticlesContent = () => {
  const { t } = useTranslation()

  const {
    fastOrderListItem: { foundArticles },
  } = useFastOrderListItemContext()

  const { name } = getAvailableNonRestrictedFoundArticles(foundArticles)[0]

  return (
    <GenericArticleContentWrapper>
      <NoPhotoIcon />

      <ColumnWithGap gap={4}>
        <Typography textStyle="textNormalSb">{name}</Typography>
        <Flex gap="4px">
          <WarningTwoIcon color={colors.orange_600} />
          <Typography textStyle="textSmall" color="orange_600">
            {t(messages.warnings.multipleMatchingReferences)}
          </Typography>
        </Flex>
      </ColumnWithGap>
    </GenericArticleContentWrapper>
  )
}
