import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Notification } from 'app/components/Notification/Notification'
import { ColumnWithGap } from 'app/components/layout/ColumnWithGap'

import { useFastOrderResultsNotification } from './useFastOrderResultsNotification'

const messages = translations.pages.customer.scanner.warnings

export const FastOrderResultsNotifications = () => {
  const { t } = useTranslation()

  const {
    needsArticleSelection,
    handleNotFoundItemsDelete,
    handleNotAvailableOrRestrictedItemsDelete,
    shouldShowNoMatchingReferencesError,
    shouldShowNotAvailableOrRestrictedArticlesWarning,
    isAnyNotificationShown,
  } = useFastOrderResultsNotification()

  if (!isAnyNotificationShown) return null

  return (
    <ColumnWithGap gap={8} flexGrow={0}>
      {shouldShowNoMatchingReferencesError && (
        <Notification
          type="error"
          data-testid="noMatchingReferencesError"
          buttonText={t(messages.removeArticlesButtonText)}
          buttonClickHandler={handleNotFoundItemsDelete}
        >
          {t(messages.noMatchingReferences)}
        </Notification>
      )}
      {shouldShowNotAvailableOrRestrictedArticlesWarning && (
        <Notification
          type="warning"
          data-testid="notAvailableOrRestrictedArticlesWarning"
          buttonText={t(messages.removeArticlesButtonText)}
          buttonClickHandler={handleNotAvailableOrRestrictedItemsDelete}
        >
          {t(messages.notAvailableArticles)}
        </Notification>
      )}
      {needsArticleSelection && (
        <Notification
          type="warning"
          data-testid="multipleMatchingReferencesWarning"
        >
          {t(messages.multipleMatchingReferences)}
        </Notification>
      )}
    </ColumnWithGap>
  )
}
