import { useQueryClient } from '@tanstack/react-query'

import { articleRelatedQueryKeys } from './consts'

export const useInvalidateArticleRelatedQueries = () => {
  const queryClient = useQueryClient()

  return () => {
    articleRelatedQueryKeys.forEach((key) =>
      queryClient.invalidateQueries({ queryKey: [key] }),
    )
  }
}
