import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import { mediaQuery } from 'theme/mediaQueries'

export interface ColumnWithGapProps extends FlexProps {
  gap?: 0 | 2 | 4 | 6 | 8 | 12 | 16 | 20 | 24 | 32 | 64
}

export const ColumnWithGap = ({ gap, ...rest }: ColumnWithGapProps) => (
  <Flex
    gap={typeof gap === 'number' ? `${gap}px` : '20px'}
    sx={{ [mediaQuery.small]: { gap: gap ? `${gap}px` : '16px' } }}
    flex={1}
    width="100%"
    flexDirection="column"
    {...rest}
  />
)
