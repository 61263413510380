import { Redirect, Route, Switch } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import { OciCheckout } from 'app/pages/customers/Checkout/OciLoadable'
import { Layout } from 'app/components/layout/Layout'
import { FullPageLoader } from 'app/components/loaders/FullPageLoader'
import { useCurrentUser } from 'app/containers/common/Auth'

import { useInitUser } from '../authorized/useInitUser'
import { useShouldApplyWiderMobileSpacing } from '../authorized/routes/CustomerRoutes/utils'
import { useActiveBreadcrumbs } from '../authorized/routes/utils'
import { BREADCRUMBS_MAP } from '../authorized/routes/CustomerRoutes/consts'

import {
  useSetOciSessionIdEffect,
  useLogoutAuthorizedUserEffect,
  useDataForClearingOciRootPath,
} from './hooks'
import { renderCustomerAndOciCommonRoutes } from './renderCustomerAndOciCommonRoutes'

export const OciCustomerSessionRoutes = () => {
  const { shouldInitOciFlow } = useSetOciSessionIdEffect()
  const { isAuthorized } = useLogoutAuthorizedUserEffect()

  if (isAuthorized || shouldInitOciFlow) {
    return <FullPageLoader />
  }

  return <Routes />
}

const Routes = () => {
  useInitUser() // INFO: Initialize user data and profile after successful authentication

  const shouldApplyWiderMobileSpacing = useShouldApplyWiderMobileSpacing()
  const { isUserInitialized } = useCurrentUser()
  const { shouldRedirect, redirectPath } = useDataForClearingOciRootPath()
  const activeBreadcrumbs = useActiveBreadcrumbs(BREADCRUMBS_MAP)

  if (shouldRedirect) {
    return <Redirect to={redirectPath} />
  }

  if (!isUserInitialized) return <FullPageLoader />

  return (
    <Layout
      breadcrumbs={activeBreadcrumbs}
      shouldApplyWiderMobileSpacing={shouldApplyWiderMobileSpacing}
    >
      <Switch>
        {renderCustomerAndOciCommonRoutes()}

        <Route path={PATHS.customer.checkout.root}>
          <OciCheckout />
        </Route>

        {/* fallback redirection */}
        <Route>
          <Redirect to={PATHS.customer.home} />
        </Route>
      </Switch>
    </Layout>
  )
}
