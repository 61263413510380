import styled from '@emotion/styled'
import { Flex } from '@chakra-ui/react'

export const AmountInputWrapper = styled(Flex)(({ theme: { colors } }) => ({
  borderRadius: '6px',
  background: colors.backgroundC,
  width: '100%',
}))

export const InputWrapper = styled.div`
  flex: 1;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
