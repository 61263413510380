import { useTranslation } from 'react-i18next'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { forwardRef } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { BareInput } from 'app/components/inputs/Input'
import { Button } from 'app/components/Buttons/Button'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'

const messages = translations.pages.customer.scanner

type ScannerInputProps = {
  isAddingScannedValue?: boolean
  addScannedValue: (args: { barcode: string }) => void
}

export const ScannerInput = forwardRef<ScannerInputProps, 'input'>(
  ({ addScannedValue, isAddingScannedValue }, ref) => {
    const { t } = useTranslation()
    const { inputValue, setInputValue } = useFastOrderContext()

    const searchBarcode = () => {
      const trimmedInputValue = inputValue?.trim()

      if (trimmedInputValue) {
        addScannedValue({ barcode: trimmedInputValue })
      }
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') searchBarcode()
    }

    return (
      <BareInput
        aria-label="Scanner input"
        name="scannerInput"
        placeholder={t(messages.searchInputPlaceholder)}
        value={inputValue}
        onKeyDown={handleOnKeyDown}
        rightElement={
          <Button
            size="squareSm"
            onClick={searchBarcode}
            dataTestId="searchBarcodeButton"
            isLoading={isAddingScannedValue}
          >
            <ArrowForwardIcon boxSize="20px" />
          </Button>
        }
        ref={ref}
        onChange={(e) => {
          setInputValue(e.target.value)
        }}
      />
    )
  },
)
