import type { ReactNode } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'theme'

import { Accordion } from 'app/components/accordions/styles'
import { AccordionRow } from 'app/components/accordions/AccordionRow'

import { NavItem } from './NavItem'
import type { SideNavOption } from './types'

type ExtendableNavItemProps = {
  options: SideNavOption[]
  titleMsg: string
  icon: ReactNode
  dataTestId?: string
}

export const ExtendableNavItem = ({
  options,
  titleMsg,
  icon,
  dataTestId,
}: ExtendableNavItemProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [index, setIndex] = useState(-1)

  return (
    <Accordion
      index={index}
      onChange={(expandedIndex: number) => setIndex(expandedIndex)}
      width="100%"
    >
      <AccordionRow
        title={t(titleMsg)}
        shouldScrollToActiveElement
        accordionButtonProps={{
          justifyContent: 'space-between',
          padding: '10px 12px',
          alignItems: 'center',
        }}
        buttonLabelProps={{ textStyle: 'textNormalSb' }}
        iconLeft={icon}
        data-testid={dataTestId}
      >
        {options.map((option) => (
          <NavItem
            {...option}
            key={option.dataCy}
            onLinkActive={() => setIndex(0)}
            style={{
              padding: '10px 12px 10px 38px',
              fontWeight: 400,
              color: colors.contentB,
            }}
          />
        ))}
      </AccordionRow>
    </Accordion>
  )
}
