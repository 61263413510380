import { useLocation } from 'react-router-dom'

import { PATHS } from 'consts/paths'

import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  DEFAULT_TILES_CONTENT_PAGE_SIZE,
  SEARCH_PAGE_SIZE_OPTIONS,
} from './consts'

export const useGetPageSizeOptions = () => {
  const { pathname } = useLocation()

  switch (pathname) {
    case PATHS.customer.search:
      return SEARCH_PAGE_SIZE_OPTIONS

    case PATHS.customer.ociCatalogs:
    case PATHS.customer.vendors:
    case PATHS.vendor.customers:
      return [DEFAULT_TILES_CONTENT_PAGE_SIZE]

    default:
      return DEFAULT_PAGE_SIZE_OPTIONS
  }
}
