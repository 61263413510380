import { Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'

export const HideOnPrintout = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      '@media print': { display: 'none' },
    }}
  >
    {children}
  </Box>
)
