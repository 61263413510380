import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { ModelGetVendorMarketplaceCustomersWithCurrencyData } from 'libs/openapiGeneratedApi/Api'

import { getVendorMockUrl } from 'app/containers/vendors/utils'

import { customersListMockData } from '../customersListMockData'

export const getVendorMarketplaceCustomersWithCurrencyMockData: ModelGetVendorMarketplaceCustomersWithCurrencyData =
  customersListMockData

export const makeVendorMarketplaceCustomersWithCurrencyGetMock = (
  spyFn?: jest.Mock,
) =>
  http.get(
    getVendorMockUrl(
      generatedApi.vendors.getVendorMarketplaceCustomersWithCurrency.url,
    ),
    async ({ params }) => {
      if (spyFn) spyFn(params.currencyCode)

      return HttpResponse.json(
        getVendorMarketplaceCustomersWithCurrencyMockData,
      )
    },
  )

export const getVendorMarketplaceCustomersWithCurrencyMock =
  makeVendorMarketplaceCustomersWithCurrencyGetMock()
