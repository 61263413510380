import type { IconProps } from '../types'

export const MarketplaceIcon = ({
  width = '16px',
  height = '16px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6 13V9h2v3h8V9h2v4a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1ZM4.12 2a2 2 0 0 0-1.947 1.546L1.6 6v.5C1.6 7.328 2.227 8 3 8c.77 0 1.394-.665 1.4-1.488C4.406 7.335 5.03 8 5.8 8c.773 0 1.4-.672 1.4-1.5 0 .828.627 1.5 1.4 1.5.77 0 1.394-.665 1.4-1.488.006.823.63 1.488 1.4 1.488.773 0 1.4-.672 1.4-1.5 0 .828.627 1.5 1.4 1.5.773 0 1.4-.672 1.4-1.5V6l-.573-2.454A2 2 0 0 0 13.08 2H4.12Z"
      fill={color}
    />
  </svg>
)
