import type { AxiosResponse } from 'axios'

export const REQUEST_TIMEOUT = 60000

export class ResponseError extends Error {
  public response: AxiosResponse

  constructor(response: AxiosResponse) {
    super(response.statusText)
    this.response = response
  }
}
