import { useQuery } from '@tanstack/react-query'

import { getCustomersCountsRequest } from './api'
import type { API__GetCustomersCountsHookArgs } from './hooks.types'
import { prepareParams } from './utils'

export const CUSTOMERS_COUNTS_QUERY_KEY = 'customersCounts'

export const useCustomersCountsQuery = (
  params: API__GetCustomersCountsHookArgs,
) =>
  useQuery({
    queryKey: [CUSTOMERS_COUNTS_QUERY_KEY, prepareParams(params)],
    queryFn: () => getCustomersCountsRequest(prepareParams(params)),
  })
