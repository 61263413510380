import { useEffect, useRef } from 'react'
import { useZoomImageHover } from '@zoom-image/react'
import * as Sentry from '@sentry/react'
import type { UseImageReturn } from '@chakra-ui/react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import type { MemoizedImageProps } from '../types'

import { getFittedAspectRatioSize } from './getFittedAspectRatioSize'
import { ImageLoadingError } from './ImageLoadingError'

type UseZoomOnHoverProps = Pick<
  MemoizedImageProps,
  'src' | 'enableZoom' | 'size'
> & {
  imageStatus: UseImageReturn
}

export const useZoomOnHover = ({
  src,
  enableZoom,
  size,
  imageStatus,
}: UseZoomOnHoverProps) => {
  const { isMobile } = useBreakpointScreenSize()
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const zoomTargetRef = useRef<HTMLDivElement>(null)
  const { createZoomImage: createZoomImageHover } = useZoomImageHover()

  useEffect(() => {
    async function createZoomImage() {
      try {
        const imageContainer = imageContainerRef.current
        const zoomTarget = zoomTargetRef.current

        if (
          !isMobile &&
          src &&
          enableZoom &&
          imageContainer &&
          zoomTarget &&
          imageStatus === 'loaded'
        ) {
          // INFO: below manual size set is needed to prevent bug https://github.com/willnguyen1312/zoom-image/issues/249
          const { width, height, maxWidth, maxHeight } =
            await getFittedAspectRatioSize({ src, imageContainer, size })
          imageContainer.style.width = `${width}px`
          imageContainer.style.height = `${height}px`

          createZoomImageHover(imageContainer, {
            zoomImageSource: src,
            zoomTarget,
            customZoom: { width: maxWidth, height: maxHeight },
            scale: 3,
            disableScrollLock: true,
          })
        }
      } catch (error) {
        if (error instanceof ImageLoadingError) return

        Sentry.captureException(
          error instanceof Error ? error : new Error('createZoomImage error'),
        )
      }
    }

    createZoomImage()
  }, [
    src,
    createZoomImageHover,
    enableZoom,
    imageContainerRef,
    zoomTargetRef,
    isMobile,
    size,
    imageStatus,
  ])

  return { imageContainerRef, zoomTargetRef }
}
