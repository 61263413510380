import { useQuery } from '@tanstack/react-query'

import { generatedApi } from 'apiServices'

import type { UseFastOrderHookArgs } from '../types'

export const getFastOrderListCountQueryKey = ({
  fastOrderListEnum,
}: UseFastOrderHookArgs) => [`${fastOrderListEnum}_COUNTER_QUERY_KEY`] as const

export const useFastOrderListCountQuery = ({
  fastOrderListEnum,
}: UseFastOrderHookArgs) =>
  useQuery({
    queryKey: getFastOrderListCountQueryKey({ fastOrderListEnum }),
    queryFn: () =>
      generatedApi.customers.getNumberOfFastOrderListItems.request({
        fastOrderListEnum,
      }),
  })
