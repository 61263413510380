import { forwardRef } from '@chakra-ui/react'

import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'

import { ButtonStyled } from './ButtonStyled'
import type { ButtonProps } from './types'

export const Button = forwardRef<ButtonProps, 'button'>(
  (
    { variant = 'primary', size = 'md', mobileIconOnly, dataTestId, ...rest },
    ref,
  ) => {
    const { isMobile } = useBreakpointScreenSize()
    const { children, leftIcon } = rest

    const displayOnlyIconForMobile = isMobile && mobileIconOnly

    return (
      <ButtonStyled
        variant={variant}
        size={displayOnlyIconForMobile ? 'squareSm' : size}
        data-testid={dataTestId}
        {...(displayOnlyIconForMobile || children === undefined
          ? { iconSpacing: 0 }
          : {})}
        {...rest}
        ref={ref}
        leftIcon={leftIcon}
        children={displayOnlyIconForMobile ? undefined : children}
      />
    )
  },
)
