import type {
  ChakraStylesConfig,
  GroupBase,
  MenuPlacement,
} from 'chakra-react-select'
import type { SystemStyleObject } from '@chakra-ui/react'

import { theme } from 'theme'

import { textStyles } from 'app/components/Typography'

import {
  makeCommonInputStyles,
  makeSizeDependentStyles,
  disabledStateStyles,
} from '../commonStyles'
import type { Size } from '../types'

import type { MyOption } from './types'

export type ChakraSelectStyles<IsMulti extends boolean = false> =
  ChakraStylesConfig<MyOption, IsMulti, GroupBase<MyOption>>

type ChakraStylesProps = {
  size?: Size
  isInvalid?: boolean
  isIconOnlyValue?: boolean
  menuPlacement?: MenuPlacement
}

const { colors, zIndexes } = theme

export const makeDropdownIndicatorStyles = (
  options?: Pick<ChakraStylesProps, 'size'> & {
    isOpen: boolean
  },
) => {
  const { size, isOpen } = options ?? {}

  return {
    alignSelf: 'center',
    borderRadius: '6px',
    padding: '0 16px 0 0',
    svg: {
      height: size === 'sm' ? '36px' : '48px',
      transition: 'all .2s ease',
      transform: isOpen ? 'rotate(180deg)' : undefined,
    },
  }
}

export const makeMenuListStyles = (provided: SystemStyleObject) => ({
  ...provided,
  minWidth: '100%',
})

export const makeControlStyles = (options?: ChakraStylesProps) => {
  const { size, isInvalid } = options ?? {}

  return {
    ...makeSizeDependentStyles({ size }),
    ...makeCommonInputStyles({ isError: isInvalid }),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }
}

export const makeOptionStyles = () => ({
  ...textStyles.textNormal,
  color: colors.contentA,
  padding: '14px 12px',
  margin: 'auto 6px',
  borderRadius: '6px',

  ':hover': {
    background: colors.backgroundC,
  },
})

export const makeMenuStyles = ({
  menuPlacement,
  ...provided
}: SystemStyleObject & { menuPlacement?: MenuPlacement }) => ({
  ...provided,
  ...(menuPlacement === 'top'
    ? { bottom: '48px' }
    : { top: 'calc(100% - 4px)' }),
  minWidth: '100%',
  width: 'auto',
  zIndex: zIndexes.aboveGround,
})

export function makeChakraStyles<IsMulti extends boolean>(
  options?: ChakraStylesProps,
): ChakraSelectStyles<IsMulti> {
  const { size, isInvalid, isIconOnlyValue, menuPlacement } = options ?? {}

  return {
    container: () => ({
      ...disabledStateStyles,
      width: '100%',
    }),
    control: () => makeControlStyles({ size, isInvalid }),
    dropdownIndicator: (provided, state) =>
      makeDropdownIndicatorStyles({
        size,
        isOpen: state.selectProps.menuIsOpen,
      }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.contentOff,
    }),
    option: () => makeOptionStyles(),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 12px',
      ...(isIconOnlyValue && { padding: '0 0 0 16px' }),
    }),
    menu: (provided) => makeMenuStyles({ ...provided, menuPlacement }),
    menuList: (provided) => makeMenuListStyles(provided),
  }
}
