import { HStack } from '@chakra-ui/react'
import { Trans } from 'react-i18next'

import { translations } from 'locales/translations'
import type { ModelGetArticleListData } from 'libs/openapiGeneratedApi/Api'

import { DotSeparator } from 'app/components/DotSeparator/DotSeparator'
import type { TypographyProps } from 'app/components/Typography'
import { Typography } from 'app/components/Typography'
import { useGetTimeSince } from 'app/hooks/date'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.list.table

const SubHeaderTextBold = (props: TypographyProps) => (
  <Typography textStyle="textLargeSb" as="span" {...props} />
)

type ArticleListSubheaderProps = Pick<
  ModelGetArticleListData,
  'articlesCount' | 'vendorsCount' | 'updatedAt' | 'createdBy'
>

export const ArticleListSubheader = ({
  articlesCount,
  vendorsCount,
  updatedAt,
  createdBy,
}: ArticleListSubheaderProps) => {
  const getTimeSince = useGetTimeSince()
  const isCreatedByMe = createdBy.currentUser

  return (
    <Typography
      textStyle="textLarge"
      color="contentB"
      as="div"
      data-testid="subHeader"
    >
      <HStack spacing={0} divider={<DotSeparator margin="0 8px" />}>
        <span>
          <Trans
            i18nKey={messages.articlesCount}
            values={{ count: articlesCount }}
            components={[<SubHeaderTextBold />]}
          />
        </span>

        <span>
          <Trans
            i18nKey={messages.vendorsCount}
            values={{ count: vendorsCount }}
            components={[<SubHeaderTextBold />]}
          />
        </span>

        <span>
          <Trans
            i18nKey={messages.updated}
            values={{ dateFormatted: getTimeSince(updatedAt) }}
            components={[<span />, <SubHeaderTextBold />]}
          />
        </span>

        <span>
          <Trans
            i18nKey={isCreatedByMe ? messages.createdByMe : messages.createdBy}
            values={{ name: createdBy.name }}
            components={[<span />, <SubHeaderTextBold color="blue_500" />]}
          />
        </span>
      </HStack>
    </Typography>
  )
}
