import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'

import { getInvitationStateRequest } from './api'

export const INVITATION_QUERY_KEY = 'invitation'

export const useInvitationStateQuery = () => {
  const { invitationKey } = useParams<{ invitationKey?: string }>()

  return useQuery({
    queryKey: [INVITATION_QUERY_KEY, { invitationKey }],
    queryFn: () => getInvitationStateRequest({ invitationKey }),
    enabled: !!invitationKey,
    retry: 1,
  })
}
