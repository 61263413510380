import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { matchPath } from 'react-router'

import { PATHS } from 'consts/paths'

import { useMarketplaceSearchAndPagination } from 'app/pages/common/marketplaceAndSearch/useMarketplaceSearchAndPagination'

export const useRedirectToSearchResults = () => {
  const { pathname, search: currentPageSearch } = useLocation()
  const history = useHistory()
  const { searchQueryParamName, searchPreset, searchPresetQueryParamName } =
    useMarketplaceSearchAndPagination()

  return (term: string) => {
    const isArticleDetailsPage = matchPath(pathname, {
      path: PATHS.customer.articleDetails,
      exact: true,
    })

    const isSearchPage = pathname === PATHS.customer.search

    if (isSearchPage || !term) return

    const search = queryString.stringify({
      ...(isArticleDetailsPage ? queryString.parse(currentPageSearch) : {}),
      [searchQueryParamName]: term,
      [searchPresetQueryParamName]: searchPreset,
    })

    history.replace({ pathname: PATHS.customer.search, search })
  }
}
