import type { HeaderGroup } from 'react-table'

import { Divider } from 'app/components/Divider'

import { TrStyled } from '../../styles'
import type { TableProps } from '../../types'
import { useGetters } from '../../utils'

import { ThStyled, TheadStyled } from './styles'
import { Header } from './Header'

type HeadersProps<T extends object> = {
  headerGroups: HeaderGroup<T>[]
} & Pick<
  TableProps<T>,
  'withoutHeadersRow' | 'withoutHeadersRowDivider' | 'withoutHeaderTopBorder'
>

export const Headers = <T extends object>({
  headerGroups,
  withoutHeadersRow,
  withoutHeadersRowDivider,
  withoutHeaderTopBorder,
}: HeadersProps<T>) => {
  const { getHeaderProps } = useGetters<T>()

  if (withoutHeadersRow) {
    if (withoutHeadersRowDivider) return null

    return <Divider />
  }

  return (
    <TheadStyled withoutHeaderTopBorder={withoutHeaderTopBorder}>
      {headerGroups.map((headerGroup) => {
        const { key: headerGroupKey, ...headerGroupProps } =
          headerGroup.getHeaderGroupProps()

        return (
          <TrStyled key={headerGroupKey} {...headerGroupProps}>
            {headerGroup.headers.map((column) => {
              const { key: headerKey, ...headerProps } =
                column.getHeaderProps(getHeaderProps)

              return (
                <ThStyled key={headerKey} {...headerProps}>
                  <Header {...column} />
                </ThStyled>
              )
            })}
          </TrStyled>
        )
      })}
    </TheadStyled>
  )
}
