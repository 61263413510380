import type { IconProps } from '../types'

export const UserAssignIcon = ({
  width = '20px',
  height = '20px',
  color = 'currentColor',
  ...rest
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6V7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7V6Z"
      fill={color}
    />
    <path
      d="M5.53524 16H11.5C11.5 16 10.0001 15.3136 10.0001 13.5C10.0001 11.6865 11.5 11.0001 11.5 11.0001L10.0001 11C10.0001 11 4 10.9617 4 13.5001C4 14.1534 4.42691 15.0202 4.72288 15.5426C4.88769 15.8335 5.2009 16 5.53524 16Z"
      fill={color}
    />
    <path d="M11.9645 13.5004H19.0355" stroke={color} strokeWidth="1.6" />
    <path d="M15.4996 17.0355V9.96447" stroke={color} strokeWidth="1.6" />
  </svg>
)
