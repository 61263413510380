import type { ReactNode } from 'react'
import React, { createContext, useContext, useState } from 'react'

import { useAddItemToFastOrderListMutation } from 'app/containers/customers/FastOrders/Add'

const useMobileScannerContextValue = () => {
  const {
    mutate: addScannedValue,
    isPending: isAddingScannedValue,
    isSuccess: wasScannedItemAddedSuccessfully,
  } = useAddItemToFastOrderListMutation({
    fastOrderListEnum: 'SCANNING_LIST',
    suppressGlobalOnError: true,
  })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return {
    addScannedValue,
    isAddingScannedValue,
    wasScannedItemAddedSuccessfully,
    isDrawerOpen,
    setIsDrawerOpen,
  }
}

export const MobileScannerContext = createContext<
  ReturnType<typeof useMobileScannerContextValue> | undefined
>(undefined)

interface ScannerProviderProps {
  children: ReactNode
}

export const MobileScannerProvider: React.FC<ScannerProviderProps> = ({
  children,
}) => {
  const scannerContextValue = useMobileScannerContextValue()

  return (
    <MobileScannerContext.Provider value={scannerContextValue}>
      {children}
    </MobileScannerContext.Provider>
  )
}

// Create a custom hook to consume the context
export const useMobileScannerContext = () => {
  const context = useContext(MobileScannerContext)

  if (!context) {
    throw new Error(
      'useMobileScannerContext must be used within a ScannerProvider',
    )
  }

  return context
}
