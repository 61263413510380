import { useRouteMatch, Redirect, Route, Switch } from 'react-router-dom'
import type { AxiosError } from 'axios'

import { PATHS } from 'consts/paths'

import { FullPageLoader } from 'app/components/loaders/FullPageLoader'
import type { MODEL__JWTTokenResponse } from 'app/models/tokenResponse'
import { useActivationQuery } from 'app/containers/public/AccountVerifications/Verify'
import { ActivationSuccess } from 'app/pages/public/accountActivation/ActivationSuccess/Loadable'
import { ActivationExpired } from 'app/pages/public/accountActivation/ActivationExpired/Loadable'
import { AlreadyActivated } from 'app/pages/public/accountActivation/AlreadyActivated/Loadable'
import { NotActivated } from 'app/pages/public/accountActivation/NotActivated/Loadable'
import { InvalidCode } from 'app/pages/public/accountActivation/InvalidCode/Loadable'

const getInitialRedirectPath = (
  data?: MODEL__JWTTokenResponse,
  error?: AxiosError | null,
) => {
  // success path
  if (data) {
    return PATHS.activation.success
  }

  // error paths
  switch (error?.response?.status) {
    case 409:
      return PATHS.activation.alreadyActivated
    case 401:
      return PATHS.activation.invalidCode
    case 410:
    default:
      return PATHS.activation.expired
  }
}

export const ActivationRoutes = () => {
  const isOnInitialPage = !!useRouteMatch({
    path: PATHS.activation.root,
    exact: true,
  })
  const { data, error } = useActivationQuery({
    enabled: isOnInitialPage,
  })

  if (isOnInitialPage && !data && !error) {
    return <FullPageLoader />
  }

  return (
    <Switch>
      <Route path={PATHS.activation.success} exact>
        <ActivationSuccess />
      </Route>

      <Route path={PATHS.activation.expired} exact>
        <ActivationExpired />
      </Route>

      <Route path={PATHS.activation.alreadyActivated} exact>
        <AlreadyActivated />
      </Route>

      <Route path={PATHS.activation.notYetActivated} exact>
        <NotActivated />
      </Route>

      <Route path={PATHS.activation.invalidCode} exact>
        <InvalidCode />
      </Route>

      <Redirect to={getInitialRedirectPath(data, error)} />
    </Switch>
  )
}
