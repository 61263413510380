import { InfoIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'

import {
  MarkerTooltip,
  TypographyWithTooltipOnOverflow,
} from 'app/components/tooltips'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import type { DisabledFastOrderItemStatus } from 'app/pages/customers/common/fastOrder/types'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

const messages = translations.pages.customer.scanner.article

const infoMessages: Record<
  DisabledFastOrderItemStatus,
  { message: string; tooltipMessage?: string }
> = {
  NOT_FOUND: {
    message: messages.notFoundInfo,
    tooltipMessage: undefined,
  },
  NO_LONGER_AVAILABLE: {
    message: messages.notAvailableInfo,
    tooltipMessage: messages.notAvailableTooltip,
  },
  RESTRICTED: {
    message: messages.restrictedInfo,
    tooltipMessage: messages.restrictedTooltip,
  },
}

export const DisabledArticleInfo = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { isMobile } = useBreakpointScreenSize()

  const { status } = useFastOrderListItemContext()

  const { message, tooltipMessage } =
    infoMessages[status as DisabledFastOrderItemStatus]

  const iconRendered = tooltipMessage ? (
    <MarkerTooltip content={t(tooltipMessage)}>
      <InfoIcon
        _hover={{ color: colors.contentA }}
        color={colors.contentB}
        data-testid="notAvailableOrRestrictedTooltip"
      />
    </MarkerTooltip>
  ) : (
    <InfoIcon color={colors.contentB} />
  )

  const messageRendered = (
    <TypographyWithTooltipOnOverflow color="contentB">
      {t(message)}
    </TypographyWithTooltipOnOverflow>
  )

  const content = isMobile ? (
    <>
      {iconRendered}
      {messageRendered}
    </>
  ) : (
    <>
      {messageRendered}
      {iconRendered}
    </>
  )

  return (
    <Flex gap="8px" alignItems="center">
      {content}
    </Flex>
  )
}
