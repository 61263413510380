import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { useUpdateFastOrderListItemMutation } from 'app/containers/customers/FastOrders/Update'
import { PencilIcon } from 'app/components/icons'
import { ButtonWithPopover } from 'app/components/buttonWithPopovers/ButtonWithPopover'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'

import type { ChooseArticleButtonProps } from '../types'

import { FoundArticlesList } from './FoundArticlesList'

const messages = translations.pages.customer.scanner.article

export const DesktopChooseArticleButton = ({
  displayOnlyIcon,
}: ChooseArticleButtonProps) => {
  const { t } = useTranslation()

  const { fastOrderListEnum } = useFastOrderContext()

  const {
    fastOrderListItem: { id: fastOrderListItemId, selectedArticle },
  } = useFastOrderListItemContext()

  const { mutate, isPending } = useUpdateFastOrderListItemMutation({
    fastOrderListEnum,
  })

  const buttonWithPopoverProps = {
    buttonText: displayOnlyIcon ? <PencilIcon /> : t(messages.chooseArticle),
    triggerButtonProps: {
      withoutArrow: displayOnlyIcon,
      isLoading: isPending,
      size: displayOnlyIcon ? 'squareSm' : 'sm',
    },
    popoverStyleProps: {
      placement: 'bottom-end',
      maxHeight: '380px',
      padding: '0px',
      width: '754px',
    },
  } as const

  return (
    <ButtonWithPopover
      dataTestId="chooseArticleButton"
      {...buttonWithPopoverProps}
    >
      {({ closePopover }) => (
        <FoundArticlesList
          selectedArticle={selectedArticle}
          articleSelectHandler={(selectedArticleId: string) => {
            mutate(
              { fastOrderListItemId, selectedArticle: selectedArticleId },
              { onSuccess: closePopover },
            )
          }}
        />
      )}
    </ButtonWithPopover>
  )
}
