import type { UseMutationResult } from '@tanstack/react-query'

import { useDeleteFastOrderListItemsMutation } from 'app/containers/customers/FastOrders/Delete'
import { useMoveFastOrderListItemsToCartMutation } from 'app/containers/customers/FastOrders/MoveToCart'
import { useUpdateFastOrderListItemMutation } from 'app/containers/customers/FastOrders/Update'
import { useFastOrderListItemContext } from 'app/pages/customers/common/fastOrder/contexts'
import { useFastOrderContext } from 'app/pages/customers/common/fastOrder/contexts/fastOrderContext'
import {
  useGetShowMoveArticlesToCartSuccessToast,
  useShowDeleteArticleSuccessToast,
} from 'app/pages/customers/common/fastOrder/FastOrderResults/components/common'

export const useArticleActions = () => {
  const { fastOrderListEnum } = useFastOrderContext()

  const {
    fastOrderListItem: { id: fastOrderListItemId },
  } = useFastOrderListItemContext()

  const deleteMutation = useDeleteFastOrderListItemsMutation({
    fastOrderListEnum,
  }) as UseMutationResult
  const updateMutation = useUpdateFastOrderListItemMutation({
    fastOrderListEnum,
  }) as UseMutationResult
  const moveToCartMutation = useMoveFastOrderListItemsToCartMutation({
    fastOrderListEnum,
  }) as UseMutationResult

  const showDeleteSuccessToast = useShowDeleteArticleSuccessToast({
    deletedArticlesCount: 1,
  })
  const showMoveToCartSuccessToast = useGetShowMoveArticlesToCartSuccessToast({
    movedToCartArticlesCount: 1,
  })

  const deleteHandler = () =>
    deleteMutation.mutate(
      { fastOrderListItemIds: [fastOrderListItemId] },
      { onSuccess: showDeleteSuccessToast },
    )
  const updateHandler = (newQuantity: number) =>
    updateMutation.mutate({ fastOrderListItemId, quantity: newQuantity })
  const moveToCartHandler = () =>
    moveToCartMutation.mutate(
      { fastOrderListItemIds: [fastOrderListItemId] },
      { onSuccess: showMoveToCartSuccessToast },
    )

  return {
    deleteMutation,
    deleteHandler,
    updateMutation,
    updateHandler,
    moveToCartMutation,
    moveToCartHandler,
  }
}
