import { Box, Flex, useRadioGroup } from '@chakra-ui/react'
import React from 'react'
import { FixedSizeList } from 'react-window'

import { Typography } from 'app/components/Typography'

import { RadioOption } from './RadioOption'
import { RadioGroupStyled } from './styles'
import type { BareRadioListProps } from './types'

// INFO: Thanks to https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

const VIRTUALIZED_LIST_MAX_HEIGHT = 216
const VIRTUALIZED_SINGLE_OPTION_HEIGHT = 48

function BareRadioListComponent<TValue extends string>(
  {
    options,
    radioOptionProps,
    label,
    orientation,
    radioGroupStackProps,
    shouldUseVirtualization,
    ...radioGroupProps
  }: BareRadioListProps<TValue>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { name } = radioGroupProps
  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps)

  const rootProps = getRootProps()

  if (shouldUseVirtualization) {
    return (
      <Box overflow="auto" {...rootProps}>
        <FixedSizeList
          height={Math.min(
            VIRTUALIZED_LIST_MAX_HEIGHT,
            options.length * VIRTUALIZED_SINGLE_OPTION_HEIGHT,
          )}
          itemSize={VIRTUALIZED_SINGLE_OPTION_HEIGHT}
          itemCount={options.length}
          width="100%"
        >
          {({ index, style }) => {
            const { value, label: optionLabel, isDisabled } = options[index]

            return (
              <Box style={style}>
                <RadioOption
                  key={value}
                  isDisabled={isDisabled}
                  {...radioOptionProps}
                  {...getRadioProps({ value })}
                >
                  {optionLabel}
                </RadioOption>
              </Box>
            )
          }}
        </FixedSizeList>
      </Box>
    )
  }

  return (
    <Flex direction="column" gap="8px">
      {label && <Typography textStyle="tabLabelTextSb">{label}</Typography>}

      <RadioGroupStyled
        orientation={orientation}
        ref={ref}
        {...rootProps}
        {...radioGroupStackProps}
      >
        {options.map(({ value, label: optionLabel, isDisabled, Wrapper }) => (
          <RadioOption
            key={value}
            dataTestId={`${name} - ${value}`}
            orientation={orientation}
            isDisabled={isDisabled}
            Wrapper={Wrapper}
            {...radioOptionProps}
            {...getRadioProps({ value })}
          >
            {optionLabel}
          </RadioOption>
        ))}
      </RadioGroupStyled>
    </Flex>
  )
}

export const BareRadioList = React.forwardRef(BareRadioListComponent)
