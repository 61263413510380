import styled from '@emotion/styled'

import type { ButtonProps } from '../types'

import {
  fastActionButtonDimensionStyles,
  makeMediumButtonDimensionStyles,
  smallButtonDimensionStyles,
  squareMediumButtonDimensionStyles,
  squareSmallButtonDimensionStyles,
} from './dimensions'
import { ButtonStyledComponent } from './ButtonStyledComponent'
import { getButtonVariantRelatedStyles } from './variants'

const hasCustomSizingApplied = ({ padding, height }: ButtonProps) =>
  Boolean(padding && height)

export const ButtonStyled = styled(ButtonStyledComponent)<ButtonProps>(
  ({
    color,
    isTextFullWidth,
    isFlatRight,
    isFlatLeft,
    borderRadius,
    shouldDisplayOnPrintout,
    variant,
    isWithoutBorder,
    theme,
    theme: { colors, textStyles, mediaQuery },
  }) => ({
    ...textStyles.inputMediumButtonSb,
    color: color ? colors[color] : colors.contentA,
    pointerEvents: 'all',
    ...(!borderRadius && { borderRadius: '6px' }),
    ...(isFlatLeft && {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
    }),
    ...(isFlatRight && {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
    }),
    ':focus': {
      boxShadow: 'none',
    },
    ':disabled': {
      color: colors.contentOff,
    },
    ...(isTextFullWidth && {
      '> .chakra-text': {
        width: '100%',
      },
    }),
    [mediaQuery.print]: {
      display: shouldDisplayOnPrintout ? 'initial' : 'none',
    },
    ...getButtonVariantRelatedStyles({
      color,
      variant,
      isWithoutBorder,
      theme,
    }),
  }),
  ({ size, isTallOnMobile, ...props }) => {
    if (hasCustomSizingApplied(props)) return

    switch (size) {
      case 'sm': {
        return smallButtonDimensionStyles
      }
      case 'md':
        return makeMediumButtonDimensionStyles({
          isTallOnMobile,
        })
      case 'fastAction': {
        return fastActionButtonDimensionStyles
      }
      case 'squareSm': {
        return squareSmallButtonDimensionStyles
      }
      case 'squareMd': {
        return squareMediumButtonDimensionStyles
      }
    }
  },
)
