import { http, HttpResponse } from 'msw'

import { generatedApi } from 'apiServices'
import type { ModelCustomerArticleListsSearchData } from 'libs/openapiGeneratedApi/Api'

import type { CompanyUserId } from 'app/models/companyUser'

import { getCustomerMockUrl, getOciCustomerMockUrl } from '../../utils'
import type { ArticleListId } from '../types'

export const articleListsMockData: ModelCustomerArticleListsSearchData = {
  items: [
    {
      id: '123' as ArticleListId,
      name: 'Coffee articles for Managers and C-level',
      createdAt: '2023-01-19T11:32:28.682Z',
      updatedAt: '2023-01-19T11:32:28.682Z',
      articlesCount: 12,
      vendorsCount: 4,
      createdBy: {
        name: 'Erika Mustermann',
        customerUserId: '888' as CompanyUserId,
        ociUserId: undefined,
        currentUser: true,
      },
      accessLevel: 'ADMIN',
      sharedUserInfo: {
        assignedUsersCount: 10,
        allCustomerUsersAssigned: false,
        allOciUsersAssigned: false,
        userGroupsAssigned: false,
      },
    },
    {
      id: '124' as ArticleListId,
      name: 'Office supplies for tech team',
      createdAt: '2023-01-19T11:32:28.682Z',
      updatedAt: '2023-01-19T11:32:28.682Z',
      articlesCount: 99,
      vendorsCount: 2,
      createdBy: {
        name: 'Hans Zimmer',
        customerUserId: '888' as CompanyUserId,
        ociUserId: undefined,
        currentUser: false,
      },
      accessLevel: 'WRITE',
      sharedUserInfo: undefined,
    },
    {
      id: '125' as ArticleListId,
      name: 'Cleaning and maintenance',
      createdAt: '2023-01-19T11:32:28.682Z',
      updatedAt: '2023-01-19T11:32:28.682Z',
      articlesCount: 9,
      vendorsCount: 1,
      createdBy: {
        name: 'Laurent Schneider',
        customerUserId: '888' as CompanyUserId,
        ociUserId: undefined,
        currentUser: false,
      },
      accessLevel: 'READ',
      sharedUserInfo: undefined,
    },
  ],
  metadata: {
    page: 1,
    pageSize: 24,
    pageCount: 1,
    term: '',
    hitCount: 3,
  },
}

type MakeArticleListsPostMockWithSpyProps = {
  spyFnRequestBody?: jest.Mock
  spyFnURLSearchParams?: jest.Mock
}

export const makeArticleListsPostMockWithSpy = ({
  spyFnRequestBody,
  spyFnURLSearchParams,
}: MakeArticleListsPostMockWithSpyProps = {}) =>
  http.post(
    getCustomerMockUrl(generatedApi.customers.customerArticleListsSearch.url),
    async ({ request }) => {
      const reqUrl = new URL(request.url)

      if (spyFnRequestBody) spyFnRequestBody(await request.json())

      if (spyFnURLSearchParams) {
        spyFnURLSearchParams(reqUrl.searchParams.get('sort'))
      }

      return HttpResponse.json(articleListsMockData)
    },
  )

export const articleListsPostMock = makeArticleListsPostMockWithSpy()

export const articleListsOciCustomerMock = http.post(
  getOciCustomerMockUrl(
    generatedApi.customerOciSession.customerArticleListsSearchOci.url,
  ),
  async () => HttpResponse.json(articleListsMockData),
)
