import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'

import { FooterText } from '../styles'

const messages = translations.footer

export const FooterCopyright = () => {
  const { t } = useTranslation()

  return (
    <FooterText>
      <Typography textStyle="textSmallLink" color="contentA">
        {t(messages.copyright, { currentYear: new Date().getFullYear() })}
      </Typography>
    </FooterText>
  )
}
