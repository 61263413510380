import { faker } from '@faker-js/faker'
import _ from 'lodash'

import type { CustomerId } from 'app/models/user'
import type { MODEL__Company } from 'app/models/company'

export type MODEL__CustomerAssignmentData = {
  id: CustomerId
  assigned: boolean
  vendorSpecificCustomerNumber: string | null
  currency?: string
} & Pick<
  MODEL__Company,
  'name' | 'streetName' | 'streetNumber' | 'postalCode' | 'city' | 'countryCode'
>

export const createSingleCustomerAssignmentData =
  (): MODEL__CustomerAssignmentData => ({
    id: faker.string.uuid() as CustomerId,
    name: _.uniqueId(faker.person.lastName()),
    assigned: Math.random() > 0.8,
    streetName: faker.location.street(),
    streetNumber: String(faker.number.int({ max: 1000 })),
    postalCode: faker.location.zipCode(),
    city: faker.location.city(),
    countryCode: 'DE',
    vendorSpecificCustomerNumber:
      Math.random() > 0.5
        ? String(faker.number.int({ min: 10000000, max: 99999999 }))
        : null,
  })
