import { CheckIcon } from '@chakra-ui/icons'
import { HStack } from '@chakra-ui/react'
import type { FC, MouseEventHandler } from 'react'

import type { CountryCode } from 'consts/countries'
import type { AvailableLanguages } from 'locales/types'

import { LOCALES, useLocale } from 'app/hooks/useLocale'
import { Typography } from 'app/components/Typography'
import { Flag } from 'app/components/icons'

const availableLanguagesOptions = Object.values(LOCALES).map(
  ({ id, labelLong }) => ({
    id,
    labelLong,
  }),
)

const getCountryFromLanguage = (
  language: AvailableLanguages,
): CountryCode | 'CIMODE' => {
  switch (language) {
    case 'en':
      return 'US'
    case 'cs':
      return 'CZ'
    default:
      return language.toUpperCase() as Uppercase<typeof language>
  }
}

type LanguageOptionsProps = {
  handleSelectOption?: () => void
  MenuItemComponent: FC<{
    children: React.ReactNode
    onClick?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>
  }>
}

export const LanguageOptions = ({
  handleSelectOption,
  MenuItemComponent,
}: LanguageOptionsProps) => {
  const { currentLocale, setLocale } = useLocale()

  return (
    <>
      {availableLanguagesOptions.map(({ id, labelLong }) => {
        const isActive = currentLocale === id
        const onClick = () => {
          if (handleSelectOption) handleSelectOption()
          setLocale(id)
        }
        const countryCode = getCountryFromLanguage(id)

        return (
          <MenuItemComponent
            key={id}
            onClick={isActive ? undefined : onClick}
            data-testid={`languageOption-${labelLong}`}
          >
            <HStack width="100%" spacing="16px">
              <Flag countryCode={countryCode} flexShrink={0} width="20px" />

              <Typography width="100%">{labelLong}</Typography>

              {isActive && <CheckIcon boxSize="20px" padding="4px" />}
            </HStack>
          </MenuItemComponent>
        )
      })}
    </>
  )
}
