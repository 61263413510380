import { Flex, AccordionIcon } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'
import { useCurrentCompanyUserFullName } from 'app/containers/common/Me/CompanyUser'

import { AccordionOption } from '../AccordionOption'

import { MyAccountContent } from './MyAccountContent'

export const MyAccount = () => {
  const { t } = useTranslation()
  const fullName = useCurrentCompanyUserFullName()

  return (
    <AccordionOption
      withDividerAtBottom
      title={(isExpanded) => (
        <>
          <Flex
            flex="1"
            justify="space-between"
            data-testid="myAccountAccordionButtonContent"
          >
            <Typography textStyle="headerH4">
              {t(translations.header.myAccount)}
            </Typography>
            <Typography color="contentB" mr="20px">
              {!isExpanded && fullName}
            </Typography>
          </Flex>
          <AccordionIcon />
        </>
      )}
    >
      <MyAccountContent />
    </AccordionOption>
  )
}
