import type { CSSProperties } from 'react'

export type DotSeparatorProps = {
  color?: CSSProperties['color']
  margin?: CSSProperties['margin']
  alignSelf?: CSSProperties['alignSelf']
}

export const DotSeparator = ({
  color,
  margin = '0 5px',
  alignSelf,
}: DotSeparatorProps) => (
  <span style={{ margin, color, alignSelf, lineHeight: '12px' }}>•</span>
)
