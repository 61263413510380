import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as yup from 'yup'
import { merge } from 'lodash'

import { translations } from 'locales/translations'

import { getRequiredStringFieldValidation } from 'app/components/fields/utils'
import { ModalWithFooter } from 'app/components/modals/ModalWithFooter'
import { Input } from 'app/components/inputs/Input'
import { useCreateArticleListMutation } from 'app/containers/customers/ArticleLists/Create'
import { Typography } from 'app/components/Typography'
import { Notification } from 'app/components/Notification/Notification'
import type { ArticleListId } from 'app/containers/customers/ArticleLists/types'
import { useUpdateArticleListMutation } from 'app/containers/customers/ArticleLists/Update'
import { useCheckCustomerPermission } from 'app/containers/common/Me/CompanyUser'

const { createModal: createMessages, updateModal: updateMessages } =
  translations.pages.customer.companyAndSettings.articlesLists.list

export const CREATE_UPDATE_ARTICLES_LIST_MODAL =
  'CREATE_UPDATE_ARTICLES_LIST_MODAL'

type CreateUpdateArticlesListModalProps = Partial<{
  id: ArticleListId
  name: string
}>

export const CreateUpdateArticlesListModal =
  NiceModal.create<CreateUpdateArticlesListModalProps>(({ id, name }) => {
    const { t } = useTranslation()
    const { isAdmin } = useCheckCustomerPermission()

    const isUpdateMode = !!id

    const validationSchema = yup.object({
      name: getRequiredStringFieldValidation(t(createMessages.nameField.label)),
    })
    const initialValues = merge(validationSchema.getDefault(), { name })
    const { mutate: createArticleList } = useCreateArticleListMutation()
    const { mutate: updateArticleList } = useUpdateArticleListMutation()

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const onSuccess = () => {
            hideCreateUpdateArticlesListModal()
          }

          return isUpdateMode
            ? updateArticleList({ id, ...values }, { onSuccess })
            : createArticleList(values, { onSuccess })
        }}
      >
        {({ resetForm, isValid, dirty }) => (
          <ModalWithFooter
            shouldWrapWithForm
            id={CREATE_UPDATE_ARTICLES_LIST_MODAL}
            header={t(
              isUpdateMode ? updateMessages.header : createMessages.header,
            )}
            saveTranslation={t(
              isUpdateMode
                ? translations.formActions.save
                : createMessages.saveLabel,
            )}
            isSaveButtonDisabled={!isValid || !dirty}
            handleClose={() => resetForm()}
            onCancelHandler={(closeModal) => {
              resetForm()
              closeModal()
            }}
          >
            <Typography textStyle="textParagraph">
              {t(createMessages.description)}
            </Typography>
            <Input
              name="name"
              label={t(createMessages.nameField.label)}
              placeholder={t(createMessages.nameField.placeholder)}
            />
            <Notification type="info">
              {isAdmin
                ? t(createMessages.adminNotification)
                : t(createMessages.shopperNotification)}
            </Notification>
          </ModalWithFooter>
        )}
      </Formik>
    )
  })

NiceModal.register(
  CREATE_UPDATE_ARTICLES_LIST_MODAL,
  CreateUpdateArticlesListModal,
)

export const showCreateUpdateArticlesListModal = (
  params?: CreateUpdateArticlesListModalProps,
) => NiceModal.show(CREATE_UPDATE_ARTICLES_LIST_MODAL, params)

export const hideCreateUpdateArticlesListModal = () =>
  NiceModal.remove(CREATE_UPDATE_ARTICLES_LIST_MODAL)
