import { Flex } from '@chakra-ui/react'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { useTheme } from 'theme'

import { Typography, type TypographyProps } from 'app/components/Typography'
import { useFastOrderResultsNotification } from 'app/pages/customers/common/fastOrder/FastOrderResults/components'

const messages = translations.pages.customer.scanner.mobile.drawerMainBar

export const ScanningResultsHeader = (props: TypographyProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { isAnyNotificationShown } = useFastOrderResultsNotification()

  return (
    <Flex gap="8px" alignItems="center">
      <Typography {...props}>{t(messages.title)}</Typography>
      {isAnyNotificationShown && (
        <WarningTwoIcon data-testid="warningIcon" color={colors.red_500} />
      )}
    </Flex>
  )
}
