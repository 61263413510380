import type { Opaque } from 'type-fest'

export const ACCESS_TOKEN_KEY = 'token'
export const REFRESH_TOKEN_KEY = 'refreshToken'

export type Token = Opaque<string, 'token'>

export type MODEL__JWTTokenResponse = {
  [ACCESS_TOKEN_KEY]: Token
  [REFRESH_TOKEN_KEY]: Token
}

export const assertValidTokens = (
  tokens: Partial<MODEL__JWTTokenResponse> | undefined,
): tokens is MODEL__JWTTokenResponse =>
  !!tokens?.token && !!tokens?.refreshToken
