import { Flex, HStack } from '@chakra-ui/react'

import { useTheme } from 'theme'

import { useDetectPrintMode } from 'app/utils/print/useDetectPrintMode'

import { Typography } from '../Typography'
import { XIcon } from '../icons'

import { ButtonStyled, DeleteIconWrapper, NotificationWrapper } from './styles'
import { useColorMapping } from './utils'
import { ICON_MAPPING } from './consts'
import type { NotificationProps } from './types'

export const Notification = ({
  type,
  buttonClickHandler,
  buttonText,
  buttonIcon,
  onNotificationClose,
  children,
  customIcon,
  isElevated,
  isWithoutIcon,
  ...panelProps
}: NotificationProps) => {
  const { colors } = useTheme()
  const { backgroundColor, ...buttonColors } = useColorMapping(type)
  const Icon = ICON_MAPPING[type]

  const { isPrintMode } = useDetectPrintMode()

  return (
    <NotificationWrapper
      role="notification"
      backgroundColor={backgroundColor}
      isElevated={isElevated}
      type={type}
      minHeight={isPrintMode ? 'initial' : '52px'}
      p={isPrintMode ? '8px 16px' : '8px 20px'}
      {...panelProps}
    >
      <HStack flex={1} spacing="20px">
        {!isWithoutIcon && (
          <Flex width="20px" justifyContent="center" alignItems="center">
            {customIcon ?? <Icon color={colors.contentA} />}
          </Flex>
        )}

        <Typography as="div" flex={1}>
          {children}
        </Typography>
      </HStack>

      {buttonClickHandler && (
        <ButtonStyled
          {...buttonColors}
          onClick={buttonClickHandler}
          dataTestId="notificationButton"
          leftIcon={buttonIcon}
        >
          {buttonText}
        </ButtonStyled>
      )}

      {typeof onNotificationClose === 'function' && (
        <DeleteIconWrapper
          onClick={onNotificationClose}
          data-testid="deleteNotificationButton"
        >
          <XIcon color={colors.contentA} />
        </DeleteIconWrapper>
      )}
    </NotificationWrapper>
  )
}
