import type { TableHeaderProps } from 'react-table'
import styled from '@emotion/styled'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

export const TheadStyled = styled(
  ({
    withoutHeaderTopBorder,
    ...rest
  }: BoxProps & { withoutHeaderTopBorder?: boolean }) => <Box {...rest} />,
)(({ theme, withoutHeaderTopBorder }) => ({
  // These styles are required for a scrollable body to align with the header properly
  overflowY: 'auto',
  overflowX: 'hidden',
  ...(!withoutHeaderTopBorder && { borderTop: theme.borders.primary }),
  borderBottom: theme.borders.primary,
}))

export const ThStyled = styled(
  ({ style, ...rest }: BoxProps & TableHeaderProps) => (
    <Box {...rest} style={{ ...style, padding: '8px 20px' }} />
  ),
)(({ theme: { colors } }) => ({
  height: '100%',
  margin: 'auto',

  '[role=tooltip] *': {
    color: `${colors.white}`,
    fontWeight: 400,
  },
}))
